import * as t from "io-ts";
import { DateFromString } from "../../io-ts/types";
import { ProgramEntity } from "./Program";
import {
  ReimbursementEventEntity,
  ReimbursementEvent,
} from "./ReimbursementEvent";
import {
  ReimbursementServiceEntity,
  ReimbursementService,
} from "./ReimbursementService";
import { StateEntity, StateC } from "./State";

export const ReimbursementC = t.type({
  attributes: t.type({
    created_at: DateFromString,
    notification_unread_at: t.union([t.null, DateFromString]),
    provider_name: t.string,
    state: StateC,
    total_approved_amount: t.number,
    total_requested_amount: t.number,
    uid: t.string,
  }),
  id: t.string,
  type: t.literal("reimbursement"),
});

export type ReimbursementEntity = t.TypeOf<typeof ReimbursementC>;

export interface ReimbursementEntityWithRelations
  extends ReimbursementEntity {
  relationships: {
    services: ReimbursementServiceEntity[];
    program: ProgramEntity;
    events: ReimbursementEventEntity[];
  };
}

export type Reimbursement = {
  id: string;
  createdAt: Date;
  providerName: string;
  state: StateEntity;
  totalRequestedAmount: number;
  totalApprovedAmount: number;
  uid: string;
  type: "reimbursement";
  programName: string;
  events: ReimbursementEvent[];
  services: ReimbursementService[];
};

export type MainPageReimbursement = {
  id: string;
  createdAt: Date;
  state: StateEntity;
  totalRequestedAmount: number;
  totalApprovedAmount: number;
  uid: string;
  type: "reimbursement";
  events: ReimbursementEvent[];
};
