import * as t from "io-ts";
import { CareNavigator } from "./CareNavigator";

export const CallSubjectC = t.type({
  attributes: t.type({
    call_subject_type: t.string,
    call_subject_type_name: t.string,
    talk_to_expert_text: t.string,
  }),
  id: t.string,
  type: t.literal("call_subject"),
});

export type CallSubjectEntity = t.TypeOf<typeof CallSubjectC>;

export interface CallSubjectEntityWithRelations
  extends CallSubjectEntity {
  relationships: {
    call_experts: CareNavigator[];
  };
}
