import { toDate, formatInTimeZone } from "date-fns-tz";
import { isLeft } from "fp-ts/Either";
import * as t from "io-ts";
import reporter from "io-ts-reporters";
import qs from "qs";
import { useQuery } from "react-query";
import { DateFromString } from "../../../io-ts/types";
import { wrongResponseFormat } from "../../../locales/errors";
import { useAxios } from "../../../network";
import { ENDPOINTS } from "../../../network/endpoints";

const responseC = t.readonlyArray(DateFromString);
export const useAvailableCallTimes = (
  id: string,
  timezone: string,
  date: Date,
) => {
  const axios = useAxios();
  return useQuery(
    ["calls", "getAvailableCallTimes", id, date],
    async () => {
      const response = await axios.get(
        `${ENDPOINTS.getCallTimeSlots.replace(
          ":id",
          id,
        )}?${qs.stringify({
          date: formatInTimeZone(date, timezone, "yyyy-MM-dd"),
          timezone,
        })}`,
      );

      const decodeResult = responseC.decode(response.data);
      if (isLeft(decodeResult)) {
        // eslint-disable-next-line no-console
        console.error(reporter.report(decodeResult));
        throw wrongResponseFormat;
      }
      return decodeResult.right.map((time) =>
        toDate(time, {
          timeZone: timezone,
        }),
      );
    },
  );
};
