import { Trans } from "@lingui/macro";
import React, { useState, useCallback } from "react";
import styled, { css } from "styled-components";
import useAuthSession from "../../authentication/hooks/useAuthSession";
import { colors } from "../../constants";
import Button from "../../lib/Button";
import Markdown from "../../lib/Markdown";
import Title from "../../lib/Title";
import mediaQuery from "../../utils/mediaQuery";
import BookAppointmentForm from "./BookAppointmentForm";
import { ClinicEntityWithRelations } from "./models/Clinic";

const Container = styled.div`
  padding: 22px 22px 0;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      padding: 30px 40px 20px;
    `,
  )}
`;

const Info = styled.div`
  align-items: flex-start;
  border-bottom: 1px solid ${colors.brownLightGrey1};
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding-bottom: 20px;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      flex-direction: row;
    `,
  )}
`;

const InfoText = styled.div`
  flex-grow: 1;
  padding-inline-end: 15px;
`;

const StyledTitle = styled(Title)`
  margin: 0 0 10px;
`;
const InfoLine = styled.div`
  color: ${colors.brownGrey};
  font-size: 12px;
  line-height: 20px;
`;

const MoreLocations = styled.div`
  margin-bottom: 40px;
  margin-top: 14px;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      margin-bottom: 0;
    `,
  )}
`;

const ShowMoreLocations = styled.div`
  color: #3e4fd6;
  cursor: pointer;
  text-decoration: underline;
`;

const Description = styled.div`
  align-items: flex-start;
  display: flex;
  margin-bottom: 20px;
`;

const DescriptionText = styled.div`
  flex-grow: 1;
  font-size: 12px;
  line-height: 20px;

  p {
    font-size: 12px;
    line-height: 20px;
    margin: 0 0 10px;
  }
`;

const Experts = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
`;

const Expert = styled.div`
  align-items: center;
  display: flex;
  margin: 0 10px 10px;
`;

const ExpertPic = styled.div`
  border-radius: 50%;
  height: 64px;
  margin-inline-end: 10px;
  overflow: hidden;
  position: relative;
  width: 64px;
  background-color: ${colors.brownLightGrey2};
  border: 1px solid transparent;

  img {
    aspect-ratio: 1/1;
    height: auto;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
`;

const ExpertName = styled.div`
  font-size: 12px;
  line-height: 20px;
  width: 75px;
`;

const StyledButton = styled(Button)`
  flex-shrink: 0;
  width: 100%;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      width: auto;
    `,
  )}
`;

const Image = styled.div`
  align-items: center;
  border-inline-end: 1px solid ${colors.brownLightGrey1};
  display: flex;
  flex: none;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 0;
  justify-content: center;
  line-height: 0;
  margin-inline-end: 20px;
  padding: 0 10px;
  width: 40px;

  img {
    height: auto;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }

  ${mediaQuery(
    "greaterThanPhone",
    css`
      margin-inline-end: 30px;
      padding: 0 20px;
      width: 180px;
    `,
  )}
`;

const LayoutClinic: React.FC<ClinicEntityWithRelations> = ({
  attributes,
  relationships,
  id,
}: ClinicEntityWithRelations) => {
  const [showMoreLocations, setShowMoreLocations] = useState(false);
  const [showBookAppointment, setBookAppointment] = useState(false);

  const [firstLocation, ...restLocations] = relationships.locations;

  const onCancelAppointmentClick = useCallback(() => {
    setBookAppointment(false);
  }, []);

  const { data: authSession } = useAuthSession();

  const isUS: boolean =
    authSession?.user?.company?.attributes?.country_code === "US";

  return (
    <Container>
      <Info>
        {attributes.image_set && (
          <Image>
            <img
              alt={attributes.title}
              height={`${
                attributes.image_set.aspect_ratio.split(":")[1]
              }`}
              sizes="(max-width: 767px) 40px, 180px"
              src={attributes.image_set.w256}
              srcSet={`${attributes.image_set.w64} 64w, ${attributes.image_set.w128} 128w, ${attributes.image_set.w256} 256w, ${attributes.image_set.w512} 512w`}
              width={`${
                attributes.image_set.aspect_ratio.split(":")[0]
              }`}
            />
          </Image>
        )}
        <InfoText>
          <StyledTitle level="h3">{attributes.title}</StyledTitle>
          {firstLocation && (
            <InfoLine>{firstLocation.attributes.address}</InfoLine>
          )}
          {firstLocation && (
            <InfoLine>
              {firstLocation.attributes.phone.international_format}
            </InfoLine>
          )}
          {relationships.locations.length > 0 && (
            <MoreLocations>
              {restLocations.length > 0 && !showMoreLocations && (
                <ShowMoreLocations
                  onClick={() => setShowMoreLocations(true)}
                >
                  <Trans>Show more locations</Trans>
                </ShowMoreLocations>
              )}
              {showMoreLocations &&
                restLocations.map((item) => (
                  <InfoLine key={item.id}>
                    {item.attributes.address}
                  </InfoLine>
                ))}
            </MoreLocations>
          )}
        </InfoText>
        {isUS && attributes.practice_uid && !showBookAppointment && (
          <StyledButton
            kind="filledGreen"
            onClick={() => setBookAppointment(true)}
          >
            <Trans>Book Appointment</Trans>
          </StyledButton>
        )}
      </Info>
      <Description>
        <DescriptionText>
          <Markdown
            css={`
              color: ${colors.brownBlack};
            `}
            text={attributes.body.toString()}
          />
        </DescriptionText>
      </Description>
      <Experts>
        {relationships.doctors.map((item) => (
          <Expert key={item.id}>
            <ExpertPic>
              {item.attributes.image_set && (
                <img
                  alt={item.attributes.name}
                  height={`${
                    item.attributes.image_set.aspect_ratio.split(
                      ":",
                    )[1]
                  }`}
                  sizes="66px"
                  src={item.attributes.image_set.w128}
                  srcSet={`${item.attributes.image_set.w64} 64w, ${item.attributes.image_set.w128} 128w, ${item.attributes.image_set.w256} 256w`}
                  width={`${
                    item.attributes.image_set.aspect_ratio.split(
                      ":",
                    )[0]
                  }`}
                />
              )}
            </ExpertPic>
            <ExpertName>{item.attributes.name}</ExpertName>
          </Expert>
        ))}
      </Experts>
      {showBookAppointment && (
        <BookAppointmentForm
          clinicId={id}
          onCancel={onCancelAppointmentClick}
        />
      )}
    </Container>
  );
};

export default LayoutClinic;
