import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { ComponentProps, ReactNode } from "react";

export default function ErrorBoundary(props: {
  children: ReactNode;
  fallback: ComponentProps<typeof SentryErrorBoundary>["fallback"];
}) {
  const { children, fallback } = props;
  return (
    <SentryErrorBoundary fallback={fallback}>
      {children}
    </SentryErrorBoundary>
  );
}
