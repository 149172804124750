import { Trans } from "@lingui/macro";
import React, { FC } from "react";
import styled from "styled-components";
import { colors } from "../../../../constants";
import Icon from "../../../../lib/Icon";
import LinkButton from "../../../../lib/LinkButton";

const Container = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 8px;
  cursor: pointer;
`;

const StyledLinkButton = styled(LinkButton)`
  color: ${colors.blue};
  display: block;
  font-size: 13px;
  margin-inline-start: 10px;
  text-decoration: none;
`;

const ChangeEmailBlock: FC<{
  onChangeEmail: () => void;
}> = ({ onChangeEmail }) => {
  return (
    <Container
      onClick={(e) => {
        e.preventDefault();
        onChangeEmail();
      }}
    >
      <Icon
        fill={colors.blue}
        height={16}
        type="back"
        viewBox="0 0 24 24"
        width={16}
      />
      <StyledLinkButton>
        <Trans>Change email</Trans>
      </StyledLinkButton>
    </Container>
  );
};

export default ChangeEmailBlock;
