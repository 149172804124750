import * as React from "react";
import { ComponentProps } from "react";
import Spinner from "../Spinner";
import Button from ".";

export type Props = ComponentProps<typeof Button> & {
  isLoading: boolean;
  children: React.ReactNode;
  loaderColor?: string;
};

export const ButtonWithLoader: React.FC<Props> = ({
  isLoading = false,
  loaderColor = "#ffffff",
  children,
  icon,
  ...rest
}: Props) => (
  <Button {...rest}>
    {isLoading ? (
      <Spinner color={loaderColor} size="18px" />
    ) : (
      children
    )}
  </Button>
);
