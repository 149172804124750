import styled, { css } from "styled-components";
import { colors } from "../../../constants";
import Button from "../../../lib/Button";
import FormField from "../../../lib/forms/FormField";
import mediaQuery from "../../../utils/mediaQuery";

export const Wrapper = styled.div`
  padding: 24px var(--content-margin-side) 0;

  ${mediaQuery(
    "desktopMedium",
    css`
      padding-top: 50px;
    `,
  )}
`;

export const Container = styled.div`
  max-width: var(--content-max-width);
`;

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const Content = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.brownLightGrey1};
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  font-size: 15px;
  line-height: 24px;
  margin-top: 20px;
  padding: 0 22px;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      padding: 0 30px;
    `,
  )}
`;

export const Item = styled.div<{
  fullWide?: boolean;
  mobileFullWide?: boolean;
}>`
  border-bottom: 1px solid ${colors.brownLightGrey1};
  box-sizing: border-box;
  padding: 20px 0;
  width: ${({ fullWide, mobileFullWide }) =>
    fullWide || mobileFullWide ? "100%" : "50%"};

  &:last-child {
    border-bottom: none;
  }

  p {
    font-weight: bold;
    margin: 0;
  }

  ${({ fullWide }) =>
    mediaQuery(
      "greaterThanPhone",
      css`
        padding: 24px 0;
        width: ${fullWide ? "100%" : "50%"};

        &:nth-last-child(2) {
          border-bottom: none;
        }
      `,
    )}
`;

export const Label = styled.div``;

export const Comment = styled.div`
  color: ${colors.brownGrey};
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;
`;

export const Alert = styled.div`
  color: ${colors.brownGrey};
  font-size: 12px;
  line-height: 16px;
  margin: 24px 0;
  position: relative;

  &:after {
    align-items: center;
    background: ${colors.coralStable};
    border-radius: 50%;
    color: ${colors.black};
    content: "!";
    display: flex;
    height: 22px;
    inset-inline-end: 0;
    justify-content: center;
    position: absolute;
    text-align: center;
    top: 0;
    width: 22px;
  }
  ${mediaQuery(
    "desktopMedium",
    css`
      width: 420px;
    `,
  )}
`;
export const AlertMessage = styled.div`
  color: ${colors.coralRedAAA};
  margin-bottom: 10px;
  width: 280px;
`;

export const InputWrapper = styled(FormField)`
  width: 100%;

  ${mediaQuery(
    "desktopMedium",
    css`
      width: 50%;
    `,
  )}
`;

export const MarginButton = styled(Button)`
  margin-top: 24px;
`;

export const ActionText = styled.span`
  color: ${colors.blue};
  cursor: pointer;
  text-decoration: underline;
`;

export const StyledButton = styled(Button)`
  position: relative;
`;
