import { FC } from "react";
import { colors } from "../../constants";

export const LinkBackIcon: FC = () => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 17 18"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      css={`
        [dir="rtl"] & {
          transform: rotate(180deg);
          transform-origin: center;
        }
      `}
    >
      <path
        d="M9 16.3334L1.6667 8.9981 9 1.6667M16.6659 8.9H1.6667"
        stroke="currentColor"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export const ArrowLeftIcon: React.FC = () => (
  <svg fill="none" height="24" viewBox="0 0 25 24" width="24">
    <g
      css={`
        [dir="rtl"] & {
          transform: rotate(180deg);
          transform-origin: center;
        }
      `}
    >
      <path
        d="M13 23 2 11.9972 13 1m11.4989 10.85H2"
        stroke={colors.brownGrey}
        strokeWidth="2"
      />
    </g>
  </svg>
);
