import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { t, Trans } from "@lingui/macro";
import React, { FC, useCallback, useMemo, useRef } from "react";
import ReCAPTCHABase from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import * as yup from "yup";
import ReCAPTCHA from "../../../components/ReCAPTCHA";
import { colors } from "../../../constants";
import Alert from "../../../lib/Alert";
import FormField from "../../../lib/forms/FormField";
import Input from "../../../lib/forms/primitives/Input";
import { Text } from "../../../lib/Text";
import { useHandleSubmitImpl } from "../../../utils/forms";
import mediaQuery from "../../../utils/mediaQuery";
import renderError from "../../../utils/renderError";
import Info from "../Info";
import Layout from "../Layout";
import imgSrc from "../Layout/signin-bg.jpeg";
import { Header, StyledButton } from "../styles";
import {
  useCustomerSpecificCode,
  useSignUp,
  useStoreCustomerSpecificCode,
} from "./hooks";

const CheckYourEmailHeader = styled(Header)``;
const ActivateMembershipHeader = styled(Header)``;

const AlreadyActivated = styled(Text)`
  color: #4c4c4c;
  display: block;
  font-size: 16px;
  line-height: 20px;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      font-size: 20px;
      line-height: 24px;
    `,
  )}
`;

const StyledAlert = styled(Alert)`
  color: #4c4c4c;
  font-size: 20px;
  line-height: 24px;
`;

const getResolver = () => {
  const FormSchema = yup.object().shape({
    email: yup
      .string()
      .email(t`Please enter a valid email address`)
      .required(t`Email required`),
  });

  return yupResolver(FormSchema);
};

const StyledForm = styled.form`
  ${mediaQuery(
    "desktopMedium",
    css`
      width: 355px;
    `,
  )}
  .labelText {
    color: #4c4c4c;
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 12px;
  }
`;

const StyledLayout = styled(Layout)`
  ${ActivateMembershipHeader} {
    margin-bottom: 8px;
    ${mediaQuery(
      "phone",
      css`
        font-size: 20px;
        line-height: 24px;
      `,
    )}
  }
  ${CheckYourEmailHeader} {
    margin-bottom: 8px;
  }
  ${AlreadyActivated} {
    margin-bottom: 40px;
  }
  ${StyledButton} {
    margin-top: 30px;
  }
`;

const Email = styled.span`
  font-weight: 500;
`;

interface LocationState {
  invalidTokenError?: string;
}
export interface FormProps {
  email: string;
}
const ActivateMembershipScreen: FC = () => {
  const location = useLocation();
  const invalidTokenError = (location.state as LocationState | null)
    ?.invalidTokenError;
  const customerCode = useCustomerSpecificCode();
  useStoreCustomerSpecificCode();
  const {
    data: isSuccess,
    isLoading,
    error: externalError,
    mutate: signUp,
  } = useSignUp();

  const resolver = useMemo(getResolver, []);

  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors },
  } = useForm<FormProps>({
    defaultValues: {
      email: "",
    },

    resolver,
  });
  const recaptchaRef = useRef<ReCAPTCHABase>(null);
  const onSubmit = useCallback(
    ({
      email,
      recaptchaToken,
    }: {
      email: string;
      recaptchaToken: string;
    }) => {
      signUp({ customerCode, email, recaptchaToken });
    },
    [customerCode, signUp],
  );
  const { handler: handleSubmitImpl, error: internalError } =
    useHandleSubmitImpl(handleSubmit, onSubmit, recaptchaRef);
  const error = externalError || internalError;
  const fieldError =
    errors.email?.message || (error ? renderError(error) : undefined);

  return (
    <StyledLayout imgSrc={imgSrc}>
      {invalidTokenError && (
        <Alert status="error">{invalidTokenError}</Alert>
      )}
      {!isSuccess && (
        <>
          <ActivateMembershipHeader>
            <Trans>Activate your membership</Trans>
          </ActivateMembershipHeader>
          <AlreadyActivated>
            <Trans>
              Already activated?{" "}
              <Link
                css={`
                  color: #2d4f61 !important;
                  text-decoration: underline !important;
                `}
                to="/signin"
              >
                Sign in
              </Link>
            </Trans>
          </AlreadyActivated>
          <StyledForm onSubmit={handleSubmitImpl}>
            <FormField
              error={fieldError}
              label={
                customerCode
                  ? t`Enter your email`
                  : t`Enter your email to verify eligibility`
              }
              labelTextClassName="labelText"
            >
              <Input
                {...register("email")}
                $activeColor={colors.blueGreen}
                aria-invalid={!!fieldError}
                autoComplete="email"
                placeholder={
                  customerCode ? t`Your email` : t`Your work email`
                }
                type="email"
              />
            </FormField>
            <StyledButton
              disabled={isLoading}
              kind="filledBlueGreen"
              type="submit"
            >
              <Trans>Sign up</Trans>
            </StyledButton>
            <ReCAPTCHA ref={recaptchaRef} />
          </StyledForm>
          <Info full />
        </>
      )}
      {!!isSuccess && (
        <>
          <CheckYourEmailHeader>
            <Trans>Check your email</Trans>
          </CheckYourEmailHeader>
          <StyledAlert status="info">
            <Trans>
              Please check your email
              <br />
              <Email>{getValues("email")}</Email>
              <br />
              to verify your address.
            </Trans>
          </StyledAlert>
        </>
      )}
    </StyledLayout>
  );
};

export default ActivateMembershipScreen;
