import { FC, HTMLAttributes } from "react";

export const IconClose: FC<HTMLAttributes<SVGElement>> = (props) => {
  return (
    <svg
      height="1em"
      version="1.1"
      viewBox="0 0 30 30"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g fill="currentColor">
        <path d="M15,2.06896552 C7.85838686,2.06896552 2.06896552,7.85838686 2.06896552,15 C2.06896552,22.1416131 7.85838686,27.9310345 15,27.9310345 C22.1416131,27.9310345 27.9310345,22.1416131 27.9310345,15 C27.9310345,7.85838686 22.1416131,2.06896552 15,2.06896552 Z M15,0 C23.2842712,-1.52179594e-15 30,6.71572875 30,15 C30,23.2842712 23.2842712,30 15,30 C6.71572875,30 1.01453063e-15,23.2842712 0,15 C-1.01453063e-15,6.71572875 6.71572875,1.52179594e-15 15,0 Z" />
        <polygon points="9 10.8140304 10.4143967 9.4 16.1214119 15.1084934 10.4142135 20.8142136 9.00018314 19.3998169 13.2929848 15.1081271" />
        <polygon
          points="14 10.8140304 15.4143967 9.4 21.1214119 15.1084934 15.4142135 20.8142136 14.0001831 19.3998169 18.2929848 15.1081271"
          transform="translate(17.560706, 15.107107) scale(-1, 1) translate(-17.560706, -15.107107) "
        />
      </g>
    </svg>
  );
};
