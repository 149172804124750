import * as React from "react";
import styled from "styled-components";
import { colors, fonts } from "../../constants";

interface IconProps {
  color?: string;
}

const Icon: React.FC<IconProps> = ({
  color = "#ffffff",
}: IconProps) => (
  <svg fill="none" viewBox="0 0 22 23">
    <path
      d="M11 0.5C13.0874 0.5 15.1317 1.09392 16.8941 2.21239C18.6565 3.33086 20.0643 4.92771 20.9531 6.81643C21.8419 8.70514 22.1749 10.8077 21.9133 12.8787C21.6516 14.9496 20.8062 16.9033 19.4756 18.5117L17.611 16.9691C18.6488 15.7146 19.3083 14.1907 19.5123 12.5754C19.7164 10.96 19.4566 9.32001 18.7634 7.84681C18.0702 6.37362 16.9721 5.12807 15.5974 4.25567C14.2227 3.38326 12.6282 2.92 11 2.92L11 0.5Z"
      fill={color}
    />
  </svg>
);

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;

const Circle = styled.div<{ size: string }>`
  @keyframes loader-rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  animation: loader-rotation 0.75s linear infinite;
  display: flex;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`;

const Label = styled.div`
  color: ${colors.brownLightGrey1};
  font-family: ${fonts.alt};
  font-size: 24px;
  line-height: 28px;

  padding-inline-start: 10px;
  position: relative;
`;

export type Props = {
  className?: string;
  label?: string;
  size?: string;
  color?: string;
};

const Spinner: React.FC<Props> = ({
  label = "",
  size = "26px",
  color = "#ffffff",
  ...restProps
}: Props) => (
  <Container {...restProps}>
    <Circle size={size}>
      <Icon color={color} />
    </Circle>
    {label ? <Label>{label}</Label> : null}
  </Container>
);

export default Spinner;
