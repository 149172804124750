import { Bill, BillRaw } from "../models/Bill";
import { Status } from "../models/Status";

export const getBillStatus = (bill: BillRaw): Status => {
  switch (bill.attributes.state) {
    case "requested":
      if (bill.attributes.overdue) {
        return "billPaymentOverdue";
      }
      if (bill.attributes.due_soon) {
        return "billPaymentDueSoon";
      }
      return "billAvailable";
    case "initiated":
      return "billPaymentInit";
    case "failed":
      return "billPaymentFailed";
    case "paid":
    case "manually_collected":
      return "billPaid";
    default:
      return "billAvailable";
  }
};

export const getBillCreatedAt = (
  bill: Bill,
  status: Status,
): Date => {
  const now = new Date();
  switch (status) {
    case "billAvailable":
    case "billPaymentDueSoon":
    case "billPaymentOverdue":
      return bill.requestedAt || now;
    case "billPaymentInit":
      return bill.startProcessingAt || now;
    case "billPaymentFailed":
    case "billPaid":
      return bill.processedAt || now;
    default:
      return bill.requestedAt || now;
  }
};

export const getBill = (raw: BillRaw): Bill => ({
  amount: raw.attributes.amount,
  coveredAmount: raw.attributes.covered_amount,
  dueSoon: raw.attributes.due_soon,
  id: raw.id,
  overdue: raw.attributes.overdue,
  paymentDueDate: raw.attributes.payment_due_date,
  paymentSoonDueDate: raw.attributes.payment_soon_due_date,
  processedAt: raw.attributes.processed_at,
  providerName: raw.attributes.provider.provider_name,
  requestedAt: raw.attributes.requested_at,
  sequentialId: raw.attributes.sequential_id,
  serviceDate: raw.attributes.service.service_date,
  serviceName: raw.attributes.service.service_name,
  startProcessingAt: raw.attributes.start_processing_at,
  totalAmount: raw.attributes.total_amount,
  url: `/bills/${raw.id}`,
});
