import { FC } from "react";
import PageLayout, { Body, Footer, Sidebar } from "../../PageLayout";
import Categories from "../Categories";
import ArticlesHeader from "../Header";
import { ArticlesContainer } from "../style";
import CategoryBlock from "./CategoryBlock";

const CategoryPage: FC = () => (
  <PageLayout
    footer={<Footer />}
    header={<ArticlesHeader />}
    sidebar={<Sidebar />}
  >
    <Body>
      <Categories />
      <ArticlesContainer>
        <CategoryBlock />
      </ArticlesContainer>
    </Body>
  </PageLayout>
);

export default CategoryPage;
