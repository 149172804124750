import { useMutation } from "react-query";
import { useAxios } from "../../network";
import { ENDPOINTS } from "../../network/endpoints";
import broadcastChannel from "../../utils/broadcastChannel";

export function useSignOut() {
  const axios = useAxios();
  return useMutation(
    async () => {
      await axios.delete(ENDPOINTS.signIn, {
        validateStatus: (status) => status === 204 || status === 401,
      });
    },
    {
      onSuccess: () => {
        broadcastChannel.postMessage("logout");
        window.location.pathname = "/signin";
      },
    },
  );
}
