import * as t from "io-ts";
import { NumberFromString } from "../../io-ts/types";

export const ServiceStateC = t.union([
  t.literal("granted"),
  t.literal("rejected"),
  t.literal("requested"),
  t.literal("cancelled"),
  t.literal("canceled"),
]);

export type ServiceState = t.TypeOf<typeof ServiceStateC>;

export const ServiceC = t.type({
  attributes: t.type({
    amount: t.number,
    description: t.string,
    rate: NumberFromString,
    service_code: t.string,
    service_name: t.string,
    state: ServiceStateC,
  }),
  id: t.string,
  type: t.literal("service"),
});

export type ServiceRaw = t.TypeOf<typeof ServiceC>;

export type Service = {
  amount: number;
  bundleEquivalent: null;
  description: string;
  id: string;
  rate: number;
  serviceCode: string;
  serviceName: string;
  state: ServiceState;
};

// export type ServiceState =
//   | "canceled"
//   | "granted"
//   | "rejected"
//   | "requested";
