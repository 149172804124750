import type { MessageDescriptor } from "@lingui/core";
import { i18n } from "@lingui/core";
import {
  JSONApiErrorsException,
  JSONApiErrorsPayload,
} from "../network/jsonApi/core/JSONApiErrorsPayload";

export class LocalizedJSONApiErrorsException extends JSONApiErrorsException {
  constructor(
    private messageDescription: MessageDescriptor,
    errors: JSONApiErrorsPayload["errors"],
    status: number,
  ) {
    super(
      messageDescription.message || messageDescription.id || "",
      errors,
      status,
    );
  }

  public get localizedMessage(): string {
    return i18n._(this.messageDescription);
  }
}
