import { MessageDescriptor } from "@lingui/core";
import { defineMessage } from "@lingui/macro";
import { range } from "./range";

// type DateOptionType = { label: string; value: number };
type GenderOptionType = {
  label: MessageDescriptor;
  value: "female" | "male" | "no_answer" | "other";
};

type SexOptionType = {
  label: MessageDescriptor;
  value: "female" | "male";
};

export const DAYS = range(1, 31).map((n) => ({
  label: String(n),
  value: n,
}));

export const YEARS = range(1922, new Date().getFullYear() - 18)
  .map((n) => ({
    label: String(n),
    value: n,
  }))
  .reverse();

export const SEX_OPTIONS: SexOptionType[] = [
  {
    label: defineMessage({
      message: "Male",
    }),
    value: "male",
  },
  {
    label: defineMessage({
      message: "Female",
    }),
    value: "female",
  },
];

export const GENDERS: GenderOptionType[] = [
  {
    label: defineMessage({
      message: "Male",
    }),
    value: "male",
  },
  {
    label: defineMessage({
      message: "Female",
    }),
    value: "female",
  },
  {
    label: defineMessage({
      message: "Other",
    }),
    value: "other",
  },
  {
    label: defineMessage({
      message: "Prefer not to answer",
    }),
    value: "no_answer",
  },
];

export const DAYS_RECORD = Object.fromEntries(
  DAYS.map((o) => [o.value, o]),
);

export const YEARS_RECORD = Object.fromEntries(
  YEARS.map((o) => [o.value, o]),
);
