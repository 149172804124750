import { LocalizedError } from "../locales/LocalizedError";
import { LocalizedJSONApiErrorsException } from "../locales/LocalizedJSONApiErrorsException";

const renderError = (error: unknown) => {
  if (
    error instanceof LocalizedError ||
    error instanceof LocalizedJSONApiErrorsException
  ) {
    return error.localizedMessage;
  }

  if (error instanceof Error) {
    return error.message;
  }

  return String(error);
};

export default renderError;
