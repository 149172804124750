import { FC, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../../constants";
import Spinner from "../../../lib/Spinner";
import { useUnlockAccount } from "./hooks";

const Wrapper = styled.div`
  padding-top: 40px;
`;

const UnlockScreen: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { mutate: unlockAccount } = useUnlockAccount();

  useEffect(() => {
    unlockAccount(
      {
        unlock_token: searchParams.get("unlock_token") || "",
      },
      {
        onError: () => {
          navigate(`/signin?unlocked=false`);
        },
        onSuccess: () => {
          navigate(`/signin?unlocked=true`);
        },
      },
    );
  }, [navigate, searchParams, unlockAccount]);

  return (
    <Wrapper>
      <Spinner color={colors.brownLightGrey1} size="40px" />
    </Wrapper>
  );
};

export default UnlockScreen;
