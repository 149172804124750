import * as t from "io-ts";
import JSONApiRelationshipsDataC from "./JSONApiRelationshipsData";
import JSONApiRelationshipsLinksC from "./JSONApiRelationshipsLinks";

const JSONApiRelationshipsC = t.union(
  [
    JSONApiRelationshipsDataC,
    JSONApiRelationshipsLinksC,
    t.intersection([
      JSONApiRelationshipsDataC,
      JSONApiRelationshipsLinksC,
    ]),
  ],
  "JSONApiRelationships",
);

export type JSONApiRelationships = t.TypeOf<
  typeof JSONApiRelationshipsC
>;

export default JSONApiRelationshipsC;
