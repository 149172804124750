import * as t from "io-ts";
import {
  DateFromString,
  ISODate,
  NumberFromString,
} from "../../io-ts/types";
import {
  AuthorizationEvent,
  AuthorizationEventRaw,
} from "./AuthorizationEvent";
import { Bill, BillRaw } from "./Bill";
import { Service, ServiceRaw } from "./Service";
// import { UserPlan } from "./UserPlan";

export const AuthorizationC = t.type({
  attributes: t.type({
    bundle_equivalent: NumberFromString,
    expired_at: t.union([t.null, DateFromString]),
    max_rx_coverage: t.union([t.string, t.null, t.undefined]),
    member_uid: t.string,
    oop_span: t.union([t.tuple([t.number, t.number]), t.null]), // [1, 2] or null
    practice_name: t.string,
    practice_template: t.union([
      t.literal("pharmacy"),
      t.literal("default"),
    ]),
    service_date: ISODate,
    title: t.string,
    uid: t.string,
    unread_at: t.union([t.null, DateFromString]),
  }),
  id: t.string,
  type: t.literal("notification"),
});

export type AuthorizationEntity = t.TypeOf<typeof AuthorizationC>;

export interface AuthorizationEntityWithRelations
  extends AuthorizationEntity {
  relationships: {
    services: ServiceRaw[];
    // user_plan: UserPlan;
    bill?: BillRaw;
    events: AuthorizationEventRaw[];
  };
}

export type Authorization = {
  id: string;
  bundleEquivalent: number;
  createdAt: Date;
  expiredAt: Date | null;
  oopSpan: [number, number] | null;
  practiceName: string;
  practiceTemplate: "default" | "pharmacy";
  serviceDate: Date | null;
  services: Service[] | null;
  title: string;
  uid: string;
  url: string;
  bill?: Bill;
  events: AuthorizationEvent[];
  maxRxCoverage: string | null;
};
