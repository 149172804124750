import * as t from "io-ts";
import JSONApiLinkC from "../../JSONApiLink";

const JSONApiRelationshipsSelfLinkC = t.type(
  {
    /**
     * a link for the relationship itself (a “relationship link”). This link allows the client to directly manipulate the relationship.
     */
    self: JSONApiLinkC,
  },
  "JSONApiRelationshipsSelfLink",
);

export type JSONApiRelationshipsSelfLink = t.TypeOf<
  typeof JSONApiRelationshipsSelfLinkC
>;

export default JSONApiRelationshipsSelfLinkC;
