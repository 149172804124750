import { ApplicationLocaleName } from "../config";
import { getPublicLocaleCodes } from "../config/getPublicLocales";
import normalizeLocaleName from "../config/normalizeLanguageName";
import toCommonLocale from "../config/toCommonLocale";

export function getBrowserLanguage(): string {
  return normalizeLocaleName(navigator.language);
}

export function getLocaleFromLocalStorage(): string | null {
  const res = localStorage.getItem("STORK_LANG");

  if (res) {
    return normalizeLocaleName(res);
  }
  return null;
}

export function saveLocaleToLocalStorage(
  locale: ApplicationLocaleName,
) {
  localStorage.setItem("STORK_LANG", locale);
}

export const URL_LOCALE_PARAM = "locale";
export function getLanguageFromURL(): string | null {
  const url = new URL(window.location.href);
  const param = url.searchParams.get(URL_LOCALE_PARAM);

  if (param) {
    return normalizeLocaleName(param);
  }
  return null;
}

export function calculateLocale<L extends string>(
  detectors: ReadonlyArray<() => string | null>,
  availableLocales: readonly L[],
  fallbackRules: {
    [locale: string]: L;
  },
  lastFallbackLocale: L,
): L {
  for (const locale of detectors.map((detector) => detector())) {
    if (!locale) {
      continue;
    }
    // exact match, like en-US
    if (locale && availableLocales.includes(locale as L)) {
      return locale as L;
    }
    // common match, like en
    const commonLocale = locale && toCommonLocale(locale);
    if (commonLocale && commonLocale in fallbackRules) {
      return fallbackRules[commonLocale];
    }
  }
  // no match, fallback to default
  return lastFallbackLocale;
}

/**
 * Not every locale is allowed to be detected.
 *
 * We can allow all the locales by setting VITE_PUBLIC_LOCALES to "*".
 * We can allow a specific regional locale by setting VITE_PUBLIC_LOCALES to "en-US,fr-FR".
 * We can allow a specific common locale by setting VITE_PUBLIC_LOCALES to "en,fr".
 *
 * Common locale can be used as a fallback for regional locales if it enabled by VITE_PUBLIC_LOCALES.
 *
 */
export function applyVisibilityRestrictions(
  locale: string,
  fallbackRules?: Record<string, string>,
): string | null {
  const codes = getPublicLocaleCodes();
  if (codes === true) {
    return locale;
  }
  if (locale && codes.includes(locale)) {
    return locale;
  }
  if (fallbackRules) {
    const commonLocale = toCommonLocale(locale);
    if (!commonLocale) {
      return null;
    }
    if (!codes.includes(commonLocale)) {
      return null;
    }
    if (commonLocale in fallbackRules) {
      return fallbackRules[commonLocale];
    }
  }
  return null;
}
