import React from "react";
import styled, { css } from "styled-components";
import { colors } from "../../constants";
import Markdown from "../../lib/Markdown";
import mediaQuery from "../../utils/mediaQuery";
import { ClinicEntityWithRelations } from "./models/Clinic";

const Container = styled.div`
  display: flex;
  padding: 30px 20px 30px 20px;
  padding-block: 30px;
  padding-inline: 20px;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      padding-block: 30px;
      padding-inline-end: 20px;
      padding-inline-start: 40px;
    `,
  )}
`;

const RoomLeft = styled.div`
  border-inline-end: 1px solid ${colors.brownLightGrey1};
  flex-shrink: 0;
  margin-inline-end: 20px;
  padding-inline-end: 10px;
  width: calc(50% - 15px);
  ${mediaQuery(
    "greaterThanPhone",
    css`
      margin-inline-end: 30px;
      padding-inline-end: 20px;
      width: 150px;
    `,
  )}
`;

const RoomRight = styled.div``;

const Title = styled.h5`
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 12px;
`;
const SubTitle = styled.div`
  font-size: 12px;
  line-height: 20px;
`;

const Body = styled.div`
  font-size: 12px;
  line-height: 20px;
  p {
    font-size: 12px;
    line-height: 20px;
  }
`;

const LayoutRoom: React.FC<ClinicEntityWithRelations> = ({
  attributes,
}: ClinicEntityWithRelations) => (
  <Container>
    <RoomLeft>
      <Title>{attributes.title}</Title>
      <SubTitle>{attributes.subtitle}</SubTitle>
    </RoomLeft>
    <RoomRight>
      <Body>
        <Markdown
          css={`
            color: ${colors.brownBlack};
          `}
          text={attributes.body.toString()}
        />
      </Body>
    </RoomRight>
  </Container>
);

export default LayoutRoom;
