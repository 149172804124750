import * as t from "io-ts";
import { ImageSet } from "../../../io-ts/types";
import { SpokenLanguageCodeC } from "../../../locales/appLocale/config";

export const DoctorC = t.type({
  attributes: t.type({
    biography: t.string,
    certification: t.string,
    community: t.string,
    ethnicity: t.string,
    image_set: t.union([ImageSet, t.null]),
    languages_spoken: t.array(SpokenLanguageCodeC),
    name: t.string,
  }),
  id: t.string,
  type: t.literal("doctor"),
});

export type Doctor = t.TypeOf<typeof DoctorC>;
