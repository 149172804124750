import * as t from "io-ts";

type EmptyArrayType = never[];
const EmptyArrayC = new t.Type<
  EmptyArrayType,
  EmptyArrayType,
  unknown
>(
  "EmptyArray",
  (u): u is EmptyArrayType => {
    return Array.isArray(u) && u.length === 0;
  },
  (u, c) => {
    if (Array.isArray(u) && u.length === 0) {
      return t.success(u as EmptyArrayType);
    }
    return t.failure(u, c, "Not an empty array");
  },
  (a) => a,
);

export default EmptyArrayC;
