import { formatISO, parse, isMatch, startOfDay } from "date-fns";
import { either } from "fp-ts/Either";
import * as t from "io-ts";

/**
 * ISO 8601
 */
export const ISODate = new t.Type(
  "ISODate",
  (v): v is Date => v instanceof Date,
  (input, context) => {
    if (typeof input !== "string") {
      return t.failure(input, context, "Not a string");
    }
    if (!isMatch(input, "yyyy-LL-dd")) {
      return t.failure(input, context, "Incorrect date format");
    }
    return t.success(
      parse(input, "yyyy-LL-dd", startOfDay(new Date())),
    );
  },
  (v) => {
    return formatISO(v, { representation: "date" });
  },
);

//  https://github.com/gcanti/io-ts/blob/master/index.md#custom-types
// represents a Date from an ISO string: 1973-11-29T23:00:00.000Z
export const DateFromString = new t.Type<Date, string, unknown>(
  "DateFromString",
  (u): u is Date => u instanceof Date,
  (u, c) =>
    either.chain(t.string.validate(u, c), (s) => {
      const d = new Date(s);
      return isNaN(d.getTime())
        ? t.failure(u, c, "Incorrect date format")
        : t.success(d);
    }),
  (a) => a.toISOString(),
);

export const HtmlBody = new t.Type(
  "HtmlBody",
  (v): v is string => typeof v === "string",
  (input, context) => {
    if (input === null) {
      return t.success("");
    }
    if (typeof input !== "string") {
      return t.failure(input, context, "Not a string");
    }
    try {
      return t.success(input);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return t.failure(input, context, "Decode error");
    }
  },
  (v) => v.toString(),
);

// null => 0
// "1.0" => 1
// 2 => 2
// "asdf" => error Not a number
export const NumberFromString = new t.Type(
  "NumberFromString",
  (v): v is number => typeof v === "number",
  (input, context) => {
    if (input === null) return t.success(0);
    if (typeof input === "number") return t.success(input);
    if (typeof input === "string") {
      const parsedString = parseFloat(input);
      if (isNaN(parsedString)) {
        return t.failure(input, context, "Not a number");
      }
      return t.success(parsedString);
    }
    return t.failure(input, context, "Not a number");
  },
  (v) => {
    return v.toString();
  },
);

export const ImageSet = t.type(
  {
    aspect_ratio: t.string,
    w1024: t.string,
    w128: t.string,
    w1600: t.string,
    w2048: t.string,
    w256: t.string,
    w512: t.string,
    w64: t.string,
    w800: t.string,
  },
  "ImageSet",
);
