import { Trans, t } from "@lingui/macro";
import React, { FC } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../../constants/colors";
import Link from "../../../lib/Link";
import Spinner from "../../../lib/Spinner";
import renderError from "../../../utils/renderError";
import Layout from "../Layout";
import imgSrc from "../Layout/signin-bg2-a73bb9ef0fc46cb003538bafd272607d.jpeg";
import { ErrorHeader } from "../styles";
import ChangePasswordForm from "./ChangePasswordForm";
import { useCheckRestorePasswordToken } from "./hooks";

const StyledLink = styled(Link)`
  color: ${colors.blueGreen};
  font-size: 20px;
  line-height: 24px;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ChangePasswordScreen: FC = () => {
  const [searchParams] = useSearchParams();
  const resetPasswordToken = searchParams.get("reset_password_token");

  const {
    data: checkResetTokenData,
    isLoading: checkResetTokenIsLoading,
    error: checkResetTokenError,
  } = useCheckRestorePasswordToken(resetPasswordToken || "");

  if (checkResetTokenIsLoading) {
    return (
      <div css="padding-top: 40px">
        <Spinner color={colors.brownLightGrey1} size="40px" />
      </div>
    );
  }

  if (
    checkResetTokenError ||
    !resetPasswordToken ||
    !checkResetTokenData
  ) {
    return (
      <Layout imgSrc={imgSrc}>
        <ErrorContainer>
          <ErrorHeader>
            {checkResetTokenError
              ? renderError(checkResetTokenError)
              : t`The reset link you're trying to use can't be found or expired. Please start again.`}
          </ErrorHeader>
          <div>
            <StyledLink to="/password/new">
              <Trans>Forgot password?</Trans>
            </StyledLink>
          </div>
          <div>
            <StyledLink to="/signin">
              <Trans>Sign in</Trans>
            </StyledLink>
          </div>
        </ErrorContainer>
      </Layout>
    );
  }

  return (
    <Layout imgSrc={imgSrc}>
      <ChangePasswordForm
        email={checkResetTokenData.email}
        passwordMinLength={checkResetTokenData.passwordMinLength}
        resetPasswordToken={resetPasswordToken}
      />
    </Layout>
  );
};

export default ChangePasswordScreen;
