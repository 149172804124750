/* eslint max-lines: 0 */

import React from "react";
import Title from "../../../lib/Title";
import LegalPage, { Part } from "../LegalPage";

const Hipaa: React.FC = () => (
  <LegalPage>
    <Title level="h1">HIPAA Notice and Consent</Title>
    <p>Effective Date: February 9, 2018</p>
    <Part>
      <p>
        This notice describes how medical information about you may be
        used and disclosed and how you can get access to this
        information. Please review it carefully.
      </p>
    </Part>

    <Part>
      <Title level="h2">Stork Club Responsibilities</Title>
      <p>
        Under the Health Insurance Portability and Accountability Act
        of 1996 (HIPAA), Stork Club must take measures to protect the
        privacy of your{" "}
        <strong>“Protected Health Information”</strong> (“PHI”). PHI
        includes information we have created or received regarding
        your health or payment for your health, that may be used to
        identify you, and that relates to (a) your past, present, or
        future physical or mental health or condition, (b) the
        provision of health care to you, or (c) your past, present, or
        future payment for the provision of health care.
      </p>
      <p>Under federal law, we are required to:</p>
      <ul>
        <li>Maintain the privacy and security of your PHI</li>
        <li>
          Let you know promptly if a breach occurs that may have
          compromised the privacy or security of your PHI
        </li>
        <li>
          Follow the duties and privacy practices described in this
          notice
        </li>
        <li>Give you a copy of this notice</li>
      </ul>
      <p>
        In certain cases, state law gives more protection to PHI than
        federal law, and vice versa. In each case, we will apply the
        laws that protect PHI the most.
      </p>
      <p>
        Stork Club will not use or share your Protected Health
        Information other than as described in this notice unless you
        tell us in writing that we can. If you tell us we can, you may
        change your mind at any time. Let us know in writing if you
        change your mind.
      </p>
      <p>
        For more information see:{" "}
        <a href="https://www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/noticepp.html">
          https://www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/noticepp.html
        </a>
        .
      </p>
    </Part>

    <Part>
      <Title level="h2">
        Your Rights Regarding Your Protected Health Information
      </Title>
      <p>You have a right to:</p>
      <ul>
        <li>
          <p>
            <strong>Access your health and claims records</strong>.
            You can ask to see or get a copy of your health and claims
            records and other health information we have about you. To
            do so, send us an email at{" "}
            <a href="mailto:legal@joinstorkclub.com" target="blank">
              legal@joinstorkclub.com
            </a>
            . We will provide a copy or a summary of your health and
            claims records, usually within 30 days of your request. We
            may charge a reasonable, cost-based fee.
          </p>
        </li>
        <li>
          <p>
            <strong>
              Ask us to correct health and claims records
            </strong>
            . You can ask us to correct your health and claims records
            if you think they are incorrect or incomplete. To do so,
            send us an email at{" "}
            <a href="mailto:legal@joinstorkclub.com" target="blank">
              legal@joinstorkclub.com
            </a>
            . We may say “no” to your request, but we’ll tell you why
            in writing within 60 days.
          </p>
        </li>
        <li>
          <p>
            <strong>Request confidential communications</strong>. You
            can ask us to contact you in a specific way (for example,
            home or office phone) or to send mail to a different
            address. We will consider all reasonable requests, and
            must say “yes” if you tell us you would be in danger if we
            do not.
          </p>
        </li>
        <li>
          <p>
            <strong>Ask us to limit what we use or share</strong>. You
            can ask us not to use or share certain health information
            for treatment, payment, or our operations. We are not
            required to agree to your request, and we may say “no” if
            it would affect your care.
          </p>
        </li>
        <li>
          <p>
            <strong>
              Get a list of those with whom we’ve shared information
            </strong>
            . You can ask for a list (accounting) of the times we’ve
            shared your PHI for six years prior to the date you ask,
            who we shared it with, and why. We will include all the
            disclosures except for those about treatment, payment, and
            health care operations, and certain other disclosures
            (such as any you asked us to make). We’ll provide one
            accounting a year for free. If you request another
            accounting during the same year, we may charge you a
            reasonable, cost-based fee.
          </p>
        </li>
        <li>
          <p>
            <strong>Get a copy of this privacy notice</strong>. You
            can ask for a paper copy of this notice at any time, even
            if you have agreed to receive the notice electronically.
            We will provide you with a paper copy promptly.
          </p>
        </li>
        <li>
          <p>
            <strong>Choose someone to act for you</strong>. If you
            have given someone medical power of attorney or if someone
            is your legal guardian, that person can exercise your
            rights and make choices about your PHI. We will make sure
            the person has this authority and can act for you before
            we take any action.
          </p>
        </li>
        <li>
          <p>
            <strong>
              File a complaint if you feel your rights are violated
            </strong>
            . You can complain if you feel we have violated your
            rights by contacting the Privacy Officer at{" "}
            <a href="mailto:legal@joinstorkclub.com" target="blank">
              legal@joinstorkclub.com
            </a>
            . You can file a complaint with the U.S. Department of
            Health and Human Services Office for Civil Rights by
            sending a letter to 200 Independence Avenue, S.W.,
            Washington, D.C. 20201, calling 1-877-696-6775, or
            visiting{" "}
            <a href="https://www.hhs.gov/ocr/privacy/hipaa/complaints/">
              https://www.hhs.gov/ocr/privacy/hipaa/complaints/
            </a>
            . We will not retaliate against you for filing a
            complaint.
          </p>
        </li>
        <li>
          <p>
            <strong>
              For certain health information, you can tell us your
              choices about what we share
            </strong>
            . If you have a clear preference for how we share your
            information in the situations described below, talk to us.
            Tell us what you want us to do, and we will follow your
            instructions.
          </p>
        </li>
      </ul>
      <p>
        You have both the right and choice to tell us to: share
        information with your family, close friends, or others
        involved in payment for your care; or share information in a
        disaster relief situation.
      </p>
      <p>
        If you are not able to tell us your preference – for example,
        if you are unconscious – we may go ahead and share your
        information if we believe it is in your best interest. We may
        also share your information when needed to lessen a serious
        and imminent threat to health or safety.
      </p>
      <p>
        Unless you give us written permission, we may not sell your
        information, or share your information for marketing purposes.
      </p>
    </Part>

    <Part>
      <Title level="h2">
        Our Uses and Disclosures of Your Protected Health Information
      </Title>
      <Title level="h3">
        How does Stork Club typically use or share your PHI?
      </Title>
      <p>We typically use or share your PHI in the following ways.</p>
      <p>
        <strong>
          Help manage the health care treatment you receive
        </strong>
        . We can use your PHI and share it with professionals who are
        treating you. For example, a doctor may send us information
        about your diagnosis and treatment plan so we can arrange
        additional services.
      </p>
      <p>
        <strong>Run our organization</strong>. We can use and disclose
        your information to run our organization and contact you when
        necessary. For example, we may use PHI about you to develop
        better services for you. We are not allowed to use genetic
        information to decide whether we will give you coverage and
        the price of that coverage. This does not apply to long term
        care plans.
      </p>
      <p>
        <strong>Pay for your health services</strong>. We can use and
        disclose your PHI as we pay for your health services. For
        example, we may share information about you with your health
        plan administrator to coordinate payment for health services
        you have received.
      </p>
      <p>
        <strong>Administer your plan</strong>. We may disclose your
        PHI to your health plan sponsor for plan administration.
      </p>
    </Part>

    <Part>
      <Title level="h2">How else can we use or share your PHI?</Title>
      <p>
        We are allowed or required to share your PHI in other ways –
        usually in ways that contribute to the public good, such as
        public health and research. We have to meet many conditions in
        the law before we can share your PHI for these purposes. For
        more information see:{" "}
        <a href="https://www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/index.html">
          https://www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/index.html
        </a>
        .
      </p>
      <p>
        <strong>Help with public health and safety issues</strong>. We
        can share your PHI for certain situations such as:
      </p>
      <ol>
        <li>preventing disease;</li>
        <li>reporting adverse reactions to medications;</li>
        <li>
          reporting suspected abuse, neglect, or domestic violence; or
        </li>
        <li>
          preventing or reducing a serious threat to anyone’s health
          or safety.
        </li>
      </ol>
      <p>
        <strong>Do research</strong>. We can use or share your PHI for
        health research.
      </p>
      <p>
        <strong>Comply with the law</strong>. We will share your PHI
        if state or federal laws require it, including with the
        Department of Health and Human Services if it wants to see
        that we’re complying with federal privacy law.
      </p>
      <p>
        <strong>
          Respond to organ and tissue donation requests and work with
          a medical examiner or funeral director
        </strong>
        . We can share your PHI with organ procurement organizations.
        We can share PHI with a coroner, medical examiner, or funeral
        director when an individual dies.
      </p>
      <p>
        <strong>
          Address workers’ compensation, law enforcement, and other
          government requests
        </strong>
        . We can use or share your PHI: 1) for workers’ compensation
        claims; 2) for law enforcement purposes or with a law
        enforcement official; 3) with health oversight agencies for
        activities authorized by law; 4) for special government
        functions such as military, national security, and
        presidential protective services.
      </p>
      <p>
        <strong>Respond to lawsuits and legal actions</strong>. We can
        share your PHI in response to a court or administrative order,
        or in response to a subpoena.
      </p>
    </Part>

    <Part>
      <Title level="h2">Changes to the Terms of this Notice</Title>
      <p>
        We can change the terms of this notice, and the changes will
        apply to all information we have about you. The new notice
        will be available upon request, on our website, and we will
        mail a copy to you.
      </p>
    </Part>

    <Part>
      <Title level="h2">
        Consent to Share and Release Information
      </Title>
      <p>
        Stork Club Fertility, Inc. (“Stork Club”), as part of
        administering the Stork Club program (the “Services”), may
        have access to and use my personal health information (“PHI”),
        which I provide to Stork Club as part of my participation in
        the Services. Stork Club also manages the Stork Club Community
        (the “Community”), in which I may choose to participate, and
        in so doing may choose to share information about myself. I
        understand that if I so share such information, other
        participants in the Community may also be able to see my
        information, including PHI that I post and/or disclose in the
        course of engaging with the Community.
      </p>
      <p>
        You acknowledge that you have read, understand and agree to
        the terms of the Consent to Share and Release such
        Information.
      </p>
    </Part>

    <Title level="h2">Contact Us</Title>
    <p>
      If you have any questions about this notice, please contact us
      at{" "}
      <a href="mailto:legal@joinstorkclub.com" target="blank">
        legal@joinstorkclub.com
      </a>
      .
    </p>
  </LegalPage>
);

export default Hipaa;
