import * as t from "io-ts";
import { ImageSet } from "../../../io-ts/types";
import { SpokenLanguageCodeC } from "../../../locales/appLocale/config";

export const CareNavigatorC = t.type({
  attributes: t.type({
    body: t.union([t.string, t.null]),
    education: t.union([t.string, t.null]),
    experience: t.union([t.string, t.null]),
    fname: t.string,
    image_set: t.union([ImageSet, t.null]),
    lname: t.string,
    name: t.string,
    role: t.string,
    sign_languages: t.array(t.string),
    spoken_languages: t.array(SpokenLanguageCodeC),
  }),
  id: t.string,
  type: t.literal("call_expert"),
});

export type CareNavigator = t.TypeOf<typeof CareNavigatorC>;
