import * as t from "io-ts";
import { ImageSet } from "../../../../../io-ts/types";

const CareNavigatorC = t.type({
  attributes: t.type({
    image_set: t.union([ImageSet, t.null]),
    name: t.string, // TODO: replace name with "fname" and "lname"
  }),
  id: t.string,
  type: t.literal("call_expert"),
});

export type CareNavigator = t.TypeOf<typeof CareNavigatorC>;

export default CareNavigatorC;
