import { FC } from "react";
import styled, { css } from "styled-components";
import Title from "../../../lib/Title";
import mediaQuery from "../../../utils/mediaQuery";
import ScrollHandler from "../../ScrollHandler";
import { Article } from "../models/Article";
import Preloader from "../Preloader";
import Item from "./Item";
import ItemLarge from "./ItemLarge";
import { SmallItems, SmallItemsRibbon } from "./SmallItems";

const Container = styled.div`
  ${mediaQuery(
    "greaterThanPhone",
    css`
      max-width: 670px;
    `,
  )}
  ${mediaQuery(
    "desktopMedium",
    css`
      max-width: 800px;
    `,
  )}

  & + & {
    margin-top: 30px;
    ${mediaQuery(
      "greaterThanPhone",
      css`
        margin-top: 50px;
      `,
    )}
  }

  &:last-child {
    ${mediaQuery(
      "phone",
      css`
        padding-bottom: 30px;
      `,
    )}
  }

  & > ${Title} {
    margin-bottom: 30px;
  }
`;

type Props = {
  data?: Article[];
  title: string;
  isLoading: boolean;
};

const Items: FC<Props> = ({ data, title, isLoading }: Props) => {
  const [firstItem, ...restItems] = data || [];

  return (
    <Container>
      {isLoading ? (
        <Preloader />
      ) : (
        <>
          {firstItem ? (
            <ScrollHandler key={title}>
              <Title as="h2">{title}</Title>
              <ItemLarge {...firstItem} />

              <SmallItems>
                <SmallItemsRibbon length={restItems.length}>
                  {restItems.map((article) => (
                    <Item {...article} key={article.id} />
                  ))}
                </SmallItemsRibbon>
              </SmallItems>
            </ScrollHandler>
          ) : null}
        </>
      )}
    </Container>
  );
};

export default Items;
