import * as t from "io-ts";

export const LocationC = t.type({
  attributes: t.intersection([
    t.type({
      address: t.string,
      phone: t.type({
        international_format: t.union([t.string, t.null]),
        national_format: t.union([t.string, t.null]),
      }),
    }),
    t.partial({
      approximate_location: t.union([t.string, t.null]),
    }),
  ]),
  id: t.string,
  type: t.literal("location"),
});

export type Location = t.TypeOf<typeof LocationC>;
