import * as t from "io-ts";
import { ISODate } from "../../../../../io-ts/types";
import { BackendLanguageCodeC } from "../../../../../locales/appLocale/config";
import { CareNavigator } from "../CareNavigator";
import {
  CompanyEntityWithRelations,
  CompanyPlan,
  CompanyPlanTypeC,
} from "../Company";

// const SendbirdCredentialsC = t.type({
//   access_token: t.string,
//   app_id: t.string,
//   channel_url: t.string,
//   user_id: t.string,
// });

const ProfileC = t.type({
  attributes: t.intersection([
    t.partial({
      preferred_name: t.string,
    }),
    t.type({
      ask_member_id: t.boolean,
      company_plan_type: t.union([
        CompanyPlanTypeC,
        t.null,
        t.undefined,
      ]),
      dob: ISODate,
      email: t.string,
      fname: t.string,
      gender: t.union([
        t.literal("female"),
        t.literal("male"),
        t.literal("no_answer"),
        t.literal("other"),
      ]),
      lname: t.string,
      member_id: t.union([t.string, t.null]),
      name: t.string,
      notice_member_id_change: t.boolean,
      owner: t.boolean,
      partner: t.boolean,
      password_min_length: t.number,
      personal_email: t.union([t.string, t.null]), // Possibly will change to t.string only in future
      phone: t.union([t.string, t.null]),
      preferred_language: BackendLanguageCodeC,
      // sendbird_credentials: SendbirdCredentialsC,
      sex: t.union([t.literal("female"), t.literal("male")]),
      status: t.union([
        t.literal("planning_later"),
        t.literal("planning_now"),
        t.literal("expecting_a_baby"),
        t.literal("having_a_newborn"),
        t.literal("working_parent"),
        t.literal("menopause"),
        t.literal("mens_optimization"),
        t.literal("gender_affirming_care"),
      ]),
      unconfirmed_email: t.union([t.string, t.null]),
      zip: t.string,
    }),
  ]),
  id: t.string,
  type: t.literal("user"),
});

const UpdateProfileC = t.intersection([
  t.partial({
    company_plan_id: t.string,
    company_plan_type: t.string,
    dob: ISODate,
    email: t.string,
    gender: t.union([
      t.literal("female"),
      t.literal("male"),
      t.literal("no_answer"),
      t.literal("other"),
    ]),
    personal_email: t.string, // Not t.union([t.string, t.null]) because empty input outputs empty string
    preferred_name: t.string,
    skip_member_id_update: t.boolean,
    zip: t.string,
  }),
  t.type({ member_id: t.string }),
]);

const UpdateProfilePasswordC = t.type({
  current_password: t.string,
  password: t.string,
  password_confirmation: t.string,
});

export type ProfileEntity = t.TypeOf<typeof ProfileC>;

export interface ProfileEntityWithRelations extends ProfileEntity {
  relationships: {
    company: CompanyEntityWithRelations;
    care_navigator: CareNavigator;
    companyPlan?: CompanyPlan;
  };
}

export type UpdateProfileEntity = t.TypeOf<typeof UpdateProfileC>;

export type UpdatePasswordEntity = t.TypeOf<
  typeof UpdateProfilePasswordC
>;

export default ProfileC;
