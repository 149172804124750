import { FC, SVGAttributes } from "react";

const Info: FC<SVGAttributes<SVGElement>> = ({ ...restProps }) => {
  return (
    <svg
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <circle cx="13" cy="13" fill="white" id="Oval" r="13" />
      <path
        d="M16.4,6.75706714 C16.4,7.23763491 16.2351271,7.65061665 15.9053763,7.99602473 C15.5756256,8.34143282 15.1813643,8.51413428 14.7225806,8.51413428 C14.263797,8.51413428 13.8695357,8.34143282 13.5397849,7.99602473 C13.2100342,7.65061665 13.0451613,7.23763491 13.0451613,6.75706714 C13.0451613,6.26899049 13.2100342,5.85413157 13.5397849,5.51247792 C13.8695357,5.17082426 14.263797,5 14.7225806,5 C15.1813643,5 15.5756256,5.17082426 15.9053763,5.51247792 C16.2351271,5.85413157 16.4,6.26899049 16.4,6.75706714 Z M14.883871,16.9251325 L15.9698925,16.9251325 C15.0523252,18.9750544 13.7691839,20 12.1204301,20 C11.4537601,20 10.951973,19.8535792 10.6150538,19.5607332 C10.2781345,19.2678872 10.1096774,18.8323792 10.1096774,18.2541961 C10.1096774,17.9162969 10.249461,17.3231049 10.5290323,16.4746025 L11.8193548,12.5887809 C11.9197138,12.2884261 11.9698925,12.0931983 11.9698925,12.0030919 C11.9698925,11.9129854 11.9501794,11.8472838 11.9107527,11.805985 C11.871326,11.7646862 11.8086026,11.7440371 11.7225806,11.7440371 C11.3569874,11.7440371 10.9268842,12.2321064 10.4322581,13.2082597 L9.4,13.2082597 C10.3247358,11.1132846 11.6293823,10.0658127 13.3139785,10.0658127 C13.9089635,10.0658127 14.3713245,10.2178651 14.7010753,10.5219744 C15.030826,10.8260837 15.1956989,11.244697 15.1956989,11.7778269 C15.1956989,12.1682882 15.048747,12.7877608 14.7548387,13.6362633 L13.5075269,17.1954505 C13.3283145,17.6985449 13.2387097,18.0139128 13.2387097,18.1415636 C13.2387097,18.2241612 13.2620069,18.2879856 13.3086022,18.3330389 C13.3551974,18.3780921 13.4215049,18.4006184 13.5075269,18.4006184 C13.6939077,18.4006184 13.9322566,18.2316713 14.2225806,17.8937721 C14.5129047,17.5558729 14.7333326,17.2329962 14.883871,16.9251325 Z"
        fill="currentColor"
        id="i"
      />
    </svg>
  );
};

export default Info;
