import { Link as BaseLink } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../constants";

const Link = styled(BaseLink)`
  color: ${colors.blue};
  font-style: normal;
  font-weight: normal;
  &:hover {
    color: ${colors.blueHover};
  }
`;

export default Link;
