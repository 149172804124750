import { FC, SVGAttributes } from "react";
import * as React from "react";
import styled, { keyframes } from "styled-components";
import { BaseIconAttributes } from "./types";

const loadingCircle = keyframes`
  to {
    transform: rotate(360deg)
  }
`;

type SpinnerProps = SVGAttributes<SVGElement>;

const Svg = styled.svg<SpinnerProps>`
  animation: ${loadingCircle} 1s infinite linear;
`;

export const Spinner: FC<BaseIconAttributes> = ({
  width = "1em",
  height = "1em",
  mainColor = "currentColor",
  ...restProps
}) => (
  <Svg
    {...restProps}
    fill="none"
    height={height}
    viewBox="0 0 22 23"
    width={width}
  >
    <path
      d="M11 0.5C13.0874 0.5 15.1317 1.09392 16.8941 2.21239C18.6565 3.33086 20.0643 4.92771 20.9531 6.81643C21.8419 8.70514 22.1749 10.8077 21.9133 12.8787C21.6516 14.9496 20.8062 16.9033 19.4756 18.5117L17.611 16.9691C18.6488 15.7146 19.3083 14.1907 19.5123 12.5754C19.7164 10.96 19.4566 9.32001 18.7634 7.84681C18.0702 6.37362 16.9721 5.12807 15.5974 4.25567C14.2227 3.38326 12.6282 2.92 11 2.92L11 0.5Z"
      fill={mainColor}
    />
  </Svg>
);
