import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { t, Trans } from "@lingui/macro";
import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReCAPTCHABase from "react-google-recaptcha";
import { Controller, useForm } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";
import styled, { css } from "styled-components";
import * as yup from "yup";
import ReCAPTCHA from "../../../../components/ReCAPTCHA";
import { colors } from "../../../../constants";
import Alert from "../../../../lib/Alert";
import FormField from "../../../../lib/forms/FormField";
import Input from "../../../../lib/forms/primitives/Input";
import PageTitle from "../../../../lib/PageTitle";
import { useHandleSubmitImpl } from "../../../../utils/forms";
import mediaQuery from "../../../../utils/mediaQuery";
import renderError from "../../../../utils/renderError";
import { isValidEmail } from "../../../../utils/validateString";
import Info from "../../Info";
import { Header, StyledButton } from "../../styles";
import { Strategy, useCheckEmail } from "./hooks";

const getResolver = () => {
  const FormSchema = yup.object().shape({
    email: yup
      .string()
      .email(() => t`Invalid email`)
      .required(() => t`Email required`),
  });

  return yupResolver(FormSchema);
};

const StyledAlert = styled(Alert)``;
const ActivateLink = styled(Alert)`
  color: #4c4c4c;
  display: block;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 30px;
  ${mediaQuery(
    "tablet",
    css`
      margin-bottom: 60px;
    `,
  )}
  ${mediaQuery(
    "desktopSmall",
    css`
      margin-bottom: 40px;
    `,
  )}
    ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 60px;
    `,
  )}
`;
const StyledForm = styled.form`
  ${mediaQuery(
    "desktopMedium",
    css`
      max-width: 355px;
    `,
  )}
  ${StyledButton} {
    margin-top: 30px;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${StyledAlert} {
    display: block;
    margin-bottom: 8px;
  }

  ${Header} {
    margin-bottom: 8px;
  }
`;

interface FormProps {
  email: string;
}

export interface CheckEmailFormProps {
  initialEmail: string;
  onSuccess: (email: string, strategies: Strategy[]) => void;
  errorMessage?: string;
}

const CheckEmailForm: FC<CheckEmailFormProps> = (props) => {
  const [searchParams] = useSearchParams();
  const unlockAccountStatus = searchParams.get("unlocked");
  const [emailChanged] = useState(
    () => sessionStorage.getItem("emailChanged") === "true",
  );

  useEffect(() => {
    // There is a race condition somewhere in the code, so we can not detect if the component is rendered for the first time.
    // This hack should be enough in most cases. (still not 100% reliable)
    // I'm sure we will refactor this component soon, so it is fine for now.
    const int = setTimeout(() => {
      sessionStorage.removeItem("emailChanged");
    }, 1000);
    return () => {
      clearTimeout(int);
    };
  }, []);

  const { initialEmail, errorMessage, onSuccess } = props;

  const {
    data: strategies,
    isLoading,
    error: externalError,
    mutate: checkEmail,
  } = useCheckEmail();

  const resolver = useMemo(() => getResolver(), []);
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormProps>({
    defaultValues: {
      email: initialEmail,
    },

    resolver,
  });

  const recaptchaRef = useRef<ReCAPTCHABase>(null);

  const onSubmit = useCallback(
    ({
      email,
      recaptchaToken,
    }: {
      email: string;
      recaptchaToken: string;
    }) => {
      checkEmail(
        { email, recaptchaToken },
        {
          onSuccess: (data) => {
            onSuccess(email, data);
          },
        },
      );
    },
    [checkEmail, onSuccess],
  );

  const { handler: handleSubmitImpl, error: internalError } =
    useHandleSubmitImpl(handleSubmit, onSubmit, recaptchaRef);
  const error = externalError || internalError;

  const isEmailFound = !!strategies && strategies.length > 0;

  return (
    <Container>
      {emailChanged && (
        <StyledAlert status="success">
          <Trans>
            Your email has been changed successfully. <br />
            Please sign in using your new email to continue.
          </Trans>
        </StyledAlert>
      )}
      {unlockAccountStatus === "true" && (
        <StyledAlert status="success">
          <Trans>
            Your account has been unlocked successfully. Please sign
            in to continue.
          </Trans>
        </StyledAlert>
      )}
      {unlockAccountStatus === "false" && (
        <StyledAlert status="error">
          <Trans>This token is invalid already.</Trans>
        </StyledAlert>
      )}
      {isEmailFound && (
        <StyledAlert status="error">
          <Trans>Email not found</Trans>
        </StyledAlert>
      )}
      {errorMessage && (
        <StyledAlert status="error">
          {String(errorMessage)}
        </StyledAlert>
      )}
      {!!error && (
        <StyledAlert status="error">{renderError(error)}</StyledAlert>
      )}
      <PageTitle>Sign in</PageTitle>
      <Header>
        <Trans>Sign in</Trans>
      </Header>

      <ActivateLink status="info">
        <Trans>New to Stork Club?</Trans>{" "}
        <Controller
          control={control}
          name="email"
          render={({ field: { value } }) => {
            const email = isValidEmail(value) ? value : undefined;
            return (
              <Trans>
                <Link
                  css={`
                    color: #2d4f61 !important;
                    white-space: nowrap;
                  `}
                  state={{
                    email,
                  }}
                  to="/signup"
                >
                  Activate membership
                </Link>
              </Trans>
            );
          }}
        />
      </ActivateLink>
      <StyledForm onSubmit={handleSubmitImpl}>
        <FormField
          error={errors.email?.message}
          hideLabel
          label={t`Email`}
        >
          <Input
            {...register("email")}
            $activeColor={colors.blueGreen}
            autoComplete="username"
            placeholder={t`Email`}
            type="email"
          />
        </FormField>
        <StyledButton
          disabled={isLoading}
          kind="filledBlueGreen"
          type="submit"
        >
          <Trans>Next</Trans>
        </StyledButton>
      </StyledForm>
      <ReCAPTCHA ref={recaptchaRef} />
      <Info />
    </Container>
  );
};

export default CheckEmailForm;
