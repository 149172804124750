import styled from "styled-components";

export const DropdownIndicatorNetwork = styled.div<{
  $isDisabled?: boolean;
}>`
  box-sizing: border-box;
  color: ${({ $isDisabled }) =>
    $isDisabled ? "#C4C4C4" : "#909090"};
  display: flex;
  padding: 18px;
`;

export const DropdownIndicatorNetworkIcon = () => (
  <svg
    fill="none"
    height="10"
    viewBox="0 0 16 10"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M8.29136 6.73244L14.586 0.439453L16 1.85385L8.29099 9.56086L0.585787 1.85367L2.00018 0.439636L8.29136 6.73244Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
