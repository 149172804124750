msgid ""
msgstr ""
"POT-Creation-Date: 2022-10-18 16:00+0600\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=utf-8\n"
"Content-Transfer-Encoding: 8bit\n"
"X-Generator: @lingui/cli\n"
"Language: en\n"
"Project-Id-Version: \n"
"Report-Msgid-Bugs-To: \n"
"PO-Revision-Date: \n"
"Last-Translator: \n"
"Language-Team: \n"
"Plural-Forms: \n"

#: src/calls/utils/userfriendlyerror.ts:27
msgid "1. Please enable the microphone in the browser settings.<br>2. Please connect a microphone to your computer and verify it is enabled in your system settings."
msgstr "1. Please enable the microphone in the browser settings.<br>2. Please connect a microphone to your computer and verify it is enabled in your system settings."

#: src/calls/utils/userfriendlyerror.ts:23
msgid "1. Please reload this page and grant permission to access the microphone.<br>2. Please allow access to the microphone in the browser settings and reload this page."
msgstr "1. Please reload this page and grant permission to access the microphone.<br>2. Please allow access to the microphone in the browser settings and reload this page."

#: src/calls/utils/userfriendlyerror.ts:22
msgid "1. The user has denied permission for your app to access the input device either by dismissing the permission dialog or clicking on the \"deny\" button.<br> 2. The user has denied permission for your app to access the input device in the browser settings."
msgstr "1. The user has denied permission for your app to access the input device either by dismissing the permission dialog or clicking on the \"deny\" button.<br> 2. The user has denied permission for your app to access the input device in the browser settings."

#: src/calls/utils/userfriendlyerror.ts:26
msgid "1. The user has disabled the input device for the browser in the system settings.<br>2. The user's machine does not have such input device connected to it."
msgstr "1. The user has disabled the input device for the browser in the system settings.<br>2. The user's machine does not have such input device connected to it."

#: src/authentication/components/SignInScreen/CheckEmailForm/index.tsx:199
msgid "<0>Activate membership</0>"
msgstr "<0>Activate membership</0>"

#: src/components/CallBooking/Disclaimer.tsx:33
msgid "<0>Disclaimer: </0>Meetings with Stork Club experts are for educational and informational purposes only. By scheduling a meeting..."
msgstr "<0>Disclaimer: </0>Meetings with Stork Club experts are for educational and informational purposes only. By scheduling a meeting..."

#: src/notifications/components/Widget/NothingYetWidget.tsx:130
msgid "<0>Download Stork Club App</0> in the <1>Apple App Store</1> or <2>Google Play</2> to receive notifications on your phone directly"
msgstr "<0>Download Stork Club App</0> in the <1>Apple App Store</1> or <2>Google Play</2> to receive notifications on your phone directly"

#: src/components/Payment/index.tsx:442
msgid "<0>Privacy</0>"
msgstr "<0>Privacy</0>"

#: src/components/Payment/index.tsx:437
msgid "<0>Terms</0>"
msgstr "<0>Terms</0>"

#: src/reimbursements/components/Reimbursement/HeaderTitle.tsx:129
msgid "<0>approved</0> expense reimbursement"
msgstr "<0>approved</0> expense reimbursement"

#: src/reimbursements/components/Reimbursement/HeaderTitle.tsx:105
msgid "<0>canceled</0> expense reimbursement"
msgstr "<0>canceled</0> expense reimbursement"

#: src/reimbursements/components/Reimbursement/HeaderTitle.tsx:117
msgid "<0>rejected</0> expense reimbursement"
msgstr "<0>rejected</0> expense reimbursement"

#: src/calls/components/ScreenCall/index.tsx:191
msgid "<0>{0}</0> has disconnected"
msgstr "<0>{0}</0> has disconnected"

#: src/calls/components/ScreenCall/index.tsx:172
msgid "<0>{0}</0> has no video"
msgstr "<0>{0}</0> has no video"

#: src/calls/components/ScreenCall/index.tsx:181
msgid "<0>{0}</0> hasn't started the call yet"
msgstr "<0>{0}</0> hasn't started the call yet"

#: src/components/PageLayout/Footer/index.tsx:132
msgid "<0>© Stork Club Fertility, Inc.</0>"
msgstr "<0>© Stork Club Fertility, Inc.</0>"

#: src/components/Payment/index.tsx:394
msgid "ACH payments are less expensive than credit card transactions so we can avoid charging you any fee"
msgstr "ACH payments are less expensive than credit card transactions so we can avoid charging you any fee"

#: src/authentication/components/ActivateMembershipScreen/index.tsx:164
msgid "Activate your membership"
msgstr "Activate your membership"

#: src/authentication/components/onboarding/MemberIdScreen/index.tsx:343
#: src/components/ProfilePage/Profile/index.tsx:254
msgid "Add"
msgstr "Add"

#: src/reimbursement/components/ReimbursementForm/ExpensesBlock/index.tsx:174
msgid "Add expense"
msgstr "Add expense"

#: src/components/ProfilePage/Profile/index.tsx:270
#: src/components/ProfilePage/Profile/index.tsx:367
msgid "Add in edit mode"
msgstr "Add in edit mode"

#: src/components/ProfilePage/AddPartner/index.tsx:176
#: src/components/ProfilePage/index.tsx:101
msgid "Add partner"
msgstr "Add partner"

#: src/authentication/components/onboarding/MemberIdScreen/index.tsx:276
msgid "Add your Member ID"
msgstr "Add your Member ID"

#: src/components/ProfilePage/AddPartner/index.tsx:180
msgid "All eligible employees, regardless of their age, race, sexual orientation, martial status, and fertility health condition, may register for access. Eligible adult dependents, such as spouses and civil partners, may also be granted access to utilize this benefit, subject to the employee’s lifetime maximum. If you would like to share access to this benefit with an eligible loved one, please submit below."
msgstr "All eligible employees, regardless of their age, race, sexual orientation, martial status, and fertility health condition, may register for access. Eligible adult dependents, such as spouses and civil partners, may also be granted access to utilize this benefit, subject to the employee’s lifetime maximum. If you would like to share access to this benefit with an eligible loved one, please submit below."

#: src/authentication/components/ActivateMembershipScreen/index.tsx:167
msgid "Already activated? <0>Sign in</0>"
msgstr "Already activated? <0>Sign in</0>"

#: src/reimbursement/components/ReimbursementForm/ExpensesBlock/ExpensesItem/index.tsx:160
msgid "Amount"
msgstr "Amount"

#: src/calls/utils/userfriendlyerror.ts:31
msgid "Another app is using the microphone. Please close all apps and tabs before restarting the browser and opening this page again."
msgstr "Another app is using the microphone. Please close all apps and tabs before restarting the browser and opening this page again."

#: src/components/MobileApp/MobileAppButton.tsx:42
msgid "App Store"
msgstr "App Store"

#: src/calls/components/_/SelectAudioDevice.tsx:186
#: src/calls/components/_/SelectVideoDevice.tsx:156
msgid "Apply"
msgstr "Apply"

#: src/reimbursements/components/Reimbursement/Body/Services.tsx:102
msgid "Approved"
msgstr "Approved"

#: src/components/articles/ArticlesSearch/index.tsx:30
#: src/components/articles/Header/index.tsx:79
#: src/components/articles/ListPage/index.tsx:17
#: src/components/PageLayout/Sidebar/index.tsx:315
msgid "Articles"
msgstr "Articles"

#: src/components/HomeScreen/components/CallSubjects/index.tsx:31
msgid "Ask a vetted expert"
msgstr "Ask a vetted expert"

#: src/lib/forms/primitives/FileInput/index.tsx:185
msgid "Attached"
msgstr "Attached"

#: src/notifications/components/AuthorizationGroup/index.tsx:190
msgid "Auth ID"
msgstr "Auth ID"

#: src/reimbursement/components/ReimbursementPage/index.tsx:128
msgid "Back"
msgstr "Back"

#: src/components/Payment/index.tsx:218
#: src/components/ProfileCompletion/index.tsx:130
msgid "Back to Notifications"
msgstr "Back to Notifications"

#: src/components/Payment/index.tsx:371
#: src/notifications/components/Notification/Body/BillDetails.tsx:164
msgid "Balance due"
msgstr "Balance due"

#: src/care-navigator/CareNavigatorScreen/Channel/MessagesList/ListTop/index.tsx:31
msgid "Beginning of the Conversation"
msgstr "Beginning of the Conversation"

#: src/notifications/components/Notification/Body/BillDetails.tsx:115
msgid "Bill"
msgstr "Bill"

#: src/components/Payment/index.tsx:347
msgid "Bill # <0>{0}</0>"
msgstr "Bill # <0>{0}</0>"

#: src/notifications/components/Notification/HeaderTitle.tsx:219
msgid "Bill #{sequentialId} has been <0>paid</0>"
msgstr "Bill #{sequentialId} has been <0>paid</0>"

#: src/notifications/components/Notification/HeaderTitle.tsx:153
#: src/notifications/components/Notification/HeaderTitle.tsx:166
msgid "Bill #{sequentialId} is <0>due </0>"
msgstr "Bill #{sequentialId} is <0>due </0>"

#: src/notifications/components/Notification/HeaderTitle.tsx:179
msgid "Bill #{sequentialId} is <0>due soon </0>"
msgstr "Bill #{sequentialId} is <0>due soon </0>"

#: src/notifications/components/Notification/HeaderTitle.tsx:192
msgid "Bill #{sequentialId} is now <0>overdue </0>"
msgstr "Bill #{sequentialId} is now <0>overdue </0>"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:602
#: src/components/ProfilePage/Profile/index.tsx:276
msgid "Biological sex"
msgstr "Biological sex"

#: src/components/company-programs/LayoutClinic.tsx:234
msgid "Book Appointment"
msgstr "Book Appointment"

#: src/notifications/components/Notification/Body/BodyRxCanceled.tsx:41
#: src/notifications/components/Notification/Body/BodyRxGranted.tsx:106
#: src/notifications/components/Notification/Body/BodyRxRejected.tsx:36
#: src/notifications/components/Notification/Body/BodyRxRequested.tsx:66
msgid "By <0>Stork Club Rx ({0})</0>"
msgstr "By <0>Stork Club Rx ({0})</0>"

#: src/notifications/components/Notification/Body/BodyBlank.tsx:15
#: src/notifications/components/Notification/Body/BodyService.tsx:110
msgid "By <0>{practiceName}</0> for <1>{title}</1>"
msgstr "By <0>{practiceName}</0> for <1>{title}</1>"

#: src/authentication/components/onboarding/ConsentToSharing/index.tsx:215
msgid "By checking this box, you confirm that you have read,\nunderstood and consent to the use of your\ninformation as described."
msgstr "By checking this box, you confirm that you have read,\nunderstood and consent to the use of your\ninformation as described."

#: src/calls/components/_/SelectVideoDevice.tsx:137
#: src/calls/components/ScreenCheck/index.tsx:229
msgid "Camera"
msgstr "Camera"

#: src/components/CallBooking/Experts/ExpertCard/index.tsx:176
msgid "Can help you with"
msgstr "Can help you with"

#: src/calls/components/_/ExtendCall.tsx:144
#: src/components/CallSubjects/Call.tsx:209
#: src/components/company-programs/BookAppointmentForm.tsx:192
msgid "Cancel"
msgstr "Cancel"

#: src/reimbursements/components/Reimbursement/Body/Services.tsx:91
msgid "Cancel request"
msgstr "Cancel request"

#: src/components/Payment/index.tsx:167
msgid "Cannot process the payment at the moment. Please try again later"
msgstr "Cannot process the payment at the moment. Please try again later"

#: src/care-navigator/CareNavigatorScreen/Channel/MessagesList/Message/index.tsx:187
#: src/components/PageLayout/Sidebar/index.tsx:295
msgid "Care Navigator"
msgstr "Care Navigator"

#: src/care-navigator/CareNavigatorScreen/Channel/MessagesList/Message/index.tsx:187
#: src/components/PageLayout/Sidebar/index.tsx:295
msgid "Care Partner"
msgstr "Care Partner"

#: src/components/CallSubjects/Tab.tsx:79
msgid "Certification and official confirmation of all necessary training"
msgstr "Certification and official confirmation of all necessary training"

#: src/components/ProfilePage/Profile/ChangeLanguageForm/index.tsx:170
#: src/components/ProfilePage/Profile/index.tsx:147
#: src/components/ProfilePage/Profile/index.tsx:341
msgid "Change"
msgstr "Change"

#: src/components/ChangeFamilyStatus/index.tsx:302
msgid "Change current family status?"
msgstr "Change current family status?"

#: src/calls/components/ScreenCheck/index.tsx:221
msgid "Change device"
msgstr "Change device"

#: src/authentication/components/SignInScreen/ChangeEmailBlock/index.tsx:56
msgid "Change email"
msgstr "Change email"

#: src/authentication/components/ChangePasswordScreen/index.tsx:200
#: src/authentication/components/ChangePasswordScreen/index.tsx:241
#: src/components/ProfilePage/Profile/ChangePasswordForm/index.tsx:194
msgid "Change password"
msgstr "Change password"

#: src/components/CallBooking/index.tsx:422
msgid "Change time"
msgstr "Change time"

#: src/components/HomeScreen/components/ChangeFamilyStatus/index.tsx:314
msgid "Change your current status?"
msgstr "Change your current status?"

#. js-lingui-explicit-id
#: src/care-navigator/ChatInvitation/index.tsx:74
msgid "ChatInvitationLetter"
msgstr "E"

#: src/authentication/components/SignInScreen/TwoFactorCodeForm/index.tsx:186
msgid "Check code"
msgstr "Check code"

#: src/calls/utils/userfriendlyerror.ts:39
msgid "Check if your microphone is connected in the system or browser settings."
msgstr "Check if your microphone is connected in the system or browser settings."

#: src/care-navigator/CareNavigatorScreen/SendBirdCantConnect.tsx:80
msgid "Check your data or wifi connection and try again.<0/>If this error persists, please contact us at <1>care@joinstorkclub.com</1>"
msgstr "Check your data or wifi connection and try again.<0/>If this error persists, please contact us at <1>care@joinstorkclub.com</1>"

#: src/authentication/components/ActivateMembershipScreen/index.tsx:216
msgid "Check your email"
msgstr "Check your email"

#: src/components/CallBooking/index.tsx:181
#: src/components/CallBooking/index.tsx:218
msgid "Choose an expert"
msgstr "Choose an expert"

#: src/authentication/components/onboarding/StatusScreen/index.tsx:215
msgid "Choose your current family status"
msgstr "Choose your current family status"

#: src/authentication/components/onboarding/StatusScreen/index.tsx:138
msgid "Choose your current status"
msgstr "Choose your current status"

#: src/authentication/components/SignInScreen/TwoFactorCodeForm/index.tsx:35
msgid "Code must be 6 characters"
msgstr "Code must be 6 characters"

#: src/authentication/components/SignInScreen/TwoFactorCodeForm/index.tsx:36
msgid "Code required"
msgstr "Code required"

#: src/components/company-programs/LayoutDoula.tsx:205
msgid "Communities supported"
msgstr "Communities supported"

#: src/components/company-policies/index.tsx:49
#: src/components/PageLayout/Sidebar/index.tsx:322
msgid "Company policies"
msgstr "Company policies"

#. js-lingui-explicit-id
#: src/network/jsonApiV2/models/specific/Company/index.tsx:13
msgid "CompanyPlanType.family"
msgstr "Family"

#. js-lingui-explicit-id
#: src/network/jsonApiV2/models/specific/Company/index.tsx:17
msgid "CompanyPlanType.plus_one"
msgstr "Member plus one"

#. js-lingui-explicit-id
#: src/network/jsonApiV2/models/specific/Company/index.tsx:9
msgid "CompanyPlanType.single"
msgstr "Single"

#: src/notifications/components/Notification/Body/Responsibility/Incomplete.tsx:214
msgid "Complete profile"
msgstr "Complete profile"

#: src/components/ProfileCompletion/Form.tsx:115
msgid "Complete your profile"
msgstr "Complete your profile"

#: src/authentication/components/onboarding/ConsentToSharing/index.tsx:228
msgid "Confirm"
msgstr "Confirm"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:65
msgid "Confirm Password is a required field"
msgstr "Confirm Password is a required field"

#: src/authentication/components/ChangePasswordScreen/index.tsx:225
#: src/authentication/components/SignUpScreen/SignUpForm.tsx:745
msgid "Confirm password"
msgstr "Confirm password"

#: src/components/HomeScreen/components/Block/index.tsx:152
msgid "Connect with HR and your supervisor to make sure you're on the same page."
msgstr "Connect with HR and your supervisor to make sure you're on the same page."

#: src/components/Payment/index.tsx:418
msgid "Connect your bank and pay now"
msgstr "Connect your bank and pay now"

#: src/calls/components/ScreenCall/index.tsx:140
msgid "Connecting..."
msgstr "Connecting..."

#: src/authentication/components/onboarding/ConsentToSharing/index.tsx:170
msgid "Consent for data use"
msgstr "Consent for data use"

#: src/authentication/components/onboarding/StatusScreen/index.tsx:262
#: src/components/CallBooking/index.tsx:378
#: src/components/CallBooking/index.tsx:436
msgid "Continue"
msgstr "Continue"

#: src/care-navigator/CareNavigatorScreen/index.tsx:107
#: src/care-navigator/CareNavigatorScreen/index.tsx:109
msgid "Conversation with Care Navigator"
msgstr "Conversation with Care Navigator"

#: src/care-navigator/CareNavigatorScreen/index.tsx:107
#: src/care-navigator/CareNavigatorScreen/index.tsx:109
msgid "Conversation with Care Partner"
msgstr "Conversation with Care Partner"

#: src/components/CookieYesButton/index.tsx:39
msgid "Cookie Settings"
msgstr "Cookie Settings"

#: src/notifications/components/Notification/Body/Responsibility/CostSharing.tsx:99
#: src/notifications/components/Notification/Body/Responsibility/CostSharing.tsx:137
#: src/notifications/components/Notification/Body/Responsibility/IndividualCost.tsx:128
msgid "Copay"
msgstr "Copay"

#: src/notifications/components/Notification/Body/Responsibility/CostSharing.tsx:70
#: src/notifications/components/Notification/Body/Responsibility/CostSharing.tsx:96
#: src/notifications/components/Notification/Body/Responsibility/CostSharing.tsx:124
#: src/notifications/components/Notification/Body/Responsibility/Incomplete.tsx:244
msgid "Cost-sharing"
msgstr "Cost-sharing"

#: src/calls/utils/userfriendlyerror.ts:42
msgid "Could not satisfy the requested media constraints. One of the reasons could be that your saved microphone or camera is no longer available."
msgstr "Could not satisfy the requested media constraints. One of the reasons could be that your saved microphone or camera is no longer available."

#: src/care-navigator/CareNavigatorScreen/Channel/MessagesList/Message/index.tsx:223
msgid "Could not send message"
msgstr "Could not send message"

#: src/care-navigator/CareNavigatorScreen/SendBirdCantConnect.tsx:76
msgid "Couldn't load messages"
msgstr "Couldn't load messages"

#: src/care-navigator/CareNavigatorScreen/Channel/MessagesList/Message/CouldNotSend/index.tsx:74
msgid "Couldn't send the message."
msgstr "Couldn't send the message."

#: src/authentication/components/SignUpScreen/index.tsx:294
msgid "Countries are defined by your employer"
msgstr "Countries are defined by your employer"

#: src/authentication/components/SignUpScreen/index.tsx:293
#: src/authentication/components/SignUpScreen/index.tsx:297
#: src/authentication/components/SignUpScreen/index.tsx:317
msgid "Country"
msgstr "Country"

#: src/notifications/components/Notification/Body/CoveredTable.tsx:45
msgid "Covered"
msgstr "Covered"

#: src/components/company-programs/index.tsx:133
#: src/components/company-programs/ProgramsListPage.tsx:66
#: src/components/company-programs/ProgramsListPage.tsx:68
msgid "Covered Programs"
msgstr "Covered Programs"

#: src/notifications/components/Notification/Body/BillDetails.tsx:155
msgid "Covered by Health Plan"
msgstr "Covered by Health Plan"

#: src/components/CallSubjects/Subject.tsx:141
msgid "Covered by employer"
msgstr "Covered by employer"

#: src/components/PageLayout/Sidebar/index.tsx:333
msgid "Covered programs"
msgstr "Covered programs"

#: src/authentication/components/ChangePasswordScreen/index.tsx:212
#: src/authentication/components/SignUpScreen/SignUpForm.tsx:732
msgid "Create a password"
msgstr "Create a password"

#: src/components/ProfilePage/Profile/ChangePasswordForm/index.tsx:154
#: src/components/ProfilePage/Profile/ChangePasswordForm/index.tsx:159
msgid "Current password"
msgstr "Current password"

#: src/components/ProfilePage/EditProfile/index.tsx:320
msgid "Date of Birth"
msgstr "Date of Birth"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:643
#: src/components/ProfilePage/Profile/index.tsx:306
msgid "Date of birth"
msgstr "Date of birth"

#: src/reimbursement/components/ReimbursementForm/ExpensesBlock/ExpensesItem/index.tsx:150
msgid "Date of service"
msgstr "Date of service"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:681
#: src/authentication/components/SignUpScreen/SignUpForm.tsx:688
#: src/components/ProfilePage/EditProfile/index.tsx:363
#: src/components/ProfilePage/EditProfile/index.tsx:369
msgid "Day"
msgstr "Day"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:70
msgid "Day is a required field"
msgstr "Day is a required field"

#: src/notifications/components/Notification/Body/Responsibility/CostSharing.tsx:129
#: src/notifications/components/Notification/Body/Responsibility/IndividualCost.tsx:105
msgid "Deductible"
msgstr "Deductible"

#: src/notifications/components/Notification/Body/Deductible.tsx:60
msgid "Deductible and coinsurance"
msgstr "Deductible and coinsurance"

#: src/notifications/components/Notification/Body/BodyRxGranted.tsx:116
#: src/notifications/components/Notification/Body/BodyRxRequested.tsx:76
msgid "Delivery no later than <0>{0}</0>"
msgstr "Delivery no later than <0>{0}</0>"

#: src/notifications/components/Notification/Body/BodyBillAvailable.tsx:114
#: src/notifications/components/Notification/Body/BodyBillPaymentFailed.tsx:63
msgid "Due date"
msgstr "Due date"

#: src/components/ProfilePage/Profile/index.tsx:109
msgid "Edit"
msgstr "Edit"

#: src/components/ProfilePage/EditProfile/index.tsx:264
msgid "Edit profile"
msgstr "Edit profile"

#: src/components/CallBooking/Experts/ExpertCard/index.tsx:184
msgid "Education"
msgstr "Education"

#: src/authentication/components/ForgotPasswordScreen/index.tsx:163
#: src/authentication/components/ForgotPasswordScreen/index.tsx:169
#: src/authentication/components/SignInScreen/CheckEmailForm/index.tsx:221
#: src/authentication/components/SignInScreen/CheckEmailForm/index.tsx:227
#: src/components/ProfilePage/AddPartner/index.tsx:220
#: src/components/ProfilePage/AddPartner/index.tsx:225
#: src/components/ProfilePage/EditProfile/index.tsx:269
#: src/components/ProfilePage/Profile/index.tsx:115
msgid "Email"
msgstr "Email"

#: src/components/ProfilePage/AddPartner/index.tsx:32
msgid "Email is required"
msgstr "Email is required"

#: src/components/ProfilePage/EditProfile/index.tsx:47
msgid "Email must be a valid email"
msgstr "Email must be a valid email"

#: src/components/ProfilePage/AddPartner/index.tsx:33
msgid "Email must be a valid email address"
msgstr "Email must be a valid email address"

#: src/authentication/components/SignInScreen/CheckEmailForm/index.tsx:175
msgid "Email not found"
msgstr "Email not found"

#: src/authentication/components/ActivateMembershipScreen/index.tsx:57
#: src/authentication/components/ForgotPasswordScreen/index.tsx:73
#: src/authentication/components/SignInScreen/CheckEmailForm/index.tsx:28
msgid "Email required"
msgstr "Email required"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:587
msgid "Employee ID"
msgstr "Employee ID"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:75
msgid "Employee ID is a required field"
msgstr "Employee ID is a required field"

#: src/components/ProfilePage/Profile/index.tsx:349
msgid "Employer"
msgstr "Employer"

#: src/calls/components/ScreenCall/index.tsx:241
msgid "End"
msgstr "End"

#: src/authentication/components/ActivateMembershipScreen/index.tsx:185
msgid "Enter your email"
msgstr "Enter your email"

#: src/authentication/components/ActivateMembershipScreen/index.tsx:186
msgid "Enter your email to verify eligibility"
msgstr "Enter your email to verify eligibility"

#: src/components/ProfileCompletion/Form.tsx:94
msgid "Error occurred while updating profile"
msgstr "Error occurred while updating profile"

#: src/components/ProfilePage/EditProfile/index.tsx:225
msgid "Error updating profile"
msgstr "Error updating profile"

#: src/calls/components/_/ExtendCall.tsx:73
msgid "Escalation"
msgstr "Escalation"

#: src/components/ProfilePage/AddPartner/index.tsx:180
msgid "Every employee regardless of his or her age, race, sexual orientation, marital status, and fertility health condition is eligible for the benefit. Adult dependents, such as spouses and civil partners, are also eligible to utilize this benefit subject to the employee’s lifetime maximum. If you would like to share access to the benefits with your loved one, please submit below."
msgstr "Every employee regardless of his or her age, race, sexual orientation, marital status, and fertility health condition is eligible for the benefit. Adult dependents, such as spouses and civil partners, are also eligible to utilize this benefit subject to the employee’s lifetime maximum. If you would like to share access to the benefits with your loved one, please submit below."

#: src/reimbursement/components/ReimbursementForm/ExpensesBlock/ExpensesItem/index.tsx:143
msgid "Expense description"
msgstr "Expense description"

#: src/reimbursement/components/ReimbursementSuccessResult/index.tsx:122
msgid "Expenses submitted"
msgstr "Expenses submitted"

#: src/components/CallBooking/Experts/ExpertCard/index.tsx:209
msgid "Experience"
msgstr "Experience"

#: src/components/HomeScreen/components/Block/index.tsx:181
msgid "Explore your coverage"
msgstr "Explore your coverage"

#: src/calls/components/_/ExtendCall.tsx:141
msgid "Extend"
msgstr "Extend"

#: src/calls/components/_/ExtendCall.tsx:119
#: src/calls/components/ScreenCall/index.tsx:262
#: src/calls/components/ScreenCall/index.tsx:296
msgid "Extend call"
msgstr "Extend call"

#: src/utils/formOptions.ts:36
#: src/utils/formOptions.ts:51
msgid "Female"
msgstr "Female"

#: src/authentication/components/onboarding/MemberIdScreen/index.tsx:268
msgid "Final step"
msgstr "Final step"

#: src/components/company-programs/LayoutProvider.tsx:114
msgid "Find care"
msgstr "Find care"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:520
msgid "First name"
msgstr "First name"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:80
msgid "First name is a required field"
msgstr "First name is a required field"

#: src/authentication/components/ForgotPasswordScreen/index.tsx:155
msgid "Forgot password"
msgstr "Forgot password"

#: src/authentication/components/SignInScreen/PasswordForm/index.tsx:250
msgid "Forgot password?"
msgstr "Forgot password?"

#: src/components/FamilyStatusForm/FamilyStatus.tsx:30
msgid "Gender affirming"
msgstr "Gender affirming"

#: src/components/FamilyStatusForm/FamilyStatus.tsx:31
msgid "Gender affirming care"
msgstr "Gender affirming care"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:622
#: src/components/ProfilePage/EditProfile/index.tsx:407
#: src/components/ProfilePage/Profile/index.tsx:291
msgid "Gender identity"
msgstr "Gender identity"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:85
msgid "Gender is a required field"
msgstr "Gender is a required field"

#: src/components/CallSubjects/Tab.tsx:109
msgid "Get answers to your questions today"
msgstr "Get answers to your questions today"

#: src/authentication/components/Layout/index.tsx:220
#: src/components/PageLayout/Footer/index.tsx:184
msgid "Global Privacy Notice"
msgstr "Global Privacy Notice"

#: src/components/Payment/index.tsx:192
msgid "Go to Home page"
msgstr "Go to Home page"

#: src/components/Payment/index.tsx:189
msgid "Go to Notifications"
msgstr "Go to Notifications"

#: src/components/MobileApp/MobileAppButton.tsx:54
msgid "Google Play"
msgstr "Google Play"

#: src/components/PageLayout/Footer/index.tsx:174
msgid "HIPAA"
msgstr "HIPAA"

#: src/authentication/components/Layout/index.tsx:243
msgid "HIPAA Notice and Consent"
msgstr "HIPAA Notice and Consent"

#: src/components/FamilyStatus/index.tsx:76
msgid "Having a  newborn"
msgstr "Having a  newborn"

#: src/components/FamilyStatusForm/FamilyStatus.tsx:35
msgid "Having a newborn"
msgstr "Having a newborn"

#: src/calls/components/ScreenCall/index.tsx:205
#: src/calls/components/ScreenCall/index.tsx:216
msgid "Hide info"
msgstr "Hide info"

#: src/care-navigator/CareNavigatorScreen/Channel/MessagesList/Message/TranslationButton/index.tsx:54
msgid "Hide original"
msgstr "Hide original"

#: src/care-navigator/CareNavigatorScreen/Channel/MessagesList/Message/TranslationButton/index.tsx:59
msgid "Hide translation"
msgstr "Hide translation"

#: src/components/HomeScreen/components/CareNavigatorWelcome/index.tsx:120
msgid "Hi {userName}"
msgstr "Hi {userName}"

#: src/components/PageLayout/Sidebar/index.tsx:290
msgid "Home"
msgstr "Home"

#: src/components/FamilyStatusForm/index.tsx:105
msgid "Hormone Care"
msgstr "Hormone Care"

#: src/components/CallSubjects/Tab.tsx:67
msgid "How Stork Club finds experts for you"
msgstr "How Stork Club finds experts for you"

#: src/components/Payment/index.tsx:402
msgid "How it works"
msgstr "How it works"

#: src/components/HomeScreen/index.tsx:194
msgid "How many months are you pregnant?"
msgstr "How many months are you pregnant?"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:422
msgid "I have read and acknowledge both the <0>US Privacy Policy</0> and <1>HIPAA Notice & Consent</1>. I accept the <2>Terms of Service</2>."
msgstr "I have read and acknowledge both the <0>US Privacy Policy</0> and <1>HIPAA Notice & Consent</1>. I accept the <2>Terms of Service</2>."

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:452
msgid "I have read and acknowledge the <0>Privacy Notice</0>. I accept the <1>Terms of Service</1>."
msgstr "I have read and acknowledge the <0>Privacy Notice</0>. I accept the <1>Terms of Service</1>."

#: src/notifications/components/Notification/Body/Responsibility/Complete.tsx:244
msgid "If a Stork Club provider asks you to pay them directly contact your <0>Care Navigator</0> so we can help."
msgstr "If a Stork Club provider asks you to pay them directly contact your <0>Care Navigator</0> so we can help."

#: src/notifications/components/Notification/Body/Responsibility/Complete.tsx:250
msgid "If a Stork Club provider asks you to pay them directly contact your <0>Care Partner</0> so we can help."
msgstr "If a Stork Club provider asks you to pay them directly contact your <0>Care Partner</0> so we can help."

#: src/notifications/components/Notification/Body/Responsibility/Complete.tsx:219
msgid "If the pharmacy asks you to pay your deductible directly, please contact your <0>Care Navigator</0> so we can help."
msgstr "If the pharmacy asks you to pay your deductible directly, please contact your <0>Care Navigator</0> so we can help."

#: src/notifications/components/Notification/Body/Responsibility/Complete.tsx:225
msgid "If the pharmacy asks you to pay your deductible directly, please contact your <0>Care Partner</0> so we can help."
msgstr "If the pharmacy asks you to pay your deductible directly, please contact your <0>Care Partner</0> so we can help."

#: src/components/Payment/index.tsx:322
msgid "If this error persists, please contact our <0>support team</0>"
msgstr "If this error persists, please contact our <0>support team</0>"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:498
msgid "If you have already registered previously, <0>log in at my.joinstorkclub.com</0>"
msgstr "If you have already registered previously, <0>log in at my.joinstorkclub.com</0>"

#: src/reimbursements/components/Reimbursement/Body/index.tsx:140
msgid "If you have any other questions, please reach out to your <0>Care Navigator</0>."
msgstr "If you have any other questions, please reach out to your <0>Care Navigator</0>."

#: src/reimbursements/components/Reimbursement/Body/index.tsx:140
msgid "If you have any other questions, please reach out to your <0>Care Partner</0>."
msgstr "If you have any other questions, please reach out to your <0>Care Partner</0>."

#: src/reimbursements/components/Reimbursement/Body/index.tsx:134
msgid "If you have any questions about payroll, please reach out to your HR team."
msgstr "If you have any questions about payroll, please reach out to your HR team."

#: src/reimbursement/components/ReimbursementForm/index.tsx:213
msgid "If you have any questions while filling out this form, please <0>contact your Care Navigator</0> and they can help guide you through the process."
msgstr "If you have any questions while filling out this form, please <0>contact your Care Navigator</0> and they can help guide you through the process."

#: src/reimbursement/components/ReimbursementForm/index.tsx:213
msgid "If you have any questions while filling out this form, please <0>contact your Care Partner</0> and they can help guide you through the process."
msgstr "If you have any questions while filling out this form, please <0>contact your Care Partner</0> and they can help guide you through the process."

#: src/reimbursements/components/Reimbursement/Body/index.tsx:115
msgid "If you have any questions, please reach out to your <0>Care Navigator</0>."
msgstr "If you have any questions, please reach out to your <0>Care Navigator</0>."

#: src/reimbursements/components/Reimbursement/Body/index.tsx:115
msgid "If you have any questions, please reach out to your <0>Care Partner</0>."
msgstr "If you have any questions, please reach out to your <0>Care Partner</0>."

#: src/notifications/components/Notification/Body/WhyAmIGettingThisBill.tsx:27
msgid "If you have questions about this bill, please contact your <0>Care Navigator</0>"
msgstr "If you have questions about this bill, please contact your <0>Care Navigator</0>"

#: src/notifications/components/Notification/Body/WhyAmIGettingThisBill.tsx:27
msgid "If you have questions about this bill, please contact your <0>Care Partner</0>"
msgstr "If you have questions about this bill, please contact your <0>Care Partner</0>"

#: src/components/CallBooking/index.tsx:200
msgid "If you have troubles deciding, ask your <0>Care Navigator</0> to help you."
msgstr "If you have troubles deciding, ask your <0>Care Navigator</0> to help you."

#: src/components/CallBooking/index.tsx:200
msgid "If you have troubles deciding, ask your <0>Care Partner</0> to help you."
msgstr "If you have troubles deciding, ask your <0>Care Partner</0> to help you."

#: src/authentication/components/onboarding/MemberIdScreen/index.tsx:303
msgid "If you're enrolled in a medical plan which includes Stork Club, please type in the Member ID from your regular medical insurance card. Stork Club will use it for verifying your eligibility and billing."
msgstr "If you're enrolled in a medical plan which includes Stork Club, please type in the Member ID from your regular medical insurance card. Stork Club will use it for verifying your eligibility and billing."

#: src/components/ProfileCompletion/Form.tsx:181
msgid "If you're enrolled in a medical plan with {companyName}, please type in your Member ID from your medical card."
msgstr "If you're enrolled in a medical plan with {companyName}, please type in your Member ID from your medical card."

#: src/notifications/components/Notification/Body/Responsibility/Complete.tsx:137
msgid "If your current plan type is"
msgstr "If your current plan type is"

#: src/authentication/components/SignInScreen/CheckEmailForm/index.tsx:166
msgid "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes."
msgstr "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes."

#: src/components/ProfilePage/EditProfile/index.tsx:298
#: src/components/ProfilePage/Profile/index.tsx:189
msgid "If you‘re enrolled in the medical plan, the ID is on your primary health insurance medical card. Otherwise, leave this blank."
msgstr "If you‘re enrolled in the medical plan, the ID is on your primary health insurance medical card. Otherwise, leave this blank."

#: src/components/ProfileCompletion/Form.tsx:175
#: src/components/ProfilePage/Profile/index.tsx:183
msgid "If you’re enrolled in the new medical plan with JAMF, please type in your Member ID from your BCBS medical card."
msgstr "If you’re enrolled in the new medical plan with JAMF, please type in your Member ID from your BCBS medical card."

#: src/authentication/components/onboarding/MemberIdScreen/index.tsx:297
msgid "If you’re enrolled in the new medical plan with JAMF, please type in your Member ID from your BCBS medical card."
msgstr "If you’re enrolled in the new medical plan with JAMF, please type in your Member ID from your BCBS medical card."

#: src/authentication/components/Info.tsx:137
#: src/authentication/components/SignUpScreen/Cobra.tsx:58
msgid "If you’re no longer working for the company with Stork Club coverage but you have COBRA for your health plan, reach out to us at <0>care@joinstorkclub.com</0> to verify your eligibility and restore access to your account."
msgstr "If you’re no longer working for the company with Stork Club coverage but you have COBRA for your health plan, reach out to us at <0>care@joinstorkclub.com</0> to verify your eligibility and restore access to your account."

#: src/reimbursements/components/Reimbursement/Body/index.tsx:110
msgid "Important: Stork Club will review the request and if\napproved, it will be sent to your company to reimburse you\nor your partner."
msgstr "Important: Stork Club will review the request and if\napproved, it will be sent to your company to reimburse you\nor your partner."

#: src/notifications/components/Notification/Body/Responsibility/Complete.tsx:210
msgid "Important: The pharmacy should only collect your copay, not your deductible."
msgstr "Important: The pharmacy should only collect your copay, not your deductible."

#: src/notifications/components/Notification/Body/Responsibility/Complete.tsx:234
msgid "Important: You should not pay to our providers directly!"
msgstr "Important: You should not pay to our providers directly!"

#: src/authentication/components/onboarding/MemberIdScreen/index.tsx:155
msgid "Incorrect Member ID"
msgstr "Incorrect Member ID"

#: src/lib/IncreaseContrast.tsx:72
msgid "Increase contrast"
msgstr "Increase contrast"

#: src/notifications/components/Notification/Body/Responsibility/IndividualCost.tsx:97
msgid "Individual Cost"
msgstr "Individual Cost"

#: src/notifications/components/Notification/Body/Responsibility/Incomplete.tsx:264
msgid "Individual cost"
msgstr "Individual cost"

#: src/notifications/components/Notification/Body/Responsibility/Complete.tsx:237
msgid "Instead, Stork Club will send you a bill with exact amount after you receive covered services so you could pay online via secure connection."
msgstr "Instead, Stork Club will send you a bill with exact amount after you receive covered services so you could pay online via secure connection."

#: src/authentication/components/SignInScreen/CheckEmailForm/index.tsx:27
msgid "Invalid email"
msgstr "Invalid email"

#: src/components/PageLayout/Sidebar/index.tsx:354
msgid "Invite partner"
msgstr "Invite partner"

#: src/components/HomeScreen/components/CareNavigatorWelcome/index.tsx:123
msgid "I’m your Care Navigator"
msgstr "I’m your Care Navigator"

#: src/components/HomeScreen/components/CareNavigatorWelcome/index.tsx:123
msgid "I’m your Care Partner"
msgstr "I’m your Care Partner"

#: src/calls/components/ScreenCheck/index.tsx:281
msgid "Join"
msgstr "Join"

#: src/authentication/components/onboarding/ConsentToSharing/index.tsx:166
#: src/authentication/components/onboarding/StatusScreen/index.tsx:209
msgid "Just before we start"
msgstr "Just before we start"

#: src/components/ProfilePage/Profile/ChangeLanguageForm/index.tsx:138
#: src/components/ProfilePage/Profile/ChangeLanguageForm/index.tsx:152
#: src/components/ProfilePage/Profile/ChangeLanguageForm/index.tsx:157
#: src/components/ProfilePage/Profile/index.tsx:323
msgid "Language"
msgstr "Language"

#: src/components/company-programs/LayoutDoula.tsx:212
msgid "Languages"
msgstr "Languages"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:530
msgid "Last name"
msgstr "Last name"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:92
msgid "Last name is a required field"
msgstr "Last name is a required field"

#: src/authentication/components/SignUpScreen/index.tsx:288
msgid "Let's customize your experience"
msgstr "Let's customize your experience"

#: src/components/Payment/index.tsx:249
#: src/components/Payment/oAuth.tsx:82
msgid "Loading"
msgstr "Loading"

#: src/components/PageLayout/Sidebar/index.tsx:370
msgid "Logout"
msgstr "Logout"

#: src/calls/components/_/DurationOption.tsx:37
msgid "MIN"
msgstr "MIN"

#: src/utils/formOptions.ts:30
#: src/utils/formOptions.ts:45
msgid "Male"
msgstr "Male"

#: src/calls/components/ScreenCall/index.tsx:251
msgid "Max. call duration<0/>has been reached"
msgstr "Max. call duration<0/>has been reached"

#: src/authentication/components/onboarding/MemberIdScreen/MedicalCard/index.tsx:105
#: src/components/ProfileCompletion/MedicalCardPic.tsx:88
msgid "Medical Card"
msgstr "Medical Card"

#: src/components/CallBooking/Disclaimer.tsx:16
msgid "Meetings with Stork Club experts are for educational and informational purposes only. By scheduling a meeting with a Stork Club expert, you acknowledge and agree that Stork Club expert consultants are not acting as medical professionals, and the support and information they provide is not a substitute for professional medical advice, diagnosis or treatment. Always seek the advice of your physician or other qualified health care provider with any questions you may have regarding a medical condition or treatment and before undertaking a new health care regimen, and never disregard professional medical advice or delay in seeking it because of something you learn through your meeting with a Stork Club expert consultant."
msgstr "Meetings with Stork Club experts are for educational and informational purposes only. By scheduling a meeting with a Stork Club expert, you acknowledge and agree that Stork Club expert consultants are not acting as medical professionals, and the support and information they provide is not a substitute for professional medical advice, diagnosis or treatment. Always seek the advice of your physician or other qualified health care provider with any questions you may have regarding a medical condition or treatment and before undertaking a new health care regimen, and never disregard professional medical advice or delay in seeking it because of something you learn through your meeting with a Stork Club expert consultant."

#: src/authentication/components/onboarding/MemberIdScreen/index.tsx:324
#: src/authentication/components/onboarding/MemberIdScreen/index.tsx:331
#: src/authentication/components/onboarding/MemberIdScreen/MedicalCard/index.tsx:108
#: src/components/ProfileCompletion/Form.tsx:170
#: src/components/ProfileCompletion/Form.tsx:190
#: src/components/ProfileCompletion/MedicalCardPic.tsx:91
#: src/components/ProfilePage/EditProfile/index.tsx:281
#: src/components/ProfilePage/Profile/index.tsx:162
#: src/components/ProfilePage/Profile/index.tsx:208
msgid "Member ID"
msgstr "Member ID"

#: src/components/ProfilePage/Profile/index.tsx:176
msgid "Member ID is necessary to fill out to start using fertility coverage"
msgstr "Member ID is necessary to fill out to start using fertility coverage"

#: src/calls/components/_/ExtendCall.tsx:75
msgid "Member joined call late"
msgstr "Member joined call late"

#: src/notifications/components/Notification/Body/BillDetails.tsx:140
msgid "Member's financial responsibility:"
msgstr "Member's financial responsibility:"

#: src/components/FamilyStatusForm/FamilyStatus.tsx:39
msgid "Menopause"
msgstr "Menopause"

#: src/components/FamilyStatusForm/FamilyStatus.tsx:42
msgid "Men’s Health"
msgstr "Men’s Health"

#: src/components/FamilyStatusForm/FamilyStatus.tsx:42
msgid "Men’s health"
msgstr "Men’s health"

#: src/components/FamilyStatusForm/FamilyStatus.tsx:43
msgid "Men’s optimization"
msgstr "Men’s optimization"

#: src/calls/components/_/SelectAudioDevice.tsx:163
#: src/calls/components/ScreenCheck/index.tsx:238
msgid "Microphone"
msgstr "Microphone"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:559
msgid "Mobile phone"
msgstr "Mobile phone"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:97
msgid "Mobile phone is a required field"
msgstr "Mobile phone is a required field"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:658
#: src/authentication/components/SignUpScreen/SignUpForm.tsx:665
#: src/components/ProfilePage/EditProfile/index.tsx:338
#: src/components/ProfilePage/EditProfile/index.tsx:344
msgid "Month"
msgstr "Month"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:102
msgid "Month is a required field"
msgstr "Month is a required field"

#: src/components/articles/ListPage/MustReadBlock.tsx:9
msgid "Must Read"
msgstr "Must Read"

#: src/authentication/components/ChangePasswordScreen/ChangePasswordForm.tsx:209
msgid "Must be at least {passwordMinLength}+ characters long, contain lower and upper case letters, at least one number (0-9), and special characters (!&*)."
msgstr "Must be at least {passwordMinLength}+ characters long, contain lower and upper case letters, at least one number (0-9), and special characters (!&*)."

#: src/authentication/components/ChangePasswordScreen/ChangePasswordForm.tsx:82
msgid "Must contain lower and upper case letters and at least one number (0-9), and special characters (!&*)"
msgstr "Must contain lower and upper case letters and at least one number (0-9), and special characters (!&*)"

#: src/authentication/components/ChangePasswordScreen/index.tsx:83
msgid "Must have 8+ characters in length and contain lower and upper case letters and at least one number (0-9)"
msgstr "Must have 8+ characters in length and contain lower and upper case letters and at least one number (0-9)"

#: src/authentication/components/ChangePasswordScreen/index.tsx:205
#: src/authentication/components/SignUpScreen/SignUpForm.tsx:724
msgid "Must have {minPasswordLength}+ characters in length and contain lower and upper case letters and at least one number (0-9)"
msgstr "Must have {minPasswordLength}+ characters in length and contain lower and upper case letters and at least one number (0-9)"

#: src/authentication/components/ChangePasswordScreen/ChangePasswordForm.tsx:154
msgid "Must have {passwordMinLength}+ characters in length and contain lower and upper case letters and at least one number (0-9)"
msgstr "Must have {passwordMinLength}+ characters in length and contain lower and upper case letters and at least one number (0-9)"

#: src/calls/components/ScreenCall/index.tsx:225
#: src/calls/components/ScreenCheck/index.tsx:248
msgid "Mute"
msgstr "Mute"

#: src/components/ProfilePage/index.tsx:89
#: src/components/ProfilePage/Profile/index.tsx:100
msgid "My profile"
msgstr "My profile"

#: src/authentication/components/onboarding/MemberIdScreen/index.tsx:268
msgid "Needs your attention"
msgstr "Needs your attention"

#: src/components/ProfilePage/Profile/index.tsx:120
msgid "New email {0} waiting for confirmation"
msgstr "New email {0} waiting for confirmation"

#: src/components/ProfilePage/Profile/ChangePasswordForm/index.tsx:166
#: src/components/ProfilePage/Profile/ChangePasswordForm/index.tsx:171
msgid "New password"
msgstr "New password"

#: src/authentication/components/SignInScreen/CheckEmailForm/index.tsx:192
msgid "New to Stork Club?"
msgstr "New to Stork Club?"

#: src/authentication/components/SignInScreen/CheckEmailForm/index.tsx:236
msgid "Next"
msgstr "Next"

#: src/components/CallBooking/Experts/ExpertCard/index.tsx:202
msgid "Next available:"
msgstr "Next available:"

#: src/calls/components/ScreenCheck/index.tsx:210
msgid "No camera"
msgstr "No camera"

#: src/authentication/components/Info.tsx:127
msgid "No longer working for the company with Stork Club coverage?"
msgstr "No longer working for the company with Stork Club coverage?"

#: src/notifications/components/Widget/NoNewWidget.tsx:58
msgid "No new notifications."
msgstr "No new notifications."

#: src/components/CallBooking/Experts/ExpertCard/index.tsx:210
#: src/components/CallBooking/Experts/ExpertCard/index.tsx:217
msgid "Not available"
msgstr "Not available"

#: src/components/articles/ArticlesSearch/SearchBlock.tsx:20
msgid "Nothing can be found"
msgstr "Nothing can be found"

#: src/notifications/components/Widget/NothingYetWidget.tsx:123
msgid "Nothing yet. You'll see notifications about coverage authorizations and copays here."
msgstr "Nothing yet. You'll see notifications about coverage authorizations and copays here."

#: src/components/PageLayout/Sidebar/index.tsx:301
#: src/notifications/components/NotificationsPage/index.tsx:153
#: src/notifications/components/NotificationsPage/index.tsx:164
#: src/notifications/components/NotificationsPage/index.tsx:166
#: src/notifications/components/Widget/MainPageWidget.tsx:92
#: src/notifications/components/Widget/NoNewWidget.tsx:50
#: src/notifications/components/Widget/NothingYetWidget.tsx:120
msgid "Notifications"
msgstr "Notifications"

#: src/components/CallNotifications/index.tsx:161
msgid "Open Stork Club mobile app to join the call"
msgstr "Open Stork Club mobile app to join the call"

#: src/components/PageLayout/Sidebar/index.tsx:361
msgid "Open my profile"
msgstr "Open my profile"

#: src/components/PageLayout/Sidebar/index.tsx:277
#: src/components/PageLayout/Sidebar/index.tsx:289
msgid "Open the home page"
msgstr "Open the home page"

#: src/calls/components/_/ExtendCall.tsx:78
#: src/utils/formOptions.ts:57
msgid "Other"
msgstr "Other"

#: src/notifications/components/Notification/Body/Responsibility/Complete.tsx:181
msgid "Out-of-pocket Max"
msgstr "Out-of-pocket Max"

#: src/notifications/components/Notification/Body/Responsibility/Incomplete.tsx:235
msgid "Out-of-pocket max"
msgstr "Out-of-pocket max"

#: src/components/CallSubjects/Tab.tsx:85
msgid "Over 3 years of patient experience with outstanding reviews"
msgstr "Over 3 years of patient experience with outstanding reviews"

#: src/notifications/components/Notification/Body/BodyBillPaid.tsx:74
msgid "Paid"
msgstr "Paid"

#: src/components/CallSubjects/Tab.tsx:91
msgid "Passion for their profession and compassion for moms and moms-to-be"
msgstr "Passion for their profession and compassion for moms and moms-to-be"

#: src/authentication/components/SignInScreen/PasswordForm/index.tsx:236
#: src/components/ProfilePage/Profile/index.tsx:129
msgid "Password"
msgstr "Password"

#: src/components/ProfilePage/Profile/index.tsx:139
msgid "Password changed successfully"
msgstr "Password changed successfully"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:110
msgid "Password is a required field"
msgstr "Password is a required field"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:113
#: src/components/ProfilePage/Profile/ChangePasswordForm/index.tsx:34
msgid "Password is too short (minimum is {minPasswordLength} characters)"
msgstr "Password is too short (minimum is {minPasswordLength} characters)"

#: src/authentication/components/ChangePasswordScreen/index.tsx:79
msgid "Password must be at least {minPasswordLength} characters long"
msgstr "Password must be at least {minPasswordLength} characters long"

#: src/authentication/components/ChangePasswordScreen/ChangePasswordForm.tsx:68
msgid "Password must be at least {passwordMinLength} characters long"
msgstr "Password must be at least {passwordMinLength} characters long"

#: src/authentication/components/ChangePasswordScreen/index.tsx:76
#: src/authentication/components/SignInScreen/PasswordForm/index.tsx:36
msgid "Password required"
msgstr "Password required"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:117
#: src/components/ProfilePage/Profile/ChangePasswordForm/index.tsx:38
msgid "Password should contain lower and upper case letters and at least one number (0-9)"
msgstr "Password should contain lower and upper case letters and at least one number (0-9)"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:109
#: src/components/ProfilePage/Profile/ChangePasswordForm/index.tsx:38
msgid "Password should contain lower and upper case letters and at least one number (0-9), and special characters (!&*)"
msgstr "Password should contain lower and upper case letters and at least one number (0-9), and special characters (!&*)"

#: src/components/CallBooking/Experts/ExpertCard/index.tsx:192
msgid "Patient experience"
msgstr "Patient experience"

#: src/components/Payment/index.tsx:386
msgid "Pay by bank transfer (ACH)"
msgstr "Pay by bank transfer (ACH)"

#: src/notifications/components/Notification/Body/BodyBillAvailable.tsx:96
#: src/notifications/components/Notification/Body/BodyBillPaymentFailed.tsx:58
msgid "Pay now"
msgstr "Pay now"

#: src/components/Payment/index.tsx:286
msgid "Payment confirmed"
msgstr "Payment confirmed"

#: src/notifications/components/Notification/HeaderTitle.tsx:205
msgid "Payment for bill #{sequentialId} has <0>failed</0>"
msgstr "Payment for bill #{sequentialId} has <0>failed</0>"

#: src/components/Payment/index.tsx:263
msgid "Payment initiated"
msgstr "Payment initiated"

#: src/components/ProfilePage/EditProfile/index.tsx:435
#: src/components/ProfilePage/Profile/index.tsx:355
msgid "Personal email"
msgstr "Personal email"

#: src/components/ProfilePage/EditProfile/index.tsx:58
msgid "Personal email must be a valid email"
msgstr "Personal email must be a valid email"

#. TestYozi
#: src/components/company-programs/BookAppointmentForm.tsx:178
msgid "Phone"
msgstr "Phone"

#: src/components/company-programs/BookAppointmentForm.tsx:35
msgid "Phone number is required"
msgstr "Phone number is required"

#: src/components/CallBooking/index.tsx:281
msgid "Pick a date"
msgstr "Pick a date"

#: src/calls/components/_/ExtendCall.tsx:130
msgid "Pick a duration"
msgstr "Pick a duration"

#: src/components/CallBooking/index.tsx:319
msgid "Pick a time"
msgstr "Pick a time"

#: src/reimbursement/components/ReimbursementForm/types.ts:36
msgid "Please add at least one expense to your reimbursement"
msgstr "Please add at least one expense to your reimbursement"

#: src/authentication/components/SignInScreen/TwoFactorCodeForm/index.tsx:169
msgid "Please check your email for an authentication code and enter it here"
msgstr "Please check your email for an authentication code and enter it here"

#: src/authentication/components/ActivateMembershipScreen/index.tsx:219
msgid "Please check your email<0/><1>{0}</1><2/>to verify your address."
msgstr "Please check your email<0/><1>{0}</1><2/>to verify your address."

#: src/components/CallBooking/index.tsx:64
msgid "Please choose a date"
msgstr "Please choose a date"

#: src/components/CallBooking/index.tsx:67
msgid "Please choose a time"
msgstr "Please choose a time"

#: src/components/CallBooking/index.tsx:65
msgid "Please choose an expert"
msgstr "Please choose an expert"

#: src/components/ProfilePage/Profile/ChangePasswordForm/index.tsx:25
msgid "Please confirm your new password"
msgstr "Please confirm your new password"

#: src/components/CallBooking/index.tsx:389
msgid "Please describe what you'd like to discuss to help us connect you with the best experts"
msgstr "Please describe what you'd like to discuss to help us connect you with the best experts"

#: src/reimbursement/components/ReimbursementForm/types.ts:29
msgid "Please enter a date"
msgstr "Please enter a date"

#: src/reimbursement/components/ReimbursementForm/types.ts:32
msgid "Please enter a description"
msgstr "Please enter a description"

#: src/reimbursement/components/ReimbursementForm/types.ts:19
msgid "Please enter a smaller amount"
msgstr "Please enter a smaller amount"

#: src/authentication/components/ActivateMembershipScreen/index.tsx:56
#: src/authentication/components/ForgotPasswordScreen/index.tsx:72
#: src/authentication/components/SignUpScreen/SignUpForm.tsx:105
msgid "Please enter a valid email address"
msgstr "Please enter a valid email address"

#: src/reimbursement/components/ReimbursementForm/types.ts:16
msgid "Please enter an amount"
msgstr "Please enter an amount"

#: src/components/ProfilePage/Profile/ChangePasswordForm/index.tsx:28
msgid "Please enter your current password"
msgstr "Please enter your current password"

#: src/components/ProfilePage/EditProfile/index.tsx:48
msgid "Please enter your email"
msgstr "Please enter your email"

#: src/reimbursement/components/ReimbursementForm/ExpensesBlock/index.tsx:149
msgid "Please enter your expenses as listed on your receipt:"
msgstr "Please enter your expenses as listed on your receipt:"

#: src/components/ProfilePage/Profile/ChangePasswordForm/index.tsx:40
msgid "Please enter your new password"
msgstr "Please enter your new password"

#: src/components/ProfilePage/EditProfile/index.tsx:68
msgid "Please enter your zip code"
msgstr "Please enter your zip code"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:395
msgid "Please fill out or check the required fields below"
msgstr "Please fill out or check the required fields below"

#: src/calls/utils/userfriendlyerror.ts:43
msgid "Please make sure the microphone is connected and refresh this page."
msgstr "Please make sure the microphone is connected and refresh this page."

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:88
msgid "Please read and accept to proceed"
msgstr "Please read and accept to proceed"

#: src/components/ProfileCompletion/Form.tsx:48
#: src/components/ProfileCompletion/Form.tsx:49
msgid "Please select"
msgstr "Please select"

#: src/components/ProfilePage/EditProfile/index.tsx:43
msgid "Please select a day"
msgstr "Please select a day"

#: src/components/ProfilePage/Profile/ChangeLanguageForm/index.tsx:22
msgid "Please select a language"
msgstr "Please select a language"

#: src/components/ProfilePage/EditProfile/index.tsx:54
msgid "Please select a month"
msgstr "Please select a month"

#: src/reimbursement/components/ReimbursementForm/types.ts:44
msgid "Please select a type"
msgstr "Please select a type"

#: src/components/ProfilePage/EditProfile/index.tsx:64
msgid "Please select a year"
msgstr "Please select a year"

#: src/calls/components/_/SelectAudioDevice.tsx:166
#: src/calls/components/_/SelectVideoDevice.tsx:140
msgid "Please select your device"
msgstr "Please select your device"

#: src/reimbursement/components/ReimbursementForm/types.ts:45
msgid "Please type a provider"
msgstr "Please type a provider"

#: src/reimbursement/components/ReimbursementForm/types.ts:39
msgid "Please upload a file"
msgstr "Please upload a file"

#: src/calls/utils/userfriendlyerror.ts:47
msgid "Please, reload page and try again."
msgstr "Please, reload page and try again."

#: src/components/articles/ListPage/PopularBlock.tsx:12
msgid "Popular Articles"
msgstr "Popular Articles"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:576
#: src/components/ProfilePage/EditProfile/index.tsx:423
#: src/components/ProfilePage/Profile/index.tsx:315
msgid "Postal code"
msgstr "Postal code"

#: src/components/Payment/index.tsx:428
msgid "Powered by"
msgstr "Powered by"

#: src/utils/formOptions.ts:63
msgid "Prefer not to answer"
msgstr "Prefer not to answer"

#: src/components/ProfilePage/EditProfile/index.tsx:307
#: src/components/ProfilePage/Profile/index.tsx:261
msgid "Preferred name"
msgstr "Preferred name"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:540
msgid "Preferred name (optional)"
msgstr "Preferred name (optional)"

#: src/components/FamilyStatus/index.tsx:72
msgid "Pregnant"
msgstr "Pregnant"

#: src/notifications/components/Notification/Body/PrescribedRx.tsx:33
msgid "Prescribed Rx"
msgstr "Prescribed Rx"

#: src/components/PageLayout/Footer/index.tsx:167
msgid "Privacy Policy"
msgstr "Privacy Policy"

#: src/components/ProfileCompletion/Form.tsx:98
#: src/components/ProfilePage/EditProfile/index.tsx:235
msgid "Profile updated"
msgstr "Profile updated"

#: src/components/HomeScreen/components/Block/index.tsx:136
msgid "Program sponsorship"
msgstr "Program sponsorship"

#: src/reimbursement/components/ReimbursementForm/index.tsx:285
msgid "Provider"
msgstr "Provider"

#: src/notifications/components/Notification/Body/BillDetails.tsx:129
msgid "Provider <0>Stork Club Rx (${providerName})</0>"
msgstr "Provider <0>Stork Club Rx (${providerName})</0>"

#: src/components/Payment/index.tsx:362
msgid "Provider <0>{0}</0>"
msgstr "Provider <0>{0}</0>"

#: src/notifications/components/Notification/Body/BillDetails.tsx:134
msgid "Provider <0>{providerName}</0>"
msgstr "Provider <0>{providerName}</0>"

#: src/components/company-programs/LayoutDoula.tsx:198
msgid "Race/ethnicity"
msgstr "Race/ethnicity"

#: src/components/HomeScreen/components/Posts/index.tsx:75
msgid "Read"
msgstr "Read"

#: src/components/CallBooking/Disclaimer.tsx:44
msgid "Read all"
msgstr "Read all"

#: src/components/articles/Items/ItemLarge.tsx:147
#: src/components/HomeScreen/components/Post/FeaturedPost.tsx:119
msgid "Read more"
msgstr "Read more"

#: src/reimbursements/components/Reimbursement/Body/index.tsx:78
msgid "Receipt from <0>{providerName}</0> for <1>{programName}</1><2>submitted on <3>{date}</3></2>"
msgstr "Receipt from <0>{providerName}</0> for <1>{programName}</1><2>submitted on <3>{date}</3></2>"

#: src/components/articles/ListPage/RecentBlock.tsx:12
msgid "Recent Articles"
msgstr "Recent Articles"

#: src/calls/components/ScreenCall/index.tsx:162
msgid "Reconnecting"
msgstr "Reconnecting"

#: src/components/ProfilePage/EditProfile/index.tsx:446
#: src/components/ProfilePage/Profile/index.tsx:370
msgid "Recovery email to restore access to your account"
msgstr "Recovery email to restore access to your account"

#: src/reimbursements/components/ReimbursementGroup/index.tsx:186
msgid "Reimbursement ID"
msgstr "Reimbursement ID"

#: src/reimbursement/components/ReimbursementForm/index.tsx:210
msgid "Reimbursement form"
msgstr "Reimbursement form"

#: src/reimbursement/components/ReimbursementForm/index.tsx:255
msgid "Reimbursement type"
msgstr "Reimbursement type"

#: src/notifications/components/Notification/Body/CoveredTable.tsx:60
#: src/reimbursements/components/Reimbursement/Body/Services.tsx:131
msgid "Rejected"
msgstr "Rejected"

#: src/notifications/components/Notification/Body/Responsibility/CostSharing.tsx:132
msgid "Remaining amount"
msgstr "Remaining amount"

#: src/reimbursement/components/StatelessSubmitExpensesWidget/index.tsx:107
msgid "Remaining coverage"
msgstr "Remaining coverage"

#: src/reimbursement/components/ReimbursementForm/ExpensesBlock/ExpensesItem/index.tsx:184
msgid "Remove expense"
msgstr "Remove expense"

#: src/components/ProfilePage/Profile/ChangePasswordForm/index.tsx:178
#: src/components/ProfilePage/Profile/ChangePasswordForm/index.tsx:183
msgid "Repeat password"
msgstr "Repeat password"

#: src/components/FamilyStatusForm/index.tsx:84
msgid "Reproductive Care"
msgstr "Reproductive Care"

#: src/reimbursements/components/Reimbursement/Body/Services.tsx:55
msgid "Requested"
msgstr "Requested"

#: src/components/CallSubjects/Call.tsx:206
msgid "Reschedule"
msgstr "Reschedule"

#: src/components/CallBooking/index.tsx:179
msgid "Reschedule a call"
msgstr "Reschedule a call"

#: src/care-navigator/CareNavigatorScreen/Channel/MessagesList/Message/CouldNotSend/index.tsx:77
#: src/care-navigator/CareNavigatorScreen/SendBirdCantConnect.tsx:97
msgid "Retry"
msgstr "Retry"

#: src/components/Error404Screen/index.tsx:119
#: src/components/Error500Screen/index.tsx:108
msgid "Return home"
msgstr "Return home"

#: src/components/ProfilePage/AddPartner/index.tsx:212
msgid "Revoke access"
msgstr "Revoke access"

#: src/notifications/components/AuthorizationGroup/index.tsx:185
msgid "Rx authorization"
msgstr "Rx authorization"

#: src/notifications/components/Notification/Body/BodyRxCanceled.tsx:37
msgid "Rx authorization was canceled by Provider"
msgstr "Rx authorization was canceled by Provider"

#: src/notifications/components/Notification/Body/Responsibility/CostSharing.tsx:110
#: src/notifications/components/Notification/Body/Responsibility/CostSharing.tsx:152
msgid "SMP Pharmacy will collect the copay from you directly before shipping the order."
msgstr "SMP Pharmacy will collect the copay from you directly before shipping the order."

#: src/components/CallSubjects/Subject.tsx:149
msgid "Schedule a call"
msgstr "Schedule a call"

#: src/components/HomeScreen/components/CareNavigatorWelcome/index.tsx:162
msgid "Schedule call"
msgstr "Schedule call"

#: src/components/CallBooking/index.tsx:453
#: src/components/CallSubjects/Call.tsx:192
msgid "Scheduled for {0}"
msgstr "Scheduled for {0}"

#: src/components/articles/Header/index.tsx:84
msgid "Search"
msgstr "Search"

#: src/components/articles/ArticlesSearch/SearchBlock.tsx:31
msgid "Search results for \"{q}\""
msgstr "Search results for \"{q}\""

#: src/components/articles/ArticlesSearch/SearchBlock.tsx:13
msgid "Search results for {q}"
msgstr "Search results for {q}"

#: src/components/company-programs/LayoutProvider.tsx:115
#: src/components/HomeScreen/components/Programs/Program/index.tsx:145
msgid "See more"
msgstr "See more"

#: src/authentication/components/onboarding/ConsentToSharing/index.tsx:154
msgid "See more information in the <0>Global Privacy Notice</0>"
msgstr "See more information in the <0>Global Privacy Notice</0>"

#: src/care-navigator/CareNavigatorScreen/Channel/MessagesList/Message/TranslationButton/index.tsx:55
msgid "See original"
msgstr "See original"

#: src/care-navigator/CareNavigatorScreen/Channel/MessagesList/Message/TranslationButton/index.tsx:60
msgid "See translation"
msgstr "See translation"

#: src/reimbursement/components/ReimbursementForm/index.tsx:274
msgid "Select"
msgstr "Select"

#: src/calls/components/_/ExtendCall.tsx:122
msgid "Select a reason"
msgstr "Select a reason"

#: src/components/CallBooking/index.tsx:269
msgid "Select day and time"
msgstr "Select day and time"

#: src/reimbursement/components/ReimbursementForm/index.tsx:268
msgid "Select reimbursement type"
msgstr "Select reimbursement type"

#: src/components/ProfileCompletion/Form.tsx:150
msgid "Select type"
msgstr "Select type"

#: src/components/ProfileCompletion/Form.tsx:133
msgid "Select your Medical Plan sponsored by employer"
msgstr "Select your Medical Plan sponsored by employer"

#: src/care-navigator/CareNavigatorScreen/Channel/MessageInput/index.tsx:218
msgid "Send"
msgstr "Send"

#: src/components/ProfilePage/AddPartner/index.tsx:236
msgid "Send invitation"
msgstr "Send invitation"

#: src/components/HomeScreen/components/CareNavigatorWelcome/index.tsx:151
msgid "Send message"
msgstr "Send message"

#: src/components/PageLayout/Footer/index.tsx:151
msgid "Send questions at <0>care@joinstorkclub.com</0>"
msgstr "Send questions at <0>care@joinstorkclub.com</0>"

#: src/authentication/components/ForgotPasswordScreen/index.tsx:178
msgid "Send reset link"
msgstr "Send reset link"

#: src/components/Payment/index.tsx:352
msgid "Service <0>{0}</0> completed on"
msgstr "Service <0>{0}</0> completed on"

#: src/notifications/components/Notification/Body/BillDetails.tsx:119
msgid "Service <0>{serviceName}</0> completed on"
msgstr "Service <0>{serviceName}</0> completed on"

#: src/components/ProfilePage/Settings/index.tsx:48
msgid "Settings"
msgstr "Settings"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:124
msgid "Sex is a required field"
msgstr "Sex is a required field"

#: src/calls/components/ScreenCall/index.tsx:205
#: src/calls/components/ScreenCall/index.tsx:216
msgid "Show info"
msgstr "Show info"

#: src/authentication/components/Info.tsx:132
msgid "Show less"
msgstr "Show less"

#: src/care-navigator/CareNavigatorScreen/Channel/MessagesList/ListBottom/index.tsx:81
msgid "Show messages in their original language"
msgstr "Show messages in their original language"

#: src/authentication/components/Info.tsx:132
msgid "Show more"
msgstr "Show more"

#: src/components/company-programs/LayoutClinic.tsx:217
msgid "Show more locations"
msgstr "Show more locations"

#: src/authentication/components/ForgotPasswordScreen/index.tsx:151
#: src/authentication/components/SignInScreen/CheckEmailForm/index.tsx:188
#: src/authentication/components/SignInScreen/PasswordForm/index.tsx:258
msgid "Sign in"
msgstr "Sign in"

#: src/authentication/components/SignInScreen/PasswordForm/index.tsx:280
msgid "Sign in with Okta"
msgstr "Sign in with Okta"

#: src/authentication/components/ActivateMembershipScreen/index.tsx:206
#: src/authentication/components/SignUpScreen/SignUpForm.tsx:791
msgid "Sign up"
msgstr "Sign up"

#: src/locales/errors.ts:17
msgid "SignUp token is not defined!"
msgstr "SignUp token is not defined!"

#: src/authentication/components/SignInScreen/index.tsx:73
msgid "Signed in successfully."
msgstr "Signed in successfully."

#: src/authentication/components/onboarding/MemberIdScreen/index.tsx:354
msgid "Skip"
msgstr "Skip"

#: src/authentication/components/onboarding/MemberIdScreen/index.tsx:314
msgid "Skip this step if you're not enrolled in a medical plan with Stork Club coverage."
msgstr "Skip this step if you're not enrolled in a medical plan with Stork Club coverage."

#: src/components/BypassLink.tsx:25
msgid "Skip to main content"
msgstr "Skip to main content"

#: src/authentication/components/onboarding/StatusScreen/index.tsx:219
msgid "So we can recommend information experts, and company benefits for you"
msgstr "So we can recommend information experts, and company benefits for you"

#: src/components/Payment/index.tsx:302
#: src/components/ProfilePage/Profile/index.tsx:243
msgid "Something went wrong"
msgstr "Something went wrong"

#: src/calls/utils/userfriendlyerror.ts:46
msgid "Something went wrong."
msgstr "Something went wrong."

#: src/components/Payment/index.tsx:160
#: src/components/Payment/oAuth.tsx:71
msgid "Sorry, something went wrong. Please reload the page or try again later."
msgstr "Sorry, something went wrong. Please reload the page or try again later."

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:391
msgid "Sorry, we couldn’t find your account with the information provided."
msgstr "Sorry, we couldn’t find your account with the information provided."

#: src/components/company-programs/BookAppointmentForm.tsx:189
msgid "Sounds great!"
msgstr "Sounds great!"

#: src/components/HomeScreen/index.tsx:163
msgid "Stork Club"
msgstr "Stork Club"

#: src/notifications/components/Notification/HeaderTitle.tsx:96
msgid "Stork Club <0>granted</0> Rx authorization"
msgstr "Stork Club <0>granted</0> Rx authorization"

#: src/notifications/components/Notification/HeaderTitle.tsx:100
msgid "Stork Club <0>granted</0> Service authorization"
msgstr "Stork Club <0>granted</0> Service authorization"

#: src/notifications/components/Notification/HeaderTitle.tsx:77
msgid "Stork Club <0>rejected</0> Rx authorization"
msgstr "Stork Club <0>rejected</0> Rx authorization"

#: src/notifications/components/Notification/HeaderTitle.tsx:82
msgid "Stork Club <0>rejected</0> Service authorization"
msgstr "Stork Club <0>rejected</0> Service authorization"

#: src/notifications/components/Notification/HeaderTitle.tsx:133
msgid "Stork Club Rx {practiceName} <0>canceled</0> Rx authorization"
msgstr "Stork Club Rx {practiceName} <0>canceled</0> Rx authorization"

#: src/notifications/components/Notification/HeaderTitle.tsx:114
msgid "Stork Club Rx {practiceName} <0>requested</0> Rx authorization"
msgstr "Stork Club Rx {practiceName} <0>requested</0> Rx authorization"

#: src/components/Payment/index.tsx:405
msgid "Stork Club uses Plaid to securely verify your account and complete the payment."
msgstr "Stork Club uses Plaid to securely verify your account and complete the payment."

#: src/notifications/components/Notification/Body/Responsibility/Complete.tsx:213
msgid "Stork Club will send you a bill for you applicable deductible after you receive your Rx."
msgstr "Stork Club will send you a bill for you applicable deductible after you receive your Rx."

#: src/components/ProfileCompletion/Form.tsx:212
#: src/reimbursement/components/ReimbursementForm/index.tsx:306
msgid "Submit"
msgstr "Submit"

#: src/reimbursement/components/ReimbursementSuccessResult/index.tsx:142
msgid "Submit another receipt"
msgstr "Submit another receipt"

#: src/reimbursement/components/StatelessSubmitExpensesWidget/index.tsx:121
msgid "Submit expenses"
msgstr "Submit expenses"

#: src/lib/Tabs/index.tsx:239
msgid "Tabs"
msgstr "Tabs"

#: src/components/CallSubjects/index.tsx:66
#: src/components/PageLayout/Sidebar/index.tsx:308
msgid "Talk to experts"
msgstr "Talk to experts"

#: src/authentication/components/Layout/index.tsx:235
#: src/components/PageLayout/Footer/index.tsx:142
msgid "Terms of Service"
msgstr "Terms of Service"

#: src/notifications/components/Notification/Body/BodyBillAvailable.tsx:80
msgid "Thank you for using Stork Club and our in-network providers for your care.<0/>You're receiving this bill because your health plan includes a cost-sharing policy."
msgstr "Thank you for using Stork Club and our in-network providers for your care.<0/>You're receiving this bill because your health plan includes a cost-sharing policy."

#: src/notifications/components/Notification/Body/BodyBillPaid.tsx:69
msgid "Thank you!"
msgstr "Thank you!"

#: src/components/Payment/index.tsx:266
msgid "Thank you! Funds will be withdrawn from your account in 1-2 business day<0/>After Stork Club receives the payment, we will send a confirmation notification."
msgstr "Thank you! Funds will be withdrawn from your account in 1-2 business day<0/>After Stork Club receives the payment, we will send a confirmation notification."

#: src/components/Payment/index.tsx:289
msgid "Thank you! The bill has been paid."
msgstr "Thank you! The bill has been paid."

#: src/reimbursement/components/ReimbursementSuccessResult/index.tsx:125
msgid "Thank you! You can check on your reimbursement status on the Notifications page.<0>We will send you an email once Stork Club reviews your request.</0>"
msgstr "Thank you! You can check on your reimbursement status on the Notifications page.<0>We will send you an email once Stork Club reviews your request.</0>"

#: src/notifications/components/Notification/Body/BodyBillAvailable.tsx:108
msgid "The bill has been paid"
msgstr "The bill has been paid"

#: src/calls/utils/userfriendlyerror.ts:30
msgid "The browser could not start media capture with the input device even after the user gave permission, probably because another app or tab has reserved the input device."
msgstr "The browser could not start media capture with the input device even after the user gave permission, probably because another app or tab has reserved the input device."

#: src/calls/components/CallPage/index.tsx:62
#: src/calls/components/VideoCall/index.tsx:99
msgid "The call has ended"
msgstr "The call has ended"

#: src/calls/components/ScreenCall/index.tsx:290
msgid "The call is ending in"
msgstr "The call is ending in"

#: src/components/company-programs/BookAppointmentForm.tsx:150
msgid "The clinic will contact you soon to schedule an appointment."
msgstr "The clinic will contact you soon to schedule an appointment."

#: src/notifications/components/Notification/Body/Responsibility/IndividualCost.tsx:181
msgid "The individual cost won’t exceed <0>{0}</0> <1/>(your out-of-pocket max)"
msgstr "The individual cost won’t exceed <0>{0}</0> <1/>(your out-of-pocket max)"

#: src/authentication/components/SignUpScreen/index.tsx:169
msgid "The invitation you're trying to use can't be found or expired. Please start again."
msgstr "The invitation you're trying to use can't be found or expired. Please start again."

#: src/components/Error404Screen/index.tsx:110
msgid "The page you were looking for doesn’t exist"
msgstr "The page you were looking for doesn’t exist"

#: src/authentication/components/ChangePasswordScreen/index.tsx:105
msgid "The reset link you're trying to use can't be found or expired. Please start again."
msgstr "The reset link you're trying to use can't be found or expired. Please start again."

#: src/notifications/components/Notification/Body/Responsibility/Complete.tsx:157
msgid "Then your responsibility includes"
msgstr "Then your responsibility includes"

#: src/reimbursements/components/Reimbursement/Body/index.tsx:56
msgid "There is no action required from you and the review process may take up to 24 hours."
msgstr "There is no action required from you and the review process may take up to 24 hours."

#: src/reimbursements/components/Reimbursement/Body/index.tsx:69
msgid "There is no action required from you and this approved amount will be sent to your company for reimbursement."
msgstr "There is no action required from you and this approved amount will be sent to your company for reimbursement."

#: src/notifications/components/Notification/Body/BodyService.tsx:101
msgid "There is no action required from you. You’ll be able to use the coverage once Stork Club authorizes it. The processing time might take up to 24 hours."
msgstr "There is no action required from you. You’ll be able to use the coverage once Stork Club authorizes it. The processing time might take up to 24 hours."

#: src/notifications/components/Notification/Body/BodyRxRequested.tsx:57
msgid "There is no action required from you. You’ll be able to use the coverage once Stork Club authorizes it. The processing time might take up to 24 hours. <0/>If your delivery date is immediate, we will process it ASAP."
msgstr "There is no action required from you. You’ll be able to use the coverage once Stork Club authorizes it. The processing time might take up to 24 hours. <0/>If your delivery date is immediate, we will process it ASAP."

#: src/components/Payment/index.tsx:307
msgid "There was an error processing your payment.<0/>Please ensure that you have sufficient funds in your account and try again."
msgstr "There was an error processing your payment.<0/>Please ensure that you have sufficient funds in your account and try again."

#: src/notifications/components/Notification/Body/BodyBillPaymentFailed.tsx:45
msgid "There was an error while processing your payment. <0/>Please ensure that you have sufficient funds in your account and try again."
msgstr "There was an error while processing your payment. <0/>Please ensure that you have sufficient funds in your account and try again."

#: src/components/Payment/index.tsx:316
msgid "There was an unexpected error. Please try again."
msgstr "There was an unexpected error. Please try again."

#: src/reimbursement/components/ReimbursementPage/index.tsx:71
msgid "There was an unexpected error. Please try again. If this error persists, please contact our support team."
msgstr "There was an unexpected error. Please try again. If this error persists, please contact our support team."

#: src/authentication/components/onboarding/StatusScreen/index.tsx:269
#: src/components/ChangeFamilyStatus/index.tsx:316
msgid "This information will always stay private and you can always change your status later"
msgstr "This information will always stay private and you can always change your status later"

#: src/notifications/components/Notification/Body/BodyService.tsx:140
msgid "This service if provided in full will deduct <0>{bundleEquivalent} of your bundles</0>"
msgstr "This service if provided in full will deduct <0>{bundleEquivalent} of your bundles</0>"

#: src/authentication/components/SignInScreen/CheckEmailForm/index.tsx:161
msgid "This token is invalid already."
msgstr "This token is invalid already."

#: src/components/CallBooking/index.tsx:272
msgid "Times are in"
msgstr "Times are in"

#: src/calls/components/DownloadAppsPage/index.tsx:71
msgid "To join the call, download Stork Club on your mobile phone and open the call link from your mobile phone"
msgstr "To join the call, download Stork Club on your mobile phone and open the call link from your mobile phone"

#: src/notifications/components/Notification/Body/BodyService.tsx:128
msgid "To perform on <0>{0}</0>"
msgstr "To perform on <0>{0}</0>"

#: src/notifications/components/Notification/Body/BodyService.tsx:117
msgid "To perform on <0>{0}</0> until <1>{1}</1>"
msgstr "To perform on <0>{0}</0> until <1>{1}</1>"

#: src/authentication/components/onboarding/StatusScreen/index.tsx:142
msgid "To personalize your experience with curated care, experts, and resources."
msgstr "To personalize your experience with curated care, experts, and resources."

#: src/notifications/components/Notification/Body/Responsibility/Incomplete.tsx:197
msgid "To see your individual financial responsibility for this Rx, please complete your profile"
msgstr "To see your individual financial responsibility for this Rx, please complete your profile"

#: src/notifications/components/Notification/Body/Responsibility/Incomplete.tsx:202
msgid "To see your individual financial responsibility for this service, please complete your profile"
msgstr "To see your individual financial responsibility for this service, please complete your profile"

#: src/components/MobileApp/index.tsx:59
msgid "To use Stork Club on your phone, download the mobile app."
msgstr "To use Stork Club on your phone, download the mobile app."

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:510
msgid "To use your coverage, please enter your full name as it appears on your <0>medical ID card or other official ID</0>"
msgstr "To use your coverage, please enter your full name as it appears on your <0>medical ID card or other official ID</0>"

#: src/notifications/components/Notification/Body/Responsibility/Complete.tsx:165
msgid "Total Deductible"
msgstr "Total Deductible"

#: src/notifications/components/Notification/Body/BillDetails.tsx:147
#: src/notifications/components/Notification/Body/BodyRxGranted.tsx:142
#: src/notifications/components/Notification/Body/BodyService.tsx:150
msgid "Total cost"
msgstr "Total cost"

#: src/notifications/components/Notification/Body/BodyRxGranted.tsx:152
#: src/reimbursement/components/StatelessSubmitExpensesWidget/index.tsx:103
msgid "Total coverage"
msgstr "Total coverage"

#: src/notifications/components/Notification/Body/Responsibility/Incomplete.tsx:226
msgid "Total deductible"
msgstr "Total deductible"

#: src/reimbursements/components/Reimbursement/Body/index.tsx:89
msgid "Total reimbursement"
msgstr "Total reimbursement"

#: src/reimbursements/components/Reimbursement/Body/Services.tsx:81
msgid "Total:"
msgstr "Total:"

#: src/reimbursement/components/ReimbursementForm/ExpensesBlock/index.tsx:177
msgid "Total: {0}"
msgstr "Total: {0}"

#: src/care-navigator/CareNavigatorScreen/Channel/MessagesList/ListBottom/index.tsx:93
msgid "Translate all messages to {localeName}"
msgstr "Translate all messages to {localeName}"

#: src/components/Payment/index.tsx:336
msgid "Try again"
msgstr "Try again"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:490
msgid "Try again or reach out to us at <0>{companyCareEmail}</0> to verify your information."
msgstr "Try again or reach out to us at <0>{companyCareEmail}</0> to verify your information."

#: src/calls/components/ScreenCall/index.tsx:233
#: src/calls/components/ScreenCheck/index.tsx:256
msgid "Turn off"
msgstr "Turn off"

#: src/calls/components/ScreenCall/index.tsx:233
#: src/calls/components/ScreenCheck/index.tsx:256
msgid "Turn on"
msgstr "Turn on"

#: src/components/CallBooking/index.tsx:397
msgid "Type your answer here"
msgstr "Type your answer here"

#: src/authentication/components/Layout/index.tsx:227
msgid "US Privacy Policy"
msgstr "US Privacy Policy"

#: src/locales/errors.ts:23
msgid "Unknown error"
msgstr "Unknown error"

#: src/locales/errors.ts:29
msgid "Unknown error. Please reload the page"
msgstr "Unknown error. Please reload the page"

#: src/calls/components/ScreenCall/index.tsx:225
#: src/calls/components/ScreenCheck/index.tsx:248
msgid "Unmute"
msgstr "Unmute"

#: src/authentication/components/onboarding/MemberIdScreen/index.tsx:341
#: src/components/ProfilePage/EditProfile/index.tsx:457
msgid "Update"
msgstr "Update"

#: src/authentication/components/onboarding/MemberIdScreen/index.tsx:274
msgid "Update your Member ID"
msgstr "Update your Member ID"

#: src/authentication/components/onboarding/StatusScreen/index.tsx:213
msgid "Update your current family status"
msgstr "Update your current family status"

#: src/authentication/components/onboarding/StatusScreen/index.tsx:136
msgid "Update your current status"
msgstr "Update your current status"

#: src/reimbursement/components/ReimbursementForm/index.tsx:233
#: src/reimbursement/components/ReimbursementForm/index.tsx:246
msgid "Upload receipt"
msgstr "Upload receipt"

#: src/authentication/components/SignInScreen/TwoFactorCodeForm/index.tsx:177
msgid "Verification code"
msgstr "Verification code"

#: src/notifications/components/Widget/MainPageWidget.tsx:95
#: src/notifications/components/Widget/NoNewWidget.tsx:53
msgid "View all"
msgstr "View all"

#: src/reimbursement/components/ReimbursementSuccessResult/index.tsx:145
msgid "View requested expenses"
msgstr "View requested expenses"

#: src/components/HomeScreen/components/Block/index.tsx:187
msgid "View your company's policies"
msgstr "View your company's policies"

#: src/components/FamilyStatus/index.tsx:80
msgid "Want a child later"
msgstr "Want a child later"

#: src/components/FamilyStatus/index.tsx:84
msgid "Want a child now"
msgstr "Want a child now"

#: src/components/company-programs/BookAppointmentForm.tsx:161
msgid "Want to book an appointment?"
msgstr "Want to book an appointment?"

#: src/components/company-programs/BookAppointmentForm.tsx:186
msgid "Want to connect?"
msgstr "Want to connect?"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:759
msgid "Want to invite your partner? (can add later)"
msgstr "Want to invite your partner? (can add later)"

#: src/notifications/components/Notification/Body/BodyBillAvailable.tsx:101
msgid "We are processing your payment. It may take up to 7 days"
msgstr "We are processing your payment. It may take up to 7 days"

#: src/authentication/components/ForgotPasswordScreen/index.tsx:160
msgid "We will send you a recovery email"
msgstr "We will send you a recovery email"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:553
msgid "We will send you appointment reminders"
msgstr "We will send you appointment reminders"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:570
msgid "We will show the best doctors in your area"
msgstr "We will show the best doctors in your area"

#: src/authentication/components/onboarding/ConsentToSharing/index.tsx:204
msgid "We will use this shared information to provide you with more tailored and relevant services that meet your family planning needs."
msgstr "We will use this shared information to provide you with more tailored and relevant services that meet your family planning needs."

#: src/components/company-programs/BookAppointmentForm.tsx:164
msgid "We'll ask the clinic to contact you to schedule it."
msgstr "We'll ask the clinic to contact you to schedule it."

#: src/components/company-programs/BookAppointmentForm.tsx:193
msgid "We'll help you meet to decide if it's a fit"
msgstr "We'll help you meet to decide if it's a fit"

#: src/components/company-programs/BookAppointmentForm.tsx:169
msgid "We'll help you meet, to decide if it's a fit."
msgstr "We'll help you meet, to decide if it's a fit."

#: src/components/CallBooking/index.tsx:460
msgid "We're sending you an email with the details."
msgstr "We're sending you an email with the details."

#: src/components/Error500Screen/index.tsx:105
msgid "We're sorry, but something went wrong"
msgstr "We're sorry, but something went wrong"

#: src/authentication/components/SignInScreen/PasswordForm/index.tsx:214
#: src/authentication/components/SignInScreen/TwoFactorCodeForm/index.tsx:163
msgid "Welcome back"
msgstr "Welcome back"

#: src/components/CallSubjects/Tab.tsx:70
msgid "When it comes to you and your child’s health, you want to speak with the best people. So do we. All Stork Club experts were carefully chosen by our team based on these requirements:"
msgstr "When it comes to you and your child’s health, you want to speak with the best people. So do we. All Stork Club experts were carefully chosen by our team based on these requirements:"

#: src/authentication/components/onboarding/ConsentToSharing/index.tsx:174
msgid "When you use Stork Club’s services, you may voluntarily provide us with sensitive information about yourself, goals and healthcare needs when you think it's necessary, which may include:"
msgstr "When you use Stork Club’s services, you may voluntarily provide us with sensitive information about yourself, goals and healthcare needs when you think it's necessary, which may include:"

#: src/notifications/components/Notification/Body/WhyAmIGettingThisBill.tsx:14
msgid "Why am I getting this bill?"
msgstr "Why am I getting this bill?"

#: src/components/Payment/index.tsx:391
msgid "Why bank transfer (ACH)"
msgstr "Why bank transfer (ACH)"

#: src/components/FamilyStatus/index.tsx:88
msgid "Working parent"
msgstr "Working parent"

#: src/care-navigator/CareNavigatorScreen/Channel/MessageInput/index.tsx:208
msgid "Write your message here"
msgstr "Write your message here"

#: src/locales/errors.ts:5
msgid "Wrong API response"
msgstr "Wrong API response"

#: src/locales/errors.ts:11
msgid "Wrong response format"
msgstr "Wrong response format"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:704
#: src/authentication/components/SignUpScreen/SignUpForm.tsx:713
#: src/components/ProfilePage/EditProfile/index.tsx:388
#: src/components/ProfilePage/EditProfile/index.tsx:396
msgid "Year"
msgstr "Year"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:129
msgid "Year is a required field"
msgstr "Year is a required field"

#: src/care-navigator/CareNavigatorScreen/Channel/MessagesList/Message/index.tsx:187
#: src/reimbursements/components/Reimbursement/HeaderTitle.tsx:104
msgid "You"
msgstr "You"

#: src/reimbursements/components/Reimbursement/HeaderTitle.tsx:86
msgid "You <0>requested</0> expense reimbursement"
msgstr "You <0>requested</0> expense reimbursement"

#: src/notifications/components/Notification/Body/BodyService.tsx:97
msgid "You can now use these services covered by Stork Club."
msgstr "You can now use these services covered by Stork Club."

#: src/notifications/components/Notification/Body/WhyAmIGettingThisBill.tsx:17
msgid "You do not need to pay this bill to our in-network providers when receiving care or Rx. Instead, Stork Club makes it easy to pay your responsibility (e.g., deductible and/or coinsurance) via this secure system. This payment contributes to your deductible (if applicable) and out-of-pocket max for your medical plan."
msgstr "You do not need to pay this bill to our in-network providers when receiving care or Rx. Instead, Stork Club makes it easy to pay your responsibility (e.g., deductible and/or coinsurance) via this secure system. This payment contributes to your deductible (if applicable) and out-of-pocket max for your medical plan."

#: src/components/PageLayout/Sidebar/UnreadChatBadge.tsx:12
#: src/components/PageLayout/Sidebar/UnreadNotificationsBadge/index.tsx:9
msgid "You have unread message"
msgstr "You have unread message"

#: src/components/Error404Screen/index.tsx:113
msgid "You may have mistyped the address <0/>or the page may have moved"
msgstr "You may have mistyped the address <0/>or the page may have moved"

#: src/authentication/components/onboarding/MemberIdScreen/index.tsx:287
msgid "You need to add your Member ID to use fertility coverage."
msgstr "You need to add your Member ID to use fertility coverage."

#: src/components/ProfilePage/Profile/index.tsx:171
msgid "You need to update your Member ID to use fertility coverage."
msgstr "You need to update your Member ID to use fertility coverage."

#: src/authentication/components/onboarding/MemberIdScreen/index.tsx:281
msgid "You need to update your Member ID<0/>to use fertility coverage."
msgstr "You need to update your Member ID<0/>to use fertility coverage."

#: src/components/HomeScreen/components/ChangeFamilyStatus/index.tsx:25
msgid "You shared with us that you are interested in gender affirming care."
msgstr "You shared with us that you are interested in gender affirming care."

#: src/components/HomeScreen/components/ChangeFamilyStatus/index.tsx:32
msgid "You shared with us that you are interested in menopause care."
msgstr "You shared with us that you are interested in menopause care."

#: src/components/HomeScreen/components/ChangeFamilyStatus/index.tsx:36
msgid "You shared with us that you are interested in men’s Health care."
msgstr "You shared with us that you are interested in men’s health care."

#: src/components/HomeScreen/components/ChangeFamilyStatus/index.tsx:36
msgid "You shared with us that you are interested in men’s health care."
msgstr "You shared with us that you are interested in men’s health care."

#: src/components/HomeScreen/components/ChangeFamilyStatus/index.tsx:36
msgid "You shared with us that you are interested in men’s optimization care."
msgstr "You shared with us that you are interested in men’s optimization care."

#: src/components/ChangeFamilyStatus/index.tsx:24
msgid "You shared with us that you have a newborn."
msgstr "You shared with us that you have a newborn."

#: src/components/ChangeFamilyStatus/index.tsx:27
msgid "You shared with us that you want to have a child later."
msgstr "You shared with us that you want to have a child later."

#: src/components/ChangeFamilyStatus/index.tsx:31
msgid "You shared with us that you want to have a child now."
msgstr "You shared with us that you want to have a child now."

#: src/components/ChangeFamilyStatus/index.tsx:34
msgid "You shared with us that you’re a working parent."
msgstr "You shared with us that you’re a working parent."

#: src/components/ChangeFamilyStatus/index.tsx:21
msgid "You shared with us that you’re pregnant."
msgstr "You shared with us that you’re pregnant."

#: src/components/ProfilePage/EditProfile/index.tsx:231
msgid "You will receive an email with instructions for how to confirm your new email address shortly"
msgstr "You will receive an email with instructions for how to confirm your new email address shortly"

#: src/authentication/components/Info.tsx:114
msgid "You'll be able to invite your partner on the next step."
msgstr "You'll be able to invite your partner on the next step."

#: src/components/CallBooking/index.tsx:446
#: src/components/company-programs/BookAppointmentForm.tsx:147
msgid "You're all set!"
msgstr "You're all set!"

#: src/components/ProfileCompletion/Form.tsx:121
msgid "Your Medical Plan"
msgstr "Your Medical Plan"

#: src/authentication/components/SignInScreen/CheckEmailForm/index.tsx:153
msgid "Your account has been unlocked successfully. Please sign in to continue."
msgstr "Your account has been unlocked successfully. Please sign in to continue."

#: src/components/CallSubjects/Tab.tsx:101
msgid "Your appointments with experts"
msgstr "Your appointments with experts"

#: src/notifications/components/Notification/HeaderTitle.tsx:63
msgid "Your authorization for Service has <0>expired</0><1>{date}</1>"
msgstr "Your authorization for Service has <0>expired</0><1>{date}</1>"

#: src/notifications/components/Notification/HeaderTitle.tsx:57
msgid "Your authorization for Service to perform on {0} has <0>expired</0><1>{date}</1>"
msgstr "Your authorization for Service to perform on {0} has <0>expired</0><1>{date}</1>"

#: src/calls/utils/userfriendlyerror.ts:18
#: src/calls/utils/userfriendlyerror.ts:34
msgid "Your browser isn't supported."
msgstr "Your browser isn't supported."

#: src/components/CallSubjects/Call.tsx:162
msgid "Your call has been successfully canceled."
msgstr "Your call has been successfully canceled."

#: src/reimbursements/components/Reimbursement/Body/index.tsx:130
msgid "Your company will confirm eligibility and decide to\nreimburse you or your partner."
msgstr "Your company will confirm eligibility and decide to\nreimburse you or your partner."

#: src/notifications/components/Notification/Body/BodyRxGranted.tsx:98
msgid "Your covered Rx will be shipped as soon as our in-network pharmacy, SMP, contacts you to collect your shipping address and any applicable copay."
msgstr "Your covered Rx will be shipped as soon as our in-network pharmacy, SMP, contacts you to collect your shipping address and any applicable copay."

#: src/authentication/components/ActivateMembershipScreen/index.tsx:196
msgid "Your email"
msgstr "Your email"

#: src/authentication/components/SignInScreen/CheckEmailForm/index.tsx:159
msgid "Your email has been changed successfully. <0/>Please sign in using your new email to continue."
msgstr "Your email has been changed successfully. <0/>Please sign in using your new email to continue."

#: src/authentication/components/onboarding/ConsentToSharing/index.tsx:183
msgid "Your health information – such as your overall wellness, fertility status, issues or concerns, relevant medical history"
msgstr "Your health information – such as your overall wellness, fertility status, issues or concerns, relevant medical history"

#: src/notifications/components/Notification/Body/Responsibility/Complete.tsx:128
msgid "Your medical plan includes cost-sharing with individual responsibility:"
msgstr "Your medical plan includes cost-sharing with individual responsibility:"

#: src/components/HomeScreen/index.tsx:118
msgid "Your month"
msgstr "Your month"

#: src/notifications/components/Notification/Body/Responsibility/IndividualCost.tsx:71
msgid "Your out-of-pocket max is reached"
msgstr "Your out-of-pocket max is reached"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:766
msgid "Your partner's email"
msgstr "Your partner's email"

#: src/authentication/components/ChangePasswordScreen/hooks.ts:65
msgid "Your password has been changed successfully. You are now signed in."
msgstr "Your password has been changed successfully. You are now signed in."

#: src/notifications/components/Notification/Body/Responsibility/Complete.tsx:149
msgid "Your plan has changed?"
msgstr "Your plan has changed?"

#: src/authentication/components/onboarding/ConsentToSharing/index.tsx:196
msgid "Your race or ethnicity – where necessary for any medical or cultural reasons"
msgstr "Your race or ethnicity – where necessary for any medical or cultural reasons"

#: src/notifications/components/Notification/Body/Responsibility/Incomplete.tsx:220
msgid "Your responsibility includes"
msgstr "Your responsibility includes"

#: src/calls/utils/userfriendlyerror.ts:38
msgid "Your saved microphone or camera is no longer available."
msgstr "Your saved microphone or camera is no longer available."

#: src/components/CallNotifications/index.tsx:159
msgid "Your scheduled call has started."
msgstr "Your scheduled call has started."

#: src/authentication/components/onboarding/ConsentToSharing/index.tsx:190
msgid "Your sexual orientation – within the context of your family planning and fertility goals"
msgstr "Your sexual orientation – within the context of your family planning and fertility goals"

#: src/authentication/components/onboarding/StatusScreen/index.tsx:152
msgid "Your status won't hide any information, but it helps us highlight what you might be most interested in."
msgstr "Your status won't hide any information, but it helps us highlight what you might be most interested in."

#: src/authentication/components/ActivateMembershipScreen/index.tsx:196
msgid "Your work email"
msgstr "Your work email"

#: src/components/ProfilePage/AddPartner/index.tsx:201
msgid "You‘ve invited {0} to your account."
msgstr "You‘ve invited {0} to your account."

#: src/care-navigator/CareNavigatorScreen/SendBirdProvider/IsOnlineProvider.tsx:53
msgid "You’re now online"
msgstr "You’re now online"

#: src/care-navigator/CareNavigatorScreen/SendBirdProvider/IsOnlineProvider.tsx:42
msgid "You’re offline. Check your connection"
msgstr "You’re offline. Check your connection"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:576
#: src/components/ProfilePage/EditProfile/index.tsx:423
#: src/components/ProfilePage/Profile/index.tsx:313
msgid "ZIP"
msgstr "ZIP"

#: src/authentication/components/SignUpScreen/SignUpForm.tsx:134
msgid "Zip is a required field"
msgstr "Zip is a required field"

#: src/notifications/components/Notification/Body/Responsibility/Incomplete.tsx:258
msgid "after deductible"
msgstr "after deductible"

#: src/authentication/components/onboarding/MemberIdScreen/index.tsx:154
msgid "can't be blank"
msgstr "can't be blank"

#: src/notifications/components/Notification/Body/Responsibility/Complete.tsx:175
#: src/notifications/components/Notification/Body/Responsibility/CostSharing.tsx:82
#: src/notifications/components/Notification/Body/Responsibility/CostSharing.tsx:175
#: src/notifications/components/Notification/Body/Responsibility/IndividualCost.tsx:121
#: src/notifications/components/Notification/Body/Responsibility/IndividualCost.tsx:138
#: src/notifications/components/Notification/Body/Responsibility/IndividualCost.tsx:150
msgid "cannot be shown"
msgstr "cannot be shown"

#: src/notifications/components/Notification/Body/Responsibility/IndividualCost.tsx:167
msgid "depending on your remaining deductible"
msgstr "depending on your remaining deductible"

#: src/calls/components/ScreenCall/index.tsx:147
msgid "disconnected"
msgstr "disconnected"

#: src/calls/components/ScreenCall/index.tsx:154
msgid "disconnecting..."
msgstr "disconnecting..."

#: src/authentication/components/ChangePasswordScreen/index.tsx:87
#: src/authentication/components/SignUpScreen/SignUpForm.tsx:64
msgid "doesn't match Password"
msgstr "doesn't match Password"

#: src/components/ProfilePage/Profile/ChangePasswordForm/index.tsx:23
msgid "doesn't match new password"
msgstr "doesn't match new password"

#: src/components/HomeScreen/components/Post/FeaturedPost.tsx:107
#: src/components/HomeScreen/components/Post/index.tsx:133
msgid "min to read: {0}"
msgstr "min to read: {0}"

#: src/notifications/components/NotificationsPage/index.tsx:71
msgid "nothing yet"
msgstr "nothing yet"

#: src/authentication/components/SignInScreen/PasswordForm/index.tsx:230
msgid "password"
msgstr "password"

#: src/components/company-programs/BookAppointmentForm.tsx:33
#: src/components/company-programs/BookAppointmentForm.tsx:172
msgid "phone"
msgstr "phone"

#: src/notifications/components/NotificationsPage/index.tsx:152
msgid "reimbursement"
msgstr "reimbursement"

#. From "value" to "value".
#: src/notifications/components/Notification/Body/Responsibility/Incomplete.tsx:270
#: src/notifications/components/Notification/Body/Responsibility/IndividualCost.tsx:112
#: src/notifications/components/Notification/Body/Responsibility/IndividualCost.tsx:160
msgid "to"
msgstr "to"

#: src/notifications/components/Notification/Body/BillDetails.tsx:89
msgid "{0} copay after deductible"
msgstr "{0} copay after deductible"

#: src/notifications/components/Notification/Body/BillDetails.tsx:82
msgid "{0} copay{deductible}"
msgstr "{0} copay{deductible}"

#: src/notifications/components/Notification/Body/Responsibility/CostSharing.tsx:102
msgid "{0} per Rx"
msgstr "{0} per Rx"

#: src/notifications/components/Notification/Body/Responsibility/CostSharing.tsx:140
msgid "{0} per Rx,<0/>after deductible"
msgstr "{0} per Rx,<0/>after deductible"

#: src/notifications/components/Notification/Body/BillDetails.tsx:92
msgid "{0}% after deductible"
msgstr "{0}% after deductible"

#: src/notifications/components/Notification/Body/Responsibility/CostSharing.tsx:167
msgid "{coinsuranceRx}%<0/>after Deductible"
msgstr "{coinsuranceRx}%<0/>after Deductible"

#: src/notifications/components/Notification/Body/Responsibility/CostSharing.tsx:74
msgid "{costSharing}<0/>after Deductible"
msgstr "{costSharing}<0/>after Deductible"

#: src/care-navigator/CareNavigatorScreen/Channel/MessageInput/index.tsx:201
msgid "{navigatorName} typically responds within 1 business day"
msgstr "{navigatorName} typically responds within 1 business day"

#: src/notifications/components/Notification/HeaderTitle.tsx:138
msgid "{practiceName} <0>canceled</0> Service authorization"
msgstr "{practiceName} <0>canceled</0> Service authorization"

#: src/notifications/components/Notification/HeaderTitle.tsx:119
msgid "{practiceName} <0>requested</0> Service authorization"
msgstr "{practiceName} <0>requested</0> Service authorization"

#: src/reimbursements/components/ReimbursementGroup/index.tsx:181
msgid "{programName} reimbursement"
msgstr "{programName} reimbursement"
