import { i18n } from "@lingui/core";
import { t, Trans } from "@lingui/macro";
import { format as formatDate } from "date-fns";
import { FC } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../../constants";
import { formatCurrency } from "../../../../utils/currency";
import mediaQuery from "../../../../utils/mediaQuery";
import { Authorization } from "../../../models/Authorization";
import { Bill } from "../../../models/Bill";
import { UserPlan } from "../../../models/UserPlan";
import { BoldBlack, Hr, Summ } from "../style";

const CostTable = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 50% 50%;
  margin-bottom: 30px;
  padding-top: 30px;

  ${mediaQuery(
    "desktopMedium",
    css`
      grid-template-columns:
        calc((100% - 40px) / 3) calc((100% - 40px) / 3)
        calc((100% - 40px) / 3);
    `,
  )}
`;

const CostTableTitle = styled.div`
  color: #000;
  font-weight: 500;
  margin-bottom: 10px;
`;

const BillRow = styled.div`
  margin-bottom: 4px;
`;

const ServiceRow = styled.div`
  margin-bottom: 4px;
`;

const ProviderRow = styled.div`
  margin-bottom: 20px;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 10px;
    `,
  )}
`;

const ResponsibilityRow = styled.div`
  margin-bottom: 30px;
`;

const Red = styled.span`
  color: ${colors.coralBlueGreen};
`;

type Props = {
  bill: Bill;
  authorization: Authorization;
  userPlan: UserPlan;
};

const getPolicy = (isRx: boolean, userPlan: UserPlan): string => {
  const {
    copay,
    copayRx,
    coinsurance,
    coinsuranceRx,
    noDeductibleRx,
  } = userPlan;
  if (isRx) {
    const deductible = noDeductibleRx ? "" : " after deductible";

    return copayRx && copayRx > 0
      ? t`${formatCurrency(copayRx, "USD", i18n.locale, {
          maximumFractionDigits: 0,
        })} copay${deductible}`
      : `${coinsuranceRx || ""}%${deductible}`;
  }

  return copay && copay > 0
    ? t`${formatCurrency(copay, "USD", i18n.locale, {
        maximumFractionDigits: 0,
      })} copay after deductible`
    : t`${coinsurance || ""}% after deductible`;
};

export const BillDetails: FC<Props> = ({
  bill,
  authorization,
  userPlan,
}: Props) => {
  const {
    serviceDate,
    serviceName,
    providerName,
    sequentialId,
    totalAmount,
    coveredAmount,
    amount,
  } = bill;
  const isRx = authorization.practiceTemplate === "pharmacy";
  const policy = getPolicy(isRx, userPlan);

  return (
    <>
      <BillRow>
        <Trans>Bill</Trans># <BoldBlack>{sequentialId}</BoldBlack>
      </BillRow>
      {!isRx && serviceDate && (
        <ServiceRow>
          <Trans>
            Service <BoldBlack>{serviceName}</BoldBlack> completed on
          </Trans>{" "}
          <BoldBlack>
            {formatDate(serviceDate, "MM-dd-yyyy")}
          </BoldBlack>
        </ServiceRow>
      )}
      <ProviderRow>
        {isRx ? (
          <Trans>
            Provider{" "}
            <BoldBlack>Stork Club Rx (${providerName})</BoldBlack>
          </Trans>
        ) : (
          <Trans>
            Provider <BoldBlack>{providerName}</BoldBlack>
          </Trans>
        )}
      </ProviderRow>
      <ResponsibilityRow>
        <Trans>Member&apos;s financial responsibility:</Trans>{" "}
        {policy}
      </ResponsibilityRow>
      <Hr />
      <CostTable>
        <div>
          <CostTableTitle>
            <Trans>Total cost</Trans>
          </CostTableTitle>
          <Summ>
            {formatCurrency(totalAmount, "USD", i18n.locale)}
          </Summ>
        </div>
        <div>
          <CostTableTitle>
            <Trans>Covered by Health Plan</Trans>
          </CostTableTitle>
          <Summ>
            {formatCurrency(coveredAmount, "USD", i18n.locale)}
          </Summ>
        </div>
        <div>
          <CostTableTitle>
            <Red>
              <Trans>Balance due</Trans>
            </Red>
          </CostTableTitle>
          <Summ>
            <Red>{formatCurrency(amount, "USD", i18n.locale)}</Red>
          </Summ>
        </div>
      </CostTable>
    </>
  );
};
