import { CSSProperties, FC, ReactNode } from "react";
import styled from "styled-components";
import { colors } from "../../constants";
import { AlertKind, AlertStatus } from "./types";

// TODO: with icon boolean | ReactNode
export interface AlertProps {
  children: ReactNode;
  className?: string;
  kind?: AlertKind;
  status: AlertStatus;
  title?: string;
}

const AlertTitle = styled.div`
  font-size: 24px;
`;

const alertColors: Record<AlertStatus, CSSProperties["color"]> = {
  error: colors.red,
  info: colors.brownBlack,
  success: colors.green,
};
const AlertContainer = styled.div<{
  $kind: AlertKind;
  $status: AlertStatus;
}>`
  background: none;
  border: none;
  color: ${({ $status }) => alertColors[$status]};
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;

  ${AlertTitle} {
    margin-bottom: 4px;
  }
`;

const Alert: FC<AlertProps> = (props) => {
  const {
    children,
    kind = "text",
    status,
    title,
    ...extraProps
  } = props;

  const isAlertRole = status === "error" || status === "success";

  return (
    <AlertContainer
      $kind={kind}
      $status={status}
      role={isAlertRole ? "alert" : undefined}
      {...extraProps}
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {children}
    </AlertContainer>
  );
};

export default Alert;
