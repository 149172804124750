import { useEffect } from "react";
import { useMutation } from "react-query";
import { useSearchParams } from "react-router-dom";
import { unknownError } from "../../../locales/errors";
import { useAxios } from "../../../network";
import { ENDPOINTS } from "../../../network/endpoints";
import { JSONApiErrorsException } from "../../../network/jsonApi/core/JSONApiErrorsPayload";

export interface CheckEmailPayload {
  email: string;
  recaptchaToken: string;
  customerCode: string | null;
}
export function useSignUp() {
  const axios = useAxios();

  return useMutation(async (payload: CheckEmailPayload) => {
    const { email, recaptchaToken, customerCode } = payload;
    try {
      await axios.post<unknown>(`${ENDPOINTS.activateMembership}`, {
        code: customerCode || undefined,
        email,
        "g-recaptcha-response": recaptchaToken,
      });
    } catch (e: unknown) {
      if (e instanceof JSONApiErrorsException && e.errors[0]?.title) {
        throw new Error(e.errors[0]?.title);
      }

      throw unknownError;
    }

    return true;
  });
}

export function useStoreCustomerSpecificCode() {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const code = searchParams.get("code");
    if (code) {
      sessionStorage.setItem("customerCode", code);
    }
  }, [searchParams]);
}

export function useCustomerSpecificCode(): string | null {
  const [searchParams] = useSearchParams();

  return (
    searchParams.get("code") || sessionStorage.getItem("customerCode")
  );
}
