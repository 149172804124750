/* eslint-disable import/no-unresolved */ // Temporary fix for swiper
import "swiper/css";
import "swiper/css/a11y";
import "swiper/css/navigation";
import classNames from "classnames";
import {
  CSSProperties,
  HTMLAttributes,
  useCallback,
  MouseEvent,
  forwardRef,
} from "react";
import styled, { css } from "styled-components";
import { A11y, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { colors } from "../../../constants";
import mediaQuery from "../../../utils/mediaQuery";
import { CareNavigator } from "../../CallSubjects/models/CareNavigator";
import ExpertCard from "./ExpertCard";

export interface ExpertsProps
  extends Omit<HTMLAttributes<HTMLElement>, "onChange"> {
  className?: string;
  style?: CSSProperties;
  experts: readonly CareNavigator[];
  selectedExpert?: string | null;
  onChange: (expert: string | null) => void;
  timeZone: string;
}

// https://www.w3.org/WAI/ARIA/apg/example-index/Experts/Experts-1-prev-next.html
const StyledSwiper = styled(Swiper)`
  margin-inline-start: 0;
  & .swiper-slide {
    height: auto;
    width: 300px;
    ${mediaQuery(
      "greaterThanPhone",
      css`
        width: 330px;
      `,
    )}
  }
`;
const ControlButton = styled.button`
  background: white;
  border: 1px solid ${colors.brownLightGrey1};
  bottom: 0;
  height: 100%;
  margin: 0;
  top: 0;
  transition: border-color 0.15s;
  width: 80px;
  &::after {
    font-size: 24px;
  }
  &:hover {
    border-color: ${colors.coral};
    border-width: 2px;
  }
`;
const StyledExpertCard = styled(ExpertCard)`
  height: 100%;
`;
const Container = styled.div`
  position: relative;
`;
const swiperModules = [A11y, Navigation];
const Experts = forwardRef<HTMLDivElement, ExpertsProps>(
  (
    {
      experts,
      selectedExpert,
      className,
      style,
      onBlur,
      onChange,
      timeZone,
      ...restProps
    },
    ref,
  ) => {
    const handleChange = useCallback(
      (event: MouseEvent<HTMLElement>) => {
        onChange(event.currentTarget.dataset.expert_id || null);
      },
      [onChange],
    );

    return (
      <Container
        className={classNames(className, {
          "swiper-rtl": document.dir === "rtl",
        })}
        {...restProps}
        ref={ref}
      >
        <ControlButton
          className="swiper-button-prev swiper-button-expert-prev"
          type="button"
        />
        <ControlButton
          className="swiper-button-next swiper-button-expert-next"
          type="button"
        />
        <StyledSwiper
          key={experts.length}
          dir={document.dir}
          initialSlide={experts.findIndex(
            (expert) => expert.id === selectedExpert,
          )}
          modules={swiperModules}
          navigation={{
            enabled: true,
            nextEl: ".swiper-button-expert-next",
            prevEl: ".swiper-button-expert-prev",
          }}
          observer
          slidesPerView="auto"
          spaceBetween={10}
          style={style}
        >
          {experts.map((expert) => {
            return (
              <SwiperSlide key={expert.id}>
                <StyledExpertCard
                  aria-checked={selectedExpert === expert.id}
                  data-expert_id={expert.id}
                  expert={expert}
                  onBlur={onBlur}
                  onClick={handleChange}
                  role="radio"
                  selected={selectedExpert === expert.id}
                  timeZone={timeZone}
                />
              </SwiperSlide>
            );
          })}
        </StyledSwiper>
      </Container>
    );
  },
);

export default Experts;
