/* eslint-disable max-lines */
import { FC } from "react";
import { colors } from "../../../constants";
import { State } from "../../models/State";

const GrantedIcon = () => (
  <svg
    fill="none"
    height="22"
    viewBox="0 0 22 22"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="11"
      cy="11"
      r="10.25"
      stroke={colors.greenStable}
      strokeWidth="1.5"
    />
    <path
      d="M5.949 10.6331l3.5368 5.1382 6.0608-8.8083.5051-.734"
      stroke={colors.greenStable}
      strokeLinejoin="bevel"
      strokeWidth="1.5"
    />
  </svg>
);

const RequestedIcon = () => (
  <svg
    fill="none"
    height="22"
    viewBox="0 0 22 22"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="11"
      cy="11"
      r="10.25"
      stroke={colors.brownGreyStable}
      strokeWidth="1.5"
    />
    <g
      css={`
        [dir="rtl"] & {
          transform: rotate(180deg);
          transform-origin: center;
        }
      `}
    >
      <path
        d="M12.0002 15l4-4.001-4-3.999"
        stroke={colors.brownGreyStable}
        strokeWidth="1.5"
      />
      <path
        d="M5 11h10"
        stroke={colors.brownGreyStable}
        strokeLinejoin="bevel"
        strokeWidth="1.5"
      />
    </g>
  </svg>
);

const RejectedIcon = () => (
  <svg
    fill="none"
    height="22"
    viewBox="0 0 22 22"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="11"
      cy="11"
      r="10.25"
      stroke={colors.redStable}
      strokeWidth="1.5"
    />
    <path
      d="M6.85 15.1249l8.276-8.2759M15.1259 15.1539L6.8472 6.8751"
      stroke={colors.redStable}
      strokeWidth="1.5"
    />
  </svg>
);

const CanceledIcon = () => (
  <svg
    fill="none"
    height="22"
    viewBox="0 0 22 22"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="11"
      cy="11"
      r="10.25"
      stroke={colors.brownLightGrey1}
      strokeWidth="1.5"
    />
    <path
      d="M6.2856 11h9.4286"
      stroke={colors.brownLightGrey1}
      strokeWidth="1.5"
    />
  </svg>
);

export type StatusIconProps = {
  state: State;
};

export const StatusIcon: FC<StatusIconProps> = ({
  state,
}: StatusIconProps) => {
  switch (state) {
    case "approved":
      return <GrantedIcon />;
    case "requested":
      return <RequestedIcon />;
    case "rejected":
      return <RejectedIcon />;
    case "canceled":
      return <CanceledIcon />;
    default:
      return null;
  }
};
