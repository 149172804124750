import { TypeOf } from "io-ts";
import { useRef } from "react";
import styled from "styled-components";
import { ImageSet } from "../../../io-ts/types";
import useImageLoaded from "../../../utils/useImageLoaded";

interface Props {
  imageSet: TypeOf<typeof ImageSet>;
  alt: string;
}

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
`;

const Image = styled.img`
  display: block;
  height: auto;
  width: 100%;
`;

const Preview = styled.img`
  display: block;
  height: auto;
  width: 100%;
  position: absolute;
  top: 0;
  filter: blur(4px);
`;

const Cover: React.FC<Props> = ({ imageSet, alt }: Props) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const isLoaded = useImageLoaded(imageRef);
  const [width, height] = imageSet.aspect_ratio.split(":");

  return (
    <Container>
      <Image
        ref={imageRef}
        alt={alt}
        height={height}
        sizes="(max-width: 767px) 100vw, 800px"
        src={imageSet.w1600}
        srcSet={`${imageSet.w256} 256w, ${imageSet.w512} 512w, ${imageSet.w800} 800w, ${imageSet.w1600} 1600w`}
        width={width}
      />
      <Preview
        alt={alt}
        aria-hidden="true"
        height={height}
        src={imageSet.w64}
        style={{ opacity: isLoaded ? 0 : 1 }}
        width={width}
      />
    </Container>
  );
};

export default Cover;
