import { Trans } from "@lingui/macro";
import { FC, HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import Title from "../../../../lib/Title";
import mediaQuery from "../../../../utils/mediaQuery";
import Post, { HomePagePost } from "../Post";
import FeaturedPost from "../Post/FeaturedPost";

export interface PostsProps extends HTMLAttributes<HTMLElement> {
  posts: readonly HomePagePost[];
}

const PostsTitle = styled(Title)``;
const PostsContainer = styled.div`
  display: grid;
  grid-column-gap: 15px;
  grid-row-gap: 40px;
  grid-template-columns: 1fr;

  ${mediaQuery(
    "desktopMedium",
    css`
      grid-template-columns: 1fr 1fr 1fr;
    `,
  )}
`;

const Container = styled.div`
  ${PostsTitle} {
    margin-bottom: 20px;
  }
`;

const Items = styled.div`
  margin: 0 -20px 0;
  overflow: auto hidden;
  width: calc(100% + 41px);

  ${mediaQuery(
    "desktopMedium",
    css`
      display: contents;
      width: auto;
    `,
  )}
`;

const ItemsRibbon = styled.div<{ length: number }>`
  box-sizing: border-box;
  display: flex;
  gap: 20px;
  padding: 0 20px;
  width: ${({ length }) => length * 210 + 20 * (length - 1) + 40}px;

  ${mediaQuery(
    "desktopMedium",
    css`
      display: contents;
      width: auto;
    `,
  )}
`;

const Posts: FC<PostsProps> = ({ posts, ...restProps }) => {
  const [featuredPost, ...restPosts] =
    posts.length % 3 === 1
      ? [posts[0], ...posts.slice(1, posts.length)]
      : [null, ...posts];

  return (
    <Container {...restProps}>
      {posts.length && (
        <PostsTitle level="h3" styleLevel="h4">
          <Trans>Read</Trans>
        </PostsTitle>
      )}
      <PostsContainer>
        {featuredPost && (
          <FeaturedPost key={featuredPost.id} post={featuredPost} />
        )}
        {restPosts.length > 0 && (
          <Items>
            <ItemsRibbon length={restPosts.length}>
              {restPosts.map((post) => (
                <Post key={post.id} post={post} />
              ))}
            </ItemsRibbon>
          </Items>
        )}
      </PostsContainer>
    </Container>
  );
};

export default Posts;
