import { ConnectionState } from "@sendbird/chat";
import { captureException } from "@sentry/react";
import { ReactNode, useEffect, useRef } from "react";
import { useQuery } from "react-query";
import useAuthSession from "../../../authentication/hooks/useAuthSession";
import { useSendbirdLocale } from "./SendbirdLanguageProvider";
import { useSdk } from "./index";

let mountedCounter = 0;
export default function SendBirdConnector(props: {
  children: ReactNode;
}) {
  const { children } = props;
  const sdk = useSdk();

  const { data: authSession } = useAuthSession();
  if (!authSession || !authSession.user) {
    throw new Error(
      "Cannot use SendBirdConnector without authSession defined synchronously",
    );
  }
  const credentials =
    authSession.user.attributes.sendbird_credentials;

  useEffect(() => {
    mountedCounter += 1;

    return () => {
      mountedCounter -= 1;
    };
  }, []);

  // until there is at least one mounted connector, it will be used. We may think about it as a singleton
  useQuery({
    cacheTime: 500, // 500ms helps us do not reconnect on changing a page (e.g. Sidebar may be unmounted and mounted again in a short time)
    queryFn: async () => {
      // eslint-disable-next-line no-console
      console.debug("Connect to sendbird");
      if (!credentials.access_token) {
        throw new Error("No access token"); // normally we should not be here as we have a guard component above.
        // TODO: it would be great to create a UI for this case but it is not required yet
      }
      return sdk.connect(
        credentials.user_id,
        credentials.access_token,
      );
    },
    queryKey: ["SendBirdConnector", sdk.appId, credentials],
    staleTime: 500,
    suspense: true,
  });
  const { localeCode } = useSendbirdLocale();
  useEffect(() => {
    sdk
      .updateCurrentUserInfoWithPreferredLanguages([localeCode])
      .catch((err) => {
        if (err instanceof Error) {
          captureException(err);
        } else {
          captureException(
            new Error(
              "updateCurrentUserInfoWithPreferredLanguages failed",
            ),
            {
              extra: {
                err: err as unknown,
              },
            },
          );
        }
      });
  }, [localeCode, sdk]);

  const reactSideEffectCheckBypass = useRef(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      reactSideEffectCheckBypass.current = true;
    }, 0);

    return () => {
      clearTimeout(timeout);
      if (
        reactSideEffectCheckBypass.current &&
        mountedCounter === 0
      ) {
        // eslint-disable-next-line no-console
        console.debug(
          "Disconnecting from SendBird, every consumer unmounted",
        );
        if (sdk.connectionState !== ConnectionState.CLOSED) {
          sdk.disconnect().catch((err) => {
            if (err instanceof Error) {
              captureException(err);
            } else {
              captureException(
                new Error("Failed to disconnect from SendBird"),
                {
                  extra: {
                    err: err as unknown,
                  },
                },
              );
            }
          });
        }
      }
    };
  }, [sdk]);

  return <>{children}</>;
}
