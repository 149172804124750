/* eslint-disable max-lines */
import { Trans } from "@lingui/macro";
import { FC } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors, fonts } from "../../../../../constants";
import Button from "../../../../../lib/Button";
import { GreyBlock } from "../../../../../lib/GreyBlock";
import mediaQuery from "../../../../../utils/mediaQuery";
import { UserPlanIncomplete } from "../../../../models/UserPlan";
import { Hr } from "../../style";

export type Props = {
  isRx?: boolean;
  userPlan: UserPlanIncomplete;
};

const CompleteProfileTable = styled.div`
  ${mediaQuery(
    "desktopMedium",
    css`
      align-items: center;
      display: flex;
    `,
  )}
`;

const MainGrid = styled.div`
  color: ${colors.brownGrey};
  display: grid;
  grid-row-gap: 20px;
  grid-template-areas:
    "deductible oop"
    "cost-sharing individual-cost";
  line-height: 22px;
  margin-bottom: 0px;

  ${mediaQuery(
    "desktopMedium",
    css`
      grid-row-gap: 32px;
      grid-template-areas:
        "deductible cost-sharing individual-cost"
        "oop cost-sharing individual-cost";
      margin-bottom: 7px;
    `,
  )}
`;

const MainGridItem = styled.div`
  border-bottom: 1px solid ${colors.brownLightGrey1};
  padding: 0 0 20px;

  ${mediaQuery(
    "desktopMedium",
    css`
      border-bottom: none;
      padding: 0;
    `,
  )}
`;

const DeductibleContainer = styled(MainGridItem)`
  grid-area: deductible;
`;

const OopContainer = styled(MainGridItem)`
  grid-area: oop;
`;

const CostSharingContainer = styled(MainGridItem)`
  border-bottom: none;
  grid-area: cost-sharing;

  ${mediaQuery(
    "desktopMedium",
    css`
      border-left: 1px solid ${colors.brownLightGrey1};
      padding: 0 0 0 10px;
    `,
  )}
`;

const IndividualCostContainer = styled(MainGridItem)`
  border-bottom: none;
  grid-area: individual-cost;

  ${mediaQuery(
    "desktopMedium",
    css`
      border-left: 1px solid ${colors.brownLightGrey1};
      padding: 0 0 0 10px;
    `,
  )}
`;

const CompleteProfileButton = styled(Button)`
  width: 100%;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-inline-start: 45px;
      padding-inline-end: 35px;
      padding-inline-start: 35px;
      white-space: nowrap;
      width: auto;
    `,
  )}
`;

const FieldName = styled.div`
  font-weight: 500;
  margin-bottom: 6px;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 10px;
    `,
  )}
`;

const Blur = styled.span`
  filter: blur(4px);
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.86);
`;

const Title = styled.div`
  color: ${colors.brownGrey};
  font-family: ${fonts.alt};
  font-size: 19px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 20px;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 25px;
    `,
  )}
`;

const Value = styled.div`
  font-size: 19px;
  font-weight: bold;
  line-height: 22px;
`;

const PleaseCompleteText = styled.div`
  color: ${colors.black};
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 16px;
  padding-top: 10px;
  text-align: center;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 0;
      padding-top: 0;
      text-align: start;
    `,
  )}
`;

const StyledHr = styled(Hr)`
  margin: 20px 0;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin: 30px 0 25px;
    `,
  )}
`;

export const ResponsibilityBlockIncomplete: FC<Props> = ({
  isRx,
  userPlan,
}: Props) => {
  const isCopay = isRx
    ? userPlan.copayRx && userPlan.copayRx > 0
    : userPlan.copay && userPlan.copay > 0;
  const { noDeductibleRx } = userPlan;
  const withAfterDeductible =
    (isRx && !noDeductibleRx && !isCopay) || (!isRx && !isCopay);

  return (
    <GreyBlock>
      <CompleteProfileTable>
        <PleaseCompleteText>
          {isRx ? (
            <Trans>
              To see your individual financial responsibility for this
              Rx, please complete your profile
            </Trans>
          ) : (
            <Trans>
              To see your individual financial responsibility for this
              service, please complete your profile
            </Trans>
          )}
        </PleaseCompleteText>
        <CompleteProfileButton
          as={Link}
          kind="filledCoral"
          resetWidth
          to="/profile/completion/new"
        >
          <Trans>Complete profile</Trans>
        </CompleteProfileButton>
      </CompleteProfileTable>

      <StyledHr />
      <Title>
        <Trans>Your responsibility includes</Trans>
      </Title>

      <MainGrid>
        <DeductibleContainer>
          <FieldName>
            <Trans>Total deductible</Trans>
          </FieldName>
          <Value>
            $<Blur> 0,000</Blur>
          </Value>
        </DeductibleContainer>

        <OopContainer>
          <FieldName>
            <Trans>Out-of-pocket max</Trans>
          </FieldName>
          <Value>
            $<Blur> 0,000</Blur>
          </Value>
        </OopContainer>

        <CostSharingContainer>
          <FieldName>
            <Trans>Cost-sharing</Trans>
          </FieldName>
          <Value>
            {!!isCopay && (
              <>
                $<Blur>000 000 000000</Blur>
              </>
            )}
            {!isCopay && (
              <>
                <Blur>00</Blur>%
              </>
            )}
            <br />
            {withAfterDeductible && <Trans>after deductible</Trans>}
          </Value>
        </CostSharingContainer>

        <IndividualCostContainer>
          <FieldName>
            <Trans>Individual cost</Trans>
          </FieldName>
          <Value>
            $
            <Blur>
              {" "}
              0,500 <Trans>to</Trans> $000
            </Blur>
          </Value>
        </IndividualCostContainer>
      </MainGrid>
    </GreyBlock>
  );
};
