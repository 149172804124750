import type { MessageDescriptor } from "@lingui/core";
import { i18n } from "@lingui/core";

export class LocalizedError extends Error {
  constructor(private messageDescription: MessageDescriptor) {
    super(messageDescription.message || messageDescription.id);
  }

  public get localizedMessage(): string {
    return i18n._(this.messageDescription);
  }
}
