/* eslint-disable max-lines */
import {
  AriaAttributes,
  FC,
  ForwardedRef,
  TextareaHTMLAttributes,
  ReactNode,
  forwardRef,
  useCallback,
  useRef,
} from "react";
import styled, { css } from "styled-components";
import { colors, fonts } from "../../../../constants";
import { useFormFieldContext } from "../../FormField/context";

const TextAreaRaw = styled.textarea<TextAreaExtraProps>`
  background: transparent;
  border: none;
  font-family: ${fonts.main};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  outline: none;
  resize: none;
  width: 100%;

  &::placeholder {
    color: ${colors.brownGrey};
    opacity: 1; /* Firefox */
  }

  &[disabled] {
    cursor: not-allowed;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${colors.brownBlack};
      opacity: 1; /* Firefox */
    }
  }
`;

interface TextAreaExtraProps
  extends Pick<AriaAttributes, "aria-invalid">,
    Pick<TextareaHTMLAttributes<HTMLTextAreaElement>, "disabled"> {}

export interface TextAreaProps
  extends TextAreaExtraProps,
    TextareaHTMLAttributes<HTMLTextAreaElement> {
  /**
   * The suffix icon for the TextArea
   */
  suffix?: ReactNode;

  /**
   * If allow to remove textArea content with clear icon
   */
  allowClear?: boolean;

  ref?: ForwardedRef<HTMLTextAreaElement>;
}

const Border = styled.div`
  inset: 0;
  pointer-events: none;
  position: absolute;
`;

const disabledInputContainerStyle = css`
  background: ${colors.brownLightGrey2};
  border: 1px solid ${colors.brownLightGrey1};
  border-radius: 8px;
  color: ${colors.brownGrey};
  cursor: not-allowed;
`;

const Wrapper = styled.div<{
  $disabled: boolean | undefined;
  "$aria-invalid": boolean | undefined;
}>`
  background: ${colors.white};
  border-radius: 8px;
  box-sizing: border-box;
  color: ${colors.brownBlack};
  display: inline-flex;
  padding: 18px;
  position: relative;

  ${Border} {
    border: ${({ "$aria-invalid": hasError }) =>
      hasError
        ? css`2px solid ${colors.red}`
        : css`1px solid ${colors.brownLightGrey1}`};
    border-radius: 8px;
  }

  &:focus-within {
    ${Border} {
      border: 2px solid ${colors.blue};
    }
  }
  &:hover {
    ${Border} {
      border: 1px solid ${colors.brownGrey};
    }
  }
  ${({ $disabled }) =>
    $disabled ? disabledInputContainerStyle : undefined}
`;

const TextArea: FC<TextAreaProps> = forwardRef<
  HTMLTextAreaElement,
  TextAreaProps
>(
  (
    {
      suffix,
      allowClear,
      onChange,
      disabled,
      "aria-invalid": ariaInvalid,
      className,
      style,
      ...restProps
    },
    ref,
  ) => {
    const invalid = !!ariaInvalid && ariaInvalid !== "false";
    const formFieldContextValue = useFormFieldContext();

    const myOwnRef = useRef<HTMLTextAreaElement | null>(null);

    const maintainRef = useCallback(
      (element: HTMLTextAreaElement | null) => {
        myOwnRef.current = element;
        if (ref) {
          if (typeof ref === "function") {
            ref(element);
          } else {
            // eslint-disable-next-line no-param-reassign
            ref.current = element;
          }
        }
      },
      [ref],
    );

    return (
      <Wrapper
        $aria-invalid={
          invalid || formFieldContextValue["aria-invalid"]
        }
        $disabled={disabled || formFieldContextValue.disabled}
        className={className}
        style={style}
      >
        <TextAreaRaw
          {...restProps}
          ref={maintainRef}
          aria-invalid={
            invalid || formFieldContextValue["aria-invalid"]
          }
          disabled={disabled || formFieldContextValue.disabled}
          onChange={onChange}
        />
        <Border />
      </Wrapper>
    );
  },
);

export default TextArea;
