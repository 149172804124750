const scrollToEl = (el: HTMLElement) => {
  const { top } = el.getBoundingClientRect();
  const { scrollY } = window;
  // setTimeout(() => window.scrollTo(0, window.scrollY + el.getBoundingClientRect().top), 1000);
  setTimeout(() => {
    window.scrollTo({
      behavior: "smooth",
      left: 0,
      top: scrollY + top,
    });
  }, 500);
};

export default scrollToEl;
