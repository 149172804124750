export function formatCurrency(
  amount: number,
  currency: string, // USD, EUR, etc.
  locale: string,
  options?: {
    maximumFractionDigits?: number;
  },
): string {
  const formatter = new Intl.NumberFormat(locale, {
    currency,
    maximumFractionDigits:
      options?.maximumFractionDigits ?? undefined,
    style: "currency",
  });

  const result = formatter.format(amount);
  const isRTL = document.documentElement.dir === "rtl";

  if (isRTL && currency === "USD") {
    // special case for USD and ar or he. As CLDR seems to have a bug
    // Original result: &rlm;&lrm;-55,100.00&nbsp;US$ It becomes (visually) : $-55,100.00 US
    // Should be: &rlm;&lrm;-55,100.00&nbsp;&rlm;US$  It will be rendered as $US -55,100.00
    return result.replace("US", "\u200FUS");
  }
  return result;
}
