import { isLeft } from "fp-ts/Either";
import * as t from "io-ts";
import { useQuery } from "react-query";
import { UseQueryResult } from "react-query/types/react/types";
import { wrongResponseFormat } from "../../../locales/errors";
import { useAxios } from "../../../network";
import { ENDPOINTS } from "../../../network/endpoints";

const CallCategoryC = t.type({
  attributes: t.type({
    call_category_type_name: t.string,
  }),
  id: t.string,
  type: t.literal("call_category"),
});

const responseC = t.type({
  data: t.array(CallCategoryC),
});

type CallCategory = t.TypeOf<typeof CallCategoryC>;

export const useGetCallCategories = (): UseQueryResult<
  CallCategory[]
> => {
  const axios = useAxios();
  return useQuery(["calls", "getCallCategories"], async () => {
    const response = await axios.get(ENDPOINTS.getCallCategories);

    const decodeResult = responseC.decode(response.data);

    if (isLeft(decodeResult)) {
      // eslint-disable-next-line no-console
      console.error(decodeResult);
      throw wrongResponseFormat;
    }
    return decodeResult.right.data;
  });
};
