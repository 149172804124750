import { useQuery } from "react-query";
import { UseQueryResult } from "react-query/types/react/types";
import { useAxios } from "../../network";
import { Authorization } from "../models/Authorization";
import { UserPlan } from "../models/UserPlan";
import toGetNotificationsResult from "./toGetNotificationsResult";

const useGetNotifications = (): UseQueryResult<{
  authorizations: Authorization[];
  userPlan: UserPlan;
}> => {
  const axios = useAxios();
  return useQuery("getNotifications", async () => {
    const response = await axios.get(`/v2/notifications`);

    return toGetNotificationsResult(response.data);
  });
};

export default useGetNotifications;
