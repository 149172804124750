/* eslint-disable no-underscore-dangle */
import { i18n } from "@lingui/core";

const memo: {
  [key: string]: string[];
} = {};

const getMonthsForLocale = (
  monthFormat?: "2-digit" | "long" | "narrow" | "numeric" | "short",
) => {
  const localeName = i18n.locale;

  if (memo[localeName]) {
    return memo[localeName];
  }

  const format = new Intl.DateTimeFormat(localeName, {
    month: monthFormat || "long",
    // TODO: timeZone: "UTC", check if it is more correct than date: 15 (user in negative timezones)
  });
  const result = [...Array(12).keys()]
    .map((m) => format.format(new Date(Date.UTC(2021, m, 15))))
    .map(
      (str) =>
        `${str[0].toLocaleUpperCase(localeName)}${str.slice(1)}`,
    );
  memo[localeName] = result;
  return result;
};

export default getMonthsForLocale;
