import { Link } from "react-router-dom";
import styled from "styled-components";
import { ArticleCategory } from "../models/ArticleCategory";

const Container = styled(Link)<{ hex: string }>`
  background-color: ${({ hex }) => `#${hex}`};
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 0;
  padding: 0 8px;
  text-decoration: none;
`;

const CategoryBadge: React.FC<ArticleCategory> = ({
  hex,
  slug,
  name,
}: ArticleCategory) => (
  <Container hex={hex} to={`/articles/by-category/${slug}`}>
    {name}
  </Container>
);

export default CategoryBadge;
