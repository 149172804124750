/* eslint-disable no-useless-escape */
// https://html.spec.whatwg.org/multipage/input.html#email-state-(type=email)
export const emailRegexp =
  // eslint-disable-next-line max-len
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const passwordRegexp =
  /^(?=.{14,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~])/;
export const memberIdRegexp = /^[A-z\d-]+$/;

/**
 * 'some_value'                                   => undefined
 * '.htaccess'                                    => 'htaccess'
 * '../images/something.cool.jpg'                 => 'jpg'
 * 'http://www.w3schools.com/jsref/jsref_pop.asp' => 'asp'
 * 'http://stackoverflow.com/questions/680929'    => 'com/questions/680929'
 */
function getExt(string: string): string | undefined {
  const chunks = string.replaceAll("/", "").split(".");
  if (chunks.length < 2) {
    return undefined;
  }

  return chunks.pop();
}

const fileExtensions = ["doc", "docx", "pdf"];
export function isFileLink(string: string): boolean {
  const ext = getExt(string);

  return !!ext && fileExtensions.includes(ext);
}
