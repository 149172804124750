import * as t from "io-ts";
import { NumberFromString } from "../../io-ts/types";
import { CompanyPlanTypeC } from "../../network/jsonApiV2/models/specific/Company";

export const UserPlanC = t.type({
  attributes: t.union([
    t.type({
      coinsurance: NumberFromString,
      coinsurance_rx: NumberFromString,
      company_plan_name: t.string,
      company_plan_type: t.union([t.string, t.null]),
      copay: NumberFromString,
      copay_rx: NumberFromString,
      deductible: t.union([t.null, NumberFromString]),
      no_deductible_rx: t.boolean,
      oop_max: NumberFromString,
      profile_complete: t.literal(true),
    }),
    t.type({
      coinsurance: t.union([NumberFromString, t.null]),
      coinsurance_rx: t.union([NumberFromString, t.null]),
      company_plan_name: t.union([t.string, t.null]),
      company_plan_type: t.union([t.string, t.null]),
      copay: t.union([NumberFromString, t.null]),
      copay_rx: t.union([NumberFromString, t.null]),
      deductible: t.union([t.null, NumberFromString]),
      no_deductible_rx: t.union([t.boolean, t.null]),
      oop_max: NumberFromString,
      profile_complete: t.literal(false),
    }),
  ]),
  id: t.string,
  type: t.literal("user_plan"),
});

export type UserPlanRaw = t.TypeOf<typeof UserPlanC>;

export type UserPlanIncomplete = {
  coinsurance: number | null;
  coinsuranceRx: number | null;
  companyPlanName: string | null;
  companyPlanType: t.TypeOf<typeof CompanyPlanTypeC> | null;
  copay: number | null;
  copayRx: number | null;
  deductible: number | null;
  noDeductibleRx: boolean | null;
  oopMax: number | null;
  profileComplete: false;
};
export type UserPlanComplete = {
  coinsurance: number;
  coinsuranceRx: number;
  companyPlanName: string;
  companyPlanType: t.TypeOf<typeof CompanyPlanTypeC> | null;
  copay: number;
  copayRx: number;
  deductible: number | null;
  noDeductibleRx: boolean;
  oopMax: number;
  profileComplete: true;
};

export type UserPlan = UserPlanComplete | UserPlanIncomplete;
