import { FC, ReactNode, useMemo, useReducer, useState } from "react";
import { MessageProps, MessageType } from "../types";
import {
  MessagesApi,
  MessagesApiContext,
  MessagesContext,
  OpenMessageConfig,
} from "./context";

// TODO: replace with useOpaqueId from the new react
export function createIdGenerator(): {
  get: () => number;
} {
  let counter = 0;

  return {
    get: () => {
      const returnVal = counter;

      counter += 1;
      return returnVal;
    },
  };
}

interface BaseAction {
  type: string;
}
interface PushAction extends BaseAction {
  type: "push";
  message: MessageProps;
}
interface DestroyAction extends BaseAction {
  type: "destroy";
  key: number | string;
}

type Action = DestroyAction | PushAction;
function reducer(messages: readonly MessageProps[], action: Action) {
  let nextMessages;

  // it has no sense, react checks switch/case perfectly
  // eslint-disable-next-line default-case
  switch (action.type) {
    case "push": {
      const newMessages = [...messages];
      const existingMessageIndex = newMessages.findIndex(
        (message) => message.messageKey === action.message.messageKey,
      );
      if (existingMessageIndex !== -1) {
        newMessages[existingMessageIndex] = action.message;
      } else {
        newMessages.push(action.message);
      }
      nextMessages = newMessages;
      break;
    }
    case "destroy": {
      nextMessages = messages.filter(
        (m) => m.messageKey !== action.key,
      );
      break;
    }
  }

  return nextMessages;
}

const MessagesHost: FC<{ children?: ReactNode }> = ({ children }) => {
  const [messages, dispatch] = useReducer(reducer, []);
  const [idGenerator] = useState(() => createIdGenerator());
  const [idPrefix] = useState(() =>
    Math.floor(Math.random() * 100000),
  );

  const api: MessagesApi = useMemo(() => {
    const pushMessage = (
      type: MessageType,
      config: OpenMessageConfig,
    ): void => {
      const {
        closable = true,
        className,
        onClick,
        content,
        duration = 7000,
        key,
        style,
      } = config;
      dispatch({
        message: {
          className,
          closable,
          content,
          duration,
          messageKey:
            key || `${String(idPrefix)}_${idGenerator.get()}`,
          onClick,
          style,
          type,
        },
        type: "push",
      });
    };
    const info: MessagesApi["info"] = (config) => {
      pushMessage("info", config);
    };
    const critical: MessagesApi["critical"] = (config) => {
      pushMessage("critical", config);
    };
    const success: MessagesApi["success"] = (config) => {
      pushMessage("success", config);
    };
    const error: MessagesApi["error"] = (config) => {
      pushMessage("error", config);
    };
    const destroy: MessagesApi["destroy"] = (key) => {
      dispatch({ key, type: "destroy" });
    };

    return {
      critical,
      destroy,
      error,
      info,
      success,
    };
  }, [idGenerator, idPrefix]);

  return (
    <MessagesApiContext.Provider value={api}>
      <MessagesContext.Provider value={messages}>
        {children}
      </MessagesContext.Provider>
    </MessagesApiContext.Provider>
  );
};

export default MessagesHost;
