export const ENDPOINTS = {
  activateMembership: "/v2/email_signup",
  cancelCall: "/v2/calls/:id",
  cancelReimbursement: "/v2/reimbursements/:id",
  checkEmail: "/v2/session/strategies",
  checkEmailChangeToken: "/v2/confirmation",
  checkResetPasswordToken: `/v2/password/check_reset_token`,
  checkSignUpToken: "/v2/registration/check_signup_token",
  extendCall: "/v2/call_expert/calls/:token/extend",
  forgotPassword: `/v2/password`,
  getCallCategories: "/v2/company/call_categories",
  getCallCategory: "/v2/company/call_categories/:id",
  getCallDates: "/v2/call_experts/:id/available_dates",
  getCallSubject: "/v2/company/call_subjects/:id",
  getCallTimeSlots: "/v2/call_experts/:id/available_times",
  getCallsLive: "/v2/calls/live",
  getCallsUpcoming: "/v2/calls/upcoming",
  getHomePage: "/v2/homepage",
  getProfile: "/v2/session",
  getReimbursementNotifications: "/v2/reimbursements",
  programCategories: "/v2/company/programs/categories",
  programs: "/v2/company/programs",
  registration: "/v2/registration",
  reimbursementsSubmit: "/v2/reimbursements",
  resetPassword: `/v2/password`,
  scheduleCall: "/v2/calls",
  signIn: "/v2/session",
  signOut: "/v2/session",
  updateDataSharingConsent: "/v2/profile/update_data_sharing_consent",
  updateDefaultHomePageTab: "/v2/profile/update_default_home_tab",
  updatePreferredLanguage: "/v2/profile/update_preferred_language",
  updateProfileStatus: "/v2/profile/update_status",
  verifyTwoFactorCode: "/v2/session/verify_two_factor",
};
