import * as t from "io-ts";

const JSONApiResourceIdentifierC = t.intersection(
  [
    t.type({
      type: t.string,
    }),
    t.partial({
      /**
       * Optional, because client can omit this parameter in case of creating new entities
       */
      id: t.string, // TODO: make it strictly required in included documents?
    }),
  ],
  `JSONApiResourceIdentifier`,
);

export type JSONApiResourceIdentifier = t.TypeOf<
  typeof JSONApiResourceIdentifierC
>;
export default JSONApiResourceIdentifierC;
