import { t, Trans } from "@lingui/macro";
import { FC } from "react";
import PageTitle from "../../../lib/PageTitle";
import Title from "../../../lib/Title";
import ScrollHandler from "../../ScrollHandler";
import { useGetArticlesSearch } from "../hooks/useGetArticles";
import Items from "../Items";

const CategoryBlock: FC<{ q: string }> = ({ q }) => {
  const { data, isLoading } = useGetArticlesSearch(q);

  const pageTitle = (
    <PageTitle>{t`Search results for ${q}`}</PageTitle>
  );
  if (data?.length === 0 && !isLoading)
    return (
      <>
        {pageTitle}
        <Title level="h2" styleLevel="h1">
          <Trans>Nothing can be found</Trans>
        </Title>
      </>
    );

  return (
    <ScrollHandler key={q}>
      {pageTitle}
      <Items
        data={data}
        isLoading={isLoading}
        title={t`Search results for "${q}"`}
      />
    </ScrollHandler>
  );
};

export default CategoryBlock;
