import { Trans } from "@lingui/macro";
import { FC } from "react";
import { Authorization } from "../../../models/Authorization";
import { BoldBlack } from "../style";

type Props = {
  authorization: Authorization;
};

export const Blank: FC<Props> = ({ authorization }: Props) => {
  const { practiceName, title } = authorization;
  return (
    <>
      <div>
        <Trans>
          By <BoldBlack>{practiceName}</BoldBlack> for{" "}
          <BoldBlack>{title}</BoldBlack>
        </Trans>
      </div>
    </>
  );
};
