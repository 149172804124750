import { i18n } from "@lingui/core";
import { Messages } from "@lingui/core/dist/index";
import { I18nProvider, useLingui } from "@lingui/react";
import { FC, Fragment, ReactNode, Suspense, useState } from "react";
import { sendLocaleToRN } from "../../utils/react-native";
import {
  CommonLocaleName,
  fallbackToCommonRules,
  ApplicationLocaleName,
  applicationLocales,
  sourceLocaleCode,
} from "./config";
import normalizeLocaleName from "./config/normalizeLanguageName";

import {
  applyVisibilityRestrictions,
  calculateLocale,
  getBrowserLanguage,
  getLanguageFromURL,
  getLocaleFromLocalStorage,
  saveLocaleToLocalStorage,
} from "./languageDetectors";
import updateFrontendLocale from "./updateFrontendLocale";

i18n.on("missing", (event) => {
  // eslint-disable-next-line no-console
  console.debug("Missing translation", event);
});

export function getInitialLocale() {
  return calculateLocale(
    [
      getLanguageFromURL,
      getLocaleFromLocalStorage,
      () =>
        applyVisibilityRestrictions(
          getBrowserLanguage(),
          fallbackToCommonRules,
        ),
    ],
    Object.keys(applicationLocales) as ApplicationLocaleName[],
    fallbackToCommonRules,
    sourceLocaleCode,
  );
}

const localesCache = new Map<
  ApplicationLocaleName,
  Messages | Promise<Messages>
>();

function useMessages(locale: ApplicationLocaleName) {
  const { messages } = applicationLocales[locale];

  if (!localesCache.has(locale)) {
    const promise = messages().then((data) => {
      localesCache.set(locale, data);
      return data;
    });
    localesCache.set(locale, promise);
    /* eslint-disable-next-line @typescript-eslint/no-throw-literal */
    throw promise;
  } else {
    const cached = localesCache.get(locale);
    if (cached instanceof Promise) {
      /* eslint-disable-next-line @typescript-eslint/no-throw-literal */
      throw cached;
    } else if (!cached) {
      throw new Error(`No messages for locale ${locale}`);
    }
    return cached;
  }
}
export function initializeLocale(
  locale: ApplicationLocaleName,
  messages: Messages,
) {
  i18n.load(locale, messages);
  i18n.activate(locale);
  const localeToSend = toBackendLocale(locale);

  sendLocaleToRN(localeToSend || "en");
  saveLocaleToLocalStorage(locale);
}

export interface TranslationsProviderProps {
  children: ReactNode;
  /**
   * Locale to use. If not provided, will be detected automatically.
   */
  locale?: ApplicationLocaleName;
}
export const TranslationsProvider: FC<TranslationsProviderProps> = ({
  children,
  locale,
}) => {
  const safeLocale = locale || getInitialLocale();
  const messages = useMessages(safeLocale);
  useState(() => initializeLocale(safeLocale, messages));
  return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
};

const StorybookTranslationsSynchronizer = ({
  children,
}: {
  children: ReactNode;
}) => {
  const context = useLingui();

  return <Fragment key={context.i18n.locale}>{children}</Fragment>;
};
export const StorybookTranslationsProvider: FC<
  TranslationsProviderProps
> = ({ children, locale }) => {
  return (
    <Suspense fallback={<div>Loading translations</div>}>
      <TranslationsProvider locale={locale}>
        <StorybookTranslationsSynchronizer>
          {children}
        </StorybookTranslationsSynchronizer>
      </TranslationsProvider>
    </Suspense>
  );
};

export function fromBackendLocale(
  locale: string,
  region: string,
): string {
  // if locale is already in the format of "en-US", "de-DE", etc, then just return it. Right now it handles only zh-XX locales as all other locales are in the format of "en", "de", etc.
  return locale.includes("-") || locale.includes("_")
    ? locale
    : normalizeLocaleName(`${locale}-${region}`);
}

/**
 * Format used by backend and react-native
 * @param locale
 */
export function toBackendLocale(locale: string): string {
  const match = locale.match(/^([a-zA-Z]{2})([_-]+([a-zA-Z]{2}))?$/);
  if (!match) {
    return locale; // wrong format, it is more safe to return it as is
  }
  const [, language, , region] = match;
  if (language === "zh") {
    return `${language.toLowerCase()}${region ? `-${region}` : ""}`;
  }
  return language.toLowerCase();
}

export function syncWithBackendLocale(
  locale: string,
  region: string,
) {
  if (import.meta.env.STORYBOOK) {
    // do nothing in storybook
    return;
  }
  const localeWithRegion = fromBackendLocale(locale, region);
  if (localeWithRegion === i18n.locale) {
    // everything is fine
    return;
  }
  let localeToSave: ApplicationLocaleName;
  if (localeWithRegion in applicationLocales) {
    // we have special language for this region
    localeToSave = localeWithRegion as ApplicationLocaleName;
  }
  // we do not have special language for this region, but we have a fallback
  else if (locale.toLowerCase() in fallbackToCommonRules) {
    localeToSave =
      fallbackToCommonRules[locale.toLowerCase() as CommonLocaleName];
  }
  // we do not have a fallback for the region
  else {
    // TODO: update the value on the backend side
    return;
  }

  if (localeToSave !== i18n.locale) {
    updateFrontendLocale(localeToSave);
  }
}
