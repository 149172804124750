import * as React from "react";
import { BaseIconAttributes } from "./types";

export const Minus: React.FC<BaseIconAttributes> = ({
  width = "1em",
  height = "1em",
  mainColor = "currentColor",
  ...restProps
}) => (
  <svg
    {...restProps}
    fill="none"
    height={height}
    viewBox="0 0 10 2"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.285706 1H9.71428"
      stroke={mainColor}
      strokeWidth="1.5"
    />
  </svg>
);
