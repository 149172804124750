import { normalizeError } from "./normalizeError";
import type { MappedErrors } from "./types";

export default function defaultErrorParser(
  error: unknown,
): MappedErrors {
  return {
    fieldErrors: {},
    restErrors: [normalizeError(error).message],
  };
}
