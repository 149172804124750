import * as t from "io-ts";
import JSONApiLinkC from "../../JSONApiLink";

const JSONApiRelationshipsRelatedLinkC = t.type({
  /**
   *  a related resource link
   */
  related: JSONApiLinkC,
});

export type JSONApiRelationshipsRelatedLink = t.TypeOf<
  typeof JSONApiRelationshipsRelatedLinkC
>;

export default JSONApiRelationshipsRelatedLinkC;
