/* eslint-disable max-lines */
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { FC, useEffect, useState } from "react";
import {
  useNavigate,
  Link as ReactRouterLink,
} from "react-router-dom";
import useAuthSession from "../../../authentication/hooks/useAuthSession";
import Input from "../../../lib/forms/primitives/Input";
import Link from "../../../lib/Link";
import { useMessagesApi } from "../../../lib/messages";
import Title from "../../../lib/Title";
import { JSONApiErrorsException } from "../../../network/jsonApi/core/JSONApiErrorsPayload";
import { ProfileEntityWithRelations } from "../../../network/jsonApiV2/models/specific/Profile";
import { formatDate } from "../../../utils/date-fns";
import { mapJSONApiErrors } from "../../../utils/errors";
import { GENDERS, SEX_OPTIONS } from "../../../utils/formOptions";
import ScrollHandler from "../../ScrollHandler";
import EditProfile from "../EditProfile";
import { useUpdateProfile } from "../hooks";
import ChangePasswordForm from "./ChangePasswordForm";
import {
  Container,
  TitleContainer,
  Content,
  Wrapper,
  Comment,
  Alert,
  ActionText,
  AlertMessage,
  MarginButton,
  Item,
  Label,
  InputWrapper,
  StyledButton,
} from "./style";

type Props = {
  data: ProfileEntityWithRelations;
  editMode?: boolean | undefined;
};

const Profile: FC<Props> = (props) => {
  const { data, editMode = false } = props;

  const { mutate: updateProfile } = useUpdateProfile();
  const [memberId, setMemberId] = useState<string>(
    data.attributes.member_id || "",
  );

  const [changePasswordActive, setChangePasswordActive] =
    useState<boolean>(false);

  const { data: authSession } = useAuthSession();
  const isUS: boolean =
    authSession?.user?.company?.attributes?.country_code === "US";

  useEffect(() => {
    setMemberId(data.attributes.member_id || "");
  }, [data.attributes.member_id]);

  const messagesApi = useMessagesApi();

  const [memberIdError, setMemberIdError] = useState<
    string | undefined
  >(undefined);

  const { i18n } = useLingui();

  const navigate = useNavigate();

  return (
    <Wrapper>
      {editMode ? (
        <ScrollHandler key={String(editMode)}>
          <EditProfile data={data} />
        </ScrollHandler>
      ) : (
        <ScrollHandler key={String(editMode)}>
          <Container>
            <TitleContainer>
              <Title level="h2">
                <Trans>My profile</Trans>
              </Title>

              <StyledButton
                as={ReactRouterLink}
                kind="outlinedCoral"
                resetWidth
                to="/profile/edit"
              >
                <Trans>Edit</Trans>
              </StyledButton>
            </TitleContainer>
            <Content>
              <Item fullWide>
                <Label>
                  <Trans>Email</Trans>
                </Label>
                <p>{data.attributes.email}</p>
                {data.attributes.unconfirmed_email && (
                  <Comment>
                    <Trans>
                      New email {data.attributes.unconfirmed_email}{" "}
                      waiting for confirmation
                    </Trans>
                  </Comment>
                )}
              </Item>
              <Item fullWide>
                <Label>
                  <Trans>Password</Trans>
                </Label>
                {changePasswordActive ? (
                  <ChangePasswordForm
                    minPasswordLength={
                      data.attributes.password_min_length
                    }
                    onSuccess={() => {
                      setChangePasswordActive(false);
                      messagesApi.success({
                        content: t`Password changed successfully`,
                      });
                    }}
                  />
                ) : (
                  <ActionText
                    onClick={() => setChangePasswordActive(true)}
                  >
                    <Trans>Change</Trans>
                  </ActionText>
                )}
              </Item>
              <Item
                fullWide
                style={{
                  display:
                    data.attributes.ask_member_id ||
                    !!data.attributes.member_id
                      ? undefined
                      : "none",
                }}
              >
                <Label>
                  <Trans>Member ID</Trans>
                </Label>
                {data.attributes.member_id ? (
                  <p>{data.attributes.member_id}</p>
                ) : (
                  <>
                    <Alert>
                      <AlertMessage>
                        {data.attributes.notice_member_id_change ? (
                          <Trans>
                            You need to update your Member ID to use
                            fertility coverage.
                          </Trans>
                        ) : (
                          <Trans>
                            Member ID is necessary to fill out to
                            start using fertility coverage
                          </Trans>
                        )}
                      </AlertMessage>
                      {data.attributes.notice_member_id_change ? (
                        <Trans>
                          If you’re enrolled in the new medical plan
                          with JAMF, please type in your Member ID
                          from your BCBS medical card.
                        </Trans>
                      ) : (
                        <Trans>
                          If you&lsquo;re enrolled in the medical
                          plan, the ID is on your primary health
                          insurance medical card. Otherwise, leave
                          this blank.
                        </Trans>
                      )}
                    </Alert>
                    <InputWrapper
                      error={memberIdError}
                      hideLabel
                      label="Member ID"
                    >
                      <Input
                        css="width: 100%;"
                        id="member_id"
                        onChange={(event) =>
                          setMemberId(event.currentTarget.value)
                        }
                        placeholder={t`Member ID`}
                        type="text"
                        value={memberId}
                      />
                    </InputWrapper>
                    <MarginButton
                      kind="filledCoral"
                      onClick={() =>
                        updateProfile(
                          {
                            member_id: memberId,
                          },
                          {
                            onError: (error) => {
                              if (
                                error instanceof
                                JSONApiErrorsException
                              ) {
                                const { errors } = error;
                                const mappedErrors = mapJSONApiErrors(
                                  errors,
                                  {
                                    member_id:
                                      "data/attributes/member_id",
                                  },
                                );
                                if (mappedErrors.member_id) {
                                  setMemberIdError(
                                    mappedErrors.member_id.title,
                                  );
                                  return;
                                }
                              }

                              setMemberIdError(
                                t`Something went wrong`,
                              );
                            },
                            onSuccess: () => {
                              setMemberIdError(undefined);
                            },
                          },
                        )
                      }
                      resetWidth
                    >
                      <Trans>Add</Trans>
                    </MarginButton>
                  </>
                )}
              </Item>
              <Item fullWide>
                <Label>
                  <Trans>Preferred name</Trans>
                </Label>
                {!!data.attributes.preferred_name && (
                  <p>{data.attributes.preferred_name}</p>
                )}
                {!data.attributes.preferred_name && (
                  <ActionText
                    onClick={() => navigate("/profile/edit")}
                  >
                    <Trans>Add in edit mode</Trans>
                  </ActionText>
                )}
              </Item>
              <Item>
                <Label>
                  <Trans>Biological sex</Trans>
                </Label>
                <p style={{ textTransform: "capitalize" }}>
                  {(() => {
                    const sexOption = SEX_OPTIONS.find(
                      (g) => g.value === data.attributes.sex,
                    );
                    return sexOption
                      ? i18n._(sexOption.label)
                      : data.attributes.sex;
                  })()}
                </p>
              </Item>
              <Item>
                <Label>
                  <Trans>Gender identity</Trans>
                </Label>
                <p style={{ textTransform: "capitalize" }}>
                  {(() => {
                    const genderOption = GENDERS.find(
                      (g) => g.value === data.attributes.gender,
                    );
                    return genderOption
                      ? i18n._(genderOption.label)
                      : data.attributes.gender;
                  })()}
                </p>
              </Item>
              <Item>
                <Label>
                  <Trans>Date of birth</Trans>
                </Label>
                <p>{formatDate(data.attributes.dob)}</p>
              </Item>
              <Item>
                <Label>
                  {isUS ? (
                    <Trans>ZIP</Trans>
                  ) : (
                    <Trans>Postal code</Trans>
                  )}
                </Label>
                <p>{data.attributes.zip}</p>
              </Item>
              <Item mobileFullWide>
                <Label>
                  <Trans>Employer</Trans>
                </Label>
                <p>{data.relationships.company.attributes.name}</p>
              </Item>
              <Item mobileFullWide>
                <Label>
                  <Trans>Personal email</Trans>
                </Label>
                {data.attributes.personal_email ? (
                  <p>{data.attributes.personal_email}</p>
                ) : (
                  <>
                    <Link
                      state={{
                        focus: "personal_email",
                      }}
                      to="/profile/edit"
                    >
                      <Trans>Add in edit mode</Trans>
                    </Link>
                    <Comment>
                      <Trans>
                        Recovery email to restore access to your
                        account
                      </Trans>
                    </Comment>
                  </>
                )}
              </Item>
            </Content>
          </Container>
        </ScrollHandler>
      )}
    </Wrapper>
  );
};

export default Profile;
