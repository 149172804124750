import { FC } from "react";
import styled from "styled-components";
import { colors } from "../../../../constants";
import { Text } from "../../../../lib/Text";
import { Service } from "../../../models/Service";

type Props = {
  item: Service;
  isRejected?: boolean;
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 36px auto;
  margin-bottom: 2px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Description = styled.div`
  color: ${colors.brownBlack};
  grid-column: 1 / 3;
  line-height: 16px;
`;

const CoveredItem: FC<Props> = ({ isRejected, item }: Props) => {
  const { serviceName, amount, description } = item;
  const { brownGrey } = colors;

  return (
    <Container>
      <Text color={isRejected ? brownGrey : "#000"} textType="body">
        x{amount}
      </Text>
      <Text color={isRejected ? brownGrey : "#000"} textType="body">
        {serviceName}
      </Text>
      {isRejected && (
        <Description>
          <Text color={colors.brownBlack} textType="smallText">
            {description}
          </Text>
        </Description>
      )}
    </Container>
  );
};

export default CoveredItem;
