/* eslint-disable max-lines */
import styled, { css } from "styled-components";
import { colors, fonts } from "../../../constants";
import hexColorOpacity from "../../../utils/hexColorOpacity";
import mediaQuery from "../../../utils/mediaQuery";

const getContainerColor = (
  isExpanded: boolean,
  isSeen: boolean,
): string => {
  if (isExpanded) return "transparent";
  return isSeen ? "rgba(245, 243, 241, 0.5)" : "#ffffff";
};

const getBorderColor = ({
  isExpanded,
  isSeen,
}: {
  isExpanded: boolean;
  isSeen: boolean;
}): string => {
  if (isExpanded) return colors.brownLightGrey1;
  return isSeen ? "transparent" : "rgba(224, 221, 217, 0.6)";
};

export const NotificationContainer = styled.div<{
  isExpanded?: boolean;
  $isSeen: boolean;
}>`
  align-items: center;
  background: ${({ isExpanded, $isSeen }) =>
    getContainerColor(!!isExpanded, !!$isSeen)};
  border-color: ${({ isExpanded, $isSeen }) =>
    getBorderColor({
      isExpanded: !!isExpanded,
      isSeen: !!$isSeen,
    })};
  border-radius: 10px;
  border-style: solid;
  ${({ isExpanded }) =>
    !!isExpanded &&
    "box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.05);"}
  border-width: 1px;
  box-sizing: border-box;
  color: ${colors.brownBlack};
  display: block;
  font-family: ${fonts.main};
  font-size: 15px;
  line-height: 1.5;
  margin-bottom: 5px;
  position: relative;
  text-decoration: none;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 4px;
    `,
  )}

  &:hover {
    border-color: ${hexColorOpacity(colors.brownGrey, 0.4)};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const getHeaderBackground = ({
  state,
  isExpanded,
}: {
  state: string;
  isExpanded: boolean;
}): string => {
  if (!isExpanded) return "transparent";

  switch (state) {
    case "approved":
      return hexColorOpacity(colors.greenStable, 0.06);
    case "rejected":
      return hexColorOpacity(colors.redStable, 0.08);
    default:
      return hexColorOpacity(colors.brownLightGrey2, 0.5);
  }
};

export const Header = styled.div<{
  state: string;
  isExpanded?: boolean;
}>`
  align-items: center;
  background: ${({ state, isExpanded }) =>
    getHeaderBackground({ isExpanded: !!isExpanded, state })};
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  margin: 1px 2px 0;
  padding-block: 9px 10px;
  padding-inline: 7px 5px;
  transition: background 0.3s ease;
  user-select: none;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin: 2px 2px 0;
      padding-block: 15px 16px;
      padding-inline: 17px 14px;
    `,
  )}
`;

export const HeaderIcon = styled.div`
  align-self: start;
  margin-inline-end: 7px;

  svg {
    transform: translateY(0);

    ${mediaQuery(
      "desktopMedium",
      css`
        transform: translateY(4px);
      `,
    )}
  }

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-inline-end: 12px;
    `,
  )}
`;

export const ExpandIcon = styled.div<{
  isWhiteBg?: boolean;
  isFlip?: boolean;
  shouldHover?: boolean;
}>`
  align-items: center;
  background: ${({ isWhiteBg }) =>
    isWhiteBg ? colors.white : "transparent"};
  border-radius: 50%;
  box-shadow: ${({ isWhiteBg }) =>
    isWhiteBg ? "0px 0px 12px rgba(0, 0, 0, 0.07)" : "none"};
  color: ${colors.brownGrey};
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 30px;
  justify-content: center;
  transform: rotate(${({ isFlip }) => (isFlip ? "-180" : "0")}deg);
  transition: all 0.3s ease;
  user-select: none;
  width: 30px;

  ${({ shouldHover }) =>
    shouldHover
      ? css`
          ${Header}:hover & {
            background: ${colors.white};
            box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.07);
          }
        `
      : ""}
`;

export const BodyInner = styled.div`
  padding: 15px 15px 5px;

  ${mediaQuery(
    "desktopMedium",
    css`
      padding: 25px 40px 20px;
    `,
  )}
`;

export const Bold = styled.span`
  font-weight: 500;
`;

export const BoldBlack = styled(Bold)`
  color: ${colors.black};
`;

export const Hr = styled.div`
  background: ${colors.brownLightGrey1};
  height: 1px;
  width: 100%;
`;

export const BrownGrey = styled.span`
  color: ${colors.brownGrey};
`;

export const Summ = styled.div<{ color?: string }>`
  color: ${({ color }) => color || colors.brownGrey};
  font-size: 24px;
  font-weight: bold;
`;
