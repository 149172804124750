import * as t from "io-ts";
import {
  DateFromString,
  ISODate,
  NumberFromString,
} from "../../io-ts/types";
import { StatusC } from "./Status";

export const BillC = t.type({
  attributes: t.type({
    amount: NumberFromString,
    covered_amount: NumberFromString,
    due_soon: t.boolean,
    overdue: t.boolean,
    payment_due_date: t.union([DateFromString, t.null]),
    payment_soon_due_date: t.union([DateFromString, t.null]),
    processed_at: t.union([DateFromString, t.null]),
    provider: t.type({
      provider_name: t.string,
      // provider_template: t.union([t.literal("default"), t.literal("pharmacy")]),
    }),
    requested_at: DateFromString,
    sequential_id: t.string,
    service: t.type({
      service_date: ISODate,
      service_name: t.string,
    }),
    start_processing_at: t.union([DateFromString, t.null]),
    state: StatusC,
    total_amount: NumberFromString,
  }),
  id: t.string,
  type: t.literal("bill"),
});

export type BillRaw = t.TypeOf<typeof BillC>;

export type Bill = {
  amount: number;
  coveredAmount: number;
  dueSoon: boolean;
  id: string;
  overdue: boolean;
  paymentDueDate: Date | null;
  paymentSoonDueDate: Date | null;
  processedAt: Date | null;
  providerName: string;
  // isRx: boolean;
  requestedAt: Date | null;
  sequentialId: string;
  serviceDate: Date | null;
  serviceName: string;
  startProcessingAt: Date | null;
  totalAmount: number;
  url: string;
  // state: BillState;
};
