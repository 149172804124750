import styled, { css } from "styled-components";
import { colors, fonts } from "../../constants";
import FormField, {
  LabelText,
  Error,
} from "../../lib/forms/FormField";
import { GreyBlock } from "../../lib/GreyBlock";
import mediaQuery from "../../utils/mediaQuery";

export const Bottom = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: flex-start;
  margin-top: 40px;
`;

export const SubmitContainer = styled.div`
  width: 100%;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      width: 210px;
    `,
  )}
`;

export const GreyBlockCustom = styled(GreyBlock)`
  padding: 20px 10px 20px;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      padding: 30px;
    `,
  )}
`;

export const FormTitle = styled.div`
  font-family: ${fonts.alt};
  font-size: 24px;
  font-weight: 800;
  line-height: 24px;
  margin-bottom: 20px;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 36px;
    `,
  )}
`;

export const FieldSets = styled.div`
  > div + div {
    margin-top: 16px;
  }
`;

export const MemberIdTextContainer = styled.div`
  display: block;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      width: 290px;
    `,
  )}
`;

export const Description = styled.div`
  color: ${colors.brownBlack};
  font-size: 15px;
  line-height: 140%;
  margin-bottom: 20px;
`;

export const RadioFormField = styled(FormField)`
  ${LabelText} {
    margin-bottom: 20px;
  }
  ${Error} {
    margin-top: 15px;
  }
`;
