import { Trans } from "@lingui/macro";
import * as React from "react";
import styled, { css } from "styled-components";
import { colors } from "../constants";
import {
  updateHighContrastSetting,
  highContrastMode,
} from "../constants/colors";
import mediaQuery from "../utils/mediaQuery";
import Switcher from "./forms/primitives/Switcher";

const Container = styled.label`
  align-items: center;
  background: none;
  background: rgba(245, 243, 241, 0.8);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  height: 50px;
  padding: 0 10px;

  ${mediaQuery(
    "phone",
    css`
      display: none;
    `,
  )}

  @media (forced-colors: active) {
    display: none;
  }
`;

const Text = styled.span`
  color: ${colors.brownBlack};
  margin: 0 20px 0 8px;
`;

const AccissibilityLogo = () => (
  <svg
    fill="none"
    height="22"
    viewBox="0 0 22 22"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11Z"
      fill={colors.brownBlack}
    />
    <path
      d="M11 6.2375C10.625 6.2375 10.3031 6.10313 10.0344 5.83438C9.76562 5.56563 9.63125 5.24375 9.63125 4.86875C9.63125 4.49375 9.76562 4.17187 10.0344 3.90312C10.3031 3.63437 10.625 3.5 11 3.5C11.375 3.5 11.6969 3.63437 11.9656 3.90312C12.2344 4.17187 12.3687 4.49375 12.3687 4.86875C12.3687 5.24375 12.2344 5.56563 11.9656 5.83438C11.6969 6.10313 11.375 6.2375 11 6.2375ZM8.99375 18.5V8.50625C8.14375 8.44375 7.32187 8.35625 6.52812 8.24375C5.73438 8.13125 4.975 7.9875 4.25 7.8125L4.53125 6.6875C5.59375 6.9375 6.65 7.11563 7.7 7.22188C8.75 7.32812 9.85 7.38125 11 7.38125C12.15 7.38125 13.25 7.32812 14.3 7.22188C15.35 7.11563 16.4062 6.9375 17.4688 6.6875L17.75 7.8125C17.025 7.9875 16.2656 8.13125 15.4719 8.24375C14.6781 8.35625 13.8562 8.44375 13.0063 8.50625V18.5H11.8813V13.625H10.1187V18.5H8.99375Z"
      fill="white"
    />
  </svg>
);

const IncreaseContrast: React.FC = () => {
  const [isChecked, setIsChecked] =
    React.useState<boolean>(highContrastMode);

  const onChangeHandler = React.useCallback(() => {
    setIsChecked(!highContrastMode);

    // wait a little, until animation played
    setTimeout(() => {
      updateHighContrastSetting(!highContrastMode);
    }, 200);
  }, []);

  return (
    <Container>
      <AccissibilityLogo />
      <Text>
        <Trans>Increase contrast</Trans>
      </Text>
      <Switcher checked={isChecked} onChange={onChangeHandler} />
    </Container>
  );
};

export default IncreaseContrast;
