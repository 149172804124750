import { FC, HTMLAttributes, ReactNode } from "react";
import styled from "styled-components";

export const Wrapper = styled.header`
  background-color: #fff;
  padding: 30px var(--content-margin-side);
`;

const Container = styled.div`
  max-width: var(--content-max-width);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export interface HeaderProps {
  children: ReactNode | ReactNode[];
}

const Header: FC<HeaderProps & HTMLAttributes<HTMLElement>> = ({
  children,
  ...restProps
}) => {
  return (
    <Wrapper>
      <Container {...restProps}>{children}</Container>
    </Wrapper>
  );
};

export default styled(Header)``;
