import { isLeft } from "fp-ts/Either";
import * as t from "io-ts";
import { useQuery } from "react-query";
import { UseQueryResult } from "react-query/types/react/types";
import { DateFromString } from "../../io-ts/types";
import {
  unknownError,
  wrongResponseFormat,
} from "../../locales/errors";
import { useAxios } from "../../network";
import { JSONApiErrorsException } from "../../network/jsonApi/core/JSONApiErrorsPayload";

const CallC = t.type({
  attributes: t.type({
    booked_at: DateFromString,
    call_subject_type_name: t.string,
    ends_at: DateFromString,
    member_name: t.string,
    note: t.string,
    twilio_access_token: t.string,
  }),
  id: t.string,
  type: t.literal("call"),
});

const responseC = t.type({
  data: CallC,
});

type UseGetCallResult = t.TypeOf<typeof CallC>;

export const useGetCall = (
  token: string,
): UseQueryResult<UseGetCallResult> => {
  const axios = useAxios();
  return useQuery(["getCall", token], async () => {
    try {
      const response = await axios.get(
        `/v2/call_expert/calls/${token}`,
      );

      const decodeResult = responseC.decode(response.data);

      if (isLeft(decodeResult)) {
        // eslint-disable-next-line no-console
        console.error(decodeResult);
        throw wrongResponseFormat;
      }
      return decodeResult.right.data;
    } catch (e: unknown) {
      if (e instanceof JSONApiErrorsException && e.errors[0]?.title) {
        throw new Error(e.errors[0]?.title);
      }
      throw unknownError;
    }
  });
};
