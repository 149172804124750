import { isLeft } from "fp-ts/Either";
import { CompanyPlanTypeC } from "../../network/jsonApiV2/models/specific/Company";
import { UserPlan, UserPlanRaw } from "../models/UserPlan";

export const getUserPlan = (raw: UserPlanRaw): UserPlan => {
  let companyPlanType: UserPlan["companyPlanType"];
  if (raw.attributes.company_plan_type) {
    const decodedCompanyPlanType = CompanyPlanTypeC.decode(
      raw.attributes.company_plan_type,
    );
    if (isLeft(decodedCompanyPlanType)) {
      throw new Error("Company plan type is invalid");
    } else {
      companyPlanType = decodedCompanyPlanType.right;
    }
  } else {
    companyPlanType = null;
  }
  if (raw.attributes.profile_complete) {
    return {
      coinsurance: raw.attributes.coinsurance,
      coinsuranceRx: raw.attributes.coinsurance_rx,
      companyPlanName: raw.attributes.company_plan_name,
      companyPlanType,
      copay: raw.attributes.copay,
      copayRx: raw.attributes.copay_rx,
      deductible: raw.attributes.deductible,
      noDeductibleRx: raw.attributes.no_deductible_rx,
      oopMax: raw.attributes.oop_max,
      profileComplete: true,
    };
  }

  return {
    coinsurance: raw.attributes.coinsurance,
    coinsuranceRx: raw.attributes.coinsurance_rx,
    companyPlanName: raw.attributes.company_plan_name,
    companyPlanType,
    copay: raw.attributes.copay,
    copayRx: raw.attributes.copay_rx,
    deductible: raw.attributes.deductible,
    noDeductibleRx: raw.attributes.no_deductible_rx,
    oopMax: raw.attributes.oop_max,
    profileComplete: false,
  };
};
