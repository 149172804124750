import * as t from "io-ts";

export const AllProfileStatuses = [
  "planning_later",
  "planning_now",
  "expecting_a_baby",
  "having_a_newborn",
  "working_parent",
  "menopause",
  "mens_optimization",
  "gender_affirming_care",
] as const;
export const ProfileStatusC = t.union([
  t.literal(AllProfileStatuses[0]),
  t.literal(AllProfileStatuses[1]),
  t.literal(AllProfileStatuses[2]),
  t.literal(AllProfileStatuses[3]),
  t.literal(AllProfileStatuses[4]),
  t.literal(AllProfileStatuses[5]),
  t.literal(AllProfileStatuses[6]),
  t.literal(AllProfileStatuses[7]),
]);

export const fertilityCareStatuses: ProfileStatus[] = [
  "planning_later",
  "planning_now",
  "expecting_a_baby",
  "having_a_newborn",
  "working_parent",
];

export const hormoneCareStatuses: ProfileStatus[] = [
  "menopause",
  "mens_optimization",
  "gender_affirming_care",
];

export type ProfileStatus = t.TypeOf<typeof ProfileStatusC>;
