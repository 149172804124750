import { Trans } from "@lingui/macro";
import React from "react";
import styled, { css } from "styled-components";
import { fonts } from "../../constants";
import { LogoColor } from "../../lib/Logo";
import mediaQuery from "../../utils/mediaQuery";
import {
  MobileButtonAndroid,
  MobileButtonIOS,
} from "./MobileAppButton";

const Container = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  inset: 0;
  justify-content: center;
  padding: 0 20px 60px;
  position: fixed;
  width: 100%;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      padding: 0;
    `,
  )}
`;

const Text = styled.div`
  color: #000;
  font-family: ${fonts.faktum};
  font-size: 22px;
  font-weight: 500;
  line-height: 1.4;
  margin: 60px 0 40px;
  max-width: 550px;
  text-align: center;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      font-size: 30px;
    `,
  )}
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
`;

const MobileApp: React.FC = () => {
  return (
    <Container>
      <LogoColor />
      <Text>
        <Trans>
          To use Stork Club on your phone, download the mobile app.
        </Trans>
      </Text>
      <Buttons>
        <MobileButtonIOS />
        <MobileButtonAndroid />
      </Buttons>
    </Container>
  );
};

export default MobileApp;
