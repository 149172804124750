import { css } from "styled-components";
import { colors } from "../constants";

export const disabled = css`
  opacity: 0.4;
  pointer-events: none;
  user-select: none;
`;

export const truncate = (width = "100%") => css`
  max-width: ${width};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const goldenListMixin = css`
  ul,
  ol {
    counter-reset: myCounter;
  }

  ol {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      margin: 12px 0;
      padding: 0;
      padding-inline-start: 46px;
      position: relative;

      &::before {
        background-color: ${colors.gold};
        border-radius: 13px;
        color: #000;
        content: counter(myCounter);
        counter-increment: myCounter;
        display: block;
        font-size: 13px;
        height: 25px;
        inset-inline-start: 0;
        line-height: 25px;
        padding-top: 1px;
        position: absolute;
        text-align: center;
        top: 0;
        width: 26px;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      margin: 12px 0;
      padding: 0;
      padding-inline-start: 30px;
      position: relative;

      &:before {
        background-color: ${colors.gold};
        border-radius: 5px;
        content: "";
        display: block;
        height: 10px;
        inset-inline-start: 0;
        position: absolute;
        top: 7px;
        width: 10px;
      }
    }
  }
`;

export const programsMarkdownMixin = css`
  hr {
    border: none;
    height: 5px;
  }

  em {
    color: #000;
    font-style: normal;
  }

  h1 {
    margin-bottom: 28px;
  }

  h2 {
    margin-bottom: 15px;
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }
  }
`;
