/* eslint-disable import/no-unresolved */ // Temporary fix for swiper
import "swiper/css";
import "swiper/css/a11y";
import "swiper/css/navigation";
import classNames from "classnames";
import {
  CSSProperties,
  forwardRef,
  HTMLAttributes,
  useMemo,
} from "react";
import styled, { css } from "styled-components";
import { A11y, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { colors } from "../../../constants";
import Alert from "../../../lib/Alert";
import Skeleton from "../../../lib/Skeleton";
import { Text } from "../../../lib/Text";
import Title from "../../../lib/Title";
import {
  formatDayAndMonthTZ,
  formatWeekday,
} from "../../../utils/date-fns";
import mediaQuery from "../../../utils/mediaQuery";
import { isMobile } from "../../../utils/mobileRedirect";
import renderError from "../../../utils/renderError";
import { cardStylesMixin } from "../cardStylesMixin";
import { useAvailableCallDates } from "../hooks/useAvailableCallDates";

export interface DatesProps
  extends Omit<HTMLAttributes<HTMLElement>, "onChange"> {
  className?: string;
  style?: CSSProperties;
  selectedDate?: Date | null;
  selectedExpert: string;
  onChange: (date: Date | null) => void;
  timeZone: string;
}
const DayOfWeek = styled(Text)`
  color: #4a4a4a;
`;
const DateString = styled(Title)``;
const Container = styled.div`
  position: relative;
`;

// https://www.w3.org/WAI/ARIA/apg/example-index/Dates/Dates-1-prev-next.html
const StyledSwiper = styled(Swiper)`
  margin-inline-start: 0;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      padding: 0 20px;
    `,
  )}

  & .swiper-slide {
    height: auto;
    width: 330px;
  }
  .swiper-slide {
    ${cardStylesMixin};

    align-items: stretch;
    display: flex;

    flex-direction: column;
    height: 95px;
    justify-content: stretch;
    width: 110px;

    ${DayOfWeek} {
      border-bottom: 1px solid ${colors.brownLightGrey1};
      flex-basis: 40%;
    }
    ${DateString} {
      flex-basis: 70%;
    }
    ${DayOfWeek}, ${DateString} {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }
`;
const ControlButton = styled.button`
  background: white;
  border: 1px solid ${colors.brownLightGrey1};
  bottom: 0;
  height: 100%;
  margin: 0;
  top: 0;
  transition: border-color 0.15s;
  width: 80px;
  &::after {
    font-size: 24px;
  }
  &:hover {
    border-color: ${colors.coral};
    border-width: 2px;
  }
`;

const StyledSkeleton = styled(Skeleton)`
  max-width: var(--content-max-width);
  padding: 0 var(--content-margin-side);

  ${mediaQuery(
    "desktopMedium",
    css`
      padding: 0;
    `,
  )}
`;

const swiperModules = [A11y, Navigation];
const Dates = forwardRef<HTMLDivElement, DatesProps>(
  (
    {
      selectedExpert,
      selectedDate,
      className,
      style,
      onBlur,
      onChange,
      timeZone,
      ...restProps
    },
    ref,
  ) => {
    const { data, isLoading, error } = useAvailableCallDates(
      selectedExpert,
      timeZone,
    );
    const dates = useMemo(() => {
      return data || [];
    }, [data]);
    return (
      <Container
        {...restProps}
        ref={ref}
        className={classNames(className, {
          "swiper-rtl": document.dir === "rtl",
        })}
      >
        <StyledSkeleton active loading={isLoading}>
          {!!error && (
            <Alert status="error">{renderError(error)}</Alert>
          )}
          <ControlButton
            className="swiper-button-prev swiper-button-date-prev"
            type="button"
          />
          <ControlButton
            className="swiper-button-next swiper-button-date-next"
            type="button"
          />
          <StyledSwiper
            key={dates.length}
            dir={document.dir}
            initialSlide={dates.findIndex(
              (date) => date.getTime() === selectedDate?.getTime(),
            )}
            modules={swiperModules}
            navigation={{
              enabled: true,
              nextEl: ".swiper-button-date-next",
              prevEl: ".swiper-button-date-prev",
            }}
            observer
            preventClicksPropagation={false}
            preventInteractionOnTransition={false}
            slidesPerView={isMobile ? 3.5 : "auto"}
            slideToClickedSlide
            spaceBetween={10}
            style={style}
          >
            {dates.map((date) => {
              const checked =
                selectedDate?.getTime() === date.getTime();
              return (
                <SwiperSlide
                  key={date.getTime()}
                  aria-checked={checked}
                  data-testid="SwiperSlide-testId"
                  onBlur={onBlur}
                  onClick={() => {
                    onChange(date);
                  }}
                  onKeyUp={(event) => {
                    // for a1y we need to support space and enter, not just click
                    if (event.key === "Enter" || event.key === " ") {
                      onChange(date);
                    }
                  }}
                  role="radio"
                  tabIndex={0}
                >
                  <DayOfWeek textType="smallText">
                    {formatWeekday(date, timeZone)}
                  </DayOfWeek>
                  <DateString as="div" styleLevel="h3">
                    {formatDayAndMonthTZ(date, timeZone, "MMM dd")}
                  </DateString>
                </SwiperSlide>
              );
            })}
          </StyledSwiper>
        </StyledSkeleton>
      </Container>
    );
  },
);

export default Dates;
