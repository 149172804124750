import { FC, HTMLAttributes } from "react";
import styled from "styled-components";
import Block, { HomePageBlock } from "../Block";

export interface BlocksProps extends HTMLAttributes<HTMLElement> {
  blocks: readonly HomePageBlock[];
}

const Container = styled.div`
  padding: 0 0 20px;
`;

const Blocks: FC<BlocksProps> = ({ blocks, ...restProps }) => {
  return (
    <Container {...restProps}>
      {blocks.map((block) => (
        <Block key={block.id} block={block} />
      ))}
    </Container>
  );
};

export default Blocks;
