import { createContext, useContext } from "react";

export interface FormFieldContextValue {
  "aria-invalid"?: boolean;
  disabled?: boolean;
}
export const FormFieldContext = createContext<FormFieldContextValue>(
  {},
);

export function useFormFieldContext() {
  return useContext(FormFieldContext);
}
