import { useMutation, UseMutationResult } from "react-query";
import { useAxios } from "../../../network";
import { ENDPOINTS } from "../../../network/endpoints";

export interface ForgotPasswordPayload {
  email: string;
  recaptchaToken: string;
  // authenticity_token
  // g-recaptcha-response
}
export function useForgotPassword(): UseMutationResult<
  unknown,
  unknown,
  ForgotPasswordPayload
> {
  const axios = useAxios();
  return useMutation(async ({ email, recaptchaToken }) => {
    await axios.post<unknown>(
      `${ENDPOINTS.forgotPassword}`,
      {
        email,
        "g-recaptcha-response": recaptchaToken,
      },
      {
        validateStatus: (status) => status === 204,
      },
    );
    return true;
  });
}
