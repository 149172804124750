import styled, { css } from "styled-components";
import { colors } from "../../../../constants";
import mediaQuery from "../../../../utils/mediaQuery";

export const FirstRow = styled.div`
  color: ${colors.brownGrey};
  margin-bottom: 20px;
`;

export const PracticeRow = styled.div`
  line-height: 24px;
  margin-bottom: 10px;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 0;
    `,
  )}
`;

export const ServiceDateRow = styled.div``;

export const BoldBlack = styled.span`
  color: ${colors.black};
  font-weight: 500;
`;

export const ServicesContainer = styled.div`
  border: 1px solid ${colors.brownLightGrey1};
  border-radius: 10px;
  box-sizing: border-box;
  margin: 25px -10px 0;
  padding: 15px;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin: 20px -20px 0;
      padding: 15px 20px;
    `,
  )}
`;

export const ServicesStatus = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: 500;
  margin-bottom: 10px;
`;

export const TableWrapper = styled.div`
  border-bottom: 1px solid ${colors.brownLightGrey1};
  margin-bottom: 15px;
  padding-bottom: 15px;

  &:last-child {
    border: none;
    margin: 0;
    padding: 0;
  }
`;

export const ServicesTable = styled.table<{ rejected?: boolean }>`
  border-collapse: collapse;
  width: 100%;

  td {
    color: ${({ rejected }) =>
      rejected ? colors.brownGrey : colors.black};
    margin: 0;
    padding-bottom: 10px;
    padding-top: 0;
    vertical-align: top;

    &:first-child {
      padding-inline-end: 30px;
    }
    &:last-child {
      width: 100%;
    }
  }

  tr:last-child td {
    padding-bottom: 0;
  }
`;

export const ServiceRejectReason = styled.div`
  color: ${colors.brownBlack};
  font-size: 12px;
  line-height: 16px;
`;

export const RequestedStateContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;

  ${mediaQuery(
    "desktopMedium",
    css`
      padding: 0;
    `,
  )}
`;

export const RequestedTotal = styled.div`
  color: ${colors.black};
  font-weight: 700;
`;

export const CancelButton = styled.span`
  color: ${colors.brownGrey};
  cursor: pointer;
  text-decoration: underline;
`;

export const Bold = styled.span`
  font-weight: 500;
`;

export const TotalReimbursement = styled.div`
  border-top: 1px solid ${colors.brownLightGrey1};
  font-weight: 500;
  margin-top: 20px;
  padding-top: 25px;
`;

export const TotalReimbursementAmount = styled.div`
  color: ${colors.black};
  font-size: 19px;
  font-weight: 700;
  margin-top: 6px;
`;
