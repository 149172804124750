import { Trans } from "@lingui/macro";
import { FC, HTMLAttributes } from "react";
import { useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import useAuthSession from "../../../authentication/hooks/useAuthSession";
import { colors } from "../../../constants";
import mediaQuery from "../../../utils/mediaQuery";
import CookieYesButton from "../../CookieYesButton";

const Wrapper = styled.div<{
  isProfileRoute: boolean;
}>`
  display: ${({ isProfileRoute }) =>
    isProfileRoute ? "block" : "none"};
  padding: 0 var(--content-margin-side);
  box-sizing: border-box;
  width: 100%;
  margin: 60px 0 50px; // On mobile we have some popups at the bottom

  ${mediaQuery(
    "greaterThanPhone",
    css`
      display: block;
      margin: 50px 0 0;
      padding-bottom: 30px;
    `,
  )}
`;

const Container = styled.div`
  border-top: 1px solid ${colors.brownLightGrey1};
  color: ${colors.brownGrey};
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  font-weight: 700;
  justify-content: space-between;
  max-width: var(--content-max-width);
  padding-top: 20px;
  row-gap: 20px;
  width: 100%;

  ${mediaQuery(
    "desktopMedium",
    css`
      flex-wrap: nowrap;
      padding-top: 30px;
      row-gap: 0;
    `,
  )}
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 13px;
  gap: 10px;
  line-height: 16px;

  &:first-child {
    border-inline-end: 1px solid ${colors.brownLightGrey1};
    box-sizing: border-box;
    order: 1;
    padding-inline-end: 15px;
    width: 50%;
  }

  &:nth-child(2) {
    border-top: 1px solid ${colors.brownLightGrey1};
    order: 3;
    padding-top: 20px;
    width: 100%;
  }

  &:last-child {
    box-sizing: border-box;
    order: 2;
    padding-inline-start: 20px;
    width: 50%;
  }

  a,
  button {
    color: ${colors.blue};
    font-weight: 700;
    text-align: start;
  }

  ${mediaQuery(
    "desktopMedium",
    css`
      gap: 8px;
      padding: 0;

      &:first-child {
        border: none;
        order: 1;
        width: auto;
      }

      &:nth-child(2) {
        border: none;
        order: 2;
        padding-top: 0;
        width: auto;
      }

      &:last-child {
        order: 3;
        width: auto;
      }
    `,
  )}
`;

const Footer: FC<HTMLAttributes<HTMLElement>> = ({
  ...restProps
}) => {
  const { data: authSession } = useAuthSession();
  const location = useLocation();

  const isProfileRoute =
    location.pathname.split("/profile").length > 1;

  const isUS: boolean =
    authSession?.user?.company?.attributes?.country_code === "US";

  return (
    <Wrapper {...restProps} isProfileRoute={isProfileRoute}>
      <Container>
        <Col>
          <Trans>
            <span className="force-ltr-to-right-align" dir="auto">
              © Stork Club Fertility, Inc.
            </span>
          </Trans>
          <a
            href="https://joinstorkclub.com/legal/tos"
            rel="noreferrer"
            target="_blank"
          >
            <Trans>Terms of Service</Trans>
          </a>
        </Col>
        <Col
          css={`
            white-space: nowrap;
          `}
        >
          <span>
            <Trans>
              Send questions at{" "}
              <a href="mailto: care@joinstorkclub.com">
                care@joinstorkclub.com
              </a>
            </Trans>
          </span>
        </Col>
        <Col>
          {isUS ? (
            <>
              <a
                href="https://joinstorkclub.com/legal/privacy"
                rel="noreferrer"
                target="_blank"
              >
                <Trans>Privacy Policy</Trans>
              </a>
              <a
                href="https://joinstorkclub.com/legal/hipaa"
                rel="noreferrer"
                target="_blank"
              >
                <Trans>HIPAA</Trans>
              </a>
            </>
          ) : (
            <>
              <a
                href="https://joinstorkclub.com/legal/privacy_global"
                rel="noreferrer"
                target="_blank"
              >
                <Trans>Global Privacy Notice</Trans>
              </a>
            </>
          )}
          <CookieYesButton />
        </Col>
      </Container>
    </Wrapper>
  );
};

export default styled(Footer)``;
