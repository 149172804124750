import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { ReactNode, useMemo } from "react";
import {
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import { formSchema, FormState } from "./types";

export default function ReimbursementFormProvider({
  children,
  preselectedProgramId,
}: {
  children: ReactNode;
  preselectedProgramId?: string;
}) {
  const resolver = useMemo(() => {
    return yupResolver(formSchema);
  }, []);
  const methods = useForm({
    defaultValues: {
      expenses: [{}],
      id: preselectedProgramId,
    },
    resolver,
  });
  return <FormProvider {...methods}>{children}</FormProvider>;
}

export function useReimbursementFormContext() {
  return useFormContext<FormState>();
}
