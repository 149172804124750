import { SetupWorker } from "msw";

const workerModuleP: Promise<SetupWorker | null> =
  import.meta.env.VITE_MSW_ENABLED === "enabled"
    ? import("./mockServiceWorker/browser").then((module) => {
        // eslint-disable-next-line no-console
        console.debug("API mocked");
        return module.default;
      })
    : Promise.resolve(null);

export default async function patchApi(): Promise<void> {
  const { VITE_BACKEND } = import.meta.env;
  if (!VITE_BACKEND) {
    throw new Error("Specify VITE_BACKEND");
  }

  const workerModule = await workerModuleP;
  if (workerModule) {
    await workerModule.start({
      onUnhandledRequest(req) {
        const isCallToAPI = req.url.pathname.startsWith(VITE_BACKEND);
        if (isCallToAPI) {
          // eslint-disable-next-line no-console
          console.warn(
            "Found an unhandled %s request to %s",
            req.method,
            req.url.href,
          );
        }
      },
    });
  }
}
