import { i18n } from "@lingui/core";
import { captureException } from "@sentry/react";
import { useMemo } from "react";
import { getSwitcherLocale } from "../../../locales/appLocale/config";

export function useSendbirdLocale(): {
  localeCode: string;
  localeName: string;
  translationsEnabled: boolean; // either language is english, and we do not need to translate or the feature is not enabled at all.
} {
  const translationsEnabled =
    import.meta.env.VITE_SENDBIRD_TRANSLATIONS_ENABLED === "true"; // i18n.locale !== "en";
  return useMemo(() => {
    if (translationsEnabled) {
      const result = getSendbirdLocale(i18n.locale);
      if (result) {
        return {
          ...result,
          translationsEnabled,
        };
      }
    }

    return {
      localeCode: "en",
      localeName: "English",
      translationsEnabled: false,
    };
  }, [translationsEnabled]);
}

export function getSendbirdLocale(appLocale: string):
  | {
      localeCode: string;
      localeName: string;
    }
  | undefined {
  const translationsEnabled =
    import.meta.env.VITE_SENDBIRD_TRANSLATIONS_ENABLED === "true"; // i18n.locale !== "en";
  if (!translationsEnabled) {
    return undefined;
  }

  const switcherLocale = getSwitcherLocale(appLocale);

  if (!switcherLocale) {
    captureException(new Error("Locale is not mapped to Sendbird"), {
      extra: {
        appLocale,
        i18n: i18n.locale,
      },
      level: "warning",
    });
    return undefined;
  }

  return {
    localeCode: switcherLocale.sendbirdLocale,
    localeName: switcherLocale.getLabel(),
  };
}
