import * as t from "io-ts";
import { useMutation } from "react-query";
import { useAxios } from "../../../network";

const UnlockAccountC = t.type({
  unlock_token: t.string,
});

type UnlockAccountEntity = t.TypeOf<typeof UnlockAccountC>;

export function useUnlockAccount() {
  const axios = useAxios();

  return useMutation(
    async (payload: UnlockAccountEntity): Promise<void> => {
      await axios.put<unknown>(`/v2/unlock`, payload);
    },
  );
}
