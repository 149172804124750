import { isLeft } from "fp-ts/Either";
import * as t from "io-ts";
import reporter from "io-ts-reporters";
import { useQuery } from "react-query";
import { wrongResponseFormat } from "../../../locales/errors";
import { useAxios } from "../../../network";
import { ENDPOINTS } from "../../../network/endpoints";
import { CallC } from "../../CallSubjects/models/Call";

const responseC = t.type({
  data: t.array(CallC),
});

export const useGetCallsUpcoming = () => {
  const axios = useAxios();
  return useQuery("callsUpcoming", async () => {
    const response = await axios.get(ENDPOINTS.getCallsUpcoming);

    const decodeResult = responseC.decode(response.data);

    if (isLeft(decodeResult)) {
      // eslint-disable-next-line no-console
      console.error(reporter.report(decodeResult));
      throw wrongResponseFormat;
    }
    return decodeResult.right;
  });
};

export const useGetCallsLive = (enabled: boolean) => {
  const axios = useAxios();
  return useQuery(
    "callsLive",
    async () => {
      const response = await axios.get(ENDPOINTS.getCallsLive);

      const decodeResult = responseC.decode(response.data);

      if (isLeft(decodeResult)) {
        // eslint-disable-next-line no-console
        console.error(reporter.report(decodeResult));
        throw wrongResponseFormat;
      }
      return decodeResult.right;
    },
    { enabled, refetchInterval: 60 * 1000 },
  );
};
