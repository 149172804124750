import { Trans } from "@lingui/macro";
import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors } from "../../constants";
import Button from "../../lib/Button";
import Title from "../../lib/Title";
import mediaQuery from "../../utils/mediaQuery";
import { CallSubjectEntityWithRelations } from "./models/CallSubject";
import styles from "./styles.module.css";

const Links = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  font-size: 18px;
  justify-content: flex-end;
  margin: 0;
  min-width: 158px;
  padding-left: 0;
`;

const Container = styled.div`
  background-color: #fff;
  border: 1px solid ${colors.brownLightGrey1};
  border-radius: 10px;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  display: block;
  margin-bottom: 20px;
  padding: 20px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  ${mediaQuery(
    "desktopMedium",
    css`
      display: flex;
      margin-bottom: 26px;
      padding-block: 30px;
      padding-inline-end: 20px;
      padding-inline-start: 40px;

      ${Links} {
        padding-inline-start: 50px;
      }
    `,
  )}
`;

const Info = styled.div`
  flex: 1;
  overflow: hidden;
`;

const Text = styled.div`
  margin-bottom: 25px;
`;

const Description = styled.div`
  color: ${colors.brownGrey};
  margin-bottom: 25px;
`;

const Images = styled.div`
  display: flex;
  margin-bottom: 42px;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 10px; /* images 40px height. button: 50px. It was easier than change a layout completely and align with a button properly. */
    `,
  )}
`;

const IMAGE_SIZE = `40px`;
const Image = styled.div`
  border-radius: 50%;
  flex-shrink: 0;
  height: ${IMAGE_SIZE};
  margin-inline-end: 10px;
  overflow: hidden;
  position: relative;
  width: ${IMAGE_SIZE};
  img {
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
`;

const Covered = styled.div`
  color: ${colors.brownGrey};
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.7px;
  line-height: 20px;
  margin-bottom: 10px;
  text-align: center;
  text-transform: uppercase;
`;

const Subject: React.FC<CallSubjectEntityWithRelations> = ({
  id,
  attributes,
  relationships,
}: CallSubjectEntityWithRelations) => (
  <Container>
    <Info>
      <Text>
        <Title css="margin-bottom: 3px" level="h3">
          {attributes.call_subject_type_name}
        </Title>
        <Description>{attributes.talk_to_expert_text}</Description>
      </Text>
      <Images className={styles.maskedOverflow}>
        {relationships.call_experts.map((item) => {
          if (!item.attributes.image_set) {
            return null;
          }
          return (
            <Image key={item.id}>
              <img
                alt={item.attributes.name}
                sizes={IMAGE_SIZE}
                src={item.attributes.image_set.w128}
                srcSet={`${item.attributes.image_set.w64} 64w, ${item.attributes.image_set.w128} 128w`}
              />
            </Image>
          );
        })}
      </Images>
    </Info>
    <Links>
      <Covered>
        <Trans>Covered by employer</Trans>
      </Covered>

      <Button
        as={Link}
        kind="filledCoral"
        to={`/schedule/calls/new?category=${attributes.call_subject_type}`}
      >
        <Trans>Schedule a call</Trans>
      </Button>
    </Links>
  </Container>
);

export default Subject;
