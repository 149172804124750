import * as t from "io-ts";
import JSONApiRelationshipsC from "../JSONApiRelationships";
import JSONApiResourceIdentifierC from "../JSONApiResourceIdentifier";
import JSONApiResourceObjectLinksC from "./JSONApiResourceObjectLinks";

const JSONApiResourceObjectC = t.intersection(
  [
    JSONApiResourceIdentifierC,
    t.partial({
      attributes: t.UnknownRecord,
      links: JSONApiResourceObjectLinksC,
      relationships: t.record(t.string, JSONApiRelationshipsC),
    }),
  ],
  `JSONApiResourceObject`,
);

/**
 * Unknown included element in case backend included something we don't know about yet
 */
export const IncludedJSONApiResourceObjectC = t.intersection(
  [
    t.type(
      {
        id: t.string,
        type: t.string,
      },
      `IncludedJSONApiResourceIdentifier`,
    ),
    t.type({
      attributes: t.UnknownRecord,
    }),
  ],
  "IncludedJSONApiResourceObjectC",
);
export type JSONApiResourceObject = t.TypeOf<
  typeof JSONApiResourceObjectC
>;
export default JSONApiResourceObjectC;
