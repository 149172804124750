import { useMutation, useQueryClient } from "react-query";
import { useAxios } from "../../../network";
import { ENDPOINTS } from "../../../network/endpoints";

export const useCancelCall = () => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  return useMutation(
    async (id: string) => {
      await axios.delete(ENDPOINTS.cancelCall.replace(":id", id));

      return true;
    },
    {
      onSuccess: () => {
        queryClient
          .invalidateQueries("calls")
          // eslint-disable-next-line no-console
          .catch((err) => console.error(err));
      },
    },
  );
};
