import { t } from "@lingui/macro";
import { HTMLAttributes, lazy, useCallback } from "react";
import { Controller } from "react-hook-form";
import styled, { css } from "styled-components";
import { colors } from "../../../../../constants";
import FormField from "../../../../../lib/forms/FormField";
import Input from "../../../../../lib/forms/primitives/Input";
import Icon from "../../../../../lib/SharedIcon";
import mediaQuery from "../../../../../utils/mediaQuery";
import { useReimbursementFormContext } from "../../ReimbursementFormProvider";
import { MAX_EXPENSE_AMOUNT } from "../../types";

const CurrencyInput = lazy(
  () => import("../../../../../lib/forms/primitives/CurrencyInput"),
);
const CloseButton = styled.button`
  background: none;
  border: none;
  border-radius: 50%;
  box-sizing: content-box;
  height: 1em;
  margin-right: -12px;
  padding: 16px;
  span {
    align-items: center;
    display: inline-flex;
    justify-content: center;
  }
  ${mediaQuery(
    "greaterThanTablet",
    css`
      margin-right: 0;
    `,
  )}

  ${({ disabled }) =>
    disabled
      ? css`
          visibility: hidden;
        `
      : undefined}
  
  &:hover {
    background: ${colors.brownLightGrey1};
  }
  &:active {
    background: ${colors.white};
  }
`;
const FlexedFormField = styled(FormField)`
  flex-grow: 1;
`;
const DateFlexedFormField = styled(FlexedFormField)`
  input {
    box-sizing: content-box;
    max-width: 144px;
  }
  ${mediaQuery(
    "greaterThanTablet",
    css`
      input {
        max-width: 142px;
      }
    `,
  )}
`;

const AmountFlexedFormField = styled(FlexedFormField)`
  input {
    box-sizing: content-box;
    max-width: 115px;
  }
  ${mediaQuery(
    "greaterThanTablet",
    css`
      input {
        max-width: 124px;
      }
    `,
  )}
`;
const RowBlockContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 8px;
  justify-content: space-between;
  position: relative;
`;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
  ${mediaQuery(
    "greaterThanTablet",
    css`
      flex-wrap: nowrap;
    `,
  )}

  ${CloseButton} {
    color: ${colors.brownBlack};
    cursor: pointer;
    font-size: 16px;
    justify-self: center;
    line-height: 1;
    margin-top: 24px; // line-height of the label
  }
`;

export interface ExpensesItemProps
  extends HTMLAttributes<HTMLElement> {
  children?: never;
  itemIndex: number;
  onRemoveItem: (itemId: number) => void;
  deletable: boolean;
  currency: string;
}

export default function ExpensesItem({
  className,
  itemIndex,
  onRemoveItem,
  deletable,
  currency,
  ...restProps
}: ExpensesItemProps) {
  const handleRemoveItem = useCallback(() => {
    onRemoveItem(itemIndex);
  }, [itemIndex, onRemoveItem]);
  const {
    register,
    control,
    formState: { errors },
  } = useReimbursementFormContext();
  return (
    <Container className={className} {...restProps}>
      <FlexedFormField
        error={errors.expenses?.[itemIndex]?.description?.message}
        label={t`Expense description`}
      >
        <Input {...register(`expenses.${itemIndex}.description`)} />
      </FlexedFormField>
      <RowBlockContainer>
        <DateFlexedFormField
          error={errors.expenses?.[itemIndex]?.date?.message}
          label={t`Date of service`}
        >
          <Input
            /* max={format(new Date(), "yyyy-MM-dd")} */
            type="date"
            {...register(`expenses.${itemIndex}.date`)}
          />
        </DateFlexedFormField>
        <AmountFlexedFormField
          error={errors.expenses?.[itemIndex]?.amount?.message}
          label={t`Amount`}
        >
          <Controller
            control={control}
            name={`expenses.${itemIndex}.amount`}
            render={({ field }) => {
              return (
                <CurrencyInput
                  formatOptions={{
                    currency,
                    style: "currency",
                  }}
                  {...field}
                  // maxValue here prevent user from typing more than the max value, but it does not trigger the error message.
                  // So the MAX_EXPENSE_AMOUNT - is used to trigger the error message which is a preferable behavior
                  // MAX_EXPENSE_AMOUNT * 100 - is a fallback with a kind of absurd value
                  // It is less likely that user will type it. Unfortunately we can not trigger the error message with the maxValue
                  maxValue={MAX_EXPENSE_AMOUNT * 100}
                />
              );
            }}
          />
        </AmountFlexedFormField>
        <CloseButton
          aria-label={t`Remove expense`}
          disabled={!deletable}
          onClick={handleRemoveItem}
          type="button"
        >
          <span>
            <Icon type="cross" />
          </span>
        </CloseButton>
      </RowBlockContainer>
    </Container>
  );
}
