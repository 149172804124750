import { Trans } from "@lingui/macro";
import { useState } from "react";
import {
  DisclaimerContainer,
  DisclaimerLongContent,
  DisclaimerShortContent,
  ReadAllLink,
} from "./styles";

const Disclaimer = () => {
  const [forceReadAll, setForceReadAll] = useState(false);

  return (
    <DisclaimerContainer $readMore={forceReadAll}>
      <DisclaimerLongContent>
        <Trans>
          Meetings with Stork Club experts are for educational and
          informational purposes only. By scheduling a meeting with a
          Stork Club expert, you acknowledge and agree that Stork Club
          expert consultants are not acting as medical professionals,
          and the support and information they provide is&nbsp;not a
          substitute for professional medical advice, diagnosis or
          treatment. Always seek the advice of your physician or other
          qualified health care provider with any questions you may
          have regarding a medical condition or treatment and before
          undertaking a new health care regimen, and never disregard
          professional medical advice or delay in seeking it because
          of something you learn through your meeting with a Stork
          Club expert consultant.
        </Trans>
      </DisclaimerLongContent>
      <DisclaimerShortContent>
        <Trans>
          <b>Disclaimer: </b>
          Meetings with Stork Club experts are for educational and
          informational purposes only. By scheduling a meeting...
        </Trans>
        <ReadAllLink
          onClick={() => {
            setForceReadAll(true);
          }}
          type="button"
        >
          <Trans>Read all</Trans>
        </ReadAllLink>
      </DisclaimerShortContent>
    </DisclaimerContainer>
  );
};

export default Disclaimer;
