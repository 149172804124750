import { ComponentType, FC, Fragment } from "react";
import styled from "styled-components";
import { ArrowDown } from "./ArrowDown";
import { ArrowLeft } from "./ArrowLeft";
import { Bank } from "./Bank";
import { Camera } from "./Camera";
import { Check } from "./Check";
import { CheckSlim } from "./CheckSlim";
import { Cross } from "./Cross";
import { LinkBack } from "./LinkBack";
import { Lock } from "./Lock";
import { Minus } from "./Minus";
import { PasswordEyeClosed } from "./PasswordEyeClosed";
import { PasswordEyeOpen } from "./PasswordEyeOpen";
import { Plaid } from "./Plaid";
import { Spinner } from "./Spinner";
import { Stripe } from "./Stripe";
import { BaseIconAttributes } from "./types";

/**
 * useful for rendering stories
 */
export const ICON_TYPES = [
  "arrowDown" as const,
  "arrowLeft" as const,
  "bank" as const,
  "camera" as const,
  "check" as const,
  "checkSlim" as const,
  "cross" as const,
  "linkBack" as const,
  "lock" as const,
  "minus" as const,
  "passwordEyeClosed" as const,
  "passwordEyeOpen" as const,
  "plaid" as const,
  "spinner" as const,
  "stripe" as const,
];
type ArrayElementType<T> =
  T extends Array<infer Item> ? Item : unknown;

export type IconType = ArrayElementType<typeof ICON_TYPES>;

export interface IconProps extends BaseIconAttributes {
  type: IconType;
}

const iconsMapper: Record<
  IconType,
  ComponentType<BaseIconAttributes>
> = {
  arrowDown: ArrowDown,
  arrowLeft: ArrowLeft,
  bank: Bank,
  camera: Camera,
  check: Check,
  checkSlim: CheckSlim,
  cross: Cross,
  linkBack: LinkBack,
  lock: Lock,
  minus: Minus,
  passwordEyeClosed: PasswordEyeClosed,
  passwordEyeOpen: PasswordEyeOpen,
  plaid: Plaid,
  spinner: Spinner,
  stripe: Stripe,
};

const Icon: FC<IconProps> = ({ type, ...restProps }) => {
  const IconC = iconsMapper[type] || Fragment;

  return <IconC {...restProps} />;
};

export default styled(Icon)``;
