import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import { format as formatDate } from "date-fns";
import { FC } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../../constants";
import { formatCurrency } from "../../../../utils/currency";
import mediaQuery from "../../../../utils/mediaQuery";
import { Authorization } from "../../../models/Authorization";
import { UserPlan } from "../../../models/UserPlan";
import { BoldBlack } from "../style";
import { CoveredTable, RejectedTable } from "./CoveredTable";
import PrescribedRx from "./PrescribedRx";
import { ResponsibilityBlockComplete } from "./Responsibility/Complete";
import { ResponsibilityBlockIncomplete } from "./Responsibility/Incomplete";

type Props = {
  authorization: Authorization;
  userPlan: UserPlan;
};

const IntroRow = styled.div`
  color: ${colors.brownGrey};
  margin-bottom: 20px;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 10px;
    `,
  )}
`;

const DeliveryRow = styled.div`
  /* margin-bottom: 20px; */
  margin-bottom: 30px;

  ${mediaQuery(
    "desktopMedium",
    css`
      /* margin-bottom: 10px; */
      margin-bottom: 30px;
    `,
  )}
`;

// const WillDeductRow = styled.div`
//   margin-bottom: 30px;
// `;

const PracticeRow = styled.div`
  margin-bottom: 4px;
`;

const TotalCostRow = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 30px 0;
`;

const TotalCostRowTitle = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 10px;
`;

const TotalCostRowPrice = styled.div`
  color: ${colors.brownGrey};
  font-size: 19px;
  font-weight: 800;
`;

export const RxGranted: FC<Props> = ({
  authorization,
  userPlan,
}: Props) => {
  const { serviceDate, services, maxRxCoverage } = authorization;
  const { profileComplete } = userPlan;
  // const { deductible } = userPlan;
  const totalCost = services
    ? services.reduce(
        (acc, { amount, rate }) => acc + amount * rate,
        0,
      )
    : 0;

  const rejectedServices =
    services?.filter((item) => item.state === "rejected") || [];
  const grantedServices =
    services?.filter((item) => item.state === "granted") || [];
  const hasSomePrescribedServices =
    rejectedServices.length > 0 || grantedServices.length > 0;

  return (
    <>
      <IntroRow>
        <Trans>
          Your covered Rx will be shipped as soon as our in-network
          pharmacy, SMP, contacts you to collect your shipping address
          and any applicable copay.
        </Trans>
      </IntroRow>

      <PracticeRow>
        <Trans>
          By{" "}
          <BoldBlack>
            Stork Club Rx ({authorization.practiceName})
          </BoldBlack>
        </Trans>
      </PracticeRow>

      {serviceDate && (
        <DeliveryRow>
          <Trans>
            Delivery no later than{" "}
            <BoldBlack>
              {formatDate(serviceDate, "MM-dd-yyyy")}
            </BoldBlack>
          </Trans>
        </DeliveryRow>
      )}

      {/* <WillDeductRow>
        This order if provided in full will deduct{" "}
        <BoldBlack>
          ${deductible} of your remaining Rx coverage
        </BoldBlack>
      </WillDeductRow> */}

      {hasSomePrescribedServices && (
        <PrescribedRx>
          <CoveredTable services={grantedServices} />
          <RejectedTable services={rejectedServices} />
        </PrescribedRx>
      )}

      <TotalCostRow>
        <div>
          <TotalCostRowTitle>
            <Trans>Total cost</Trans>
          </TotalCostRowTitle>
          <TotalCostRowPrice>
            {formatCurrency(totalCost, "USD", i18n.locale, {
              maximumFractionDigits: 0,
            })}
          </TotalCostRowPrice>
        </div>
        <div>
          <TotalCostRowTitle>
            <Trans>Total coverage</Trans>
          </TotalCostRowTitle>
          <TotalCostRowPrice>{maxRxCoverage}</TotalCostRowPrice>
        </div>
      </TotalCostRow>

      {profileComplete ? (
        <ResponsibilityBlockComplete
          authorization={authorization}
          userPlan={userPlan}
        />
      ) : (
        <ResponsibilityBlockIncomplete isRx userPlan={userPlan} />
      )}
    </>
  );
};
