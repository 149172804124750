import { Trans } from "@lingui/macro";
import { FC, useState, useCallback, useRef } from "react";
import { useMount } from "react-use";
import styled, { css } from "styled-components";
import { colors, fonts } from "../../../constants";
import scrollToEl from "../../../notifications/components/AuthorizationGroup/scrollToEl";
import hexColorOpacity from "../../../utils/hexColorOpacity";
import mediaQuery from "../../../utils/mediaQuery";
import { Reimbursement } from "../../models/Reimbursement";
import { ReimbursementEvent } from "../../models/ReimbursementEvent";
import { Notification } from "../Reimbursement";
import { ReimbursementIcon } from "./Icon";

export type Props = {
  reimbursement: Reimbursement;
  expandedId: string | null;
  onRead: (id: string) => void;
};

const Container = styled.div`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Uid = styled.div`
  background: ${hexColorOpacity(colors.green, 0.1)};
  border-radius: 5px;
  color: ${colors.green};
  font-size: 12px;
  height: 26px;
  left: 46px;
  line-height: 25px;
  opacity: 0.9;
  padding: 0 6px;
  position: absolute;
  white-space: nowrap;

  ${mediaQuery(
    "desktopMedium",
    css`
      font-size: 15px;
      line-height: 26px;
      position: static;
    `,
  )}
`;

const Border = styled.div`
  background: ${colors.white};
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px 10px 10px;

  ${mediaQuery(
    "desktopMedium",
    css`
      padding: 42px 60px 40px;
    `,
  )}
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0;
  position: relative;

  ${mediaQuery(
    "desktopMedium",
    css`
      padding-inline-end: 20px;
    `,
  )}
`;

const TitleContainer = styled.div`
  font-family: ${fonts.alt};
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
  margin-bottom: 20px;
  margin-inline-end: 0px;

  ${mediaQuery(
    "desktopMedium",
    css`
      display: flex;
      margin-bottom: 28px;
      margin-inline-end: 20px;
    `,
  )}
`;

const TitleIcon = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  width: 46px;

  ${mediaQuery(
    "desktopMedium",
    css`
      width: 40px;
    `,
  )}
`;

const TitleText = styled.div`
  padding: 5px 10px 0;

  ${mediaQuery(
    "desktopMedium",
    css`
      padding: 0;
    `,
  )}
`;

const ReimbursementGroup: FC<Props> = ({
  reimbursement,
  expandedId = null,
  onRead,
}: Props) => {
  const containerRef = useRef(null);
  const { uid, programName, events } = reimbursement;

  const [opened, setOpened] = useState<{
    [key: string]: boolean;
  }>({});

  const onOpen = useCallback(
    (event: ReimbursementEvent) => {
      const { isRead, id } = event;

      if (!isRead && !opened[id]) {
        onRead(id);
      }

      setOpened((currentOpened) => ({
        ...currentOpened,
        [id]: true,
      }));
    },
    [onRead, opened],
  );

  useMount(() => {
    const isExpanded =
      !!expandedId && events.some((item) => item.id === expandedId);

    if (isExpanded) {
      setOpened((currentOpened) => ({
        ...currentOpened,
        [expandedId]: true,
      }));

      const event = reimbursement.events.find(
        (item) => item.id === expandedId,
      );
      if (event && !event.isRead) {
        onRead(expandedId);
      }

      if (containerRef?.current) scrollToEl(containerRef?.current);
    }
  });

  return (
    <Container>
      <div ref={containerRef}>
        <Border>
          <Top>
            <TitleContainer>
              <TitleIcon>
                <ReimbursementIcon />
              </TitleIcon>
              <TitleText>
                <Trans>{programName} reimbursement</Trans>
              </TitleText>
            </TitleContainer>

            <Uid>
              <Trans>Reimbursement ID</Trans>
              {"\u00A0"}
              {uid.match(/-(\d+$)/)?.[1] || uid}
            </Uid>
          </Top>
          {events.map((item) => (
            <Notification
              key={item.id}
              event={item}
              isExpandedOnMount={item.id === expandedId}
              isOpened={opened[item.id]}
              onOpen={onOpen}
              reimbursement={reimbursement}
            />
          ))}
        </Border>
      </div>
    </Container>
  );
};

export default ReimbursementGroup;
