import { CSSProperties, HTMLAttributes } from "react";
import * as React from "react";
import styled, { css } from "styled-components";
import { colors } from "../../constants";

export type Level = "h1" | "h2" | "h3" | "h4";
export type StyleLevel = "h1" | "h2" | "h3" | "h4";
interface ExtraProps {
  /**
   * Which tag to use
   */
  level?: Level;
  /**
   * You can override styles using it. E.g. render h1 as h4
   */
  styleLevel?: StyleLevel;
}

const FONT_SIZE: Record<Level, CSSProperties["fontSize"]> = {
  h1: "32px",
  h2: "24px",
  h3: "18px",
  h4: "15px",
};
const LINE_HEIGHT: Record<Level, CSSProperties["lineHeight"]> = {
  h1: "32px",
  h2: "28.8px",
  h3: "24px",
  h4: "24px",
};
const FONT_WEIGHT: Record<Level, CSSProperties["fontWeight"]> = {
  h1: 700,
  h2: 700,
  h3: 700,
  h4: 700,
};
const FONT_FAMILY: Record<Level, string> = {
  h1: "Duplicate Sans",
  h2: "Duplicate Sans",
  h3: "Roboto",
  h4: "Roboto",
};

export const getTitleStyle = (styleLevel: StyleLevel) => css`
  color: ${colors.black};
  font-family: "${FONT_FAMILY[styleLevel]}", sans-serif;
  font-size: ${FONT_SIZE[styleLevel]};
  font-style: normal;
  font-weight: ${FONT_WEIGHT[styleLevel]};
  line-height: ${LINE_HEIGHT[styleLevel]};
  margin: unset;
`;
const Title = styled.h1.attrs(
  (
    props: ExtraProps & {
      as?:
        | React.ComponentType<
            ExtraProps & HTMLAttributes<HTMLElement>
          >
        | string;
    },
  ) => ({
    as: props.as || props.level || "h1",
    styleLevel: props.styleLevel || props.level || "h1",
  }),
)<ExtraProps>`
  ${({ styleLevel }) => getTitleStyle(styleLevel)}
`;

export default Title;
