/* eslint-disable react/destructuring-assignment */
import { AnimatePresence, motion } from "framer-motion";
import { FC, useContext } from "react";
import styled from "styled-components";
import Message from "../Message";
import { MessagesContext } from "../MessagesApiProvider/context";

const Container = styled(motion.div)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  inset-inline-end: 0;
  inset-inline-start: 0;
  pointer-events: none;
  position: fixed;
  top: 15px;
  z-index: 9000;
`;
const MessagesRenderRoot: FC = () => {
  const messages = useContext(MessagesContext);

  return (
    <Container>
      <AnimatePresence initial={false}>
        {/* False positive. Maybe a regression in the eslint plugin.  */}
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {messages.map(
          ({
            messageKey,
            onClick,
            content,
            className,
            style,
            duration,
            type,
            closable,
          }) => (
            <Message
              key={messageKey}
              className={className}
              closable={closable}
              content={content}
              duration={duration}
              messageKey={messageKey}
              onClick={onClick}
              style={style}
              type={type}
            />
          ),
        )}
      </AnimatePresence>
    </Container>
  );
};

export default MessagesRenderRoot;
