import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";

import { FC } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../../constants";
import { formatCurrency } from "../../../../utils/currency";
import mediaQuery from "../../../../utils/mediaQuery";
import { Bill } from "../../../models/Bill";
import { Hr, Summ } from "../style";

type Props = {
  bill: Bill;
};

const StyledHr = styled(Hr)`
  margin-bottom: 24px;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 36px;
    `,
  )}
`;

const PaidRow = styled.div`
  margin-bottom: 16px;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 8px;
    `,
  )}
`;

const PaidRowTitle = styled.div`
  color: ${colors.green};
  font-weight: 500;
  margin-bottom: 4px;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 10px;
    `,
  )}
`;

const InitialRow = styled.div`
  color: ${colors.brownGrey};
  margin-bottom: 24px;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 36px;
    `,
  )}
`;

export const BillPaid: FC<Props> = ({ bill }: Props) => {
  const { amount } = bill;

  return (
    <>
      <InitialRow>
        <Trans>Thank you!</Trans>
      </InitialRow>
      <StyledHr />
      <PaidRow>
        <PaidRowTitle>
          <Trans>Paid</Trans>
        </PaidRowTitle>
        <Summ>{formatCurrency(amount, "USD", i18n.locale)}</Summ>
      </PaidRow>
    </>
  );
};
