import { FC, ReactNode } from "react";
import styled from "styled-components";
import Title from "../../../../lib/Title";
import Program, { ProgramProps } from "./Program";

export interface ProgramsProps {
  title: ReactNode;
  programs: readonly ProgramProps[];
}

const ProgramsTitle = styled(Title)`
  margin-bottom: 20px;
`;
const Container = styled.div``;
const Programs: FC<ProgramsProps> = ({ programs, title }) => {
  return (
    <Container>
      <ProgramsTitle level="h3" styleLevel="h4">
        {title}
      </ProgramsTitle>
      {programs.map((program) => (
        <Program {...program} key={program.id} />
      ))}
    </Container>
  );
};

export default Programs;
