import { t, Trans } from "@lingui/macro";
import React from "react";
import { usePlaidLink, PlaidLinkOnSuccess } from "react-plaid-link";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../constants";
import Spinner from "../../lib/Spinner";
import { useMakePayment } from "./hooks";

const Wrapper = styled.div`
  inset: 0;
  margin: auto;
  position: absolute;
`;

const PaymentOAuth = () => {
  // The Link token from the first Link initialization
  const token = localStorage.getItem("link_token");
  const uid = localStorage.getItem("redirect_bill_uid");
  const navigate = useNavigate();
  const { mutate: makePayment } = useMakePayment();

  const onSuccess = React.useCallback<PlaidLinkOnSuccess>(
    (publicToken, metadata) => {
      const payload = {
        // accountId: metadata.account_id,
        accountId: metadata.accounts[0].id, // No typings provided for metadata.account_id from 'react-plaid-link'
        publicToken,
        uid,
      };

      let errorParam = "";

      makePayment(payload, {
        onError: () => {
          errorParam = "?internal_oauth_error=true";
        },
        onSettled: () => {
          localStorage.removeItem("link_token");
          localStorage.removeItem("redirect_bill_uid");

          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          navigate(`/bills/${uid + errorParam}`);
        },
      });
    },
    [makePayment, uid, navigate],
  );

  const onExit = React.useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    navigate(`/bills/${uid}`);
  }, [uid, navigate]);

  const config = {
    onExit,
    onSuccess,
    receivedRedirectUri: window.location.href,
    token,
  };

  const { open, ready } = usePlaidLink(config);

  // Automatically reinitialize Link
  React.useEffect(() => {
    if (uid && ready) open();
  }, [ready, open, uid]);

  if (!uid) {
    return (
      <Trans>
        Sorry, something went wrong. Please reload the page or try
        again later.
      </Trans>
    );
  }

  return (
    <Wrapper>
      <Spinner
        color={colors.brownLightGrey1}
        label={t`Loading`}
        size="22px"
      />
    </Wrapper>
  );
};

export default PaymentOAuth;
