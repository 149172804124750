import { isLeft } from "fp-ts/Either";
import * as t from "io-ts";
import { useQuery } from "react-query";
import { UseQueryResult } from "react-query/types/react/types";
import { wrongResponseFormat } from "../../../locales/errors";
import { useAxios } from "../../../network";

export const responseC = t.type({
  data: t.array(
    t.type({
      attributes: t.type({
        name: t.string,
        program_type: t.string,
        reimbursable: t.boolean,
      }),
      id: t.string,
      relationships: t.type({
        program_category: t.type({
          data: t.type({
            id: t.string,
            type: t.literal("program_category"),
          }),
        }),
      }),
      type: t.literal("program"),
    }),
  ),
  included: t.array(
    t.type({
      attributes: t.type({
        name: t.string,
      }),
      id: t.string,
      type: t.literal("program_category"),
    }),
  ),
});

export type Program = {
  id: string;
  name: string;
  category: {
    id: string;
    name: string;
    slug: string;
  };
  programType: string;
  slug: string;
  reimbursable: boolean;
};

function responseToEntities(
  response: t.TypeOf<typeof responseC>,
): Program[] {
  const { data } = response;
  if (data.length === 0) return [];

  return data.map((item) => {
    const category = response.included.find(
      (cat) => cat.id === item.relationships.program_category.data.id,
    );
    if (!category) {
      // eslint-disable-next-line no-console
      console.error("category for a program not found in included");
      throw wrongResponseFormat;
    }

    return {
      category: {
        id: item.relationships.program_category.data.id,
        name: category.attributes.name,
        slug: item.relationships.program_category.data.id.replaceAll(
          "_",
          "-",
        ),
      },
      id: item.id,
      name: item.attributes.name,
      programType: item.attributes.program_type,
      reimbursable: item.attributes.reimbursable,
      slug: item.attributes.program_type.replaceAll("_", "-"),
    };
  });
}

export const useGetCompanyPrograms = (): UseQueryResult<
  Program[]
> => {
  const axios = useAxios();
  return useQuery("getCompanyPrograms", async () => {
    const response = await axios.get(`/v2/company/programs`);

    const decodeResult = responseC.decode(response.data);

    if (isLeft(decodeResult)) {
      // eslint-disable-next-line no-console
      console.error(decodeResult);
      throw wrongResponseFormat;
    }
    return responseToEntities(decodeResult.right);
  });
};
