import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import { format as formatDate } from "date-fns";
import { FC } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../../constants";
import { formatCurrency } from "../../../../utils/currency";
import mediaQuery from "../../../../utils/mediaQuery";
import { Authorization } from "../../../models/Authorization";
import { Status } from "../../../models/Status";
import { UserPlan } from "../../../models/UserPlan";
import { BoldBlack, Hr } from "../style";
import { ResponsibilityBlockComplete } from "./Responsibility/Complete";
import { ResponsibilityBlockIncomplete } from "./Responsibility/Incomplete";

type Props = {
  authorization: Authorization;
  userPlan: UserPlan;
  status: Status;
};

const FirstRow = styled.div`
  color: ${colors.brownGrey};
  margin-bottom: 20px;
`;

const PracticeRow = styled.div`
  margin-bottom: 20px;
`;

const ServiceDateRow = styled.div`
  margin-top: 4px;
`;

const DeductBundlesRow = styled.div`
  margin-bottom: 24px;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 36px;
    `,
  )}
`;

const TotalCostRow = styled.div`
  margin: 24px 0;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin: 36px 0;
    `,
  )}
`;

const TotalCostRowTitle = styled.div`
  color: ${colors.black};
  font-weight: 500;
  margin-bottom: 10px;
`;

const TotalCostRowPrice = styled.div`
  color: ${colors.brownGrey};
  font-size: 24px;
  font-weight: bold;
`;

export const Service: FC<Props> = ({
  authorization,
  userPlan,
  status,
}: Props) => {
  const {
    practiceName,
    serviceDate,
    title,
    expiredAt,
    bundleEquivalent,
    services,
  } = authorization;

  const { profileComplete } = userPlan;
  const isGranted = status === "granted";

  const totalCost = services
    ? services.reduce(
        (acc, { amount, rate }) => acc + amount * rate,
        0,
      )
    : 0;

  return (
    <>
      <FirstRow>
        {isGranted ? (
          <Trans>
            You can now use these services covered by Stork Club.
          </Trans>
        ) : (
          <Trans>
            There is no action required from you. You’ll be able to
            use the coverage once Stork Club authorizes it. The
            processing time might take up to 24 hours.
          </Trans>
        )}
      </FirstRow>

      <PracticeRow>
        <Trans>
          By <BoldBlack>{practiceName}</BoldBlack> for{" "}
          <BoldBlack>{title}</BoldBlack>
        </Trans>
        {serviceDate && (
          <ServiceDateRow>
            {isGranted && expiredAt ? (
              <Trans>
                To perform on{" "}
                <BoldBlack>
                  {formatDate(serviceDate, "MM-dd-yyyy")}
                </BoldBlack>{" "}
                until{" "}
                <BoldBlack>
                  {formatDate(expiredAt, "MM-dd-yyyy")}
                </BoldBlack>
              </Trans>
            ) : (
              <Trans>
                To perform on{" "}
                <BoldBlack>
                  {formatDate(serviceDate, "MM-dd-yyyy")}
                </BoldBlack>
              </Trans>
            )}
          </ServiceDateRow>
        )}
      </PracticeRow>

      <DeductBundlesRow>
        <Trans>
          This service if provided in full will deduct{" "}
          <BoldBlack>{bundleEquivalent} of your bundles</BoldBlack>
        </Trans>
      </DeductBundlesRow>

      <Hr />

      <TotalCostRow>
        <TotalCostRowTitle>
          <Trans>Total cost</Trans>
        </TotalCostRowTitle>
        <TotalCostRowPrice>
          {formatCurrency(totalCost, "USD", i18n.locale)}
        </TotalCostRowPrice>
      </TotalCostRow>

      {profileComplete ? (
        <ResponsibilityBlockComplete
          authorization={authorization}
          userPlan={userPlan}
        />
      ) : (
        <ResponsibilityBlockIncomplete userPlan={userPlan} />
      )}
    </>
  );
};
