import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import { FC } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../../../constants";
import { formatCurrency } from "../../../../../utils/currency";
import mediaQuery from "../../../../../utils/mediaQuery";
import { Authorization } from "../../../../models/Authorization";
import { UserPlanComplete } from "../../../../models/UserPlan";
import { BoldBlack, BrownGrey } from "../../style";
import { Value, GridMobile } from "./Responsibility.style";

const StyledValue = styled(Value)`
  color: ${colors.black};
`;

type Props = {
  isRx?: boolean;
  authorization: Authorization;
  userPlan: UserPlanComplete;
};

const Container = styled.div`
  ${mediaQuery(
    "desktopMedium",
    css`
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    `,
  )}
`;

const Title = styled.div`
  color: ${colors.brownBlack};
  font-weight: 500;
  margin-bottom: 8px;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 8px;
    `,
  )}
`;

const SubTitle = styled.div`
  color: ${colors.black};
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 2px;
`;

const DependingRow = styled.div`
  margin-bottom: 6px;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 10px;
    `,
  )}
`;

const OopMaxReached = () => (
  <>
    <StyledValue>$0</StyledValue>
    <BrownGrey>
      <Trans>Your out-of-pocket max is&nbsp;reached</Trans>
    </BrownGrey>
  </>
);

export const IndividualCost: FC<Props> = ({
  isRx,
  userPlan,
  authorization,
}: Props) => {
  const { oopSpan } = authorization;
  const { oopMax, copayRx, noDeductibleRx } = userPlan;

  const isCopayRx = isRx && copayRx > 0;

  const min = oopSpan?.[0];
  const max = oopSpan?.[1];
  const validCost =
    typeof min === "number" && typeof max === "number";
  const zeroCost = max === 0;
  const positiveCost = validCost && max > 0;

  return (
    <Container>
      <div>
        <Title>
          <Trans>Individual Cost</Trans>
        </Title>
        {isCopayRx && (
          <>
            <GridMobile>
              {!noDeductibleRx && (
                <div>
                  <SubTitle>
                    <Trans>Deductible</Trans>
                  </SubTitle>
                  {positiveCost && (
                    <StyledValue>
                      {formatCurrency(0, "USD", i18n.locale, {
                        maximumFractionDigits: 0,
                      })}{" "}
                      <Trans>to</Trans>{" "}
                      {formatCurrency(max - min, "USD", i18n.locale, {
                        maximumFractionDigits: 0,
                      })}
                    </StyledValue>
                  )}
                  {zeroCost && <OopMaxReached />}
                  {!validCost && (
                    <BrownGrey>
                      <Trans>cannot be shown</Trans>
                    </BrownGrey>
                  )}
                </div>
              )}
              <div>
                <SubTitle>
                  <Trans>Copay</Trans>
                </SubTitle>
                {validCost ? (
                  <StyledValue>
                    {formatCurrency(min, "USD", i18n.locale, {
                      maximumFractionDigits: 0,
                    })}
                  </StyledValue>
                ) : (
                  <BrownGrey>
                    <Trans>cannot be shown</Trans>
                  </BrownGrey>
                )}
              </div>
            </GridMobile>
          </>
        )}

        {!isCopayRx && (
          <>
            {!validCost && (
              <BrownGrey>
                <Trans>cannot be shown</Trans>
              </BrownGrey>
            )}
            {zeroCost && <OopMaxReached />}
            {positiveCost && (
              <>
                <StyledValue>
                  {formatCurrency(min, "USD", i18n.locale, {
                    maximumFractionDigits: 0,
                  })}{" "}
                  <Trans>to</Trans>{" "}
                  {formatCurrency(max, "USD", i18n.locale, {
                    maximumFractionDigits: 0,
                  })}
                </StyledValue>
                <DependingRow>
                  <BrownGrey>
                    <Trans>
                      depending on your remaining deductible
                    </Trans>
                  </BrownGrey>
                </DependingRow>
              </>
            )}
          </>
        )}
      </div>

      <div>
        {positiveCost && typeof oopMax === "number" && (
          <BrownGrey>
            <Trans>
              The individual cost won’t exceed{" "}
              <BoldBlack>
                {formatCurrency(oopMax, "USD", i18n.locale, {
                  maximumFractionDigits: 0,
                })}
              </BoldBlack>{" "}
              <br />
              (your out-of-pocket max)
            </Trans>
          </BrownGrey>
        )}
      </div>
    </Container>
  );
};
