/**
 * Browser tries to use the first font in the list, if it does not support a glyph,
 * it tries the next one in the list.
 */
export const extraLanguageFonts =
  '"Noto Sans", "Noto Sans Bengali", "Noto Sans Sinhala", "Noto Sans Tamil", "Noto Sans KR"';

export const fonts = {
  alt: `var(--font-alt, "Duplicate Sans")`,
  faktum: `var(--font-faktum, "Faktum")`,
  main: `var(--font-main, "Roboto")`,
};
