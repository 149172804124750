import * as React from "react";
import { BaseIconAttributes } from "./types";

export const ArrowLeft: React.FC<BaseIconAttributes> = ({
  width = "1em",
  height = "1em",
  mainColor = "currentColor",
  ...restProps
}) => (
  <svg
    {...restProps}
    fill="none"
    height={height}
    viewBox="0 0 25 24"
    width={width}
  >
    <path
      d="M13 23 2 11.9972 13 1m11.4989 10.85H2"
      stroke={mainColor}
      strokeWidth="2"
    />
  </svg>
);
