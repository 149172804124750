import { useLocation } from "react-router";

/**
 * In location.state.focus we have a string.
 * If this string is presented - do not scroll automatically to top
 *
 * But set an autofocus on the element with this id
 */
export function useAutofocusElementId(): string | undefined {
  const location = useLocation();
  return (location.state as { focus?: string } | undefined)?.focus;
}
