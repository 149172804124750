import { FC } from "react";
import { useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import useAuthSession from "../../authentication/hooks/useAuthSession";
import { colors } from "../../constants";
import Markdown from "../../lib/Markdown";
import Skeleton from "../../lib/Skeleton";
import Title from "../../lib/Title";
import StatelessSubmitExpensesWidget from "../../reimbursement/components/StatelessSubmitExpensesWidget";
import mediaQuery from "../../utils/mediaQuery";
import {
  programsMarkdownMixin,
  goldenListMixin,
} from "../../utils/style";
import ScrollHandler from "../ScrollHandler";
import Clinic from "./Clinic";
import { useGetProgram } from "./hooks/program";

type TabProps = {
  name: string;
  programType: string;
};

const StyledTitle = styled(Title)`
  margin-bottom: 30px;
`;

const Clinics = styled.div`
  ${mediaQuery(
    "desktopMedium",
    css`
      width: 700px;
    `,
  )}
`;

const StyledMarkdown = styled(Markdown)`
  color: ${colors.brownBlack};
  ${goldenListMixin};
  ${programsMarkdownMixin};
`;

const StyledStatelessSubmitExpensesWidget = styled(
  StatelessSubmitExpensesWidget,
)`
  border-bottom: 1px solid ${colors.brownLightGrey1};
  margin-bottom: 25px;
  padding-bottom: 30px;
`;

const Tab: FC<TabProps> = ({ name, programType }: TabProps) => {
  const { data, isLoading } = useGetProgram(programType);
  const clinicLayout = data?.attributes.clinic_layout;
  const isHRT = clinicLayout === "hormone_replacement";
  const location = useLocation();
  const { data: authSession, isLoading: isAuthSessionLoading } =
    useAuthSession();
  const currency =
    authSession?.user?.company.attributes.country_currency;
  if (!isAuthSessionLoading && !currency) {
    throw new Error("Currency is not defined");
  }
  return (
    <ScrollHandler key={data?.id}>
      <Skeleton active loading={isLoading}>
        {data ? (
          <>
            <StyledTitle level="h2" styleLevel="h1">
              {name}
            </StyledTitle>
            {data.attributes.reimbursable && currency && (
              <StyledStatelessSubmitExpensesWidget
                backLinkUrl={location.pathname}
                currency={currency}
                hideCoverage={!data.attributes.flat_max_reimbursement}
                preselectedProgramId={data.id}
                remaining={
                  data.attributes.remaining_reimbursable_coverage
                }
                total={data.attributes.total_reimbursable_coverage}
              />
            )}
            <StyledMarkdown text={data.attributes.body.toString()} />
            {!!clinicLayout && !isHRT && (
              <Clinics>
                {data.relationships.global_clinics.map((item) => (
                  <Clinic
                    key={`global_${item.id}`}
                    clinic={item}
                    clinicLayout={clinicLayout}
                    programType={programType}
                  />
                ))}
                {data.relationships.nearby_clinics.map((item) => (
                  <Clinic
                    key={`nearby_${item.id}`}
                    clinic={item}
                    clinicLayout={clinicLayout}
                    programType={programType}
                  />
                ))}
              </Clinics>
            )}
          </>
        ) : null}
      </Skeleton>
    </ScrollHandler>
  );
};

export default Tab;
