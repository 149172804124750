import { isLeft } from "fp-ts/Either";
import * as t from "io-ts";
import { useQuery } from "react-query";
import { UseQueryResult } from "react-query/types/react/types";
import { ImageSet, ISODate } from "../../../io-ts/types";
import { wrongResponseFormat } from "../../../locales/errors";
import { useAxios } from "../../../network";
import { Article } from "../models/Article";
import { IncludedPostCategoryC } from "../models/IncludedPostCategory";

export const responseC = t.type({
  data: t.array(
    t.type({
      attributes: t.type({
        image_set: ImageSet,
        intro: t.string,
        name: t.string,
        published_at: ISODate,
        show_cover: t.boolean,
        slug: t.string,
      }),
      id: t.string,
      relationships: t.type({
        post_category: t.type({
          data: t.type({
            id: t.string,
            type: t.literal("post_category"),
          }),
        }),
      }),
      type: t.literal("post"),
    }),
  ),
  included: t.array(IncludedPostCategoryC),
});

function responseToEntities(
  response: t.TypeOf<typeof responseC>,
): Article[] {
  const { data, included } = response;
  if (data.length === 0) return [];

  return data.map((item) => {
    const category = included.find(
      (c) => c.id === item.relationships.post_category.data.id,
    )?.attributes;

    if (!category) throw Error("category not found");

    return {
      blocks: [],
      category,
      id: item.id,
      imageSet: item.attributes.image_set,
      intro: item.attributes.intro,
      name: item.attributes.name,
      publishedAt: item.attributes.published_at,
      showCover: item.attributes.show_cover,
      url: `/articles/${item.attributes.slug}`,
    };
  });
}

type UseGetArticlesResult = Article[];

export const useGetArticlesRecent =
  (): UseQueryResult<UseGetArticlesResult> => {
    const axios = useAxios();
    return useQuery("getArticlesRecent", async () => {
      const response = await axios.get(`/v2/posts?page=1&per_page=4`);

      const decodeResult = responseC.decode(response.data);

      if (isLeft(decodeResult)) {
        // eslint-disable-next-line no-console
        console.error(decodeResult);
        throw wrongResponseFormat;
      }
      return responseToEntities(decodeResult.right);
    });
  };

export const useGetArticlesPopular =
  (): UseQueryResult<UseGetArticlesResult> => {
    const axios = useAxios();
    return useQuery("getArticlesPopular", async () => {
      const response = await axios.get(
        `/v2/posts?q[post_sections_section_eq]=popular&page=1&per_page=4`,
      );

      const decodeResult = responseC.decode(response.data);

      if (isLeft(decodeResult)) {
        // eslint-disable-next-line no-console
        console.error(decodeResult);
        throw wrongResponseFormat;
      }
      return responseToEntities(decodeResult.right);
    });
  };

export const useGetArticlesMustRead =
  (): UseQueryResult<UseGetArticlesResult> => {
    const axios = useAxios();
    return useQuery("getArticlesMustRead", async () => {
      const response = await axios.get(
        `/v2/posts?q[post_sections_section_eq]=mustread&page=1&per_page=4`,
      );

      const decodeResult = responseC.decode(response.data);

      if (isLeft(decodeResult)) {
        // eslint-disable-next-line no-console
        console.error(decodeResult);
        throw wrongResponseFormat;
      }
      return responseToEntities(decodeResult.right);
    });
  };

export const useGetArticlesCategory = (
  category: string,
): UseQueryResult<UseGetArticlesResult> => {
  const axios = useAxios();
  return useQuery(["getArticlesCategory", category], async () => {
    const response = await axios.get(
      `/v2/posts?q[post_category_slug_eq]=${category}`,
    );

    const decodeResult = responseC.decode(response.data);

    if (isLeft(decodeResult)) {
      // eslint-disable-next-line no-console
      console.error(decodeResult);
      throw wrongResponseFormat;
    }
    return responseToEntities(decodeResult.right);
  });
};

export const useGetArticlesSearch = (
  query: string,
): UseQueryResult<UseGetArticlesResult> => {
  const axios = useAxios();
  return useQuery(["getArticlesSearch", query], async () => {
    const response = await axios.get(`/v2/posts?search=${query}`);

    const decodeResult = responseC.decode(response.data);

    if (isLeft(decodeResult)) {
      // eslint-disable-next-line no-console
      console.error(decodeResult);
      throw wrongResponseFormat;
    }
    return responseToEntities(decodeResult.right);
  });
};
