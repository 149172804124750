import * as t from "io-ts";
import { createResourceIdentifierC } from "../../../network/jsonApi/builders";
import { CategoryC } from "./Category";
import { ClinicC } from "./Clinic";
import { DoctorC } from "./Doctor";
import { LocationC } from "./Location";
import { ProgramC } from "./Program";

export const responseC = t.type({
  data: t.intersection([
    ProgramC,
    t.type({
      relationships: t.type({
        global_clinics: t.type({
          data: t.array(createResourceIdentifierC("clinic")),
        }),
        nearby_clinics: t.type({
          data: t.array(createResourceIdentifierC("clinic")),
        }),
        program_category: t.type({
          data: createResourceIdentifierC("program_category"),
        }),
      }),
    }),
  ]),
  included: t.array(
    t.union([
      CategoryC,
      DoctorC,
      LocationC,
      t.intersection([
        ClinicC,
        t.type({
          relationships: t.type({
            doctors: t.type({
              data: t.array(createResourceIdentifierC("doctor")),
            }),
            locations: t.type({
              data: t.array(createResourceIdentifierC("location")),
            }),
          }),
        }),
      ]),
    ]),
  ),
});
