import { isLeft } from "fp-ts/Either";
import * as t from "io-ts";
import { useMutation } from "react-query";
import { wrongApiResponse } from "../../../../locales/errors";
import { useAxios } from "../../../../network";
import { ENDPOINTS } from "../../../../network/endpoints";

const StrategyC = t.union([t.literal("password"), t.literal("okta")]);
const responseC = t.array(StrategyC);
export type Strategy = t.TypeOf<typeof StrategyC>;
export interface CheckEmailPayload {
  email: string;
  recaptchaToken: string;
}
export function useCheckEmail() {
  const axios = useAxios();

  return useMutation(async (payload: CheckEmailPayload) => {
    const { email, recaptchaToken } = payload;
    const { data } = await axios.post<unknown>(
      `${ENDPOINTS.checkEmail}`,
      { email, "g-recaptcha-response": recaptchaToken },
    );

    const decoded = responseC.decode(data);
    if (isLeft(decoded)) {
      // eslint-disable-next-line no-console
      console.error(decoded.left);
      throw wrongApiResponse;
    }

    return decoded.right;
  });
}
