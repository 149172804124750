/* eslint-disable import/no-unresolved */ // Temporary fix for swiper
import "swiper/css";
import "swiper/css/a11y";
import "swiper/css/navigation";
import classNames from "classnames";
import {
  CSSProperties,
  forwardRef,
  HTMLAttributes,
  useMemo,
} from "react";
import styled, { css } from "styled-components";
import { A11y, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { colors } from "../../../constants";
import Alert from "../../../lib/Alert";
import Skeleton from "../../../lib/Skeleton";
import { formatTime } from "../../../utils/date-fns";
import mediaQuery from "../../../utils/mediaQuery";
import renderError from "../../../utils/renderError";
import { cardStylesMixin } from "../cardStylesMixin";
import { useAvailableCallTimes } from "../hooks/useAvailableCallTimes";

export interface TimeSlotsProps
  extends Omit<HTMLAttributes<HTMLElement>, "onChange"> {
  className?: string;
  style?: CSSProperties;
  selectedDate: Date;
  selectedTime: Date | null;
  selectedExpert: string;
  onChange: (date: Date | null) => void;
  timeZone: string;
}

const TimePart = styled.span`
  color: ${colors.black};
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
`;
const DayPart = styled.span`
  color: ${colors.brownBlack};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;
const Container = styled.div`
  position: relative;
`;

// swiper only for mobile
// https://www.w3.org/WAI/ARIA/apg/example-index/TimeSlots/TimeSlots-1-prev-next.html
const StyledSwiper = styled(Swiper)`
  margin-inline-start: 0;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      display: none !important;
    `,
  )}

  & .swiper-slide {
    height: auto;
    width: 330px;
  }
  .swiper-slide {
    ${cardStylesMixin};

    align-items: center;
    display: flex;
    gap: 8px;
    height: 70px;
    justify-content: center;
    width: 110px;
  }
`;
const ControlButton = styled.button`
  background: white;
  border: 1px solid ${colors.brownLightGrey1};
  bottom: 0;
  height: 100%;
  margin: 0;
  top: 0;
  transition: border-color 0.15s;
  width: 80px;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      display: none !important;
    `,
  )}

  &::after {
    font-size: 24px;
  }
  &:hover {
    border-color: ${colors.coral};
    border-width: 2px;
  }
`;

// flex container for time slots only for desktop
const SlotsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  ${mediaQuery(
    "phone",
    css`
      display: none !important;
    `,
  )}
`;

const Slot = styled.div`
  ${cardStylesMixin};
  align-items: center;
  display: flex;
  gap: 8px;
  height: 70px;
  justify-content: center;
  width: 110px;
`;

const StyledSkeleton = styled(Skeleton)`
  max-width: var(--content-max-width);
  padding: 0 var(--content-margin-side);

  ${mediaQuery(
    "greaterThanPhone",
    css`
      padding: 0;
    `,
  )}
`;

const swiperModules = [A11y, Navigation];
const TimeSlots = forwardRef<HTMLDivElement, TimeSlotsProps>(
  (
    {
      selectedExpert,
      selectedDate,
      selectedTime,
      className,
      style,
      onBlur,
      onChange,
      timeZone,
      ...restProps
    },
    ref,
  ) => {
    const { data, isLoading, error } = useAvailableCallTimes(
      selectedExpert,
      timeZone,
      selectedDate,
    );
    const timeSlots = useMemo(() => {
      return data || [];
    }, [data]);
    return (
      <Container
        {...restProps}
        ref={ref}
        className={classNames(className, {
          "swiper-rtl": document.dir === "rtl",
        })}
      >
        <StyledSkeleton active loading={isLoading}>
          {!!error && (
            <Alert status="error">{renderError(error)}</Alert>
          )}
          <ControlButton
            className="swiper-button-prev swiper-button-time-prev"
            type="button"
          />
          <ControlButton
            className="swiper-button-next swiper-button-time-next"
            type="button"
          />
          <StyledSwiper
            key={timeSlots.length}
            dir={document.dir}
            initialSlide={timeSlots.findIndex(
              (date) => date.getTime() === selectedTime?.getTime(),
            )}
            modules={swiperModules}
            navigation={{
              enabled: true,
              nextEl: ".swiper-button-time-next",
              prevEl: ".swiper-button-time-prev",
            }}
            observer
            preventClicksPropagation={false}
            preventInteractionOnTransition={false}
            slidesPerView={3.5}
            slideToClickedSlide
            spaceBetween={10}
            style={style}
          >
            {timeSlots.map((time) => {
              const checked =
                selectedTime?.getTime() === time.getTime();

              // "12:00 AM" => ["12:00", "AM"]
              // "13:00" => ["13:00", undefined]
              const [timeLeft, timeRight] = formatTime(
                time,
                timeZone,
              ).split(" ");

              return (
                <SwiperSlide
                  key={time.getTime()}
                  aria-checked={checked}
                  onBlur={onBlur}
                  onClick={() => {
                    onChange(time);
                  }}
                  role="radio"
                  tabIndex={0}
                >
                  <TimePart>{timeLeft}</TimePart>
                  <DayPart>{timeRight}</DayPart>
                </SwiperSlide>
              );
            })}
          </StyledSwiper>

          <SlotsContainer>
            {timeSlots.map((time) => {
              const checked =
                selectedTime?.getTime() === time.getTime();

              const [timeLeft, timeRight] = formatTime(
                time,
                timeZone,
              ).split(" ");

              return (
                <Slot
                  key={time.getTime()}
                  aria-checked={checked}
                  onBlur={onBlur}
                  onClick={() => {
                    onChange(time);
                  }}
                  role="radio"
                  tabIndex={0}
                >
                  <TimePart>{timeLeft}</TimePart>
                  <DayPart>{timeRight}</DayPart>
                </Slot>
              );
            })}
          </SlotsContainer>
        </StyledSkeleton>
      </Container>
    );
  },
);

export default TimeSlots;
