import * as t from "io-ts";
import { HtmlBody } from "../../../io-ts/types";
import { Category } from "./Category";
import { ClinicEntityWithRelations } from "./Clinic";

export const ProgramC = t.type({
  attributes: t.type({
    body: HtmlBody,
    clinic_layout: t.union([
      t.literal("hormone_replacement"),
      t.literal("clinic"),
      t.literal("provider"),
      t.literal("room"),
      t.literal("doula"),
    ]),
    flat_max_reimbursement: t.boolean,
    name: t.string,
    program_type: t.string,
    reimbursable: t.boolean,
    remaining_reimbursable_coverage: t.number,
    total_reimbursable_coverage: t.number,
  }),
  id: t.string,
  type: t.literal("program"),
});

export type ProgramEntity = t.TypeOf<typeof ProgramC>;

export interface ProgramEntityWithRelations extends ProgramEntity {
  relationships: {
    global_clinics: ClinicEntityWithRelations[];
    nearby_clinics: ClinicEntityWithRelations[];
    program_category: Category;
  };
}
