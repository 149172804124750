import { FC } from "react";
import styled, { css } from "styled-components";
import mediaQuery from "../../../utils/mediaQuery";
import PageLayout, { Body, Footer, Sidebar } from "../../PageLayout";
import Categories from "../Categories";
import ArticlesHeader, {
  BackButton,
  Inner as HeaderInner,
} from "../Header";
import { ArticlesContainer } from "../style";
import ArticleFull from "./ArticleFull";

const CategoriesContainer = styled.div`
  ${mediaQuery(
    "phone",
    css`
      display: none;
    `,
  )}
`;

const StyledPageLayout = styled(PageLayout)`
  ${mediaQuery(
    "phone",
    css`
      ${BackButton} {
        display: block;
      }
      ${HeaderInner} {
        display: none;
      }
    `,
  )}
`;

const ArticlePage: FC = () => (
  <StyledPageLayout
    footer={<Footer />}
    header={<ArticlesHeader />}
    sidebar={<Sidebar />}
  >
    <Body>
      <CategoriesContainer>
        <Categories />
      </CategoriesContainer>
      <ArticlesContainer>
        <ArticleFull />
      </ArticlesContainer>
    </Body>
  </StyledPageLayout>
);

export default ArticlePage;
