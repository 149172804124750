import styled from "styled-components";
import HiddenProvider from "./HiddenProvider";
import { HiddenProviderEntity } from "./types";

const List = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
`;

export interface HiddenProvidersListProps {
  providers: HiddenProviderEntity[];
}

export default function HiddenProvidersList(
  props: HiddenProvidersListProps,
) {
  const { providers } = props;
  return (
    <List>
      {providers.map((provider) => (
        <HiddenProvider key={provider.id} provider={provider} />
      ))}
    </List>
  );
}
