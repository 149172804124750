import { isLeft } from "fp-ts/Either";
import * as t from "io-ts";
import { useQuery } from "react-query";
import { UseQueryResult } from "react-query/types/react/types";
import { wrongResponseFormat } from "../../../locales/errors";
import { useAxios } from "../../../network";

export const responseC = t.type({
  data: t.array(
    t.type({
      attributes: t.type({
        name: t.string,
      }),
      id: t.string,
      type: t.literal("program_category"),
    }),
  ),
});

export type Category = {
  id: string;
  name: string;
  slug: string;
};

function responseToEntities(
  response: t.TypeOf<typeof responseC>,
): Category[] {
  const { data } = response;

  return data.map((item) => ({
    id: item.id,
    name: item.attributes.name,
    slug: item.id.replaceAll("_", "-"),
  }));
}

export const useGetProgramsCategories = (): UseQueryResult<
  Category[]
> => {
  const axios = useAxios();
  return useQuery(
    "getCompanyProgramsCategories",
    async () => {
      const response = await axios.get(
        `/v2/company/programs/categories`,
      );

      const decodeResult = responseC.decode(response.data);

      if (isLeft(decodeResult)) {
        // eslint-disable-next-line no-console
        console.error(decodeResult);
        throw wrongResponseFormat;
      }
      return responseToEntities(decodeResult.right);
    },
    {
      cacheTime: 300000,
      staleTime: 60000,
    },
  );
};
