import { i18n } from "@lingui/core";
import { captureException } from "@sentry/react";
import Cookies from "js-cookie";
import { FC, ForwardedRef, forwardRef, useMemo } from "react";
import ReCAPTCHABase, {
  ReCAPTCHAProps as ReCAPTCHABaseProps,
} from "react-google-recaptcha";
import { getSwitcherLocale } from "../../locales/appLocale/config";
import ReCAPTCHADisabled from "./ReCAPTCHADisabled";

export type ReCAPTCHAExternalProps = Omit<
  ReCAPTCHABaseProps,
  "sitekey"
>;
export interface ReCAPTCHAProps extends ReCAPTCHAExternalProps {
  forwardedRef?: ForwardedRef<ReCAPTCHABase>;
}

/**
 * ReCAPTCHA may not support all the languages we support.
 * @param lng
 */
function getRecaptchaLanguage(lng: string): string {
  const localeData = getSwitcherLocale(lng);
  if (localeData) {
    return localeData.recaptchaLocale;
  }
  captureException(
    new Error("Locale is not supported by ReCAPTCHA"),
    {
      extra: {
        locale: i18n.locale,
      },
      level: "warning",
    },
  );

  return "en";
}
const ReCAPTCHABound: FC<ReCAPTCHAProps> = (props) => {
  const { forwardedRef, size = "invisible", ...restProps } = props;
  const sitekey = import.meta.env.VITE_RECAPTCHA_SITEKEY;
  // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // // @ts-ignore
  // FIXME: mobile app does not support recaptcha.net yet
  // window.recaptchaOptions = {
  //   useRecaptchaNet: true,
  // };
  if (typeof sitekey !== "string") {
    throw new Error("VITE_RECAPTCHA_SITEKEY is not defined");
  }
  const isDisabled = useMemo(() => {
    const disabled =
      !!Cookies.get("_skip_recaptcha") ||
      import.meta.env.VITE_RECAPTCHA_DISABLED === "true";

    if (disabled) {
      // eslint-disable-next-line no-console
      console.warn("ReCAPTCHA disabled by a cookie");
    }
    return disabled;
  }, []);

  if (isDisabled) {
    return <ReCAPTCHADisabled ref={forwardedRef as never} />;
  }
  const hl = getRecaptchaLanguage(i18n.locale);

  return (
    <ReCAPTCHABase
      {...restProps}
      ref={forwardedRef}
      hl={hl}
      sitekey={sitekey}
      size={size}
    />
  );
};

const ReCAPTCHA = forwardRef(
  (
    props: ReCAPTCHAExternalProps,
    ref: ForwardedRef<ReCAPTCHABase>,
  ) => {
    return <ReCAPTCHABound {...props} forwardedRef={ref} />;
  },
);

export default ReCAPTCHA;
