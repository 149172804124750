import { i18n } from "@lingui/core";
import { t, Trans } from "@lingui/macro";
import { format as formatDate } from "date-fns";
import { FC } from "react";
import styled from "styled-components";
import {
  GreyBlock,
  GreyBlockTitle,
  IconAttention,
} from "../../../../lib/GreyBlock";
import Link from "../../../../lib/Link";
import { formatCurrency } from "../../../../utils/currency";
import { Reimbursement } from "../../../models/Reimbursement";
import { ReimbursementEvent } from "../../../models/ReimbursementEvent";
import { State } from "../../../models/State";
import { Services } from "./Services";
import {
  FirstRow,
  PracticeRow,
  ServiceDateRow,
  BoldBlack,
  TotalReimbursementAmount,
  TotalReimbursement,
} from "./style";

type Props = {
  reimbursement: Reimbursement;
  state: State;
  event: ReimbursementEvent;
  currency: string;
};

const StyledGreyBlock = styled(GreyBlock)`
  margin-top: 20px;
`;

const DATE_FORMAT = "MM-dd-yyyy";
const dt = (date: Date) => formatDate(date, DATE_FORMAT);

export const Body: FC<Props> = (props: Props) => {
  const { reimbursement, event, state, currency } = props;
  const { createdAt } = event;
  const { providerName, programName, totalApprovedAmount } =
    reimbursement;
  const date = dt(createdAt);

  // We cannot cancel "approved" or "rejected" reimbursement
  // And first event is always "requested"
  const isCancellable = reimbursement.events.length === 1;

  return (
    <>
      {(state === "requested" || state === "approved") && (
        <FirstRow>
          {state === "requested" && (
            <Trans>
              There is no action required from you and the review
              process may take up to 24 hours.
            </Trans>
          )}

          {/* Do we need a reason for cancelation both for user and adminUser?
          Is there a case for adminUser to cancel reimbursement? */}
          {/* {state === "canceled" && (
            <Trans>Reason for cancelation: [reason]</Trans>
          )} */}

          {state === "approved" && (
            <Trans>
              There is no action required from you and this approved
              amount will be sent to your company for reimbursement.
            </Trans>
          )}
        </FirstRow>
      )}

      <PracticeRow>
        <Trans>
          Receipt from <BoldBlack>{providerName}</BoldBlack> for{" "}
          <BoldBlack>{programName}</BoldBlack>
          <ServiceDateRow>
            submitted on <BoldBlack>{date}</BoldBlack>
          </ServiceDateRow>
        </Trans>
      </PracticeRow>

      {state === "approved" && (
        <TotalReimbursement>
          <Trans>Total reimbursement</Trans>
          <TotalReimbursementAmount>
            {formatCurrency(
              totalApprovedAmount,
              currency,
              i18n.locale,
            )}
          </TotalReimbursementAmount>
        </TotalReimbursement>
      )}

      {state !== "canceled" && (
        <Services isCancellable={isCancellable} {...props} />
      )}

      {(state === "requested" || state === "approved") && (
        <StyledGreyBlock>
          {state === "requested" && (
            <>
              <GreyBlockTitle
                icon={<IconAttention />}
                title={t`Important: Stork Club will review the request and if
                approved, it will be sent to your company to reimburse you
                or your partner.`}
              />
              <p>
                <Trans>
                  If you have any questions, please reach out to your{" "}
                  <Link to="/care-navigator">Care&nbsp;Partner</Link>.
                </Trans>
              </p>
            </>
          )}

          {state === "approved" && (
            <>
              <GreyBlockTitle
                icon={<IconAttention />}
                title={t`Your company will confirm eligibility and decide to
                reimburse you or your partner.`}
              />
              <p>
                <Trans>
                  If you have any questions about payroll, please
                  reach out to your HR team.
                </Trans>
              </p>
              <p>
                <Trans>
                  If you have any other questions, please reach out to
                  your{" "}
                  <Link to="/care-navigator">Care&nbsp;Partner</Link>.
                </Trans>
              </p>
            </>
          )}
        </StyledGreyBlock>
      )}
    </>
  );
};
