import * as t from "io-ts";
import { BackendLanguageCodeC } from "../../locales/appLocale/config";
import JSONApiErrorsPayloadC from "../../network/jsonApi/core/JSONApiErrorsPayload";

export const RegistrationCompanyC = t.type({
  attributes: t.intersection([
    t.type({
      care_team_email: t.union([t.string, t.null]),
      country_code: t.string,
      country_name: t.string,
      default_language: t.string,
      languages: t.readonlyArray(BackendLanguageCodeC),
      name: t.string,
      password_min_length: t.number,
    }),
    // not merged to master yet
    t.partial({
      partner_invitation_enabled: t.boolean,
    }),
  ]),
  id: t.string,
  type: t.literal("company"),
});

export type RegistrationCompany = t.TypeOf<
  typeof RegistrationCompanyC
>;

export const ResetPasswordTokenHolderC = t.type({
  data: t.type({
    attributes: t.type({
      email: t.string,
    }),
    relationships: t.type({
      company: t.type({
        data: t.type({ id: t.string, type: t.literal("company") }),
      }),
    }),
    type: t.literal("token_holder"),
  }),
  included: t.readonlyArray(RegistrationCompanyC),
});
export const ResetPasswordTokenHolderResponseC = t.union([
  ResetPasswordTokenHolderC,
  JSONApiErrorsPayloadC,
]);

export const RegistrationTokenHolderC = t.type({
  data: t.type({
    attributes: t.type({
      require_employee_id: t.boolean,
    }),
    relationships: t.type({
      allowed_companies: t.type({
        data: t.readonlyArray(
          t.type({ id: t.string, type: t.literal("company") }),
        ),
      }),
    }),
    type: t.literal("token_holder"),
  }),
  included: t.readonlyArray(RegistrationCompanyC),
});

export const RegistrationTokenHolderResponseC = t.union([
  RegistrationTokenHolderC,
  JSONApiErrorsPayloadC,
]);
