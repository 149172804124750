import * as React from "react";
import styled from "styled-components";
import { colors } from "../../constants/colors";
import { fonts } from "../../constants/fonts";
import {
  type ApplicationLocaleName,
  languageSwitcherLocalesMap,
  type LanguageSwitcherLocalesMap,
} from "../../locales/appLocale/config";
import updateFrontendLocale from "../../locales/appLocale/updateFrontendLocale";

const Container = styled.div`
  background: #fff;
  border: 1px solid ${colors.brownLightGrey1};
  border-radius: 12px;
  box-shadow: 0 0 14px 0 #0000000a;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  inset-inline-end: 0;
  min-width: 152px;
  position: absolute;
  top: 46px;
  white-space: nowrap;
  z-index: 1;
`;

const Item = styled.div<{ isActive: boolean }>`
  padding: 7px 20px;
  background: ${({ isActive }) =>
    isActive ? colors.brownLightGrey1 : undefined};
  color: ${colors.black};
  cursor: ${({ isActive }) => (isActive ? "default" : "pointer")};
  font-family: ${fonts.faktum};
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;

  &:hover {
    color: ${colors.blue};
  }
`;

type Props = {
  onClose: () => void;
  activeLocale: string;
  locales: ReadonlyArray<{
    code: keyof LanguageSwitcherLocalesMap;
    fullCode: ApplicationLocaleName;
    title: string;
  }>;
  switcherRef: React.RefObject<HTMLDivElement>;
};

export const LocalesPanel: React.FC<Props> = ({
  onClose,
  activeLocale,
  locales,
  switcherRef,
}: Props) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const handleClickOutside = React.useCallback(
    (event: Event) => {
      if (
        !containerRef?.current?.contains(event.target as Node) &&
        !switcherRef?.current?.contains(event.target as Node)
      ) {
        onClose();
      }
    },
    [onClose, switcherRef],
  );

  const onLocaleClick = (code: keyof LanguageSwitcherLocalesMap) => {
    const { fullCode } = languageSwitcherLocalesMap[code];
    updateFrontendLocale(fullCode);
    // onClose();
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const onItemClick = (code: keyof LanguageSwitcherLocalesMap) => {
    if (activeLocale !== code) {
      onLocaleClick(code);
    }
  };

  return (
    <Container ref={containerRef}>
      {locales.map((item) => (
        <Item
          key={item.code}
          isActive={activeLocale === item.fullCode}
          onClick={() => onItemClick(item.code)}
        >
          {item.title}
        </Item>
      ))}
    </Container>
  );
};
