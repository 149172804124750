import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import { FC } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../../../constants";
import { formatCurrency } from "../../../../../utils/currency";
import mediaQuery from "../../../../../utils/mediaQuery";
import { UserPlanComplete } from "../../../../models/UserPlan";
import { BrownGrey } from "../../style";
import { GridMobile, Value } from "./Responsibility.style";

type Props = {
  userPlan: UserPlanComplete;
  isRx?: boolean;
};

const Title = styled.div`
  color: ${colors.brownBlack};
  font-weight: 500;
  margin-bottom: 6px;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 10px;
    `,
  )}
`;

const SubTitle = styled.div`
  color: ${colors.brownGrey};
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 2px;
`;

const StyledValue = styled(Value)`
  margin-bottom: 8px;
`;

const Info = styled.div`
  color: ${colors.brownGrey};
  font-size: 15px;
  line-height: 24px;
`;

const StyledGridMobile = styled(GridMobile)`
  ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 8px;
    `,
  )}
`;

const CostSharing: FC<Props> = ({ userPlan, isRx }: Props) => {
  if (!isRx) {
    const { copay, coinsurance } = userPlan;
    const costSharing =
      copay > 0
        ? `${formatCurrency(copay, "USD", i18n.locale, {
            maximumFractionDigits: 0,
          })}`
        : `${coinsurance}%`;

    return (
      <>
        <Title>
          <Trans>Cost-sharing</Trans>
        </Title>
        {costSharing ? (
          <Value>
            <Trans>
              {costSharing}
              <br />
              after Deductible
            </Trans>
          </Value>
        ) : (
          <BrownGrey>
            <Trans>cannot be shown</Trans>
          </BrownGrey>
        )}
      </>
    );
  }
  const { copayRx, coinsuranceRx, noDeductibleRx } = userPlan;
  const isCopay = copayRx > 0;

  if (isCopay && noDeductibleRx) {
    // $100 copay per medication, NO deductible
    return (
      <>
        <Title>
          <Trans>Cost-sharing</Trans>
        </Title>
        <SubTitle>
          <Trans>Copay</Trans>
        </SubTitle>
        <StyledValue>
          <Trans>
            {formatCurrency(copayRx, "USD", i18n.locale, {
              maximumFractionDigits: 0,
            })}{" "}
            per Rx
          </Trans>
        </StyledValue>
        <Info>
          <Trans>
            SMP Pharmacy will collect the copay from you directly
            before shipping the order.
          </Trans>
        </Info>
      </>
    );
  }

  if (isCopay && !noDeductibleRx) {
    // $100 copay per medication, AFTER deductible
    return (
      <>
        <Title>
          <Trans>Cost-sharing</Trans>
        </Title>
        <StyledGridMobile>
          <div>
            <SubTitle>
              <Trans>Deductible</Trans>
            </SubTitle>
            <Value>
              <Trans>Remaining amount</Trans>
            </Value>
          </div>
          <div>
            <SubTitle>
              <Trans>Copay</Trans>
            </SubTitle>
            <Value>
              <Trans>
                {formatCurrency(copayRx, "USD", i18n.locale, {
                  maximumFractionDigits: 0,
                })}{" "}
                per Rx,
                <br />
                after deductible
              </Trans>
            </Value>
          </div>
        </StyledGridMobile>
        <Info>
          <Trans>
            SMP Pharmacy will collect the copay from you directly
            before shipping the order.
          </Trans>
        </Info>
      </>
    );
  }

  // XX% coinsurance
  return (
    <>
      <Title>Cost-sharing</Title>
      {coinsuranceRx ? (
        <StyledValue>
          <Trans>
            {coinsuranceRx}%
            <br />
            after Deductible
          </Trans>
        </StyledValue>
      ) : (
        <BrownGrey>
          <Trans>cannot be shown</Trans>
        </BrownGrey>
      )}
    </>
  );
};

export default CostSharing;
