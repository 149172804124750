import { useMutation } from "react-query";
import { ISODate } from "../../../io-ts/types";
import { useAxios } from "../../../network";
import { ENDPOINTS } from "../../../network/endpoints";

export interface SubmitReimbursementPayload {
  receipt: File;
  programId: string;
  providerName: string;
  services: ReadonlyArray<{
    name: string;
    date: Date;
    amount: number;
  }>;
}
export function useSubmitReimbursement() {
  const axios = useAxios();
  return useMutation(async (payload: SubmitReimbursementPayload) => {
    const data = new FormData();
    data.append("receipt", payload.receipt);
    data.append("program_id", payload.programId.toString());
    data.append("provider_name", payload.providerName);
    payload.services.forEach((service) => {
      data.append(`services[][name]`, service.name);
      data.append(`services[][date]`, ISODate.encode(service.date));
      data.append(`services[][amount]`, service.amount.toString());
    });
    await axios.post(ENDPOINTS.reimbursementsSubmit, data);
    // TODO: handle errors
  });
}
