import styled, { css } from "styled-components";
import { colors, fonts } from "../../../../constants";
import { highContrastMode } from "../../../../constants/colors";
import mediaQuery from "../../../../utils/mediaQuery";

export const Container = styled.section`
  background: #fff;
  border: 1px solid ${colors.brownLightGrey1};
  border-radius: 10px;
  box-sizing: border-box;
  display: grid;
  grid-column-gap: 15px;
  grid-row-gap: 20px;
  grid-template-areas:
    "title avatar"
    "buttons buttons";
  grid-template-columns: minmax(0, 1fr) auto;
  padding: 20px 10px 10px;
  position: relative;

  ${mediaQuery(
    "desktopMedium",
    css`
      grid-column-gap: 20px;
      grid-template-areas:
        "title avatar"
        "buttons avatar";
      padding: 40px 60px;
    `,
  )}
`;

export const Title = styled.h1`
  color: #000;
  font-family: ${fonts.alt};
  font-size: 24px;
  grid-area: title;
  line-height: 29px;
  margin: 0;
  padding-left: 10px;

  ${mediaQuery(
    "desktopMedium",
    css`
      padding-left: 0;

      br {
        display: none;
      }
    `,
  )}
`;

export const AvatarContainer = styled.div`
  grid-area: avatar;
  padding-inline-end: 10px;

  ${mediaQuery(
    "desktopMedium",
    css`
      display: flex;
      flex-direction: row-reverse;
      margin: auto;
      padding-inline-end: 0;
    `,
  )}
`;

export const Avatar = styled.img`
  aspect-ratio: 1;
  border-radius: 50%;
  height: 80px;
  object-fit: cover;
  object-position: center;
  width: 80px;
  ${mediaQuery(
    "desktopMedium",
    css`
      height: 120px;
      width: 120px;
    `,
  )}
`;

export const Name = styled.div`
  color: ${colors.brownBlack};
  font-family: ${fonts.main};
  font-size: 12px;
  line-height: 16px;
  margin: 4px 0 0 0;
  opacity: 0.6;
  text-align: center;
  ${mediaQuery(
    "desktopMedium",
    css`
      margin: 70px 6px 0 0;
      text-align: end;
    `,
  )}
`;

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  grid-area: buttons;
  width: 100%;

  ${mediaQuery(
    "desktopMedium",
    css`
      gap: 10px;
      width: auto;
    `,
  )}
`;

export const IconContainer = styled.span`
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 24px;
  justify-content: center;
  margin-inline-end: 7px;
  width: 24px;
`;

export const EnvelopeContainer = styled(IconContainer)`
  background: ${highContrastMode ? colors.white : colors.blue};
  color: ${!highContrastMode ? colors.white : colors.blue};
`;

export const CameraContainer = styled(IconContainer)`
  background: ${highContrastMode ? colors.white : colors.green};
  color: ${!highContrastMode ? colors.white : colors.green};
`;

export const TitleRow = styled.span`
  display: inline-block;
`;
