import { FC, useState } from "react";
import Expandable from "../../../lib/Expandable";
import Icon from "../../../lib/SharedIcon";
import { Authorization } from "../../models/Authorization";
import { AuthorizationEvent } from "../../models/AuthorizationEvent";
import { UserPlan } from "../../models/UserPlan";
import { Body, BodyBill } from "./Body";
import { HeaderTitle } from "./HeaderTitle";
import { StatusIcon } from "./StatusIcon";
import {
  NotificationContainer,
  Header,
  HeaderIcon,
  ExpandIcon,
  BodyInner,
} from "./style";

export type Props = {
  onOpen: (event: AuthorizationEvent) => void;
  isExpandedOnMount: boolean;
  authorization: Authorization;
  isOpened: boolean;
  userPlan: UserPlan;
  event: AuthorizationEvent;
};

export const Notification: FC<Props> = (props: Props) => {
  const {
    isExpandedOnMount = false,
    onOpen,
    isOpened,
    userPlan,
    authorization,
    event,
  } = props;
  const { isBill, status, isRead } = event;
  const { bill } = authorization;

  const [isExpanded, setExpanded] = useState(isExpandedOnMount);

  const onNotificationClick = () => {
    if (!isExpanded) {
      onOpen(event);
    }
    setExpanded(!isExpanded);
  };

  const isBillPaymentFailed = status === "billPaymentFailed";

  return (
    <NotificationContainer
      $isSeen={isRead || isOpened}
      isError={isBillPaymentFailed}
      isExpanded={isExpanded}
    >
      <Header
        isExpanded={isExpanded}
        onClick={onNotificationClick}
        status={status}
      >
        <HeaderIcon>
          <StatusIcon status={status} />
        </HeaderIcon>
        <HeaderTitle {...props} />
        <ExpandIcon
          isFlip={isExpanded}
          isWhiteBg={isExpanded}
          shouldHover={!isExpanded}
        >
          <Icon type="arrowDown" width="14px" />
        </ExpandIcon>
      </Header>
      <Expandable isExpanded={isExpanded}>
        <BodyInner>
          {isBill && bill && (
            <BodyBill
              authorization={authorization}
              bill={bill}
              status={status}
              userPlan={userPlan}
            />
          )}
          {!isBill && (
            <Body
              authorization={authorization}
              status={status}
              userPlan={userPlan}
            />
          )}
        </BodyInner>
      </Expandable>
    </NotificationContainer>
  );
};
