/* eslint-disable max-lines */
import { FC, useEffect } from "react";
import { Navigate, Outlet, Routes } from "react-router";
import { Route, useSearchParams } from "react-router-dom";
import ActivateMembershipScreen from "./authentication/components/ActivateMembershipScreen";
import ChangePasswordScreen from "./authentication/components/ChangePasswordScreen";
import ForgotPasswordScreen from "./authentication/components/ForgotPasswordScreen";
import ConsentToSharing from "./authentication/components/onboarding/ConsentToSharing";
import StatusScreenGuard from "./authentication/components/onboarding/StatusScreenGuard";
import {
  NavigateToSignIn,
  RestoreLocation,
} from "./authentication/components/RestoreLocation";
import SignInScreen from "./authentication/components/SignInScreen";
import SignUpScreen from "./authentication/components/SignUpScreen";
import UnlockScreen from "./authentication/components/UnlockScreen";
import useAuthSession, {
  SessionUser,
} from "./authentication/hooks/useAuthSession";
import { useSignOut } from "./authentication/hooks/useSignOut";
import CallPage from "./calls/components/CallPage";
import DownloadAppsPage from "./calls/components/DownloadAppsPage";
import CareNavigatorScreen from "./care-navigator/CareNavigatorScreen";
import ChatInvitation from "./care-navigator/ChatInvitation";
import Article from "./components/articles/ArticlePage";
import ArticlesSearch from "./components/articles/ArticlesSearch";
import ArticlesCategory from "./components/articles/CategoryPage";
import Articles from "./components/articles/ListPage";
import CallBooking from "./components/CallBooking";
import CallNotifications from "./components/CallNotifications";
import CallSubjects from "./components/CallSubjects";
import CompanyPolicies from "./components/company-policies";
import CompanyPrograms from "./components/company-programs";
import ProgramsListPage from "./components/company-programs/ProgramsListPage";
import Error404Screen from "./components/Error404Screen";
import GlobalSpinner from "./components/GlobalSpinner";
import HiddenProvidersPage from "./components/hidden-providers/HiddenProvidersPage";
import HomeScreen from "./components/HomeScreen";
import Hipaa from "./components/legal/hipaa";
import Privacy from "./components/legal/privacy";
import ToS from "./components/legal/tos";
import MobileAppPage from "./components/MobileApp";
import UnreadCountProvider from "./components/PageLayout/Sidebar/UnreadCountProvider";
import Payment from "./components/Payment";
import PaymentOAuth from "./components/Payment/oAuth";
import ProfileCompletion from "./components/ProfileCompletion";
import ProfilePage from "./components/ProfilePage";
import { MessagesRenderRoot } from "./lib/messages";
import MessagesHost from "./lib/messages/MessagesApiProvider";
import { TitleAnnouncerContextProvider } from "./lib/PageTitle/Announcer";
import RTLProvider from "./lib/RTLProvider";
import Notifications from "./notifications/components/NotificationsPage";
import ReimbursementPage from "./reimbursement/components/ReimbursementPage";
import ReimbursementSuccessResult from "./reimbursement/components/ReimbursementSuccessResult";
import { isInMobileApp } from "./utils/mobileRedirect";
import {
  usePostMessageQuestion,
  useSyncSession,
} from "./utils/react-native";

const SignOutRoute = () => {
  const { mutate: signOut } = useSignOut();
  useEffect(() => {
    signOut();
  }, [signOut]);
  return null;
};

const SyncSessionMiddleware = ({ user }: { user: SessionUser }) => {
  const askQuestion = usePostMessageQuestion();
  const syncSession = useSyncSession();

  useEffect(() => {
    if (
      window.ReactNativeWebView &&
      window.location.pathname !== "/signout"
    ) {
      // eslint-disable-next-line no-console
      console.debug("ask question");
      askQuestion("get_has_session", (hasSession: boolean | null) => {
        // eslint-disable-next-line no-console
        console.debug("question answered", hasSession);
        if (hasSession === false) {
          syncSession(user)
            .then(() => {
              // eslint-disable-next-line no-console
              console.debug("Sent set_session");
            })
            // eslint-disable-next-line no-console
            .catch((err) => console.error(err));
        }
      });
    }
  }, [askQuestion, syncSession, user]);

  return <Outlet />;
};

function useSyncXCRFToken(token: string | null, enabled: boolean) {
  useEffect(() => {
    if (enabled && window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          action: "set_csrf_token",
          payload: {
            token,
          },
        }),
      );
    }
  }, [enabled, token]);
}

const App: FC = () => {
  const { data: authSession, isLoading: isAuthSessionLoading } =
    useAuthSession();
  const isAuthenticated = !!authSession && !!authSession.user;
  useSyncXCRFToken(authSession?.csrfToken || null, !!authSession);

  const [params] = useSearchParams();
  const shouldRedirectToDownloadAppsPage =
    !!params.get("call_id") && !window.ReactNativeWebView;

  const isPartner = !!authSession?.user?.attributes.partner;

  if (isAuthSessionLoading) {
    return <GlobalSpinner />;
  }

  const askDataConsent =
    !!authSession?.user?.attributes.require_data_sharing_consent;

  const authenticatedRoutes =
    !!authSession && !!authSession.user ? (
      <Route element={<RestoreLocation />}>
        <Route
          element={askDataConsent ? <ConsentToSharing /> : <Outlet />}
        >
          <Route
            element={
              authSession?.user ? (
                <StatusScreenGuard
                  askMemberId={
                    authSession.user.attributes.ask_member_id
                  }
                  availableStatuses={
                    authSession.user.availableStatuses
                  }
                  memberId={authSession.user.attributes.member_id}
                  noticeMemberIdChange={
                    authSession?.user?.attributes
                      .notice_member_id_change
                  }
                  skipMemberIdUpdate={
                    authSession?.user?.attributes
                      .skip_member_id_update
                  }
                  userStatus={authSession.user.attributes.status}
                />
              ) : (
                <Outlet />
              )
            }
          >
            <Route
              element={
                authSession?.user ? (
                  <SyncSessionMiddleware user={authSession.user} />
                ) : (
                  <Outlet />
                )
              }
            >
              <Route
                element={
                  <UnreadCountProvider
                    channelUrl={
                      authSession.user.attributes.sendbird_credentials
                        .channel_url
                    }
                  >
                    <Outlet />
                    <ChatInvitation />
                  </UnreadCountProvider>
                }
              >
                <Route element={<HomeScreen />} index />
                <Route
                  element={<ProfileCompletion />}
                  path="/profile/completion/new"
                />
                <Route
                  element={<Notifications />}
                  path="/notifications/:authorizationUidOrId/:eventId"
                />
                <Route
                  element={<Notifications />}
                  path="/notifications/:authorizationUidOrId"
                />
                <Route
                  element={<Notifications />}
                  path="/notifications"
                />
                <Route
                  element={<ArticlesSearch />}
                  path="/articles/search"
                />
                <Route
                  element={<ArticlesCategory />}
                  path="/articles/by-category/:category"
                />
                <Route element={<Article />} path="/articles/:slug" />

                <Route
                  element={<CareNavigatorScreen />}
                  path="/care-navigator"
                />
                <Route element={<Articles />} path="/articles" />
                <Route element={<Navigate to="/" />} path="/signin" />
                <Route
                  element={<Navigate to="/" />}
                  path="/signup/*"
                />
                <Route
                  element={<Navigate to="/" />}
                  path="/password/new"
                />
                <Route
                  element={<Navigate to="/" />}
                  path="/password/edit"
                />
                <Route
                  element={<CompanyPolicies />}
                  path="/company-policies/:slug"
                />
                <Route
                  element={<CompanyPolicies />}
                  path="/company-policies"
                />
                <Route
                  element={<CompanyPrograms />}
                  path="/programs/:categorySlug/:slug"
                />
                <Route
                  element={<CompanyPrograms />}
                  path="/programs/:categorySlug"
                />
                <Route
                  element={<ProgramsListPage />}
                  path="/programs"
                />
                <Route
                  element={<CallSubjects />}
                  path="/talk-to-experts/:categoryId"
                />
                <Route
                  element={<CallSubjects />}
                  path="/talk-to-experts"
                />
                <Route
                  element={<CallBooking />}
                  path="/schedule/calls/new"
                />
                <Route element={<Hipaa />} path="/legal/hipaa" />
                <Route element={<Privacy />} path="/legal/privacy" />
                <Route element={<ToS />} path="/legal/tos" />

                <Route element={<ProfilePage />} path="/profile" />
                <Route
                  element={<ProfilePage editMode />}
                  path="/profile/edit"
                />
                {!isPartner && (
                  <Route
                    element={<ProfilePage addPartnerMode />}
                    path="/profile/add-partner"
                  />
                )}
                <Route
                  element={<MobileAppPage />}
                  path="/download-our-app"
                />
                <Route element={<CallPage />} path="/join/:token" />
                <Route
                  element={<DownloadAppsPage />}
                  path="/pages/download_apps"
                />
                <Route
                  element={<DownloadAppsPage />}
                  path="/calls/:token"
                />

                <Route element={<Payment />} path="/bills/:uid" />
                <Route
                  element={<PaymentOAuth />}
                  path="/bills/oauth"
                />
                <Route
                  element={<ReimbursementSuccessResult />}
                  path="/reimbursement/success"
                />
                <Route
                  element={
                    <ReimbursementPage
                      currency={
                        authSession.user.company.attributes
                          .country_currency
                      }
                    />
                  }
                  path="/reimbursement"
                />
                <Route
                  element={<HiddenProvidersPage />}
                  path="/all-providers"
                />
                <Route element={<SignOutRoute />} path="/signout" />
                <Route element={<Error404Screen />} path="*" />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    ) : null;
  const notAuthenticatedRoutes = (
    <Route>
      <Route element={<SignInScreen />} path="/signin" />
      <Route element={<ActivateMembershipScreen />} path="/signup" />
      <Route element={<SignUpScreen />} path="/signup/continue" />
      <Route
        element={<ForgotPasswordScreen />}
        path="/password/new"
      />
      <Route
        element={<ChangePasswordScreen />}
        path="/password/edit"
      />
      <Route element={<CallPage />} path="/join/:token" />
      <Route element={<UnlockScreen />} path="/unlock" />
      <Route element={<MobileAppPage />} path="/download-our-app" />
      <Route element={<Hipaa />} path="/legal/hipaa" />
      <Route element={<Privacy />} path="/legal/privacy" />
      <Route element={<ToS />} path="/legal/tos" />
      <Route element={<SignOutRoute />} path="/signout" />
      <Route element={<NavigateToSignIn />} path="*" />
    </Route>
  );
  return (
    <RTLProvider>
      <MessagesHost>
        <MessagesRenderRoot />
        {isAuthenticated && !isInMobileApp && !askDataConsent && (
          <CallNotifications />
        )}
        <TitleAnnouncerContextProvider>
          <Routes>
            <Route
              element={
                shouldRedirectToDownloadAppsPage ? (
                  <DownloadAppsPage />
                ) : (
                  <Outlet />
                )
              }
            >
              {isAuthenticated
                ? authenticatedRoutes
                : notAuthenticatedRoutes}
            </Route>
          </Routes>
        </TitleAnnouncerContextProvider>
      </MessagesHost>
    </RTLProvider>
  );
};

export default App;
