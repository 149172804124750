import { useMutation, useQueryClient } from "react-query";
import { DateFromString } from "../../../io-ts/types";
import { useAxios } from "../../../network";
import { ENDPOINTS } from "../../../network/endpoints";

export const useSchedule = () => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  return useMutation(
    async (data: {
      category: string;
      expertId: string;
      time: Date;
      note: string;
    }) => {
      await axios.post(ENDPOINTS.scheduleCall, {
        call_expert_id: data.expertId,
        call_subject_type: data.category,
        note: data.note,
        time: DateFromString.encode(data.time),
      });

      return true;
    },
    {
      onSuccess: () => {
        // we scheduled a new call, so we need to refresh the list of calls and available slots
        queryClient
          .invalidateQueries("calls")
          // eslint-disable-next-line no-console
          .catch((err) => console.error(err));
      },
    },
  );
};
