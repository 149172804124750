import { isLeft } from "fp-ts/Either";
import reporter from "io-ts-reporters";
import { useQuery } from "react-query";
import { UseQueryResult } from "react-query/types/react/types";
import { wrongResponseFormat } from "../../../locales/errors";
import { useAxios } from "../../../network";
import { denormalize } from "../../../network/jsonApi/denormalize";
import { ProgramEntityWithRelations } from "../models/Program";
import { responseC } from "../models/ProgramResponse";

export const useGetProgram = (
  programType: string,
): UseQueryResult<ProgramEntityWithRelations> => {
  const axios = useAxios();
  return useQuery(["getProgram", programType], async () => {
    const response = await axios.get(
      `/v2/company/programs/${programType}`,
    );

    const decodeResult = responseC.decode(response.data);

    if (isLeft(decodeResult)) {
      // eslint-disable-next-line no-console
      console.error(reporter.report(decodeResult));
      throw wrongResponseFormat;
    }
    return denormalize(decodeResult.right);
  });
};
