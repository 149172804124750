import { isLeft } from "fp-ts/Either";
import * as t from "io-ts";
import reporter from "io-ts-reporters";
import { wrongResponseFormat } from "../../locales/errors";
import { denormalize } from "../../network/jsonApi/denormalize";
import { responseC } from "../models/NotificationsResponse";
import { UserPlan, UserPlanRaw } from "../models/UserPlan";
import { getAuthorization } from "./getAuthorization";
import { getUserPlan } from "./getUserPlan";

function responseToUserPlan(
  response: t.TypeOf<typeof responseC>,
): UserPlan {
  const { included } = response;
  const userPlan = included.find(
    (item): item is UserPlanRaw => item.type === "user_plan",
  );
  if (!userPlan) {
    throw new Error("user not found");
  }

  return getUserPlan(userPlan);
}

const toGetNotificationsResult = (data: unknown) => {
  const decodeResult = responseC.decode(data);

  if (isLeft(decodeResult)) {
    // eslint-disable-next-line no-console
    console.error(reporter.report(decodeResult));
    throw wrongResponseFormat;
  }
  const denormalized = denormalize(decodeResult.right);
  const authorizations = denormalized
    .map(getAuthorization)
    .sort(
      (a, b) =>
        b.events[0].createdAt.getTime() -
        a.events[0].createdAt.getTime(),
    );
  const userPlan =
    authorizations.length > 0
      ? responseToUserPlan(decodeResult.right)
      : null;

  return {
    authorizations,
    userPlan,
  };
};

export default toGetNotificationsResult;
