/* eslint-disable no-console */
import SendbirdChat from "@sendbird/chat";
import {
  GroupChannelModule,
  SendbirdGroupChat,
} from "@sendbird/chat/groupChannel";
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
} from "react";
import { useQuery } from "react-query";
import useAuthSession from "../../../authentication/hooks/useAuthSession";

export const SdkContext = createContext<SendbirdGroupChat | null>(
  null,
);

export const useSdk = () => {
  const sdk = useContext(SdkContext);
  if (!sdk) {
    throw new Error("Cannot use sdk outside of SendbirdProvider");
  }

  return sdk;
};

export interface SendBirdProviderProps {
  children: ReactNode;
}

const SendBirdProvider: FC<SendBirdProviderProps> = ({
  children,
}) => {
  const { data: userSession } = useAuthSession();
  const appId =
    userSession?.user?.attributes.sendbird_credentials.app_id;
  if (!appId) {
    throw new Error("Cannot use sdk without appId available");
  }

  // we need a singleton here because the sdk is not designed to be used
  // with multiple instances
  const { data: sdk } = useQuery({
    cacheTime: 0,
    queryFn: () => {
      return SendbirdChat.init({
        appId,
        localCacheEnabled: false,
        modules: [new GroupChannelModule()],
      }) as SendbirdGroupChat;
    },
    // single instance, never stale
    queryKey: "sendbird-sdk",
    suspense: true,
  });

  useEffect(() => {
    const tryReconnect = () => {
      if (!sdk) {
        return;
      }
      try {
        console.warn("Try reconnecting SDK");
        if (sdk.connectionState !== "OPEN") {
          // connection is not broken yet
          sdk.reconnect();
        }
      } catch {
        //
      }
    };
    // addEventListener version
    window.addEventListener("online", tryReconnect);
    return () => {
      window.removeEventListener("online", tryReconnect);
    };
  }, [sdk]);

  return (
    <SdkContext.Provider value={sdk || null}>
      {children}
    </SdkContext.Provider>
  );
};

export default SendBirdProvider;
