import {
  FieldErrors,
  MappedErrors,
  SquashedMappedErrors,
} from "./types";

/**
 * Most of the places in the UI can show only one error per field.
 * This function takes the first error from each array of errors.
 */
export default function squashErrors(
  sourceErrors: MappedErrors,
): SquashedMappedErrors {
  const fieldErrors: FieldErrors = {};
  for (const [fieldName, errors] of Object.entries(
    sourceErrors.fieldErrors,
  )) {
    fieldErrors[fieldName] = (errors && errors[0]) || undefined;
  }
  return {
    error: sourceErrors.restErrors[0] || null,
    fieldErrors,
  };
}
