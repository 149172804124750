/* eslint-disable max-lines */
import { t } from "@lingui/macro";
import {
  CSSProperties,
  FC,
  KeyboardEvent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import ScrollHandler from "../../components/ScrollHandler";
import { colors } from "../../constants";
import mediaQuery from "../../utils/mediaQuery";

export interface TabInterface {
  key: string;
  label: ReactNode;
  component: ReactNode;
  route?: string;
}

type Props = {
  tabs: TabInterface[];
  defaultActiveKey?: string;
  withDefaultPadding?: boolean;
  className?: string;
  style?: CSSProperties;
};

export const Header = styled.div<{ withDefaultPadding?: boolean }>`
  -webkit-overflow-scrolling: touch;
  align-items: flex-end;
  background: ${colors.white};
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  justify-content: flex-start;
  overflow-x: auto;
  overflow-y: hidden;
  padding: ${({ withDefaultPadding }) =>
    withDefaultPadding ? "0 var(--content-margin-side)" : "0"};

  ${mediaQuery(
    "greaterThanPhone",
    css`
      /* gmail implementation */
      &::-webkit-scrollbar {
        background: ${colors.brownLightGrey2};
        height: 8px;
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background: transparent;
        border: none;
        border-radius: 4px;
        box-shadow: none;
      }
      &:hover::-webkit-scrollbar-thumb {
        background-color: rgba(32, 33, 36, 0.38);
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: rgba(32, 33, 36, 0.541);
      }
    `,
  )}
`;

const Inner = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

const Tab = styled.button<{
  active: boolean;
}>`
  background: transparent;
  border: 1px solid ${colors.brownLightGrey1};
  border-bottom: none;
  border-radius: 10px 10px 0 0;
  box-sizing: border-box;
  color: ${colors.black};
  cursor: pointer;
  font-size: 15px;
  min-width: 120px;
  padding: 7px 8px 3px;
  position: relative;
  width: 100%;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      width: auto;
    `,
  )}

  &:hover {
    color: ${colors.blue};
  }

  &::before,
  &::after {
    bottom: 0;
    content: "";
    display: block;
    height: 7px;
    position: absolute;
    width: 7px;
  }

  &::before {
    left: -7px;
  }

  &::after {
    right: -7px;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${colors.brownLightGrey2};
      border: 1px solid transparent;
      color: ${colors.blue};
      cursor: default;
      font-weight: bold;
      min-width: 140px;
      padding: 10px 23px 6px;

      &::before {
        background-image: radial-gradient(
          circle at 0px 0px,
          rgba(0, 0, 0, 0) 0,
          rgba(0, 0, 0, 0) 7px,
          ${colors.brownLightGrey2} 7px
        );
      }

      &::after {
        background-image: radial-gradient(
          circle at 7px 0px,
          rgba(0, 0, 0, 0) 0,
          rgba(0, 0, 0, 0) 7px,
          ${colors.brownLightGrey2} 7px
        );
      }
    `};
`;

const Content = styled.div``;

const Tabs: FC<Props> = ({
  tabs,
  defaultActiveKey = tabs[0]?.key,
  withDefaultPadding = false,
  className,
  style,
}: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const fallbackActiveTab = tabs.find(
    (l) => l.key === defaultActiveKey,
  );
  const [activeKey, setActiveKey] = useState<string | undefined>(
    fallbackActiveTab?.key,
  );

  // If tabs are changed and currentActive tab is not in the new tabs - select a new one
  useEffect(() => {
    if (
      !activeKey ||
      !tabs.map((tab) => tab.key).includes(activeKey)
    ) {
      if (fallbackActiveTab) {
        setActiveKey(fallbackActiveTab.key);
      }
    }
  }, [activeKey, fallbackActiveTab, tabs]);

  const tabsRef = useRef<HTMLButtonElement[]>([]);

  const handleClick = useCallback(
    (key: string, route?: string) => {
      setActiveKey(key);
      if (route && pathname !== route) navigate(route);
    },
    [navigate, pathname],
  );
  const focusedTabIndex = useRef<number>(-1);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (focusedTabIndex.current > -1) {
        let newFocusedTabIndex: number | undefined;

        if (e.key === "ArrowRight") {
          newFocusedTabIndex = focusedTabIndex.current + 1;
          // If we're at the end, go to the start
          if (newFocusedTabIndex >= tabs.length) {
            newFocusedTabIndex = 0;
          }
          // Move left
        } else if (e.key === "ArrowLeft") {
          newFocusedTabIndex = focusedTabIndex.current - 1;
          // If we're at the start, move to the end
          if (newFocusedTabIndex < 0) {
            newFocusedTabIndex = tabs.length - 1;
          }
        }
        if (newFocusedTabIndex !== undefined) {
          tabsRef.current[focusedTabIndex.current].setAttribute(
            "tabindex",
            "-1",
          );
          tabsRef.current[newFocusedTabIndex].setAttribute(
            "tabindex",
            "0",
          );
          tabsRef.current[newFocusedTabIndex].focus();
        }
      } else {
        // eslint-disable-next-line no-console
        console.error(
          "Impossible case: we can not call this keyboard event if we have no tabs. Code broken?",
        );
      }
    },
    [tabs],
  );

  return (
    <ScrollHandler>
      <Header
        aria-label={t`Tabs`}
        className={className}
        onKeyDown={handleKeyDown}
        role="tablist"
        style={style}
        withDefaultPadding={withDefaultPadding}
      >
        {tabs.map(({ key, label, route }, i: number) => {
          const active = key === activeKey;

          return (
            <Tab
              key={key}
              ref={(el: HTMLButtonElement) => {
                tabsRef.current[i] = el;
              }}
              active={active}
              aria-selected={active}
              onBlur={() => {
                focusedTabIndex.current = -1;
                setTimeout(() => {
                  // user left focus from tabs, so we need to reset tabIndexes
                  if (focusedTabIndex.current === -1) {
                    tabsRef.current.forEach((tabRef) => {
                      tabRef.setAttribute("tabindex", "-1");
                    });
                    const activeIndex = tabs.findIndex(
                      (tab) => tab.key === activeKey,
                    );
                    if (activeIndex !== -1) {
                      tabsRef.current[activeIndex].setAttribute(
                        "tabindex",
                        "0",
                      );
                    }
                  }
                }, 0);
              }}
              onClick={() => {
                handleClick(key, route);
              }}
              onFocus={() => {
                focusedTabIndex.current = i;
              }}
              role="tab"
              tabIndex={active ? 0 : 1}
            >
              {label}
            </Tab>
          );
        })}
      </Header>
      <Inner>
        {tabs.map(
          ({ component, key }) =>
            key === activeKey && (
              <ScrollHandler key={key}>
                <Content key={key} role="tabpanel">
                  {component}
                </Content>
              </ScrollHandler>
            ),
        )}
      </Inner>
    </ScrollHandler>
  );
};

export default Tabs;
