import { FC } from "react";
import { Link, useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors } from "../../../constants";
import mediaQuery from "../../../utils/mediaQuery";
import useGetCategories from "../hooks/useGetCategories";

// '$' signals the transient prop
// https://stackoverflow.com/a/62604142
const Category = styled(Link)<{ $isActive: boolean }>`
  color: ${colors.white};
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    color: ${colors.white};
    opacity: 0.7;
  }

  ${mediaQuery(
    "greaterThanPhone",
    css`
      font-size: 18px;
      font-weight: 700;
    `,
  )}

  ${({ $isActive }) =>
    $isActive &&
    css`
      font-weight: 800 !important;
    `};
`;

const Inner = styled.div`
  -ms-overflow-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 25px;
  overflow: hidden;
  overflow-x: auto; /* Firefox */
  scrollbar-width: none; /* Internet Explorer 10+ */

  &::-webkit-scrollbar {
    /* WebKit */
    display: none;
    background: transparent;
    height: 0;
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
`;

const Container = styled.div`
  background-color: ${colors.blue};
  margin-bottom: 30px;
  padding: 12px var(--content-margin-side);

  ${mediaQuery(
    "greaterThanPhone",
    css`
      margin-bottom: 50px;
      padding-bottom: 28px;
      padding-top: 28px;
    `,
  )}
`;

const ArticlesCategories: FC = () => {
  const { data } = useGetCategories();

  const { category } = useParams<{
    category: string | undefined;
  }>();

  return (
    <Container>
      <Inner>
        {data &&
          data.map(({ name, slug }) => (
            <Category
              key={slug}
              $isActive={category !== undefined && category === slug}
              to={`/articles/by-category/${slug}`}
            >
              {name}
            </Category>
          ))}
      </Inner>
    </Container>
  );
};

export default ArticlesCategories;
