import { isLeft } from "fp-ts/Either";
import * as t from "io-ts";
import { useQuery } from "react-query";
import { UseQueryResult } from "react-query/types/react/types";
import { HtmlBody } from "../../../io-ts/types";
import { wrongResponseFormat } from "../../../locales/errors";
import { useAxios } from "../../../network";

export const responseC = t.type({
  data: t.array(
    t.type({
      attributes: t.type({
        body: HtmlBody,
        name: t.string,
        policy_type: t.string,
      }),
      id: t.string,
      type: t.literal("policy"),
    }),
  ),
});

export type Policy = {
  body: string;
  id: string;
  name: string;
  slug: string;
};

function responseToEntities(
  response: t.TypeOf<typeof responseC>,
): Policy[] {
  const { data } = response;
  if (data.length === 0) return [];

  return data.map((item) => ({
    body: item.attributes.body,
    id: item.id,
    name: item.attributes.name,
    slug: item.attributes.policy_type.replaceAll("_", "-"),
  }));
}

type UseGetCompanyPoliciesResult = Policy[];

export const useGetCompanyPolicies =
  (): UseQueryResult<UseGetCompanyPoliciesResult> => {
    const axios = useAxios();
    return useQuery(
      "getCompanyPolicies",
      async () => {
        const response = await axios.get(`/v2/company/policies`);

        const decodeResult = responseC.decode(response.data);

        if (isLeft(decodeResult)) {
          // eslint-disable-next-line no-console
          console.error(decodeResult);
          throw wrongResponseFormat;
        }
        return responseToEntities(decodeResult.right);
      },
      {
        cacheTime: 300000,
        staleTime: 60000,
      },
    );
  };
