import { AxiosResponse } from "axios";
import { isLeft } from "fp-ts/Either";
import * as t from "io-ts";
import reporter from "io-ts-reporters";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ISODate } from "../../../io-ts/types";
import {
  unknownError,
  wrongApiResponse,
} from "../../../locales/errors";
import { useAxios } from "../../../network";
import { ENDPOINTS } from "../../../network/endpoints";
import {
  RegistrationCompany,
  RegistrationTokenHolderResponseC,
} from "../../models/TokenHolder";

const responseC = t.type({});
export interface SignUpPayload {
  employeeId?: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  zip: string;
  sex: "female" | "male";
  gender: "female" | "male" | "no_answer" | "other";
  dob: Date;
  password: string;
  partnerEmail: string | null | undefined;
  preferredName: string;
  emailSignUpToken?: string;
  partnerInvitationToken?: string;
  recaptchaToken: string;
  companyId: string;
}

export function useSignUp() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: SignUpPayload) => {
      const {
        employeeId,
        firstName,
        lastName,
        mobilePhone,
        zip,
        sex,
        gender,
        dob,
        password,
        partnerEmail,
        preferredName,
        emailSignUpToken,
        partnerInvitationToken,
        recaptchaToken,
        companyId,
      } = payload;
      const { data } = await axios.post<
        unknown,
        AxiosResponse<unknown>,
        {
          company_id?: string;
          employee_id?: string;
          fname: string;
          lname: string;
          preferred_name: string;
          phone: string;
          sex: string;
          gender: string;
          dob: string;
          zip: string;
          password: string;
          password_confirmation: string;
          partner_email?: string;
          email_signup_token?: string;
          partner_invitation_token?: string;
          "g-recaptcha-response": string;
        }
      >(`${ENDPOINTS.registration}`, {
        company_id: employeeId ? undefined : companyId,
        dob: ISODate.encode(dob),
        email_signup_token: emailSignUpToken,
        employee_id: employeeId || undefined,
        fname: firstName,
        "g-recaptcha-response": recaptchaToken,
        gender,
        lname: lastName,
        partner_email: partnerEmail || undefined,
        partner_invitation_token: partnerInvitationToken,
        password,
        password_confirmation: password,
        phone: mobilePhone,
        preferred_name: preferredName,
        sex,
        zip,
      });

      const decoded = responseC.decode(data);
      if (isLeft(decoded)) {
        // eslint-disable-next-line no-console
        console.error(reporter.report(decoded));
        throw wrongApiResponse;
      }

      return true;
    },
    {
      onSuccess: () => {
        queryClient
          .refetchQueries(["cookie"])
          // eslint-disable-next-line no-console
          .catch((err) => console.error(err));
      },
    },
  );
}

export interface CheckSignUpTokenResult {
  companies: RegistrationCompany[];
  askEmployeeId: boolean;
}
export function useCheckSignUpToken(token: {
  emailSignUpToken?: string | null;
  partnerInvitationToken?: string | null;
}) {
  const axios = useAxios();

  return useQuery(
    "checkSignUpToken",
    async (): Promise<CheckSignUpTokenResult> => {
      const { data } = await axios.post<unknown>(
        `${ENDPOINTS.checkSignUpToken}`,
        token
          ? {
              email_signup_token: token.emailSignUpToken,
              partner_invitation_token: token.partnerInvitationToken,
            }
          : {},
      );

      const responseDecode =
        RegistrationTokenHolderResponseC.decode(data);
      if (isLeft(responseDecode)) {
        // eslint-disable-next-line no-console
        console.error(
          reporter.report(responseDecode),
          responseDecode.left,
        );
        throw wrongApiResponse;
      } else {
        if ("data" in responseDecode.right) {
          const companiesMap = new Map(
            responseDecode.right.included
              .filter(
                (element): element is RegistrationCompany =>
                  element.type === "company",
              )
              .map((element) => [element.id, element]),
          );

          const companies =
            responseDecode.right.data.relationships.allowed_companies.data.map(
              (item) => {
                const company = companiesMap.get(item.id);
                if (!company)
                  throw new Error(
                    "Company relationship not found in `included`",
                  );
                return company;
              },
            );

          return {
            askEmployeeId:
              responseDecode.right.data.attributes
                ?.require_employee_id || false,
            companies,
          };
        }
        if (responseDecode.right.errors[0]?.title) {
          throw new Error(responseDecode.right.errors[0].title);
        }
        throw unknownError;
      }
    },
    {
      cacheTime: 0,
      enabled:
        !!token.partnerInvitationToken || !!token.emailSignUpToken,
    },
  );
}
