import * as t from "io-ts";
import { ISODate } from "../../io-ts/types";
import { State, StateC } from "./State";

export const ReimbursementServiceC = t.type({
  attributes: t.intersection([
    t.type({
      approved_amount: t.number,
      date: ISODate,
      name: t.string,
      requested_amount: t.number,
      state: StateC,
    }),
    t.partial({
      reason: t.string,
    }),
  ]),
  id: t.string,
  type: t.literal("reimbursement_service"),
});

export type ReimbursementServiceEntity = t.TypeOf<
  typeof ReimbursementServiceC
>;

export type ReimbursementService = {
  approvedAmount: number;
  requestedAmount: number;
  id: string;
  name: string;
  date: Date;
  state: State;
  reason?: string;
};
