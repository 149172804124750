/* eslint-disable react/no-unescaped-entities */
/* eslint max-lines: 0 */

import React from "react";
import Title from "../../../lib/Title";
import LegalPage, { Part } from "../LegalPage";

const ToS: React.FC = () => (
  <LegalPage>
    <Title level="h1">Terms of Service</Title>
    <p>Effective date: February 8, 2024</p>
    <Part>
      <p>
        Welcome to Stork Club. We help employers offer family-related
        benefits, including fertility and parental programs (Services)
        to their employees (Users). We – meaning Stork Club Fertility,
        Inc., doing business as Stork Club (also "Stork Cub," "our" or
        "us") – prepared these Terms of Services and our Privacy
        Policy to help you understand our practices to provide
        Services via Web and Mobile apps (the "Stork Club Products").
      </p>
      <p>
        By accessing or using the Services, you agree to these Terms
        of Service, Privacy Policy, and our{" "}
        <a href="/legal/hipaa">HIPAA Notice</a>. Any conflict between
        this Privacy Policy and the HIPAA Notice with respect to any
        PHI (as defined in the HIPAA Notice) shall be governed by the
        HIPAA Notice.
      </p>
      <p>
        These Terms do not apply to third party sites or applications
        to which we provide access through our Services. These sites
        or applications set their terms and privacy policies
        independent of us. We encourage you to review the terms and
        privacy policies of these sites and applications before you
        access, enroll in, or use them.
      </p>
      <p>
        Although we help navigate employer family-related benefits for
        employees and their dependents, individuals under 13 years of
        age are not authorized to use the Stork Club Products, with or
        without a registration. Stork Club does not agree to offer
        Stork Club Services and Products to individuals under the age
        of 13.
      </p>
    </Part>

    <Part>
      <Title level="h2">What These Terms of Service Cover</Title>
      <p>
        These Terms constitute a binding legal agreement between you
        and Stork Club. If you have been authorized to, and are
        helping another person apply for and/or use the Services,
        these Terms constitute a legally binding agreement between
        both the helper and the person being helped and Stork Club.
      </p>
      <p>
        IF YOU ARE NOT WILLING TO BE OR CANNOT BE BOUND BY THESE
        TERMS, THEN YOU MAY NOT ACCESS, BROWSE OR USE THE SERVICES.
        MOREOVER, WE DO NOT AND WILL NOT GRANT YOU ANY RIGHT OR
        LICENSE TO ACCESS, BROWSE OR USE THE SERVICES WITHOUT YOUR
        WILLINGNESS AND ABILITY TO BE BOUND BY ALL OF THE TERMS AND
        CONDITIONS SET FORTH HEREIN.
      </p>
      <p>
        We reserve the right, at our discretion, to modify, replace,
        update or change any of these Terms at any time and for any
        reason. Any changes, revisions or modifications to these Terms
        shall become effective upon posting by Stork Club on the Stork
        Club Site, the Mobile App (Stork Club Products), via the
        Service or by sending you an email or other notification. It
        is your responsibility to check these Terms periodically for
        changes. By continuing to use the Services, you agree to
        accept all such changes, revisions or modifications to the
        Terms.
      </p>
      <p>
        If you accept these Terms but later decide that you want to
        terminate your account, please contact us at{" "}
        <a href="mailto:care@joinstorkclub.com" target="blank">
          care@joinstorkclub.com
        </a>
        , and we will verify and complete your request.
      </p>
    </Part>

    <Part>
      <Title level="h2">Who May Use Our Services</Title>
      <p>
        Your access to and use of the Services is expressly
        conditioned on your acceptance of and compliance with these
        Terms. These Terms apply to all users of the Services. If you
        do not agree with these Terms, you are not authorized to
        access or use any portion of the Services in any manner or for
        any purpose.
      </p>
      <p>
        Your use of any Services with which we may be affiliated may
        be subject to additional terms, including terms and conditions
        provided by a third party. If you do not agree with any of
        these additional terms and conditions, you are not authorized
        to use the Services subject to such additional terms.
      </p>
      <p>
        In order to receive the Services, you must register as more
        fully described below in the section entitled “Registration.”
        You represent and warrant that you have the right, authority
        and capacity to enter into these Terms and are at least 18
        years old. In addition, you represent and warrant that (1) all
        registration information that you submit or that is submitted
        on your behalf is complete, accurate and truthful, and (2) in
        the event that you allow a third party to assist you in
        providing any information, including in completing the
        application and submitting any registration information to us,
        you have reviewed and confirmed that all such registration
        information is complete, accurate and truthful prior to its
        submission to us.
      </p>
      <p>
        Stork Club may, in its sole discretion, refuse to offer the
        Services to any person or entity and change our enrollment
        criteria for the Stork Club Services at any time for any
        reason. This provision is void where prohibited by law, and
        the right to access the Services is revoked in such
        jurisdictions.
      </p>
      <p>
        Stork Club reserves the right, at its discretion, to change,
        modify, replace suspend, or discontinue the Services or any
        component thereof (including without limitation, the
        availability of any feature, specification, database, or
        content) at any time and for any reason. Stork Club will not
        be liable to you for any such change, modification,
        replacement, suspension or discontinuation of your rights to
        access and use the Services. Stork Club may also impose limits
        on certain features and services or restrict your access to
        parts or all of the Services without notice or liability.
      </p>
    </Part>

    <Part>
      <Title level="h2">What Services We Provide</Title>
      <p>As part of the Services, we provide</p>
      <ol>
        <li>
          Fertility and parental informational programs to individuals
          who’re looking for help with fertility preservation,
          assisted pregnancy (e.g. IVF, surrogacy), pregnancy, newborn
          care and their return to work after childbirth;
        </li>
        <li>
          Employer-sponsored fertility coverage to help employees and
          their partners undergo fertility treatments (e.g. egg
          freezing, IVF) at Stork Club in-network clinics.
        </li>
      </ol>
      <p>
        Our Services may include, without limitation, the following:
      </p>
      <ul>
        <li>
          The ability to transmit, display and access health
          information shared by you and others through our Services,
          augmented with information collected about you through
          authorized third parties (e.g., televisits with our Experts,
          communication with Care Partner, information about your
          benefits from your employer).
        </li>
        <li>
          The ability to build, maintain and enable your participation
          in an online community related to your participation in the
          Services and your health goals.
        </li>
        <li>
          Access to other information about Stork Club and our
          products and/or services through the various websites we own
          and operate, including, without limitation,
          my.joinstorkclub.com website and domain name, and any other
          features, content or applications offered from time to time
          by Stork Club in connection therewith.
        </li>
        <li>
          The ability to interact with relevant Stork Club personnel
          in a timely and effective manner from the time of your
          account activation in Stork Club Products and throughout the
          course of your access to and use of the Stork Club Services,
          including but not limited to Stork Club vetted experts, Care
          Navigators, and support team.
        </li>
        <li>
          The ability to interact with your other healthcare and
          benefits providers, integrated in your custom program.
        </li>
        <li>
          The ability to find information about your fertility
          program, if included in your custom package of Stork Club
          Services by your employer. If so, you can undergo certain
          fertility treatments at Stork Club in-network clinics.
        </li>
        <li>
          You may use the Site and/or the Mobile App to access and use
          the Stork Club Services. In order to access and use Stork
          Club Services, you must download it from a digital
          distribution platform, such as the Apple App Store or Google
          Play (the “Mobile App Provider”). You acknowledge and agree
          that: (i) these Terms are entered into between you and us,
          and not with the Mobile App Provider, and that we are solely
          responsible for the Mobile App (not the Mobile App
          Provider); (ii) the Mobile App Provider has no obligation to
          furnish any maintenance and support services with respect to
          the Mobile App; (iii) the Mobile App Provider is not
          responsible for addressing any claims you have or any claims
          of any third party relating to the Mobile App or your
          possession and use of the Mobile App, including, but not
          limited to: (a) product liability claims, (b) any claim that
          the Mobile App fails to conform to any applicable legal or
          regulatory requirement, and (c) claims arising under
          consumer protection or similar legislation; (iv) in the
          event of any third party claim that the Mobile App or your
          possession and use of that Mobile App infringes that third
          party’s intellectual property rights, we will be responsible
          for the investigation, defense, settlement and discharge of
          any such intellectual property infringement claim to the
          extent required by these Terms; and (v) you must also comply
          with all applicable third party terms of service when using
          the Services.
        </li>
      </ul>
      <Title level="h3">No Medical Advice</Title>
      <p>
        OUR SERVICES DO NOT INCLUDE THE PROVISION OF ANY MEDICAL
        ADVICE OR TREATMENT BY STORK CLUB. THE SERVICES ARE NOT
        INTENDED TO BE AND DO NOT CONSTITUTE A SUBSTITUTE FOR
        PROFESSIONAL MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT AND ARE
        OFFERED FOR INFORMATIONAL PURPOSES ONLY. RATHER, THE SERVICES
        ENABLE REGISTERED USERS TO ACCESS DIGITAL CONTENT AND HEALTH
        COACHING TOOLS AND RESOURCES, INCLUDING AN EXPERT WHO MAY
        PROVIDE YOU WITH LIMITED WEB-, TELEPHONE-, SMS-, OR
        EMAIL-BASED SUPPORT. STORK CLUB DOES NOT EMPLOY OR CONTRACT
        WITH PHYSICIANS TO PROVIDE MEDICAL CARE TO YOU — OUR EXPERTS
        ARE NOT LICENSED PHYSICIANS OR LICENSED HEALTH CARE PROVIDERS.
        WHILE THE SERVICES PROVIDE INFORMATION, STORK CLUB CANNOT AND
        DOES NOT DIAGNOSE YOUR HEALTH CONDITIONS OR CONDITIONS OF YOUR
        CHILD(REN) OTHERWISE PROVIDE YOU WITH ANY MEDICAL ADVICE OR
        TREATMENT. ANY CONTENT PROVIDED OR ACCESSED THROUGH THE
        SERVICES, INCLUDING BUT NOT LIMITED TO INFORMATION PROVIDED BY
        OUR PERSONNEL, IS FOR INFORMATIONAL PURPOSES ONLY. THIS
        CONTENT SHOULD NOT BE USED DURING A MEDICAL EMERGENCY OR FOR
        THE DIAGNOSIS OR TREATMENT OF ANY MEDICAL CONDITION.
      </p>
      <p>
        ALWAYS SEEK THE ADVICE OF YOUR PHYSICIAN OR OTHER QUALIFIED
        HEALTH PROVIDER WITH ANY QUESTIONS REGARDING YOUR MEDICAL
        CONDITION OR THE USE (OR FREQUENCY) OF ANY MEDICATION OR
        MEDICAL DEVICE, CHANGING YOUR DIET OR COMMENCING OR
        DISCONTINUING ANY COURSE OF TREATMENT. DO NOT USE THE SERVICES
        AS A SUBSTITUTE FOR CONSULTING WITH YOUR PHYSICIAN OR OTHER
        HEALTHCARE PROVIDER, AND DO NOT DISREGARD PROFESSIONAL MEDICAL
        ADVICE OR DELAY IN SEEKING IT BECAUSE OF ANY INFORMATION
        RECEIVED IN CONNECTION WITH OUR SERVICES. IF YOU THINK YOU
        HAVE A MEDICAL EMERGENCY, CALL 911 IMMEDIATELY.
      </p>
      <p>
        TO THE EXTENT YOU RECEIVE MEDICAL CARE IN CONJUNCTION WITH OR
        CONSEQUENT TO THE SERVICES, YOUR TREATING MEDICAL PROFESSIONAL
        IS RESPONSIBLE FOR OBTAINING YOUR INFORMED CONSENT TO ANY
        MEDICAL DIAGNOSIS OR TREATMENT, INCLUDING WITHOUT LIMITATION,
        YOUR CONSENT TO USE TELEMEDICINE IN THE COURSE OF YOUR
        TREATMENT TO THE EXTENT SUCH CONSENT IS REQUIRED BY APPLICABLE
        LAW. THIS DIAGNOSIS OR TREATMENT IS SEPARATE FROM AND
        UNRELATED TO THE SERVICES PROVIDED BY STORK CLUB. STORK CLUB
        DOES NOT REPRESENT OR WARRANT THAT THE SERVICES OR ANY
        PARTICULAR DRUG OR TREATMENT IS SAFE, APPROPRIATE, OR
        EFFECTIVE FOR YOU. STORK CLUB IS NOT RESPONSIBLE OR LIABLE FOR
        ANY ADVICE, COURSE OF TREATMENT, DIAGNOSIS OR ANY OTHER
        INFORMATION, SERVICES OR PRODUCTS THAT YOU MAY OBTAIN IN
        CONNECTION WITH OR AS A RESULT OF USING THE SERVICES.
      </p>
      <Title level="h3">No Emergency Services</Title>
      <p>
        THE SERVICES ARE NOT INTENDED TO SUPPORT OR CARRY EMERGENCY OR
        TIME-CRITICAL CALLS OR COMMUNICATIONS TO ANY TYPE OF HOSPITAL,
        LAW ENFORCEMENT AGENCY, MEDICAL CARE UNIT, OR ANY OTHER KIND
        OF EMERGENCY OR TIME-CRITICAL SERVICE.
      </p>
      <p>
        THE SITE, THE MOBILE APP AND THE SERVICES ARE FOR
        NON-EMERGENCY PURPOSES ONLY. DO NOT ATTEMPT TO ACCESS
        EMERGENCY CARE THROUGH THE SITE, THE MOBILE APP OR THE
        SERVICES. IF AT ANY TIME YOU ARE CONCERNED ABOUT YOUR CARE OR
        TREATMENT, OR IF YOU THINK YOU HAVE A MEDICAL EMERGENCY, CALL
        911 OR GO TO THE NEAREST OPEN EMERGENCY ROOM IMMEDIATELY.
      </p>
      <p>
        If Stork Club becomes aware of or contemplates an emergency,
        Stork Club may, at its sole discretion, secure from any
        licensed hospital, physician, and/or medical personnel
        (“Emergency Responders”) any emergency treatment deemed
        necessary by Stork Club for your immediate care. You
        understand and agree that, in the event that Stork Club does
        take any action with respect to securing Emergency Responders
        on your behalf, you, not Stork Club, will be solely
        responsible for payment of any and all medical services
        rendered in connection with any such treatment.
      </p>
    </Part>

    <Part>
      <Title level="h2">Access and Use of the Services</Title>
      <Title level="h3">Registration</Title>
      <p>
        As a condition to using the Services, you will be required to
        register with Stork Club. You will be required to enter User’s
        name, email address, password, and certain other information
        (e.g., date of birth, contact information). You shall provide
        Stork Club with true, accurate, complete, and current
        registration information and maintain and update promptly any
        changes to such information. Failure to do so shall constitute
        a breach of these Terms, which may result in immediate
        termination of your account.
      </p>
      <Title level="h3">Registration Security</Title>
      <p>
        You shall not (a) use as a name of another person with the
        intent to impersonate that person; (b) use as a name subject
        to any rights of a person other than you without appropriate
        authorization. Stork Club reserves the right to refuse
        registration of or cancel your Stork Club account in its sole
        discretion. You are responsible for safeguarding your Stork
        Club login credentials. You are responsible for activity on
        your account, whether or not you authorized that activity. You
        should immediately notify us of any unauthorized use of your
        account.
      </p>
      <Title level="h3">Electronic Communications</Title>
      <p>
        As a condition of using Stork Club Products and participating
        in the Services, you will be required to provide Stork Club
        with your email address and phone number as part of
        registration. By activating your Stork Club account, you give
        Stork Club permission to communicate your information about
        your healthcare and health coverage via registered mail, email
        and other electronic communications, SMS messages, voice
        calls, push notifications or otherwise. The information we may
        share include, but not limited to, information about your
        healthcare, plan or claims, benefit statements, changes to
        your plan, suggestions to improve your healthcare experience,
        information about your coverage, and any related information.
        You may change your communication preferences at any time on{" "}
        <a href="https://my.joinstorkclub.com/profile">
          https://my.joinstorkclub.com/profile
        </a>{" "}
        or by emailing us at{" "}
        <a href="mailto:care@joinstorkclub.com" target="blank">
          care@joinstorkclub.com
        </a>
        .
      </p>
      <Title level="h3">Covered Third-Party Providers</Title>
      <p>
        Our program pages include lists of providers in your network
        and are designed to help you find the healthcare provider
        faster. The information in the tool about each provider is
        provided by network partners (e.g. your health insurance
        provider) and is updated regularly; however, before receiving
        services, you should independently confirm whether providers
        are in your network.
      </p>
      <p>
        THIRD-PARTY PROVIDER LISTS ARE PROVIDED AS IS WITHOUT ANY
        REPRESENTATIONS OR WARRANTIES OF ANY KIND. WE DISCLAIM ANY AND
        ALL EXPRESS OR IMPLIED WARRANTIES OR REPRESENTATIONS ABOUT THE
        ACCURACY OR COMPLETENESS OF THE PROVIDER INFORMATION ON THE
        THIRD-PARTY PROVIDER LISTS TOOL, INCLUDING WITHOUT LIMITATION,
        THE WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
        PURPOSE.
      </p>
      <Title level="h3">Modifications to Service</Title>
      <p>
        We reserve the right to modify, suspend, or discontinue,
        temporarily or permanently, the Stork Club Services (or any
        part thereof) with or without notice. You agree that Stork
        Club, its directors, officers, employees, affiliates, and
        assigns will not be liable to you or to any third party for
        any modification, suspension, or discontinuance of the
        Services or any part thereof, whether temporary or permanent,
        or for any loss of information or access to information
        resulting therefrom.
      </p>
      <p>
        With the exception of the Services, you are responsible for
        obtaining, installing, maintaining and operating all software,
        hardware or other equipment (collectively, “Stork Club
        Products”) necessary for you to access and use the Services,
        as well as Internet services via the Internet service provider
        of your choice and any wireless services your require (the
        “Connections”). This responsibility includes, without
        limitation, your utilizing current versions of web-browsers
        and appropriate encryption, antivirus, anti-spyware, and
        Internet security software. By activating your account you
        consent to these Terms, you demonstrate that you can access
        information that we provide to you by using the Stork Club
        Products.
      </p>
    </Part>

    <Part>
      <Title level="h2">Conditions of Use</Title>
      <Title level="h3">Conduct and Rules</Title>
      <p>
        As a condition to using the Services, you agree not to use the
        Services for any purpose that is prohibited by these Terms.
        You shall, at all times, abide by all applicable local, state,
        and federal laws, rules and regulations in accessing and using
        the Services. The Services (including, without limitation, any
        Content or User Submissions (both as defined below)) are
        provided only for your own personal, non-commercial, limited
        use in accordance with these Terms. You are responsible for
        all of your activity in connection with the Services. For
        purposes of these Terms, the term “Content” includes, without
        limitation, any advertisements, advice, lessons, instructions,
        suggestions, videos, audio clips, written forum comments,
        information, posts, comments, materials, data (obtained about
        you through the Services or from third parties), content,
        text, photographs, images, software, scripts, art, graphics,
        logos, button icons and interactive features generated,
        provided, or otherwise made accessible by or to Stork Club or
        its partners on or through the Services.
      </p>
      <p>
        By way of example, and not limitation, you shall not, and
        shall not permit any third party to, directly or indirectly
        (a) take any action or (b) upload, download, post, submit or
        otherwise distribute or facilitate distribution of any content
        on or through the Services, that:
      </p>
      <ul>
        <li>
          infringes any patent, trademark, trade secret, copyright,
          right of publicity or other rights of any other person or
          entity;
        </li>
        <li>
          is unlawful, threatening, abusive, harassing, defamatory,
          libelous, deceptive, fraudulent, invasive of another’s
          privacy, tortious, obscene, offensive, or profane;
        </li>
        <li>
          constitutes unauthorized or unsolicited advertising, junk or
          bulk email (“spamming”);
        </li>
        <li>
          involves commercial activities and/or sales without Stork
          Club’s prior written consent, such as contests, sweepstakes,
          barter, advertising, or pyramid schemes;
        </li>
        <li>
          contains software viruses or any other computer codes,
          files, or programs that are designed or intended to disrupt,
          damage, limit or interfere with the proper function of any
          software, hardware, or telecommunications equipment or to
          damage or obtain unauthorized access to any system, data,
          password or other information of Stork Club or any third
          party;
        </li>
        <li>
          or impersonates any person or entity, including any employee
          or representative of Stork Club.
        </li>
      </ul>
      <p>
        Additionally, you shall not: (a) take any action that imposes
        or may impose an unreasonable or disproportionately large load
        on Stork Club (or its third party providers’) infrastructure;
        (b) interfere or attempt to interfere with the proper working
        of the Services or any activities conducted on or as a part of
        the Services; (c) bypass any measures Stork Club may use to
        prevent or restrict access to the Services (or other accounts,
        computer systems or networks connected to the Services); (d)
        run Maillist, Listserv, any form of auto-responder or “spam”
        on the Services; or (e) use manual or automated software,
        devices, or other processes to “crawl” or “spider” any page of
        the Site, in each case as determined by Stork Club in its sole
        discretion.
      </p>
      <p>
        You shall not (a) decipher, decompile, disassemble, reverse
        engineer or otherwise attempt to derive any source code or
        underlying ideas or algorithms of any part of the Service,
        except to the limited extent applicable laws specifically
        prohibit such restriction; (b) modify, translate, or otherwise
        create derivative works of any part of the Services; or (c)
        copy, rent, lease, distribute, or otherwise transfer any or
        all of the rights that you receive hereunder. Stork Club
        reserves the right to remove any User Submissions or Content
        from the Services at any time for any reason (including, but
        not limited to, upon receipt of claims or allegations from
        third parties or authorities relating to such Content or if
        Stork Club is concerned that you may have violated these
        Terms), or for no reason at all. Stork Club also reserves the
        right to access, read, preserve, and disclose any information
        as Stork Club reasonably believes is necessary to (i) satisfy
        any applicable law, regulation, legal process or governmental
        request, (ii) enforce these Terms, including investigation of
        potential violations hereof, (iii) detect, prevent, or
        otherwise address fraud, security or technical issues, (iv)
        respond to user support requests, or (v) protect the rights,
        property or safety of Stork Club, its users and the public.
      </p>
    </Part>

    <Part>
      <Title level="h2">Third-Party Websites and Materials</Title>
      <p>
        The Services may allow you to display, use, include or make
        available content, data, information, applications or
        materials from third parties (“Third Party Materials”) or
        provide links to certain third party websites (“Third Party
        Websites”). For example, the Services integrate some
        third-party providers such as Milk Stork — milk shipping
        services or Trusted Childcare. In such case, in order to use
        the Services, you must agree to the Milk Stork and Trusted
        Childcare Terms of Use and their Privacy Policies.
      </p>
      <p>
        When you use any Third Party Materials or access any Third
        Party Websites, you do so at your own risk, and you understand
        and agree that you are solely responsible for reading and
        understanding any terms of use and/or privacy policies that
        apply to such Third Party Materials or Third Party Websites.
        These Third Party Materials and Third Party Websites are not
        under Stork Club’s control, and Stork Club is not responsible
        or liable for the availability, performance, reliability,
        content, functions, accuracy, legality, appropriateness,
        services, materials or any other aspect of such Third Party
        Materials and Third Party Websites. Stork Club is providing
        these Third Party Materials and links to Third Party Websites
        for your convenience. The inclusion of any such Third Party
        Materials or links in the Services does not imply endorsement
        or recommendation by Stork Club or any association with the
        third party website’s operators. Stork Club also does not
        accept any responsibility for technical failures or for
        unauthorized access of user transmissions by any third
        parties.
      </p>
      <p>
        The providers of Third Party Materials and Third Party
        Websites (each, a “Third Party Service Provider”) may collect
        and use certain information about you, as specified in their
        privacy policies and terms of use. Prior to using or providing
        any information to any Third Party Service Provider, you
        should review their privacy policy and terms of use. If you do
        not understand or do not agree to the terms of a Third Party
        Service Provider’s privacy policy and/or terms of use, you
        should not use the services. Stork Club, its directors,
        officers, employees, affiliates, and assigns are not liable
        for any loss or claim that you may have against any such third
        party.
      </p>
    </Part>

    <Part>
      <Title level="h2">Content and Intellectual Property</Title>
      <p>
        Stork Club Products and Services Services (including but not
        limited to the Site, the Mobile App and the Stork Club
        programs) and the Content are protected under the United
        States and international intellectual property, copyright,
        trademark, patent, trade secret and other laws. The Products,
        Services and the Content are the sole property of Stork Club.
        You shall abide by all copyright notices, information, and
        restrictions contained in any Content accessed through the
        Services. You shall not sell, license, rent, modify,
        distribute, copy, reproduce, transmit, publicly display,
        publicly perform, publish, adapt, edit, create derivative
        works from, or otherwise exploit the Services or any Content
        or third party submissions or other proprietary rights not
        owned by you (a) without the consent of the respective owners
        or other valid right, and (b) in any way that violates any
        third party right.
      </p>
      <p>
        Subject to your compliance with these Terms (and any other
        terms governing your use of the Services), you are granted a
        personal, non-exclusive, non-transferable, non-sublicensable,
        revocable, limited right to access our Site, download and use
        the Mobile App, and use the Services. All rights in and to the
        Services (including, but not limited to, the Site, the Mobile
        App, the Stork Club Program and the Content) not expressly
        granted in these Terms are hereby reserved and retained by
        Stork Club.
      </p>
    </Part>

    <Part>
      <Title level="h2">Copyright Infringement</Title>
      <p>
        At Stork Club, we respect the intellectual property rights of
        others. In accordance with the Digital Millennium Copyright
        Act of 1998, Stork Club will respond expeditiously to claims
        of copyright infringement. If you believe that any materials
        you or others access via the Services infringe your copyright
        or other intellectual property rights (e.g., trademark
        infringement or right of publicity), please contact our
        Copyright Agent at the address below and provide the following
        information:
      </p>
      <ol>
        <li>
          Your full legal name and electronic or physical signature
        </li>
        <li>
          A description of the copyrighted work or other interest that
          you believe has been infringed;
        </li>
        <li>
          Enough information to properly identify and locate that
          content (including, at a minimum, the relevant URL)
        </li>
        <li>
          Contact information, including your address, telephone
          number, and email address
        </li>
        <li>
          The following statements in the body of notice:
          <ul>
            <li>
              “I hereby state that I have not authorized the
              challenged use, and I have a good-faith belief that the
              challenged use is not authorized by law.”
            </li>
            <li>
              “I hereby state under penalty of perjury that all of the
              information in the notification is accurate and that I
              am the owner of the IP, or authorized to act on behalf
              of the owner of the IP.”
            </li>
          </ul>
        </li>
      </ol>
      <p>
        Upon receipt of notice as described above, we will seek to
        confirm the existence of the IP and take whatever action, in
        its sole discretion, we deem appropriate.
      </p>
      <p>
        Our designated Copyright Agent for notices of copyright
        infringement and counter-notices is:
        <br />
        Stork Club Fertility, Inc.. ATTN: Copyright Agent{" "}
        <a href="mailto:legal@joinstorkclub.com" target="blank">
          legal@joinstorkclub.com
        </a>
        .
      </p>
    </Part>

    <Part>
      <Title level="h2">Publicity</Title>
      <p>
        Unless otherwise specified, Stork Club may use Customer’s
        name, logo and marks (including marks on Customer Properties)
        to identify Customer as a Stork Club customer on Stork Club's
        website and other marketing materials.
      </p>
    </Part>

    <Part>
      <Title level="h2">Termination</Title>
      <p>
        Stork Club may terminate your access to all or any part of the
        Services at any time, with or without cause, with or without
        notice, effective immediately, which may result in the
        forfeiture and destruction of all information associated with
        your account. Upon termination of your right to use our
        Services, including the Site and the Mobile App, or our
        termination of the Services, all licenses and other rights
        granted to you by these Terms will immediately terminate.
      </p>
      <p>
        If you wish to terminate your account, you may do so by
        contacting our customer support team at{" "}
        <a href="mailto:care@joinstorkclub.com" target="blank">
          care@joinstorkclub.com
        </a>
        . Any fees paid hereunder are non-refundable. All provisions
        of these Terms which by their nature should survive
        termination shall survive termination, including, without
        limitation, ownership provisions, warranty disclaimers,
        indemnity and limitations of liability.
      </p>
    </Part>

    <Part>
      <Title level="h2">Warranty Disclaimer</Title>
      <p>
        YOUR USE OF THE SERVICES IS SOLELY AT YOUR OWN RISK. THE
        SERVICES (INCLUDING, WITHOUT LIMITATION, ANY CONTENT) ARE
        PROVIDED “AS IS” AND “AS AVAILABLE” AND ARE WITHOUT WARRANTY
        OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED
        TO, THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT,
        MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY
        WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF
        TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED.
      </p>
      <p>
        STORK CLUB, AND ITS DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS,
        PARTNERS OR ANYONE ELSE INVOLVED IN CREATING, PRODUCING OR
        DELIVERING THE SERVICES OR THE INFORMATION, SERVICES AND
        MATERIALS CONTAINED THEREIN (I) DO NOT ASSUME ANY LIABILITY OR
        RESPONSIBILITY FOR THE ACCURACY, COMPLETENESS OR USEFULNESS OF
        ANY INFORMATION, SERVICES AND MATERIALS PROVIDED ON OR THROUGH
        THE SERVICES AND (II) DO NOT WARRANT THAT: (A) THE SERVICES
        WILL BE SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR
        LOCATION; (B) ANY DEFECTS OR ERRORS WILL BE CORRECTED; (C) ANY
        CONTENT OR SOFTWARE AVAILABLE AT OR THROUGH THE SERVICES IS
        FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR (D) THE
        RESULTS OF USING THE SERVICES WILL MEET YOUR REQUIREMENTS OR
        THE STATED PURPOSE.
      </p>
      <p>
        WITHOUT LIMITING ANYTHING IN THIS AGREEMENT, ANY GENERAL
        ADVICE OR INFORMATION THAT MAY BE POSTED ON STORK CLUB
        PRODUCTS OR THROUGH THE SERVICES IS FOR INFORMATIONAL PURPOSES
        ONLY AND IS NOT INTENDED TO REPLACE OR SUBSTITUTE ANY MEDICAL
        OR OTHER PROFESSIONAL ADVICE. NO ADVICE OR INFORMATION,
        WHETHER ORAL OR WRITTEN, OBTAINED BY USER FROM THE SERVICE OR
        STORK CLUB OR ANY MATERIALS OR CONTENT AVAILABLE THROUGH THE
        SERVICE WILL CREATE ANY WARRANTY REGARDING ANY OF THE STORK
        CLUB OR THE SERVICE THAT IS NOT EXPRESSLY STATED IN THESE
        TERMS. WE ARE NOT RESPONSIBLE FOR ANY DAMAGE THAT MAY RESULT
        FROM THE SERVICE AND YOUR DEALING WITH ANY OTHER THIRD-PARTY
        SERVICE. IF YOU HAVE SPECIFIC CONCERNS OR A SITUATION ARISES
        IN WHICH YOU REQUIRE MEDICAL ADVICE, YOU SHOULD CONSULT WITH
        AN APPROPRIATELY TRAINED AND QUALIFIED MEDICAL SERVICES
        PROVIDER. DO NOT USE THE SERVICES FOR EMERGENCY MEDICAL NEEDS.
        IF YOU EXPERIENCE A MEDICAL EMERGENCY, IMMEDIATELY CALL 911.
      </p>
      <p>
        NOTHING STATED, POSTED, OR IMPLIED ON THE SERVICES IS INTENDED
        TO BE AND MUST NOT BE TAKEN TO BE, THE PRACTICE OF MEDICINE,
        NURSING, SOCIAL WORK, OR OTHER PROFESSIONAL HEALTHCARE ADVISE
        OR THE PROVISION OF MEDICAL CARE.
      </p>
      <p>
        YOU UNDERSTAND AND AGREE THAT YOU USE ANY PORTION OF THE
        SERVICE AT YOUR OWN DISCRETION AND RISK, AND THAT WE ARE NOT
        RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR
        COMPUTER SYSTEM OR MOBILE DEVICE USED IN CONNECTION WITH THE
        SERVICE) OR ANY LOSS OF DATA, INCLUDING CONTENT.
      </p>
      <p>
        PLEASE NOTE THAT SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION
        OF IMPLIED WARRANTIES, SO THE ABOVE LIMITATIONS MAY NOT APPLY
        TO YOU.
      </p>
    </Part>

    <Part>
      <Title level="h2">Indemnification and Release</Title>
      <p>
        You will hold harmless and indemnify Stork Club and its
        affiliates, officers, directors, employees, contractors,
        agents, licensors, and suppliers from and against any claim,
        suit or action arising from or related to the use of Stork
        Club Services and Stork Club Products or violation of these
        Terms, including any liability or expense arising from claims,
        losses, damages, suits, judgments, litigation costs and
        attorneys’ fees.
      </p>
      <p>
        IF YOU ARE A CALIFORNIA RESIDENT, YOU AGREE TO WAIVE
        CALIFORNIA CIVIL CODE SECTION 1542, WHICH SAYS: “A GENERAL
        RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT
        KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING
        THE RELEASE, WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY
        AFFECTED HIS SETTLEMENT WITH THE DEBTOR.
      </p>
      <p>
        If you are a resident of another jurisdiction, you agree to
        waive any similar statute or doctrine. We reserve the right,
        at our own expense, to assume the exclusive defense and
        control of any matter otherwise subject to indemnification by
        you (without limiting your indemnification obligations with
        respect to that matter), and in that case, you agree to
        cooperate with our defense of those claims.
      </p>
    </Part>

    <Part>
      <Title level="h2">Limitation of Liability</Title>
      <p>
        (A) TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL
        STORK CLUB, ITS AFFILIATES, OFFICERS, EMPLOYEES, AGENTS,
        SUPPLIERS OR LICENSORS BE LIABLE FOR ANY INDIRECT, SPECIAL,
        INCIDENTAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES
        (INCLUDING LOSS OF USE, PROFIT, DATA, GOODWILL, SERVICE
        INTERRUPTIONS, COMPUTER DAMAGE OR SYSTEM FAILURE), REGARDLESS
        OF LEGAL THEORY, WHETHER OR NOT STORK CLUB HAS BEEN WARNED OF
        THE POSSIBILITY OF SUCH DAMAGES, AND EVEN IF A REMEDY FAILS OF
        ITS ESSENTIAL PURPOSE; (B) AGGREGATE LIABILITY FOR ALL CLAIMS
        RELATING TO AIRTABLE MORE THAN THE GREATER OF $50.00.
      </p>
      <p>
        Some states do not allow the types of limitations in this
        paragraph. If you are in one of these jurisdictions, these
        limitations may not apply to you.
      </p>
      <p>
        NOTHING HEREIN SHALL LIMIT THE POTENTIAL PROFESSIONAL
        LIABILITY OF A PHYSICIAN OR OTHER LICENSED HEALTHCARE PROVIDER
        ARISING FROM OR RELATED TO MEDICAL SERVICES THAT YOU MAY
        RECEIVE IN CONNECTION WITH OR CONSEQUENT TO YOUR USE OF THE
        STORK CLUB SERVICES. WE ARE NOT LIABLE TO ANY PERSON OR USER
        FOR ANY HARM CAUSED BY THE NEGLIGENCE OR MISCONDUCT OF ANY
        SUCH LICENSED MEDICAL PROFESSIONAL OR ANY OTHER PARTY.
      </p>
      <p>
        Any claims against Stork Club arising in connection with your
        use of the Stork Club Services must be brought against Stork
        Club within one (1) year of the date of the event giving rise
        to such action.
      </p>
    </Part>

    <Part>
      <Title level="h2">Governing Law</Title>
      <p>
        These Terms shall be governed by and construed in accordance
        with the laws of the State of California, excluding its
        conflicts of law rules, and the United States of America.
      </p>
      <p>
        These Terms, the Privacy Policy and the HIPAA Notice and any
        other agreement referenced herein, constitute the entire
        agreement between you and Stork Club with respect to the
        Services, and supersede all prior or contemporaneous
        communications and proposals (whether oral, written or
        electronic) between you and Stork Club with respect to the
        Services. If any provision of these Terms is found to be
        unenforceable or invalid, that provision will be limited or
        eliminated to the minimum extent necessary so that these Terms
        will otherwise remain in full force and effect and
        enforceable. The failure of either party to exercise in any
        respect any right provided for herein shall not be deemed a
        waiver of any further rights hereunder.
      </p>
    </Part>

    <Part>
      <Title level="h2">Resolving Disputes</Title>
      <p>
        We hope to solve issues before they become problems. To help
        us do so, you agree to contact{" "}
        <a href="mailto:care@joinstorkclub.com" target="blank">
          care@joinstorkclub.com
        </a>{" "}
        first, before filing a claim against Stork Club.
      </p>
      <p>
        With respect to any disputes or claims not subject to
        arbitration, as set forth below, Stork Club and you agree to
        submit to the personal and exclusive jurisdiction of the state
        courts located within San Francisco County, California and
        federal courts located within San Francisco, California.
      </p>
      <Title level="h3">
        Prohibition of class and representative actions and
        non-individualized relief.
      </Title>
      <p>
        STORK CLUB AND YOU AGREE THAT EACH OF US MAY BRING CLAIMS
        AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A
        PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
        REPRESENTATIVE ACTION OR PROCEEDING. UNLESS BOTH STORK CLUB
        AND YOU AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR
        JOIN MORE THAN ONE PERSON’S OR PARTY’S CLAIMS AND MAY NOT
        OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED,
        REPRESENTATIVE, OR CLASS PROCEEDING. THE ARBITRATOR MAY AWARD
        RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY
        RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF
        AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF
        NECESSITATED BY THAT PARTY’S INDIVIDUAL CLAIM(S).
      </p>
    </Part>

    <Part>
      <Title level="h2">Privacy and Your Personal Information</Title>
      <p>
        Stork Club’s Privacy Policy is located at{" "}
        <a href="https://my.joinstorkclub.com/legal/privacy">
          https://my.joinstorkclub.com/legal/privacy
        </a>{" "}
        (the “Privacy Policy”) and is expressly incorporated into
        these Terms. The Privacy Policy discloses Stork Club’s
        practices regarding the collection, use, and disclosure of
        your personal information that you create, upload, transmit or
        display while using the Services. By agreeing to these Terms,
        you are also agreeing to the terms of Stork Club’s Privacy
        Policy and consenting to the collection, use and disclosure of
        information provided to Stork Club as set forth herein. For
        inquiries in regard to the Privacy Policy or to report a
        privacy-related problem, please contact us at{" "}
        <a href="mailto:legal@joinstorkclub.com" target="blank">
          legal@joinstorkclub.com
        </a>
        .
      </p>
      <p>
        The Services include, but not limited by the ability to
        connect with an invitation-only social network of people
        planning to or currently undergoing fertility treatments (e.g.
        egg freezing, IVF). BY VISITING OR USING THE SERVICES, YOU
        CONSENT TO OUR COLLECTION, USE AND DISCLOSURE OF YOUR PERSONAL
        INFORMATION, INCLUDING HEALTH-RELATED INFORMATION SUCH AS YOUR
        MEDICAL CONDITIONS, IN ACCORDANCE WITH OUR PRIVACY POLICY. IF
        YOU DO NOT CONSENT TO THE COLLECTION, USE AND DISCLOSURE OF
        THIS INFORMATION, YOU SHOULD NOT ACCESS OR USE THE SITE,
        MOBILE APP OR THE SERVICES.
      </p>
    </Part>

    <Part>
      <Title level="h2">General</Title>
      <p>
        These Terms and any action related thereto will be governed by
        the laws of the State of California without regard to its
        conflict of laws provisions. All claims arising out of or
        relating to these terms or the services or software must be
        litigated exclusively in the state and federal courts located
        in the Northern District of California and each of the parties
        hereto waives any objection to jurisdiction and venue in such
        courts. These Terms constitute the entire and exclusive
        agreement between you and Stork Club Fertility, Inc., and
        supersede and replace any other agreements, terms and
        conditions. These Terms create no third party beneficiary
        rights. Stork Club’s failure to enforce a provision is not a
        waiver of its right to do so later. If a provision is found
        unenforceable, the remaining provisions of the Agreement will
        remain in full effect and an enforceable term will be
        substituted reflecting our intent as closely as possible. You
        may not assign any of your rights in these Terms without our
        written consent, and any such attempt will be null and have no
        effect. Stork Club may freely assign or transfer these terms
        without restriction. Except as expressly set forth in these
        Terms, the exercise by either party of any of its remedies
        under these Terms will be without prejudice to its other
        remedies under these Terms or otherwise.
      </p>
    </Part>

    <Part>
      <Title level="h2">Changes to Terms</Title>
      <p>
        We may revise the Terms of Services from time to time. If a
        revision is material, as determined solely by us, we will
        notify you, for example via email. The current version will
        always be posted to our Terms page.
      </p>
    </Part>

    <Title level="h2">Contact us</Title>
    <p>
      If you have any questions about these Terms, please contact us
      at{" "}
      <a href="mailto:legal@joinstorkclub.com" target="blank">
        legal@joinstorkclub.com
      </a>
      .
    </p>
  </LegalPage>
);

export default ToS;
