import * as t from "io-ts";

export const ProgramC = t.type({
  attributes: t.type({
    name: t.string,
    program_type: t.string,
    reimbursable: t.boolean,
  }),
  id: t.string,
  type: t.literal("program"),
});

export type ProgramEntity = t.TypeOf<typeof ProgramC>;

// export interface ProgramEntityWithRelations
//   extends ProgramEntity {
//   relationships: {
//     program_category:
//   };
// }
