import { Trans } from "@lingui/macro";
import * as React from "react";
import styled from "styled-components";
import appStore from "./appStore.png";
import googlePlay from "./googlePlay.png";

const Button = styled.a`
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: block;
  height: 40px;
  margin: 0 15px;
  width: 120px;
  span {
    display: none;
  }
`;

const ButtonIOS = styled(Button)`
  background-image: url(${appStore});
`;

const ButtonAndroid = styled(Button)`
  background-image: url(${googlePlay});
`;

const iosReg = /(iPhone|iPad|iPod)/;
const isIos = navigator.userAgent.match(iosReg);

export const MobileButtonIOS: React.FC = () => (
  <ButtonIOS
    // https://stackoverflow.com/questions/433907/how-to-link-to-apps-on-the-app-store
    href={
      isIos
        ? "itms-apps://apps.apple.com/app/id1491545080"
        : "https://apps.apple.com/us/app/stork-club-family-benefits/id1491545080"
    }
    rel="noreferrer"
    target={isIos ? "_self" : "_blank"}
  >
    <span>
      <Trans>App Store</Trans>
    </span>
  </ButtonIOS>
);

export const MobileButtonAndroid: React.FC = () => (
  <ButtonAndroid
    href="https://play.google.com/store/apps/details?id=com.storkclub"
    rel="noreferrer"
    target="_blank"
  >
    <span>
      <Trans>Google Play</Trans>
    </span>
  </ButtonAndroid>
);
