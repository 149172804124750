import { Trans } from "@lingui/macro";
import { FC } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../../../constants";
import mediaQuery from "../../../../../utils/mediaQuery";
import memberIdHelp from "./member-id_help.png";

const Title = styled.div`
  color: #4a4a4a;
  font-size: 18px;
  line-height: 18px;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      font-size: 24px;
      line-height: 24px;
    `,
  )}
`;
const SubTitle = styled.div`
  color: ${colors.red};
  font-size: 13px;
  line-height: 13px;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      font-size: 15px;
      line-height: 15px;
    `,
  )}
`;
const CardNumber = styled.div`
  color: #e0ddd9;
  font-size: 13px;
  line-height: 13px;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      font-size: 15px;
      line-height: 15px;
    `,
  )}
`;
const Card = styled.div`
  background: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  height: 129px;
  padding: 16px;
  position: relative;
  width: 214px;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      height: 169px;
      padding: 20px 22px;
      width: 260px;
    `,
  )}

  ${Title} {
    margin-bottom: 24px;
    ${mediaQuery(
      "greaterThanPhone",
      css`
        margin-bottom: 39px;
      `,
    )}
  }
  ${SubTitle} {
    margin-bottom: 6px;
  }
  &:after {
    background-image: url(${memberIdHelp});
    background-size: contain;
    content: "";
    height: 158px;
    left: 134px;
    position: absolute;
    top: -5px;
    width: 205px;
    ${mediaQuery(
      "greaterThanPhone",
      css`
        height: 231px;
        left: 0;
        top: 37px;
        width: 300px;
      `,
    )}
  }

  ${mediaQuery(
    "greaterThanPhone",
    css`
      background-color: #f5f3f1;
    `,
  )}
`;

const MedicalCard: FC = () => {
  return (
    <Card>
      <Title>
        <Trans>Medical Card</Trans>
      </Title>
      <SubTitle>
        <Trans>Member ID</Trans>
      </SubTitle>
      <CardNumber>XXXXXXXXXXXXXX</CardNumber>
    </Card>
  );
};

export default MedicalCard;
