import { Right } from "fp-ts/Either";
import { useMutation, useQueryClient } from "react-query";
import { BackendLanguageCodeC } from "../../locales/appLocale/config";
import { useAxios } from "../../network";
import { ENDPOINTS } from "../../network/endpoints";
import { ProfileEntityWithRelations } from "../../network/jsonApiV2/models/specific/Profile";

export function useUpdateLanguage() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation(
    async (language: string): Promise<void> => {
      const patchedLanguage = BackendLanguageCodeC.encode(language);

      await axios.put<unknown>(
        ENDPOINTS.updatePreferredLanguage,
        {
          language: patchedLanguage,
        },
        {
          headers: {
            "X-Language": patchedLanguage,
          },
        },
      );
    },
    {
      onSuccess: (status, value) => {
        queryClient.setQueryData<
          ProfileEntityWithRelations | undefined
        >("profile", (existingData) => {
          if (existingData) {
            const decoded = BackendLanguageCodeC.decode(
              value,
            ) as Right<string>;

            return {
              ...existingData,
              attributes: {
                ...existingData.attributes,
                preferred_language: decoded.right,
              },
            };
          }
          return existingData;
        });
      },
    },
  );
}
