import * as t from "io-ts";
import { ProfileStatusC } from "../../components/FamilyStatusForm/types";
import { BackendLanguageCodeC } from "../../locales/appLocale/config";
import { CompanyCurrencyC } from "../../network/jsonApiV2/models/specific/Company";

export const userAttributesC = t.intersection([
  t.partial({
    email: t.string,
    new_ux: t.boolean,
    preferred_name: t.string,
  }),
  t.type({
    ask_member_id: t.boolean,
    fname: t.string,
    lname: t.string,
    member_id: t.union([t.string, t.null]),
    name: t.string,
    notice_member_id_change: t.boolean,
    notifications_enabled: t.boolean,
    partner: t.boolean,
    phone: t.union([t.string, t.null]),
    preferred_language: BackendLanguageCodeC,
    require_data_sharing_consent: t.boolean,
    sendbird_credentials: t.type({
      access_token: t.union([
        t.string,
        t.null, // Backend postpones sendbird credentials generation until user selects a status.
      ]),
      app_id: t.string,
      channel_url: t.string,
      user_id: t.string,
    }),
    skip_member_id_update: t.boolean,
    status: t.union([ProfileStatusC, t.null]),
    timezone: t.string,
    timezone_human: t.string,
  }),
]);

export const userCompanyC = t.type({
  attributes: t.intersection([
    t.type({
      country_code: t.string,
      country_currency: CompanyCurrencyC,
      default_language: t.string,
      languages: t.readonlyArray(BackendLanguageCodeC),
      name: t.string,
      statuses: t.readonlyArray(ProfileStatusC),
    }),
    t.partial({
      // not merged to master yet
      partner_invitation_enabled: t.boolean,
    }),
  ]),
  id: t.string,
  type: t.literal("company"),
});

export type UserCompany = t.TypeOf<typeof userCompanyC>;

export const SessionC = t.type({
  data: t.type({
    attributes: userAttributesC,
    id: t.string,
    type: t.literal("user"),
  }),
  included: t.readonlyArray(t.union([userCompanyC, t.unknown])),
});

export const tokenC = t.type(
  {
    meta: t.type(
      {
        csrf_token: t.string,
      },
      "csrf_token",
    ),
  },
  "meta",
);

export const cobraC = t.type(
  {
    meta: t.type(
      {
        cobra: t.boolean,
      },
      "cobra",
    ),
  },
  "meta",
);
