import { t } from "@lingui/macro";
import * as yup from "yup";

export const MAX_EXPENSE_AMOUNT = 100000;

export const formSchema = yup.object({
  expenses: yup
    .array()
    .of(
      yup.object({
        amount: yup
          .number()
          .transform((v: unknown) => {
            return typeof v === "number" && isNaN(v) ? undefined : v;
          })
          .required(() => t`Please enter an amount`)
          .max(
            MAX_EXPENSE_AMOUNT,
            () => t`Please enter a smaller amount`,
          ),
        date: yup
          .date()
          .transform((v: unknown) => {
            if (v instanceof Date && !isNaN(v.getTime())) {
              return v;
            }
            return undefined;
          })
          .required(() => t`Please enter a date`),
        description: yup
          .string()
          .required(() => t`Please enter a description`),
      }),
    )
    .required(
      () => t`Please add at least one expense to your reimbursement`,
    ),
  file: yup.mixed().test({
    message: () => t`Please upload a file`,
    test: (v: FileList) => {
      return v.length > 0;
    },
  }),
  id: yup.string().required(() => t`Please select a type`),
  provider: yup.string().required(() => t`Please type a provider`),
});

/**
 * Form values after validation - required fields are not optional
 */
export type FormValues = Omit<
  yup.InferType<typeof formSchema>,
  "file"
> & {
  file: FileList;
};
/**
 * Form values before validation - fields are optional
 */
export type FormState = Omit<
  yup.TypeOf<typeof formSchema>,
  "file"
> & {
  file: FileList | undefined;
};
