import * as React from "react";
import { BaseIconAttributes } from "./types";

export const Camera: React.FC<BaseIconAttributes> = ({
  width = "1em",
  height = "1em",
  mainColor = "currentColor",
  ...restProps
}) => (
  <svg
    {...restProps}
    fill="none"
    height={height}
    viewBox="0 0 16 10"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M3.24374 0.0800781C1.98762 0.0800781 0.96933 1.09836 0.96933 2.35448V7.64567C0.96933 8.90179 1.98762 9.92008 3.24374 9.92008H9.43741C10.6935 9.92008 11.7118 8.90179 11.7118 7.64567V5.67553L15.0307 9.2506V0.691551L11.7118 4.26662V2.35449C11.7118 1.09837 10.6935 0.0800781 9.43741 0.0800781H3.24374Z"
      fill={mainColor}
      fillRule="evenodd"
    />
  </svg>
);
