import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../constants";
import Icon from "../../lib/SharedIcon";
import { Authorization } from "../models/Authorization";
import { AuthorizationEvent } from "../models/AuthorizationEvent";
import { HeaderTitle } from "./Notification/HeaderTitle";
import { StatusIcon } from "./Notification/StatusIcon";
import {
  NotificationContainer,
  Header,
  HeaderIcon,
} from "./Notification/style";

export const ExpandIcon = styled.div`
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 30px;
  justify-content: center;
  transform: rotate(-90deg);
  user-select: none;
  width: 30px;
  ${Header}:hover & svg path {
    stroke: ${colors.blue};
  }

  [dir="rtl"] & {
    transform: rotate(90deg);
  }
`;

export type Props = {
  authorization: Authorization;
  event: AuthorizationEvent;
};

export const NotificationPreview: React.FC<Props> = (
  props: Props,
) => {
  const { event } = props;
  const { status, url } = event;

  return (
    <NotificationContainer $isSeen={event.isRead} as={Link} to={url}>
      <Header isExpanded={false} status={status}>
        <HeaderIcon>
          <StatusIcon status={status} />
        </HeaderIcon>
        <HeaderTitle {...props} />
        <ExpandIcon>
          <Icon type="arrowDown" width="14px" />
        </ExpandIcon>
      </Header>
    </NotificationContainer>
  );
};
