import { Trans } from "@lingui/macro";
import { format as formatDate } from "date-fns";
import { FC } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors } from "../../../../constants";
import Button from "../../../../lib/Button";
import mediaQuery from "../../../../utils/mediaQuery";
import { Authorization } from "../../../models/Authorization";
import { Bill } from "../../../models/Bill";
import { Status } from "../../../models/Status";
import { UserPlan } from "../../../models/UserPlan";
import { BillDetails } from "./BillDetails";
import { ResponsibilityBlockComplete } from "./Responsibility/Complete";
import { ResponsibilityBlockIncomplete } from "./Responsibility/Incomplete";
import { WhyAmIGettingThisBill } from "./WhyAmIGettingThisBill";

type Props = {
  bill: Bill;
  status: Status;
  authorization: Authorization;
  userPlan: UserPlan;
};

const ThankYouRow = styled.div`
  color: ${colors.brownGrey};
  margin-bottom: 20px;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 10px;
    `,
  )}
`;

export const ButtonContainer = styled.div`
  margin-bottom: 15px;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 10px;
    `,
  )}
`;

export const DueDateContainer = styled.div`
  margin-bottom: 15px;
  text-align: center;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 10px;
    `,
  )}
`;

export const DueDate = styled.span`
  color: ${colors.coralBlueGreen};
  font-weight: 500;
`;

const WhyAmIGettingThisBillRow = styled.div`
  margin-bottom: 20px;
`;

export const BillAvailable: FC<Props> = (props: Props) => {
  const { bill, status, authorization, userPlan } = props;
  const { paymentDueDate, url } = bill;
  const { profileComplete } = userPlan;
  const isRx = authorization.practiceTemplate === "pharmacy";
  const isBillProcessing = status === "billPaymentInit";
  const isBillPaid = status === "billPaid";

  return (
    <>
      <ThankYouRow>
        <Trans>
          Thank you for using Stork Club and our in-network providers
          for your care.
          <br />
          You&apos;re receiving this bill because your health plan
          includes a cost-sharing policy.
        </Trans>
      </ThankYouRow>
      <BillDetails
        authorization={authorization}
        bill={bill}
        userPlan={userPlan}
      />
      <ButtonContainer>
        {!isBillProcessing && !isBillPaid && (
          <Button as={Link} kind="filledCoral" to={url}>
            <Trans>Pay now</Trans>
          </Button>
        )}
        {isBillProcessing && (
          <Button disabled kind="outlinedCoral">
            <Trans>
              We are processing your payment. It may take up to 7 days
            </Trans>
          </Button>
        )}
        {isBillPaid && (
          <Button disabled kind="outlinedGreen">
            <Trans>The bill has been paid</Trans>
          </Button>
        )}
      </ButtonContainer>
      {!isBillProcessing && paymentDueDate && (
        <DueDateContainer>
          <Trans>Due date</Trans>{" "}
          <DueDate>
            {formatDate(paymentDueDate, "MM-dd-yyyy")}
          </DueDate>
        </DueDateContainer>
      )}
      <WhyAmIGettingThisBillRow>
        <WhyAmIGettingThisBill />
      </WhyAmIGettingThisBillRow>
      {profileComplete ? (
        <ResponsibilityBlockComplete
          authorization={authorization}
          userPlan={userPlan}
        />
      ) : (
        <ResponsibilityBlockIncomplete
          isRx={isRx}
          userPlan={userPlan}
        />
      )}
    </>
  );
};
