import { isLeft } from "fp-ts/Either";
import * as t from "io-ts";
import { useQuery } from "react-query";
import { UseQueryResult } from "react-query/types/react/types";
import { wrongResponseFormat } from "../../../../locales/errors";
import { useAxios } from "../../../../network";

export const responseC = t.type({
  meta: t.type({
    // any_all: t.boolean,
    any_unread: t.boolean,
  }),
});

const useGetUnreadNotifications = (): UseQueryResult<boolean> => {
  const axios = useAxios();
  return useQuery({
    queryFn: async () => {
      const response = await axios.get(
        `/v2/notifications?meta_only=true`,
      );

      const decodeResult = responseC.decode(response.data);

      if (isLeft(decodeResult)) {
        // eslint-disable-next-line no-console
        console.error(decodeResult);
        throw wrongResponseFormat;
      }

      return decodeResult.right.meta.any_unread;
    },
    queryKey: "getUnreadNotifications",
    staleTime: 5 * 60 * 1000, // 5 minutes cache
  });
};

export default useGetUnreadNotifications;
