/**
 * Language name can come in different formats, this function normalizes it.
 * locale name is always lowercase
 * region name is always uppercase
 * delimiter is always "-"
 *
 *
 * "EN" => "en",
 * "en" => "en",
 * "en-US" => "en-US",
 * "en-us" => "en-US",
 * "en_us" => "en-US",
 */
export default function normalizeLocaleName(
  languageName: string,
): string {
  const match = languageName.match(
    /^([a-zA-Z]{2})([_-]+([a-zA-Z]{2}))?$/,
  );
  if (!match) {
    return languageName; // wrong format, it is more safe to return it as is
  }
  const [, language, , region] = match;
  return `${language.toLowerCase()}${
    region ? `-${region.toUpperCase()}` : ""
  }`;
}

export type NormalizeLocaleNameType = typeof normalizeLocaleName;
