import { t } from "@lingui/macro";
import React, { FC, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import useAuthSession from "../../authentication/hooks/useAuthSession";
import { colors } from "../../constants";
import Icon from "../../lib/Icon";
import IncreaseContrast from "../../lib/IncreaseContrast";
import PageTitle from "../../lib/PageTitle";
import Skeleton from "../../lib/Skeleton";
import Tabs from "../../lib/Tabs";
import Title from "../../lib/Title";
import mediaQuery from "../../utils/mediaQuery";
import PageLayout, {
  Header,
  Body,
  Footer,
  Sidebar,
} from "../PageLayout";
import AddPartner from "./AddPartner";
import { usePartnerInvitationEnabled } from "./AddPartner/hooks";
import { useProfile } from "./hooks";
import Profile from "./Profile";
import Settings from "./Settings";

const StyledHeader = styled(Header)`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      flex-wrap: nowrap;
    `,
  )}
`;

const BackButtonContainer = styled.div`
  margin-bottom: 14px;
  width: 100%;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      display: none;
    `,
  )}
`;

const BackButton = styled.button`
  background: none;
  border: none;
  display: block;
  margin: 0;
  padding: 0;
  &:active,
  &:focus,
  &:hover {
    background: none;
    border: none;
  }
`;

const ProfilePage: FC<{
  editMode?: boolean;
  addPartnerMode?: boolean;
}> = ({ editMode, addPartnerMode }) => {
  const { data, error, isLoading } = useProfile();
  const navigate = useNavigate();
  const { data: authSession } = useAuthSession();
  const isPartner = !!authSession?.user?.attributes.partner;
  const partnerInvitationEnabled = usePartnerInvitationEnabled();

  const tabs = useMemo(() => {
    if (!data) {
      return [];
    }
    return [
      {
        component: (
          <>
            <PageTitle>My profile</PageTitle>
            <Profile data={data} editMode={editMode} />
          </>
        ),
        key: "My profile",
        label: t`My profile`,
        route: "/profile",
      },
      !isPartner && partnerInvitationEnabled
        ? {
            component: (
              <>
                <PageTitle>Add partner</PageTitle>
                <AddPartner />
              </>
            ),
            key: "Add partner",
            label: t`Add partner`,
            route: "/profile/add-partner",
          }
        : null,
    ].filter((x): x is NonNullable<typeof x> => !!x);
  }, [data, editMode, isPartner, partnerInvitationEnabled]);

  if (error) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw error;
  }

  const defaultActiveKey = addPartnerMode
    ? "Add partner"
    : "My profile";

  return (
    <PageLayout
      footer={<Footer />}
      header={
        data ? (
          <StyledHeader>
            <Settings />
            <BackButtonContainer>
              <BackButton onClick={() => navigate(-1)}>
                <Icon fill={colors.brownGrey} type="back" />
              </BackButton>
            </BackButtonContainer>
            <Title level="h1">{data.attributes.name}</Title>
            <IncreaseContrast />
          </StyledHeader>
        ) : null
      }
      sidebar={<Sidebar />}
    >
      <Body>
        <Skeleton active css="padding: 50px;" loading={isLoading}>
          {tabs.length > 0 ? (
            <Tabs
              key={defaultActiveKey}
              defaultActiveKey={defaultActiveKey}
              tabs={tabs}
              withDefaultPadding
            />
          ) : null}
        </Skeleton>
      </Body>
    </PageLayout>
  );
};

export default ProfilePage;
