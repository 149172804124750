import * as React from "react";
import { BaseIconAttributes } from "./types";

export const PasswordEyeClosed: React.FC<BaseIconAttributes> = ({
  width = "1em",
  height = "1em",
  mainColor = "currentColor",
  ...restProps
}) => (
  <svg
    {...restProps}
    fill="none"
    height={height}
    viewBox="0 0 22 11"
    width={width}
  >
    <path
      clipRule="evenodd"
      d="M2.2878 7.66481C2.01839 7.49526 1.75351 7.31824 1.49341 7.13402L3.68079 4C3.93594 4.18883 4.19933 4.36824 4.47036 4.53769L2.2878 7.66481ZM8.38237 10.0131C8.06702 9.95895 7.75445 9.89582 7.44489 9.82394L8.21327 6.09641C8.52153 6.16933 8.83447 6.23117 9.15159 6.28145L8.38237 10.0131ZM14.9212 9.82379C14.6117 9.89568 14.2991 9.95883 13.9838 10.013L13.2147 6.28199C13.5318 6.23179 13.8448 6.17003 14.1531 6.09719L14.9212 9.82379ZM20.8724 7.13318C20.6123 7.31745 20.3475 7.49452 20.0781 7.66411L17.8968 4.53883C18.1679 4.3694 18.4313 4.19 18.6864 4.00117L20.8724 7.13318Z"
      fill={mainColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M21.0754 0.668658L21.1786 0.500126L22 1.00133L21.8945 1.17367L21.8946 1.17372L21.8898 1.18118C19.6622 4.67421 15.6133 7 11 7C6.30888 7 2.20123 4.59514 0 1.00145L0.821262 0.5C2.84555 3.80481 6.64021 6.03886 11 6.03886C15.2857 6.03886 19.0256 3.87997 21.0754 0.668658Z"
      fill={mainColor}
      fillRule="evenodd"
    />
  </svg>
);
