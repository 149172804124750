import * as React from "react";
import { BaseIconAttributes } from "./types";

export const ArrowDown: React.FC<BaseIconAttributes> = ({
  width = "1em",
  height = "1em",
  mainColor = "currentColor",
  ...restProps
}) => (
  <svg
    {...restProps}
    fill="none"
    height={height}
    viewBox="0 0 16 8"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1l7 6 7-6"
      stroke={mainColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);
