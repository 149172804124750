import { i18n } from "@lingui/core";
import { useState, useMemo, FC, useCallback } from "react";
import { StylesConfig } from "react-select/dist/declarations/src/styles";
import styled from "styled-components";
import { colors, fonts } from "../../constants";
import { syncWithBackendLocale } from "../../locales/appLocale";
import { languageSwitcherLocalesMap } from "../../locales/appLocale/config";
import BaseSelect from "../forms/primitives/Select/BaseSelect";
import Icon from "../Icon";
import { useUpdateLanguage } from "./hooks";

const Container = styled.div`
  user-select: none;
  display: flex;
  justify-content: flex-end;
`;

interface LocaleSelectProps extends Props {
  onChange: (language: string) => void;
}

const LocaleSelect: FC<LocaleSelectProps> = ({
  languages = [],
  defaultLanguage = "en",
  onChange,
  ...restProps
}: LocaleSelectProps) => {
  const [lang, setLang] = useState(defaultLanguage);

  const customStyles: StylesConfig = useMemo(
    () => ({
      control: (base, { menuIsOpen }) => ({
        ...base,
        "&:hover": {
          background: colors.brownLightGrey2,
          borderColor: "transparent",
        },
        background: menuIsOpen
          ? colors.brownLightGrey2
          : "rgba(245, 243, 241, 0.6)",
        border: `1px solid ${
          menuIsOpen
            ? `${colors.brownLightGrey1} !important`
            : "transparent"
        }`,
        borderRadius: "8px",
        boxShadow: "none",
        cursor: "pointer",
        display: "inline-flex",
        flexDirection: "row-reverse",
        gap: "6px",
        padding: "6px 10px",
      }),
      menu: (base) => ({
        ...base,
        border: `1px solid ${colors.brownLightGrey1}`,
        borderRadius: "8px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        margin: "2px 0 0 0",
        minWidth: "157px",
        overflow: "hidden",
      }),
      menuList: (base) => ({
        ...base,
        padding: "0",
      }),
      option: (base, { isSelected }) => ({
        ...base,
        "&:hover": {
          backgroundColor: colors.brownLightGrey2,
          color: colors.black,
        },
        backgroundColor: isSelected
          ? `${colors.brownLightGrey1} !important`
          : colors.white,
        color: isSelected ? colors.black : colors.brownGrey,
        cursor: "pointer",
        fontFamily: fonts.main,
      }),
      singleValue: (base) => ({
        ...base,
        fontFamily: fonts.main,
        fontWeight: "500",
        margin: "0",
      }),
      valueContainer: (base) => ({
        ...base,
        padding: "0",
      }),
    }),
    [],
  );

  const options = languages
    .filter(
      (code): code is keyof typeof languageSwitcherLocalesMap =>
        code in languageSwitcherLocalesMap,
    )
    .map((code) => ({
      label: languageSwitcherLocalesMap[code].getLabel(),
      value: code,
    }))
    .sort((a, b) => a.label.localeCompare(b.label, i18n.locale));

  const onSelectLanguage = (
    language: (typeof options)[number] | unknown | null,
  ) => {
    const { value } = language as (typeof options)[number];

    setLang(value);
    onChange(value);
  };

  if (options.length < 2) {
    return null;
  }
  return (
    <Container {...restProps}>
      <BaseSelect
        components={{
          DropdownIndicator: () => (
            <Icon
              fill={colors.brownBlack}
              height={18}
              type="earth"
              width={18}
            />
          ),
          IndicatorSeparator: () => null,
        }}
        isMulti={false}
        isSearchable={false}
        menuPortalTarget={document.body}
        onChange={onSelectLanguage}
        options={options}
        styles={customStyles}
        value={options.find((m) => m.value === lang)}
      />
    </Container>
  );
};

interface Props {
  languages?: readonly string[];
  defaultLanguage?: string;
  userRegion?: string;
}

export const HomeLocaleSelect: FC<Props> = ({
  userRegion,
  ...restProps
}) => {
  const { mutate: updateLanguage } = useUpdateLanguage();

  const onChange = useCallback(
    (value: string) => {
      if (!userRegion) {
        throw new Error("User region is not defined");
      }

      updateLanguage(value, {
        onSuccess: () => {
          syncWithBackendLocale(value, userRegion);
        },
      });
    },
    [updateLanguage, userRegion],
  );

  return <LocaleSelect {...restProps} onChange={onChange} />;
};

// TODO: Create and add SignupLocaleSelect component with design
//       like in signup page language switcher to export for this component
//       with different onChange behaviour (change language locally not on server)
// TODO: Delete LocaleSwitcher (signup language switcher) and related components
// TODO: Add story for this component
