import { Trans, t } from "@lingui/macro";
import { useCallback, FC, useMemo } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import PageLayout, {
  Body,
  Footer,
  Sidebar,
} from "../../../components/PageLayout";
import PageTitle from "../../../lib/PageTitle";
import Skeleton from "../../../lib/Skeleton";
import Title from "../../../lib/Title";
import { useAxios } from "../../../network";
import ReimbursementGroup from "../../../reimbursements/components/ReimbursementGroup";
import useGetReimbursements from "../../../reimbursements/hooks/useGetReimbursements";
import { Reimbursement } from "../../../reimbursements/models/Reimbursement";
import useGetNotifications from "../../hooks/useGetNotifications";
import { Authorization } from "../../models/Authorization";
import { UserPlan } from "../../models/UserPlan";
import AuthorizationGroup from "../AuthorizationGroup";

const StyledTitle = styled(Title)`
  margin-bottom: 30px;
`;

const NothingYet = styled.h2`
  color: #808080;
`;

const Container = styled.div`
  max-width: var(--content-max-width);
  padding: 30px var(--content-margin-side) 0;
`;

type Props = {
  notifications: Array<Authorization | Reimbursement>;
  userPlan: UserPlan;
};

const Notifications: FC<Props> = ({
  notifications,
  userPlan,
}: Props) => {
  const { authorizationUidOrId, eventId } = useParams();
  const axios = useAxios();
  const queryClient = useQueryClient();

  const postNotificationRead = useCallback(
    async (id: string) => {
      await axios.put(`/v2/notifications/${id}/read`);
      queryClient
        .invalidateQueries({
          queryKey: "getUnreadNotifications",
        })
        .catch(() => {});
    },
    [axios, queryClient],
  );

  const postReimbursementRead = useCallback(
    async (id: string) => {
      await axios.put(`/v2/reimbursements/${id}/read`);
    },
    [axios],
  );

  if (notifications.length === 0)
    return (
      <NothingYet>
        <Trans>nothing yet</Trans>
      </NothingYet>
    );

  return (
    <>
      {notifications.map((item) => {
        // some older notification links may contain ID in url instead of newer UID implementation
        // so we should search for both UID and ID in authorizations
        // and remove ID as soon as there will be no old active links
        const expandedId =
          item.uid === authorizationUidOrId ||
          item.id === authorizationUidOrId
            ? eventId || item.events[0]?.id
            : null;

        //  isReimbursement
        if (!("practiceTemplate" in item)) {
          return (
            <ReimbursementGroup
              key={item.id}
              expandedId={expandedId}
              onRead={() => postReimbursementRead(item.id)}
              reimbursement={item}
            />
          );
        }
        return (
          <AuthorizationGroup
            key={item.id}
            authorization={item}
            expandedId={expandedId}
            onRead={() => postNotificationRead(item.id)}
            userPlan={userPlan}
          />
        );
      })}
    </>
  );
};

const NotificationsPage: FC = () => {
  const { authorizationUidOrId } = useParams();
  const { data: A, isLoading } = useGetNotifications();
  const { data: R } = useGetReimbursements();

  const mergedData = useMemo(() => {
    if (A?.authorizations) {
      if (R?.reimbursements) {
        return {
          notifications: [
            ...A.authorizations,
            ...R.reimbursements,
          ].sort((a, b) => {
            // We treat events[0] as the freshest event because we sort events in
            // hooks/toGetNotificationsResult.ts and hooks/toGetReimbursementsResult.ts
            const aMostRecent = a.events[0].createdAt;
            const bMostRecent = b.events[0].createdAt;

            return bMostRecent.getTime() - aMostRecent.getTime();
          }),
          userPlan: A?.userPlan,
        };
      }
    }

    return null;
  }, [A, R]);

  const foundReimbursementTitleByParam = R?.reimbursements?.find(
    ({ uid }) => uid === authorizationUidOrId,
  )?.programName;
  const foundAuthorizationTitleByParam = A?.authorizations?.find(
    ({ uid, id }) =>
      // some older notification links may contain ID in url instead of newer UID implementation
      // so we should search for both UID and ID in authorizations
      // and remove ID as soon as there will be no old active links
      uid === authorizationUidOrId || id === authorizationUidOrId,
  )?.title;

  const pageTitle = foundReimbursementTitleByParam
    ? `${foundReimbursementTitleByParam} ${t`reimbursement`}`
    : foundAuthorizationTitleByParam || t`Notifications`;

  return (
    <PageLayout
      footer={<Footer />}
      header={<PageTitle>{pageTitle}</PageTitle>}
      sidebar={<Sidebar />}
    >
      <Body>
        <Container>
          <StyledTitle level="h1">
            <Trans>Notifications</Trans>
          </StyledTitle>
          <PageTitle>{t`Notifications`}</PageTitle>
          <Skeleton active loading={isLoading}>
            {mergedData ? <Notifications {...mergedData} /> : null}
          </Skeleton>
        </Container>
      </Body>
    </PageLayout>
  );
};

export default NotificationsPage;
