import { Trans } from "@lingui/macro";
import { FC } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../../constants";
import mediaQuery from "../../../../utils/mediaQuery";
import { Authorization } from "../../../models/Authorization";
import { BoldBlack } from "../style";
import { PrescribedTable } from "./CoveredTable";
import PrescribedRx from "./PrescribedRx";

type Props = {
  authorization: Authorization;
};

const IntroRow = styled.div`
  color: ${colors.brownGrey};
  margin-bottom: 20px;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 10px;
    `,
  )}
`;

const PracticeRow = styled.div`
  margin-bottom: 20px;
`;

export const RxCanceled: FC<Props> = ({ authorization }: Props) => {
  const { services } = authorization;

  return (
    <>
      <IntroRow>
        <Trans>Rx authorization was canceled by Provider</Trans>
      </IntroRow>

      <PracticeRow>
        <Trans>
          By{" "}
          <BoldBlack>
            Stork Club Rx ({authorization.practiceName})
          </BoldBlack>
        </Trans>
      </PracticeRow>

      {!!services && services.length > 0 && (
        <PrescribedRx>
          <PrescribedTable services={services} />
        </PrescribedRx>
      )}
    </>
  );
};
