import styled from "styled-components";
import { colors } from "../../../constants";

const Badge = styled.div.attrs({
  role: "status",
})`
  display: inline-flex;
  background-color: ${colors.coral};
  border-radius: 50%;
  color: white;
  width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 100%;
  justify-content: center;
  align-items: center;
  margin-inline-start: 8px;
  font-weight: bold;
  user-select: none;
  flex-shrink: 0;
`;

export default Badge;
