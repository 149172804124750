import { Trans } from "@lingui/macro";
import { TypeOf } from "io-ts";
import { FC } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors } from "../../../../constants";
import { highContrastMode } from "../../../../constants/colors";
import { ImageSet } from "../../../../io-ts/types";
import Button from "../../../../lib/Button";
import hexColorOpacity from "../../../../utils/hexColorOpacity";
import mediaQuery from "../../../../utils/mediaQuery";
import {
  Container,
  Title,
  AvatarContainer,
  Avatar,
  Name,
  ButtonsContainer,
  EnvelopeContainer,
  CameraContainer,
  TitleRow,
} from "./style";

const Envelope = () => (
  <svg
    fill="none"
    height="10"
    viewBox="0 0 14 10"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.165 0.5H1.83498C1.12479 0.5 0.550188 1.00625 0.550188 1.625L0.543732 8.375C0.543732 8.99375 1.12479 9.5 1.83498 9.5H12.165C12.8752 9.5 13.4562 8.99375 13.4562 8.375V1.625C13.4562 1.00625 12.8752 0.5 12.165 0.5ZM12.165 2.75L6.99998 5.5625L1.83498 2.75V1.625L6.99998 4.4375L12.165 1.625V2.75Z"
      fill="currentColor"
    />
  </svg>
);

const Camera = () => (
  <svg
    fill="none"
    height="10"
    viewBox="0 0 16 10"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M3.24374 0.0800781C1.98762 0.0800781 0.96933 1.09836 0.96933 2.35448V7.64567C0.96933 8.90179 1.98762 9.92008 3.24374 9.92008H9.43741C10.6935 9.92008 11.7118 8.90179 11.7118 7.64567V5.67553L15.0307 9.2506V0.691551L11.7118 4.26662V2.35449C11.7118 1.09837 10.6935 0.0800781 9.43741 0.0800781H3.24374Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

const StyledButton = styled(Button)`
  flex-grow: 1;
  justify-content: center;
  padding-inline-end: 5px;
  padding-inline-start: 5px;
  text-decoration: none;
  user-select: none;

  ${mediaQuery(
    "desktopMedium",
    css`
      flex-grow: 0;
      padding-inline-end: 15px;
      padding-inline-start: 15px;
    `,
  )}

  ${({ kind }) => {
    // TODO: subscribe on color changes, or (better) pass it via css variables `var(--high-contrast)`
    if (kind === "filledBlue" && !highContrastMode) {
      return css`
        background-color: ${hexColorOpacity(colors.blue, 0.04)};
        color: ${colors.blue};

        &:hover {
          background-color: ${hexColorOpacity(colors.blue, 0.1)};
          color: ${colors.blue};
        }
      `;
    }
    if (kind === "filledGreen" && !highContrastMode) {
      return css`
        background-color: ${hexColorOpacity(colors.green, 0.04)};
        color: ${colors.green};

        &:hover {
          background-color: ${hexColorOpacity(colors.green, 0.1)};
          color: ${colors.green};
        }
      `;
    }
    return ``;
  }}
`;
export type Props = {
  fname: string;
  lname: string;
  userName: string;
  avatar: TypeOf<typeof ImageSet> | null;
  chatLink: string;
  scheduleLink: string;
};

export const CareNavigatorWelcome: FC<Props> = ({
  fname,
  lname,
  userName,
  avatar,
  chatLink,
  scheduleLink,
}: Props) => (
  <Container aria-labelledby="CareNavigatorWelcome_Title">
    <Title id="CareNavigatorWelcome_Title">
      <TitleRow>
        <Trans>Hi&nbsp;{userName}</Trans>,&nbsp;
      </TitleRow>
      <TitleRow>
        <Trans>I’m&nbsp;your Care&nbsp;Partner</Trans>
      </TitleRow>
    </Title>
    <AvatarContainer>
      {avatar && (
        <Avatar
          alt=""
          sizes="(max-width: 767px) 90px, 120px"
          src={avatar.w256}
          srcSet={`${avatar.w64} 64w, ${avatar.w128} 128w, ${avatar.w256} 256w`}
        />
      )}
      <Name>
        {fname}
        <br />
        {lname}
      </Name>
    </AvatarContainer>
    <ButtonsContainer>
      <StyledButton
        as={Link}
        kind="filledBlue"
        resetWidth
        to={chatLink}
      >
        <EnvelopeContainer>
          <Envelope />
        </EnvelopeContainer>
        <Trans>Send message</Trans>
      </StyledButton>
      <StyledButton
        as={Link}
        kind="filledGreen"
        resetWidth
        to={scheduleLink}
      >
        <CameraContainer>
          <Camera />
        </CameraContainer>
        <Trans>Schedule call</Trans>
      </StyledButton>
    </ButtonsContainer>
  </Container>
);
