import { forwardRef, HTMLAttributes } from "react";
import styled from "styled-components";
import { colors } from "../../../constants";
import Icon from "../../../lib/SharedIcon";

const Container = styled.div`
  color: ${colors.brownLightGrey1};
  text-align: center;
`;

const Spinner = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLElement>
>((props, ref) => {
  return (
    <Container ref={ref} {...props}>
      <Icon css="font-size: 28px" type="spinner" />
    </Container>
  );
});

export default Spinner;
