import { Trans } from "@lingui/macro";
import React from "react";
import styled, { css } from "styled-components";
import { colors } from "../../constants";
import Button from "../../lib/Button";
import Icon from "../../lib/messages/Icon";
import mediaQuery from "../../utils/mediaQuery";

const StyledIcon = styled(Icon)``;
const P = styled.p`
  color: ${colors.brownBlack};
  font-size: 15px;
  line-height: 1.6;
  margin: 0;
`;

const RetryButton = styled(Button)`
  color: ${colors.red};
`;

const Title = styled.div`
  align-items: center;
  color: ${colors.red};
  display: flex;
  font-size: 15px;
  gap: 4px;
  line-height: 1.6;
`;
const SendBirdCantConnectContainer = styled.div`
  align-items: center;
  border: 1px solid ${colors.brownLightGrey1};
  border-radius: 10px;
  display: grid;
  grid-template: "title" "description" "button" / auto;

  padding: 20px;
  row-gap: 12px;

  ${RetryButton} {
    grid-area: button;
    margin-top: 8px;
    justify-self: flex-start;
    padding: 13px 40px;
  }

  ${P} {
    grid-area: description;
  }
  ${mediaQuery(
    "greaterThanPhone",
    css`
      grid-template:
        "title title"
        "description button" / auto min-content;
      ${RetryButton} {
        justify-self: unset;
        margin-top: 0;
      }
    `,
  )}
`;
const BrDesktop = styled.br`
  ${mediaQuery(
    "phone",
    css`
      display: none;
    `,
  )}
`;
export default function SendBirdCantConnect() {
  return (
    <SendBirdCantConnectContainer>
      <Title>
        <StyledIcon size="16" type="error" />
        <span>
          <Trans>Couldn&#39;t load messages</Trans>
        </span>
      </Title>
      <P>
        <Trans>
          Check your data or wifi connection and try again.
          <BrDesktop />
          If this error persists, please contact us at{" "}
          <a href="mailto:care@joinstorkclub.com">
            care@joinstorkclub.com
          </a>
        </Trans>
      </P>
      <RetryButton
        kind="outlinedCoral"
        onClick={() => {
          window.location.reload();
        }}
        resetWidth
        type="button"
      >
        <Trans>Retry</Trans>
      </RetryButton>
    </SendBirdCantConnectContainer>
  );
}
