import * as React from "react";
import { BaseIconAttributes } from "./types";

export const CheckSlim: React.FC<BaseIconAttributes> = ({
  width = "1em",
  height = "1em",
  mainColor = "currentColor",
  ...restProps
}) => (
  <svg
    {...restProps}
    fill="none"
    height={height}
    viewBox="0 0 12 12"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.948868 5.63307L4.48571 10.7712L10.5465 1.96296L11.0516 1.22894"
      stroke={mainColor}
      strokeLinejoin="bevel"
      strokeWidth="1.5"
    />
  </svg>
);
