import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { t, Trans } from "@lingui/macro";
import { createRef, FC, useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useMount } from "react-use";
import styled, { css } from "styled-components";
import * as yup from "yup";
import useAuthSession from "../../authentication/hooks/useAuthSession";
import { colors } from "../../constants";
import Button from "../../lib/Button";
import { ButtonWithLoader } from "../../lib/Button/ButtonWithLoader";
import FormField from "../../lib/forms/FormField";
import Input from "../../lib/forms/primitives/Input";
import Title from "../../lib/Title";
import { useAxios } from "../../network";
import { JSONApiErrorsException } from "../../network/jsonApi/core/JSONApiErrorsPayload";
import { mapJSONApiErrors } from "../../utils/errors";
import { useHandleSubmitImpl } from "../../utils/forms";
import mediaQuery from "../../utils/mediaQuery";

interface FormProps {
  phone: string;
}

type Props = {
  clinicId: string;
  onCancel: () => void;
  isDoula?: boolean;
};

const getResolver = () => {
  const FormSchema = yup.object().shape({
    phone: yup
      .string()
      .label(t`phone`)
      .trim()
      .required(t`Phone number is required`),
  });

  return yupResolver(FormSchema);
};

const Container = styled.div`
  box-sizing: border-box;
  margin-top: 20px;
  padding-bottom: 25px;
  position: relative;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      padding-bottom: 0;
    `,
  )}
`;

const StyledTitle = styled(Title)`
  margin: 0 0 5px;
`;

const AllSetContainer = styled(Container)`
  padding-top: 20px;
  border-top: 1px solid ${colors.brownLightGrey1};
  margin-top: 0px;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      padding-top: 30px;
      margin-top: 30px;
    `,
  )}
`;

const Text = styled.div`
  font-size: 15px;
  margin: 0 0 15px;
`;

const Form = styled.form`
  display: grid;
  grid-gap: 20px;
  margin-bottom: 30px;

  ${mediaQuery(
    "desktopMedium",
    css`
      grid-template-columns: 335px 150px 90px;
      grid-template-rows: 50px;
    `,
  )}
`;

const BookAppointmentForm: FC<Props> = ({
  clinicId,
  onCancel,
  isDoula = false,
}: Props) => {
  const { data: authSession } = useAuthSession();
  const containerRef = createRef<HTMLDivElement>();

  useMount(() => {
    containerRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  });

  const resolver = useMemo(() => getResolver(), []);

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm<FormProps>({
    resolver,
  });

  const axios = useAxios();

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const onSubmit = useCallback(
    async ({ phone }: FormProps) => {
      setIsLoading(true);
      try {
        await axios.post(`/v2/clinics/${clinicId}/appointments`, {
          phone,
        });
        setIsSuccess(true);
      } catch (e) {
        if (e instanceof JSONApiErrorsException) {
          const parsedErrors = mapJSONApiErrors(e.errors, {
            phone: "data/attributes/phone",
          });

          for (const entry of Object.entries(parsedErrors)) {
            const error = entry[1];
            const key = entry[0] as keyof typeof parsedErrors;
            if (error?.title) {
              setError(key, {
                message: error.title,
              });
            }
          }
        }
      } finally {
        setIsLoading(false);
      }
    },
    [axios, clinicId, setError],
  );

  const { handler: handleSubmitImpl } = useHandleSubmitImpl(
    handleSubmit,
    onSubmit,
  );

  if (isSuccess)
    return (
      <AllSetContainer>
        <StyledTitle level="h3">
          <Trans>You&apos;re all set!</Trans>
        </StyledTitle>
        <Text>
          {isDoula ? (
            <Trans>
              We&apos;ll help you meet, to decide if it&apos;s a fit.
            </Trans>
          ) : (
            <Trans>
              The clinic will contact you soon to schedule an
              appointment.
            </Trans>
          )}
        </Text>
      </AllSetContainer>
    );

  return (
    <Container ref={containerRef}>
      <StyledTitle level="h3">
        {isDoula ? (
          <Trans>Want to connect?</Trans>
        ) : (
          <Trans>Want to book an appointment?</Trans>
        )}
      </StyledTitle>
      <Text>
        {isDoula ? (
          <Trans>
            We&apos;ll help you meet to decide if it&apos;s a fit
          </Trans>
        ) : (
          <Trans>
            We&apos;ll ask the clinic to contact you to schedule it.
          </Trans>
        )}
      </Text>
      <Form onSubmit={handleSubmitImpl}>
        <FormField
          error={errors.phone?.message}
          hideLabel
          label={t`phone`}
        >
          <Input
            {...register("phone")}
            autoComplete="phone"
            defaultValue={authSession?.user?.attributes.phone || ""}
            placeholder={t`Phone`}
            type="text"
          />
        </FormField>
        <ButtonWithLoader
          disabled={isLoading}
          isLoading={isLoading}
          kind="filledGreen"
          resetWidth
          type="submit"
        >
          <Trans>Sounds great!</Trans>
        </ButtonWithLoader>
        <Button kind="outlinedGreen" onClick={onCancel} resetWidth>
          <Trans>Cancel</Trans>
        </Button>
      </Form>
    </Container>
  );
};

export default BookAppointmentForm;
