import React, { FC, ReactNode, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";

export function buildQueryClient(): QueryClient {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });
}

const QueryClientProviderBound: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [queryClient] = useState(() => buildQueryClient());

  return (
    <QueryClientProvider client={queryClient}>
      {children}
    </QueryClientProvider>
  );
};

export default QueryClientProviderBound;
