import * as t from "io-ts";
import { HtmlBody, ImageSet } from "../../../io-ts/types";
import { Doctor } from "./Doctor";
import { Location } from "./Location";

export const ClinicC = t.type({
  attributes: t.type({
    body: HtmlBody,
    image_set: t.union([ImageSet, t.null]),
    practice_uid: t.union([t.string, t.null]),
    subtitle: t.string,
    title: t.string,
    url: t.union([t.string, t.null]),
  }),
  id: t.string,
  type: t.literal("clinic"),
});

export type ClinicEntity = t.TypeOf<typeof ClinicC>;

export interface ClinicEntityWithRelations extends ClinicEntity {
  relationships: {
    doctors: Doctor[];
    locations: Location[];
  };
}
