import getDevice from "ismobilejs";

const redirectLink = "/download-our-app";

const result = getDevice();
export const isMobile = result?.phone;
export const isInMobileApp = !!window.ReactNativeWebView;
const isJoinCallPage = window.location.pathname.startsWith("/join/");
const forceNoRedirect = !!window.localStorage.getItem(
  "FORCE_NO_REDIRECT",
);
const cookieNoRedirect = document.cookie?.indexOf("noredirect") > -1;
const isRedirectPage = window.location.pathname === redirectLink;
const shouldRedirect =
  isMobile &&
  !isRedirectPage &&
  !isJoinCallPage &&
  !isInMobileApp &&
  !forceNoRedirect &&
  !cookieNoRedirect;

if (shouldRedirect) {
  window.location.replace(redirectLink);
}
