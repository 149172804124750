import { Component, ReactNode } from "react";
import { findDOMNode as findDOMNodeBase } from "react-dom";

/**
 * Check if the top of the HTMLElement is in the viewport.
 */
export function topOfElementInViewport(element: HTMLElement) {
  const rect = element.getBoundingClientRect();
  return rect.top >= 0;
}

// TODO-APP: Replace with new React API for finding dom nodes without a `ref` when available
/**
 * Wraps ReactDOM.findDOMNode with additional logic to hide React Strict Mode warning
 */
function findDOMNode(
  instance: Parameters<typeof findDOMNodeBase>[0],
): ReturnType<typeof findDOMNodeBase> {
  // Only apply strict mode warning when not in production
  if (import.meta.env.NODE_ENV !== "production") {
    // eslint-disable-next-line no-console
    const originalConsoleError = console.error;
    try {
      // eslint-disable-next-line no-console
      console.error = (...messages) => {
        // Ignore strict mode warning for the findDomNode call below
        if (
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
          !messages[0].includes(
            "Warning: %s is deprecated in StrictMode.",
          )
        ) {
          originalConsoleError(...messages);
        }
      };
      return findDOMNodeBase(instance);
    } finally {
      // eslint-disable-next-line no-console,@typescript-eslint/no-non-null-assertion
      console.error = originalConsoleError!;
    }
  }
  return findDOMNodeBase(instance);
}

export default class ScrollHandler extends Component<{
  children: ReactNode;
}> {
  componentDidMount() {
    // eslint-disable-next-line react/no-find-dom-node
    const domNode = findDOMNode(this);

    if (domNode instanceof HTMLElement) {
      // Set focus on the element
      domNode.focus();
      const inViewport = topOfElementInViewport(domNode);
      // Only scroll into viewport when the layout is not visible currently.
      if (!inViewport) {
        const htmlElement = document.documentElement;
        const existing = htmlElement.style.scrollBehavior;
        htmlElement.style.scrollBehavior = "auto";
        // In Chrome-based browsers we need to force reflow before calling `scrollTo`.
        // Otherwise, it will not pick up the change in scrollBehavior
        // More info here: https://github.com/vercel/next.js/issues/40719#issuecomment-1336248042
        htmlElement.getClientRects();
        domNode.scrollIntoView();
        htmlElement.style.scrollBehavior = existing;
      }
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}
