import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors } from "../../constants";
import { Logo } from "../../lib/Logo";
import mediaQuery from "../../utils/mediaQuery";

type Props = {
  children: React.ReactNode;
};

const LogoLink = styled(Link)`
  display: block;
  margin-bottom: 45px;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      margin-bottom: 100px;
    `,
  )}
`;

const Container = styled.div`
  box-sizing: border-box;
  line-height: 1.6;
  margin: 0 auto;
  overflow-x: hidden;
  padding: 20px;
  position: relative;
  width: 100%;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      padding: 50px 20px;
      width: 1050px;
    `,
  )}

  h1 {
    margin-bottom: 30px;
  }

  h2 {
    margin-bottom: 25px;
  }

  h3 {
    margin-bottom: 20px;
  }

  a {
    color: ${colors.blue};
  }
`;

export const Part = styled.div`
  margin-bottom: 50px;
`;

const LegalPage: React.FC<Props> = ({ children }: Props) => (
  <Container>
    <LogoLink to="/">
      <Logo fill={colors.blueGreen} height={30} width={169} />
    </LogoLink>
    {children}
  </Container>
);

export default LegalPage;
