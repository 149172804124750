import { useState, createContext, ReactNode } from "react";
import styled from "styled-components";

const Announcer = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  clip: rect(0px, 0px, 0px, 0px);
  clip-path: inset(50%);
  overflow: hidden;
  white-space: nowrap;
  width: 1px;
  height: 1px;
`;

export const TitleAnnouncerContext = createContext(
  (title: string) => {},
);

export const TitleAnnouncerContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [pageTitle, setPageTitle] = useState("");

  return (
    <TitleAnnouncerContext.Provider value={setPageTitle}>
      {children}
      <Announcer aria-atomic="true" aria-live="assertive">
        {pageTitle}
      </Announcer>
    </TitleAnnouncerContext.Provider>
  );
};
