/* eslint-disable no-nested-ternary */
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { t, Trans } from "@lingui/macro";
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import ReCAPTCHABase from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import * as yup from "yup";
import ReCAPTCHA from "../../../../components/ReCAPTCHA";
import { colors } from "../../../../constants";
import Alert from "../../../../lib/Alert";
import FormField from "../../../../lib/forms/FormField";
import Input from "../../../../lib/forms/primitives/Input";
import LinkButton from "../../../../lib/LinkButton";
import PageTitle from "../../../../lib/PageTitle";
import { JSONApiErrorsException } from "../../../../network/jsonApi/core/JSONApiErrorsPayload";
import { useHandleSubmitImpl } from "../../../../utils/forms";
import mediaQuery from "../../../../utils/mediaQuery";
import renderError from "../../../../utils/renderError";
import useAuthSession from "../../../hooks/useAuthSession";
import Info from "../../Info";
import { EmailBlock, Header, StyledButton } from "../../styles";
import ChangeEmailBlock from "../ChangeEmailBlock";
import { Strategy } from "../CheckEmailForm/hooks";
import { useSignIn } from "./hooks";

const getResolver = () => {
  const FormSchema = yup.object().shape({
    password: yup.string().required(t`Password required`),
  });

  return yupResolver(FormSchema);
};

const StyledAlert = styled(Alert)``;

const ForgotPasswordButton = styled(LinkButton)`
  color: #7f7f7f;
  display: block;
  font-size: 15px;
  text-decoration: underline;
`;

const StyledForm = styled.form`
  ${mediaQuery(
    "desktopMedium",
    css`
      max-width: 355px;
    `,
  )}
  ${StyledButton} {
    margin-top: 30px;
  }
  ${ForgotPasswordButton} {
    margin-top: 8px;
  }
`;
const OktaForm = styled.form`
  ${mediaQuery(
    "greaterThanPhone",
    css`
      max-width: 355px;
    `,
  )}
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${StyledAlert} {
    display: block;
    margin-bottom: 8px;
  }
  ${Header} {
    display: block;
    margin-bottom: 32px;
    ${mediaQuery(
      "greaterThanPhone",
      css`
        margin-bottom: 40px;
      `,
    )}
  }
  ${StyledForm} {
    margin-top: 32px;
    ${mediaQuery(
      "greaterThanPhone",
      css`
        margin-top: 40px;
      `,
    )}
  }
`;

interface FormProps {
  password: string;
}

const OR = styled.div`
  align-items: center;
  display: flex;
  font-size: 13px;
  height: 40px;
  justify-content: center;
  line-height: 16px;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      max-width: 355px;
    `,
  )}
`;
const OktaButton = styled(StyledButton)`
  ::before {
    background: #fff;
    border: 5px solid currentColor;
    border-radius: 50%;
    content: " ";
    display: block;
    height: 10px;
    margin-inline-end: 9px;
    width: 10px;
  }
`;
export interface PasswordFormProps {
  email: string;
  strategies: Strategy[];
  onChangeEmail: () => void;
  onSignedIn: () => void;
  onCheckCode: (verificationToken: string) => void;
}
const PasswordForm: FC<PasswordFormProps> = (props) => {
  const {
    email,
    strategies,
    onChangeEmail,
    onSignedIn,
    onCheckCode,
  } = props;

  const { data: session } = useAuthSession();

  const navigate = useNavigate();
  const hasOkta = strategies.some((s) => s === "okta");
  const hasPassword = strategies.some((s) => s === "password");
  const hasBoth = hasOkta && hasPassword;

  const {
    isLoading,
    error: externalError,
    mutate: signIn,
    data,
  } = useSignIn();

  const resolver = useMemo(() => getResolver(), []);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormProps>({
    defaultValues: {
      password: "",
    },

    resolver,
  });

  useEffect(() => {
    if (data?.mode === "password") {
      onSignedIn();
    } else if (data?.mode === "2fa") {
      onCheckCode(data.verificationToken);
    }
  }, [data, onCheckCode, onSignedIn]);

  const recaptchaRef = useRef<ReCAPTCHABase>(null);

  const onSubmit = useCallback(
    ({
      password,
      recaptchaToken,
    }: {
      password: string;
      recaptchaToken: string;
    }) => {
      signIn({ email, password, recaptchaToken });
    },
    [email, signIn],
  );

  const { handler: handleSubmitImpl, error: internalError } =
    useHandleSubmitImpl(handleSubmit, onSubmit, recaptchaRef);
  const error = externalError || internalError;

  return (
    <Container>
      {!!error && (
        <StyledAlert status="error">
          {error instanceof JSONApiErrorsException
            ? error.errors.map((e) => e.title).join(";\n")
            : renderError(error)}
        </StyledAlert>
      )}
      <PageTitle>Enter password</PageTitle>
      <ChangeEmailBlock onChangeEmail={onChangeEmail} />
      <Header>
        <Trans>Welcome back</Trans>
      </Header>
      <EmailBlock>{email}</EmailBlock>
      {hasPassword && (
        <StyledForm onSubmit={handleSubmitImpl}>
          <input
            autoComplete="username"
            hidden
            name="username"
            readOnly
            type="text" // use-hidden-fields-for-implicit-information
            value={email}
          />
          <FormField
            error={errors.password?.message}
            hideLabel
            label={t`password`}
          >
            <Input
              {...register("password")}
              $activeColor={colors.blueGreen}
              autoComplete="current-password"
              placeholder={t`Password`}
              type="password"
            />
          </FormField>
          <ForgotPasswordButton
            onClick={(e) => {
              e.preventDefault();
              navigate("/password/new", {
                state: {
                  email,
                },
              });
            }}
          >
            <Trans>Forgot password?</Trans>
          </ForgotPasswordButton>
          <StyledButton
            $fullWidth={hasOkta}
            disabled={isLoading}
            kind="filledBlueGreen"
            type="submit"
          >
            <Trans>Sign in</Trans>
          </StyledButton>
        </StyledForm>
      )}
      {hasBoth && <OR>OR</OR>}
      {hasOkta && (
        <OktaForm
          action={`${
            import.meta.env.VITE_API_HOST || ""
          }/users/auth/okta`}
          method="post"
        >
          <input
            name="authenticity_token"
            type="hidden"
            value={session?.csrfToken || ""}
          />
          <OktaButton
            $fullWidth
            kind="outlinedBlueGreen"
            type="submit"
          >
            <Trans>Sign in with Okta</Trans>
          </OktaButton>
        </OktaForm>
      )}
      <ReCAPTCHA ref={recaptchaRef} />
      <Info />
    </Container>
  );
};

export default PasswordForm;
