import { Trans } from "@lingui/macro";
import { FC } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import {
  MobileButtonAndroid,
  MobileButtonIOS,
} from "../../../components/MobileApp/MobileAppButton";
import PageLayout, { Body } from "../../../components/PageLayout";
import { colors } from "../../../constants";
import { LogoColor } from "../../../lib/Logo";
import mediaQuery from "../../../utils/mediaQuery";

const Сontainer = styled.div`
  align-items: center;
  background-color: ${colors.brownLightGrey2};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  inset: 0;
  justify-content: center;
  padding: 0 20px 60px;
  position: fixed;
  width: 100%;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      padding: 0;
    `,
  )}
`;

const LogoContainer = styled.div`
  margin: 0 0 60px;
`;

const Message = styled.div`
  color: #000;
  font-size: 22px;
  line-height: 1.4;
  max-width: 550px;
  text-align: center;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      font-size: 30px;
    `,
  )}
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 0;
`;

const DownloadAppsPage: FC = () => {
  return (
    <PageLayout>
      <Body>
        <Сontainer>
          <LogoContainer>
            <Link to="/">
              <LogoColor />
            </Link>
          </LogoContainer>
          <Message>
            <Trans>
              To join the call, download Stork Club on your mobile
              phone and open the call link from your mobile phone
            </Trans>
          </Message>
          <Buttons>
            <MobileButtonIOS />
            <MobileButtonAndroid />
          </Buttons>
        </Сontainer>
      </Body>
    </PageLayout>
  );
};

export default DownloadAppsPage;
