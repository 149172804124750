import { Trans } from "@lingui/macro";
import { FC, HTMLAttributes } from "react";
import styled from "styled-components";
import Title from "../../../../lib/Title";
import Call from "../../../CallSubjects/Call";
import { CallEntityWithRelations } from "../../../CallSubjects/models/Call";
import { CallSubjectEntityWithRelations } from "../../../CallSubjects/models/CallSubject";
import Subject from "../../../CallSubjects/Subject";

const CallSubjectsTitle = styled(Title)``;

const Container = styled.div`
  ${CallSubjectsTitle} {
    margin-bottom: 20px;
  }
`;

export interface CallSubjectsProps
  extends HTMLAttributes<HTMLElement> {
  callSubjects: readonly CallSubjectEntityWithRelations[];
  calls: readonly CallEntityWithRelations[] | undefined;
}
const CallSubjects: FC<CallSubjectsProps> = ({
  callSubjects,
  calls,
  ...restProps
}) => {
  return (
    <Container {...restProps}>
      <CallSubjectsTitle level="h3" styleLevel="h4">
        <Trans>Ask a vetted expert</Trans>
      </CallSubjectsTitle>
      {calls?.map((call) => <Call key={call.id} {...call} />)}
      {callSubjects.map((callSubject) => (
        <Subject key={callSubject.id} {...callSubject} />
      ))}
    </Container>
  );
};

export default CallSubjects;
