import { t, Trans } from "@lingui/macro";
import React, { FC, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import styled, { css } from "styled-components";
import { colors, fonts } from "../../../../constants";
import { Title } from "../../../../lib";
import Button from "../../../../lib/Button";
import Checkbox, {
  CheckMarkBackPart,
  Label,
} from "../../../../lib/forms/primitives/Checkbox";
import { useAxios } from "../../../../network";
import { ENDPOINTS } from "../../../../network/endpoints";
import mediaQuery from "../../../../utils/mediaQuery";
import { goldenListMixin } from "../../../../utils/style";
import { Session } from "../../../hooks/useAuthSession";
import Layout from "../Layout";

const Content = styled.div`
  margin: 0 20px 0;
  ${mediaQuery(
    "phone",
    css`
      ${Button} {
        margin-bottom: 20px;
        width: 100%;
      }
    `,
  )};
`;

const StyledTitle = styled(Title)`
  ${mediaQuery(
    "phone",
    css`
      font-size: 24px;
      line-height: 29px;
      text-align: center;
    `,
  )}
`;

const StyledCheckbox = styled(Checkbox)``;
const CheckBoxBlock = styled.div`
  background: ${colors.brownLightGrey2};
  border-radius: 8px;
  color: ${colors.brownBlack};
  display: flex;
  font-family: ${fonts.main}, sans-serif;
  font-size: 15px;
  font-weight: 400;
  gap: 8px;
  line-height: 160%;
  padding: 10px;

  ${StyledCheckbox} {
    align-items: start;
    ${CheckMarkBackPart} {
      transform: translateY(calc((1.6em - 18px) / 2));
    }
    ${Label} {
      font-size: 15px;
    }
  }
`;

const FooterContent = styled.div`
  color: ${colors.brownGrey};
  font-size: 12px;
  line-height: 133%;
`;
const StyledLink = styled.a`
  color: ${colors.blue};
  margin-inline-end: 10px;
  text-decoration: underline;
`;

const Description = styled.div`
  color: ${colors.brownBlack};
  display: flex;
  flex-direction: column;
  font-family: ${fonts.main}, sans-serif;
  font-size: 15px;
  gap: 20px;
  line-height: 160%;

  b {
    color: ${colors.black};
    font-weight: 500;
  }

  ${goldenListMixin};
`;

const StyledLayout = styled(Layout)`
  ${StyledTitle} {
    margin-bottom: 9px;
    ${mediaQuery(
      "greaterThanPhone",
      css`
        margin-bottom: 30px;
      `,
    )}
  }
  ${CheckBoxBlock} {
    margin: 20px 0 20px;
    ${mediaQuery(
      "greaterThanPhone",
      css`
        margin: 30px 0 30px;
      `,
    )}
  }
`;

const ConsentToSharing: FC = () => {
  const [checked, setChecked] = useState(false);

  const axios = useAxios();
  const client = useQueryClient();

  const { mutate } = useMutation(
    async () => {
      await axios.put(ENDPOINTS.updateDataSharingConsent);
    },
    {
      onSuccess: () => {
        client.setQueryData<Session | undefined>(
          "cookie",
          (existingData) => {
            if (existingData?.user) {
              return {
                ...existingData,
                user: {
                  ...existingData.user,
                  attributes: {
                    ...existingData.user.attributes,
                    require_data_sharing_consent: false,
                  },
                },
              };
            }
            return existingData;
          },
        );
      },
    },
  );

  return (
    <StyledLayout
      footer={
        <FooterContent>
          <Trans>
            See more information in the{" "}
            <StyledLink
              href="https://joinstorkclub.com/legal/privacy_global"
              rel="noreferrer"
              target="_blank"
            >
              Global Privacy Notice
            </StyledLink>
          </Trans>
        </FooterContent>
      }
      title={t`Just before we start`}
    >
      <Content>
        <StyledTitle>
          <Trans>Consent for data use</Trans>
        </StyledTitle>
        <Description>
          <div>
            <Trans>
              When you use Stork Club’s services, you may voluntarily
              provide us with sensitive information about yourself,
              goals and healthcare needs when you think it&#39;s
              necessary, which may include:
            </Trans>
          </div>
          <ul>
            <li>
              <Trans>
                Your health information – such as your overall
                wellness, fertility status, issues or concerns,
                relevant medical history
              </Trans>
            </li>
            <li>
              <Trans>
                Your sexual orientation – within the context of your
                family planning and fertility goals
              </Trans>
            </li>
            <li>
              <Trans>
                Your race or ethnicity – where necessary for any
                medical or cultural reasons
              </Trans>
            </li>
          </ul>
          <div>
            <b>
              <Trans>
                We will use this shared information to provide you
                with more tailored and relevant services that meet
                your family planning needs.
              </Trans>
            </b>
          </div>
        </Description>
        <CheckBoxBlock>
          <StyledCheckbox
            checked={checked}
            label={t`By checking this box, you confirm that you have read,
              understood and consent to the use of your
              information as described.`}
            onChange={(e) => setChecked(e.currentTarget.checked)}
          />
        </CheckBoxBlock>
        <Button
          disabled={!checked}
          kind="filledCoral"
          onClick={() => mutate()}
          resetWidth
          type="button"
        >
          <Trans>Confirm</Trans>
        </Button>
      </Content>
    </StyledLayout>
  );
};

export default ConsentToSharing;
