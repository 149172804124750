import { Trans } from "@lingui/macro";
import React, { useCallback } from "react";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import useAuthSession from "../../authentication/hooks/useAuthSession";
import { colors } from "../../constants";
import Button from "../../lib/Button";
import { useMessagesApi } from "../../lib/messages";
import Title from "../../lib/Title";
import { JSONApiErrorsException } from "../../network/jsonApi/core/JSONApiErrorsPayload";
import { formatDateTimeShort } from "../../utils/date-fns";
import mediaQuery from "../../utils/mediaQuery";
import renderError from "../../utils/renderError";
import { useCancelCall } from "../CallBooking/hooks/useCancelCall";
import { CallEntityWithRelations } from "./models/Call";

const Container = styled.div`
  background-color: #fff;
  border: 1px solid ${colors.brownLightGrey1};
  border-radius: 10px;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  display: block;
  margin: 16px 0;
  padding: 20px;
  position: relative;

  ${mediaQuery(
    "desktopMedium",
    css`
      display: flex;
      margin: 26px 0;
      padding-block: 30px;
      padding-inline-end: 20px;
      padding-inline-start: 40px;
    `,
  )}
`;

const Info = styled.div`
  flex-grow: 1;
  line-height: 24px;
`;

const Date = styled.div`
  color: ${colors.green};
  font-size: 16px;
`;

const Images = styled.div`
  height: 66px;
  margin-inline-end: 20px;
  position: relative;
  width: 72px;
`;

const IMAGE_SIZE = `66px`;
const Image = styled.div`
  border-radius: 50%;
  height: ${IMAGE_SIZE};
  margin-inline-end: 10px;
  overflow: hidden;
  position: relative;
  width: ${IMAGE_SIZE};

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
`;

const Links = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  font-size: 18px;
  justify-content: space-between;
  margin: 16px 0 0;
  min-width: 158px;

  ${mediaQuery(
    "desktopMedium",
    css`
      align-items: initial;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin: 0;
    `,
  )}
`;

const CancelButton = styled(Button)`
  background: none;
  color: ${colors.brownGrey};
  font-size: 18px;

  &:hover,
  &:active {
    background: none;
    color: ${colors.brownGrey};
  }

  /* This is a required workaround to set padding: 0 only in <1200px device width */
  ${mediaQuery(
    "desktopSmall",
    css`
      padding: 0;
    `,
  )}

  ${mediaQuery(
    "tablet",
    css`
      padding: 0;
    `,
  )}

  ${mediaQuery(
    "phone",
    css`
      padding: 0;
    `,
  )}
`;

const Call: React.FC<CallEntityWithRelations> = ({
  attributes,
  relationships,
}: CallEntityWithRelations) => {
  const queryClient = useQueryClient();
  const { mutate } = useCancelCall();
  const messages = useMessagesApi();
  const session = useAuthSession();
  const timeZone = session.data?.user?.attributes.timezone;

  const onCancelClick = useCallback(() => {
    mutate(attributes.token, {
      onError: (error) => {
        messages.error({
          content:
            error instanceof JSONApiErrorsException && error.errors[0]
              ? error.errors[0].title
              : renderError(error),
        });
      },
      onSuccess: () => {
        // other pages
        queryClient
          .invalidateQueries("calls")
          // eslint-disable-next-line no-console
          .catch((err) => console.error(err));
        // this exact page
        queryClient
          .refetchQueries(["homeScreen"])
          // eslint-disable-next-line no-console
          .catch((err) => console.error(err));
        messages.success({
          content: (
            <Trans>Your call has been successfully canceled.</Trans>
          ),
        });
      },
    });
  }, [attributes.token, messages, mutate, queryClient]);

  return (
    <Container>
      <Images>
        <Image>
          {relationships.call_expert.attributes.image_set && (
            <img
              alt={relationships.call_expert.attributes.name}
              sizes={IMAGE_SIZE}
              src={
                relationships.call_expert.attributes.image_set.w256
              }
              srcSet={`${relationships.call_expert.attributes.image_set.w64} 64w, ${relationships.call_expert.attributes.image_set.w128} 128w, ${relationships.call_expert.attributes.image_set.w256} 256w`}
            />
          )}
        </Image>
      </Images>
      <Info>
        <Title css="margin-bottom: 3px" level="h3">
          Call with {relationships.call_expert.attributes.name}
          <br />
          {attributes.call_subject_type_name}
        </Title>
        <Date>
          <Trans>
            Scheduled for{" "}
            {timeZone &&
              formatDateTimeShort(attributes.booked_at, timeZone)}
          </Trans>
        </Date>
      </Info>
      <Links>
        <Button
          as={Link}
          kind="outlinedCoral"
          resetWidth
          to={`/schedule/calls/new?category=${attributes.call_subject_type}&expert=${relationships.call_expert.id}`}
        >
          <Trans>Reschedule</Trans>
        </Button>
        <CancelButton onClick={onCancelClick} resetWidth>
          <Trans>Cancel</Trans>
        </CancelButton>
      </Links>
    </Container>
  );
};

export default Call;
