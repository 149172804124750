import { MessageDescriptor } from "@lingui/core";
import { defineMessage } from "@lingui/macro";
import * as t from "io-ts";
import { ProfileStatusC } from "../../../../../components/FamilyStatusForm/types";
import { BackendLanguageCodeC } from "../../../../../locales/appLocale/config";

export const CompanyCurrencyC = t.string; // usd, eur, etc.

export const CompanyPlanTypeSingleMessageDescriptor = defineMessage({
  id: "CompanyPlanType.single", // custom message id so it can be better auto-translated
  message: "Single",
});
export const CompanyPlanTypeFamilyMessageDescriptor = defineMessage({
  id: "CompanyPlanType.family",
  message: "Family",
});
export const CompanyPlanTypePlusOneMessageDescriptor = defineMessage({
  id: "CompanyPlanType.plus_one",
  message: "Plus one",
});
export const CompanyPlanTypeC = new t.Type(
  "CompanyPlanType",
  (
    v,
  ): v is {
    label: MessageDescriptor;
    value: string;
  } =>
    typeof v === "object" &&
    v !== null &&
    "value" in v &&
    typeof v.value === "string",
  (input, context) => {
    if (typeof input !== "string") {
      return t.failure(input, context, "Expected string");
    }

    let result: {
      label: MessageDescriptor;
      value: string;
    };
    switch (input) {
      case "single":
        result = {
          label: CompanyPlanTypeSingleMessageDescriptor,
          value: "single",
        };
        break;
      case "family":
        result = {
          label: CompanyPlanTypeFamilyMessageDescriptor,
          value: "family",
        };
        break;
      case "plus_one":
        result = {
          label: CompanyPlanTypePlusOneMessageDescriptor,
          value: "plus_one",
        };
        break;
      default: {
        // eslint-disable-next-line no-console
        console.error(
          `CompanyPlanTypeC: unknown value '${input}', using it as a label`,
        );
        result = {
          label: {
            id: "CompanyPlanType.unknown", // we do not use macro here to do not let lingui extract it as we don't want to translate it
            message: input, // it will be picked up by i18n as a fallback in runtime
          },
          value: input,
        };
      }
    }

    return t.success(result);
  },
  (output) => {
    return output.value;
  },
);

const CompanyC = t.type({
  attributes: t.type({
    company_plan_types: t.array(CompanyPlanTypeC),
    country_currency: CompanyCurrencyC,
    default_language: t.string,
    languages: t.array(BackendLanguageCodeC),
    name: t.string,
    statuses: t.array(ProfileStatusC),
    uid: t.string,
  }),
  id: t.string,
  type: t.literal("company"),
});

// export type Company = t.TypeOf<typeof CompanyC>;

export const CompanyPlanC = t.type({
  attributes: t.type({
    name: t.string,
  }),
  id: t.string,
  type: t.literal("company_plan"),
});

export type CompanyPlan = t.TypeOf<typeof CompanyPlanC>;

export type CompanyEntity = t.TypeOf<typeof CompanyC>;
export interface CompanyEntityWithRelations extends CompanyEntity {
  relationships: {
    companyPlans: CompanyPlan[];
  };
}

export default CompanyC;
