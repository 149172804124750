import { useMemo } from "react";
import { useLocation } from "react-router-dom";

// https://v5.reactrouter.com/web/example/query-parameters
// A custom hook that builds on useLocation to parse the query string for you.
function useLocationQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default useLocationQuery;
