import { Trans } from "@lingui/macro";
import React from "react";
import styled from "styled-components";
import Icon from "../../lib/Icon";

const CookieYesIcon = styled(Icon)`
  height: 12px;
  opacity: 0.6;
  position: relative;
  top: -1px;
  width: 13px;
`;

const Button = styled.button`
  align-items: center;
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  gap: 4px;
  outline: none;
  padding: 0;
  text-decoration: underline;
`;

const CookieYesButton: React.FC = () => {
  const cookieSettingsClick = () => {
    const cookieYesButtonNode: HTMLElement = document.querySelector(
      ".cky-btn-revisit",
    ) as HTMLElement;
    if (cookieYesButtonNode) cookieYesButtonNode.click();
  };

  return (
    <>
      {import.meta.env.VITE_COOKIEYES_TOKEN ? (
        <Button onClick={cookieSettingsClick} type="button">
          <Trans>Cookie Settings</Trans>
          <CookieYesIcon type="cookieyes" />
        </Button>
      ) : null}
    </>
  );
};

export default CookieYesButton;
