import {
  AuthorizationEntityWithRelations,
  Authorization,
} from "../models/Authorization";
import {
  AuthorizationEvent,
  AuthorizationEventRaw,
} from "../models/AuthorizationEvent";
import { Service, ServiceRaw, ServiceState } from "../models/Service";
import { Status, StatusRaw } from "../models/Status";
import { getBill, getBillStatus, getBillCreatedAt } from "./getBill";

const filterEvent = (event: AuthorizationEventRaw): boolean =>
  [
    "created",
    "granted",
    "cancelled",
    "canceled",
    "processed",
    "requested",
    "rejected",
    "expired",
  ].includes(event.attributes.name);

export const getEventStatus = (type: StatusRaw): Status => {
  switch (type) {
    case "granted":
    case "processed":
      return "granted";
    case "requested":
    case "created":
      return "requested";
    case "rejected":
      return "rejected";
    case "expired":
      return "expired";
    case "cancelled":
    case "canceled":
      return "canceled";
    default:
      throw Error("unknown event status");
  }
};

const getServiceState = (raw: ServiceState): ServiceState => {
  switch (raw) {
    case "granted":
      return "granted";
    case "requested":
      return "requested";
    case "rejected":
      return "rejected";
    case "cancelled":
    case "canceled":
    default:
      return "canceled";
  }
};

const getService = (raw: ServiceRaw): Service => ({
  amount: raw.attributes.amount,
  bundleEquivalent: null,
  description: raw.attributes.description,
  id: raw.id,
  rate: raw.attributes.rate,
  serviceCode: raw.attributes.service_code,
  serviceName: raw.attributes.service_name,
  state: getServiceState(raw.attributes.state),
});

const sortByCreatedAt = (
  a: AuthorizationEvent,
  b: AuthorizationEvent,
) => b.createdAt.getTime() - a.createdAt.getTime();

const getEvent = (
  raw: AuthorizationEventRaw,
  authorizationUid: string,
  authReadAt: Date | null,
): AuthorizationEvent => {
  const createdAt = raw.attributes.created_at;

  return {
    createdAt,
    id: raw.id,
    isBill: false,
    isRead: !authReadAt || createdAt <= authReadAt,
    status: getEventStatus(raw.attributes.name),
    url: `/notifications/${authorizationUid}/${raw.id}`,
  };
};

export const getAuthorization = (
  raw: AuthorizationEntityWithRelations,
): Authorization => {
  const authReadAt = raw.attributes.unread_at;

  const events = raw.relationships.events
    .filter(filterEvent)
    .map((event) => getEvent(event, raw.attributes.uid, authReadAt))
    .sort(sortByCreatedAt);

  if (events.length === 0) {
    throw Error(`No valid events in authorization ${raw.id}`);
  }

  // at least one Granted event ?
  const hasGrantedEvent = events.some(
    (event) => event.status === "granted",
  );

  // filter events: remove duplicate statuses and remove Rejected if there is already Granted one
  const filteredEvents = events.reduce(
    (acc: AuthorizationEvent[], event) => {
      // has duplicate statuses
      const hasSameStatusEvents = acc.find(
        (i) => i.status === event.status,
      );
      if (hasSameStatusEvents) return acc;

      const isRejected = event.status === "rejected";
      if (isRejected && hasGrantedEvent) return acc;

      return [...acc, event];
    },
    [],
  );

  const result = {
    bill: raw.relationships.bill
      ? getBill(raw.relationships.bill)
      : undefined,
    bundleEquivalent: raw.attributes.bundle_equivalent,
    createdAt: raw.relationships.events[0].attributes.created_at,
    events: filteredEvents,
    expiredAt: raw.attributes.expired_at,
    id: raw.id,
    isSeen: !!raw.attributes.unread_at,
    maxRxCoverage: raw.attributes.max_rx_coverage || "", // TODO
    oopSpan: raw.attributes.oop_span,
    practiceName: raw.attributes.practice_name,
    practiceTemplate: raw.attributes.practice_template,
    serviceDate: raw.attributes.service_date,
    services: raw.relationships.services.map(getService),
    title: raw.attributes.title,
    uid: raw.attributes.uid,
    url: `/notifications/${raw.attributes.uid}`,
  };

  // TODO: bill event should come from api
  if (raw.relationships.bill && result.bill) {
    const billStatus = getBillStatus(raw.relationships.bill);
    const createdAt = getBillCreatedAt(result.bill, billStatus);
    const billEvent = {
      createdAt,
      id: `${raw.id}-bill`,
      isBill: true,
      isRead: !authReadAt || createdAt <= authReadAt,
      status: billStatus,
      url: `/notifications/${raw.attributes.uid}/${raw.id}-bill`,
    };
    result.events = [billEvent, ...result.events];
  }

  return result;
};
