import { Trans } from "@lingui/macro";
import * as React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors, fonts } from "../../../constants";
import { ReimbursementEventPreview } from "../../../reimbursements/components/Reimbursement/EventPreview";
import { MainPageReimbursement } from "../../../reimbursements/models/Reimbursement";
import { ReimbursementEvent } from "../../../reimbursements/models/ReimbursementEvent";
import mediaQuery from "../../../utils/mediaQuery";
import { Auth, Reimb } from "../../hooks/useGetMainPageNotifications";
import { Authorization } from "../../models/Authorization";
import { AuthorizationEvent } from "../../models/AuthorizationEvent";
import { NotificationPreview } from "../NotificationPreview";

const Container = styled.section`
  background: #fff;
  border: 1px solid ${colors.brownLightGrey1};
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px 10px 10px;

  ${mediaQuery(
    "desktopMedium",
    css`
      padding: 40px 60px;
    `,
  )}
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  ${mediaQuery(
    "desktopMedium",
    css`
      padding: 0;
    `,
  )}
`;

const ViewAll = styled(Link)`
  color: ${colors.blue};
  font-size: 15px;
  line-height: 32px;
  ${mediaQuery(
    "desktopMedium",
    css`
      margin-inline-end: 5px;
    `,
  )}
`;

const Title = styled.h1`
  display: flex;
  font-family: ${fonts.alt};
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
  margin-bottom: 20px;
  margin-top: 0;
`;

export type Props = {
  items: Array<Auth | Reimb>;
};

const MainPageWidget: React.FC<Props> = ({ items }: Props) => {
  function isReimbursementAuth(
    authorization: Authorization | MainPageReimbursement,
  ): authorization is MainPageReimbursement {
    return "totalRequestedAmount" in authorization;
  }

  function isReimbursementEvent(
    event: AuthorizationEvent | ReimbursementEvent,
  ): event is ReimbursementEvent {
    return "userEvent" in event;
  }

  function isReimbursement(item: Auth | Reimb): item is Reimb {
    return (
      isReimbursementAuth(item.authorization) &&
      isReimbursementEvent(item.event)
    );
  }

  return (
    <Container aria-labelledby="NotificationsMainPage_Title">
      <Top>
        <Title id="NotificationsMainPage_Title">
          <Trans>Notifications</Trans>
        </Title>
        <ViewAll to="/notifications">
          <Trans>View all</Trans>
        </ViewAll>
      </Top>
      {items.map((item) => {
        if (isReimbursement(item)) {
          return (
            <ReimbursementEventPreview
              key={`${item.authorization.id}_${item.event.id}`}
              event={item.event}
              reimbursement={item.authorization}
            />
          );
        }

        return (
          <NotificationPreview
            key={`${item.authorization.id}_${item.event.id}`}
            authorization={item.authorization}
            event={item.event}
          />
        );
      })}
    </Container>
  );
};

export default MainPageWidget;
