/* eslint-disable react/no-unescaped-entities */
/* eslint max-lines: 0 */
import React from "react";
import Title from "../../../lib/Title";
import LegalPage, { Part } from "../LegalPage";

const Privacy: React.FC = () => (
  <LegalPage>
    <Title level="h1">Privacy Policy</Title>
    <p>Effective date: February 8, 2024</p>

    <Part>
      <p>
        At Stork Club, we take the respect and protection of your
        privacy and security very seriously. We are committed to
        following and continuously evolving best practices to support
        this principle.
      </p>
      <p>
        We – meaning Stork Club Fertility, Inc., doing business as
        Stork Club (also "Stork Cub," "our" or "us") – prepared this
        Privacy Policy to help you understand our practices with
        respect to the collection, use, and disclosure of information
        we collect from you through Stork Club’s web or mobile
        applications (the "Stork Club Products"), our websites that
        link to this Privacy Policy, and any other services we provide
        to our Customers or end Users (collectively with the Stork
        Club Products, the "Services").
      </p>
      <p>
        By accessing or using the Services, you agree to this Privacy
        Policy, our HIPAA Notice and our Terms of Service. Any
        conflict between this Privacy Policy and the HIPAA Notice with
        respect to any PHI (as defined in the HIPAA Notice) shall be
        governed by the HIPAA Notice.
      </p>
    </Part>

    <Part>
      <Title level="h2">What This Privacy Policy Covers</Title>
      <Title level="h3">User Information – In General.</Title>
      <p>
        This Privacy Policy covers how we use and disclose personally
        identifiable information that we gather from you starting from
        the time that you initially access our Site or our Mobile App
        or begin using our Services. Personally identifiable
        information refers to any information about you that can be
        used to contact or identify you and information on your use or
        potential use of the Site, our Mobile App or our Services,
        including, but not limited to, a first and last name, a
        personal profile, an email address or other contact
        information and all User Submissions (collectively, “User
        Information”). It is important for you to understand, however,
        that this policy does not apply to the practices of companies
        and/or websites or other third party services that we do not
        own or control, or to individuals that we do not employ or
        manage.
      </p>
      <Title level="h3">
        Protected Health Information (PHI) — Health-related data.
      </Title>
      <p>
        PHI is Personal Information that we receive as part of your
        account activation and administration on Stork Club that
        relates to
      </p>
      <ul>
        <li>
          your past, present or future physical or mental health or
          condition,
        </li>
        <li>the provision of healthcare to you, or</li>
        <li>
          your past, present or future payment for the provision of
          healthcare, which is created, received, transmitted or
          maintained by Stork Club.
        </li>
      </ul>
      <p>
        This Privacy Policy describes how we protect your privacy as a
        visitor to or general user of our Services. You have
        additional rights under federal and state law with respect to
        the access to, use, and disclosure of Personal Information
        that constitutes PHI. For a more complete description of your
        rights with respect to PHI, please refer to our HIPAA Notice,
        which provides important information to you about how we may
        use and disclose your PHI. Our HIPAA Notice is available{" "}
        <a href="/legal/hipaa">here</a>.
      </p>
    </Part>

    <Part>
      <Title level="h2">What Information We Collect</Title>
      <p>
        What types of information we collect from you depends on how
        you use the Services, what Services you use, and what
        information you choose to provide or make available to us. We
        collect information from you when you:
      </p>
      <ul>
        <li>
          Create or register an account, and when you administer your
          account
        </li>
        <li>
          Input information or data into any of our Services, or post
          or upload Content to our Services
        </li>
        <li>
          Submit questions, requests, other communications to us via
          forms, email, or other communication media
        </li>
        <li>
          Contact our third-party providers via forms or video calls
          via Stork Club
        </li>
        <li>Contact us for User support or technical support</li>
        <li>Visit any of our websites or download any of our apps</li>
        <li>
          Participate in any promotions, demonstrations, contests,
          surveys, or other marketing events
        </li>
        <li>
          Interact with other Stork Club Users and guests on our
          community forums
        </li>
      </ul>
      <Title level="h3">User Information</Title>
      <p>
        Information related to the activation and creation of accounts
        or that otherwise identifies you as an end User of Stork Club
        is what we call "User Information." User Information may
        include certain "personally identifiable information" or
        "PII", such as your name, email address, gender, postal
        address and phone number. It also may include payment
        information, such as payment method, credit card information,
        and any contact information (such as name and postal address)
        associated with payment billing information. If you are an end
        User using the Services through an account created on your
        behalf by a Stork Club User (such as an employer, organization
        of which you are a member or another individual), we may
        collect and process User Information about you on behalf of
        the Stork Club User with whom your use of the Services is
        associated.
      </p>
      <p>
        When you create an account with Stork Club, we may collect
        certain User Information directly from you or, if you create
        your account using a third-party service (such as Google or a
        single sign-on service such as Okta), we may collect User
        Information about you from the third-party service (such as
        your Username or User ID associated with that third-party
        service). By choosing to create an account using a third-party
        service, you authorize us to collect User Information
        necessary to authenticate your account with the third-party
        service provider.
      </p>
      <p>
        Certain aspects of the Services include integrated third-party
        products and services to enable certain features and
        functionalities with the Services. For example, you can find
        links to other third-party benefits and healthcare providers.
        If you choose to use these features or functionalities, you
        may be asked to create an account with a third-party service
        provider or login with your existing account with that service
        provider (and, by doing so, agree to the privacy policy and/or
        terms and conditions of that third-party). You may also be
        asked to authorize Stork Club to collect information from the
        third-party service provider on your behalf. We will then
        collect information (such as your Username or User ID
        associated with that third-party service) from you and/or that
        third-party service provider as necessary to enable the
        Services to access your data and content stored with that
        third-party service provider. Once the authentication is
        complete, we have the ability to access the information you
        provided to us or was otherwise collected by the third-party
        service in accordance with the privacy practices of that
        third-party service. We will store the information and data we
        collect and associate it with your Stork Club account, and we
        will only use that information and data to enable the
        integration of the Services with the third-party service
        provider and to perform actions requested or initiated by you,
        or that are reasonably necessary to carry out instructions
        provided by you.
      </p>
      <p>
        PLEASE NOTE: By using the Services, you consent to and
        authorize Stork Club to disclose your eligibility for and
        participation in the Services (i.e., you are interested in
        using family-related benefits and your chosen current status)
        to the other users of the Services. The users, including but
        not limited to administrators, care partners, vetted experts
        and other authorized Stork Club personnel, and your fellow
        Community members, will have access to a range of Personal
        Information such as your username and picture, linking you to
        your current family goal/status and location.
      </p>
      <Title level="h3">Agents</Title>
      <p>
        We employ other companies and people to perform tasks on our
        behalf and need to share your information with them to provide
        products and/or services to you. Without specific
        authorization and/or consent, we limit the rights of our
        agents to use Personal Information we share with them to that
        which is minimally necessary to assist us. You hereby consent
        to our sharing of Personal Information for the above purposes.
      </p>
      <Title level="h3">Content</Title>
      <p>
        Any information you choose to provide or upload to the group
        or public forum components (our Community) of the Services,
        including Personal Information about your health and medical
        condition, will be visible to your group, as well as health
        coaches, administrators, and other users. As your information
        will be viewable to the other users of the Site, the Mobile
        App and the Services, you should provide only the information
        you feel comfortable disclosing. There may be an opportunity
        to speak on the phone or via video chat in a group conference
        call.
      </p>
      <p>
        Participation in such opportunities is not mandatory, but
        should you choose to participate, you should share only as
        much information as you feel comfortable sharing in these
        additional forums.
      </p>
      <Title level="h3">
        Information Collected Automatically Through Your Use of the
        Services
      </Title>
      <p>
        We collect information about how you use the Services and your
        actions on the Services, including IP addresses, what software
        and hardware you use (such as browser types, operating
        systems, ISPs, platform type, device type, mobile device
        identifiers such as make and model, mobile carrier), pages or
        features of Stork Club used and associated dates and time
        stamps, search terms, links you click, whether you open
        messages sent to your account, and other statistics related to
        your usage of the Services. We may also use tools, including
        third-party tools, to collect analytics data. Some of this
        information is collected through the use of "cookies" and
        other tracking technologies, such as web beacons, session
        replay scripts, and similar technologies ("tracking
        technologies"). We may also work with third-party partners to
        employ tracking technologies.
      </p>
      <p>
        We can share collected information with your Employer (the
        party that sponsors your account) only in anonymized format.
        If you would prefer not to accept cookies or otherwise wish to
        disable our use of tracking technologies, you may set your
        browser to refuse or disable these data collection methods,
        but doing so may change your experience with the Services.
      </p>
      <Title level="h3">Location Information</Title>
      <p>
        We collect limited location information: your zip code if
        you’re logged in and limited location information if you’re
        not. For example, we convert your IP address into a rough
        geolocation to understand in what areas our Users are located
        and to improve and personalize Stork Club (for example, to
        display time data in the correct local time).
      </p>
      <Title level="h3">Aggregated and Anonymized Information</Title>
      <p>
        We may collect information by aggregating and anonymizing
        other information. The aggregation and anonymization process
        prevents the information from being reassociated or identified
        with any one User account, User, or individual. We may use
        aggregated and anonymized information for a wide variety of
        statistical and analytical purposes.
      </p>
    </Part>

    <Part>
      <Title level="h2">How We Use the Information We Collect</Title>
      <p>
        We use the information we collect for a variety of purposes,
        and how we use it depends on what we collect and what Services
        you use (or features of the Services). These purposes may
        include:
      </p>
      <ul>
        <li>Providing the Services to you</li>
        <li>Responding to requests or inquiries from you</li>
        <li>Providing User support or technical assistance</li>
        <li>
          Contacting you to provide product updates, information about
          products you have requested or purchased
        </li>
        <li>
          Marketing Stork Club products, services and features that
          you may be interested in, and monitoring the performance of
          our advertisements and marketing efforts
        </li>
        <li>
          Creating or administering your account, including
          identifying you with your account or the account of a Stork
          Club User
        </li>
        <li>Managing your benefits and payments</li>
        <li>
          Deriving market insights, ways to improve the Services, and
          other business analysis or research purposes
        </li>
        <li>
          Customizing existing and future product offerings and other
          aspects of the Services to you and other Users
        </li>
        <li>
          Securing the Services and our systems, and protecting your
          information and data
        </li>
        <li>
          Sharing with third parties for the purposes discussed in
          "How we share information", below
        </li>
        <li>
          Any legitimate business purpose, provided that the
          information is aggregated and anonymized
        </li>
      </ul>
      <Title level="h3">Personally Identifiable Information</Title>
      <p>
        Specifically, we may use personally identifiable information
        (including those contained within the User Information or
        otherwise collected by us) for the above purposes, but only as
        to the extent necessary for the purposes for which you have
        provided us with the information, to respond to inquiries or
        requests (including requests for User support or technical
        assistance), to otherwise perform our obligations or act
        consistently with our Terms of Service, to respond to law
        enforcement or other governmental or legal authorities, and to
        otherwise use the information consistent with your
        instructions to us (both explicit, such as when you contact us
        directly with a request, and implied, such as when you engage
        us to provide you with the Services). If you wish to change
        these instructions, either by request, by correcting,
        amending, or deleting information we may have collected about
        you, or by opting out of certain data collection practices,
        please see "Managing Your Information", below.
      </p>
      <Title level="h3">How We Share Information</Title>
      <p>
        We disclose information we collect in the following cases:
      </p>
      <ul>
        <li>
          With vendors we engage to provide you with aspects of the
          Services, such as data storage, hosting, communication.
        </li>
        <li>
          With third-party service providers who enable certain
          aspects of the Services that you’ve requested, such as
          vetted by Stork Club experts, in-network clinics, care
          navigators (for example, by scheduling a call with one of
          our experts in Stork Club using the Talk to experts
          feature).
        </li>
        <li>
          With vendors we engage to help us gain insights and
          analytics into how the Services are used and how they might
          be improved (for example, we may use third-party data
          analytics services).
        </li>
        <li>
          As necessary to comply with applicable law, including
          governmental requests, law enforcement requests, and
          otherwise to protect the rights, privacy, safety, or
          property of you, us, or others.
        </li>
        <li>
          As necessary in the event of a proposed or actual
          reorganization, merger, sale, joint venture, assignment,
          transfer, financing, or other disposition of all or any
          portion of Stork Club business, assets, or stock.
        </li>
        <li>
          With others for any legitimate business purpose, provided
          the information is aggregated and anonymized.
        </li>
      </ul>
      <Title level="h3">How We Secure Your Information</Title>
      <p>
        We implement appropriate technical and organizational measures
        to protect the information we collect and store.
        Unfortunately, no security measures are 100% foolproof, and as
        such no network or system (including ours) can be guaranteed
        to be 100% secure against destruction, loss, alteration,
        unauthorized disclosure of, or access to information we
        collect and store. If you believe your information may not be
        secure for any reason, please contact us immediately at{" "}
        <a href="mailto:legal@joinstorkclub.com" target="blank">
          legal@joinstorkclub.com
        </a>
        .
      </p>
      <Title level="h3">Managing Your Information</Title>
      <p>
        You may access, correct, amend, or delete User Information we
        have about you by logging into your account and navigating to
        your Profile page. If you wish to cancel your account, you may
        do so by sending us an email at{" "}
        <a href="mailto:care@joinstorkclub.com" target="blank">
          care@joinstorkclub.com
        </a>
        . If you do, personally identifiable information associated
        with your account will be deleted as soon as is reasonably
        practical or as required by applicable law. Please note that
        we may retain information that is otherwise deleted in
        anonymized and aggregated form, in archived or backup copies
        as required pursuant to records retention obligations, or
        otherwise as required by law.
      </p>
      <p>
        We may use some of the information we collect for marketing
        purposes, including to send you promotional communications
        about new Stork Club features, products, events, or other
        opportunities. If you wish to stop receiving these
        communications or to opt out of use of your information for
        these purposes, please follow the opt-out instructions by
        clicking "Unsubscribe" (or similar opt-out language) in those
        communications. You can also contact us at{" "}
        <a href="mailto:legal@joinstorkclub.com" target="blank">
          legal@joinstorkclub.com
        </a>{" "}
        to opt out.
      </p>
      <p>
        You may access, correct, amend, or delete your Content by
        accessing it within Stork Club Community. You control all
        Content you upload. Content you delete (including Content
        containing personal information) may be retained in archived
        or backup copies in order to enable you to use certain
        features like revision history and base snapshots. For
        instructions on how to permanently delete Content from your
        Stork Club Community account, please contact us at{" "}
        <a href="mailto:legal@joinstorkclub.com" target="blank">
          legal@joinstorkclub.com
        </a>
        . Please note that permanent deletion of Content through this
        process will impair or disable those features with respect to
        that Content.
      </p>
      <Title level="h3">
        Cookies and tracking technologies – how to opt out
      </Title>
      <p>
        If you would prefer not to accept cookies or otherwise wish to
        disable our use of tracking technologies, most browsers and
        mobile devices allow you to change your settings so as to
        notify you when you receive cookies or other tracking
        technologies are being used, and to choose whether or not to
        accept/allow it. Most browsers also allow you to disable or
        delete existing cookies or to automatically reject future
        cookies. Disabling cookies may limit your ability to use the
        Services.
      </p>
      <p>
        Certain tracking technologies we use involve the use of
        session replay scripts or other scripts that run directly from
        your browser. If you wish to disable these tracking
        technologies, you can install a third-party browser analytics
        blocker plug-in such as Ghostery.
      </p>
      <p>
        If you have any questions about how we or our third-party
        service providers use cookies or other tracking technologies
        that aren’t answered in this Privacy Policy, please contact us
        at{" "}
        <a href="mailto:legal@joinstorkclub.com" target="blank">
          legal@joinstorkclub.com
        </a>
        .
      </p>
      <Title level="h3">
        International transfers (including transfers outside of the
        European Union)
      </Title>
      <p>
        We process and store information on servers located in the
        United States, and we may store information on servers and
        equipment in other countries depending on a variety of
        factors, including the locations of our Users and service
        providers. By using the Services, you consent to the transfer
        of information (including User Information, Content, and
        personally identifiable information) to locations that may be
        outside of your country of residence, including the United
        States. You acknowledge and agree that, as a condition of
        providing any Content, you can legally transfer it to the
        United States or another country.
      </p>
      <p>
        With respect to transfers of information out of the European
        Union (EU), we may process some personally identifiable
        information pursuant to data processing agreements that
        include the EU Standard Contractual Clauses. To learn more
        about our data processing agreements, please contact us at{" "}
        <a href="mailto:legal@joinstorkclub.com" target="blank">
          legal@joinstorkclub.com
        </a>
        .
      </p>
      <Title level="h3">Information from children</Title>
      <p>
        Stork Club is not directed to children under the age of 13 and
        we do not knowingly collect personally identifiable
        information from children under the age of 13. If we learn
        that we have collected personally identifiable information of
        a child under the age 13, we will take reasonable steps to
        delete such information from our files as soon as is
        practicable. Please contact us at{" "}
        <a href="mailto:legal@joinstorkclub.com" target="blank">
          legal@joinstorkclub.com
        </a>{" "}
        if you believe we have any information from or about a child
        under the age of 13.
      </p>
    </Part>

    <Part>
      <Title level="h2">Changes to Terms</Title>
      <p>
        Any information that we collect is subject to the Privacy
        Policy in effect at the time such information is collected. We
        may, however, revise the Privacy Policy from time to time. If
        a revision is material, as determined solely by us, we will
        notify you, for example via email. The current version will
        always be posted to our Privacy Policy page.
      </p>
    </Part>

    <Title level="h2">Contact us</Title>
    <p>
      If you have any questions about this Privacy Policy, please
      contact us at{" "}
      <a href="mailto:legal@joinstorkclub.com" target="blank">
        legal@joinstorkclub.com
      </a>
      .
    </p>
  </LegalPage>
);

export default Privacy;
