/* eslint-disable @typescript-eslint/ban-ts-comment,max-lines */
import { i18n } from "@lingui/core";
import * as t from "io-ts";
import { messages as sourceLocaleMessages } from "../../translations/en/messages.po";
import normalizeLocaleName from "./normalizeLanguageName";
import { Locales } from "./types";

i18n.load("en", sourceLocaleMessages);
/**
 * translations mapping.
 * Keys here are name of files we store in Lokalize
 */
const gettextFiles = {
  ar: () =>
    import("../../translations/ar/messages.po").then(
      (m) => m.messages,
    ),
  bg: () =>
    import("../../translations/bg/messages.po").then(
      (m) => m.messages,
    ),
  "bs-BA": () =>
    import("../../translations/bs-BA/messages.po").then(
      (m) => m.messages,
    ),
  cs: () =>
    import("../../translations/cs/messages.po").then(
      (m) => m.messages,
    ),
  da: () =>
    import("../../translations/da/messages.po").then(
      (m) => m.messages,
    ),
  de: () =>
    import("../../translations/de/messages.po").then(
      (m) => m.messages,
    ),
  el: () =>
    import("../../translations/el/messages.po").then(
      (m) => m.messages,
    ),
  "el-CY": () =>
    import("../../translations/el-CY/messages.po").then(
      (m) => m.messages,
    ),
  en: () => Promise.resolve(sourceLocaleMessages),
  es: () =>
    import("../../translations/es/messages.po").then(
      (m) => m.messages,
    ),
  et: () =>
    import("../../translations/et/messages.po").then(
      (m) => m.messages,
    ),
  fi: () =>
    import("../../translations/fi/messages.po").then(
      (m) => m.messages,
    ),
  fil: () =>
    import("../../translations/fil/messages.po").then(
      (m) => m.messages,
    ),
  fr: () =>
    import("../../translations/fr/messages.po").then(
      (m) => m.messages,
    ),
  he: () =>
    import("../../translations/he/messages.po").then(
      (m) => m.messages,
    ),
  hi: () =>
    import("../../translations/hi/messages.po").then(
      (m) => m.messages,
    ),
  hr: () =>
    import("../../translations/hr/messages.po").then(
      (m) => m.messages,
    ),
  "ht-HT": () =>
    import("../../translations/ht-HT/messages.po").then(
      (m) => m.messages,
    ),
  hu: () =>
    import("../../translations/hu/messages.po").then(
      (m) => m.messages,
    ),
  id: () =>
    import("../../translations/id/messages.po").then(
      (m) => m.messages,
    ),
  is: () =>
    import("../../translations/is/messages.po").then(
      (m) => m.messages,
    ),
  it: () =>
    import("../../translations/it/messages.po").then(
      (m) => m.messages,
    ),
  ja: () =>
    import("../../translations/ja/messages.po").then(
      (m) => m.messages,
    ),
  "km-KH": () =>
    import("../../translations/km-KH/messages.po").then(
      (m) => m.messages,
    ),
  ko: () =>
    import("../../translations/ko/messages.po").then(
      (m) => m.messages,
    ),
  lb: () =>
    import("../../translations/lb/messages.po").then(
      (m) => m.messages,
    ),
  lt: () =>
    import("../../translations/lt/messages.po").then(
      (m) => m.messages,
    ),
  lv: () =>
    import("../../translations/lv/messages.po").then(
      (m) => m.messages,
    ),
  ms: () =>
    import("../../translations/ms/messages.po").then(
      (m) => m.messages,
    ),
  "mt-MT": () =>
    import("../../translations/mt-MT/messages.po").then(
      (m) => m.messages,
    ),
  nl: () =>
    import("../../translations/nl/messages.po").then(
      (m) => m.messages,
    ),
  no: () =>
    import("../../translations/no/messages.po").then(
      (m) => m.messages,
    ),
  pl: () =>
    import("../../translations/pl/messages.po").then(
      (m) => m.messages,
    ),
  pt: () =>
    import("../../translations/pt/messages.po").then(
      (m) => m.messages,
    ),
  ro: () =>
    import("../../translations/ro/messages.po").then(
      (m) => m.messages,
    ),
  sk: () =>
    import("../../translations/sk/messages.po").then(
      (m) => m.messages,
    ),
  sl: () =>
    import("../../translations/sl/messages.po").then(
      (m) => m.messages,
    ),
  sr: () =>
    import("../../translations/sr/messages.po").then(
      (m) => m.messages,
    ),
  sv: () =>
    import("../../translations/sv/messages.po").then(
      (m) => m.messages,
    ),
  sw: () =>
    import("../../translations/sw/messages.po").then(
      (m) => m.messages,
    ),
  th: () =>
    import("../../translations/th/messages.po").then(
      (m) => m.messages,
    ),
  tr: () =>
    import("../../translations/tr/messages.po").then(
      (m) => m.messages,
    ),
  vi: () =>
    import("../../translations/vi/messages.po").then(
      (m) => m.messages,
    ),
  "zh-CN": () =>
    import("../../translations/zh-CN/messages.po").then(
      (m) => m.messages,
    ),
  "zh-HK": () =>
    import("../../translations/zh-HK/messages.po").then(
      (m) => m.messages,
    ),
  "zh-TW": () =>
    import("../../translations/zh-TW/messages.po").then(
      (m) => m.messages,
    ),
};

export type GettextLocales = keyof typeof gettextFiles;
export const applicationLocales = {
  "ar-AE": {
    flag: "🇦🇪",
    messages: gettextFiles.ar,
    title: "Arabic",
  },
  "bg-BG": {
    flag: "🇧🇬",
    messages: gettextFiles.bg,
    title: "Bulgarian",
  },
  "bs-BA": {
    flag: "🇧🇦",
    messages: gettextFiles["bs-BA"],
    title: "Bosnian",
  },
  "cs-CZ": {
    flag: "🇨🇿",
    messages: gettextFiles.cs,
    title: "Czech",
  },
  "da-DK": {
    flag: "🇩🇰",
    messages: gettextFiles.da,
    title: "Danish",
  },
  "de-DE": {
    flag: "🇩🇪",
    messages: gettextFiles.de,
    title: "German",
  },
  "el-CY": {
    flag: "🇨🇾",
    messages: gettextFiles["el-CY"],
    title: "Greek (Cyprus)",
  },
  "el-GR": {
    flag: "🇬🇷",
    messages: gettextFiles.el,
    title: "Greek",
  },
  "en-AU": {
    flag: "🇦🇺",
    messages: gettextFiles.en,
    title: "English (AU)",
  },
  "en-CA": {
    flag: "🇨🇦",
    messages: gettextFiles.en,
    title: "English (CA)",
  },
  "en-GB": {
    flag: "🇬🇧",
    messages: gettextFiles.en,
    title: "English (UK)",
  },
  "en-NZ": {
    flag: "🇳🇿",
    messages: gettextFiles.en,
    title: "English (NZ)",
  },
  "en-SG": {
    flag: "🇸🇬",
    messages: gettextFiles.en,
    title: "English (SG)",
  },
  "en-US": {
    flag: "🇺🇸",
    messages: gettextFiles.en,
    title: "English (US)",
  },
  "es-ES": {
    flag: "🇪🇸",
    messages: gettextFiles.es,
    title: "Spanish",
  },
  "es-MX": {
    flag: "🇲🇽",
    messages: gettextFiles.es,
    title: "Spanish (MX)",
  },
  "et-EE": {
    flag: "🇪🇪",
    messages: gettextFiles.et,
    title: "Estonian",
  },
  "fi-FI": {
    flag: "🇫🇮",
    messages: gettextFiles.fi,
    title: "Finnish",
  },
  "fil-PH": {
    flag: "🇵🇭",
    messages: gettextFiles.fil,
    title: "Filipino",
  },
  "fr-FR": {
    flag: "🇫🇷",
    messages: gettextFiles.fr,
    title: "French",
  },
  "he-IL": {
    flag: "🇮🇱",
    messages: gettextFiles.he,
    title: "Hebrew",
  },
  "hi-IN": {
    flag: "🇮🇳",
    messages: gettextFiles.hi,
    title: "Hindi",
  },
  "hr-HR": {
    flag: "🇭🇷",
    messages: gettextFiles.hr,
    title: "Croatian",
  },
  "ht-HT": {
    flag: "🇭🇹",
    messages: gettextFiles["ht-HT"],
    title: "Haitian Creole",
  },
  "hu-HU": {
    flag: "🇭🇺",
    messages: gettextFiles.hu,
    title: "Hungarian",
  },
  "id-ID": {
    flag: "🇮🇩",
    messages: gettextFiles.id,
    title: "Indonesian",
  },
  "is-IS": {
    flag: "🇮🇸",
    messages: gettextFiles.is,
    title: "Icelandic",
  },
  "it-IT": {
    flag: "🇮🇹",
    messages: gettextFiles.it,
    title: "Italian",
  },
  "ja-JP": {
    flag: "🇯🇵",
    messages: gettextFiles.ja,
    title: "Japanese",
  },
  "km-KH": {
    flag: "🇰🇭",
    messages: gettextFiles["km-KH"],
    title: "Khmer",
  },
  "ko-KR": {
    flag: "🇰🇷",
    messages: gettextFiles.ko,
    title: "Korean",
  },
  "lb-LU": {
    flag: "🇱🇺",
    messages: gettextFiles.lb,
    title: "Luxembourgish",
  },
  "lt-LT": {
    flag: "🇱🇹",
    messages: gettextFiles.lt,
    title: "Lithuanian",
  },
  "lv-LV": {
    flag: "🇱🇻",
    messages: gettextFiles.lv,
    title: "Latvian",
  },
  "ms-MY": {
    flag: "🇲🇾",
    messages: gettextFiles.ms,
    title: "Malay",
  },
  "mt-MT": {
    flag: "🇲🇹",
    messages: gettextFiles["mt-MT"],
    title: "Maltese",
  },
  "nl-BE": {
    flag: "🇧🇪",
    messages: gettextFiles.nl,
    title: "Dutch (BE)",
  },
  "nl-NL": {
    flag: "🇳🇱",
    messages: gettextFiles.nl,
    title: "Dutch",
  },
  "no-NO": {
    flag: "🇳🇴",
    messages: gettextFiles.no,
    title: "Norwegian",
  },
  "pl-PL": {
    flag: "🇵🇱",
    messages: gettextFiles.pl,
    title: "Polish",
  },
  "pt-BR": {
    flag: "🇧🇷",
    messages: gettextFiles.pt,
    title: "Portuguese (BR)",
  },
  "ro-RO": {
    flag: "🇷🇴",
    messages: gettextFiles.ro,
    title: "Romanian",
  },
  "sk-SK": {
    flag: "🇸🇰",
    messages: gettextFiles.sk,
    title: "Slovak",
  },
  "sl-SI": {
    flag: "🇸🇮",
    messages: gettextFiles.sl,
    title: "Slovenian",
  },
  "sr-RS": {
    flag: "🇷🇸",
    messages: gettextFiles.sr,
    title: "Serbian",
  },
  "sv-SE": {
    flag: "🇸🇪",
    messages: gettextFiles.sv,
    title: "Swedish",
  },
  "sw-KE": {
    flag: "🇰🇪",
    messages: gettextFiles.sw,
    title: "Swahili",
  },
  "th-TH": {
    flag: "🇹🇭",
    messages: gettextFiles.th,
    title: "Thai",
  },
  "tr-TR": {
    flag: "🇹🇷",
    messages: gettextFiles.tr,
    title: "Turkish",
  },
  "vi-VN": {
    flag: "🇻🇳",
    messages: gettextFiles.vi,
    title: "Vietnamese",
  },
  "zh-CN": {
    flag: "🇨🇳",
    messages: gettextFiles["zh-CN"],
    title: "Chinese (CN)",
  },
  "zh-HK": {
    flag: "🇭🇰",
    messages: gettextFiles["zh-HK"],
    title: "Chinese (HK)",
  },
  "zh-TW": {
    flag: "🇹🇼",
    messages: gettextFiles["zh-TW"],
    title: "Chinese (TW)",
  },
} satisfies Locales;
export type ApplicationLocaleName = keyof typeof applicationLocales;

export interface SwitcherLocale {
  getLabel: () => string; // getter allows us to translate it on the fly
  getShortLabel: () => string; // getter allows us to translate it on the fly
  fullCode: ApplicationLocaleName;
  sendbirdLocale: string; // https://sendbird.com/docs/chat/platform-api/v3/message/translations/translation-engine
  recaptchaLocale: string; // https://developers.google.com/recaptcha/docs/language
}

export type CommonLocaleName =
  ApplicationLocaleName extends `${infer L}-${string}` ? L : never;

/**
 * If browser returns a locale code with a region code that we do not support directly we can try to use a fallback locale.
 */
export const fallbackToCommonRules = {
  ar: "ar-AE" as const,
  bg: "bg-BG" as const,
  bs: "bs-BA" as const,
  cs: "cs-CZ" as const,
  da: "da-DK" as const,
  de: "de-DE" as const,
  el: "el-GR" as const,
  en: "en-US" as const,
  es: "es-ES" as const,
  et: "et-EE" as const,
  fi: "fi-FI" as const,
  fil: "fil-PH" as const,
  fr: "fr-FR" as const,
  he: "he-IL" as const,
  hi: "hi-IN" as const,
  hr: "hr-HR" as const,
  ht: "ht-HT" as const,
  hu: "hu-HU" as const,
  id: "id-ID" as const,
  is: "is-IS" as const,
  it: "it-IT" as const,
  ja: "ja-JP" as const,
  km: "km-KH" as const,
  ko: "ko-KR" as const,
  lb: "lb-LU" as const,
  lt: "lt-LT" as const,
  lv: "lv-LV" as const,
  ms: "ms-MY" as const,
  mt: "mt-MT" as const,
  nl: "nl-NL" as const,
  no: "no-NO" as const,
  pl: "pl-PL" as const,
  pt: "pt-BR" as const,
  ro: "ro-RO" as const,
  sk: "sk-SK" as const,
  sl: "sl-SI" as const,
  sr: "sr-RS" as const,
  sv: "sv-SE" as const,
  sw: "sw-KE" as const,
  th: "th-TH" as const,
  tr: "tr-TR" as const,
  vi: "vi-VN" as const,
  zh: "zh-CN" as const,
} satisfies {
  [key in CommonLocaleName]: ApplicationLocaleName;
};

export type FallbackRules = typeof fallbackToCommonRules;

/**
 * Locales that we can show in the language switcher.
 *
 * Keys are `CommonLocaleName` for now - backend anyway does not support regional locales.
 * UPD: chinese locales are exceptional, as we need to use more specific approach on them.
 *
 * This map contains all the languages. In actual UI this list is filtered by a companies settings.
 */
export const languageSwitcherLocalesMap = {
  ar: {
    fullCode: fallbackToCommonRules.ar,
    getLabel: () => "العربية",
    getShortLabel: () => "AR",
    recaptchaLocale: "ar",
    sendbirdLocale: "ar",
  },
  bg: {
    fullCode: fallbackToCommonRules.bg,
    getLabel: () => "Български",
    getShortLabel: () => "BG",
    recaptchaLocale: "bg",
    sendbirdLocale: "bg",
  },
  "bs-BA": {
    fullCode: fallbackToCommonRules.bs,
    getLabel: () => "Bosanski",
    getShortLabel: () => "BS",
    recaptchaLocale: "en",
    sendbirdLocale: "bs",
  },
  cs: {
    fullCode: fallbackToCommonRules.cs,
    getLabel: () => "Čeština",
    getShortLabel: () => "CS",
    recaptchaLocale: "cs",
    sendbirdLocale: "cs",
  },
  da: {
    fullCode: fallbackToCommonRules.da,
    getLabel: () => "Dansk",
    getShortLabel: () => "DA",
    recaptchaLocale: "da",
    sendbirdLocale: "da",
  },
  de: {
    fullCode: fallbackToCommonRules.de,
    getLabel: () => "Deutsch",
    getShortLabel: () => "DE",
    recaptchaLocale: "de",
    sendbirdLocale: "de",
  },
  el: {
    fullCode: fallbackToCommonRules.el,
    getLabel: () => "Ελληνικά",
    getShortLabel: () => "EL",
    recaptchaLocale: "el",
    sendbirdLocale: "el",
  },
  "el-CY": {
    fullCode: "el-CY" as const,
    getLabel: () => "Ελληνικά (Κύπρος)",
    getShortLabel: () => "EL",
    recaptchaLocale: "el",
    sendbirdLocale: "el",
  },
  en: {
    fullCode: fallbackToCommonRules.en,
    getLabel: () => "English",
    getShortLabel: () => "EN",
    recaptchaLocale: "en",
    sendbirdLocale: "en",
  },
  es: {
    fullCode: fallbackToCommonRules.es,
    getLabel: () => "Español",
    getShortLabel: () => "ES",
    recaptchaLocale: "es",
    sendbirdLocale: "es",
  },
  et: {
    fullCode: fallbackToCommonRules.et,
    getLabel: () => "Eesti",
    getShortLabel: () => "ET",
    recaptchaLocale: "et",
    sendbirdLocale: "et",
  },
  fi: {
    fullCode: fallbackToCommonRules.fi,
    getLabel: () => "Suomi",
    getShortLabel: () => "FI",
    recaptchaLocale: "fi",
    sendbirdLocale: "fi",
  },
  fil: {
    fullCode: fallbackToCommonRules.fil,
    getLabel: () => "Filipino",
    getShortLabel: () => "FIL",
    // !!! Sendbird uses `tl` for Filipino
    recaptchaLocale: "tl",
    sendbirdLocale: "tl",
  },
  fr: {
    fullCode: fallbackToCommonRules.fr,
    getLabel: () => "Français",
    getShortLabel: () => "FR",
    recaptchaLocale: "fr",
    sendbirdLocale: "fr",
  },
  he: {
    fullCode: fallbackToCommonRules.he,
    getLabel: () => "עברית",
    getShortLabel: () => "HE",
    recaptchaLocale: "iw",
    sendbirdLocale: "he", // !!! Recaptcha uses `iw` for Hebrew
  },
  hi: {
    fullCode: fallbackToCommonRules.hi,
    getLabel: () => "हिन्दी",
    getShortLabel: () => "HI",
    recaptchaLocale: "hi",
    sendbirdLocale: "hi",
  },
  hr: {
    fullCode: fallbackToCommonRules.hr,
    getLabel: () => "Hrvatski",
    getShortLabel: () => "HR",
    recaptchaLocale: "hr",
    sendbirdLocale: "hr",
  },
  "ht-HT": {
    fullCode: fallbackToCommonRules.ht,
    getLabel: () => "Kreyòl Ayisyen",
    getShortLabel: () => "ht-HT",
    recaptchaLocale: "en",
    sendbirdLocale: "ht",
  },
  hu: {
    fullCode: fallbackToCommonRules.hu,
    getLabel: () => "Magyar",
    getShortLabel: () => "HU",
    recaptchaLocale: "hu",
    sendbirdLocale: "hu",
  },
  id: {
    fullCode: fallbackToCommonRules.id,
    getLabel: () => "Bahasa Indonesia",
    getShortLabel: () => "ID",
    recaptchaLocale: "id",
    sendbirdLocale: "id",
  },
  is: {
    fullCode: fallbackToCommonRules.is,
    getLabel: () => "Íslenska",
    getShortLabel: () => "IS",
    recaptchaLocale: "is",
    sendbirdLocale: "is",
  },
  it: {
    fullCode: fallbackToCommonRules.it,
    getLabel: () => "Italiano",
    getShortLabel: () => "IT",
    recaptchaLocale: "it",
    sendbirdLocale: "it",
  },
  ja: {
    fullCode: fallbackToCommonRules.ja,
    getLabel: () => "日本語",
    getShortLabel: () => "JA",
    recaptchaLocale: "ja",
    sendbirdLocale: "ja",
  },
  "km-KH": {
    fullCode: fallbackToCommonRules.km,
    getLabel: () => "ភាសាខ្មែរ",
    getShortLabel: () => "KM-KH",
    recaptchaLocale: "en",
    sendbirdLocale: "km",
  },
  ko: {
    fullCode: fallbackToCommonRules.ko,
    getLabel: () => "한국어",
    getShortLabel: () => "KO",
    recaptchaLocale: "ko",
    sendbirdLocale: "ko",
  },
  lb: {
    fullCode: fallbackToCommonRules.lb,
    getLabel: () => "Lëtzebuergesch",
    getShortLabel: () => "LB",
    recaptchaLocale: "en",
    sendbirdLocale: "lb",
  },
  lt: {
    fullCode: fallbackToCommonRules.lt,
    getLabel: () => "Lietuvių",
    getShortLabel: () => "LT",
    recaptchaLocale: "lt",
    sendbirdLocale: "lt",
  },
  lv: {
    fullCode: fallbackToCommonRules.lv,
    getLabel: () => "Latviešu",
    getShortLabel: () => "LV",
    recaptchaLocale: "lv",
    sendbirdLocale: "lv",
  },
  ms: {
    fullCode: fallbackToCommonRules.ms,
    getLabel: () => "Bahasa Melayu",
    getShortLabel: () => "MS",
    recaptchaLocale: "ms",
    sendbirdLocale: "ms",
  },
  "mt-MT": {
    fullCode: fallbackToCommonRules.mt,
    getLabel: () => "Malti",
    getShortLabel: () => "MT",
    recaptchaLocale: "en",
    sendbirdLocale: "mt",
  },
  nl: {
    fullCode: fallbackToCommonRules.nl,
    getLabel: () => "Nederlands",
    getShortLabel: () => "NL",
    recaptchaLocale: "nl",
    sendbirdLocale: "nl",
  },
  no: {
    fullCode: fallbackToCommonRules.no,
    getLabel: () => "Norsk",
    getShortLabel: () => "NO",
    recaptchaLocale: "no",
    sendbirdLocale: "no",
  },
  pl: {
    fullCode: fallbackToCommonRules.pl,
    getLabel: () => "Polski",
    getShortLabel: () => "PL",
    recaptchaLocale: "pl",
    sendbirdLocale: "pl",
  },
  pt: {
    fullCode: fallbackToCommonRules.pt,
    getLabel: () => "Português",
    getShortLabel: () => "PT",
    recaptchaLocale: "pt",
    sendbirdLocale: "pt",
  },
  ro: {
    fullCode: fallbackToCommonRules.ro,
    getLabel: () => "Română",
    getShortLabel: () => "RO",
    recaptchaLocale: "ro",
    sendbirdLocale: "ro",
  },
  sk: {
    fullCode: fallbackToCommonRules.sk,
    getLabel: () => "Slovenčina",
    getShortLabel: () => "SK",
    recaptchaLocale: "sk",
    sendbirdLocale: "sk",
  },
  sl: {
    fullCode: fallbackToCommonRules.sl,
    getLabel: () => "Slovenščina",
    getShortLabel: () => "SL",
    recaptchaLocale: "sl",
    sendbirdLocale: "sl",
  },
  sr: {
    fullCode: fallbackToCommonRules.sr,
    getLabel: () => "Српска",
    getShortLabel: () => "SR",
    recaptchaLocale: "sr",
    sendbirdLocale: "sr",
  },
  sv: {
    fullCode: fallbackToCommonRules.sv,
    getLabel: () => "Svenska",
    getShortLabel: () => "SV",
    recaptchaLocale: "sv",
    sendbirdLocale: "sv",
  },
  sw: {
    fullCode: fallbackToCommonRules.sw,
    getLabel: () => "Kiswahili",
    getShortLabel: () => "SW",
    recaptchaLocale: "sw",
    sendbirdLocale: "sw",
  },
  th: {
    fullCode: fallbackToCommonRules.th,
    getLabel: () => "ไทย",
    getShortLabel: () => "TH",
    recaptchaLocale: "th",
    sendbirdLocale: "th",
  },
  tr: {
    fullCode: fallbackToCommonRules.tr,
    getLabel: () => "Türkçe",
    getShortLabel: () => "TR",
    recaptchaLocale: "tr",
    sendbirdLocale: "tr",
  },
  vi: {
    fullCode: fallbackToCommonRules.vi,
    getLabel: () => "Tiếng Việt",
    getShortLabel: () => "VI",
    recaptchaLocale: "vi",
    sendbirdLocale: "vi",
  },
  "zh-CN": {
    fullCode: "zh-CN",
    getLabel: () => "中文(简体)",
    getShortLabel: () => "ZH-CN",
    recaptchaLocale: "zh-CN",
    sendbirdLocale: "zh-CN",
  },
  "zh-HK": {
    fullCode: "zh-HK",
    getLabel: () => "中文(香港)",
    getShortLabel: () => "ZH-HK",
    // Sendbird does not support HK according to the [docs](https://sendbird.com/docs/chat/platform-api/v3/message/translations/translation-engine). TW is the closest.
    recaptchaLocale: "zh-HK",
    sendbirdLocale: "zh-TW",
  },
  "zh-TW": {
    fullCode: "zh-TW",
    getLabel: () => "中文(繁體)",
    getShortLabel: () => "ZH-TW",
    recaptchaLocale: "zh-TW",
    sendbirdLocale: "zh-TW",
  },
} satisfies Record<string, SwitcherLocale>;

export type LanguageSwitcherLocalesMap =
  typeof languageSwitcherLocalesMap;

/**
 * Spoken languages that we can show in the experts/doula profile. It can have locales we do not offer in the language switcher.
 */
export const spokenLanguagesLocalesMap = {
  ...(Object.fromEntries(
    Object.entries(languageSwitcherLocalesMap).map(
      ([key, record]) => {
        return [key, { getLabel: () => record.getLabel() }];
      },
    ),
  ) as Record<
    keyof LanguageSwitcherLocalesMap,
    { getLabel: () => string }
  >),
  ru: { getLabel: () => "Русский" },
} satisfies Record<string, { getLabel: () => string }>;

export type SpokenLanguagesLocalsMap =
  typeof languageSwitcherLocalesMap;

export const SpokenLanguageCodeC = new t.Type(
  "spokenLanguageCode",
  (v): v is string => typeof v === "string",
  (input, context) => {
    if (typeof input !== "string") {
      return t.failure(input, context, "Not a string");
    }
    const normalized = normalizeLocaleName(input);
    if (normalized in spokenLanguagesLocalesMap) {
      return t.success(normalized);
    }
    return t.failure(input, context);
  },
  (output) => {
    return normalizeLocaleName(output).replace("-", "_");
  },
);

/**
 * If we do not have translation for a language we use this locale.
 */
export const sourceLocaleCode = fallbackToCommonRules.en;

export const BackendLanguageCodeC = new t.Type(
  "languageCode",
  (v): v is string => typeof v === "string",
  (input, context) => {
    if (typeof input !== "string") {
      return t.failure(input, context, "Not a string");
    }
    let normalized = normalizeLocaleName(input);
    // FIXME: temporary workaround for "ru" locale in the backend response
    if (normalized === "ru") {
      normalized = "en";
    }
    if (normalized in languageSwitcherLocalesMap) {
      return t.success(normalized);
    }
    return t.failure(input, context);
  },
  (output) => {
    return normalizeLocaleName(output).replace("-", "_");
  },
);

export function getSwitcherLocale(
  fullCode: string,
): SwitcherLocale | null {
  return (
    Object.entries(languageSwitcherLocalesMap).find(
      ([, locale]) => locale.fullCode === fullCode,
    )?.[1] ?? null
  );
}
