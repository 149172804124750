import { Trans } from "@lingui/macro";
import { FC, Fragment, HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../../constants";
import Alert from "../../../../lib/Alert";
import Markdown from "../../../../lib/Markdown";
import Icon from "../../../../lib/SharedIcon";
import Title from "../../../../lib/Title";
import {
  spokenLanguagesLocalesMap,
  SpokenLanguagesLocalsMap,
} from "../../../../locales/appLocale/config";
import { signLangsMap } from "../../../../locales/appLocale/config/signLanguages";
import { formatDayAndMonthTZ } from "../../../../utils/date-fns";
import { CareNavigator } from "../../../CallSubjects/models/CareNavigator";
import { cardStylesMixin } from "../../cardStylesMixin";
import { useAvailableCallDates } from "../../hooks/useAvailableCallDates";

export interface ExpertCardProps extends HTMLAttributes<HTMLElement> {
  expert: CareNavigator;
  selected: boolean;
  timeZone: string;
}

const PHOTO_SIZE = `60px`;
const PhotoContainer = styled.div`
  border-radius: 30px;
  flex-shrink: 0;
  float: left;
  font-size: 0;
  height: ${PHOTO_SIZE};
  margin-inline-end: 15px;
  overflow: hidden;
  position: relative;
  width: ${PHOTO_SIZE};
`;
const Photo = styled.img`
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
`;
const ExpertRole = styled.span`
  color: ${colors.brownBlack};
  font-size: 15px;
  margin: 6px 0 3px;
`;
const ExpertName = styled(Title)``;
const ExpertData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: revert;
`;
const Header = styled.header`
  border-bottom: 1px solid ${colors.brownLightGrey1};
  column-gap: 15px;
  display: grid;
  grid-template-columns: 60px 1fr;
  margin-bottom: 17px;
  padding-bottom: 17px;
`;

const StyledReactMarkdown = styled(Markdown)`
  color: ${colors.brownBlack};
  margin: 0;
  & > p {
    margin: 0;
  }
`;
const ExpertInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 15px;
  gap: 20px;
  line-height: 24px;
  margin-bottom: 30px;
`;

const Footer = styled.footer`
  align-items: center;
  border-top: 1px solid ${colors.brownLightGrey1};
  bottom: 13px;
  box-sizing: border-box;
  color: ${colors.coral};
  column-gap: 20px;
  display: grid;
  font-size: 15px;
  grid-template-columns: auto 1fr;
  padding-inline-start: 19px;
  padding-top: 13px;
`;
const Container = styled.div`
  ${cardStylesMixin};
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  padding: 20px 0 13px 0;
  padding-inline-end: 40px;
  padding-inline-start: 30px;

  ${Footer} {
    margin-inline-end: -40px;
    margin-inline-start: -30px;
  }

  ${({ tabIndex }) =>
    tabIndex === 0
      ? css`
          &:hover,
          &:focus-visible {
            padding: 19px 0 12px 0;
            padding-inline-end: 39px;
            padding-inline-start: 29px;
          }
        `
      : undefined}

  ${(props) =>
    props["aria-checked"]
      ? css`
          padding-block-end: 12px;
          padding-block-start: 19px;
          padding-inline-end: 39px;
          padding-inline-start: 29px;
        `
      : undefined}
`;

const ExpirienceLanguagesGrid = styled.div`
  display: grid;
  grid-template-columns: calc(50% - 7.5px) calc(50% - 7.5px);
  gap: 15px;
`;

const ExpertCard: FC<ExpertCardProps> = ({
  expert,
  selected,
  timeZone,
  onClick,
  ...restProps
}) => {
  const { data, isLoading, error } = useAvailableCallDates(
    expert.id,
    timeZone,
    1,
  );

  const available: boolean | null = data ? data.length > 0 : null;

  const spokenLanguages = expert.attributes.spoken_languages
    .filter(
      (code): code is keyof SpokenLanguagesLocalsMap =>
        code in spokenLanguagesLocalesMap,
    )
    .map((code) => spokenLanguagesLocalesMap[code].getLabel());

  const signLanguages = expert.attributes.sign_languages
    .filter(
      (code): code is keyof typeof signLangsMap =>
        code in signLangsMap,
    )
    .map((code) => signLangsMap[code].getLabel());

  const languages = [...spokenLanguages, ...signLanguages];

  // FIXME: originally not available experts disappeared from the list (display: none), but it is against accessibility standards. We need to discuss it with Sasha Korobov
  //    Maybe we should delay showing the page while we do not know all the slots. It would be either with using Suspense
  return (
    <Container
      {...restProps}
      aria-disabled={!available}
      aria-labelledby={`expert-card-${expert.id}`}
      onClick={
        available
          ? onClick
          : () => {
              // eslint-disable-next-line no-console
              console.warn("not available");
            }
      }
      tabIndex={available ? 0 : -1}
    >
      <Header>
        <PhotoContainer>
          {expert.attributes.image_set && (
            <Photo
              alt={`${expert.attributes.name}`}
              sizes={PHOTO_SIZE}
              src={expert.attributes.image_set.w128}
              srcSet={`${expert.attributes.image_set.w64} 64w, ${expert.attributes.image_set.w128} 128w, ${expert.attributes.image_set.w256} 256w`}
            />
          )}
        </PhotoContainer>
        <ExpertData>
          <ExpertRole>{expert.attributes.role}</ExpertRole>
          <ExpertName id={`expert-card-${expert.id}`} level="h3">
            {expert.attributes.name}
          </ExpertName>
        </ExpertData>
      </Header>
      <ExpertInfo>
        {expert.attributes.body && (
          <div>
            <Title level="h4">
              <Trans>Can help you with</Trans>
            </Title>
            <StyledReactMarkdown text={expert.attributes.body} />
          </div>
        )}
        {expert.attributes.education && (
          <div>
            <Title level="h4">
              <Trans>Education</Trans>
            </Title>
            <StyledReactMarkdown text={expert.attributes.education} />
          </div>
        )}
        <ExpirienceLanguagesGrid>
          {expert.attributes.experience && (
            <div>
              <Title level="h4">
                <Trans>Experience</Trans>
              </Title>
              <StyledReactMarkdown
                text={expert.attributes.experience}
              />
            </div>
          )}
          {languages.length > 0 && (
            <div>
              <Title level="h4">
                <Trans>Languages</Trans>
              </Title>
              {languages.join(", ")}
            </div>
          )}
        </ExpirienceLanguagesGrid>
      </ExpertInfo>
      <Footer>
        <span>
          <Trans>Next available:</Trans>
        </span>{" "}
        {isLoading ? (
          <Icon type="spinner" />
        ) : (
          <>
            {error && (
              <Alert status="error">
                <Trans>Not available</Trans>
              </Alert>
            )}
            {!error && !!data && (
              <span>
                {data.length === 0 && (
                  <Alert status="info">
                    <Trans>Not available</Trans>
                  </Alert>
                )}
                {data.length > 0 &&
                  formatDayAndMonthTZ(data[0], timeZone, "MMMM dd")}
              </span>
            )}
          </>
        )}
      </Footer>
    </Container>
  );
};

export default ExpertCard;
