import { Trans } from "@lingui/macro";
import React from "react";
import styled, { css } from "styled-components";
import Skeleton from "../../lib/Skeleton";
import Title from "../../lib/Title";
import mediaQuery from "../../utils/mediaQuery";
import { goldenListMixin } from "../../utils/style";
import Call from "./Call";
import { useGetCallCategory } from "./hooks/useGetCallCategory";
import Subject from "./Subject";

type TabProps = {
  id: string;
};

const Container = styled.div`
  max-width: var(--content-max-width);
  padding: 30px var(--content-margin-side) 0;

  ${mediaQuery(
    "desktopMedium",
    css`
      padding-top: 50px;
    `,
  )}

  ${mediaQuery(
    "phone", // Is required to show some gap above mobile footer
    css`
      padding-bottom: 50px;
    `,
  )}

  ${Title} {
    margin-bottom: 10px;

    ${mediaQuery(
      "desktopMedium",
      css`
        margin-bottom: 26px;
      `,
    )}
  }
`;

const Text = styled.div`
  line-height: 24px;
  margin-bottom: 46px;
  max-width: 650px;
  ${goldenListMixin}
`;

const Calls = styled.div`
  margin-bottom: 50px;
`;

const Tab: React.FC<TabProps> = ({ id }: TabProps) => {
  const { data, isLoading } = useGetCallCategory(id);

  return (
    <Container>
      <Skeleton active loading={isLoading}>
        {data ? (
          <>
            <Text>
              <Title as="h2" level="h4">
                <Trans>How Stork Club finds experts for you</Trans>
              </Title>
              <p>
                <Trans>
                  When it comes to you and your child’s health, you
                  want to speak with the best people. So do we. All
                  Stork Club experts were carefully chosen by our team
                  based on these requirements:
                </Trans>
              </p>
              <ul>
                <li>
                  <Trans>
                    Certification and official confirmation of all
                    necessary training
                  </Trans>
                </li>
                <li>
                  <Trans>
                    Over 3 years of patient experience with
                    outstanding reviews
                  </Trans>
                </li>
                <li>
                  <Trans>
                    Passion for their profession and compassion for
                    moms and moms-to-be
                  </Trans>
                </li>
              </ul>
            </Text>
            {data.relationships.future_calls.length > 0 && (
              <Calls>
                <Title level="h2">
                  <Trans>Your appointments with experts</Trans>
                </Title>
                {data.relationships.future_calls.map((item) => (
                  <Call key={item.id} {...item} />
                ))}
              </Calls>
            )}
            <Title level="h2">
              <Trans>Get answers to your questions today</Trans>
            </Title>
            {data.relationships.call_subjects.map((item) => (
              <Subject key={item.id} {...item} />
            ))}
          </>
        ) : null}
      </Skeleton>
    </Container>
  );
};

export default Tab;
