import { Trans } from "@lingui/macro";
import { format as formatDate } from "date-fns";
import { FC } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors } from "../../../../constants";
import Button from "../../../../lib/Button";
import mediaQuery from "../../../../utils/mediaQuery";
import { Authorization } from "../../../models/Authorization";
import { Bill } from "../../../models/Bill";
import { UserPlan } from "../../../models/UserPlan";
import { BillDetails } from "./BillDetails";
import {
  ButtonContainer,
  DueDate,
  DueDateContainer,
} from "./BodyBillAvailable";
import { WhyAmIGettingThisBill } from "./WhyAmIGettingThisBill";

type Props = {
  bill: Bill;
  authorization: Authorization;
  userPlan: UserPlan;
};

const InitialRow = styled.div`
  color: ${colors.coral};
  margin-bottom: 20px;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 10px;
    `,
  )}
`;

export const BillPaymentFailed: FC<Props> = (props: Props) => {
  const { bill, authorization, userPlan } = props;
  const { paymentDueDate, url } = bill;

  return (
    <>
      <InitialRow>
        <Trans>
          There was an error while processing your payment. <br />
          Please ensure that you have sufficient funds in your account
          and try again.
        </Trans>
      </InitialRow>
      <BillDetails
        authorization={authorization}
        bill={bill}
        userPlan={userPlan}
      />
      <ButtonContainer>
        <Button as={Link} kind="filledCoral" to={url}>
          <Trans>Pay now</Trans>
        </Button>
      </ButtonContainer>
      {paymentDueDate && (
        <DueDateContainer>
          <Trans>Due date</Trans>{" "}
          <DueDate>
            {formatDate(paymentDueDate, "MM-dd-yyyy")}
          </DueDate>
        </DueDateContainer>
      )}
      <WhyAmIGettingThisBill />
    </>
  );
};
