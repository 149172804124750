import { FC, SVGAttributes } from "react";

const Question: FC<SVGAttributes<SVGElement>> = ({
  ...restProps
}) => {
  return (
    <svg
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <circle cx="13" cy="13" fill="#FF7468" id="Oval" r="13" />
      <path
        d="M12.1843262,8.84033203 L13.8156738,8.84033203 L13.6887207,14.9785156 L12.3112793,14.9785156 L12.1843262,8.84033203 Z M12.9968262,18.1269531 C12.482666,18.1269531 12.076416,17.7270508 12.076416,17.2255859 C12.076416,16.7241211 12.482666,16.3305664 12.9968262,16.3305664 C13.517334,16.3305664 13.923584,16.7241211 13.923584,17.2255859 C13.923584,17.7270508 13.517334,18.1269531 12.9968262,18.1269531 Z"
        fill="black"
      />
    </svg>
  );
};

export default Question;
