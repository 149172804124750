import React from "react";
import { useParams } from "react-router-dom";
import PageTitle from "../../../lib/PageTitle";
import { useGetArticlesCategory } from "../hooks/useGetArticles";
import useGetCategories from "../hooks/useGetCategories";
import Items from "../Items";
import { ArticleCategory } from "../models/ArticleCategory";

// returns category name by its slug
const getCategoryName = (
  slug: string,
  list?: ArticleCategory[],
): string => {
  if (!list) return "";

  const category = list.find((item) => item.slug === slug);
  if (!category) return "";

  return category.name;
};

const CategoryBlock = () => {
  const { category = "" } = useParams<{
    category: string;
  }>();

  const { data: categoriesList } = useGetCategories();
  const categoryName = getCategoryName(category, categoriesList);

  const { data, isLoading } = useGetArticlesCategory(category);

  return (
    <>
      <PageTitle>{categoryName}</PageTitle>
      <Items data={data} isLoading={isLoading} title={categoryName} />
    </>
  );
};

export default CategoryBlock;
