import { motion } from "framer-motion";
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
} from "react";
import styled from "styled-components";
import { colors, fonts } from "../../../constants";
import hexColorOpacity from "../../../utils/hexColorOpacity";
import Icon from "../Icon";
import { useMessagesApi } from "../MessagesApiProvider/context";
import { MessageProps, MessageType } from "../types";

const Container = styled(motion.div)<{ $type: MessageType }>`
  align-items: center;

  background: ${({ $type }) =>
    $type === "critical" ? colors.brownBlack : colors.white};
  border: 1px solid
    ${({ $type }) => {
      switch ($type) {
        case "critical":
          return hexColorOpacity(colors.brownBlack, 0.4);
        case "error":
          return hexColorOpacity(colors.coralStable, 0.4);
        case "info":
          return hexColorOpacity(colors.gold, 0.4);
        case "success":
          return hexColorOpacity(colors.greenStable, 0.4);
        default:
          return hexColorOpacity(colors.gold, 0.4);
      }
    }};
  border-radius: 5px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  color: ${({ $type }) =>
    $type === "critical" ? "#fff" : colors.brownBlack};
  display: inline-flex;
  font-family: ${fonts.main};
  font-size: 15px;
  max-width: 800px;
  padding: 10px 12px;
  pointer-events: auto;
  user-select: none;
`;

const IconContainer = styled.div`
  height: 22px;
  margin-inline-end: 8px;
`;

const animate = { opacity: 1, scale: 1, y: 0 };
const exit = {
  opacity: 0,
  scale: 0.5,
  transition: { duration: 0.3 },
};
const initial = { opacity: 0, scale: 0.3, y: 50 };

const Message = forwardRef(
  (
    {
      content,
      type,
      messageKey,
      duration,
      closable,
      ...restProps
    }: MessageProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const messagesApi = useMessagesApi();

    useEffect(() => {
      let timeoutId: unknown | undefined;

      if (duration) {
        timeoutId = setTimeout(() => {
          messagesApi.destroy(messageKey);
        }, duration);
      }

      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId as number);
        }
      };
    }, [duration, messageKey, messagesApi]);

    const handleClick = useCallback(() => {
      if (closable) {
        messagesApi.destroy(messageKey);
      }
    }, [closable, messageKey, messagesApi]);

    return (
      <Container
        {...restProps}
        ref={ref}
        $type={type}
        animate={animate}
        exit={exit}
        initial={initial}
        onClick={handleClick}
        role={
          type === "critical" || type === "error" ? "alert" : "status"
        }
      >
        <IconContainer>
          <Icon type={type} />
        </IconContainer>
        {content}
      </Container>
    );
  },
);

export default Message;
