/* eslint-disable max-lines */
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors, fonts } from "../../constants";
import Button from "../../lib/Button";
import mediaQuery from "../../utils/mediaQuery";

export const Wrapper = styled.div`
  box-sizing: border-box;
  font-family: ${fonts.main};
  margin: auto;
  max-width: 728px;
  padding: 12px 20px 0;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      padding-top: 90px;
    `,
  )}
`;

export const Box = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.brownLightGrey1};
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 25px 20px;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      padding: 35px 40px;
    `,
  )}
`;

export const StatusBox = styled(Box)`
  ${mediaQuery(
    "greaterThanPhone",
    css`
      padding: 40px;
    `,
  )}
`;

export const Text = styled.p`
  color: ${colors.brownBlack};
  font-size: 15px;
  line-height: 24px;
  margin: 0 0 4px;

  a {
    color: ${colors.blue};
  }
`;

export const Bold = styled.span`
  color: ${colors.black};
  font-weight: 700;
`;

export const Divider = styled.div`
  background: ${colors.brownLightGrey1};
  height: 1px;
  margin: 25px 0;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      margin: 35px 0;
    `,
  )}
`;

export const Caption = styled.p`
  color: ${colors.coral};
  font-size: 15px;
  font-weight: 500;
  margin: 0 0 10px;
`;

export const Balance = styled.p`
  color: ${colors.coral};
  display: inline;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
`;

export const Title = styled.h3`
  font-family: ${fonts.alt};
  font-size: 24px;
  line-height: 24px;
  margin: 0 0 25px;
  padding-inline-start: 27px;
  position: relative;

  svg {
    height: 18px;
    inset-inline-start: 0px;
    position: absolute;
    top: 4px;
    width: 18px;
  }

  ${mediaQuery(
    "greaterThanPhone",
    css`
      margin-bottom: 40px;
    `,
  )}
`;

export const TitleAlt = styled.h3`
  align-items: center;
  color: #333333;
  display: flex;
  font-family: ${fonts.alt};
  font-size: 24px;
  margin: 0 0 20px;
`;

export const NoteContainer = styled.div`
  align-items: flex-start;
  display: block;
  justify-content: space-between;
  margin-bottom: 25px;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      display: flex;
      margin-bottom: 40px;
    `,
  )}
`;

export const Note = styled.div`
  margin-bottom: 20px;
  width: 100%;

  p {
    margin-bottom: 0;
  }

  ${mediaQuery(
    "greaterThanPhone",
    css`
      width: calc(50% - 10px);
    `,
  )}
`;

export const NoteTitle = styled.h4`
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin: 0 0 10px;
`;

export const FailAction = styled.div`
  margin-top: 20px;
`;

export const Links = styled.div`
  align-items: center;
  display: flex;
  font-size: 12px;
  justify-content: center;
  padding-bottom: 20px;
`;

export const ExternalLink = styled.a.attrs({ target: "_blank" })`
  align-items: center;
  color: #8792a2;
  display: flex;
  font-weight: 500;
  margin: 0 12px;
  text-decoration: none;

  &:last-child {
    margin-inline-end: 0;
  }

  &.stripe-link {
    margin-inline-start: 0;

    svg {
      margin-inline-start: 6px;
      height: 14px;
      width: 34px;
    }
  }

  &.plaid-link {
    svg {
      height: 20px;
      width: 55px;
    }
  }
`;

export const LinkDivider = styled.div`
  background: #8792a2;
  height: 20px;
  margin: 0 12px;
  width: 1px;
`;

export const ResultLinks = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      margin-top: 40px;
    `,
  )}
`;

export const ResultLink = styled(Link)`
  color: ${colors.blue};
  font-size: 15px;
  margin-inline-end: 20px;

  &:last-child {
    margin-inline-end: 0;
  }
`;

const RoundIconWrapper = styled.div`
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  height: 22px;
  justify-content: center;
  margin-inline-end: 10px;
  width: 22px;

  svg {
    height: 12px;
    width: 12px;
  }
`;

export const CheckIconWrapper = styled(RoundIconWrapper)`
  background: ${colors.greenStable};
  color: ${colors.white};
`;

export const CrossIconWrapper = styled(RoundIconWrapper)`
  background: ${colors.redStable};
  color: ${colors.white};
`;

export const BackButtonContainer = styled.div`
  display: flex;
  margin-bottom: 14px;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      margin-bottom: 20px;
    `,
  )}
`;

export const CrossButtonContainer = styled(BackButtonContainer)`
  justify-content: flex-end;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      display: none;
    `,
  )}
`;

export const BackButton = styled(Link)`
  color: ${colors.blue};
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  text-decoration: none;

  svg {
    display: block;
    margin-inline-end: 12px;
  }

  ${mediaQuery(
    "greaterThanPhone",
    css`
      margin-inline-start: 40px;
    `,
  )}
`;

export const CrossButton = styled(BackButton)`
  svg {
    height: 24px;
    margin-inline-end: 0;
    width: 24px;
  }
`;

export const DesktopBack = styled.div`
  align-items: center;
  display: none;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      display: flex;
    `,
  )}
`;
export const MobileBack = styled.div`
  display: block;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      display: none;
    `,
  )}
`;

export const Loader = styled.div`
  display: inline-block;
`;

export const LogoContainer = styled(Link)`
  display: block;
  inset-inline-start: 30px;
  position: absolute;
  top: 30px;

  ${mediaQuery(
    "phone",
    css`
      display: none;
    `,
  )}
`;

export const SubmitButton = styled(Button)`
  ${mediaQuery(
    "phone",
    css`
      padding-inline: 10px;
    `,
  )}
`;
