import { FC, SVGAttributes } from "react";

const Present: FC<SVGAttributes<SVGElement>> = (props) => {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 51 48"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g style={{ fill: "currentColor" }}>
        <path
          d="M1,15 L1,47 L50,47 L50,15 L1,15 Z M0,14 L51,14 L51,48 L0,48 L0,14 Z"
          id="Rectangle"
        />
        <path
          d="M1,15 L1,47 L24,47 L24,15 L1,15 Z M0,14 L25,14 L25,48 L0,48 L0,14 Z"
          id="Rectangle"
        />
        <path
          d="M1,31 L1,47 L50,47 L50,31 L1,31 Z M0,30 L51,30 L51,48 L0,48 L0,30 Z"
          id="Rectangle"
        />
        <path
          d="M21.7953022,3.02806056 C19.3665512,0.592783466 15.4276184,0.594276686 12.9967624,3.03166444 C10.5651668,5.46979375 10.5636767,9.4216265 12.9931681,11.857646 C14.5689884,13.4377006 18.2553975,14.137467 24.021425,13.8796873 C24.1411565,8.23868931 23.375262,4.6122657 21.7953022,3.02806056 Z M12.2851133,12.5638035 C9.46624248,9.73735834 9.46797076,5.15382309 12.2887075,2.32550702 C15.1101838,-0.5035506 19.6837459,-0.505284402 22.5033571,2.32190314 C24.3795602,4.20314765 25.189044,8.20956692 25.0093359,14.37111 L24.9959027,14.8316883 L24.5357622,14.8558455 C18.2389048,15.1864285 14.1665197,14.4502652 12.2851133,12.5638035 Z"
          id="Oval"
        />
        <path
          d="M33.6151045,12.3391433 C35.4388143,10.5105332 35.4511137,7.55992767 33.6455167,5.74947893 C31.8406644,3.93977706 28.9002757,3.95209985 27.0773011,5.77997285 C25.9008232,6.9596119 25.3078174,9.67391228 25.366934,13.8966791 C29.6835372,14.0585955 32.4427081,13.51469 33.6151045,12.3391433 Z M24.8521939,14.8755561 L24.387735,14.8532894 L24.3762885,14.3884379 C24.2594588,9.6438644 24.8983035,6.54871054 26.3692462,5.07381543 C28.5816409,2.85547597 32.1566467,2.84049358 34.3535715,5.04332151 C36.5497515,7.24540259 36.5348189,10.8276985 34.3231593,13.0453008 C32.8470397,14.5253867 29.7009141,15.1080094 24.8521939,14.8755561 Z"
          id="Oval"
        />
      </g>
    </svg>
  );
};

export default Present;
