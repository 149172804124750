import * as React from "react";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
// TODO: check should we use react-transition-group or something

const Container = styled.div`
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transform: translateY(-50px);
  transition: all 0.3s ease;

  &.transition-appear-active {
    max-height: 1000px;
    opacity: 1;
    transform: translateY(0px);
  }
  &.transition-enter {
    max-height: 1px;
    opacity: 0;
    transform: translateY(-50px);
  }
  &.transition-enter-active {
    max-height: 1000px;
    opacity: 1;
    transform: translateY(0px);
  }
  &.transition-enter-done {
    max-height: none;
    opacity: 1;
    transform: translateY(0px);
  }
  &.transition-exit {
    max-height: 1000px;
    opacity: 1;
    transform: translateY(0px);
  }
  &.transition-exit-active {
    max-height: 0;
    opacity: 0;
    transform: translateY(-20px);
  }
  &.transition-exit-done {
    max-height: 0;
    opacity: 0;
    transform: translateY(-50px);
  }
`;

interface Props {
  isExpanded: boolean;
  children: React.ReactChild;
}

const Expandable: React.FC<Props> = ({
  isExpanded,
  children,
}: Props) => (
  <CSSTransition
    appear={isExpanded}
    classNames="transition"
    in={isExpanded}
    timeout={300}
  >
    <Container>{children}</Container>
  </CSSTransition>
);

export default Expandable;
