import * as t from "io-ts";

export const StatusC = t.union([
  t.literal("requested"),
  t.literal("granted"),
  t.literal("cancelled"),
  t.literal("canceled"),
  t.literal("rejected"),
  t.literal("expired"),
  t.literal("processed"),
  t.literal("created"),
  t.literal("initiated"),
  t.literal("failed"),
  t.literal("paid"),
  t.literal("updated"),
  t.literal("manually_collected"),
]);

export type StatusRaw = t.TypeOf<typeof StatusC>;

export type Status =
  | "billAvailable"
  | "billPaid"
  | "billPaymentDueSoon"
  | "billPaymentFailed"
  | "billPaymentInit"
  | "billPaymentOverdue"
  | "canceled"
  | "expired"
  | "granted"
  | "rejected"
  | "requested";
