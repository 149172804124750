import * as t from "io-ts";
import JSONApiResourceLinkageC from "../JSONApiResourceLinkage";

const JSONApiRelationshipsDataC = t.type({
  data: JSONApiResourceLinkageC,
});

export type JSONApiRelationshipsData = t.TypeOf<
  typeof JSONApiRelationshipsDataC
>;

export default JSONApiRelationshipsDataC;
