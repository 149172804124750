/* eslint-disable max-lines */
import {
  Children,
  ComponentProps,
  FC,
  ReactNode,
  useMemo,
} from "react";
import ReactMarkdown from "react-markdown";
import { PluggableList } from "react-markdown/lib/react-markdown";
import remarkBreaks from "remark-breaks";
import styled, { css } from "styled-components";
import { fonts } from "../../constants";
import mediaQuery from "../../utils/mediaQuery";
import { isFileLink } from "../../utils/regexp";
import Button from "../Button";
import Link from "../Link";
import Info from "./Info";
import Question from "./Question";
import Warning from "./Warning";

const StyledReactMarkdown = styled(ReactMarkdown)`
  h1,
  h2 {
    font-family: ${fonts.alt};
    font-weight: normal;
    margin: 0;
    color: #000;
  }

  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    margin: 0;
    font-family: ${fonts.main};
    color: #000;
  }

  h1 {
    font-size: 32px;
    line-height: 32px;
    margin-inline-start: -2px;
  }

  h2 {
    font-size: 24px;
    line-height: 28px;
    margin: 46px 0 26px;

    &:first-child {
      margin-top: 0;
    }
  }

  h3 {
    font-size: 18px;
    line-height: 24px;
  }

  h4 {
    font-size: 15px;
    line-height: 24px;
    margin-bottom: -5px;
  }

  p,
  li {
    font-size: 15px;
    line-height: 24px;
  }

  pre {
    font-family: ${fonts.main};
    white-space: initial;
  }
`;

// const twoBreaks = `
//
// `;

interface Props {
  text: string;
  className?: string;
}

type BadgeType = "info" | "question" | "warning";
const badgeIconCss = css`
  display: inline-block;
  flex-shrink: 0;
  height: 26px;
  margin-inline-end: 20px;
  width: 26px;
`;
const InfoBadge = styled(Info)`
  ${badgeIconCss}
`;
const QuestionBadge = styled(Question)`
  ${badgeIconCss}
`;
const WarningBadge = styled(Warning)`
  ${badgeIconCss}
`;
export const Badge = styled.p`
  align-items: flex-start;
  display: flex;
  margin: 15px 0;
`;
const Image = styled.div<{ $media?: "greaterThanPhone" | "phone" }>`
  font-size: 0;
  line-height: 0;
  margin: 15px -50px 15px 0;
  max-width: 100%;
  text-align: center;

  img {
    width: 100%;
  }

  ${({ $media }) =>
    $media
      ? css`
          ${mediaQuery(
            "phone",
            css`
              display: ${$media === "phone" ? "block" : "none"};
            `,
          )}
          ${mediaQuery(
            "greaterThanPhone",
            css`
              display: ${$media === "greaterThanPhone"
                ? "block"
                : "none"};
            `,
          )}
        `
      : ""}
`;
const Vimeo = styled.div``;
const handleCustomCodeTags = (line: string): ReactNode => {
  const badgeMatch = line.match(/\(([!?i])\)\s([\s\S]*?)$/);
  if (badgeMatch) {
    let type: BadgeType | undefined;
    switch (badgeMatch[1]) {
      case "!": {
        type = "warning";
        break;
      }
      case "?": {
        type = "question";
        break;
      }
      case "i": {
        type = "info";
        break;
      }
      default: {
        type = undefined;
      }
    }
    return (
      <Badge>
        {type === "info" && <InfoBadge />}
        {type === "question" && <QuestionBadge />}
        {type === "warning" && <WarningBadge />}
        <NestedMarkdown text={badgeMatch[2]} />
      </Badge>
    );
  }

  const buttonMatch = line.match(/#button\s?(\{[\s\S]+?})/);
  if (buttonMatch) {
    try {
      const attrs = JSON.parse(buttonMatch[1]) as {
        href?: string;
        title?: string;
      };
      return (
        <Button
          as="a"
          href={attrs.href}
          kind="filledGreen"
          resetWidth
        >
          {attrs.title}
        </Button>
      );
    } catch (err) {
      // eslint-disable-next-line no-console
      console.debug({
        line,
        match: buttonMatch[1],
      });
      // eslint-disable-next-line no-console
      console.error("can not parse button", err);
      return line;
    }
  }

  const imgMatch = line.match(/#image\s?(\{[\s\S]+?})/);
  if (imgMatch) {
    try {
      const attrs = JSON.parse(imgMatch[1]) as {
        id?: string;
        alt?: string;
        media?: "greaterThanPhone" | "phone";
      };
      return (
        <Image $media={attrs.media}>
          <img alt={attrs.alt || ""} src={attrs.id} />
        </Image>
      );
    } catch (err) {
      // eslint-disable-next-line no-console
      console.debug({
        line,
        match: imgMatch[1],
      });
      // eslint-disable-next-line no-console
      console.error("can not parse image", err);
      return line;
    }
  }

  const vimeoMatch = line.match(/#vimeo\s([\s\S]+?)$/);
  if (vimeoMatch) {
    return (
      <Vimeo dangerouslySetInnerHTML={{ __html: vimeoMatch[1] }} />
    );
  }
  return line;
};
const remarkPlugins: PluggableList = [remarkBreaks];
const components: ComponentProps<typeof ReactMarkdown>["components"] =
  {
    a({ node, href, children, ...props }) {
      const isFile = !!href && isFileLink(href);
      return (
        <Link
          as="a"
          download={isFile || undefined /* IOS */}
          href={href}
          {...props}
        >
          {children}
        </Link>
      );
    },
    code({ children }) {
      return (
        <>
          {Children.map(children, (child) => {
            if (typeof child === "string") {
              const lines = child
                .split(/\r?\n/)
                .map((line) => handleCustomCodeTags(line));

              return lines.flatMap((value, index, array) =>
                // eslint-disable-next-line no-nested-ternary
                array.length - 1 !== index
                  ? typeof value !== "string"
                    ? value
                    : [value, "\n"]
                  : value,
              );
            }
            return <>{child}</>;
          })}
        </>
      );
    },
  };

/**
 * Special Markdown, which can be rendered inside other Markdown
 */
const NestedMarkdown: FC<Omit<Props, "className">> = ({ text }) => {
  const nestedComponents: ComponentProps<
    typeof ReactMarkdown
  >["components"] = useMemo(
    () => ({
      ...components,
      p({ node, ...props }) {
        // We are not allowed to use <p> inside <p>
        return <span {...props}>{props.children}</span>;
      },
    }),
    [],
  );
  return (
    <ReactMarkdown
      components={nestedComponents}
      linkTarget="_blank"
      remarkPlugins={remarkPlugins}
    >
      {text
        .replaceAll(/] +\(/g, "](")
        .replaceAll(/^(#+)([^\s#]+)/gm, "$1 $2")}
    </ReactMarkdown>
  );
};
const Markdown: FC<Props> = ({ text, className }: Props) => {
  return (
    <StyledReactMarkdown
      className={className}
      components={components}
      linkTarget="_blank"
      remarkPlugins={remarkPlugins}
    >
      {text
        .replaceAll(/] +\(/g, "](")
        .replaceAll(/^(#+)([^\s#]+)/gm, "$1 $2")}
    </StyledReactMarkdown>
  );
};

export default Markdown;
