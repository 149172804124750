import { isLeft } from "fp-ts/Either";
import * as t from "io-ts";
import { useMutation } from "react-query";
import { wrongApiResponse } from "../../../../locales/errors";
import { useAxios } from "../../../../network";
import { ENDPOINTS } from "../../../../network/endpoints";

const responseC = t.unknown;
export interface Check2FACodePayload {
  email: string;
  code: string;
  recaptchaToken: string;
  verificationToken: string;
}
export function useConfirm2FA() {
  const axios = useAxios();
  return useMutation(async (payload: Check2FACodePayload) => {
    const { email, code, recaptchaToken, verificationToken } =
      payload;
    const { data } = await axios.post<unknown>(
      `${ENDPOINTS.verifyTwoFactorCode}`,
      {
        email,
        "g-recaptcha-response": recaptchaToken,
        verification_code: code,
        verification_token: verificationToken,
      },
    );

    const decoded = responseC.decode(data);
    if (isLeft(decoded)) {
      // eslint-disable-next-line no-console
      console.error(decoded.left);
      throw wrongApiResponse;
    } else {
      return true;
    }
  });
}
