import { Trans } from "@lingui/macro";
import { FC } from "react";
import styled from "styled-components";
import { colors } from "../../../../constants";
import { Service } from "../../../models/Service";
import CoveredItem from "./CoveredItem";

type Props = {
  services: Service[];
};

const Items = styled.div`
  & + & {
    margin-top: 10px;
  }
`;

const Title = styled.div`
  font-weight: 500;
  margin-bottom: 2px;
`;

const TitleGreen = styled(Title)`
  color: ${colors.green};
`;

const TitleRed = styled(Title)`
  color: ${colors.red};
`;

export const PrescribedTable: FC<Props> = ({ services }: Props) => (
  <>
    {services.map((item) => (
      <CoveredItem key={item.id} item={item} />
    ))}
  </>
);

export const CoveredTable: FC<Props> = ({ services }: Props) => {
  if (services.length === 0) return null;

  return (
    <Items>
      <TitleGreen>
        <Trans>Covered</Trans>
      </TitleGreen>
      {services.map((item) => (
        <CoveredItem key={item.id} item={item} />
      ))}
    </Items>
  );
};

export const RejectedTable: FC<Props> = ({ services }: Props) => {
  if (services.length === 0) return null;

  return (
    <Items>
      <TitleRed>
        <Trans>Rejected</Trans>
      </TitleRed>
      {services.map((item) => (
        <CoveredItem key={item.id} isRejected item={item} />
      ))}
    </Items>
  );
};
