class BroadcastChannelMock {
  onmessage?: (event: MessageEvent) => void;

  // eslint-disable-next-line class-methods-use-this
  postMessage() {}
}

const broadcastChannel = window.BroadcastChannel
  ? new BroadcastChannel("stork")
  : new BroadcastChannelMock();

broadcastChannel.onmessage = (event: MessageEvent) => {
  if (event.origin !== window.location.origin) return;

  if (event.data === "logout") {
    window.location.pathname = "/signin";
  }
};

export default broadcastChannel;
