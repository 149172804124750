import * as t from "io-ts";
import { createResourceIdentifierC } from "../../../network/jsonApi/builders";
import { CareNavigatorC } from "./CareNavigator";

export const responseC = t.type({
  data: t.type({
    attributes: t.type({
      call_subject_type: t.string,
      call_subject_type_name: t.string,
    }),
    id: t.string,
    relationships: t.type({
      call_experts: t.type({
        data: t.array(createResourceIdentifierC("call_expert")),
      }),
    }),
    type: t.literal("call_subject"),
  }),
  included: t.array(CareNavigatorC),
});
