import { useMutation, useQueryClient } from "react-query";
import { useAxios } from "../../network";
import { ENDPOINTS } from "../../network/endpoints";

const useCancelReimbursement = () => {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation(
    async (id: string) => {
      await axios.delete<unknown>(
        ENDPOINTS.cancelReimbursement.replace(":id", id),
      );

      return true;
    },
    {
      onSuccess: () => {
        queryClient
          .invalidateQueries("getReimbursements")
          // eslint-disable-next-line no-console
          .catch((err) => console.error(err));
      },
    },
  );
};

export default useCancelReimbursement;
