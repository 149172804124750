import { toDate } from "date-fns-tz";
import { isLeft } from "fp-ts/Either";
import * as t from "io-ts";
import reporter from "io-ts-reporters";
import qs from "qs";
import { useQuery } from "react-query";
import { wrongResponseFormat } from "../../../locales/errors";
import { useAxios } from "../../../network";
import { ENDPOINTS } from "../../../network/endpoints";

const responseC = t.readonlyArray(t.string);
export const useAvailableCallDates = (
  id: string,
  timezone: string,
  limit?: number,
) => {
  const axios = useAxios();
  return useQuery(
    ["calls", "getAvailableCallDates", id, limit],
    async () => {
      const response = await axios.get(
        `${ENDPOINTS.getCallDates.replace(":id", id)}?${qs.stringify({
          limit,
          timezone,
        })}`,
        {
          validateStatus: (status) => status < 400 || status === 500, // FIXME: temporary hotfix
        },
      );

      if (response.status === 500) {
        return [];
      }

      const decodeResult = responseC.decode(response.data);
      if (isLeft(decodeResult)) {
        // eslint-disable-next-line no-console
        console.error(reporter.report(decodeResult));
        throw wrongResponseFormat;
      }
      return decodeResult.right.map((dateString) =>
        toDate(dateString, {
          timeZone: timezone,
        }),
      );
    },
  );
};
