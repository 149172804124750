import { setUser as sentrySetUser } from "@sentry/react";
import { isRight } from "fp-ts/Either";
import * as t from "io-ts";
import reporter from "io-ts-reporters";
import { useQuery } from "react-query";
import { UseQueryResult } from "react-query/types/react/types";
import { ProfileStatus } from "../../components/FamilyStatusForm/types";
import { syncWithBackendLocale } from "../../locales/appLocale";
import { useAxios } from "../../network";
import { ENDPOINTS } from "../../network/endpoints";
import CareNavigatorC from "../../network/jsonApiV2/models/specific/CareNavigator";
import {
  SessionC,
  tokenC,
  cobraC,
  userAttributesC,
  UserCompany,
  userCompanyC,
} from "../models/Session";

export type SessionUser = {
  id: string;
  type: "user";
  attributes: t.TypeOf<typeof userAttributesC>;
  availableStatuses: readonly ProfileStatus[];
  notificationsEnabled: boolean;
  company: UserCompany;
  careNavigator: t.TypeOf<typeof CareNavigatorC>;
};

export interface Session {
  user: SessionUser | null;
  csrfToken: string | null;
  cobra: boolean | null;
}

function extractCSRFToken(data: unknown): string {
  const metaDecodeResult = tokenC.decode(data);
  if (isRight(metaDecodeResult)) {
    return metaDecodeResult.right.meta.csrf_token;
  }

  // eslint-disable-next-line no-console
  console.error(reporter.report(metaDecodeResult));
  throw new Error("Can not extract csrfToken!");
}

function extractSessionData(
  responseData: unknown,
): SessionUser | null {
  const decodeResult = SessionC.decode(responseData);
  if (isRight(decodeResult)) {
    const jsonApiData = decodeResult.right;
    const { data, included } = jsonApiData;

    const { id, attributes } = data;

    const newUX =
      attributes.new_ux ||
      // this is a temporary solution to force new UX for demo user. See: STRK-2409
      attributes.email === "demo@joinstorkclub.com" ||
      attributes.email === "demouser@joinstorkclub.com";

    if (newUX) {
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set("new_ux", "1");
      window.location.href = currentUrl.toString();
    }

    const userCompany = included.find(userCompanyC.is);
    if (!userCompany) {
      throw new Error("Can not extract availableStatuses!");
    }
    const careNavigator = included.find(CareNavigatorC.is);
    if (!careNavigator) {
      throw new Error("Can not extract careNavigator!");
    }
    return {
      attributes,
      availableStatuses: userCompany.attributes.statuses,
      careNavigator,
      company: userCompany,
      id,
      notificationsEnabled: attributes.notifications_enabled,
      type: "user",
    };
  }
  // eslint-disable-next-line no-console
  console.error(reporter.report(decodeResult));
  throw new Error("Can not extract user info!");
}

function extractCobra(data: unknown): boolean {
  const cobraDecodeResult = cobraC.decode(data);

  if (isRight(cobraDecodeResult)) {
    return cobraDecodeResult.right.meta.cobra;
  }

  // eslint-disable-next-line no-console
  console.error(reporter.report(cobraDecodeResult));
  throw new Error("Can not extract Cobra!");
}

export default function useAuthSession(): UseQueryResult<Session> {
  const axios = useAxios();
  return useQuery(
    "cookie",
    async () => {
      let sentryUserId: string | undefined;
      try {
        const response = await axios.get<unknown>(
          `${ENDPOINTS.getProfile}?include=user,company`,
          {
            validateStatus: (status) => status < 500,
          },
        );

        const csrfToken = extractCSRFToken(response.data);
        if (response.status < 400) {
          const user = extractSessionData(response.data);
          if (user) {
            const countryCode = user.company.attributes.country_code;

            const { preferred_language: userLang } = user.attributes;
            sentryUserId = user.id;
            syncWithBackendLocale(userLang, countryCode);
          }

          return {
            csrfToken,
            user,
          };
        }

        const cobra = extractCobra(response.data);
        return {
          cobra,
          csrfToken,
          user: null,
        };
      } catch (e) {
        // exception in this method creates infinity sign-in/sign-out loop, so it must be wrapped in try/catch
        // FIXME: show a global error message and ask user to reload the app
        // eslint-disable-next-line no-console
        console.error(e);
        return {
          cobra: null,
          csrfToken: "",
          user: null,
        };
      } finally {
        sentrySetUser(
          {
            id: sentryUserId,
          } || null,
        );
      }
    },
    {
      cacheTime: Number.POSITIVE_INFINITY,
      refetchInterval: 5 * 60 * 1000,
      refetchOnWindowFocus: true, // keep session in sync for active tab (if not in the background)
      staleTime: Number.POSITIVE_INFINITY,
    },
  );
}
