import * as t from "io-ts";

export const StateC = t.union([
  t.literal("requested"),
  t.literal("approved"),
  t.literal("rejected"),
  t.literal("canceled"),
]);

export type StateEntity = t.TypeOf<typeof StateC>;

export type State =
  | "approved"
  | "canceled"
  | "rejected"
  | "requested";
