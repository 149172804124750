import { Trans } from "@lingui/macro";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import useAuthSession from "../../authentication/hooks/useAuthSession";
import { colors } from "../../constants/colors";
import mediaQuery from "../../utils/mediaQuery";

const Container = styled.div.attrs({
  // elevation: 20,
})`
  bottom: 12px;
  position: fixed;
  inset-inline-end: 12px;
  background-color: white;
  border: 2px solid white;
  border-radius: 50%;
  width: 52px;
  height: 52px;
  box-shadow:
    0 10px 13px -6px rgba(0, 0, 0, 0.2),
    0px 20px 31px 3px rgba(0, 0, 0, 0.14),
    0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  z-index: 9999999;
`;
const StyledLink = styled(Link)`
  &:focus-within {
    ${Container} {
      border-color: ${colors.blue};
    }
  }
  ${mediaQuery(
    "greaterThanPhone",
    css`
      display: none;
    `,
  )}
`;

const Photo = styled.img`
  height: 100%;
  width: 100%;
`;

const Placeholder = styled.div`
  align-items: center;
  background-color: ${colors.gold};
  border-radius: 56px;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const Letter = styled.span`
  color: white;
  font-size: 18px;
`;

function ChatInvitationInner() {
  const { data: authSession } = useAuthSession();
  if (!authSession || !authSession.user) {
    throw new Error("Auth session is required");
  }
  const photo =
    authSession.user.careNavigator.attributes.image_set?.w128;
  return (
    <StyledLink to="/care-navigator">
      <Container>
        {photo ? (
          <Photo src={photo} />
        ) : (
          <Placeholder>
            <Letter>
              <Trans id="ChatInvitationLetter">E</Trans>
            </Letter>
          </Placeholder>
        )}
      </Container>
    </StyledLink>
  );
}

export default function ChatInvitation() {
  const location = useLocation();

  const shouldRender =
    !!window.ReactNativeWebViewFeatures?.webchat &&
    !location.pathname.startsWith("/bills") &&
    !location.pathname.startsWith("/care-navigator") &&
    !location.pathname.startsWith("/reimbursement");
  if (!shouldRender) {
    return null;
  }
  return <ChatInvitationInner />;
}
