import { t } from "@lingui/macro";
import React, { FC, Suspense, lazy } from "react";
import { useParams } from "react-router-dom";
import PageLayout, { Body } from "../../../components/PageLayout";
import Skeleton from "../../../lib/Skeleton";
import { formatWeekDateTimeZone } from "../../../utils/date-fns";
import renderError from "../../../utils/renderError";
import { useGetCall } from "../../hooks";
import useExtendCall, {
  Params as ExtendCallParams,
} from "../../hooks/useExtendCall";
import ScreenMessage from "../ScreenMessage";

const VideoCall = lazy(() => import("../VideoCall"));

const CallPage: FC = () => {
  const [isEnded, setIsEnded] = React.useState<boolean>(false);
  const { token = "" } = useParams<{
    token: string;
  }>();
  const { data, error, isLoading } = useGetCall(token);
  const { mutate: extendCall } = useExtendCall(token);
  const fieldError = error ? renderError(error) : "";

  const onExtendCall = React.useCallback(
    (params: ExtendCallParams) => {
      return new Promise((resolve, reject) => {
        extendCall(params, {
          onError: (err) => {
            reject(err);
          },
          onSuccess: () => {
            resolve(true);
          },
        });
      });
    },
    [extendCall],
  );

  const onCallEndHandler = React.useCallback(() => {
    setIsEnded(true);
  }, []);

  const callTime = data
    ? formatWeekDateTimeZone(data.attributes.booked_at)
    : "";

  return (
    <PageLayout>
      <Body>
        <Skeleton active css="padding: 50px;" loading={isLoading}>
          {data ? (
            <Suspense
              fallback={
                <Skeleton active css="padding: 50px;" loading={false}>
                  children
                </Skeleton>
              }
            >
              {isEnded ? (
                <ScreenMessage message={t`The call has ended`} />
              ) : (
                <VideoCall
                  details={{
                    name: data.attributes.member_name,
                    note: data.attributes.note,
                    time: callTime,
                    title: data.attributes.call_subject_type_name,
                  }}
                  endsAt={data.attributes.ends_at}
                  onEnd={onCallEndHandler}
                  onExtendCall={onExtendCall}
                  token={data.attributes.twilio_access_token}
                />
              )}
            </Suspense>
          ) : (
            <ScreenMessage message={fieldError} />
          )}
        </Skeleton>
      </Body>
    </PageLayout>
  );
};

export default CallPage;
