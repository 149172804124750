import {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useMemo,
} from "react";
import { StylesConfig } from "react-select/dist/declarations/src/styles";
import {
  GroupBase,
  Theme,
} from "react-select/dist/declarations/src/types";
import { colors, fonts } from "../../../../constants";
import BaseSelect, { SelectRef, SelectProps } from "./BaseSelect";
import {
  DropdownIndicator,
  DropdownIndicatorIcon,
} from "./DropdownIndicator";
import {
  DropdownIndicatorNetwork,
  DropdownIndicatorNetworkIcon,
} from "./DropdownIndicatorNetwork";

export type { SelectRef };
// eslint-disable-next-line func-names
const Select = forwardRef(function <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: SelectProps<Option, IsMulti, Group> & {
    styles?: never;
    theme?: never;
    isNetworkStyle?: boolean;
  },
  ref: ForwardedRef<SelectRef<Option, IsMulti, Group>>,
) {
  const { "aria-invalid": ariaInvalid, isNetworkStyle } = props;
  const hasError = !!ariaInvalid && ariaInvalid !== "false";
  const customStyles: StylesConfig<Option, IsMulti, Group> = useMemo(
    () => ({
      control: (base, state) => ({
        ...base,
        "&:hover": {
          border: `1px solid ${
            isNetworkStyle ? "#C4C4C4" : colors.brownGrey
          }`,
        },
        // eslint-disable-next-line no-nested-ternary
        border: state.isDisabled
          ? `1px solid ${
              isNetworkStyle ? "#E5E5E5" : colors.brownLightGrey2
            }`
          : hasError && !state.isFocused
            ? `2px solid ${colors.red}`
            : `1px solid ${
                isNetworkStyle ? "#C4C4C4" : colors.brownLightGrey1
              }`,

        borderRadius: isNetworkStyle ? "8px" : "5px",
        boxShadow: "none",
        fontFamily: isNetworkStyle ? fonts.faktum : fonts.main,
        fontSize: isNetworkStyle ? "20px" : "16px",
        fontWeight: isNetworkStyle ? 500 : 400,
        height: "48px",
        lineHeight: "24px",
      }),
      menuPortal: (base) => ({
        ...base,
        zIndex: 9999,
      }),
      option: (base, { isSelected, isFocused }) => ({
        ...base,
        "&:hover": {
          color: colors.black,
        },
        color:
          isSelected || isFocused ? colors.black : colors.brownGrey,
      }),
      placeholder: (base) => ({
        ...base,
        color: isNetworkStyle ? "#C4C4C4" : colors.brownGrey,
        marginLeft: 0,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }),
      valueContainer: (base) => ({
        ...base,
        padding: "0 18px 0 18px",
      }),
    }),
    [hasError, isNetworkStyle],
  );
  const customThemeProvider: (theme: Theme) => Theme = useCallback(
    (theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        danger: colors.red,
        primary: colors.brownLightGrey1,
        primary25: colors.brownLightGrey2,
      },
    }),
    [],
  );

  return (
    <BaseSelect
      {...props}
      ref={ref}
      components={{
        DropdownIndicator: isNetworkStyle
          ? () => (
              <DropdownIndicatorNetwork
                $isDisabled={props.isDisabled}
              >
                <DropdownIndicatorNetworkIcon />
              </DropdownIndicatorNetwork>
            )
          : () => (
              <DropdownIndicator $isDisabled={props.isDisabled}>
                <DropdownIndicatorIcon />
              </DropdownIndicator>
            ),
        IndicatorSeparator: () => null,
      }}
      menuPortalTarget={document.body}
      styles={customStyles}
      theme={customThemeProvider}
    />
  );
}) as <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: SelectProps<Option, IsMulti, Group> & {
    styles?: never;
    theme?: never;
    isNetworkStyle?: boolean;
  },
) => ReactElement;

export default Select;
