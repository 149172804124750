import { FC } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors } from "../../../constants";
import { LogoColor } from "../../../lib/Logo";
import mediaQuery from "../../../utils/mediaQuery";

const Container = styled.div`
  align-items: center;
  background-color: ${colors.brownLightGrey2};
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  inset-inline-end: 0;
  inset-inline-start: 0;
  justify-content: center;
  padding: 0 20px 60px;
  position: fixed;
  top: 0;
  width: 100%;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      padding: 0;
    `,
  )}
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 20px;
  width: 100%;
`;

const Message = styled.div`
  color: ${colors.brownGrey};
  font-size: 22px;
  line-height: 1.4;
  max-width: 550px;
  text-align: center;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      font-size: 30px;
    `,
  )}
`;

interface Props {
  message: string;
}

const ScreenMessage: FC<Props> = ({ message }: Props) => (
  <Container>
    <LogoContainer>
      <Link to="/">
        <LogoColor />
      </Link>
    </LogoContainer>
    <Message role="alert">{message}</Message>
  </Container>
);

export default ScreenMessage;
