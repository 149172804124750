import { FC, useEffect, useContext } from "react";
import { TitleAnnouncerContext } from "./Announcer";

const usePageTitleAnnouncer = (title: string) => {
  const setter = useContext(TitleAnnouncerContext);

  useEffect(() => {
    setter(title);
  }, [title, setter]);
};

interface Props {
  children: string;
}

const PageTitle: FC<Props> = ({ children }: Props) => {
  usePageTitleAnnouncer(children);

  useEffect(() => {
    document.title = children;
  }, [children]);

  return null;
};

export default PageTitle;
