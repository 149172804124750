import { Trans } from "@lingui/macro";
import React, { FC } from "react";
import styled, { css } from "styled-components";
import { colors, fonts } from "../../constants";
import { Title } from "../../lib";
import Link from "../../lib/Link";
import { Logo } from "../../lib/Logo";
import { Text } from "../../lib/Text";
import mediaQuery from "../../utils/mediaQuery";
import image from "./image.png";

const LogoLink = styled(Link)`
  display: block;
`;

const StyledLogo = styled(Logo)``;

const Header = styled.header`
  ${mediaQuery(
    "phone",
    css`
      display: none;
    `,
  )}
`;

const NameOfError = styled(Title)`
  font-size: 24px;
  line-height: 100%;
  margin-bottom: 14px;
  text-align: center;

  ${mediaQuery(
    "phone",
    css`
      margin-bottom: 20px;
      padding: 0 40px;
    `,
  )}
`;

const CodeOfError = styled.div`
  color: ${colors.brownLightGrey1};
  font-family: ${fonts.alt};
  font-size: 24px;
  font-weight: bold;
  line-height: 100%;
  margin-bottom: 10px;

  ${mediaQuery(
    "phone",
    css`
      margin-bottom: 20px;
    `,
  )}
`;

const HomeLink = styled(Link)`
  ${mediaQuery(
    "phone",
    css`
      display: none;
    `,
  )}
`;

const IMG_HEIGHT_PX = 522;
const IMG_TOP_PX = 28;
const CARD_HEIGHT_PX = 365;

const Card = styled.main`
  align-items: center;
  border: 1px solid ${colors.brownLightGrey1};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: ${CARD_HEIGHT_PX}px;
  justify-content: center;
  margin-bottom: ${IMG_HEIGHT_PX - CARD_HEIGHT_PX + IMG_TOP_PX}px;
  position: relative;
  width: 800px;
  align-self: center;
  grid-area: page;
  justify-self: center;
  border-radius: 24px;

  ${HomeLink} {
    margin-top: 30px;
  }

  &:after {
    background-image: url(${image});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    height: ${IMG_HEIGHT_PX}px;
    left: 465px;
    position: absolute;
    top: ${IMG_TOP_PX}px;
    width: 540px;
  }

  ${mediaQuery(
    "tablet",
    css`
      &:after {
        height: 425px;
        left: 365px;
        top: 210px;
      }
    `,
  )}

  ${mediaQuery(
    "phone",
    css`
      width: 100%;
      height: auto;
      margin: 66px 0 0;
      place-self: start;
      border: none;

      &:after {
        height: 325px;
        left: auto;
        position: relative;
        top: auto;
        width: 336px;
        margin-top: 27px;
      }
    `,
  )}
`;

const Description = styled(Text).attrs({ as: "p" })`
  text-align: center;
  color: ${colors.brownBlack};
  line-height: 22px;
`;

const PageLayout = styled.div`
  align-items: center;
  display: grid;
  grid-template-areas: "page";
  min-height: 100%;
  position: relative;
  background-color: ${colors.white};
  overflow: hidden;

  ${Header} {
    align-self: start;
    grid-area: page;
    justify-self: start;

    ${LogoLink} {
      padding-left: 30px;
      padding-top: 26px;
    }
  }

  ${mediaQuery(
    "phone",
    css`
      background-color: ${colors.brownLightGrey2};
    `,
  )}
`;

const Error404Screen: FC = () => {
  return (
    <PageLayout>
      <Header>
        <LogoLink to="/">
          <StyledLogo />
        </LogoLink>
      </Header>

      <Card>
        <CodeOfError>404</CodeOfError>

        <NameOfError>
          <Trans>The page you were looking for doesn’t exist</Trans>
        </NameOfError>

        <Description>
          <Trans>
            You may have mistyped the address <br />
            or the page may have moved
          </Trans>
        </Description>

        <HomeLink to="/">
          <Trans>Return home</Trans>
        </HomeLink>
      </Card>
    </PageLayout>
  );
};

export default Error404Screen;
