import * as t from "io-ts";

export const CategoryC = t.type({
  attributes: t.type({
    name: t.string,
  }),
  id: t.string,
  type: t.literal("program_category"),
});

export type Category = t.TypeOf<typeof CategoryC>;
