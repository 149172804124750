import { t } from "@lingui/macro";
import React from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../constants";
import Icon from "../../../lib/Icon";
import mediaQuery from "../../../utils/mediaQuery";

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 32px;
  inset-inline-end: 21px;
  justify-content: center;
  position: absolute;
  top: 30px;
  width: 32px;
  z-index: 15;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      display: none;
    `,
  )}
`;

const SettingsButton = styled.button`
  background: none;
  border: none;
  margin: 0;
  padding: 0;
`;

const ProfileButton = () => {
  const onSettingsClick = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ action: "open_settings" }),
      );
    } else {
      // eslint-disable-next-line no-console
      console.warn("no webview detected");
    }
  };

  return (
    <Container>
      <SettingsButton
        aria-label={t`Settings`}
        onClick={onSettingsClick}
        type="button"
      >
        <Icon
          fill={colors.brownGrey}
          height={32}
          type="settings"
          width={32}
        />
      </SettingsButton>
    </Container>
  );
};

export default ProfileButton;
