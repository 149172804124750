import { isLeft } from "fp-ts/Either";
import * as t from "io-ts";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { UseQueryResult } from "react-query/types/react/types";
import { wrongResponseFormat } from "../../../locales/errors";
import { useAxios } from "../../../network";
import PaymentC, {
  PaymentEntity,
  PostPaymentEntity,
} from "../../../network/jsonApiV2/models/specific/Payment";

export const responseC = t.type({
  data: PaymentC,
});

export function responseToEntities(
  response: t.TypeOf<typeof responseC>,
): PaymentEntity {
  const { id, type, attributes } = response.data;

  return {
    attributes,
    id,
    type,
  };
}

export function usePayment(
  uid: string,
): UseQueryResult<PaymentEntity> {
  const axios = useAxios();

  return useQuery("payment", async () => {
    const response = await axios.get(`/v2/payment/bills/${uid}`, {
      headers: {
        platform: window.ReactNativePlatform?.os ?? undefined,
      },
    });

    const decodeResult = responseC.decode(response.data);
    if (isLeft(decodeResult)) {
      throw wrongResponseFormat;
    }

    return responseToEntities(decodeResult.right);
  });
}

export function useMakePayment() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: PostPaymentEntity): Promise<void> => {
      const { uid, accountId, publicToken } = payload;
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      await axios.post<unknown>(`/v2/payment/bills/${uid}/pay`, {
        account_id: accountId,
        public_token: publicToken,
      });
    },
    {
      onSuccess: async () => {
        await queryClient
          .refetchQueries(["payment"])
          // eslint-disable-next-line no-console
          .catch((err) => console.error(err));
      },
    },
  );
}
