import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors } from "../constants";
import mediaQuery from "../utils/mediaQuery";
import Icon from "./Icon";

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 32px;
  inset-inline-end: 20px;
  justify-content: center;
  width: 32px;
  z-index: 15;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      display: none;
    `,
  )}
`;

const ProfileButton = () => {
  return (
    <Container>
      <Link to="/profile">
        <Icon
          fill={colors.brownGrey}
          height={32}
          type="profile"
          width={32}
        />
      </Link>
    </Container>
  );
};

export default ProfileButton;
