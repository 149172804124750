import { ErrorBoundary } from "@sentry/react";
import {
  createContext,
  ReactNode,
  Suspense,
  useEffect,
  useState,
} from "react";
import { useQuery } from "react-query";
import SendBirdProvider, {
  useSdk,
} from "../../../care-navigator/CareNavigatorScreen/SendBirdProvider";
import SendBirdConnector from "../../../care-navigator/CareNavigatorScreen/SendBirdProvider/SendBirdConnector";

export const UnreadCountContext = createContext(0);

export default function UnreadCountProvider(props: {
  children: ReactNode;
  channelUrl: string;
}) {
  const { channelUrl, children } = props;
  const [unreadCount, setUnreadCount] = useState(0);
  return (
    <>
      <SendBirdProvider>
        <ErrorBoundary fallback={<div />}>
          <Suspense fallback={null}>
            <SendBirdConnector>
              <UnreadSynchronizer
                channelUrl={channelUrl}
                setValue={setUnreadCount}
              />
            </SendBirdConnector>
          </Suspense>
        </ErrorBoundary>
      </SendBirdProvider>
      <UnreadCountContext.Provider value={unreadCount}>
        {children}
      </UnreadCountContext.Provider>
    </>
  );
}

function UnreadSynchronizer(props: {
  channelUrl: string;
  setValue: (val: number) => void;
}) {
  const { channelUrl, setValue } = props;
  const sdk = useSdk();

  const { data: channel } = useQuery({
    cacheTime: 0,
    queryFn: async () => sdk.groupChannel.getChannel(channelUrl),
    queryKey: ["UnreadChatBadgeInner", channelUrl],
    suspense: true,
  });
  if (!channel) {
    throw new Error("Channel can not be undefined in suspense mode");
  }

  const { data: unreadSendBirdMessageCount } = useQuery({
    cacheTime: 0,
    queryFn: () => channel.unreadMessageCount,
    queryKey: [
      "UnreadChatBadgeInner",
      channelUrl,
      "unreadMessageCount",
    ],
    refetchInterval: 5000,
    suspense: true,
  });
  if (typeof unreadSendBirdMessageCount !== "number") {
    throw new Error(
      "unreadSendBirdMessageCount can not be undefined in suspense mode",
    );
  }
  useEffect(() => {
    setValue(unreadSendBirdMessageCount);
    return () => {
      setValue(0);
    };
  }, [setValue, unreadSendBirdMessageCount]);
  return null;
}
