import styled, { css } from "styled-components";
import { colors, fonts } from "../../../../../constants";
import mediaQuery from "../../../../../utils/mediaQuery";

export const Title = styled.div`
  color: ${colors.brownBlack};
  font-family: ${fonts.alt};
  font-size: 19px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 20px;
`;

export const Value = styled.div`
  color: ${colors.brownGrey};
  font-size: 19px;
  font-weight: bold;
  line-height: 22px;
`;

export const GridMobile = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  margin-bottom: 8px;

  ${mediaQuery(
    "desktopMedium",
    css`
      grid-row-gap: 8px;
      grid-template-columns: 100%;
      margin-bottom: 0;
    `,
  )}
`;
