import { CommonLocaleName, ApplicationLocaleName } from "../index";

/**
 * For such locales we can be sure that we return not undefined.
 */
function toCommonLocale(
  locale: ApplicationLocaleName,
): CommonLocaleName;
/**
 * For a random string we can return null and can not be sure if it is in CommonLocaleName
 */
function toCommonLocale(locale: string): string | null;
/**
 * Some external services (our backend, Sendbird) do not support regional locales.
 */
function toCommonLocale(locale: string): string | null {
  return locale.split("-")[0];
}

export default toCommonLocale;

export type ToCommonLocaleType = typeof toCommonLocale;
