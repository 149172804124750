import * as React from "react";
import { BaseIconAttributes } from "./types";

export const Stripe: React.FC<BaseIconAttributes> = ({
  width = "1em",
  height = "1em",
  mainColor = "currentColor",
  ...restProps
}) => (
  <svg
    {...restProps}
    fill="none"
    height={height}
    viewBox="0 0 34 14"
    width={width}
  >
    <g clipPath="url(#clip0_2526_15131)">
      <path
        clipRule="evenodd"
        d="M34.0449 7.23345C34.0449 4.84094 32.8722 2.95312 30.6309 2.95312C28.3802 2.95312 27.0184 4.84094 27.0184 7.21476C27.0184 10.0278 28.6261 11.4483 30.9335 11.4483C32.0589 11.4483 32.9101 11.1959 33.5531 10.8408V8.97169C32.9101 9.28949 32.1724 9.4857 31.2362 9.4857C30.3188 9.4857 29.5055 9.16797 29.4015 8.06519H34.026C34.026 7.94367 34.0449 7.45773 34.0449 7.23345ZM29.3731 6.34558C29.3731 5.28953 30.0257 4.85029 30.6214 4.85029C31.1983 4.85029 31.8131 5.28953 31.8131 6.34558H29.3731Z"
        fill={mainColor}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M23.368 2.95312C22.4412 2.95312 21.8455 3.38302 21.5145 3.68208L21.3916 3.10265H19.311V13.9997L21.6752 13.5043L21.6847 10.8595C22.0252 11.1025 22.5264 11.4483 23.3586 11.4483C25.0514 11.4483 26.5928 10.1025 26.5928 7.14C26.5834 4.42973 25.023 2.95312 23.368 2.95312ZM22.8006 9.39225C22.2427 9.39225 21.9117 9.19597 21.6847 8.953L21.6752 5.48579C21.9211 5.21477 22.2616 5.02786 22.8006 5.02786C23.6612 5.02786 24.257 5.98111 24.257 7.20538C24.257 8.45768 23.6707 9.39225 22.8006 9.39225Z"
        fill={mainColor}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M16.0577 2.40183L18.4314 1.89716V0L16.0577 0.495319V2.40183Z"
        fill={mainColor}
        fillRule="evenodd"
      />
      <path
        d="M18.4314 3.11172H16.0577V11.2891H18.4314V3.11172Z"
        fill={mainColor}
      />
      <path
        clipRule="evenodd"
        d="M13.5138 3.80351L13.3625 3.11193H11.3198V11.2894H13.684V5.7474C14.242 5.02778 15.1877 5.15863 15.4809 5.26143V3.11193C15.1782 2.99979 14.0718 2.79418 13.5138 3.80351Z"
        fill={mainColor}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M8.78548 1.08418L6.47796 1.57016L6.46851 9.05597C6.46851 10.4392 7.5182 11.4578 8.91787 11.4578C9.69328 11.4578 10.2607 11.3177 10.5728 11.1495V9.25225C10.2702 9.37377 8.77599 9.80364 8.77599 8.42051V5.10281H10.5728V3.11219H8.77599L8.78548 1.08418Z"
        fill={mainColor}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M2.39259 5.4858C2.39259 5.12131 2.69522 4.98113 3.19642 4.98113C3.91515 4.98113 4.82301 5.19608 5.54174 5.57924V3.38302C4.75681 3.07462 3.98135 2.95312 3.19642 2.95312C1.27668 2.95312 0 3.94376 0 5.59794C0 8.17733 3.59362 7.76615 3.59362 8.87824C3.59362 9.30818 3.21534 9.44832 2.68576 9.44832C1.90083 9.44832 0.898407 9.13059 0.104026 8.70072V10.925C0.983514 11.2988 1.87246 11.4577 2.68576 11.4577C4.65279 11.4577 6.00512 10.495 6.00512 8.82217C5.99567 6.03718 2.39259 6.53251 2.39259 5.4858Z"
        fill={mainColor}
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_2526_15131">
        <rect fill="white" height="14" width="34" />
      </clipPath>
    </defs>
  </svg>
);
