import { FC } from "react";
import { colors } from "../../../constants";

export const ReimbursementIcon: FC = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="8.5"
      cy="3.5"
      r="2.9"
      stroke="#21CD97"
      strokeWidth="1.2"
    />
    <circle
      cx="15.5"
      cy="3.5"
      r="2.9"
      stroke="#21CD97"
      strokeWidth="1.2"
    />
    <rect fill="#21CD97" height="17" rx="2" width="24" y="5" />
    <rect fill={colors.white} height="4" width="24" y="10" />
    <mask
      height="17"
      id="mask0_1186_23391"
      maskUnits="userSpaceOnUse"
      width="25"
      x="-1"
      y="5"
    >
      <rect fill="#21CD97" height="17" width="25" x="-1" y="5" />
    </mask>
    <g mask="url(#mask0_1186_23391)">
      <line
        stroke={colors.white}
        strokeWidth="1.2"
        transform="matrix(0.706823 0.70739 -0.706823 0.70739 12 5)"
        x2="5.83997"
        y1="-0.6"
        y2="-0.6"
      />
      <line
        stroke={colors.white}
        strokeWidth="1.2"
        transform="matrix(-0.706823 0.70739 0.706823 0.70739 12.1279 5)"
        x2="5.83997"
        y1="-0.6"
        y2="-0.6"
      />
    </g>
  </svg>
);
