/* eslint-disable max-lines */
import { t, Trans } from "@lingui/macro";
import {
  HTMLAttributes,
  useEffect,
  useLayoutEffect,
  useMemo,
} from "react";
import { Controller } from "react-hook-form";
import styled, { css } from "styled-components";
import { useGetCompanyPrograms } from "../../../components/company-programs/hooks";
import { colors } from "../../../constants";
import Button from "../../../lib/Button";
import FormField from "../../../lib/forms/FormField";
import FileInput from "../../../lib/forms/primitives/FileInput";
import Input from "../../../lib/forms/primitives/Input";
import Select from "../../../lib/forms/primitives/Select";
import Link from "../../../lib/Link";
import Title from "../../../lib/Title";
import mediaQuery from "../../../utils/mediaQuery";
import ExpensesBlock from "./ExpensesBlock";
import ReimbursementFormProvider, {
  useReimbursementFormContext,
} from "./ReimbursementFormProvider";
import { FormValues } from "./types";

const Description = styled.div`
  color: ${colors.brownBlack};
  font-size: 15px;

  line-height: 1.6;
`;

const SubmitButton = styled(Button)`
  ${mediaQuery(
    "greaterThanPhone",
    css`
      width: 205px;
    `,
  )}
`;

const Form = styled.form`
  column-gap: 16px;
  display: grid;
  grid-template:
    "file" min-content
    "service" min-content
    "provider" min-content
    "expenses" min-content
    "submit" min-content / 1fr;
  row-gap: 32px;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      grid-template:
        "file file" min-content
        "service provider" min-content
        "expenses expenses" min-content
        "submit submit" min-content / 1fr 1fr;

      ${SubmitButton} {
        justify-self: end;
      }
    `,
  )}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${Description} {
    margin-top: 20px;
    max-width: 500px;
  }

  ${Form} {
    margin-top: 40px;
  }
`;

export interface ReimbursementFormProps
  extends Omit<HTMLAttributes<HTMLElement>, "onSubmit"> {
  children?: never;
  onSubmit: (
    values: Omit<FormValues, "file"> & { file: File },
  ) => void;
  isSubmitting: boolean;
  errors: {
    file?: string;
    type?: string;
    provider?: string;
    expenses?: ReadonlyArray<
      | {
          date?: string;
          description?: string;
          amount?: string;
        }
      | undefined
    >;
  } | null;
  attachBeforeUnloadHandler?: boolean; // default true
  currency: string;
}

const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
  event.preventDefault();
  // eslint-disable-next-line no-param-reassign
  event.returnValue = "";
};
function ReimbursementFormInternal({
  className,
  onSubmit,
  isSubmitting,
  errors: externalErrors,
  attachBeforeUnloadHandler = true,
  currency,
  ...restProps
}: ReimbursementFormProps) {
  const {
    handleSubmit,
    control,
    register,
    setError,
    formState: { errors, isDirty },
  } = useReimbursementFormContext();
  useLayoutEffect(() => {
    if (externalErrors) {
      if (externalErrors.file) {
        setError("file", { message: externalErrors.file });
      }
      if (externalErrors.type) {
        setError("id", { message: externalErrors.type });
      }
      if (externalErrors.provider) {
        setError("provider", { message: externalErrors.provider });
      }
      if (externalErrors.expenses) {
        externalErrors.expenses.forEach((expense, index) => {
          if (expense?.date) {
            setError(`expenses.${index}.date`, {
              message: expense.date,
            });
          }
          if (expense?.description) {
            setError(`expenses.${index}.description`, {
              message: expense.description,
            });
          }
          if (expense?.amount) {
            setError(`expenses.${index}.amount`, {
              message: expense.amount,
            });
          }
        });
      }
    }
  }, [externalErrors, setError]);

  useEffect(() => {
    if (isDirty && attachBeforeUnloadHandler) {
      window.addEventListener("beforeunload", beforeUnloadHandler);
    } else {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    }

    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, [attachBeforeUnloadHandler, isDirty]);
  const { data: companyPrograms, isLoading: programsLoading } =
    useGetCompanyPrograms();
  const reimbursementTypes: ReadonlyArray<{
    label: string;
    value: string;
    id: string;
  }> = useMemo(() => {
    return (
      companyPrograms
        ?.filter((p) => p.reimbursable)
        .map((p) => {
          return {
            id: p.id,
            label: p.name,
            value: p.programType,
          };
        }) || []
    );
  }, [companyPrograms]);

  const onSubmitHandler = useMemo(() => {
    return handleSubmit((values) => {
      const typedValues = values as FormValues;
      const file = typedValues.file[0];
      if (!file) {
        throw new Error("No file");
      }

      onSubmit({
        ...typedValues,
        file,
      });
    });
  }, [handleSubmit, onSubmit]);
  return (
    <Container className={className} {...restProps}>
      <Title level="h1">
        <Trans>Reimbursement form</Trans>
      </Title>
      <Description>
        <Trans>
          If you have any questions while filling out this form,
          please{" "}
          <Link to="/care-navigator">
            contact your Care&nbsp;Partner
          </Link>{" "}
          and they can help guide you through the process.
        </Trans>
      </Description>
      <Form onSubmit={onSubmitHandler}>
        <FormField
          css={`
            grid-area: file;
          `}
          error={
            errors.file?.message
              ? String(errors.file?.message)
              : undefined
          }
          hideLabel
          label={t`Upload receipt`}
        >
          <FileInput
            accept={[
              "image/jpeg",
              "image/jpg",
              "image/png",
              "image/avif",
              "image/webp",
              "application/pdf",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ].join(",")}
            placeholder={t`Upload receipt`}
            {...register("file")}
          />
        </FormField>
        <FormField
          css={`
            grid-area: service;
          `}
          error={errors.id?.message}
          label={t`Reimbursement type`}
        >
          <Controller
            control={control}
            name="id"
            render={({
              field: { onBlur, onChange, value, ref },
              fieldState,
            }) => (
              <Select
                ref={ref}
                aria-errormessage={errors.id?.message}
                aria-invalid={!!fieldState.error}
                aria-label={t`Select reimbursement type`}
                isLoading={programsLoading}
                isMulti={false}
                onBlur={onBlur}
                onChange={(option) => onChange(option?.id)}
                options={reimbursementTypes}
                placeholder={t`Select`}
                value={reimbursementTypes.find((m) => m.id === value)}
              />
            )}
          />
        </FormField>
        <FormField
          css={`
            grid-area: provider;
          `}
          error={errors.provider?.message}
          label={t`Provider`}
        >
          <Input {...register("provider")} />
        </FormField>

        <ExpensesBlock
          css={`
            grid-area: expenses;
          `}
          currency={currency}
        />

        <SubmitButton
          css={`
            grid-area: submit;
          `}
          kind="filledCoral"
          loading={isSubmitting}
          resetWidth
          type="submit"
        >
          <Trans>Submit</Trans>
        </SubmitButton>
      </Form>
    </Container>
  );
}

const ReimbursementForm = (
  props: ReimbursementFormProps & {
    preselectedProgramId?: string;
  },
) => {
  const { preselectedProgramId } = props;
  return (
    <ReimbursementFormProvider
      preselectedProgramId={preselectedProgramId}
    >
      <ReimbursementFormInternal {...props} />
    </ReimbursementFormProvider>
  );
};

export default ReimbursementForm;
