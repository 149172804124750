import React from "react";
import styled from "styled-components";
import { Container as ItemContainer } from "../Items/Item";
import { SmallItems, SmallItemsRibbon } from "../Items/SmallItems";

const Container = styled.div`
  max-width: 820px;
`;

const ItemLarge = styled.div`
  display: flex;
  height: 310px;
  margin-bottom: 20px;
  width: 100%;
`;
const ImageLarge = styled.div`
  background-color: #e5e5e5;
  height: 100%;
  width: 44%;
`;
const InfoLarge = styled.div`
  background-color: #ffffff;
  height: 100%;
  width: 56%;
`;

const Image = styled.div`
  background-color: #e5e5e5;
  height: 180px;
  width: 100%;
`;

const Info = styled.div`
  background-color: #fff;
  height: 116px;
  width: 100%;
`;

const Placeholder: React.FC = () => (
  <ItemContainer>
    <Image />
    <Info />
  </ItemContainer>
);

const Preloader = () => (
  <Container>
    <ItemLarge>
      <ImageLarge />
      <InfoLarge />
    </ItemLarge>
    <SmallItems>
      <SmallItemsRibbon length={3}>
        {[0, 1, 2].map((i) => (
          <Placeholder key={i} />
        ))}
      </SmallItemsRibbon>
    </SmallItems>
  </Container>
);

export default Preloader;
