import { Trans } from "@lingui/macro";
import { format as formatDate } from "date-fns";
import { FC } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../../constants";
import mediaQuery from "../../../../utils/mediaQuery";
import { Authorization } from "../../../models/Authorization";
import { Status } from "../../../models/Status";
import { UserPlan } from "../../../models/UserPlan";
import { BoldBlack } from "../style";
import { PrescribedTable } from "./CoveredTable";
import PrescribedRx from "./PrescribedRx";

type Props = {
  authorization: Authorization;
  userPlan: UserPlan;
  status: Status;
};

const IntroRow = styled.div`
  color: ${colors.brownGrey};
  margin-bottom: 20px;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 10px;
    `,
  )}
`;

const DeliveryRow = styled.div`
  margin-bottom: 20px;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 30px;
    `,
  )}
`;

const PracticeRow = styled.div`
  margin-bottom: 4px;
`;

export const RxRequested: FC<Props> = ({
  authorization,
  userPlan,
  status,
}: Props) => {
  const { serviceDate, services } = authorization;

  return (
    <>
      <IntroRow>
        <Trans>
          There is no action required from you. You’ll be able to use
          the coverage once Stork Club authorizes it. The processing
          time might take up to 24 hours. <br />
          If your delivery date is immediate, we will process it ASAP.
        </Trans>
      </IntroRow>

      <PracticeRow>
        <Trans>
          By{" "}
          <BoldBlack>
            Stork Club Rx ({authorization.practiceName})
          </BoldBlack>
        </Trans>
      </PracticeRow>

      {serviceDate && (
        <DeliveryRow>
          <Trans>
            Delivery no later than{" "}
            <BoldBlack>
              {formatDate(serviceDate, "MM-dd-yyyy")}
            </BoldBlack>
          </Trans>
        </DeliveryRow>
      )}

      {!!services && services.length > 0 && (
        <PrescribedRx>
          <PrescribedTable services={services} />
        </PrescribedRx>
      )}
    </>
  );
};
