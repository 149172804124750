import { ApplicationLocaleName } from "./config";
import {
  saveLocaleToLocalStorage,
  URL_LOCALE_PARAM,
} from "./languageDetectors";

export default function updateFrontendLocale(
  locale: ApplicationLocaleName,
) {
  saveLocaleToLocalStorage(locale);

  const queryParams = new URLSearchParams(window.location.search);
  queryParams.delete(URL_LOCALE_PARAM);
  window.location.search = queryParams.toString();
}
