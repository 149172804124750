import * as t from "io-ts";
import { DateFromString } from "../../../io-ts/types";
import { CareNavigator } from "./CareNavigator";

export const CallC = t.type({
  attributes: t.type({
    booked_at: DateFromString,
    call_subject_type: t.string,
    call_subject_type_name: t.string,
    deep_url: t.union([t.string, t.null, t.undefined]),
    token: t.string,
  }),
  id: t.string,
  type: t.literal("call"),
});

export type CallEntity = t.TypeOf<typeof CallC>;

export interface CallEntityWithRelations extends CallEntity {
  relationships: {
    call_expert: CareNavigator;
  };
}
