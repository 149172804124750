export default function getMediaContrast(): boolean {
  try {
    const result = window.matchMedia("(prefers-contrast: more)");
    // eslint-disable-next-line no-console
    console.debug({ result });
    return result.matches;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.debug("Check media contrast failed", e);
    return false;
  }
}
