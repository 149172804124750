import { i18n } from "@lingui/core";

/**
 * "MMM dd" but localized. Date-fns does not provide this out of the box.
 */
export function formatDayAndMonthTZ(
  date: Date,
  timeZone: string,
  pattern: "MMM dd" | "MMMM dd",
): string {
  const format = new Intl.DateTimeFormat(i18n.locale, {
    day: "2-digit",
    month: pattern === "MMMM dd" ? "long" : "short",
    timeZone,
  });

  return format.format(date);
}

// May 5, 2022
export function formatDate(date: Date, timeZone?: string): string {
  const formatter = new Intl.DateTimeFormat(i18n.locale, {
    day: "numeric",
    month: "long",
    timeZone,
    year: "numeric",
  });

  return formatter.format(date);
}

// 12:00 AM
export function formatTime(date: Date, timeZone?: string): string {
  const formatter = new Intl.DateTimeFormat(i18n.locale, {
    hour: "numeric",
    minute: "numeric",
    timeZone,
  });

  return formatter.format(date);
}

// May 5, 2022 at 12:00 AM
export function formatDateTime(
  date: Date,
  timeZone?: string,
): string {
  const formatter = new Intl.DateTimeFormat(i18n.locale, {
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    month: "long",
    timeZone,
    year: "numeric",
  });

  return formatter.format(date);
}

// Mon, Nov 28, 2022, 5:30 AM
export function formatDateTimeShort(
  date: Date,
  timeZone?: string,
): string {
  const formatter = new Intl.DateTimeFormat(i18n.locale, {
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    month: "short",
    timeZone,
    weekday: "short",
    year: "numeric",
  });

  return formatter.format(date);
}

// Wednesday
export function formatWeekday(date: Date, timeZone?: string): string {
  const formatter = new Intl.DateTimeFormat(i18n.locale, {
    timeZone,
    weekday: "long",
  });

  return formatter.format(date);
}

// Mon, Nov 28, 2022, 5:30 AM PST
export function formatWeekDateTimeZone(
  date: Date,
  timeZone?: string,
): string {
  const formatter = new Intl.DateTimeFormat(i18n.locale, {
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    month: "short",
    timeZone,
    timeZoneName: "short",
    weekday: "short",
    year: "numeric",
  });

  return formatter.format(date);
}
