import { t, Trans } from "@lingui/macro";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../constants";
import PageTitle from "../../lib/PageTitle";
import ProfileButton from "../../lib/ProfileButton";
import Skeleton from "../../lib/Skeleton";
import Title from "../../lib/Title";
import PageLayout, {
  Body,
  Footer,
  Sidebar,
  Header,
} from "../PageLayout";
import ScrollHandler from "../ScrollHandler";
import { useGetProgramsCategories } from "./hooks/categories";

const Svg = styled.svg``;
const StyledLink = styled(Link)``;
const LI = styled.li`
  ${StyledLink} {
    align-items: center;
    box-sizing: border-box;
    color: ${colors.black};
    display: inline-flex;
    font-weight: 500;
    justify-content: space-between;
    padding: 20px 0;
    text-decoration: none;
    width: 100%;

    ${Svg} {
      color: ${colors.brownGrey};
    }

    &:focus-within,
    &:active {
      color: ${colors.blue};
      ${Svg} {
        color: currentColor;
      }
    }
  }
`;
const UL = styled.ul`
  font-size: 18px;
  line-height: 1.33;
  list-style-type: none;
  margin: 10px 0 0;
  padding: 0;
`;
const Container = styled.div`
  max-width: var(--content-max-width);
  padding: 0 var(--content-margin-side);
`;

const ProgramsListPage = () => {
  const { data, isLoading } = useGetProgramsCategories();

  return (
    <PageLayout
      footer={<Footer />}
      header={
        <Header>
          <PageTitle>{t`Covered Programs`}</PageTitle>

          <Title level="h1">
            <Trans>Covered Programs</Trans>
          </Title>

          <ProfileButton />
        </Header>
      }
      sidebar={<Sidebar />}
    >
      <Body>
        <Skeleton active css="padding: 50px;" loading={isLoading}>
          <Container>
            <ScrollHandler>
              {data && data.length > 0 && (
                <UL>
                  {data.map((item) => (
                    <LI key={item.id}>
                      <StyledLink to={`/programs/${item.slug}`}>
                        <span>{item.name}</span>
                        <Svg
                          fill="none"
                          height="0.89em"
                          viewBox="0 0 8 16"
                          width="0.445em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 15L7 8L0.999999 1"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                        </Svg>
                      </StyledLink>
                    </LI>
                  ))}
                </UL>
              )}
            </ScrollHandler>
          </Container>
        </Skeleton>
      </Body>
    </PageLayout>
  );
};

export default ProgramsListPage;
