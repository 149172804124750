import { t } from "@lingui/macro";
import React from "react";
import styled, { css } from "styled-components";
import { colors } from "../../constants";
import Button from "../../lib/Button";
import mediaQuery from "../../utils/mediaQuery";
import { ClinicEntityWithRelations } from "./models/Clinic";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 18px 20px 20px;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      flex-wrap: nowrap;
      padding-block: 30px;
      padding-inline-end: 20px;
      padding-inline-start: 40px;
    `,
  )}
`;

const Image = styled.div`
  align-items: center;
  border-inline-end: 1px solid ${colors.brownLightGrey1};
  display: flex;
  flex: none;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 0;
  justify-content: center;
  line-height: 0;
  margin-inline-end: 20px;
  padding-inline-end: 20px;
  width: 40px;

  img {
    height: auto;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }

  ${mediaQuery(
    "greaterThanPhone",
    css`
      margin-inline-end: 30px;
      padding-inline-end: 20px;
      padding-inline-start: 20px;
      width: 180px;
    `,
  )}
`;

const Description = styled.div`
  flex-grow: 1;
  font-size: 15px;
  max-width: 160px;
  padding: 0;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      max-width: initial;
      padding-inline-end: 15px;
    `,
  )}
`;

const Title = styled.h5`
  font-size: 15px;
  font-weight: bold;
  margin: 0 0 12px;
`;

const SubTitle = styled.div`
  color: ${colors.brownGrey};
`;

const Address = styled.div`
  color: ${colors.brownGrey};
`;

const ButtonContainer = styled.div`
  align-items: flex-end;
  display: flex;
  margin-top: 28px;

  width: 100%;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      margin-top: 0;
      min-width: 158px;
      width: auto;
    `,
  )}
`;

interface Props extends ClinicEntityWithRelations {
  programType: string;
}

const LayoutProvider: React.FC<Props> = ({
  attributes,
  relationships,
  programType,
}: Props) => {
  const buttonText = [
    "backup_care",
    "elderly_care",
    "mental_health",
  ].includes(programType)
    ? t`Find care`
    : t`See more`;

  return (
    <Container>
      {attributes.image_set && (
        <Image>
          <img
            alt={attributes.title}
            height={attributes.image_set.aspect_ratio.split(":")[1]}
            sizes="(max-width: 767px) 40px, 180px"
            src={attributes.image_set.w256}
            srcSet={`${attributes.image_set.w64} 64w, ${attributes.image_set.w128} 128w, ${attributes.image_set.w256} 256w, ${attributes.image_set.w512} 512w`}
            width={attributes.image_set.aspect_ratio.split(":")[0]}
          />
        </Image>
      )}
      <Description>
        <Title>{attributes.title}</Title>

        {relationships.locations[0] ? (
          <Address>
            {relationships.locations[0].attributes.address}
          </Address>
        ) : (
          <SubTitle>{attributes.subtitle}</SubTitle>
        )}
      </Description>
      {attributes.url && (
        <ButtonContainer>
          <Button
            as="a"
            href={attributes.url}
            kind="filledGreen"
            rel="noopener noreferrer"
            target="_blank"
          >
            {buttonText}
          </Button>
        </ButtonContainer>
      )}
    </Container>
  );
};

export default LayoutProvider;
