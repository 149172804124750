import * as React from "react";
import { forwardRef, InputHTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { colors, fonts } from "../../../../constants";
import Icon from "../../../SharedIcon";
import { useFormFieldContext } from "../../FormField/context";

export const CheckMarkBackPart = styled.span`
  position: relative;
`;
export const CheckMarkFrontPart = styled(Icon)`
  color: ${colors.white};
`;
export const Input = styled.input``;

// TODO: onHover styles
export const Label = styled.label<{
  $hasError: boolean;
  $networkStyle: boolean;
}>`
  align-items: center;
  color: ${colors.black};
  cursor: pointer;
  display: inline-flex;
  font-family: ${fonts.main};
  font-size: 16px;
  line-height: 160%;
  position: relative;
  user-select: none;

  ${Input} {
    box-sizing: border-box;
    cursor: pointer;
    inset: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    z-index: 1;
  }

  ${CheckMarkFrontPart} {
    display: none;
  }

  ${CheckMarkBackPart} {
    align-items: center;
    color: ${colors.brownLightGrey1};
    display: inline-flex;
    justify-content: center;
    margin-inline-end: 8px;
    &::before {
      background: ${colors.white};
      border: 1px solid currentColor;
      border-radius: 2px;
      box-sizing: border-box;
      content: "";
      cursor: pointer;
      height: 18px;
      position: relative;
      width: 18px;
    }
  }

  ${Input}:checked+${CheckMarkBackPart} {
    color: ${colors.brownBlack};
    &::before {
      background: currentColor;
    }
    ${CheckMarkFrontPart} {
      display: initial;
      position: absolute;
    }
  }
  ${Input}:disabled+${CheckMarkBackPart} {
    color: ${colors.brownLightGrey2};
    &::before {
      border-color: ${colors.brownLightGrey1};
    }
    ${CheckMarkFrontPart} {
      color: ${colors.brownLightGrey1};
    }
  }

  ${({ $networkStyle }) =>
    $networkStyle
      ? css`
          ${CheckMarkBackPart} {
            color: #4c4c4c;
            &::before {
              border-width: 2px;
            }
          }

          ${Input}:checked+${CheckMarkBackPart} {
            color: ${colors.blue};
          }
        `
      : undefined}

  ${({ $hasError }) =>
    $hasError
      ? css`
          ${CheckMarkBackPart} {
            color: ${colors.red};
            &:before {
              border-width: 2px;
              color: ${colors.red};
            }
          }
        `
      : undefined}
`;

export interface CheckboxProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "type"> {
  label: string;
  networkStyle?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  as?: React.ComponentType<any> | string;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => {
    const {
      children,
      className,
      style,
      disabled,
      label,
      networkStyle,
      as = "label",
      "aria-invalid": ariaInvalid,
      ...restProps
    } = props;

    const invalid = !!ariaInvalid && ariaInvalid !== "false";
    const formFieldContextValue = useFormFieldContext();

    const hasError = invalid || formFieldContextValue["aria-invalid"];
    return (
      <Label
        $hasError={!!hasError}
        $networkStyle={!!networkStyle}
        as={as}
        className={className}
        style={style}
      >
        <Input
          {...restProps}
          ref={ref}
          aria-invalid={
            ariaInvalid || formFieldContextValue["aria-invalid"]
          }
          disabled={disabled}
          type="checkbox"
        />
        <CheckMarkBackPart>
          <CheckMarkFrontPart
            aria-hidden
            height="9px"
            type="check"
            width="10px"
          />
        </CheckMarkBackPart>

        {label}
      </Label>
    );
  },
);

export default Checkbox;
