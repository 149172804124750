import { TypeOf } from "io-ts";
import { useRef } from "react";
import styled from "styled-components";
import { ImageSet } from "../../../io-ts/types";
import useImageLoaded from "../../../utils/useImageLoaded";

const Container = styled.div`
  position: relative;
  background-color: #e5e5e5;
  overflow: hidden;
`;

const Image = styled.img`
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
  display: block;
`;

const Preview = styled.img`
  display: block;
  height: auto;
  width: 100%;
  position: absolute;
  top: 0;
  object-fit: cover;
  object-position: center;
  height: 100%;
  filter: blur(4px);
`;

interface Props {
  imageSet: TypeOf<typeof ImageSet>;
  alt: string;
  className?: string;
}

const PreviewPic: React.FC<Props> = ({
  imageSet,
  alt,
  className,
}: Props) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const isLoaded = useImageLoaded(imageRef);
  const [width, height] = imageSet.aspect_ratio.split(":");

  return (
    <Container className={className}>
      <Image
        ref={imageRef}
        alt={alt}
        height={height}
        sizes="(max-width: 1200px) 210px, 254px"
        src={imageSet.w256}
        srcSet={`${imageSet.w128} 128w, ${imageSet.w256} 256w, ${imageSet.w512} 512w, ${imageSet.w800} 800w`}
        width={width}
      />
      <Preview
        alt={alt}
        aria-hidden="true"
        height={height}
        src={imageSet.w64}
        style={{ opacity: isLoaded ? 0 : 1 }}
        width={width}
      />
    </Container>
  );
};

export default PreviewPic;
