import { useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAxios } from "../../network";
import { ENDPOINTS } from "../../network/endpoints";
import { JSONApiErrorsException } from "../../network/jsonApi/core/JSONApiErrorsPayload";
import Error500Screen from "../Error500Screen";
import GlobalSpinner from "../GlobalSpinner";

export default function EmailChangingConfirmation() {
  const [searchParams] = useSearchParams();
  const confirmationToken = searchParams.get("confirmation_token");

  const navigate = useNavigate();

  const axios = useAxios();
  const { error } = useQuery(
    ["emailChangingConfirmation", confirmationToken],
    async () => {
      const urlSearchParams = new URLSearchParams();
      urlSearchParams.append("token", confirmationToken || "");
      return axios.put(
        `${
          ENDPOINTS.checkEmailChangeToken
        }?${urlSearchParams.toString()}`,
      );
    },
    {
      onSuccess: () => {
        sessionStorage.setItem("emailChanged", "true");
        navigate("/profile");
      },
    },
  );

  if (error instanceof JSONApiErrorsException) {
    const firstError = error.errors[0];
    return (
      <Error500Screen
        code={error.status}
        message={firstError?.title ?? "Unknown error"}
      />
    );
  }
  if (error) {
    // eslint-disable-next-line no-throw-literal
    throw error as Error;
  } else {
    return <GlobalSpinner />;
  }
}
