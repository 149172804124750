/* eslint-disable react/destructuring-assignment */
import { FC } from "react";
import { colors } from "../../../constants";

export type Props = {
  className?: string;
  type: "critical" | "error" | "info" | "success";
  size?: string;
};
type IconProps = {
  className?: string;
  size: string;
};

const IconSuccess = (props: IconProps) => (
  <svg
    className={props.className}
    fill="none"
    height={props.size}
    viewBox="0 0 22 22"
    width={props.size}
  >
    <path
      clipRule="evenodd"
      d="M11 22c6.0751 0 11-4.9249 11-11S17.0751 0 11 0 0 4.9249 0 11s4.9249 11 11 11Z"
      fill={colors.greenStable}
      fillRule="evenodd"
    />
    <path
      d="M7 10.6923 9.8007 15l4.7994-7.3846L15 7"
      stroke="#000"
      strokeLinejoin="bevel"
      strokeWidth="1.5"
    />
  </svg>
);

const IconInfo = (props: IconProps) => (
  <svg
    className={props.className}
    fill="none"
    height={props.size}
    viewBox="0 0 22 22"
    width={props.size}
  >
    <path
      clipRule="evenodd"
      d="M11 22c6.0751 0 11-4.9249 11-11S17.0751 0 11 0 0 4.9249 0 11s4.9249 11 11 11Z"
      fill={colors.gold}
      fillRule="evenodd"
    />
    <path
      d="M11.6727 15.2188h-1.3556V9.104h1.3556v6.1148Zm-1.4393-7.7029c0-.211.0651-.3862.1952-.5255.1339-.1395.3236-.2092.5691-.2092.2454 0 .4351.0697.569.2092.1338.1393.2008.3145.2008.5255 0 .2073-.067.3805-.2008.52-.1339.1356-.3236.2034-.569.2034-.2455 0-.4352-.0678-.5691-.2035-.1301-.1394-.1952-.3126-.1952-.5199Z"
      fill="#000"
    />
  </svg>
);

const IconError = (props: IconProps) => (
  <svg
    className={props.className}
    fill="none"
    height={props.size}
    viewBox="0 0 22 22"
    width={props.size}
  >
    <path
      clipRule="evenodd"
      d="M11 22c6.0751 0 11-4.9249 11-11S17.0751 0 11 0 0 4.9249 0 11s4.9249 11 11 11Z"
      fill={colors.coralStable}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M10.327 6h1.6405l-.1277 6.1724h-1.3851L10.327 6Zm.817 9.3384c-.517 0-.9255-.4022-.9255-.9064 0-.5043.4085-.9.9255-.9.5235 0 .932.3957.932.9 0 .5042-.4085.9064-.932.9064Z"
      fill="#000"
      fillRule="evenodd"
    />
  </svg>
);

const IconCritical = (props: IconProps) => (
  <svg
    className={props.className}
    fill="none"
    height={props.size}
    viewBox="0 0 22 22"
    width={props.size}
  >
    <path
      clipRule="evenodd"
      d="M11 22c6.0751 0 11-4.9249 11-11S17.0751 0 11 0 0 4.9249 0 11s4.9249 11 11 11Z"
      fill={colors.coralStable}
      fillRule="evenodd"
    />
    <path d="M7 11h8" stroke="#000" strokeWidth="1.5" />
  </svg>
);

const Icon: FC<Props> = ({ className, type, size = "22" }) => {
  switch (type) {
    case "success":
      return <IconSuccess className={className} size={size} />;
    case "info":
      return <IconInfo className={className} size={size} />;
    case "error":
      return <IconError className={className} size={size} />;
    case "critical":
      return <IconCritical className={className} size={size} />;
    default:
      return null;
  }
};

export default Icon;
