import styled from "styled-components";
import { colors } from "../../../../constants/colors";
import hexColorOpacity from "../../../../utils/hexColorOpacity";

export const DropdownIndicator = styled.div<{
  $isDisabled?: boolean;
}>`
  box-sizing: border-box;
  color: ${({ $isDisabled }) =>
    $isDisabled
      ? hexColorOpacity(colors.brownBlack, 0.5)
      : colors.brownBlack};
  display: flex;
  padding: 18px;
`;

export const DropdownIndicatorIcon = () => (
  <svg
    fill="none"
    height="7"
    viewBox="0 0 9 7"
    width="9"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M0.990487 2.04076C0.562659 1.37525 1.0405 0.5 1.83167 0.5H7.16833C7.9595 0.5 8.43734 1.37525 8.00951 2.04076L5.34118 6.1915C4.94754 6.80382 4.05246 6.80382 3.65882 6.1915L0.990487 2.04076Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
