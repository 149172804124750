import { t } from "@lingui/macro";
import { FC, HTMLAttributes, useMemo } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../../constants";
import Title from "../../../../lib/Title";
import mediaQuery from "../../../../utils/mediaQuery";
import { CallEntityWithRelations } from "../../../CallSubjects/models/Call";
import { CallSubjectEntityWithRelations } from "../../../CallSubjects/models/CallSubject";
import CallSubjects from "../CallSubjects";
import { HomePagePost } from "../Post";
import Posts from "../Posts";
import Programs from "../Programs";
import { ProgramProps } from "../Programs/Program";
import Present from "./present";
import Sheet from "./sheet";

export interface HomePagePolicy {
  attributes: {
    name: string;
    body: string;
    policy_type: string;
  };
  id: string;
  type: "policy";
}
export interface HomePageProgram {
  attributes: {
    name: string;
    program_type: string;
  };
  id: string;
  type: "program";
  relationships: {
    program_category: {
      id: string;
    };
  };
}
export interface HomePageBlock {
  id: string;
  attributes: {
    title: string;
  };
  relationships: {
    future_calls?: readonly CallEntityWithRelations[];
    call_subjects: readonly CallSubjectEntityWithRelations[];
    programs: readonly HomePageProgram[];
    posts: readonly HomePagePost[];
    policies: readonly HomePagePolicy[];
  };
}

export interface BlockProps extends HTMLAttributes<HTMLElement> {
  block: HomePageBlock;
}

const BlockTitle = styled(Title)`
  border-bottom: 1px solid #e0ddd9;
  padding-bottom: 20px;
`;

const Parts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;

  ${mediaQuery(
    "desktopMedium",
    css`
      gap: 60px;
    `,
  )}
`;
const Container = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.brownLightGrey1};
  border-radius: 10px;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 5%);
  box-sizing: border-box;
  margin: 20px 0;
  max-width: 800px;
  padding: 20px 20px;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin: 30px 0 40px;
      padding: 40px 60px;
    `,
  )}

  ${BlockTitle} {
    margin-bottom: 30px;

    ${mediaQuery(
      "desktopMedium",
      css`
        margin-bottom: 40px;
      `,
    )}
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledSheet = styled(Sheet)`
  color: ${colors.green};
  display: block;
  height: 50px;
  margin: 0 auto;
  width: 39px;
`;
const StyledPresent = styled(Present)`
  color: ${colors.green};
  display: block;
  height: 50px;
  margin: 0 auto;
  width: 40px;

  ${mediaQuery(
    "desktopMedium",
    css`
      height: 48px;
      width: 51px;
    `,
  )}
`;

const Block: FC<BlockProps> = ({ block, ...restProps }) => {
  const programs = useMemo(() => {
    return block.relationships.programs.map(
      (program): ProgramProps => {
        return {
          description: t`Program sponsorship`,
          icon: <StyledPresent />,
          id: program.id,
          title: program.attributes.name,
          url: `/programs/${program.relationships.program_category.id.replaceAll(
            "_",
            "-",
          )}/${program.attributes.program_type.replaceAll("_", "-")}`,
        };
      },
    );
  }, [block.relationships.programs]);
  const policies = useMemo(() => {
    return block.relationships.policies.map(
      (policy): ProgramProps => {
        return {
          description: t`Connect with HR and your supervisor to make sure you're on the same page.`,
          icon: <StyledSheet />,
          id: policy.id,
          title: policy.attributes.name,
          url: `/company-policies/${policy.attributes.policy_type.replaceAll(
            "_",
            "-",
          )}`,
        };
      },
    );
  }, [block.relationships.policies]);
  return (
    <Container {...restProps}>
      <BlockTitle level="h2">{block.attributes.title}</BlockTitle>
      <Parts>
        {block.relationships.posts.length > 0 && (
          <Posts posts={block.relationships.posts} />
        )}
        {(!!block.relationships.call_subjects?.length ||
          !!block.relationships.future_calls?.length) && (
          <CallSubjects
            calls={block.relationships.future_calls}
            callSubjects={block.relationships.call_subjects}
          />
        )}
        {programs.length > 0 && (
          <Programs
            programs={programs}
            title={t`Explore your coverage`}
          />
        )}
        {policies.length > 0 && (
          <Programs
            programs={policies}
            title={t`View your company's policies`}
          />
        )}
      </Parts>
    </Container>
  );
};

export default Block;
