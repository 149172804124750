import { FC } from "react";
import { createGlobalStyle, css } from "styled-components";
import normalize from "styled-normalize";
import FaktumMediumWoff from "../assets/fonts/38C82F_0_0.woff";
import FaktumMediumWoff2 from "../assets/fonts/38C82F_0_0.woff2";
import FaktumNormalWoff from "../assets/fonts/38C82F_1_0.woff";
import FaktumNormalWoff2 from "../assets/fonts/38C82F_1_0.woff2";
import DuplicateSansBoldWoff from "../assets/fonts/DuplicateSans-Bold-Web.woff";
import DuplicateSansBoldWoff2 from "../assets/fonts/DuplicateSans-Bold-Web.woff2";
import { fonts } from "../constants";
import mediaQuery from "./mediaQuery";

const BaseGlobalStyles = createGlobalStyle`
  ${normalize}

  @font-face {
    font-family: "Duplicate Sans";
    src: url("${DuplicateSansBoldWoff2}") format("woff2"),
    url("${DuplicateSansBoldWoff}") format("woff");
    font-weight: bold;
  }

  @font-face {
    font-family: "Faktum";
    font-weight: 500;
    src: url("${FaktumMediumWoff2}") format("woff2"),
    url("${FaktumMediumWoff}") format("woff");
  }

  @font-face {
    font-family: "Faktum";
    font-weight: 400;
    src: url("${FaktumNormalWoff2}") format("woff2"),
    url("${FaktumNormalWoff}") format("woff");
  }

  html, body, #root {
    font-family: ${fonts.main};
    font-size: 15px;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  :root {
    /* for debugging */
    --project-name: "product";
    --font-alt: "Duplicate Sans", sans-serif;
    --font-faktum: "Faktum", sans-serif;
    --font-main: "Roboto", sans-serif;

    &[lang="ko"] {
      --font-alt: "Noto Sans KR", sans-serif;
      --font-faktum: "Noto Sans KR", sans-serif;
      --font-main: "Noto Sans KR", sans-serif;
    }

    --content-margin-side: 20px;
    --content-max-width: auto;
    ${mediaQuery(
      "greaterThanPhone",
      css`
        --content-margin-side: 40px;
        --content-max-width: 448px;
      `,
    )}
     ${mediaQuery(
       "greaterThanTablet",
       css`
         --content-margin-side: 40px;
         --content-max-width: 650px;
       `,
     )}
    ${mediaQuery(
      "desktopMedium",
      css`
        --content-margin-side: 80px;
        --content-max-width: 800px;
      `,
    )}
  }


  /* Hide CookieYes default icon */
  .cky-btn-revisit-wrapper {
    display: none !important;
  }

  .button-icon-container {
    font-size: 1.25em;
  }
`;

const FullHeight = createGlobalStyle`
  html, body, #root {
    min-height: 100%;
    height: 1px;
  }
  html {
    scroll-behavior: smooth; /* makes all */

    &[dir="rtl"] .force-ltr-to-right-align {
      text-align: right;
    }
  }
`;

const GlobalStyles: FC = () => (
  <>
    <FullHeight />
    <BaseGlobalStyles />
  </>
);
export default GlobalStyles;
