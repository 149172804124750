import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { Auth, Reimb } from "../../hooks/useGetMainPageNotifications";
import MainPageWidget from "./MainPageWidget";
import NoNewWidget from "./NoNewWidget";
import NotificationsNothingYet from "./NothingYetWidget";

const localStorageKey = "STORK_NOTIFICATIONS_WIDGET_CLOSED";
const notificationsWasClosed =
  localStorage.getItem(localStorageKey) === "true";

export const Container = styled.div``;

type Props = {
  notifications: Array<Auth | Reimb>;
};

export const NotificationsWidget: React.FC<Props> = ({
  notifications,
}: Props) => {
  const [isClosed, setClosed] = useState<boolean>(false);

  const onClose = useCallback(() => {
    const { localStorage } = window;
    localStorage.setItem(localStorageKey, "true");
    setClosed(true);
  }, []);

  // widget manually closed
  if (isClosed) return null;

  // have some notifications, show widget
  if (notifications.length > 0) {
    return (
      <Container>
        <MainPageWidget items={notifications} />
      </Container>
    );
  }

  // no notifications, but widget've never been closed
  if (!notifications.length && !notificationsWasClosed) {
    return (
      <Container>
        <NotificationsNothingYet onClose={onClose} />
      </Container>
    );
  }

  return (
    <Container>
      <NoNewWidget />
    </Container>
  );
};
