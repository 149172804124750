import React from "react";
import styled, { css } from "styled-components";
import LinkToTop from "../../../lib/LinkToTop";
import { Text } from "../../../lib/Text";
import mediaQuery from "../../../utils/mediaQuery";
import CategoryBadge from "../CategoryBadge";
import { Article } from "../models/Article";
import PreviewPic from "./PreviewPic";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 210px;
  ${mediaQuery(
    "desktopMedium",
    css`
      flex: 0;
      width: 254px;
    `,
  )}
`;

const StyledPreviewPic = styled(PreviewPic)`
  height: 150px;

  ${mediaQuery(
    "desktopMedium",
    css`
      height: 180px;
    `,
  )}
`;

const Info = styled.div`
  background-color: #fff;
  flex: 1;
  padding: 16px;
`;

const CategoryBadgeContainer = styled.div`
  margin-bottom: 12px;
`;

const Item: React.FC<Article> = ({
  category,
  imageSet,
  name,
  url,
}: Article) => (
  <Container>
    <LinkToTop to={url}>
      <StyledPreviewPic alt={name} imageSet={imageSet} />
    </LinkToTop>
    <Info>
      <CategoryBadgeContainer>
        <CategoryBadge {...category} />
      </CategoryBadgeContainer>
      <LinkToTop css="text-decoration: none" to={url}>
        <Text as="h3" textType="bodyMedium">
          {name}
        </Text>
      </LinkToTop>
    </Info>
  </Container>
);

export default Item;
