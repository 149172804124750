import { isLeft } from "fp-ts/Either";
import * as t from "io-ts";
import { useQuery } from "react-query";
import { UseQueryResult } from "react-query/types/react/types";
import { ImageSet, ISODate } from "../../../io-ts/types";
import { wrongResponseFormat } from "../../../locales/errors";
import { useAxios } from "../../../network";
import { Article, Block } from "../models/Article";
import {
  IncludedPostBlockC,
  IncludedPostBlock,
} from "../models/IncludedPostBlock";
import {
  IncludedPostCategoryC,
  IncludedPostCategory,
} from "../models/IncludedPostCategory";

const IncludedPostC = t.type({
  type: t.literal("post"),
});

export const responseC = t.type({
  data: t.type({
    attributes: t.type({
      image_set: ImageSet,
      intro: t.string,
      name: t.string,
      published_at: ISODate,
      show_cover: t.boolean,
    }),
    id: t.string,
    type: t.literal("post"),
  }),
  included: t.array(
    t.union([
      IncludedPostBlockC,
      IncludedPostC,
      IncludedPostCategoryC,
    ]),
  ),
});

function responseToEntities(
  response: t.TypeOf<typeof responseC>,
): Article {
  const { data, included } = response;

  const category = included.find(
    (item): item is IncludedPostCategory =>
      item.type === "post_category",
  )?.attributes;
  if (!category) {
    throw Error("category not found");
  }

  const blocks = included
    .filter(
      (item): item is IncludedPostBlock => item.type === "post_block",
    )
    .map((item) => {
      let block: Block | null;
      switch (item.attributes.media) {
        case "body": {
          block = {
            id: item.id,
            text: item.attributes.body,
            type: "text",
          };
          break;
        }
        case "image": {
          block = {
            id: item.id,
            imageSet: item.attributes.image_set,
            type: "image",
          };
          break;
        }
        case "embed": {
          block = {
            embed: item.attributes.embed,
            id: item.id,
            type: "embed",
          };
          break;
        }
        case "photo": {
          block = {
            id: item.id,
            imageSet: item.attributes.image_set,
            type: "photo",
          };
          break;
        }
        case null: {
          block = null;
          break;
        }
      }

      return block;
    })
    .filter(<T>(item: T | null | undefined): item is T => !!item);

  return {
    blocks,
    category,
    id: data.id,
    imageSet: data.attributes.image_set,
    intro: data.attributes.intro,
    name: data.attributes.name,
    publishedAt: data.attributes.published_at,
    showCover: data.attributes.show_cover,
    url: `/articles/${data.id}`,
  };
}

const useGetArticle = (id: string): UseQueryResult<Article> => {
  const axios = useAxios();
  return useQuery(["getArticles", id], async () => {
    const response = await axios.get(`/v2/posts/${id}`);

    const decodeResult = responseC.decode(response.data);

    if (isLeft(decodeResult)) {
      // eslint-disable-next-line no-console
      console.error(decodeResult);
      throw wrongResponseFormat;
    }

    return responseToEntities(decodeResult.right);
  });
};

export default useGetArticle;
