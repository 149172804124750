import { ReactNode, Suspense } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import {
  ArrowLeftIcon,
  LinkBackIcon,
} from "../../components/ProfileCompletion/Icons";
import { colors } from "../../constants";
import mediaQuery from "../../utils/mediaQuery";
import { Logo } from "../Logo";
import Icon from "../SharedIcon";
import Skeleton from "../Skeleton";

const LinkBackDesktopContainer = styled.div`
  margin: 0 auto 20px;
  max-width: 728px;

  ${mediaQuery(
    "phone",
    css`
      display: none;
    `,
  )}
`;

const LinkBackMobileContainer = styled.div<{
  isCrossIcon: boolean;
}>`
  display: ${({ isCrossIcon }) => (isCrossIcon ? "flex" : "block")};
  justify-content: flex-end;
  margin-bottom: 14px;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      display: none;
    `,
  )}
`;

const LinkBackDesktop = styled(Link)`
  align-items: center;
  color: ${colors.blue};
  display: inline-flex;
  text-decoration: none;

  svg {
    margin-inline-end: 10px;
  }

  &:hover {
    color: ${colors.black};
  }

  &:active {
    color: ${colors.brownBlack};
  }
`;

const LinkBackMobile = styled(Link)`
  display: inline-block;
  font-size: 0;
  margin-inline-start: 17px;

  svg {
    height: 24px;
    width: 24px;
  }

  ${mediaQuery(
    "greaterThanPhone",
    css`
      display: none;
    `,
  )}
`;
const LogoContainer = styled(Link)`
  display: inline-block;
  font-size: 0;
  margin-inline: 30px 0;
  margin-top: 30px;

  ${mediaQuery(
    "phone",
    css`
      display: none;
    `,
  )}
`;
const Card = styled.div<{
  desktopSidePaddingSize: number;
  shouldShowMobileBorder: boolean;
}>`
  border: ${({ shouldShowMobileBorder }) =>
    shouldShowMobileBorder
      ? `1px solid ${colors.brownLightGrey1}`
      : "none"};
  border-radius: 10px;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 728px;
  padding: ${({ shouldShowMobileBorder }) =>
    shouldShowMobileBorder ? "24px 20px" : "0 16px 20px"};

  ${({ desktopSidePaddingSize }) =>
    mediaQuery(
      "greaterThanPhone",
      css`
        border: 1px solid ${colors.brownLightGrey1};
        padding: 40px ${desktopSidePaddingSize}px;
      `,
    )}
`;
const Container = styled.div<{
  shouldShowMobileBorder: boolean;
}>`
  padding: ${({ shouldShowMobileBorder }) =>
    shouldShowMobileBorder ? "12px 20px 0" : "12px 0 0"};

  ${mediaQuery(
    "greaterThanPhone",
    css`
      padding: 40px 0 30px;
    `,
  )}
`;

export type ModalScreenCardProps = {
  desktopSidePaddingSize?: number;
  shouldShowMobileBorder?: boolean;
  crossIcon?: boolean;
  children: ReactNode;
  backLinkTitle?: ReactNode;
  backLinkUrl?: string;
};

export default function ModalScreenCard(props: ModalScreenCardProps) {
  const {
    children,
    backLinkTitle,
    backLinkUrl,
    desktopSidePaddingSize = 60,
    shouldShowMobileBorder = false,
    crossIcon = false,
  } = props;
  return (
    <>
      <LogoContainer to="/">
        <Logo fill={colors.brownLightGrey1} />
      </LogoContainer>
      <Container shouldShowMobileBorder={shouldShowMobileBorder}>
        {backLinkUrl && (
          <>
            {backLinkTitle && (
              <LinkBackDesktopContainer>
                <LinkBackDesktop to={backLinkUrl}>
                  <LinkBackIcon />
                  {backLinkTitle}
                </LinkBackDesktop>
              </LinkBackDesktopContainer>
            )}
            <LinkBackMobileContainer isCrossIcon={crossIcon}>
              <LinkBackMobile to={backLinkUrl}>
                {crossIcon ? (
                  <Icon mainColor={colors.brownGrey} type="cross" />
                ) : (
                  <ArrowLeftIcon />
                )}
              </LinkBackMobile>
            </LinkBackMobileContainer>
          </>
        )}
        <Card
          desktopSidePaddingSize={desktopSidePaddingSize}
          shouldShowMobileBorder={shouldShowMobileBorder}
        >
          <Suspense fallback={<Skeleton active loading />}>
            {children}
          </Suspense>
        </Card>
      </Container>
    </>
  );
}
