import { FC } from "react";
import { Authorization } from "../../../models/Authorization";
import { Bill } from "../../../models/Bill";
import { Status } from "../../../models/Status";
import { UserPlan } from "../../../models/UserPlan";
import { BillAvailable } from "./BodyBillAvailable";
import { BillPaid } from "./BodyBillPaid";
import { BillPaymentFailed } from "./BodyBillPaymentFailed";
import { Blank } from "./BodyBlank";
import { RxCanceled } from "./BodyRxCanceled";
import { RxGranted } from "./BodyRxGranted";
import { RxRejected } from "./BodyRxRejected";
import { RxRequested } from "./BodyRxRequested";
import { Service } from "./BodyService";

export type Props = {
  authorization: Authorization;
  userPlan: UserPlan;
  status: Status;
};

type BillProps = {
  authorization: Authorization;
  userPlan: UserPlan;
  bill: Bill;
  status: Status;
};

export const Body: FC<Props> = (props: Props) => {
  const { authorization, status } = props;

  const { practiceTemplate } = authorization;

  const isGranted = status === "granted";
  const isRequested = status === "requested";
  const isRejected = status === "rejected";
  const isCanceled = status === "canceled";
  const isExpired = status === "expired";

  const isRx = practiceTemplate === "pharmacy";
  const isServiceBody = !isRx && (isGranted || isRequested);
  const isRxRequestedBody = isRx && isRequested;
  const isRxGrantedBody = isRx && isGranted;
  const isRxCanceledBody = isRx && isCanceled;
  const isRxRejectedBody = isRx && isRejected;
  const isRxExpired = isRx && isExpired;
  const isBlankBody =
    !isRx && (isExpired || isCanceled || isRejected);

  return (
    <>
      {isServiceBody && <Service {...props} />}
      {isRxRequestedBody && <RxRequested {...props} />}
      {isRxCanceledBody && <RxCanceled {...props} />}
      {isRxGrantedBody && <RxGranted {...props} />}
      {isRxRejectedBody && <RxRejected {...props} />}
      {isRxExpired && <Blank {...props} />}
      {isBlankBody && <Blank {...props} />}
    </>
  );
};

export const BodyBill: FC<BillProps> = (props: BillProps) => {
  const { status } = props;

  const isBillAvailable = status === "billAvailable";
  const isBillPaymentInit = status === "billPaymentInit";
  const isBillPaymentDueSoon = status === "billPaymentDueSoon";
  const isBillPaymentOverdue = status === "billPaymentOverdue";
  const isBillPaymentFailed = status === "billPaymentFailed";
  const isBillPaid = status === "billPaid";

  return (
    <>
      {isBillAvailable && <BillAvailable {...props} />}
      {isBillPaymentInit && <BillAvailable {...props} />}
      {isBillPaymentDueSoon && <BillAvailable {...props} />}
      {isBillPaymentOverdue && <BillAvailable {...props} />}
      {isBillPaymentFailed && <BillPaymentFailed {...props} />}
      {isBillPaid && <BillPaid {...props} />}
    </>
  );
};
