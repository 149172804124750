import { Trans, t } from "@lingui/macro";
import { FC, KeyboardEvent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors } from "../../../constants";
import Input from "../../../lib/forms/primitives/Input";
import Icon from "../../../lib/Icon";
import ProfileButton from "../../../lib/ProfileButton";
import Title from "../../../lib/Title";
import mediaQuery from "../../../utils/mediaQuery";
import { Header } from "../../PageLayout";

export const Inner = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const StyledInput = styled(Input)`
  margin-top: 15px;
  width: 100%;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      margin-top: 0;
      width: 280px;
    `,
  )}
`;

export const BackButton = styled.button`
  background: none;
  border: none;
  display: none;
  margin: 0;
  margin-bottom: 14px;
  padding: 0;

  &:active,
  &:focus,
  &:hover {
    background: none;
    border: none;
  }
`;

const ArticlesHeader: FC<{ q?: string }> = ({ q }) => {
  const navigate = useNavigate();

  const onKeyDownCallback = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget;
      if (e.key === "Enter" && value) {
        const safeValue = encodeURIComponent(value);
        navigate(`/articles/search?q=${safeValue}`);
      }
    },
    [navigate],
  );

  return (
    <Header>
      <BackButton onClick={() => navigate(-1)}>
        <Icon fill={colors.brownGrey} type="back" />
      </BackButton>

      <Inner>
        <Title level="h1">
          <Trans>Articles</Trans>
        </Title>

        <ProfileButton />

        <StyledInput
          defaultValue={q}
          onKeyDown={onKeyDownCallback}
          placeholder={t`Search`}
        />
      </Inner>
    </Header>
  );
};
export default ArticlesHeader;
