import { Trans } from "@lingui/macro";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors } from "../../../constants";
import { Title } from "../../../lib";
import Button from "../../../lib/Button";
import ModalScreenCard from "../../../lib/ModalScreenCard";
import mediaQuery from "../../../utils/mediaQuery";

const TitleIcon = () => (
  <svg
    fill={colors.greenStable}
    height="1em"
    viewBox="0 0 22 22"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="11"
      cy="11"
      r="10.25"
      stroke={colors.white}
      strokeWidth="1.5"
    />
    <path
      d="M5.949 10.6331l3.5368 5.1382 6.0608-8.8083.5051-.734"
      stroke={colors.white}
      strokeLinejoin="bevel"
      strokeWidth="1.5"
    />
  </svg>
);
const StyledTitle = styled(Title)`
  align-items: center;
  display: flex;
  gap: 8px;
`;
const StyledButton = styled(Button)``;
const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  ${StyledButton} {
    align-self: stretch;
  }

  ${mediaQuery(
    "greaterThanPhone",
    css`
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      ${StyledButton} {
        align-self: unset;
      }
    `,
  )}
`;
const P = styled.p`
  color: ${colors.brownBlack};
  font-size: 15px;
  line-height: 1.6;
  margin: 0;
  padding: 0;
`;
const Container = styled.div`
  ${P} {
    margin-top: 20px;
  }
  ${ButtonsContainer} {
    margin-top: 48px;

    ${mediaQuery(
      "greaterThanPhone",
      css`
        margin-top: 40px;
      `,
    )}
  }
`;

const StyledLink = styled(Link)`
  color: ${colors.blue};
`;

const StyledSpan = styled.span`
  display: block;
`;

export interface SuccessResultProps {
  children?: never;
}

export default function ReimbursementSuccessResult(
  props: SuccessResultProps,
) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { state } = useLocation();
  let backLinkUrl = "/";
  if (
    state &&
    typeof state === "object" &&
    typeof (state as Record<string, unknown>).backLinkUrl === "string"
  ) {
    backLinkUrl = (state as Record<string, unknown>)
      .backLinkUrl as string;
  }

  return (
    <ModalScreenCard
      backLinkUrl={backLinkUrl}
      crossIcon
      desktopSidePaddingSize={40}
      shouldShowMobileBorder
    >
      <Container>
        <StyledTitle level="h1" styleLevel="h2">
          <TitleIcon />
          <Trans>Expenses submitted</Trans>
        </StyledTitle>
        <P>
          <Trans>
            Thank you! You can check on your reimbursement status on
            the&nbsp;Notifications page.
            <StyledSpan>
              We will send you an email once Stork&nbsp;Club reviews
              your request.
            </StyledSpan>
          </Trans>
        </P>
        <ButtonsContainer>
          <StyledButton
            as={Link}
            kind="outlinedCoral"
            resetWidth
            state={state as unknown}
            to="/reimbursement"
          >
            <Trans>Submit another receipt</Trans>
          </StyledButton>
          <StyledLink to="/notifications">
            <Trans>View requested expenses</Trans>
          </StyledLink>
        </ButtonsContainer>
      </Container>
    </ModalScreenCard>
  );
}
