import { isLeft } from "fp-ts/Either";
import * as t from "io-ts";
import { useQuery } from "react-query";
import { UseQueryResult } from "react-query/types/react/types";
import { wrongResponseFormat } from "../../../locales/errors";
import { useAxios } from "../../../network";
import { ArticleCategory } from "../models/ArticleCategory";
import { IncludedPostCategoryC } from "../models/IncludedPostCategory";

export const responseC = t.type({
  data: t.array(IncludedPostCategoryC),
});

function responseToEntities(
  response: t.TypeOf<typeof responseC>,
): ArticleCategory[] {
  const { data } = response;
  if (data.length === 0) return [];

  return data.map((item) => ({
    ...item.attributes,
  }));
}

type UseGetCategoriesResult = ArticleCategory[];

const useGetCategories =
  (): UseQueryResult<UseGetCategoriesResult> => {
    const axios = useAxios();
    return useQuery(
      "getCategories",
      async () => {
        const response = await axios.get(`/v2/posts/categories`);

        const decodeResult = responseC.decode(response.data);

        if (isLeft(decodeResult)) {
          // eslint-disable-next-line no-console
          console.error(decodeResult);
          throw wrongResponseFormat;
        }
        return responseToEntities(decodeResult.right);
      },
      {
        cacheTime: 300000,
        staleTime: 60000,
      },
    );
  };

export default useGetCategories;
