/* eslint-disable @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any */

import {
  Denormalized,
  IncludedData,
  JsonApiResponse,
  Relationship,
  UnpackRelationships,
} from "./types";

function unpackRelationships<
  Refs extends Record<string, Relationship | undefined> | undefined,
  U extends IncludedData[],
>(relationships: Refs, included: U): UnpackRelationships<Refs, U> {
  if (relationships === undefined) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return;
  }

  // eslint-disable-next-line consistent-return
  return Object.entries(relationships).reduce(
    (previousValue, currentValue) => {
      const relName = currentValue[0];
      const relValue = currentValue[1];
      if (!relValue) {
        return previousValue;
      }
      const relRefs = relValue.data;

      let unpackedEntities;

      if (relRefs === null) {
        unpackedEntities = null;
      } else if (Array.isArray(relRefs)) {
        unpackedEntities = relRefs.map((ref) => {
          const found = included.find(
            (item) => item.id === ref.id && item.type === ref.type,
          );

          if (found === undefined) {
            return ref;
          }
          return {
            ...found,
            relationships: unpackRelationships(
              found.relationships,
              included,
            ),
          };
        });
      } else {
        const found = included.find(
          (item) =>
            item.id === relRefs.id && item.type === relRefs.type,
        );
        if (found === undefined) {
          unpackedEntities = relRefs;
        } else {
          unpackedEntities = {
            ...found,
            relationships: unpackRelationships(
              found.relationships,
              included,
            ),
          };
        }
      }

      return {
        ...previousValue,
        [relName]: unpackedEntities,
      };
    },
    {} as unknown as UnpackRelationships<Refs, U>,
  );
}

// TODO: handle data: array
// TODO: skip relationships by mask?
export function denormalize<T extends JsonApiResponse>(
  input: T,
): Denormalized<T> {
  const { data } = input;
  if (data === null) {
    return null as Denormalized<T>;
  }
  if (Array.isArray(data)) {
    return data.map((item) => {
      return {
        attributes: item.attributes,
        id: item.id,
        relationships: unpackRelationships(
          item.relationships,
          input.included,
        ),
        type: item.type,
      };
    }) as Denormalized<T>;
  }

  return {
    attributes: data.attributes,
    id: data.id,
    relationships: unpackRelationships(
      data.relationships,
      input.included,
    ),
    type: data.type,
  } as Denormalized<T>;
}
