import { FC, SVGAttributes } from "react";

const Question: FC<SVGAttributes<SVGElement>> = ({
  ...restProps
}) => {
  return (
    <svg
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <circle cx="13" cy="13" fill="#E0C485" id="Oval" r="13" />
      <path
        d="M10.18,11.3476562 C10.2307812,9.85595703 11.2654492,8.76416016 13.1507031,8.76416016 C14.8899609,8.76416016 16.051582,9.76708984 16.051582,11.1635742 C16.051582,12.1347656 15.5691602,12.8139648 14.7058789,13.328125 C13.8743359,13.8105469 13.6394727,14.140625 13.6394727,14.7753906 L13.6394727,15.1435547 L12.1668164,15.1435547 L12.1604687,14.6928711 C12.1033398,13.753418 12.4715039,13.1821289 13.3474805,12.6679688 C14.1345898,12.1918945 14.4011914,11.8491211 14.4011914,11.2207031 C14.4011914,10.5478516 13.8679883,10.059082 13.0681836,10.059082 C12.2493359,10.059082 11.7224805,10.5605469 11.6716992,11.3476562 L10.18,11.3476562 Z M12.9221875,18.1269531 C12.4080273,18.1269531 11.9954297,17.7270508 11.9954297,17.2255859 C11.9954297,16.7241211 12.4080273,16.3305664 12.9221875,16.3305664 C13.4363477,16.3305664 13.8425977,16.7241211 13.8425977,17.2255859 C13.8425977,17.7270508 13.4363477,18.1269531 12.9221875,18.1269531 Z"
        fill="black"
        id="?"
      />
    </svg>
  );
};

export default Question;
