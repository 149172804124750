import * as t from "io-ts";
import { ISODate } from "../../../../../io-ts/types";

const PaymentC = t.type({
  // TODO: Note that
  // [payment_soon_due_date, requested_at, start_processing_at, processed_at]
  // below values are string or null types
  // but they return "2022-07-17T04:24:16.384-07:00" or null
  // It is better to use some kind of t.union([DATE, t.null]) type
  attributes: t.type({
    amount: t.string,
    covered_amount: t.string,
    due_soon: t.boolean,
    overdue: t.boolean,
    payment_soon_due_date: t.string,
    plaid_link_token: t.union([t.string, t.null]),
    processed_at: t.union([t.null, t.string]),
    provider: t.type({
      provider_name: t.string,
      provider_template: t.string,
    }),
    requested_at: t.string,
    sequential_id: t.string,
    service: t.type({
      service_date: ISODate,
      service_name: t.string,
    }),
    start_processing_at: t.union([t.null, t.string]),
    state: t.union([
      t.literal("failed"),
      t.literal("initiated"),
      t.literal("manually_collected"),
      t.literal("paid"),
      t.literal("pending"),
      t.literal("requested"),
    ]),
    total_amount: t.string,
  }),
  id: t.string,
  type: t.literal("bill"),
});

const PostPaymentC = t.type({
  accountId: t.string,
  publicToken: t.string,
  uid: t.union([t.string, t.null]),
});

export type PaymentEntity = t.TypeOf<typeof PaymentC>;
export type PostPaymentEntity = t.TypeOf<typeof PostPaymentC>;

export default PaymentC;
