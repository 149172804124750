import { Trans } from "@lingui/macro";
import * as React from "react";
import styled, { css } from "styled-components";
import { colors, fonts } from "../../../constants";
import { Text } from "../../../lib/Text";
import mediaQuery from "../../../utils/mediaQuery";
import appBoxPic from "./appBox.png";

const Container = styled.section`
  background: #fff;
  border: 1px solid ${colors.brownLightGrey1};
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px;
  position: relative;

  ${mediaQuery(
    "desktopMedium",
    css`
      padding: 40px 60px;
    `,
  )}
`;

const Title = styled.h1`
  display: flex;
  font-family: ${fonts.alt};
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
  margin-bottom: 10px;
  margin-top: 0;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 20px;
    `,
  )}
`;

const CloseButton = styled.button`
  align-items: center;
  background: ${colors.brownLightGrey2};
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 24px;
  inset-inline-end: 10px;
  justify-content: center;
  padding: 0;
  position: absolute;
  top: 10px;
  width: 24px;

  &:hover {
    background: ${colors.brownLightGrey1};
  }
`;

const IconCross = () => (
  <svg
    fill="none"
    height="8"
    viewBox="0 0 10 10"
    width="8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M.9999 1l3.9999 4.001-4 3.9989M9 8.9995l-4-4.001L9 .9997"
      stroke={colors.brownGrey}
    />
  </svg>
);

const DownloadAppBoxContainer = styled.div`
  background: rgba(245, 243, 241, 0.5);
  border-radius: 10px;
  box-sizing: border-box;
  color: ${colors.brownBlack};
  display: none;
  font-size: 15px;
  line-height: 23px;
  margin-top: 20px;
  padding-block: 12px;
  padding-inline: 20px 220px;
  position: relative;
  strong {
    color: #000;
  }
  a {
    color: ${colors.blue};
  }
  ${mediaQuery(
    "greaterThanPhone",
    css`
      display: block;
    `,
  )}
`;

const AppBoxIcon = styled.div`
  background-image: url("${appBoxPic}");
  background-size: 100%;
  bottom: 0;
  height: 132px;
  inset-inline-end: 27px;
  position: absolute;
  width: 102px;
`;

type Props = {
  onClose: () => void;
};

const NothingYetWidget: React.FC<Props> = ({ onClose }: Props) => (
  <Container aria-labelledby="NotificationsNothingYet_Title">
    <Title id="NotificationsNothingYet_Title">
      <Trans>Notifications</Trans>
    </Title>
    <Text color={colors.brownGrey} textType="body">
      <Trans>
        Nothing yet. You&apos;ll see notifications about coverage
        authorizations and copays here.
      </Trans>
    </Text>

    <DownloadAppBoxContainer>
      <Trans>
        <strong>Download Stork Club App</strong> in the{" "}
        <a
          href="https://apps.apple.com/us/app/stork-club-family-benefits/id1491545080"
          rel="noreferrer"
          target="_blank"
        >
          Apple App Store
        </a>{" "}
        or{" "}
        <a
          href="https://play.google.com/store/apps/details?id=com.storkclub"
          rel="noreferrer"
          target="_blank"
        >
          Google Play
        </a>{" "}
        to receive notifications on your phone directly
      </Trans>
      <AppBoxIcon />
    </DownloadAppBoxContainer>
    <CloseButton onClick={onClose}>
      <IconCross />
    </CloseButton>
  </Container>
);

export default NothingYetWidget;
