/* eslint max-len: 0 */
/* eslint max-lines: 0 */

import React from "react";
import styled from "styled-components";

type Props = {
  /**
   * Background fill
   */
  fill?: string;
  /**
   * Optional, height
   */
  height?: number;
  /**
   * Any string
   */
  type:
    | "articles"
    | "back"
    | "close"
    | "cookieyes"
    | "earth"
    | "experts"
    | "gift"
    | "home"
    | "invite"
    | "logout"
    | "policies"
    | "profile"
    | "programs"
    | "settings";
  /**
   * Optional, width
   */
  width?: number;
  /**
   * Optional, viewBox
   */
  viewBox?: string;
};

const StyledSvg = styled.svg.attrs({
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg",
  xmlnsXlink: "http://www.w3.org/1999/xlink",
})``;

const Svg = styled(StyledSvg)<Pick<Props, "height" | "width">>`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
`;

const getIcon = (type: Props["type"], fill: Props["fill"]) => {
  switch (type) {
    case "articles":
      return (
        <>
          <path
            clipRule="evenodd"
            d="M21.8331 4C21.8331 4 15.222 4 12.3887 7.77778V21C15.222 17.2222 21.8331 17.2222 21.8331 17.2222V4Z"
            fill={fill}
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M1.99989 4C1.99989 4 8.611 4 11.4443 7.77778V21C8.611 17.2222 1.99989 17.2222 1.99989 17.2222V4Z"
            fill={fill}
            fillRule="evenodd"
          />
        </>
      );

    case "back":
      return (
        <g
          css={`
            [dir="rtl"] & {
              transform: rotate(180deg);
              transform-origin: center;
            }
          `}
        >
          <path
            d="M13 23L2 11.9972L13 1"
            stroke={fill}
            strokeWidth="2"
          />
          <path
            d="M24.4989 11.85L2 11.85"
            stroke={fill}
            strokeWidth="2"
          />
        </g>
      );

    case "close":
      return (
        <path
          clipRule="evenodd"
          d="M9.00022 11.4992L0 20.125L2.99978 23L12 14.3742L21.0002 23L24 20.125L14.9998 11.4992L23.9982 2.875L20.9985 0L12 8.62416L3.00153 2.35908e-06L0.00175114 2.875L9.00022 11.4992Z"
          fill="black"
          fillRule="evenodd"
        />
      );

    case "gift":
      return (
        <>
          <rect
            height="33"
            stroke="#21CD97"
            width="50"
            x="0.5"
            y="14.5"
          />
          <rect
            height="33"
            stroke="#21CD97"
            width="24"
            x="0.5"
            y="14.5"
          />
          <rect
            height="17"
            stroke="#21CD97"
            width="50"
            x="0.5"
            y="30.5"
          />
          <ellipse
            cx="17.6029"
            cy="7.5674"
            rx="6.93081"
            ry="6.86476"
            stroke="#21CD97"
          />
          <ellipse
            cx="30.1771"
            cy="9.1627"
            rx="5.31425"
            ry="5.26212"
            stroke="#21CD97"
          />
        </>
      );

    case "home":
      return (
        <path
          clipRule="evenodd"
          d="M18.7808 0.186667L20.5483 0L18.2955 10.0123L14.5579 7.10453L18.7808 0.1872V0.186667ZM20.3515 3.2816L20.9877 0.2736L24 3.74293L20.3515 3.28213V3.2816ZM11.6949 16.4048L6.78293 9.5312L17.9488 10.7627L11.6949 16.4048ZM18.3589 10.9616L13.7707 15.1557L17.0933 16.5333L18.3589 10.9616ZM5.50827 13.5131L6.17867 9.39573L10.1707 14.8373L7.14347 16.3109L5.5088 13.5125L5.50827 13.5131ZM10.1616 4.30613L6.29493 9.1232L12.688 9.7936L17.92 10.3429L14.3168 7.54027L14.3157 7.53813L10.1616 4.30613ZM0 2.7408L5.2 13.1088L5.86187 9.02933C5.86383 9.01649 5.86742 9.00395 5.87253 8.992V8.98773C5.87408 8.98558 5.87587 8.98361 5.87787 8.98187C5.87787 8.9792 5.87787 8.97867 5.87893 8.97707C5.8839 8.96749 5.88999 8.95854 5.89707 8.9504L5.89813 8.94773L6.592 8.0784L0 2.7408Z"
          fill={fill}
          fillRule="evenodd"
        />
      );

    case "experts":
      return (
        <>
          <path
            clipRule="evenodd"
            d="M20.001 14.6642V21H4.00098V14.6642C6.45083 13.5938 9.15656 13 12.001 13C14.8454 13 17.5511 13.5938 20.001 14.6642Z"
            fill={fill}
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
            fill={fill}
            fillRule="evenodd"
          />
        </>
      );

    case "invite":
      return (
        <>
          <polygon points="7.10542736e-15 5.75 7.10542736e-15 4.25 10 4.25 10 5.75" />
          <polygon
            points="7.10542736e-15 5.75 7.10542736e-15 4.25 10 4.25 10 5.75"
            transform="translate(5, 5) rotate(-270) translate(-5, -5) "
          />
        </>
      );

    case "logout":
      return (
        <>
          <path d="M1.75,2.25 L1.75,4.62804097 L0.25,4.62804097 L0.25,2.20486556 C0.25,1.39170013 0.901724386,0.75 1.70555556,0.75 L13.2833333,0.75 C14.0858792,0.75 14.75,1.39001757 14.75,2.20486556 L14.75,15.7490397 C14.75,16.5578455 14.0919439,17.25 13.2833333,17.25 L1.70555556,17.25 C0.895137699,17.25 0.25,16.5567085 0.25,15.7490397 L0.25,13.371959 L1.75,13.371959 L1.75,15.7490397 L13.2833333,15.75 C13.255779,15.75 13.2507514,15.7477511 13.25,15.7478565 L13.25,2.25 L1.75,2.25 Z" />
          <polygon points="5.29370718 5.79298497 6.36543261 4.74350671 10.5111326 8.97708855 6.36529671 13.2086167 5.29384308 12.1588609 8.41144806 8.9768167" />
          <polygon points="0.5 9.75 0.5 8.25 9.5 8.25 9.5 9.75" />
        </>
      );

    case "policies":
      return (
        <>
          <path
            clipRule="evenodd"
            d="M11.9113 1.05479L12.001 1C13.7938 2.09811 15 4.14998 15 6.5C15 8.85002 13.7938 10.9019 12.001 12C10.2066 10.9027 9 8.85046 9 6.5C9 4.18852 10.1669 2.16549 11.9113 1.05479L12.001 1L11.9113 1.05479Z"
            fill={fill}
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M11.9113 12.0548L12.001 12C13.7938 13.0981 15 15.15 15 17.5C15 19.85 13.7938 21.9019 12.001 23C10.2066 21.9027 9 19.8505 9 17.5C9 15.1885 10.1669 13.1655 11.9113 12.0548L12.001 12L11.9113 12.0548Z"
            fill={fill}
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M2.47674 6.60421L2.47411 6.49917C4.32154 5.49556 6.70159 5.47692 8.73677 6.65193C10.772 7.82694 11.9458 9.89744 12.0004 11.9992C10.1529 13.0045 7.77233 13.0233 5.73677 11.8481C3.73497 10.6923 2.56644 8.67024 2.47674 6.60421L2.47411 6.49917L2.47674 6.60421Z"
            fill={fill}
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M12.0031 12.1042L12.0005 11.9992C13.8479 10.9956 16.228 10.9769 18.2631 12.1519C20.2983 13.3269 21.4722 15.3974 21.5268 17.4992C19.6793 18.5045 17.2987 18.5233 15.2631 17.3481C13.2613 16.1923 12.0928 14.1702 12.0031 12.1042L12.0005 11.9992L12.0031 12.1042Z"
            fill={fill}
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M2.56543 17.5494L2.47315 17.4992C2.52771 15.3974 3.70159 13.3269 5.73677 12.1519C7.77195 10.9769 10.152 10.9956 11.9994 11.9992C11.9463 14.1018 10.7723 16.1729 8.73677 17.3481C6.73497 18.5038 4.39951 18.5047 2.56543 17.5494L2.47315 17.4992L2.56543 17.5494Z"
            fill={fill}
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M12.0918 12.0494L11.9995 11.9992C12.0541 9.89744 13.228 7.82694 15.2631 6.65193C17.2983 5.47692 19.6784 5.49556 21.5258 6.49917C21.4726 8.6018 20.2987 10.6729 18.2631 11.8481C16.2613 13.0038 13.9259 13.0047 12.0918 12.0494L11.9995 11.9992L12.0918 12.0494Z"
            fill={fill}
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
            fill={fill}
            fillRule="evenodd"
          />
        </>
      );

    case "profile":
      return (
        <path
          d="M16 16.0253C18.9467 16.0253 21.3334 13.6386 21.3334 10.692C21.3334 7.74531 18.9467 5.35864 16 5.35864C13.0534 5.35864 10.6667 7.74531 10.6667 10.692C10.6667 13.6386 13.0534 16.0253 16 16.0253ZM16 8.02531C17.4667 8.02531 18.6667 9.22531 18.6667 10.692C18.6667 12.1586 17.4667 13.3586 16 13.3586C14.5334 13.3586 13.3334 12.1586 13.3334 10.692C13.3334 9.22531 14.5334 8.02531 16 8.02531ZM16 17.3586C12.44 17.3586 5.33337 19.1453 5.33337 22.692V26.692H26.6667V22.692C26.6667 19.1453 19.56 17.3586 16 17.3586ZM24 24.0253H8.00004V22.7053C8.26671 21.7453 12.4 20.0253 16 20.0253C19.6 20.0253 23.7334 21.7453 24 22.692V24.0253Z"
          fill={fill}
        />
      );

    case "programs":
      return (
        <>
          <path
            clipRule="evenodd"
            d="M20.9381 9H13V2C17.0796 2 20.446 5.05369 20.9381 9Z"
            fill={fill}
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M8.5 16L8.52198 16.0002C8.95815 16.4721 9.43532 16.8785 9.96987 17.1994C9.98963 17.2965 10 17.397 10 17.5C10 18.3284 9.32843 19 8.5 19C7.67157 19 7 18.3284 7 17.5C7 16.6716 7.67157 16 8.5 16Z"
            fill={fill}
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M3 8H6L7 10H3V8Z"
            fill={fill}
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M21 17.5C21 19.433 19.433 21 17.5 21C15.567 21 14 19.433 14 17.5C14 15.567 15.567 14 17.5 14C19.433 14 21 15.567 21 17.5ZM19 17.5C19 18.3284 18.3284 19 17.5 19C16.6716 19 16 18.3284 16 17.5C16 16.6716 16.6716 16 17.5 16C18.3284 16 19 16.6716 19 17.5Z"
            fill={fill}
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M12 17.5C12 19.433 10.433 21 8.5 21C6.567 21 5 19.433 5 17.5C5 15.567 6.567 14 8.5 14C10.433 14 12 15.567 12 17.5ZM10 17.5C10 18.3284 9.32843 19 8.5 19C7.67157 19 7 18.3284 7 17.5C7 16.6716 7.67157 16 8.5 16C9.32843 16 10 16.6716 10 17.5Z"
            fill={fill}
            fillRule="evenodd"
          />
        </>
      );

    case "settings":
      return (
        <>
          <path
            clipRule="evenodd"
            d="M11.8502 28.1056L11.276 24.2774C11.2432 24.2591 11.2106 24.2405 11.178 24.2218C11.165 24.2143 11.152 24.2068 11.139 24.1993L7.52281 25.8459L3.28087 18.3695L6.47178 16L3.27539 13.6265L7.52458 6.19039L11.1266 7.81292C11.1445 7.80264 11.1625 7.79241 11.1805 7.78223C11.2076 7.76695 11.2346 7.7518 11.2618 7.73678L11.8651 3.89447H20.1527L20.7243 7.7373C20.734 7.74244 20.7437 7.74759 20.7534 7.75276C20.7894 7.77198 20.8253 7.79148 20.861 7.81125L24.5233 6.20499L28.7274 13.6552L25.4954 16L28.7316 18.3479L24.4812 25.8393L20.8479 24.1995C20.8064 24.2247 20.7646 24.2496 20.7226 24.2741L20.1527 28.1056H11.8502ZM20.5754 21.3336C20.2721 21.5864 19.935 21.8265 19.5643 22.0541C19.1935 22.2816 18.8228 22.4459 18.4521 22.547L17.9971 25.6056H14.0032L13.5482 22.5722C13.1943 22.4543 12.8193 22.2816 12.4233 22.0541C12.0273 21.8265 11.686 21.5864 11.3996 21.3336L8.56845 22.6228L6.54623 19.0586L9.09928 17.1628C9.06558 16.9943 9.0403 16.8047 9.02345 16.5941C9.0066 16.3834 8.99817 16.1854 8.99817 16C8.99817 15.8315 9.0066 15.6419 9.02345 15.4313C9.0403 15.2206 9.06558 15.0226 9.09928 14.8372L6.54623 12.9414L8.56845 9.40253L11.3743 10.6664C11.6776 10.4136 12.0231 10.1777 12.4107 9.95864C12.7983 9.73956 13.169 9.57104 13.5229 9.45308L14.0032 6.39447H17.9971L18.4521 9.45308C18.8228 9.5879 19.1978 9.75641 19.5769 9.95864C19.9561 10.1609 20.2889 10.3968 20.5754 10.6664L23.4571 9.40253L25.454 12.9414L22.8757 14.812C22.9094 15.0142 22.9347 15.2164 22.9515 15.4186C22.9684 15.6209 22.9768 15.8147 22.9768 16C22.9768 16.1685 22.9684 16.3539 22.9515 16.5561C22.9347 16.7584 22.9094 16.969 22.8757 17.1881L25.454 19.0586L23.4318 22.6228L20.5754 21.3336Z"
            fill={fill}
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M20.1396 16C20.1396 18.2863 18.2863 20.1396 16 20.1396C13.7137 20.1396 11.8604 18.2863 11.8604 16C11.8604 13.7137 13.7137 11.8604 16 11.8604C18.2863 11.8604 20.1396 13.7137 20.1396 16ZM16 17.6396C16.9055 17.6396 17.6396 16.9055 17.6396 16C17.6396 15.0944 16.9055 14.3604 16 14.3604C15.0944 14.3604 14.3604 15.0944 14.3604 16C14.3604 16.9055 15.0944 17.6396 16 17.6396Z"
            fill={fill}
            fillRule="evenodd"
          />
        </>
      );

    case "earth":
      return (
        <>
          <path
            d="M12.6328 10.6797H15.4453C15.6016 9.95052 15.6797 9.39062 15.6797 9C15.6797 8.60938 15.6016 8.04948 15.4453 7.32031H12.6328C12.7109 7.86719 12.75 8.42708 12.75 9C12.75 9.57292 12.7109 10.1328 12.6328 10.6797ZM11.1484 15.2891C11.7995 15.0807 12.4766 14.6901 13.1797 14.1172C13.8828 13.5182 14.4167 12.9193 14.7812 12.3203H12.3203C12.0599 13.362 11.6693 14.3516 11.1484 15.2891ZM10.9531 10.6797C11.0312 10.1328 11.0703 9.57292 11.0703 9C11.0703 8.42708 11.0312 7.86719 10.9531 7.32031H7.04688C6.96875 7.86719 6.92969 8.42708 6.92969 9C6.92969 9.57292 6.96875 10.1328 7.04688 10.6797H10.9531ZM9 15.6406C9.72917 14.5729 10.263 13.4661 10.6016 12.3203H7.39844C7.73698 13.4661 8.27083 14.5729 9 15.6406ZM5.67969 5.67969C5.99219 4.5599 6.38281 3.57031 6.85156 2.71094C6.20052 2.91927 5.51042 3.32292 4.78125 3.92188C4.07812 4.49479 3.55729 5.08073 3.21875 5.67969H5.67969ZM3.21875 12.3203C3.55729 12.9193 4.07812 13.5182 4.78125 14.1172C5.51042 14.6901 6.20052 15.0807 6.85156 15.2891C6.33073 14.3516 5.9401 13.362 5.67969 12.3203H3.21875ZM2.55469 10.6797H5.36719C5.28906 10.1328 5.25 9.57292 5.25 9C5.25 8.42708 5.28906 7.86719 5.36719 7.32031H2.55469C2.39844 8.04948 2.32031 8.60938 2.32031 9C2.32031 9.39062 2.39844 9.95052 2.55469 10.6797ZM9 2.35938C8.27083 3.42708 7.73698 4.53385 7.39844 5.67969H10.6016C10.263 4.53385 9.72917 3.42708 9 2.35938ZM14.7812 5.67969C14.4167 5.08073 13.8828 4.49479 13.1797 3.92188C12.4766 3.32292 11.7995 2.91927 11.1484 2.71094C11.6172 3.57031 12.0078 4.5599 12.3203 5.67969H14.7812ZM3.10156 3.14062C4.74219 1.5 6.70833 0.679688 9 0.679688C11.2917 0.679688 13.2448 1.5 14.8594 3.14062C16.5 4.75521 17.3203 6.70833 17.3203 9C17.3203 11.2917 16.5 13.2578 14.8594 14.8984C13.2448 16.513 11.2917 17.3203 9 17.3203C6.70833 17.3203 4.74219 16.513 3.10156 14.8984C1.48698 13.2578 0.679688 11.2917 0.679688 9C0.679688 6.70833 1.48698 4.75521 3.10156 3.14062Z"
            fill={fill}
          />
        </>
      );

    case "cookieyes":
      return (
        <>
          <path
            clipRule="evenodd"
            d="M6.48603 11.9026C3.23014 11.9026 0.597107 9.2616 0.597107 5.99984V5.34214L1.05147 5.43324C1.07553 5.43807 1.09741 5.44254 1.11754 5.44667C1.22972 5.46957 1.28737 5.48134 1.3644 5.48134C1.84069 5.48134 2.23329 5.1916 2.39854 4.79814L2.52909 4.4873L2.85319 4.58014C3.01435 4.6263 3.17298 4.64874 3.33001 4.64874C4.33753 4.64874 5.16506 3.82047 5.16506 2.80811C5.16506 2.6492 5.14283 2.51151 5.11483 2.34305L5.0725 2.08844L5.29366 1.95539C5.59063 1.77678 5.8048 1.42847 5.82943 1.02234C5.82773 0.901951 5.794 0.792347 5.73813 0.624321L5.6026 0.216669L6.0238 0.132216C6.19963 0.0969652 6.37043 0.0970125 6.50143 0.0970482L6.51373 0.0970509C9.76963 0.0970509 12.4027 2.73807 12.4027 5.99984C12.4027 9.26407 9.73946 11.9026 6.48603 11.9026ZM1.36204 6.24077C1.4873 8.9727 3.73185 11.1432 6.48603 11.1432C9.32323 11.1432 11.6432 8.8415 11.6432 5.99984C11.6432 3.17722 9.38303 0.891314 6.578 0.856867C6.58487 0.912817 6.5889 0.971221 6.5889 1.03184V1.0418L6.5884 1.05175C6.5594 1.60404 6.30643 2.11723 5.9001 2.45368C5.91406 2.56147 5.92447 2.6794 5.92447 2.80811C5.92447 4.2381 4.75873 5.40814 3.33001 5.40814C3.2022 5.40814 3.07489 5.39797 2.94822 5.37787C2.6194 5.88907 2.04162 6.24077 1.3644 6.24077C1.36362 6.24077 1.36283 6.24077 1.36204 6.24077Z"
            fill="#908B85"
            fillRule="evenodd"
          />
          <path
            d="M4.30165 0.843149C4.30165 0.450166 3.98309 0.131592 3.5901 0.131592C3.19712 0.131592 2.87854 0.450166 2.87854 0.843149C2.87854 1.23613 3.19712 1.5547 3.5901 1.5547C3.98309 1.5547 4.30165 1.23613 4.30165 0.843149Z"
            fill="#908B85"
          />
          <path
            d="M3.91412 3.06564C3.91412 2.77967 3.68229 2.54785 3.39633 2.54785C3.11036 2.54785 2.87854 2.77967 2.87854 3.06564C2.87854 3.3516 3.11036 3.58343 3.39633 3.58343C3.68229 3.58343 3.91412 3.3516 3.91412 3.06564Z"
            fill="#908B85"
          />
          <path
            d="M1.87477 3.42672C1.87477 3.132 1.63584 2.89307 1.3411 2.89307C1.04636 2.89307 0.807434 3.132 0.807434 3.42672C0.807434 3.72148 1.04636 3.96038 1.3411 3.96038C1.63584 3.96038 1.87477 3.72148 1.87477 3.42672Z"
            fill="#908B85"
          />
          <path
            d="M6.07155 5.75077L6.41022 6.36747L6.61662 6.7385L8.22005 4H9.45835L7.21459 7.83077H5.97629L4.83325 5.75077H6.07155Z"
            fill="#908B85"
          />
          <path
            d="M7.14708 8.5968H5.99078V9.74603H7.14708V8.5968Z"
            fill="#908B85"
          />
        </>
      );
    default:
      throw new Error("Unknown icon");
  }
};
const Icon: React.FC<Props> = ({
  fill = "#999999",
  height = 24,
  type,
  width = 24,
  ...rest
}: Props) => {
  const figure = React.useMemo(
    () => getIcon(type, fill),
    [fill, type],
  );

  return (
    <Svg fill="none" {...{ height, width, ...rest }}>
      {figure}
    </Svg>
  );
};

export default Icon;
