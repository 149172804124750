export function normalizeError(err: unknown): Error {
  if (err instanceof Error) {
    return err;
  }
  // eslint-disable-next-line no-console
  console.warn(
    `Non Error exception. You should avoid throwing anything but instance of Error`,
    {
      err,
    },
  );
  return new Error(String(err), { cause: err });
}
