/* eslint-disable react/no-danger */
import { FC, ReactNode } from "react";
import { useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors } from "../../../constants";
import Markdown from "../../../lib/Markdown";
import PageTitle from "../../../lib/PageTitle";
import Skeleton from "../../../lib/Skeleton";
import Title from "../../../lib/Title";
import { formatDate } from "../../../utils/date-fns";
import mediaQuery from "../../../utils/mediaQuery";
import ScrollHandler from "../../ScrollHandler";
import CategoryBadge from "../CategoryBadge";
import useGetArticle from "../hooks/useGetArticle";
import { Article } from "../models/Article";
import Cover from "./Cover";

const Container = styled.div``;

const ArticleHeader = styled.div`
  background: ${colors.white};
  margin: 0 -20px;
  padding: 0 20px 40px;
  text-align: center;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      margin: 0;
      padding: 40px 20px;
    `,
  )}

  ${mediaQuery(
    "desktopMedium",
    css`
      padding: 35px 45px 40px;
    `,
  )}

  & > ${Title} {
    margin-bottom: 8px;

    ${mediaQuery(
      "greaterThanPhone",
      css`
        margin-bottom: 20px;
      `,
    )}
  }
`;

const ArticleBody = styled.div`
  background-color: ${colors.white};
  margin: 0 -20px;
  padding: 32px 20px 64px;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      margin: 0;
      padding-bottom: 32px;
    `,
  )}

  ${mediaQuery(
    "desktopMedium",
    css`
      padding: 64px 60px 64px;
    `,
  )}
`;

const Date = styled.p`
  color: ${colors.brownGrey};
`;

const StyledMarkdown = styled(Markdown)`
  color: ${colors.brownBlack};
  li {
    margin-bottom: 15px;
  }

  p {
    margin-bottom: 15px;
  }
`;

const PostMedia = styled.p`
  font-size: 0;
  line-height: 1.67;
  margin-bottom: 18px;
  text-align: center;
  iframe,
  img {
    max-width: 100%;
  }
  img {
    height: auto;
  }
`;

const ArticleFull: FC<Article> = ({
  category,
  imageSet,
  showCover,
  blocks,
  name,
  publishedAt,
}: Article) => (
  <ScrollHandler key={name}>
    <Container>
      <ArticleHeader>
        <PageTitle>{name}</PageTitle>
        <Title>{name}</Title>
        <CategoryBadge {...category} />
      </ArticleHeader>
      {showCover && <Cover alt={name} imageSet={imageSet} />}
      <ArticleBody>
        {blocks.map((block) => {
          let node: ReactNode;
          switch (block.type) {
            case "text": {
              node = (
                <StyledMarkdown key={block.id} text={block.text} />
              );
              break;
            }
            case "image":
            case "photo": {
              node = (
                <PostMedia>
                  <img
                    key={block.id}
                    alt=""
                    height={`${
                      block.imageSet.aspect_ratio.split(":")[1]
                    }`}
                    sizes="(max-width: 767px) 100vh, 800px"
                    src={block.imageSet.w256}
                    srcSet={`${block.imageSet.w64} 64w, ${block.imageSet.w128} 128w, ${block.imageSet.w256} 256w, ${block.imageSet.w512} 512w, ${block.imageSet.w1024} 1024w, ${block.imageSet.w1600} 1600w`}
                    width={`${
                      block.imageSet.aspect_ratio.split(":")[0]
                    }`}
                  />
                </PostMedia>
              );
              break;
            }
            case "embed": {
              node = (
                <PostMedia
                  key={block.id}
                  dangerouslySetInnerHTML={{ __html: block.embed }}
                />
              );
              break;
            }
          }
          return node;
        })}
        <Date>{formatDate(publishedAt)}</Date>
      </ArticleBody>
    </Container>
  </ScrollHandler>
);

const ArticleFullConnected = () => {
  const { slug = "" } = useParams<{
    slug: string;
  }>();
  const { data, isLoading } = useGetArticle(slug);

  return (
    <Skeleton active loading={isLoading}>
      {data ? <ArticleFull {...data} /> : null}
    </Skeleton>
  );
};

export default ArticleFullConnected;
