import * as t from "io-ts";
import { DateFromString } from "../../io-ts/types";
import { State, StateC } from "./State";

export const ReimbursementEventC = t.type({
  attributes: t.type({
    created_at: DateFromString,
    name: StateC,
    user_event: t.boolean,
  }),
  id: t.string,
  type: t.literal("reimbursement_event"),
});

export type ReimbursementEventEntity = t.TypeOf<
  typeof ReimbursementEventC
>;

export type ReimbursementEvent = {
  id: string;
  createdAt: Date;
  state: State;
  userEvent: boolean;
  isRead: boolean;
};
