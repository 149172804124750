import React, {
  ButtonHTMLAttributes,
  FC,
  ForwardedRef,
  ReactNode,
  forwardRef,
} from "react";
import styled from "styled-components";

export interface ButtonExtraProps {
  icon?: ReactNode;
}

const CoreButtonTag = styled.button<ButtonExtraProps>``;

export const CoreButtonInner = styled.span``;
export const CoreButtonIcon = styled.span``;

export const CoreButton: FC<
  ButtonExtraProps & ButtonHTMLAttributes<HTMLButtonElement>
> = forwardRef(
  (
    { children, icon, ...buttonProps },
    ref: ForwardedRef<HTMLButtonElement>,
  ) => (
    <CoreButtonTag {...buttonProps} ref={ref} icon={icon}>
      <CoreButtonInner>
        {!!icon && <CoreButtonIcon>{icon}</CoreButtonIcon>}
        {children}
      </CoreButtonInner>
    </CoreButtonTag>
  ),
);
