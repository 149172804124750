import { Component } from "react";

export default class ReCAPTCHADisabled extends Component<
  { ref?: unknown },
  unknown
> {
  // eslint-disable-next-line class-methods-use-this
  public getValue(): string | undefined {
    return "ReCAPTCHA-disabled";
  }

  // eslint-disable-next-line class-methods-use-this
  public reset(): void {
    // pass
  }

  // eslint-disable-next-line class-methods-use-this
  public execute(): string | null {
    return "ReCAPTCHA-disabled";
  }

  public async executeAsync(): Promise<string | null> {
    await new Promise((resolve) => {
      setTimeout(resolve, 500);
    });

    return this.execute();
  }

  public render(): JSX.Element {
    return (
      <div
        css={`
          display: none;
        `}
      />
    );
  }
}
