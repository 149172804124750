import * as React from "react";
import { BaseIconAttributes } from "./types";

export const Bank: React.FC<BaseIconAttributes> = ({
  width = "1em",
  height = "1em",
  mainColor = "currentColor",
  ...restProps
}) => (
  <svg
    {...restProps}
    fill="none"
    height={height}
    viewBox="0 0 18 18"
    width={width}
  >
    <path
      clipRule="evenodd"
      d="M1.08397 6.875C0.848179 6.87977 0.617507 6.80595 0.428292 6.66518C0.239077 6.52441 0.102075 6.32468 0.0388694 6.09747C-0.0243361 5.87026 -0.010152 5.62848 0.0791865 5.41022C0.168525 5.19197 0.32794 5.00963 0.532317 4.89195C0.871467 4.69645 3.52857 3.23275 8.50107 0.5C13.4744 3.23275 16.1307 4.69645 16.4698 4.89195C16.6743 5.00966 16.8337 5.19207 16.9231 5.41042C17.0124 5.62877 17.0265 5.87064 16.9632 6.0979C16.8999 6.32516 16.7627 6.52488 16.5734 6.66558C16.384 6.80628 16.1532 6.87996 15.9173 6.875H1.08397ZM11.6886 15.375V8.46875H14.8761V15.375H16.1511C16.3765 15.375 16.5927 15.4646 16.7521 15.624C16.9115 15.7834 17.0011 15.9996 17.0011 16.225V17.5H0.00106678V16.225C0.00106678 15.9996 0.0906203 15.7834 0.250026 15.624C0.409432 15.4646 0.625633 15.375 0.851067 15.375H2.12607V8.46875H5.31357V15.375H6.90732V8.46875H10.0948V15.375H11.6886Z"
      fill={mainColor}
      fillRule="evenodd"
    />
  </svg>
);
