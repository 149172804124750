import { FC, forwardRef, InputHTMLAttributes } from "react";
import styled from "styled-components";
import { colors, fonts } from "../../../../constants";
import { useFormFieldContext } from "../../FormField/context";

export type SwitcherProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "type"
>;

const Slider = styled.span``;
const Input = styled.input``;

const Container = styled.span`
  cursor: pointer;
  display: inline-block;
  font-family: ${fonts.main};
  height: 24px;
  position: relative;
  user-select: none;
  width: 40px;

  ${Input} {
    box-sizing: border-box;
    cursor: pointer;
    inset: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    z-index: 1;
  }

  ${Slider} {
    position: absolute;
    cursor: pointer;
    inset: 0;
    background-color: ${colors.brownGrey};

    border-radius: 12px;
    &::before {
      position: absolute;
      border-radius: 50%;
      content: "";
      height: 18px;
      width: 18px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      transition: 0.1s;
      transition-timing-function: ease-in-out;
    }
  }

  ${Input}:checked+${Slider} {
    background-color: ${colors.blue};

    &::before {
      transform: translateX(16px);
    }
  }

  &:hover,
  label:hover > & {
    ${Input}+${Slider} {
      background-color: ${colors.brownGreyHover};
    }
    ${Input}:checked+${Slider} {
      background-color: ${colors.blueHover};
    }
  }
`;
const Switcher: FC<SwitcherProps> = forwardRef<
  HTMLInputElement,
  SwitcherProps
>((props, ref) => {
  const {
    children,
    "aria-invalid": ariaInvalid,
    ...restProps
  } = props;
  const formFieldContextValue = useFormFieldContext();

  return (
    <Container>
      <Input
        {...restProps}
        ref={ref}
        aria-invalid={
          ariaInvalid || formFieldContextValue["aria-invalid"]
        }
        role="switch"
        type="checkbox"
      />
      <Slider />
    </Container>
  );
});

export default Switcher;
