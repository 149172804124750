import { t } from "@lingui/macro";
import qs from "qs";
import { FC, useMemo } from "react";
import { useLocation } from "react-router";
import PageTitle from "../../../lib/PageTitle";
import PageLayout, { Body, Footer, Sidebar } from "../../PageLayout";
import Categories from "../Categories";
import ArticlesHeader from "../Header";
import { ArticlesContainer } from "../style";
import SearchBlock from "./SearchBlock";

const ArticlesSearchPage: FC = () => {
  const location = useLocation();
  const q = useMemo(() => {
    try {
      const qp = qs.parse(location.search.replace("?", ""));
      return typeof qp.q === "string" ? qp.q : "";
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return "";
    }
  }, [location.search]);
  return (
    <PageLayout
      footer={<Footer />}
      header={<ArticlesHeader q={q} />}
      sidebar={<Sidebar />}
    >
      <PageTitle>{t`Articles`}</PageTitle>
      <Body>
        <Categories />
        <ArticlesContainer>
          <SearchBlock q={q} />
        </ArticlesContainer>
      </Body>
    </PageLayout>
  );
};

export default ArticlesSearchPage;
