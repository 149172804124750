import { MainPageReimbursement } from "../../reimbursements/models/Reimbursement";
import { ReimbursementEvent } from "../../reimbursements/models/ReimbursementEvent";
import { Authorization } from "../models/Authorization";
import { AuthorizationEvent } from "../models/AuthorizationEvent";

export type Auth = {
  authorization: Authorization;
  event: AuthorizationEvent;
};

export type Reimb = {
  authorization: MainPageReimbursement;
  event: ReimbursementEvent;
};

// return correct notifications from source authorizations for main page widget
export const getLatestNotifications = (
  authorizations: ReadonlyArray<
    Authorization | MainPageReimbursement
  >,
): Array<Auth | Reimb> => {
  if (!authorizations?.length) {
    return [];
  }

  // if only one authorization - get two latest notifications
  if (authorizations.length === 1) {
    const authorization = authorizations[0];

    // isReimbursement
    if (!("practiceTemplate" in authorization)) {
      return authorization.events.map((event) => ({
        authorization,
        event,
      }));
    }

    return authorization.events.map((event) => ({
      authorization,
      event,
    }));
  }
  // if more than one authorization - get latest notification of each authorization
  return authorizations.map((item) => {
    // isReimbursement
    if (!("practiceTemplate" in item)) {
      return {
        authorization: item,
        event: item.events[0],
      };
    }

    return {
      authorization: item,
      event: item.events[0],
    };
  });
};
