import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import { format as formatDate } from "date-fns";
import { FC } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../constants";
import { formatCurrency } from "../../../utils/currency";
import mediaQuery from "../../../utils/mediaQuery";
import { MainPageReimbursement } from "../../models/Reimbursement";
import { ReimbursementEvent } from "../../models/ReimbursementEvent";

const Title = styled.div`
  flex-grow: 1;
  line-height: 22px;
  margin-inline-end: 20px;

  ${mediaQuery(
    "desktopMedium",
    css`
      display: flex;
    `,
  )}
`;

const Total = styled.div`
  color: ${colors.black};
  font-weight: 500;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-inline-start: auto;
      order: 3;
    `,
  )}
`;

const BoldBlack = styled.span`
  color: ${colors.black};
  font-weight: 500;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin: 0 4px;
    `,
  )}
`;

const Date = styled.div`
  color: ${colors.brownGrey};
  font-size: 12px;
  line-height: 14px;
  margin: 5px 0 0;

  ${mediaQuery(
    "desktopMedium",
    css`
      display: inline;
      font-size: 15px;
      line-height: 22px;
      margin: 0 10px;
      white-space: nowrap;
    `,
  )}
`;

const DATE_FORMAT = "MM-dd-yyyy";
const dt = (date: Date) => formatDate(date, DATE_FORMAT);

type Props = {
  reimbursement: MainPageReimbursement;
  event: ReimbursementEvent;
  currency: string;
};

export const HeaderTitle: FC<Props> = (props: Props) => {
  const { reimbursement, event, currency } = props;
  const { state, createdAt, userEvent } = event;
  const { totalRequestedAmount, totalApprovedAmount } = reimbursement;
  const date = dt(createdAt);

  if (state === "requested") {
    return (
      <Title>
        <Trans>
          You <BoldBlack>requested</BoldBlack> expense reimbursement
        </Trans>
        <Total dir="auto">
          {formatCurrency(
            totalRequestedAmount,
            currency,
            i18n.locale,
          )}
        </Total>
        <Date>{date}</Date>
      </Title>
    );
  }

  if (state === "canceled") {
    return (
      <Title>
        {userEvent ? <Trans>You</Trans> : "Stork Club"}{" "}
        <Trans>
          <BoldBlack>canceled</BoldBlack> expense reimbursement
        </Trans>
        <Date>{date}</Date>
      </Title>
    );
  }

  if (state === "rejected") {
    return (
      <Title>
        Stork Club{" "}
        <Trans>
          <BoldBlack>rejected</BoldBlack> expense reimbursement
        </Trans>
        <Date>{date}</Date>
      </Title>
    );
  }

  if (state === "approved") {
    return (
      <Title>
        Stork Club{" "}
        <Trans>
          <BoldBlack>approved</BoldBlack> expense reimbursement
        </Trans>
        <Total>
          {formatCurrency(totalApprovedAmount, currency, i18n.locale)}
        </Total>
        <Date>{date}</Date>
      </Title>
    );
  }

  return null;
};
