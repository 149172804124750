/* eslint-disable max-lines */
import styled, { css } from "styled-components";
import { colors } from "../../constants";
import FormField from "../../lib/forms/FormField";
import Title, { getTitleStyle } from "../../lib/Title";
import mediaQuery from "../../utils/mediaQuery";
import { Header } from "../PageLayout";
import Dates from "./Dates";
import Experts from "./Experts";
import ExpertCard from "./Experts/ExpertCard";
import TimeSlots from "./TimeSlots";

export const MainTitle = styled(Title)``;
export const SubTitle = styled(Title)``;
export const TimeWarn = styled.p`
  color: ${colors.black};
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 20px;
`;
export const TopBlock = styled.div`
  margin-bottom: 40px;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 60px;
    `,
  )}
`;
export const ExpertFormField = styled(FormField)`
  margin-top: 30px;

  .label {
    ${getTitleStyle("h2")}
    margin-bottom: 20px;
  }
`;
export const DateFormField = styled(FormField)`
  .label {
    color: ${colors.brownBlack};
    margin-bottom: 10px;
    padding: 0 20px;

    ${mediaQuery(
      "greaterThanPhone",
      css`
        padding: 0 40px;
      `,
    )}

    ${mediaQuery(
      "desktopMedium",
      css`
        padding: 0;
      `,
    )}
  }
`;
export const TimeFormField = styled(FormField)`
  .label {
    color: ${colors.brownBlack};
    margin-bottom: 10px;
    padding: 0 20px;

    ${mediaQuery(
      "greaterThanPhone",
      css`
        padding: 0;
      `,
    )}
  }
`;
export const NoteField = styled(FormField)`
  max-width: var(--content-max-width);

  .label {
    color: ${colors.brownBlack};
    margin-bottom: 30px;
  }
`;
export const SelectedDate = styled(Title)`
  margin-bottom: 10px;
`;
export const ButtonsRow = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
`;

export const DisclaimerLongContent = styled.span``;
export const DisclaimerShortContent = styled.span``;
export const ReadAllLink = styled.button`
  appearance: none;
  border: none;
  color: ${colors.blue};
  cursor: pointer;
  font-size: 12px;
  line-height: 1.33;
  margin: 0;
  padding: 0;
  text-decoration: underline;
`;
export const DisclaimerContainer = styled.div<{
  $readMore: boolean;
}>`
  border: 1px solid ${colors.brownLightGrey1};
  border-radius: 10px;
  box-sizing: border-box;
  color: ${colors.brownBlack};
  font-size: 12px;
  line-height: 1.33;
  margin: 0 0 20px;
  max-width: var(--content-max-width);
  opacity: 0.8;
  overflow: hidden;
  padding: 10px;

  ${DisclaimerLongContent} {
    display: ${({ $readMore }) => ($readMore ? undefined : "none")};

    ${mediaQuery(
      "desktopMedium",
      css`
        display: unset;
      `,
    )};
  }

  ${DisclaimerShortContent} {
    display: ${({ $readMore }) => ($readMore ? "none" : undefined)};

    ${mediaQuery(
      "desktopMedium",
      css`
        display: none;
      `,
    )};
  }
`;

export const Container = styled.form`
  padding: 30px var(--content-margin-side) 0;

  ${ExpertFormField}, ${DateFormField}, ${TimeFormField} {
    margin-inline-start: -20px;
    margin-inline-end: -20px;
  }

  ${TimeFormField} {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  ${MainTitle} {
    margin-bottom: 30px;
  }

  ${SubTitle} {
    margin-top: 0;
  }

  ${mediaQuery(
    "greaterThanPhone",
    css`
      ${ExpertFormField}, ${DateFormField} {
        margin-inline-start: -40px;
        margin-inline-end: 0;
      }

      ${TimeFormField} {
        margin-inline-start: 0;
        overflow: hidden;
      }
    `,
  )}

  ${mediaQuery(
    "desktopMedium",
    css`
      padding-top: 50px;

      ${ExpertFormField}, ${DateFormField}, ${TimeFormField} {
        margin-inline-end: 0;
        margin-inline-start: 0;
      }
    `,
  )}

  ${mediaQuery(
    "phone", // Is required to show some gap above mobile footer
    css`
      padding-bottom: 50px;
    `,
  )}

  & .swiper {
    padding: 0 40px;

    ${mediaQuery(
      "desktopMedium",
      css`
        padding: 0 10px;
      `,
    )}

    ${mediaQuery(
      "phone",
      css`
        padding: 0 20px;
      `,
    )}
  }

  & .swiper-button-prev {
    border-start-start-radius: 0;
    border-start-end-radius: 10px;
    border-end-start-radius: 0;
    border-end-end-radius: 10px;
    inset-inline-start: -80px;
  }

  & .swiper-button-next {
    border-start-start-radius: 10px;
    border-start-end-radius: 0;
    border-end-start-radius: 10px;
    border-end-end-radius: 0;
    inset-inline-end: -80px;
  }

  & .swiper-button-prev,
  & .swiper-button-next {
    display: none;

    ${mediaQuery(
      "desktopMedium",
      css`
        display: initial;
      `,
    )}
  }
`;

export const StyledExpertCard = styled(ExpertCard)``;
export const widthMixin = css`
  max-width: none;
  width: 100vw;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      max-width: calc(100vw - 240px);
    `,
  )}

  ${mediaQuery(
    "desktopMedium",
    css`
      max-width: 800px;
      width: calc(100vw - var(--sidebar-width) - 80px * 2);
    `,
  )}
`;
export const StyledExperts = styled(Experts)`
  ${widthMixin};
  ${StyledExpertCard} {
    height: 100%;
  }
`;
export const StyledDates = styled(Dates)`
  ${widthMixin};
`;
export const StyledTimes = styled(TimeSlots)`
  ${widthMixin};
`;

export const StyledHeader = styled(Header)`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const BackButtonContainer = styled.div`
  margin-bottom: 14px;
  width: 100%;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      display: none;
    `,
  )}
`;

export const BackButton = styled.button`
  background: none;
  border: none;
  display: block;
  margin: 0;
  padding: 0;
  &:active,
  &:focus,
  &:hover {
    background: none;
    border: none;
  }
`;
