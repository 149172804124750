/* eslint-disable max-lines */
import {
  AriaAttributes,
  FC,
  ForwardedRef,
  InputHTMLAttributes,
  ReactNode,
  forwardRef,
  useCallback,
  useRef,
  useState,
  ComponentProps,
} from "react";
import styled, { css } from "styled-components";
import { colors, fonts } from "../../../../constants";
import Icon from "../../../SharedIcon";
import { useFormFieldContext } from "../../FormField/context";

export const SuffixWrapper = styled.span`
  align-items: center;
  display: flex;
  flex: 0;
  position: relative;
  user-select: none;
`;

const commonIconStyles = css`
  cursor: pointer;
  font-size: 22px;
  padding: 7px;
`;
const Cross: FC<Omit<ComponentProps<typeof Icon>, "type">> = (
  props,
) => <Icon {...props} type="cross" />;
const EyeOpen: FC<Omit<ComponentProps<typeof Icon>, "type">> = (
  props,
) => <Icon {...props} type="passwordEyeOpen" />;
const EyeClosed: FC<Omit<ComponentProps<typeof Icon>, "type">> = (
  props,
) => <Icon {...props} type="passwordEyeClosed" />;
const CrossStyled = styled(Cross)`
  color: ${colors.brownBlack};
  ${commonIconStyles}
`;
const EyeOpenStyled = styled(EyeOpen)`
  ${commonIconStyles}
`;
const EyeClosedStyled = styled(EyeClosed)`
  ${commonIconStyles}
`;

/**
 * Ordinary input with some predefined styles.
 */
const InputRaw = styled.input<InputExtraProps>`
  align-self: center; // Container has fixed height, so this is needed to vertically center the input in some browsers. Like in Brave
  background: transparent;
  border: none;
  font-family: ${fonts.main};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  outline: none;

  &::placeholder {
    color: ${colors.brownGrey};
    opacity: 1; /* Firefox */
  }

  /* Hide clear button if the input is empty. Apply condition only if placeholder is set, because the check relies on it.*/
  ${({ placeholder }) =>
    placeholder
      ? css`
          &:placeholder-shown {
            & ~ ${SuffixWrapper} {
              ${CrossStyled} {
                display: none;
              }
            }
          }
        `
      : undefined};

  &[disabled] {
    cursor: not-allowed;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${colors.brownBlack};
      opacity: 1; /* Firefox */
    }
  }
`;

const disabledInputContainerStyle = css`
  background: ${colors.brownLightGrey2};
  border: 1px solid ${colors.brownLightGrey1};
  border-radius: 8px;
  color: ${colors.brownGrey};
  cursor: not-allowed;
`;

interface InputExtraProps
  extends Pick<AriaAttributes, "aria-invalid">,
    Pick<
      InputHTMLAttributes<HTMLInputElement>,
      "disabled" | "type"
    > {}

export interface InputProps
  extends InputExtraProps,
    InputHTMLAttributes<HTMLInputElement> {
  /**
   * The suffix icon for the Input
   */
  suffix?: ReactNode;

  /**
   * If allow to remove input content with clear icon
   */
  allowClear?: boolean;

  ref?: ForwardedRef<HTMLInputElement>;
  $activeColor?: string;
}

interface AffixWrapperProps extends InputExtraProps {
  $activeColor?: string;
  hasSuffix: boolean;
}

const Border = styled.div<{ $activeColor?: string }>`
  inset: 0;
  pointer-events: none;
  position: absolute;
`;

const AffixWrapper = styled.span.withConfig<AffixWrapperProps>({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    // styled-components is a dumb thing and does not filter correctly https://github.com/emotion-js/emotion/blob/main/packages/is-prop-valid/src/props.js
    !["disabled", "type"].includes(prop) && defaultValidatorFn(prop),
})`
  display: inline-flex;
  background: ${colors.white};
  border-radius: 8px;
  box-sizing: border-box;
  color: ${colors.brownBlack};
  height: 50px;
  position: relative;
  transition: 0.075s ease-in-out;

  ${InputRaw} {
    padding: 0 ${({ hasSuffix }) => (hasSuffix ? 0 : "16px")} 0 16px;
  }

  ${SuffixWrapper} {
    margin: 0 7px 0 0;
  }

  ${InputRaw} {
    flex: 1;
  }

  ${Border} {
    border: ${({ "aria-invalid": hasError }) =>
      hasError
        ? css`2px solid ${colors.red}`
        : css`1px solid ${colors.brownLightGrey1}`};
    border-radius: 8px;
  }

  &:hover {
    ${Border} {
      border: 1px solid ${colors.brownGrey};
    }
  }
  &:focus-within {
    ${Border} {
      border: 2px solid
        ${({ $activeColor }) => $activeColor || colors.blue};
    }
  }
  ${({ disabled }) =>
    disabled ? disabledInputContainerStyle : undefined}
`;

const Input: FC<InputProps> = forwardRef<
  HTMLInputElement,
  InputProps
>(
  (
    {
      className,
      style,
      suffix,
      allowClear,
      onChange,
      disabled,
      "aria-invalid": ariaInvalid,
      $activeColor,
      ...restProps
    },
    ref,
  ) => {
    const invalid = !!ariaInvalid && ariaInvalid !== "false";
    const formFieldContextValue = useFormFieldContext();
    const isPasswordField = restProps.type === "password";
    const [showPassword, setShowPassword] = useState(false);

    const showPasswordIcon = (
      <EyeOpenStyled onClick={() => setShowPassword(true)} />
    );
    const hidePasswordIcon = (
      <EyeClosedStyled onClick={() => setShowPassword(false)} />
    );
    const myOwnRef = useRef<HTMLInputElement | null>(null);

    const clearIcon = (
      <CrossStyled
        onClick={() => {
          if (myOwnRef.current) {
            myOwnRef.current.value = "";
            // @ts-expect-error @see: https://github.com/facebook/react/issues/11488
            // eslint-disable-next-line no-underscore-dangle,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            myOwnRef.current._valueTracker?.setValue(undefined);
            // manually trigger the input event to tell React to trigger onChange with a proper event type
            myOwnRef.current.dispatchEvent(
              new Event("input", { bubbles: true }),
            );
          }
        }}
      />
    );
    let patchedSuffix: ReactNode;

    if (isPasswordField) {
      patchedSuffix = showPassword
        ? hidePasswordIcon
        : showPasswordIcon;
    } else if (allowClear) {
      patchedSuffix = clearIcon;
    } else {
      patchedSuffix = suffix;
    }

    const maintainRef = useCallback(
      (element: HTMLInputElement | null) => {
        myOwnRef.current = element;
        if (ref) {
          if (typeof ref === "function") {
            ref(element);
          } else {
            // eslint-disable-next-line no-param-reassign
            ref.current = element;
          }
        }
      },
      [ref],
    );

    return (
      <AffixWrapper
        $activeColor={$activeColor}
        aria-invalid={
          invalid || formFieldContextValue["aria-invalid"]
        }
        className={className}
        disabled={disabled || formFieldContextValue.disabled}
        hasSuffix={!!patchedSuffix}
        style={style}
        type={restProps.type}
      >
        <InputRaw
          {...restProps}
          ref={maintainRef}
          aria-invalid={
            invalid || formFieldContextValue["aria-invalid"]
          }
          disabled={disabled || formFieldContextValue.disabled}
          onChange={onChange}
          type={
            isPasswordField && showPassword ? "text" : restProps.type
          }
        />
        {patchedSuffix && (
          <SuffixWrapper>{patchedSuffix}</SuffixWrapper>
        )}
        <Border $activeColor={$activeColor} />
      </AffixWrapper>
    );
  },
);

export default Input;
