import styled, { css } from "styled-components";
import { fonts } from "../../constants";
import Button from "../../lib/Button";
import mediaQuery from "../../utils/mediaQuery";

export const Header = styled.h1`
  display: block;
  font-family: ${fonts.faktum};
  font-size: 30px;
  font-weight: 500;
  line-height: 40px;
  margin: 0;
`;

export const ErrorHeader = styled(Header)`
  line-height: 30px;
  color: #4c4c4c;
`;

export const StyledButton = styled(Button)<{ $fullWidth?: boolean }>`
  font-family: ${fonts.faktum};
  font-size: 20px;
  font-weight: 500;

  ${({ $fullWidth }) =>
    $fullWidth
      ? undefined
      : mediaQuery(
          "desktopMedium",
          css`
            width: 230px;
          `,
        )}
`;

export const EmailBlock = styled.div`
  color: black;
  font-size: 20px;
  line-height: 1.5;
`;
