import { Trans } from "@lingui/macro";
import { FC } from "react";
import styled from "styled-components";
import { Authorization } from "../../../models/Authorization";
import { BoldBlack } from "../style";
import { RejectedTable } from "./CoveredTable";
import PrescribedRx from "./PrescribedRx";

type Props = {
  authorization: Authorization;
};

// const IntroRow = styled.div`
//   margin-bottom: 20px;
//   ${mediaQuery(
//     "greaterThanPhone",
//     css`
//       margin-bottom: 10px;
//     `,
//   )}
// `;

const PracticeRow = styled.div`
  margin-bottom: 20px;
`;

export const RxRejected: FC<Props> = ({ authorization }: Props) => {
  const { services } = authorization;

  const rejectedServices = services?.filter(
    (item) => item.state === "rejected",
  );
  return (
    <>
      <PracticeRow>
        <Trans>
          By{" "}
          <BoldBlack>
            Stork Club Rx ({authorization.practiceName})
          </BoldBlack>
        </Trans>
      </PracticeRow>

      {!!rejectedServices && rejectedServices.length > 0 && (
        <PrescribedRx>
          <RejectedTable services={rejectedServices} />
        </PrescribedRx>
      )}
    </>
  );
};
