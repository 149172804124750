import {
  createContext,
  useContext,
  CSSProperties,
  ReactNode,
} from "react";
import { MessageProps } from "../types";

export interface OpenMessageConfig {
  /**
   * Customized CSS class
   */
  className?: string;
  /**
   * The content of the message
   */
  content: ReactNode;
  /**
   * Time(ms) before auto-dismiss. Set 0 to disable
   */
  duration?: number;
  /**
   * The unique identifier of the Message
   */
  key?: number | string;
  /**
   * Customized inline style
   */
  style?: CSSProperties;
  /**
   * Specify a function that will be called when the message is clicked
   */
  onClick?: () => void;
  /**
   * Allow user to close the message manually
   */
  closable?: boolean;
  // /**
  //  * Specify a function that will be called when the message is closed
  //  */
  // onClose?: () => void;
}

export interface MessagesApi {
  /**
   * Show Critical message (red and black one)
   */
  critical: (config: OpenMessageConfig) => void;
  /**
   * Show Error message (red one)
   */
  error: (config: OpenMessageConfig) => void;
  /**
   * Show Success message (yellow one)
   */
  info: (config: OpenMessageConfig) => void;
  /**
   * Show Success message (green one)
   */
  success: (config: OpenMessageConfig) => void;
  /**
   * Hide a message by its key
   */
  destroy: (key: number | string) => void;
  /**
   * Allow user to close the message manually
   */
  closable?: boolean;
}

export const MessagesApiContext = createContext<MessagesApi>({
  critical: () => {
    throw new Error("Not implemented");
  },
  destroy: () => {
    throw new Error("Not implemented");
  },
  error: () => {
    throw new Error("Not implemented");
  },
  info: () => {
    throw new Error("Not implemented");
  },
  success: () => {
    throw new Error("Not implemented");
  },
});

export const MessagesContext = createContext<readonly MessageProps[]>(
  [],
);

export function useMessagesApi(): MessagesApi {
  return useContext(MessagesApiContext);
}
