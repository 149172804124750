import { defineMessage } from "@lingui/macro";
import { LocalizedError } from "./LocalizedError";

export const wrongApiResponse = new LocalizedError(
  defineMessage({
    message: "Wrong API response",
  }),
);

export const wrongResponseFormat = new LocalizedError(
  defineMessage({
    message: "Wrong response format",
  }),
);

export const signUpTokenNotDefined = new LocalizedError(
  defineMessage({
    message: "SignUp token is not defined!",
  }),
);

export const unknownError = new LocalizedError(
  defineMessage({
    message: "Unknown error",
  }),
);

export const unknownErrorReloadPage = new LocalizedError(
  defineMessage({
    message: "Unknown error. Please reload the page",
  }),
);
