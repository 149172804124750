import { useMutation } from "react-query";
import { useAxios } from "../../../network";
import { ENDPOINTS } from "../../../network/endpoints";

export function useUpdateDefaultTab() {
  const axios = useAxios();
  return useMutation(async (tab: string) => {
    await axios.put(ENDPOINTS.updateDefaultHomePageTab, {
      tab_id: tab,
    });
  });
}
