import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import { HTMLAttributes } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors } from "../../../constants";
import Button from "../../../lib/Button";
import { formatCurrency } from "../../../utils/currency";
import mediaQuery from "../../../utils/mediaQuery";

const DT = styled.dt`
  color: ${colors.brownBlack};
  font-size: 15px;
  font-weight: 500;
  line-height: calc(22 / 15); // 22 but relative to 15, so it scales
  padding: 0;
  margin: 0 0 4px;
`;
const DD = styled.dd`
  color: ${colors.black};
  font-weight: 700;
  font-size: 19px;
  line-height: calc(22 / 19); // 22 but relative to 19, so it scales
  padding: 0;
  margin: 0;
`;
const DL = styled.dl`
  column-gap: 20px;
  display: grid;
  flex: 1;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content min-content;
  margin: 0;
  padding: 0;
  ${DT} {
    grid-row: 1;
  }
  ${DD} {
    grid-row: 2;
  }
`;

const StyledButton = styled(Button)``;
const Container = styled.div<{ hideCoverage: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${mediaQuery(
    "greaterThanTablet",
    css`
      align-items: flex-start;
      flex-direction: row;
      justify-content: space-between;
    `,
  )}

  ${({ hideCoverage }) =>
    hideCoverage &&
    css`
      ${DL} {
        display: none;
      }
    `}

  ${({ hideCoverage }) =>
    hideCoverage &&
    mediaQuery(
      "greaterThanTablet",
      css`
        justify-content: end;
        margin-top: -75px;
      `,
    )}
`;

export interface SubmitExpensesWidgetProps
  extends HTMLAttributes<HTMLElement> {
  children?: never;
  backLinkUrl: string;
  preselectedProgramId?: string;
  total: number;
  remaining: number;
  currency: string;
  hideCoverage: boolean;
}

export default function StatelessSubmitExpensesWidget(
  props: SubmitExpensesWidgetProps,
) {
  const {
    total,
    remaining,
    backLinkUrl,
    currency,
    preselectedProgramId,
    ...restProps
  } = props;
  return (
    <Container {...restProps}>
      <DL>
        <DT>
          <Trans>Total coverage</Trans>
        </DT>
        <DD>{formatCurrency(total, currency, i18n.locale)}</DD>
        <DT>
          <Trans>Remaining coverage</Trans>
        </DT>
        <DD>{formatCurrency(remaining, currency, i18n.locale)}</DD>
      </DL>
      <StyledButton
        as={Link}
        kind="filledGreen"
        resetWidth
        state={{
          backLinkUrl,
          preselectedProgramId,
        }}
        to="/reimbursement"
      >
        <Trans>Submit expenses</Trans>
      </StyledButton>
    </Container>
  );
}
