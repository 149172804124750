import { Trans } from "@lingui/macro";
import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors } from "../../constants";
import { Tabs } from "../../lib";
import Icon from "../../lib/Icon";
import PageTitle from "../../lib/PageTitle";
import Skeleton from "../../lib/Skeleton";
import Title from "../../lib/Title";
import mediaQuery from "../../utils/mediaQuery";
import PageLayout, {
  Body,
  Footer,
  Sidebar,
  Header,
} from "../PageLayout";
import { useGetCompanyPrograms } from "./hooks";
import Tab from "./Tab";

const StyledHeader = styled(Header)`
  ${Title} {
    ${mediaQuery(
      "phone",
      css`
        font-size: 24px;
        line-height: 1;
      `,
    )};
  }
`;
const BackButtonContainer = styled.div`
  width: 100%;
  font-size: 0;
  margin-bottom: 20px;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      display: none;
    `,
  )}
`;
const BackButton = styled.button`
  background: none;
  border: none;
  display: block;
  margin: 0;
  padding: 0;

  &:active,
  &:focus,
  &:hover {
    background: none;
    border: none;
  }
`;
const Container = styled.div`
  max-width: var(--content-max-width);
  padding: 50px var(--content-margin-side) 0;
`;

const CompanyPrograms: FC = () => {
  let { categorySlug: categorySlugOrSlug, slug } = useParams<{
    categorySlug: string | undefined;
    slug: string | undefined;
  }>();
  const { data, isLoading } = useGetCompanyPrograms();
  let programsByCategory =
    data &&
    data.filter((item) => item.category.slug === categorySlugOrSlug);
  if (programsByCategory && programsByCategory.length === 0) {
    programsByCategory = undefined;
  }
  if (!programsByCategory) {
    const programBySlug =
      data && data.find((item) => item.slug === categorySlugOrSlug);
    if (programBySlug) {
      categorySlugOrSlug = programBySlug.category.slug;
      slug = programBySlug.slug;
    }
  }

  const navigate = useNavigate();

  const oldStyleLink = !!(
    categorySlugOrSlug &&
    slug &&
    !programsByCategory
  );
  useEffect(() => {
    // old style links
    if (categorySlugOrSlug && slug && !programsByCategory) {
      navigate(`/programs/${categorySlugOrSlug}/${slug}`);
    }
  }, [categorySlugOrSlug, navigate, programsByCategory, slug]);

  if (oldStyleLink) {
    return <Skeleton loading>{null}</Skeleton>;
  }

  const tabs =
    programsByCategory &&
    programsByCategory.map((item) => ({
      component: (
        <Container>
          <PageTitle>{item.name}</PageTitle>
          <Tab {...item} />
        </Container>
      ),
      key: item.slug,
      label: item.name,
      route: `/programs/${item.category.slug}/${item.slug}`,
    }));

  const activeTab =
    slug || (programsByCategory && programsByCategory[0].slug);

  return (
    <PageLayout
      footer={<Footer />}
      header={
        <StyledHeader>
          <BackButtonContainer>
            <BackButton onClick={() => navigate("/programs")}>
              <Icon fill={colors.brownGrey} type="back" />
            </BackButton>
          </BackButtonContainer>
          <Title level="h1">
            {programsByCategory && programsByCategory[0] ? (
              programsByCategory[0].category.name
            ) : (
              <Trans>Covered Programs</Trans>
            )}
          </Title>
        </StyledHeader>
      }
      layoutMode="textContent"
      sidebar={<Sidebar />}
    >
      <Body>
        <Skeleton active css="padding: 50px;" loading={isLoading}>
          {tabs ? (
            <Tabs
              key={categorySlugOrSlug}
              defaultActiveKey={activeTab}
              tabs={tabs}
              withDefaultPadding
            />
          ) : null}
        </Skeleton>
      </Body>
    </PageLayout>
  );
};

export default CompanyPrograms;
