/* eslint-disable max-lines */
import { isLeft } from "fp-ts/Either";
import * as t from "io-ts";
import reporter from "io-ts-reporters";
import { useQuery } from "react-query";
import {
  DateFromString,
  ImageSet,
  ISODate,
} from "../../../io-ts/types";
import { wrongResponseFormat } from "../../../locales/errors";
import { useAxios } from "../../../network";
import { ENDPOINTS } from "../../../network/endpoints";
import { createResourceIdentifierC } from "../../../network/jsonApi/builders";
import { IncludedJSONApiResourceObjectC } from "../../../network/jsonApi/core/JSONApiResourceObject";
import { denormalize } from "../../../network/jsonApi/denormalize";
import CareNavigatorC from "../../../network/jsonApiV2/models/specific/CareNavigator";
import { BillC } from "../../../notifications/models/Bill";
import { ServiceC } from "../../../notifications/models/Service";
import { StatusC } from "../../../notifications/models/Status";
import { StateC } from "../../../reimbursements/models/State";
import { CallC } from "../../CallSubjects/models/Call";
import { ProfileStatus } from "../../FamilyStatusForm/types";

const CallSubjectC = t.type({
  attributes: t.type({
    call_subject_type: t.string,
    call_subject_type_name: t.string,
    talk_to_expert_text: t.string,
  }),
  id: t.string,
  type: t.literal("call_subject"),
});

/// >--- on home page these entities are restricted
const HomePageNotificationEventC = t.type({
  attributes: t.type({
    author: t.string,
    created_at: DateFromString,
    name: StatusC,
  }),
  id: t.string,
  type: t.literal("notification_event"),
});
const HomePageNotificationC = t.type({
  attributes: t.type({
    practice_name: t.string,
    practice_template: t.union([
      t.literal("pharmacy"),
      t.literal("default"),
    ]),
    uid: t.string,
    unread_at: t.union([t.null, DateFromString]),
  }),
  id: t.string,
  relationships: t.exact(
    t.type({
      bill: t.type({
        data: t.union([t.null, createResourceIdentifierC("bill")]),
      }),
      events: t.type({
        data: t.array(
          createResourceIdentifierC("notification_event"),
        ),
      }),
      services: t.type({
        data: t.array(createResourceIdentifierC("service")),
      }),
    }),
  ),
  type: t.literal("notification"),
});

const HomePageReimbursementEventC = t.type({
  attributes: t.type({
    created_at: DateFromString,
    name: StateC,
    user_event: t.boolean,
  }),
  id: t.string,
  type: t.literal("reimbursement_event"),
});
const HomePageReimbursementC = t.type({
  attributes: t.type({
    notification_unread_at: t.union([t.null, DateFromString]),
    uid: t.string,
  }),
  id: t.string,
  relationships: t.exact(
    t.type({
      events: t.type({
        data: t.array(
          createResourceIdentifierC("reimbursement_event"),
        ),
      }),
    }),
  ),
  type: t.literal("reimbursement"),
});
/// on home page these entities are restricted ----<

const ProgramC = t.type({
  attributes: t.type({
    name: t.string,
    program_type: t.string,
  }),
  id: t.string,
  relationships: t.type({
    program_category: t.type({
      data: t.type({
        id: t.string,
        type: t.literal("program_category"),
      }),
    }),
  }),
  type: t.literal("program"),
});
const TabC = t.type(
  {
    attributes: t.type({
      name: t.string,
      title: t.string,
    }),
    id: t.string,
    relationships: t.type({
      blocks: t.type({
        data: t.array(createResourceIdentifierC("block")),
      }),
    }),
    type: t.literal("tab"),
  },
  "Tab",
);

const BlockC = t.type({
  attributes: t.type({
    title: t.string,
  }),
  id: t.string,
  relationships: t.intersection([
    t.type({
      call_subjects: t.type({
        data: t.array(createResourceIdentifierC("call_subject")),
      }),
      policies: t.type({
        data: t.array(createResourceIdentifierC("policy")),
      }),
      posts: t.type({
        data: t.array(createResourceIdentifierC("post")),
      }),
      programs: t.type({
        data: t.array(createResourceIdentifierC("program")),
      }),
    }),
    t.partial({
      future_calls: t.type({
        data: t.array(createResourceIdentifierC("call")),
      }),
    }),
  ]),
  type: t.literal("block"),
});

const PostC = t.type({
  attributes: t.type({
    image_set: ImageSet,
    intro: t.string,
    min_to_read: t.number,
    name: t.string,
    published_at: ISODate,
    show_cover: t.boolean,
    slug: t.string,
  }),
  id: t.string,
  relationships: t.exact(t.type({})), // Post relationships are broken so we omit them
  type: t.literal("post"),
});
const PolicyC = t.type({
  attributes: t.type({
    body: t.string,
    name: t.string,
    policy_type: t.string, // could be union
  }),
  id: t.string,
  type: t.literal("policy"),
});

// type Post = t.TypeOf<typeof PostC>;
// const UnknownIncludedObject = t.type({
//   attributes: t.type({}),
//   id: t.string,
//   type: t.string,
// });
const HomeDataResponse = t.type(
  {
    data: t.type(
      {
        attributes: t.type({
          default_tab_id: t.union([t.number, t.null]),
          notifications_enabled: t.boolean,
        }),
        id: t.string,
        relationships: t.intersection(
          [
            t.type({
              care_navigator: t.type({
                data: createResourceIdentifierC("call_expert"),
              }),

              tabs: t.type({
                data: t.array(createResourceIdentifierC("tab")),
              }),
            }),
            t.partial({
              notifications: t.type({
                data: t.array(
                  createResourceIdentifierC("notification"),
                ),
              }),
              reimbursements: t.type({
                data: t.array(
                  createResourceIdentifierC("reimbursement"),
                ),
              }),
            }),
          ],
          "HomeDataResponse.relationships",
        ),
        type: t.literal("homepage"),
      },
      "HomeDataResponse.data",
    ),
    included: t.array(
      t.union([
        CallSubjectC,
        CareNavigatorC,
        TabC,
        BlockC,
        PostC,
        PolicyC,
        ProgramC,
        HomePageNotificationC,
        HomePageNotificationEventC,
        HomePageReimbursementC,
        HomePageReimbursementEventC,
        ServiceC,
        BillC,
        CallC,
        IncludedJSONApiResourceObjectC,
      ]),
    ),
  },
  "HomeDataResponse",
);

export function useHomeData(
  currentFamilyStatus: ProfileStatus | null | undefined,
) {
  const axios = useAxios();

  return useQuery(["homeScreen", currentFamilyStatus], async () => {
    const response = await axios.get<unknown>(ENDPOINTS.getHomePage);
    const { data } = response;

    const decodeResult = HomeDataResponse.decode(data);
    if (isLeft(decodeResult)) {
      // eslint-disable-next-line no-console
      console.error(reporter.report(decodeResult));
      throw wrongResponseFormat;
    } else {
      const denormalized = denormalize(decodeResult.right);
      denormalized.relationships.tabs.forEach((tab) => {
        // hotfix - filter out empty blocks https://storkclub.slack.com/archives/G01FL7Q55A4/p1670389938381009
        // eslint-disable-next-line no-param-reassign
        tab.relationships.blocks = tab.relationships.blocks.filter(
          (block) => {
            const {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              call_subjects,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              future_calls,
              policies,
              posts,
              programs,
            } = block.relationships;
            const isEmptyBlock =
              call_subjects.length === 0 &&
              future_calls?.length === 0 &&
              policies.length === 0 &&
              posts.length === 0 &&
              programs.length === 0;
            return !isEmptyBlock;
          },
        );
      });

      return denormalized;
    }
  });
}
