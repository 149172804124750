import * as t from "io-ts";
import JSONApiLinkC from "../JSONApiLink";

const JSONApiResourceObjectLinksC = t.record(
  t.string,
  JSONApiLinkC,
  "JSONApiResourceObjectLinks",
);

export type JSONApiResourceObjectLinks = t.TypeOf<
  typeof JSONApiResourceObjectLinksC
>;

export default JSONApiResourceObjectLinksC;
