import { useState, useEffect, RefObject } from "react";

const useImageLoaded = (imageRef: RefObject<HTMLImageElement>) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (imageRef.current) {
      if (imageRef.current.complete) {
        setIsLoaded(true);
      } else {
        // eslint-disable-next-line no-param-reassign
        imageRef.current.onload = () => {
          setIsLoaded(true);
        };
      }
    }
  }, [imageRef]);

  return isLoaded;
};

export default useImageLoaded;
