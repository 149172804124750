import { t } from "@lingui/macro";
import React from "react";
import Badge from "../Badge";
import useGetUnreadNotifications from "./hooks";

const UnreadNotificationsBadge = () => {
  const { data } = useGetUnreadNotifications();
  return data ? (
    <Badge aria-label={t`You have unread message`} />
  ) : null;
};

export default UnreadNotificationsBadge;
