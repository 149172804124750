import { css } from "styled-components";
import { colors } from "../../constants/colors";

export const cardStylesMixin = css`
  background-color: ${colors.white};
  border: 1px solid ${colors.brownLightGrey1};
  border-radius: 10px;
  box-sizing: border-box;
  cursor: not-allowed;
  transition: border-color 0.15s;

  &[tabindex="0"] {
    cursor: pointer;
    &:focus-visible {
      outline: none;
    }
    &:hover,
    &:focus-visible {
      border-color: ${colors.coral};
      border-width: 2px;
    }
  }
  &[aria-checked="true"] {
    border-color: ${colors.coral};
    border-width: 2px;
  }
`;
