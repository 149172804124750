/* eslint-disable max-lines */
import { FC } from "react";
import { colors } from "../../../constants";
import { Status } from "../../models/Status";

const GrantedIcon = () => (
  <svg
    fill="none"
    height="22"
    viewBox="0 0 22 22"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="11"
      cy="11"
      r="10.25"
      stroke={colors.greenStable}
      strokeWidth="1.5"
    />
    <path
      d="M5.949 10.6331l3.5368 5.1382 6.0608-8.8083.5051-.734"
      stroke={colors.greenStable}
      strokeLinejoin="bevel"
      strokeWidth="1.5"
    />
  </svg>
);

const RequestedIcon = () => (
  <svg
    fill="none"
    height="22"
    viewBox="0 0 22 22"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      css={`
        [dir="rtl"] & {
          transform: rotate(180deg);
          transform-origin: center;
        }
      `}
    >
      <circle
        cx="11"
        cy="11"
        r="10.25"
        stroke={colors.brownGreyStable}
        strokeWidth="1.5"
      />
      <path
        d="M12.0002 15l4-4.001-4-3.999"
        stroke={colors.brownGreyStable}
        strokeWidth="1.5"
      />
      <path
        d="M5 11h10"
        stroke={colors.brownGreyStable}
        strokeLinejoin="bevel"
        strokeWidth="1.5"
      />
    </g>
  </svg>
);

const RejectedIcon = () => (
  <svg
    fill="none"
    height="22"
    viewBox="0 0 22 22"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="11"
      cy="11"
      r="10.25"
      stroke={colors.redStable}
      strokeWidth="1.5"
    />
    <path
      d="M6.85 15.1249l8.276-8.2759M15.1259 15.1539L6.8472 6.8751"
      stroke={colors.redStable}
      strokeWidth="1.5"
    />
  </svg>
);

const CanceledIcon = () => (
  <svg
    fill="none"
    height="22"
    viewBox="0 0 22 22"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="11"
      cy="11"
      r="10.25"
      stroke={colors.brownLightGrey1}
      strokeWidth="1.5"
    />
    <path
      d="M6.2856 11h9.4286"
      stroke={colors.brownLightGrey1}
      strokeWidth="1.5"
    />
  </svg>
);

type BillIconProps = {
  fill: string;
};

const BillIcon: FC<BillIconProps> = ({ fill }: BillIconProps) => (
  <svg fill="none" height="22" viewBox="0 0 22 22" width="22">
    <circle
      cx="11"
      cy="11"
      r="10.25"
      stroke={fill}
      strokeWidth="1.5"
    />
    <path
      d="M13.1914 13.8672c0-.4792-.1693-.8828-.5078-1.211-.3333-.3281-.8984-.625-1.6953-.8906-1.1094-.3385-1.9193-.7786-2.4297-1.3203-.5052-.5469-.7578-1.2396-.7578-2.0781 0-.8542.2474-1.5547.7422-2.1016.5-.5469 1.1849-.8724 2.0547-.9765V3.578h1.164v1.719c.875.1198 1.5547.487 2.0391 1.1015.4896.6146.7344 1.4558.7344 2.5235h-1.4375c0-.7344-.1745-1.3177-.5235-1.75-.3489-.4323-.8203-.6485-1.414-.6485-.6198 0-1.0938.1615-1.422.4844-.328.3177-.4921.763-.4921 1.336 0 .5312.1719.9557.5156 1.2734.349.3125.9193.5989 1.711.8594.7968.2552 1.4166.539 1.8593.8515.4479.3073.7761.6667.9844 1.0781.2135.4115.3203.8933.3203 1.4454 0 .8802-.2656 1.5885-.7969 2.125-.526.5364-1.2656.8541-2.2187.9531v1.4922h-1.1563v-1.4922c-.9687-.0886-1.7291-.4323-2.2812-1.0313-.5469-.6041-.8203-1.4218-.8203-2.4531h1.4453c0 .724.1901 1.2813.5703 1.6719.3802.3906.9193.5859 1.6172.5859.6823 0 1.2187-.164 1.6094-.4922.3906-.3281.5859-.776.5859-1.3437Z"
      fill={fill}
    />
  </svg>
);

export type StatusIconProps = {
  status: Status;
};

export const StatusIcon: FC<StatusIconProps> = ({
  status,
}: StatusIconProps) => {
  switch (status) {
    case "granted":
      return <GrantedIcon />;
    case "requested":
      return <RequestedIcon />;
    case "billPaymentFailed":
    case "rejected":
      return <RejectedIcon />;
    case "canceled":
    case "expired":
      return <CanceledIcon />;
    case "billAvailable":
    case "billPaymentInit":
      return <BillIcon fill="#000" />;
    case "billPaid":
      return <BillIcon fill={colors.greenStable} />;
    case "billPaymentDueSoon":
    case "billPaymentOverdue":
      return <BillIcon fill={colors.redStable} />;
    default:
      return null;
  }
};
