import { useLingui } from "@lingui/react";
import { ReactNode, useEffect } from "react";
import toCommonLocale from "../../locales/appLocale/config/toCommonLocale";

const useUpdateDocumentLanguage = () => {
  const { i18n } = useLingui();
  useEffect(() => {
    document.documentElement.lang = i18n.locale;
    const rtlLanguages = ["ar", "fa", "ur", "he", "yi"];
    const commonLocale = toCommonLocale(i18n.locale);
    const isRTL = commonLocale && rtlLanguages.includes(commonLocale);
    document.documentElement.dir = isRTL ? "rtl" : "ltr";
  }, [i18n.locale]);
};

export default function RTLProvider({
  children,
}: {
  children: ReactNode;
}) {
  useUpdateDocumentLanguage();
  return <>{children}</>;
}
