import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import { HTMLAttributes, SVGAttributes, useCallback } from "react";
import { useFieldArray } from "react-hook-form";
import styled, { css } from "styled-components";
import { colors } from "../../../../constants";
import Button from "../../../../lib/Button";
import Title from "../../../../lib/Title";
import { formatCurrency } from "../../../../utils/currency";
import mediaQuery from "../../../../utils/mediaQuery";
import { useReimbursementFormContext } from "../ReimbursementFormProvider";
import ExpensesItem from "./ExpensesItem";

export interface ExpensesBlockProps
  extends HTMLAttributes<HTMLElement> {
  children?: never;
  currency: string;
}

const BlockTitle = styled.div`
  color: ${colors.brownBlack};
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
`;
const TotalBlock = styled(Title)`
  text-align: end;
`;
const CardFooter = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledExpensesItem = styled(ExpensesItem)``;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  ${mediaQuery(
    "greaterThanTablet",
    css`
      row-gap: 32px;
    `,
  )}

  ${StyledExpensesItem} {
    border-bottom: 1px solid ${colors.brownLightGrey1};
    padding-bottom: 24px;

    &:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  ${mediaQuery(
    "greaterThanTablet",
    css`
      ${StyledExpensesItem} {
        border-bottom: none;
        padding-bottom: 0;
      }
    `,
  )}
`;
const CardContainer = styled.div`
  background: rgba(245, 243, 241, 0.8);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px 16px;

  ${mediaQuery(
    "greaterThanTablet",
    css`
      padding: 32px;
    `,
  )}
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${BlockTitle} {
    margin-bottom: 16px;
  }
`;

const AddExpenseButton = styled(Button)`
  padding-block: 13px;
  padding-inline: 20px 16px;
  white-space: nowrap;
`;

const PlusIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 20 20"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M9.25 10.75V16H10.75V10.75H16V9.25001H10.75V4H9.25V9.25001H4V10.75H9.25Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};
export default function ExpensesBlock({
  className,
  currency,
  ...restProps
}: ExpensesBlockProps) {
  const { control, watch } = useReimbursementFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "expenses",
  });
  const expensesWatch = watch(`expenses`);
  const totalAmount = expensesWatch
    ? expensesWatch.reduce((acc, item) => {
        const asN = Number(item.amount);
        if (Number.isNaN(asN)) {
          return acc;
        }
        return acc + asN;
      }, 0)
    : 0;

  const handlerAddItem = useCallback(() => {
    append({
      amount: undefined,
      date: undefined,
      description: undefined,
    });
  }, [append]);
  return (
    <Container className={className} {...restProps}>
      <BlockTitle>
        <Trans>
          Please enter your expenses as listed on your receipt:
        </Trans>
      </BlockTitle>
      <CardContainer>
        <ItemsContainer>
          {fields.map((field, index) => (
            <StyledExpensesItem
              key={field.id}
              currency={currency}
              data-testid="expenses-item"
              deletable={fields.length > 1}
              itemIndex={index}
              onRemoveItem={remove}
            />
          ))}
        </ItemsContainer>
        <CardFooter>
          <AddExpenseButton
            icon={<PlusIcon />}
            kind="outlinedCoral"
            onClick={handlerAddItem}
            resetWidth
            type="button"
          >
            <Trans>Add expense</Trans>
          </AddExpenseButton>
          <TotalBlock as="div" styleLevel="h3">
            <Trans>
              Total:{" "}
              {formatCurrency(totalAmount, currency, i18n.locale)}
            </Trans>
          </TotalBlock>
        </CardFooter>
      </CardContainer>
    </Container>
  );
}
