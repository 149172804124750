import { Trans } from "@lingui/macro";
import { FC } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Tabs, Title } from "../../lib";
import PageTitle from "../../lib/PageTitle";
import ProfileButton from "../../lib/ProfileButton";
import Skeleton from "../../lib/Skeleton";
import PageLayout, {
  Body,
  Footer,
  Header,
  Sidebar,
} from "../PageLayout";
import { useGetCompanyPolicies } from "./hooks";
import Tab from "./Tab";

const Container = styled.div`
  max-width: var(--content-max-width);
  padding: 50px var(--content-margin-side) 0;
`;

const CompanyPolicies: FC = () => {
  const { slug } = useParams<{
    slug: string | undefined;
  }>();
  const { data, isLoading } = useGetCompanyPolicies();
  const tabs =
    data &&
    data.map((item) => ({
      component: (
        <Container>
          <PageTitle>{item.name}</PageTitle>
          <Tab {...{ ...item }} />
        </Container>
      ),
      key: item.slug,
      label: item.name,
      route: `/company-policies/${item.slug}`,
    }));

  return (
    <PageLayout
      footer={<Footer />}
      header={
        <Header>
          <Title level="h1">
            <Trans>Company policies</Trans>
          </Title>

          <ProfileButton />
        </Header>
      }
      layoutMode="textContent"
      sidebar={<Sidebar />}
    >
      <Body>
        <Skeleton active css="padding: 50px;" loading={isLoading}>
          {tabs ? (
            <Tabs
              defaultActiveKey={slug}
              tabs={tabs}
              withDefaultPadding
            />
          ) : null}
        </Skeleton>
      </Body>
    </PageLayout>
  );
};

export default CompanyPolicies;
