import * as React from "react";
import { BaseIconAttributes } from "./types";

export const Lock: React.FC<BaseIconAttributes> = ({
  width = "1em",
  height = "1em",
  mainColor = "currentColor",
  ...restProps
}) => (
  <svg
    {...restProps}
    fill="none"
    height={height}
    viewBox="0 0 15 16"
    width={width}
  >
    <path
      d="M12.5145 5.21277H10.7273V3.7234C10.7273 1.66809 9.05923 0 7.00391 0C4.94859 0 3.2805 1.66809 3.2805 3.7234V5.21277H1.49327C0.674119 5.21277 0.00390625 5.88298 0.00390625 6.70213V14.1489C0.00390625 14.9681 0.674119 15.6383 1.49327 15.6383H12.5145C13.3337 15.6383 14.0039 14.9681 14.0039 14.1489V6.70213C14.0039 5.88298 13.3337 5.21277 12.5145 5.21277ZM4.76986 3.7234C4.76986 2.48723 5.76774 1.48936 7.00391 1.48936C8.24008 1.48936 9.23795 2.48723 9.23795 3.7234V5.21277H4.76986V3.7234ZM12.5145 14.1489H1.49327V6.70213H12.5145V14.1489ZM7.00391 11.9149C7.82306 11.9149 8.49327 11.2447 8.49327 10.4255C8.49327 9.60638 7.82306 8.93617 7.00391 8.93617C6.18476 8.93617 5.51454 9.60638 5.51454 10.4255C5.51454 11.2447 6.18476 11.9149 7.00391 11.9149Z"
      fill={mainColor}
    />
  </svg>
);
