import { Trans } from "@lingui/macro";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { Tabs } from "../../lib";
import PageTitle from "../../lib/PageTitle";
import ProfileButton from "../../lib/ProfileButton";
import Skeleton from "../../lib/Skeleton";
import Title from "../../lib/Title";
import mediaQuery from "../../utils/mediaQuery";
import PageLayout, {
  Body,
  Footer,
  Sidebar,
  Header,
} from "../PageLayout";
import ScrollHandler from "../ScrollHandler";
import { useGetCallCategories } from "./hooks/useGetCallCategories";
import Tab from "./Tab";

const MargedSkeleton = styled(Skeleton)`
  max-width: var(--content-max-width);
  padding: 30px var(--content-margin-side) 0;

  ${mediaQuery(
    "desktopMedium",
    css`
      padding-top: 50px;
    `,
  )}
`;

const CallSubjects: React.FC = () => {
  const { categoryId } = useParams<{
    categoryId: string | undefined;
  }>();
  const { data, isLoading } = useGetCallCategories();

  const tabs = useMemo(
    () =>
      data &&
      data.map((category) => ({
        component: (
          <>
            <PageTitle>
              {category.attributes.call_category_type_name}
            </PageTitle>
            <Tab id={category.id} />
          </>
        ),
        key: category.id,
        label: category.attributes.call_category_type_name,
        route: `/talk-to-experts/${category.id}`,
      })),
    [data],
  );

  return (
    <ScrollHandler>
      <PageLayout
        footer={<Footer />}
        header={
          <Header>
            <Title level="h1">
              <Trans>Talk to experts</Trans>
            </Title>

            <ProfileButton />
          </Header>
        }
        layoutMode="textContent"
        sidebar={<Sidebar />}
      >
        <Body>
          <MargedSkeleton active loading={isLoading}>
            {tabs ? (
              <Tabs
                defaultActiveKey={categoryId}
                tabs={tabs}
                withDefaultPadding
              />
            ) : null}
          </MargedSkeleton>
        </Body>
      </PageLayout>
    </ScrollHandler>
  );
};

export default CallSubjects;
