import { useMutation, useQueryClient } from "react-query";
import { Session } from "../../../authentication/hooks/useAuthSession";
import { useAxios } from "../../../network";
import { ENDPOINTS } from "../../../network/endpoints";
import { ProfileStatus } from "../types";

export function useUpdateFamilyStatus() {
  const client = useQueryClient();
  const axios = useAxios();
  return useMutation(
    async (status: ProfileStatus) => {
      await axios.put<unknown>(`${ENDPOINTS.updateProfileStatus}`, {
        status,
      });
    },
    {
      onSuccess: (data, status) => {
        client.setQueryData<Session | undefined>(
          "cookie",
          (existingData) => {
            if (existingData?.user) {
              return {
                ...existingData,
                user: {
                  ...existingData.user,
                  attributes: {
                    ...existingData.user.attributes,
                    status,
                  },
                },
              };
            }
            return existingData;
          },
        );
      },
    },
  );
}
