import * as yup from "yup";

type Type = "email";

const REGEX: Record<string, RegExp> = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

const validateString = (type: Type, str: string): boolean =>
  REGEX[`${type}`].test(String(str).toLowerCase());

export default validateString;

export function isValidEmail(email: string): boolean {
  try {
    yup.string().email().required().validateSync(email);
    return true;
  } catch (e) {
    return false;
  }
}
