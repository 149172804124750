import { Trans } from "@lingui/macro";
import { format as formatDate } from "date-fns";
import { FC } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../constants";
import mediaQuery from "../../../utils/mediaQuery";
import { Authorization } from "../../models/Authorization";
import { AuthorizationEvent } from "../../models/AuthorizationEvent";
import { BoldBlack } from "./style";

const Title = styled.div`
  flex-grow: 1;
  line-height: 22px;
  margin-inline-end: 20px;
`;

const Date = styled.div`
  color: ${colors.brownGrey};
  font-size: 12px;
  line-height: 14px;
  margin: 5px 0 0;

  ${mediaQuery(
    "desktopMedium",
    css`
      display: inline;
      font-size: 15px;
      line-height: 22px;
      margin: 0 10px;
      white-space: nowrap;
    `,
  )}
`;

const DATE_FORMAT = "MM-dd-yyyy";
const dt = (date: Date) => formatDate(date, DATE_FORMAT);

type Props = {
  authorization: Authorization;
  event: AuthorizationEvent;
};

export const HeaderTitle: FC<Props> = (props: Props) => {
  const { authorization, event } = props;
  const { status } = event;
  const { createdAt, practiceTemplate, practiceName, bill } =
    authorization;
  const isRx = practiceTemplate === "pharmacy";
  const date = dt(createdAt);

  if (status === "expired") {
    const { serviceDate } = authorization;

    return (
      <Title>
        {serviceDate ? (
          <Trans>
            Your authorization for Service to perform on{" "}
            {dt(serviceDate)} has <BoldBlack>expired</BoldBlack>
            <Date>{date}</Date>
          </Trans>
        ) : (
          <Trans>
            Your authorization for Service has{" "}
            <BoldBlack>expired</BoldBlack>
            <Date>{date}</Date>
          </Trans>
        )}
      </Title>
    );
  }

  if (status === "rejected") {
    return (
      <Title>
        {isRx ? (
          <Trans>
            Stork Club <BoldBlack>rejected</BoldBlack> Rx
            authorization
          </Trans>
        ) : (
          <Trans>
            Stork Club <BoldBlack>rejected</BoldBlack> Service
            authorization
          </Trans>
        )}
        <Date>{date}</Date>
      </Title>
    );
  }

  if (status === "granted") {
    return (
      <Title>
        {isRx ? (
          <Trans>
            Stork Club <BoldBlack>granted</BoldBlack> Rx authorization
          </Trans>
        ) : (
          <Trans>
            Stork Club <BoldBlack>granted</BoldBlack> Service
            authorization
          </Trans>
        )}
        <Date>{date}</Date>
      </Title>
    );
  }

  if (status === "requested") {
    return (
      <Title>
        {isRx ? (
          <Trans>
            Stork Club Rx {practiceName}{" "}
            <BoldBlack>requested</BoldBlack> Rx authorization
          </Trans>
        ) : (
          <Trans>
            {practiceName} <BoldBlack>requested</BoldBlack> Service
            authorization
          </Trans>
        )}
        <Date>{date}</Date>
      </Title>
    );
  }

  if (status === "canceled") {
    return (
      <Title>
        {isRx ? (
          <Trans>
            Stork Club Rx {practiceName}{" "}
            <BoldBlack>canceled</BoldBlack> Rx authorization
          </Trans>
        ) : (
          <Trans>
            {practiceName} <BoldBlack>canceled</BoldBlack> Service
            authorization
          </Trans>
        )}
        <Date>{date}</Date>
      </Title>
    );
  }

  if (bill && status === "billAvailable") {
    const { requestedAt, sequentialId } = bill;

    return (
      <Title>
        <Trans>
          Bill #{sequentialId} is <BoldBlack>due </BoldBlack>
        </Trans>
        {requestedAt && <Date>{dt(requestedAt)}</Date>}
      </Title>
    );
  }

  if (bill && status === "billPaymentInit") {
    const { startProcessingAt, sequentialId } = bill;

    return (
      <Title>
        <Trans>
          Bill #{sequentialId} is <BoldBlack>due </BoldBlack>
        </Trans>
        {startProcessingAt && <Date>{dt(startProcessingAt)}</Date>}
      </Title>
    );
  }

  if (bill && status === "billPaymentDueSoon") {
    const { paymentSoonDueDate, sequentialId } = bill;

    return (
      <Title>
        <Trans>
          Bill #{sequentialId} is <BoldBlack>due soon </BoldBlack>
        </Trans>
        {paymentSoonDueDate && <Date>{dt(paymentSoonDueDate)}</Date>}
      </Title>
    );
  }

  if (bill && status === "billPaymentOverdue") {
    const { paymentDueDate, sequentialId } = bill;

    return (
      <Title>
        <Trans>
          Bill #{sequentialId} is now <BoldBlack>overdue </BoldBlack>
        </Trans>
        {paymentDueDate && <Date>{dt(paymentDueDate)}</Date>}
      </Title>
    );
  }

  if (bill && status === "billPaymentFailed") {
    const { processedAt, sequentialId } = bill;

    return (
      <Title>
        <Trans>
          Payment for bill #{sequentialId} has{" "}
          <BoldBlack>failed</BoldBlack>
        </Trans>
        {processedAt && <Date>{dt(processedAt)}</Date>}
      </Title>
    );
  }

  if (bill && status === "billPaid") {
    const { processedAt, sequentialId } = bill;

    return (
      <Title>
        <Trans>
          Bill #{sequentialId} has been <BoldBlack>paid</BoldBlack>
        </Trans>
        {processedAt && <Date>{dt(processedAt)}</Date>}
      </Title>
    );
  }

  return null;
};
