import * as t from "io-ts";

export function createResourceIdentifierC<Type extends string>(
  type: Type,
): t.Type<{
  id: string;
  type: Type;
}> {
  return t.type(
    {
      id: t.string,
      type: t.literal(type),
    },
    `resourceIdentifier(${type})`,
  );
}

export function createAnonymousResourceIdentifierC(): t.Type<{
  id: string;
  type: string;
}> {
  return t.type(
    {
      id: t.string,
      type: t.string,
    },
    "resourceIdentifier",
  );
}
