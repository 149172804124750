import { useLingui } from "@lingui/react";
import * as React from "react";
import { useMemo } from "react";
import styled, { css } from "styled-components";
import { colors, fonts } from "../../constants";
import {
  LanguageSwitcherLocalesMap,
  languageSwitcherLocalesMap,
  ApplicationLocaleName,
} from "../../locales/appLocale/config";
import getPublicLocales from "../../locales/appLocale/config/getPublicLocales";
import Icon from "../Icon";
import { LocalesPanel } from "./LocalesPanel";

const Container = styled.div`
  color: ${colors.blueGreen};
  font-family: ${fonts.faktum};
  position: relative;
  user-select: none;
`;

const Switcher = styled.div<{ isOpen: boolean }>`
  align-items: center;
  background: ${colors.lightGreyBlue};
  border-radius: 8px;

  ${({ isOpen }) =>
    isOpen &&
    css`
      background: ${colors.lightGreyBlueHover};
      box-shadow: inset 0px 0px 0px 1px #9eaeb6;
    `}

  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  height: 40px;
  padding: 0 12px;

  &:hover {
    background: ${colors.lightGreyBlueHover};
  }
`;

const LocaleName = styled.div`
  font-size: 16px;
  font-weight: 500;
  padding: 0 4px 0 5px;

  &::first-letter {
    text-transform: uppercase;
  }
`;

interface Props {
  className?: string;
  localesProvided?: string[];
}

const LocaleSwitcher: React.FC<Props> = ({
  className,
  localesProvided,
}: Props) => {
  const { i18n } = useLingui();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const switcherRef = React.useRef<HTMLDivElement>(null);

  const publicLocales = getPublicLocales(languageSwitcherLocalesMap);
  const availableLocales = useMemo((): Array<{
    code: keyof LanguageSwitcherLocalesMap;
    title: string;
    shortTitle: string;
    fullCode: ApplicationLocaleName;
  }> => {
    return Object.entries(publicLocales)
      .map(([code, { getLabel, getShortLabel, fullCode }]) => ({
        code: code as keyof LanguageSwitcherLocalesMap,
        fullCode,
        shortTitle: getShortLabel(),
        title: getLabel(),
      }))
      .filter(({ code }) => {
        if (localesProvided) {
          return localesProvided.includes(code);
        }

        return true;
      })
      .sort((a, b) => {
        if (a.code === "en") {
          return -1;
        }

        if (b.code === "en") {
          return 1;
        }

        return a.title.localeCompare(b.title, i18n.locale);
      });
  }, [i18n.locale, localesProvided, publicLocales]);

  if (availableLocales.length < 2) return null;

  const selectedLocale = availableLocales.find(
    (locale) => locale.fullCode === i18n.locale,
  );

  return (
    <Container className={className}>
      <Switcher
        ref={switcherRef}
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Icon
          fill={colors.blueGreen}
          height={18}
          type="earth"
          width={18}
        />
        <LocaleName>{selectedLocale?.shortTitle || ""}</LocaleName>
        <svg height="7" viewBox="0 0 10 7" width="10">
          <path
            d="M1.17188 0L5 3.82812L8.82812 0L10 1.17188L5 6.17188L0 1.17188L1.17188 0Z"
            fill={colors.blueGreen}
          />
        </svg>
      </Switcher>
      {isOpen && (
        <LocalesPanel
          activeLocale={i18n.locale}
          locales={availableLocales}
          onClose={() => {
            setIsOpen(false);
          }}
          switcherRef={switcherRef}
        />
      )}
    </Container>
  );
};

export default LocaleSwitcher;
