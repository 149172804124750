import { useQuery } from "react-query";
import { UseQueryResult } from "react-query/types/react/types";
import { useAxios } from "../../network";
import { ENDPOINTS } from "../../network/endpoints";
import { Reimbursement } from "../models/Reimbursement";
import toGetReimbursementsResult from "./toGetReimbursementsResult";

const useGetReimbursements = (): UseQueryResult<{
  reimbursements: Reimbursement[];
}> => {
  const axios = useAxios();
  return useQuery("getReimbursements", async () => {
    const response = await axios.get<unknown>(
      ENDPOINTS.getReimbursementNotifications,
    );

    return toGetReimbursementsResult(response.data);
  });
};

export default useGetReimbursements;
