import * as t from "io-ts";
import EmptyArrayC from "./EmptyArray";
import JSONApiResourceIdentifierC from "./JSONApiResourceIdentifier";

const JSONApiResourceLinkageC = t.union(
  [
    t.null,
    EmptyArrayC,
    JSONApiResourceIdentifierC,
    t.array(JSONApiResourceIdentifierC),
  ],
  "JSONApiResourceLinkage",
);

export type JSONApiResourceLinkage = t.TypeOf<
  typeof JSONApiResourceLinkageC
>;

export default JSONApiResourceLinkageC;
