import React from "react";
import styled from "styled-components";
import { colors } from "../constants";
import Spinner from "../lib/Spinner";

const SpinnerWrapper = styled.div`
  padding-top: 40px;
`;

export default function GlobalSpinner() {
  return (
    <SpinnerWrapper>
      <Spinner color={colors.brownLightGrey1} size="40px" />
    </SpinnerWrapper>
  );
}
