import { Trans } from "@lingui/macro";
import { useState } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../constants";
import Title from "../../lib/Title";
import mediaQuery from "../../utils/mediaQuery";

import { HiddenProviderEntity } from "./types";

const Inner = styled.div`
  padding: 22px 22px 0;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      padding: 30px 40px 20px;
    `,
  )}
`;

const MoreLocations = styled.div`
  margin-bottom: 40px;
  margin-top: 14px;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      margin-bottom: 0;
    `,
  )}
`;

export const Container = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.brownLightGrey1};
  border-radius: 10px;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 5%);
  color: ${colors.brownBlack};
  font-size: 14px;
`;

const ShowMoreLocations = styled.div`
  color: #3e4fd6;
  cursor: pointer;
  text-decoration: underline;
`;

const StyledTitle = styled(Title)`
  margin: 0 0 10px;
`;

const InfoLine = styled.div`
  color: ${colors.brownGrey};
  font-size: 12px;
  line-height: 20px;
`;

export interface HiddenProviderProps {
  provider: HiddenProviderEntity;
}

export default function HiddenProvider(props: HiddenProviderProps) {
  const { provider } = props;
  const [firstLocation, ...restLocations] =
    provider.attributes.locations;

  const [showMoreLocations, setShowMoreLocations] = useState(false);

  return (
    <Container>
      <Inner>
        <StyledTitle>{provider.attributes.title}</StyledTitle>

        {firstLocation && (
          <InfoLine>{firstLocation.address}</InfoLine>
        )}

        {firstLocation && <InfoLine>{firstLocation.phone}</InfoLine>}

        {provider.attributes.locations.length > 0 && (
          <MoreLocations>
            {restLocations.length > 0 && !showMoreLocations && (
              <ShowMoreLocations
                onClick={() => setShowMoreLocations(true)}
              >
                <Trans>Show more locations</Trans>
              </ShowMoreLocations>
            )}
            {showMoreLocations &&
              restLocations.map((item) => (
                <InfoLine key={item.address}>{item.address}</InfoLine>
              ))}
          </MoreLocations>
        )}
      </Inner>
    </Container>
  );
}
