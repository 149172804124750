import { FC, SVGAttributes } from "react";

const Sheet: FC<SVGAttributes<SVGElement>> = (props) => {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 39 50"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g style={{ fill: "currentColor" }}>
        <path
          d="M11.4508983,1 L1,11.4948419 L1,49 L38,49 L38,1 L11.4508983,1 Z M11.0354552,0 L39,0 L39,50 L0,50 L0,11.0818568 L11.0354552,0 Z"
          id="Rectangle"
        />
        <path
          d="M10,2.41421356 L2.41421356,10 L10,10 L10,2.41421356 Z M11,0 L11,11 L0,11 L11,0 Z"
          id="Rectangle"
        />
        <polygon
          id="Line-2"
          points="9.91666667 18.5 9.91666667 17.5 29.9748406 17.5 29.9748406 18.5"
        />
        <polygon
          id="Line-2"
          points="9.91666667 27.5 9.91666667 26.5 29.9748406 26.5 29.9748406 27.5"
        />
        <polygon
          id="Line-2"
          points="10.4166667 36.5 9.91666667 36.5 9.91666667 35.5 24.5171605 35.5 24.5171605 36.5 24.0171605 36.5"
        />
      </g>
    </svg>
  );
};

export default Sheet;
