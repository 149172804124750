import * as t from "io-ts";
import { DateFromString } from "../../io-ts/types";
import { Status, StatusC } from "./Status";

export const AuthorizationEventC = t.type({
  attributes: t.type({
    author: t.string,
    created_at: DateFromString,
    name: StatusC,
  }),
  id: t.string,
  type: t.literal("notification_event"),
});

export type AuthorizationEventRaw = t.TypeOf<
  typeof AuthorizationEventC
>;

export type AuthorizationEvent = {
  id: string;
  createdAt: Date;
  status: Status;
  isBill: boolean;
  url: string;
  isRead: boolean;
};
