import { isLeft } from "fp-ts/Either";
import * as t from "io-ts";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { UseQueryResult } from "react-query/types/react/types";
import useAuthSession from "../../../../authentication/hooks/useAuthSession";
import { wrongResponseFormat } from "../../../../locales/errors";
import { useAxios } from "../../../../network";
import PartnerC, {
  PartnerEntity,
  AddPartnerEntity,
} from "../../../../network/jsonApiV2/models/specific/Partner";

export const responseC = t.type({
  data: t.union([PartnerC, t.null]),
});

export function responseToEntities(
  response: t.TypeOf<typeof responseC>,
): PartnerEntity | null {
  if (response.data) {
    const { id, type, attributes } = response.data;

    return {
      attributes,
      id,
      type,
    };
  }
  return null;
}

export function usePartner(): UseQueryResult<PartnerEntity> {
  const axios = useAxios();

  return useQuery("partner", async () => {
    const response = await axios.get(`/v2/partner`);

    const decodeResult = responseC.decode(response.data);
    if (isLeft(decodeResult)) {
      throw wrongResponseFormat;
    }

    return responseToEntities(decodeResult.right);
  });
}

export function useInvitePartner() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: AddPartnerEntity): Promise<void> => {
      await axios.post<unknown>(`/v2/partner`, payload);
    },
    {
      onSuccess: () => {
        queryClient
          .refetchQueries(["partner"])
          // eslint-disable-next-line no-console
          .catch((err) => console.error(err));
      },
    },
  );
}

export function useRevokePartner() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation(
    async (): Promise<void> => {
      await axios.delete<unknown>(`/v2/partner`);
    },
    {
      onSuccess: () => {
        queryClient
          .refetchQueries(["partner"])
          // eslint-disable-next-line no-console
          .catch((err) => console.error(err));
      },
    },
  );
}

export function usePartnerInvitationEnabled() {
  const { data: authSession } = useAuthSession();
  return (
    authSession?.user?.company.attributes
      .partner_invitation_enabled ?? true // true by default for backward compatibility
  );
}
