import { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useAuthSession from "../../../authentication/hooks/useAuthSession";
import { colors } from "../../../constants";
import Icon from "../../../lib/SharedIcon";
import { MainPageReimbursement } from "../../models/Reimbursement";
import { ReimbursementEvent } from "../../models/ReimbursementEvent";
import { HeaderTitle } from "./HeaderTitle";
import { StatusIcon } from "./StatusIcon";
import { NotificationContainer, Header, HeaderIcon } from "./style";

const ExpandIcon = styled.div`
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 30px;
  justify-content: center;
  transform: rotate(-90deg);
  user-select: none;
  width: 30px;

  ${Header}:hover & svg path {
    stroke: ${colors.blue};
  }

  [dir="rtl"] & {
    transform: rotate(90deg);
  }
`;

export type Props = {
  reimbursement: MainPageReimbursement;
  event: ReimbursementEvent;
};

export const ReimbursementEventPreview: FC<Props> = (
  props: Props,
) => {
  const { event, reimbursement } = props;
  const { state, isRead } = event;

  const { data: authSession } = useAuthSession();
  if (!authSession || !authSession.user) {
    throw new Error(
      "Cannot use currency without authSession defined",
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const currency: string =
    authSession.user.company.attributes.country_currency;

  return (
    <NotificationContainer
      $isSeen={isRead}
      as={Link}
      to={`/notifications/${reimbursement.uid}/${event.id}`}
    >
      <Header state={state}>
        <HeaderIcon>
          <StatusIcon state={state} />
        </HeaderIcon>
        <HeaderTitle
          currency={currency}
          event={event}
          reimbursement={reimbursement}
        />
        <ExpandIcon>
          <Icon type="arrowDown" width="14px" />
        </ExpandIcon>
      </Header>
    </NotificationContainer>
  );
};
