import { t } from "@lingui/macro";
import { isLeft } from "fp-ts/Either";
import reporter from "io-ts-reporters";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "react-query";
import { useMessagesApi } from "../../../lib/messages";
import {
  unknownError,
  wrongApiResponse,
} from "../../../locales/errors";
import { useAxios } from "../../../network";
import { ENDPOINTS } from "../../../network/endpoints";
import renderError from "../../../utils/renderError";
import {
  ResetPasswordTokenHolderResponseC,
  RegistrationCompany,
} from "../../models/TokenHolder";

export interface ResetPasswordPayload {
  recaptchaToken: string;
  resetPasswordToken: string;
  password: string;
  passwordConfirmation: string;
}
export function useResetPassword(): UseMutationResult<
  unknown,
  unknown,
  ResetPasswordPayload
> {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const messagesApi = useMessagesApi();
  return useMutation(
    async ({
      resetPasswordToken,
      password,
      passwordConfirmation,
      recaptchaToken,
    }) => {
      await axios.put<unknown>(`${ENDPOINTS.resetPassword}`, {
        "g-recaptcha-response": recaptchaToken,
        password,
        password_confirmation: passwordConfirmation,
        reset_password_token: resetPasswordToken,
      });

      return true;
    },
    {
      onSuccess: () => {
        // TODO: here we already have session data, so we able to skip additional "cookie" request
        queryClient
          .refetchQueries(["cookie"])
          .then(() => {
            messagesApi.success({
              content: t`Your password has been changed successfully. You are now signed in.`,
            });
          })
          .catch((err) =>
            messagesApi.error({
              content: renderError(err),
            }),
          );
      },
    },
  );
}

export interface CheckRestorePasswordTokenResult {
  passwordMinLength: number;
  email: string;
}
export function useCheckRestorePasswordToken(
  restorePasswordToken: string,
) {
  const axios = useAxios();

  return useQuery(
    "checkRestorePasswordToken",
    async (): Promise<CheckRestorePasswordTokenResult> => {
      const { data } = await axios.post<unknown>(
        `${ENDPOINTS.checkResetPasswordToken}`,
        {
          reset_password_token: restorePasswordToken,
        },
        {
          validateStatus: (status) =>
            status === 422 || status === 200,
        },
      );

      const responseDecode =
        ResetPasswordTokenHolderResponseC.decode(data);
      if (isLeft(responseDecode)) {
        // eslint-disable-next-line no-console
        console.error(reporter.report(responseDecode));
        throw wrongApiResponse;
      } else {
        if ("data" in responseDecode.right) {
          const companiesMap = new Map(
            responseDecode.right.included
              .filter(
                (element): element is RegistrationCompany =>
                  element.type === "company",
              )
              .map((element) => [element.id, element]),
          );
          const company = companiesMap.get(
            responseDecode.right.data.relationships.company.data.id,
          );
          if (!company) {
            throw new Error("Can not extract company");
          }

          return {
            email: responseDecode.right.data.attributes.email,
            passwordMinLength: company.attributes.password_min_length,
          };
        }
        if (responseDecode.right.errors[0]?.title) {
          throw new Error(responseDecode.right.errors[0]?.title);
        }
        throw unknownError;
      }
    },
    {
      cacheTime: 0,
      enabled: !!restorePasswordToken,
    },
  );
}
