import { Trans } from "@lingui/macro";
import { FC } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../../constants";
import LinkToTop from "../../../../lib/LinkToTop";
import mediaQuery from "../../../../utils/mediaQuery";
import {
  PostProps,
  TimeToRead,
  PostTitleLink,
  PostTitle,
  PostText,
} from "./index";

const PostImageLink = styled(LinkToTop)`
  display: block;
  flex-grow: 0;
  font-size: 0;
  height: calc((100vw - 80px) * 180 / 295);
  overflow: hidden;
  position: relative;

  img {
    height: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }

  ${mediaQuery(
    "greaterThanPhone", // Tablet height is 180
    css`
      height: 180px;
    `,
  )}

  ${mediaQuery(
    "desktopMedium",
    css`
      height: auto;

      img {
        position: static;
        transform: none;
      }
    `,
  )}
`;

const StyledPostText = styled(PostText)`
  ${mediaQuery(
    "desktopMedium",
    css`
      padding-top: 10px;
    `,
  )}
`;

const StyledPostTitle = styled(PostTitle)`
  margin: 0 0 8px;
`;

const ReadMore = styled(LinkToTop)`
  color: ${colors.blue};
  display: block;
  font-size: 15px;
  line-height: 24px;
`;

const StyledTimeToRead = styled(TimeToRead)`
  margin: 0 0 8px;
`;

const Container = styled.article`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;

  ${mediaQuery(
    "desktopMedium",
    css`
      grid-column: 1 / span 3;
      grid-gap: 30px;
      grid-template-columns: 1fr 1fr;
      min-height: 180px;
    `,
  )}
`;

const Post: FC<PostProps> = ({ post, ...restProps }) => {
  return (
    <Container {...restProps}>
      <PostImageLink
        aria-describedby={`post-${post.id}-title`}
        to={`/articles/${post.attributes.slug}`}
      >
        <img
          alt={post.attributes.name}
          sizes="(max-width: 767px) 210px, 400px"
          src={post.attributes.image_set.w800}
          srcSet={`${post.attributes.image_set.w64} 64w, ${post.attributes.image_set.w128} 128w, ${post.attributes.image_set.w256} 256w, ${post.attributes.image_set.w512} 512w, ${post.attributes.image_set.w800} 800w`}
        />
      </PostImageLink>
      <StyledPostText>
        <StyledTimeToRead>
          <Trans>min to read: {post.attributes.min_to_read}</Trans>
        </StyledTimeToRead>
        <PostTitleLink to={`/articles/${post.attributes.slug}`}>
          <StyledPostTitle
            id={`post-${post.id}-title`}
            level="h4"
            styleLevel="h1"
          >
            {post.attributes.name}
          </StyledPostTitle>
        </PostTitleLink>
        <ReadMore to={`/articles/${post.attributes.slug}`}>
          <Trans>Read more</Trans>
        </ReadMore>
      </StyledPostText>
    </Container>
  );
};

export default Post;
