import { FC, HTMLAttributes, useEffect } from "react";
import styled, { css } from "styled-components";
import Title from "../../../../lib/Title";
import mediaQuery from "../../../../utils/mediaQuery";
import { useUpdateDefaultTab } from "../../hooks/useUpdateDefaultTab";
import { HomePageBlock } from "../Block";
import Blocks from "../Blocks";

export interface HomePageTab {
  attributes: {
    name: string;
    title: string;
  };
  relationships: {
    blocks: HomePageBlock[];
  };
  id: string;
}

export interface TabProps extends HTMLAttributes<HTMLElement> {
  tab: HomePageTab;
}

const TabTitle = styled(Title)`
  margin-bottom: 20px;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 25px;
    `,
  )}
`;

const Container = styled.section`
  max-width: var(--content-max-width);
  padding: 30px var(--content-margin-side) 0;

  ${mediaQuery(
    "desktopMedium",
    css`
      padding-top: 50px;
    `,
  )}
`;

const Tab: FC<TabProps> = ({ tab, ...restProps }) => {
  const { mutate } = useUpdateDefaultTab();
  useEffect(() => {
    mutate(tab.id);
  }, [mutate, tab.id]);
  return (
    <Container {...restProps}>
      <TabTitle level="h1" styleLevel="h4">
        {tab.attributes.title}
      </TabTitle>
      <Blocks blocks={tab.relationships.blocks} />
    </Container>
  );
};

export default Tab;
