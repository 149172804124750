import getMediaContrast from "./getMediaContrast";

const defaultColors = {
  black: "#000000",
  blue: "#3E4FD6",
  blueGreen: "#2D4F61",
  blueGreenHover: "#004862",
  blueHover: "#2F42D2",
  brownBlack: "#4A4A4A",
  brownGrey: "#908B85",
  /**
   * @deprecated
   */
  brownGreyAA: "#797570",
  brownGreyAAA: "#5B5753",
  brownGreyHover: "#807B75",
  brownGreyStable: "#908B85",
  brownGreyStableHover: "#807B75",
  brownLightGrey1: "#E0DDD9",
  brownLightGrey2: "#F5F3F1",
  coral: "#FF7468",
  /**
   * Special coral, it will not look as a link in the contrast mode
   */
  coralBlueGreen: "#FF7468",
  coralBlueGreenHover: "#FF6457",
  coralHover: "#FF6457",
  coralHoverStable: "#FF6457",
  coralRedAAA: "#FF7468",
  coralStable: "#FF7468",
  gold: "#E0C485",
  green: "#21CD97",
  greenHover: "#10BC85",
  greenStable: "#21CD97",
  greenStableHover: "#10BC85",
  lightGreyBlue: "#F5F7F7",
  lightGreyBlueHover: "#E8EDED",
  red: "#EB5757",
  redAAA: "#BA1A1A",
  redStable: "#EB5757",
  white: "#FFFFFF",
};

const highContrastColors = {
  ...defaultColors,
  brownGrey: defaultColors.brownGreyAAA,
  coral: defaultColors.blue,
  coralBlueGreen: defaultColors.blueGreen,
  coralBlueGreenHover: defaultColors.blueGreenHover,
  coralHover: defaultColors.blueHover,
  coralRedAAA: defaultColors.redAAA,
  green: defaultColors.blueGreen,
  greenHover: defaultColors.blueGreenHover,
  red: defaultColors.redAAA,
};

export const highContrastMode =
  localStorage.getItem("highContrast") === "true" ||
  getMediaContrast();
// eslint-disable-next-line no-console
export const colors = highContrastMode
  ? { ...highContrastColors }
  : { ...defaultColors };

export function updateHighContrastSetting(value: boolean): void {
  const currentStoredValue =
    localStorage.getItem("highContrast") === "true";
  if (currentStoredValue !== value) {
    localStorage.setItem("highContrast", value ? "true" : "false");
    // dirty hack - reload the page to apply the new colors
    window.location.reload();
  }
}
