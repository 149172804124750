import { t } from "@lingui/macro";
import React, { useContext } from "react";
import Badge from "./Badge";
import { UnreadCountContext } from "./UnreadCountProvider";

export default function UnreadChatBadge() {
  const unreadSendBirdMessageCount = useContext(UnreadCountContext);

  return (
    <>
      {unreadSendBirdMessageCount > 0 ? (
        <Badge aria-label={t`You have unread message`}>
          {unreadSendBirdMessageCount}
        </Badge>
      ) : null}
    </>
  );
}
