import { t, Trans } from "@lingui/macro";
import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors } from "../../constants";
import mediaQuery from "../../utils/mediaQuery";
import useAuthSession from "../hooks/useAuthSession";

const StyledLink = styled(Link)``;

const Item = styled.div`
  padding-left: 20px;
  position: relative;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      width: 370px;
    `,
  )}
`;
const ItemIcon = styled.div`
  left: 0;
  position: absolute;
  top: 2px;
`;

const Text = styled.div`
  color: #000;
  font-size: 15px;
  line-height: 20px;
`;

const ShowMore = styled.button`
  background: none;
  border: none;
  color: ${colors.blue};
  cursor: pointer;
  display: inline;
`;

const ShowMoreText = styled.div`
  color: #4c4c4c;
  font-size: 13px;
  line-height: 16px;
  padding-top: 4px;
  ${StyledLink} {
    color: ${colors.blue};
  }
`;

const Container = styled.div`
  margin-top: 32px;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      margin-top: 40px;
    `,
  )}

  ${Item} + ${Item} {
    margin-top: 12px;
  }
`;

interface Props {
  full?: boolean;
}

const InfoIcon = () => (
  <svg
    fill="none"
    height="15"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 .15c-3.864 0-7 3.137-7 7 0 3.864 3.136 7 7 7s7-3.136 7-7c0-3.863-3.136-7-7-7Zm.7 10.5H6.3v-1.4h1.4v1.4Zm0-2.8H6.3v-4.2h1.4v4.2Z"
      fill="#9EAEB6"
    />
  </svg>
);

const Info: React.FC<Props> = ({ full }: Props) => {
  const { data: authSession } = useAuthSession();
  const cobra = authSession?.cobra;

  const [isShowNoLongerMore, setShowNoLongerMore] =
    React.useState<boolean>(false);

  const onShowNoLongerMoreClick = React.useCallback(() => {
    setShowNoLongerMore(!isShowNoLongerMore);
  }, [isShowNoLongerMore]);

  if (!full && !cobra) return null;

  return (
    <Container>
      {full && (
        <Item>
          <ItemIcon>
            <svg
              fill="none"
              height="15"
              width="14"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 .15c-3.864 0-7 3.137-7 7 0 3.864 3.136 7 7 7s7-3.136 7-7c0-3.863-3.136-7-7-7Zm3.5 7.7H7.7v2.8H6.3v-2.8H3.5v-1.4h2.8v-2.8h1.4v2.8h2.8v1.4Z"
                fill="#9EAEB6"
              />
            </svg>
          </ItemIcon>
          <Text>
            <Trans>
              You&#39;ll be able to invite your partner on the next
              step.
            </Trans>
          </Text>
        </Item>
      )}
      {cobra && (
        <Item>
          <ItemIcon>
            <InfoIcon />
          </ItemIcon>
          <Text>
            <Trans>
              No longer working for the company with Stork Club
              coverage?{" "}
            </Trans>
            <ShowMore onClick={onShowNoLongerMoreClick} role="button">
              {isShowNoLongerMore ? t`Show less` : t`Show more`}
            </ShowMore>
          </Text>
          {isShowNoLongerMore && (
            <ShowMoreText>
              <Trans>
                If you’re no longer working for the company with Stork
                Club coverage but you have COBRA for your health plan,
                reach out to us at{" "}
                <StyledLink
                  target="_blank"
                  to="mailto:care@joinstorkclub.com"
                >
                  care@joinstorkclub.com
                </StyledLink>{" "}
                to verify your eligibility and restore access to your
                account.
              </Trans>
            </ShowMoreText>
          )}
        </Item>
      )}
    </Container>
  );
};

export default Info;
