import * as t from "io-ts";
import JSONApiLinkC from "./JSONApiLink";

export const JSONApiErrorLinksC = t.type({
  about: JSONApiLinkC,
});

export type JSONApiErrorLinks = t.TypeOf<typeof JSONApiErrorLinksC>;

const JSONApiErrorC = t.partial({
  /**
   *  an application-specific error code, expressed as a string value.
   */
  code: t.string,

  /**
   * a human-readable explanation specific to this occurrence of the problem. Like title, this field’s value can be localized.
   */
  detail: t.string,

  /**
   * a unique identifier for this particular occurrence of the problem.
   */
  id: t.string,

  /**
   * leads to further details about this particular occurrence of the problem
   */
  links: JSONApiErrorLinksC,

  /**
   * Not implemented
   */
  meta: t.unknown,

  source: t.type({
    pointer: t.string,
  }),

  /**
   *  the HTTP status code applicable to this problem, expressed as a string value.
   */
  status: t.string,

  /**
   * a short, human-readable summary of the problem that SHOULD NOT change from occurrence to occurrence of the problem, except for purposes of localization.
   */
  title: t.string,
});

export type JSONApiError = t.TypeOf<typeof JSONApiErrorC>;
export default JSONApiErrorC;
