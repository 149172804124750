const longTimer = (
  targetTime: Date,
  interval: number,
  signal: AbortSignal,
) => {
  return new Promise((resolve) => {
    function frame() {
      if (signal.aborted) return;
      if (new Date() >= targetTime) {
        resolve(true);
      } else {
        setTimeout(() => requestAnimationFrame(frame), interval);
      }
    }

    frame();
  });
};

export default longTimer;
