import { Trans } from "@lingui/macro";
import styled from "styled-components";
import { colors } from "../constants";

const StyledLink = styled.a`
  position: fixed;
  top: 0;
  inset-inline-start: -10000px;
  color: ${colors.white};
  background: ${colors.brownBlack};
  padding: 10px 15px;
  z-index: 100;
  border: 1px solid ${colors.black};
  border-radius: 4px;
  cursor: pointer;

  &:focus {
    inset-inline-start: 0;
  }
`;

export default function BypassLink() {
  return (
    <StyledLink href="#maincontent">
      <Trans>Skip to main content</Trans>
    </StyledLink>
  );
}
