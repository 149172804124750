import * as t from "io-ts";

const JSONApiObjectLink = t.exact(
  t.intersection([
    t.type({
      href: t.string,
    }),
    t.partial({
      meta: t.unknown,
    }),
  ]),
  "JSONApiObjectLink",
);

const JSONApiLinkC = t.union(
  [t.string, JSONApiObjectLink],
  "JSONApiLink",
);

export type JSONApiLink = t.TypeOf<typeof JSONApiLinkC>;
export default JSONApiLinkC;
