import { t, Trans } from "@lingui/macro";
import React, { lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import useAuthSession from "../../authentication/hooks/useAuthSession";
import PageLayout, {
  Body,
  Header,
  Sidebar,
} from "../../components/PageLayout";
import { colors } from "../../constants";
import Icon from "../../lib/Icon";
import PageTitle from "../../lib/PageTitle";
import Title from "../../lib/Title";
import mediaQuery from "../../utils/mediaQuery";
import ErrorBoundary from "./ErrorBoundary";
import SendBirdCantConnect from "./SendBirdCantConnect";
import Spinner from "./Spinner";

const NewChatContent = lazy(() => import("./NewChatContent"));

const ContentContainer = styled.div`
  --chat-padding-bottom: 20px; // variable is used in MessageInput, here we have 0
  --chat-padding-top: 20px; // variable is used in ListTop to calc an offset
  box-sizing: border-box;
  height: 100%;
  padding: var(--chat-padding-top) 20px 0;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      margin-inline-end: var(--content-margin-side);
      max-width: 800px;
      padding-block: var(--chat-padding-top) 0;
      padding-inline: var(--content-margin-side) 0;
    `,
  )}

  ${mediaQuery(
    "greaterThanTablet",
    css`
      --chat-padding-top: 40px;
    `,
  )};

  // reusing styles, so initial Spinner and Spinner from Messages list have the same position without layout shifting between loading phases
  .spinner-top {
    margin-bottom: 20px;
    margin-top: calc(20px - var(--chat-padding-top));
  }
`;
const StyledTitle = styled(Title)`
  ${mediaQuery(
    "phone",
    css`
      margin-inline-end: 40px;
    `,
  )}
`;

const BackButtonContainer = styled.div`
  margin-bottom: 14px;
  width: 100%;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      display: none;
    `,
  )}
`;

const BackButton = styled.button`
  background: none;
  border: none;
  display: block;
  margin: 0;
  padding: 0;
  &:active,
  &:focus,
  &:hover {
    background: none;
    border: none;
  }
`;
const StyledHeader = styled(Header)`
  max-width: unset;
`;

export default function NewChatScreen() {
  const { data } = useAuthSession();
  const navigate = useNavigate();

  if (!data || !data.user) {
    throw new Error("Not logged in");
  }
  const sendbirdCredentials =
    data.user.attributes.sendbird_credentials;
  return (
    <PageLayout
      header={
        <StyledHeader>
          <BackButtonContainer>
            <BackButton onClick={() => navigate("/")}>
              <Icon fill={colors.brownGrey} type="back" />
            </BackButton>
          </BackButtonContainer>
          <PageTitle>{t`Conversation with Care Partner`}</PageTitle>
          <StyledTitle level="h1">
            <Trans>Conversation with Care Partner</Trans>
          </StyledTitle>
        </StyledHeader>
      }
      sidebar={<Sidebar />}
    >
      <Body>
        <ContentContainer>
          <ErrorBoundary fallback={<SendBirdCantConnect />}>
            <Suspense fallback={<Spinner className="spinner-top" />}>
              <NewChatContent
                sendbirdCredentials={sendbirdCredentials}
              />
            </Suspense>
          </ErrorBoundary>
        </ContentContainer>
      </Body>
    </PageLayout>
  );
}
