import { Trans } from "@lingui/macro";
import * as React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors, fonts } from "../../../constants";
import { Text } from "../../../lib/Text";
import mediaQuery from "../../../utils/mediaQuery";

const Container = styled.section`
  background: #fff;
  border: 1px solid ${colors.brownLightGrey1};
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px;
  position: relative;

  ${mediaQuery(
    "greaterThanPhone", // Should be visible only in mobile?
    css`
      display: none;
    `,
  )}
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ViewAll = styled(Link)`
  color: ${colors.blue};
  font-size: 15px;
  line-height: 32px;
`;

const Title = styled.h1`
  display: flex;
  font-family: ${fonts.alt};
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
  margin-bottom: 10px;
  margin-top: 0;
`;

const NoNewWidget: React.FC = () => (
  <Container aria-labelledby="NotificationsNoNew_Title">
    <Top>
      <Title id="NotificationsNoNew_Title">
        <Trans>Notifications</Trans>
      </Title>
      <ViewAll to="/notifications">
        <Trans>View all</Trans>
      </ViewAll>
    </Top>

    <Text color={colors.brownGrey} textType="body">
      <Trans>No new notifications.</Trans>
    </Text>
  </Container>
);

export default NoNewWidget;
