import { MouseEventHandler, ReactNode, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";

const useNavigateToTop = () => {
  const navigate = useNavigate();

  return useCallback(
    (to: string) => {
      navigate(to);
      window.scrollTo({
        behavior: "instant" as unknown as ScrollBehavior,
        left: 0,
        top: 0,
      });
    },
    [navigate],
  );
};

interface Props {
  children: ReactNode;
  className?: string;
  to: string;
}

// TODO: maybe we want to do it globally? (using react-router-dom)
// https://stackoverflow.com/a/71444024
const LinkToTop = ({ to, className, children }: Props) => {
  const navigateToTop = useNavigateToTop();

  const navigateAndReset: MouseEventHandler<HTMLAnchorElement> = (
    event,
  ) => {
    event.preventDefault();
    navigateToTop(to);
  };

  return (
    <Link className={className} onClick={navigateAndReset} to={to}>
      {children}
    </Link>
  );
};

export default LinkToTop;
