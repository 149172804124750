import { CSSProperties } from "react";
import styled from "styled-components";
import { colors, fonts } from "../../constants";

export type TextType =
  | "body"
  | "bodyBold"
  | "bodyButton"
  | "bodyMedium"
  | "default"
  | "smallText";

export interface ExtraProps {
  textType?: TextType;
  color?: string;
}

type DefaultExtraProps = Required<Pick<ExtraProps, "textType">>;
const defaultProps: DefaultExtraProps = {
  textType: "default",
};

const FONT_SIZE: Record<
  TextType,
  NonNullable<CSSProperties["fontSize"]>
> = {
  body: "15px",
  bodyBold: "15px",
  bodyButton: "16px",
  bodyMedium: "15px",
  default: "15px",
  smallText: "12px",
};

const LINE_HEIGHT: Record<
  TextType,
  NonNullable<CSSProperties["lineHeight"]>
> = {
  body: "24px",
  bodyBold: "24px",
  bodyButton: "24px",
  bodyMedium: "24px",
  default: "15px",
  smallText: "16px",
};

const FONT_WEIGHT: Record<
  TextType,
  NonNullable<CSSProperties["fontWeight"]>
> = {
  body: "normal",
  bodyBold: "500",
  bodyButton: "normal",
  bodyMedium: "500",
  default: "normal",
  smallText: "normal",
};

const FONT_FAMILY: Record<
  TextType,
  NonNullable<CSSProperties["fontFamily"]>
> = {
  body: fonts.main,
  bodyBold: fonts.main,
  bodyButton: fonts.main,
  bodyMedium: fonts.main,
  default: fonts.main,
  smallText: fonts.main,
};

export const Text = styled.span.attrs(
  (props: DefaultExtraProps & ExtraProps) => props,
)<ExtraProps>`
  color: ${({ color }) => color || colors.black};
  font-size: ${({ textType }) => FONT_SIZE[textType]};
  font-weight: ${({ textType }) => FONT_WEIGHT[textType]};
  line-height: ${({ textType }) => LINE_HEIGHT[textType]};
  font-family: ${({ textType }) => FONT_FAMILY[textType]};
  margin: 0;
  padding: 0;
  a {
    color: ${colors.blue};
  }
`;

Text.defaultProps = defaultProps;
