import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors } from "../../constants";
import { Logo } from "../../lib/Logo";
import Skeleton from "../../lib/Skeleton";
import mediaQuery from "../../utils/mediaQuery";
import { useProfile } from "../ProfilePage/hooks";
import Form from "./Form";
import { LinkBackIcon, ArrowLeftIcon } from "./Icons";

const LogoContainer = styled(Link)`
  display: inline-block;
  font-size: 0;
  margin-inline: 30px 0;
  margin-top: 30px;

  ${mediaQuery(
    "phone",
    css`
      display: none;
    `,
  )}
`;

const Container = styled.div`
  padding: 12px 0 0;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      padding: 40px 0 30px;
    `,
  )}
`;

const FormContainer = styled.div`
  border-color: ${colors.brownLightGrey1};
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 728px;
  padding: 0 20px 20px;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      border-radius: 10px;
      border-style: solid;
      border-width: 1px;
      padding: 40px 60px;
    `,
  )}
`;

const LinkBackDesktopContainer = styled.div`
  margin: 0 auto 20px;
  width: 606px;

  ${mediaQuery(
    "phone",
    css`
      display: none;
    `,
  )}
`;

const LinkBackDesktop = styled(Link)`
  align-items: center;
  color: ${colors.blue};
  display: inline-flex;
  text-decoration: none;

  svg {
    margin-inline-end: 10px;
  }

  &:hover {
    color: ${colors.black};
  }

  &:active {
    color: ${colors.brownBlack};
  }
`;

const LinkBackMobile = styled(Link)`
  display: inline-block;
  font-size: 0;
  margin-bottom: 14px;
  margin-inline-start: 17px;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      display: none;
    `,
  )}
`;

const ProfileCompletion: React.FC = () => {
  const { data, isLoading } = useProfile();
  const i18n = useLingui();
  const medicalPlanTypeOptions = useMemo(
    () =>
      data?.relationships.company.attributes.company_plan_types.map(
        (item) => ({ label: i18n._(item.label), value: item.value }),
      ) || [],
    [data?.relationships.company.attributes.company_plan_types, i18n],
  );
  const medicalPlanOptions = useMemo(
    () =>
      data?.relationships.company.relationships.companyPlans.map(
        (item) => ({
          label: item.attributes.name,
          value: item.id,
        }),
      ) || [],
    [data],
  );

  return (
    <>
      <LogoContainer to="/">
        <Logo fill={colors.brownLightGrey1} />
      </LogoContainer>
      <Container>
        <LinkBackDesktopContainer>
          <LinkBackDesktop to="/notifications">
            <LinkBackIcon />
            <Trans>Back to Notifications</Trans>
          </LinkBackDesktop>
        </LinkBackDesktopContainer>
        <LinkBackMobile to="/notifications">
          <ArrowLeftIcon />
        </LinkBackMobile>
        <FormContainer>
          <Skeleton active loading={isLoading}>
            {data ? (
              <Form
                companyName={
                  data.relationships.company.attributes.name
                }
                medicalPlanOptions={medicalPlanOptions}
                medicalPlanTypeOptions={medicalPlanTypeOptions}
                profileData={data}
              />
            ) : null}
          </Skeleton>
        </FormContainer>
      </Container>
    </>
  );
};

export default ProfileCompletion;
