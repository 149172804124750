import * as t from "io-ts";

const PartnerC = t.type({
  attributes: t.type({
    email: t.string,
    invitation_accepted: t.boolean,
  }),
  id: t.string,
  type: t.literal("partner"),
});

const AddPartnerC = t.type({
  email: t.string,
});

export type PartnerEntity = t.TypeOf<typeof PartnerC>;
export type AddPartnerEntity = t.TypeOf<typeof AddPartnerC>;

export default PartnerC;
