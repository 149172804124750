export function range(end: number): number[];
export function range(start: number, end: number): number[];
export function range(startOrEnd: number, end?: number): number[] {
  let startV: number;
  let endV: number;
  if (typeof end === "undefined") {
    endV = startOrEnd;
    startV = 0;
  } else {
    startV = startOrEnd;
    endV = end;
  }

  return [...Array(endV - startV + 1).keys()].map((x) => x + startV);
}
