/* eslint-disable max-lines */
import { CSSProperties, ReactNode } from "react";
import styled, { css } from "styled-components";
import { colors, fonts } from "../../constants";
import hexColorOpacity from "../../utils/hexColorOpacity";
import mediaQuery from "../../utils/mediaQuery";
import Icon from "../SharedIcon";
import {
  CoreButton,
  CoreButtonIcon,
  CoreButtonInner,
} from "./CoreButton";

export type ButtonKind =
  | "filledBlue"
  | "filledBlueGreen"
  | "filledCoral"
  | "filledGreen"
  | "outlinedBlue"
  | "outlinedBlueGreen"
  | "outlinedCoral"
  | "outlinedGreen";

export interface ButtonExtraProps {
  loading?: boolean;
  icon?: ReactNode;
  kind?: ButtonKind;
  resetWidth?: boolean;
}

const Background: Record<ButtonKind, CSSProperties["background"]> = {
  filledBlue: colors.blue,
  filledBlueGreen: colors.blueGreen,
  filledCoral: colors.coral,
  filledGreen: colors.green,
  outlinedBlue: "transparent",
  outlinedBlueGreen: "transparent",
  outlinedCoral: "transparent",
  outlinedGreen: "transparent",
};
const BackgroundHover: Record<
  ButtonKind,
  CSSProperties["background"]
> = {
  filledBlue: colors.blueHover,
  filledBlueGreen: colors.blueGreenHover,
  filledCoral: colors.coralHover,
  filledGreen: colors.greenHover,
  outlinedBlue: hexColorOpacity(colors.blue, 0.05),
  outlinedBlueGreen: hexColorOpacity(colors.blueGreen, 0.05),
  outlinedCoral: hexColorOpacity(colors.coral, 0.05),
  outlinedGreen: hexColorOpacity(colors.green, 0.05),
};
const BackgroundPressed: Record<
  ButtonKind,
  CSSProperties["background"]
> = {
  filledBlue: colors.blue,
  filledBlueGreen: colors.blueGreen,
  filledCoral: colors.coral,
  filledGreen: colors.green,
  outlinedBlue: hexColorOpacity(colors.blue, 0.1),
  outlinedBlueGreen: hexColorOpacity(colors.blueGreen, 0.1),
  outlinedCoral: hexColorOpacity(colors.coral, 0.1),
  outlinedGreen: hexColorOpacity(colors.green, 0.1),
};

const BorderColor: Record<ButtonKind, CSSProperties["borderColor"]> =
  {
    filledBlue: "transparent",
    filledBlueGreen: "transparent",
    filledCoral: "transparent",
    filledGreen: "transparent",
    outlinedBlue: colors.blue,
    outlinedBlueGreen: colors.blueGreen,
    outlinedCoral: colors.coral,
    outlinedGreen: colors.green,
  };

const Color: Record<ButtonKind, CSSProperties["color"]> = {
  filledBlue: colors.white,
  filledBlueGreen: colors.white,
  filledCoral: colors.white,
  filledGreen: colors.white,
  outlinedBlue: colors.blue,
  outlinedBlueGreen: colors.blueGreen,
  outlinedCoral: colors.coral,
  outlinedGreen: colors.green,
};

const Button = styled(CoreButton)
  .attrs(
    ({
      kind = "filledBlue",
      resetWidth = false,
      icon,
      loading,
    }: ButtonExtraProps) => {
      return {
        icon: loading ? <Icon type="spinner" /> : icon,
        kind,
        loading,
        resetWidth,
      };
    },
  )
  .withConfig({
    shouldForwardProp: (prop) =>
      prop !== "resetWidth" && prop !== "kind" && prop !== "loading",
  })`
  background: ${({ kind }) => {
    return Background[kind];
  }};
  border: 1px solid;
  border-color: ${({ kind }) => BorderColor[kind]};
  box-sizing: border-box;
  border-radius: 10px;
  color: ${({ kind }) => Color[kind]};
  font-family: ${fonts.main};
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding: 12px 23px;
  width: ${({ resetWidth }) => (resetWidth ? undefined : "100%")};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: 0.075s ease-in-out;
  text-decoration: none;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      padding: 12px 39px;
    `,
  )}

  &:hover {
    background: ${({ kind }) => BackgroundHover[kind]};
    color: ${({ kind }) => Color[kind]};
  }

  &:active {
    opacity: ${({ kind }) =>
      ["filledBlue", "filledCoral", "filledGreen"].includes(kind)
        ? "0.9"
        : "1"};
    background: ${({ kind }) => BackgroundPressed[kind]};
    color: ${({ kind }) => Color[kind]};
  }

  &[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
    background: ${({ kind }) => Background[kind]};
  }

  ${CoreButtonInner} {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  ${CoreButtonIcon} {
    align-items: center;
    display: flex;
    flex: 0;
    line-height: 1;
    margin-inline-end: 7px;
    white-space: nowrap;
  }

  ${mediaQuery(
    "noTouch",
    css`
      cursor: pointer;
    `,
  )}
`;

export default Button;
