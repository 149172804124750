import * as React from "react";
import { BaseIconAttributes } from "./types";

export const Cross: React.FC<BaseIconAttributes> = ({
  width = "1em",
  height = "1em",
  mainColor = "currentColor",
  ...restProps
}) => (
  <svg
    {...restProps}
    fill="none"
    height={height}
    viewBox="0 0 10 10"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M.9999 1l3.9999 4.001-4 3.9989M9 8.9995l-4-4.001L9 .9997"
      stroke={mainColor}
    />
  </svg>
);
