import * as React from "react";
import { BaseIconAttributes } from "./types";

export const PasswordEyeOpen: React.FC<BaseIconAttributes> = ({
  width = "1em",
  height = "1em",
  mainColor = "currentColor",
  ...restProps
}) => (
  <svg
    {...restProps}
    fill="none"
    height={height}
    viewBox="0 0 22 12"
    width={width}
  >
    <path
      clipRule="evenodd"
      d="M0 5.99796L0.151692 5.75716C2.32405 2.30874 6.37437 0 11 0C15.6256 0 19.6759 2.30874 21.8483 5.75716L22 5.99804L21.8484 6.23902C19.6775 9.69038 15.6264 12 11 12C6.45015 12 2.45708 9.76632 0.260117 6.41163L0.255419 6.40446L0 5.99796ZM1.11665 6C3.14934 9.0396 6.8102 11.0769 11 11.0769C15.1899 11.0769 18.8507 9.03947 20.8822 5.99841C18.8493 2.95974 15.1891 0.923077 11 0.923077C6.8101 0.923077 3.14932 2.96046 1.11665 6Z"
      fill={mainColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M11 10C13.2091 10 15 8.20914 15 6C15 3.79086 13.2091 2 11 2C8.79086 2 7 3.79086 7 6C7 8.20914 8.79086 10 11 10Z"
      fill={mainColor}
      fillRule="evenodd"
    />
  </svg>
);
