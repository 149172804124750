/**
 * Public locales are set by VITE_PUBLIC_LOCALES environment variable.
 * Only these locales can be available on Sign Up page and automatically applied by `navigator.language`.
 *
 * @see: https://storkclub.atlassian.net/browse/STRK-1486
 */
export default function getPublicLocales<
  L extends Record<string, unknown>,
>(locales: L): Partial<L> {
  const dotEnvLocales = getPublicLocaleCodes();
  if (dotEnvLocales === true) {
    return locales;
  }

  const result: Partial<L> = {};
  for (const locale of dotEnvLocales) {
    if (locale in locales) {
      const typedLocale = locale as keyof L;
      result[typedLocale] = locales[typedLocale];
    }
  }

  return result;
}

export type GetPublicLocalesType = typeof getPublicLocales;

export function getPublicLocaleCodes(): string[] | true {
  if (import.meta.env.VITE_PUBLIC_LOCALES === "*") {
    return true;
  }
  const dotEnvLocales = import.meta.env.VITE_PUBLIC_LOCALES?.split(
    ",",
  );

  if (!dotEnvLocales) {
    throw new Error(
      "VITE_PUBLIC_LOCALES: At least one explicitly enabled locale is required",
    );
  }
  return dotEnvLocales;
}

export type GetPublicLocaleCodesType = typeof getPublicLocaleCodes;
