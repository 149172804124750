import * as t from "io-ts";
import { withFallback } from "io-ts-types";

export const IncludedPostCategoryC = t.type({
  attributes: t.type({
    hex: t.string,
    name: withFallback(t.string, ""),
    slug: t.string,
  }),
  id: t.string,
  type: t.literal("post_category"),
});

export type IncludedPostCategory = t.TypeOf<
  typeof IncludedPostCategoryC
>;
