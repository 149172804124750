import styled, { css } from "styled-components";
import mediaQuery from "../../../utils/mediaQuery";

export const SmallItems = styled.div`
  margin: 20px -20px 0;
  overflow: auto hidden;
  width: 100vw;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      box-sizing: border-box;
      margin: 20px 0 0;
      margin-inline-start: -40px;
      padding-inline-start: 40px;
      width: calc(100vw - var(--sidebar-width));
    `,
  )}

  ${mediaQuery(
    "desktopMedium",
    css`
      width: calc(100vw - var(--sidebar-width) - 40px);
    `,
  )}
`;

export const SmallItemsRibbon = styled.div<{ length: number }>`
  box-sizing: border-box;
  display: flex;
  gap: 20px;
  padding: 0 20px;
  width: ${({ length }) => length * 210 + 20 * (length - 1) + 40}px;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      display: flex;
      gap: 20px;
      margin: initial;
      padding: initial;
    `,
  )}

  ${mediaQuery(
    "greaterThanTablet",
    css`
      display: grid;
      grid-gap: 20px;
      grid-template-columns: 210px 210px 210px;
      margin: initial;
      padding: initial;
      width: 100%;
    `,
  )}

  ${mediaQuery(
    "desktopMedium",
    css`
      grid-gap: 19px;
      grid-template-columns: 254px 254px 254px;
    `,
  )}
`;
