import { FC, useState } from "react";
import useAuthSession from "../../../authentication/hooks/useAuthSession";
import Expandable from "../../../lib/Expandable";
import Icon from "../../../lib/SharedIcon";
import { Reimbursement } from "../../models/Reimbursement";
import { ReimbursementEvent } from "../../models/ReimbursementEvent";
import { Body } from "./Body";
import { HeaderTitle } from "./HeaderTitle";
import { StatusIcon } from "./StatusIcon";
import {
  NotificationContainer,
  Header,
  HeaderIcon,
  ExpandIcon,
  BodyInner,
} from "./style";

export type Props = {
  onOpen: (event: ReimbursementEvent) => void;
  isExpandedOnMount: boolean;
  reimbursement: Reimbursement;
  isOpened: boolean;
  event: ReimbursementEvent;
};

export const Notification: FC<Props> = (props: Props) => {
  const {
    isExpandedOnMount = false,
    onOpen,
    isOpened,
    reimbursement,
    event,
  } = props;
  const { state, isRead } = event;

  const [isExpanded, setExpanded] = useState(isExpandedOnMount);

  const onNotificationClick = () => {
    if (!isExpanded) {
      onOpen(event);
    }
    setExpanded(!isExpanded);
  };

  const { data: authSession } = useAuthSession();
  if (!authSession || !authSession.user) {
    throw new Error(
      "Cannot use currency without authSession defined",
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const currency: string =
    authSession.user.company.attributes.country_currency;

  return (
    <NotificationContainer
      $isSeen={isRead || isOpened}
      isExpanded={isExpanded}
    >
      <Header
        isExpanded={isExpanded}
        onClick={onNotificationClick}
        state={state}
      >
        <HeaderIcon>
          <StatusIcon state={state} />
        </HeaderIcon>
        <HeaderTitle
          currency={currency}
          event={event}
          reimbursement={reimbursement}
        />
        <ExpandIcon
          isFlip={isExpanded}
          isWhiteBg={isExpanded}
          shouldHover={!isExpanded}
        >
          <Icon type="arrowDown" width="14px" />
        </ExpandIcon>
      </Header>
      <Expandable isExpanded={isExpanded}>
        <BodyInner>
          <Body
            currency={currency}
            event={event}
            reimbursement={reimbursement}
            state={state}
          />
        </BodyInner>
      </Expandable>
    </NotificationContainer>
  );
};
