import { t, Trans } from "@lingui/macro";
import { FC, useRef, useCallback, useState } from "react";
import { useMount } from "react-use";
import styled, { css } from "styled-components";
import { colors, fonts } from "../../../constants";
import hexColorOpacity from "../../../utils/hexColorOpacity";
import mediaQuery from "../../../utils/mediaQuery";
import { Authorization } from "../../models/Authorization";
import { AuthorizationEvent } from "../../models/AuthorizationEvent";
import { UserPlan } from "../../models/UserPlan";
import { Notification } from "../Notification";
import { ServiceIcon, RxIcon } from "./Icon";
import scrollToEl from "./scrollToEl";

export type Props = {
  authorization: Authorization;
  expandedId: string | null;
  onRead: (id: string) => void;
  userPlan: UserPlan;
};

const Container = styled.div`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Uid = styled.div<{ isRed: boolean }>`
  background: ${({ isRed }) =>
    isRed
      ? hexColorOpacity(colors.coral, 0.1)
      : hexColorOpacity(colors.blue, 0.1)};
  border-radius: 5px;
  color: ${({ isRed }) => (isRed ? colors.coral : colors.blue)};
  font-size: 12px;
  height: 26px;
  left: 46px;
  line-height: 25px;
  opacity: 0.9;
  padding: 0 6px;
  position: absolute;
  white-space: nowrap;

  ${mediaQuery(
    "desktopMedium",
    css`
      font-size: 15px;
      line-height: 26px;
      position: static;
    `,
  )}
`;

const Border = styled.div`
  background: ${colors.white};
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px 10px 10px;

  ${mediaQuery(
    "desktopMedium",
    css`
      padding: 42px 60px 40px;
    `,
  )}
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0;
  position: relative;

  ${mediaQuery(
    "desktopMedium",
    css`
      padding-inline-end: 20px;
    `,
  )}
`;

const TitleContainer = styled.div`
  font-family: ${fonts.alt};
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
  margin-bottom: 20px;
  margin-inline-end: 0;

  ${mediaQuery(
    "desktopMedium",
    css`
      display: flex;
      margin-bottom: 28px;
      margin-inline-end: 20px;
    `,
  )}
`;

const TitleIcon = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  width: 46px;

  ${mediaQuery(
    "desktopMedium",
    css`
      width: 40px;
    `,
  )}
`;

const TitleText = styled.div`
  padding: 5px 10px 0;

  ${mediaQuery(
    "desktopMedium",
    css`
      padding: 0;
    `,
  )}
`;

const AuthorizationGroup: FC<Props> = ({
  authorization,
  expandedId = null,
  userPlan,
  onRead,
}: Props) => {
  const containerRef = useRef(null);
  const { title, uid, events } = authorization;
  const [opened, setOpened] = useState<{
    [key: string]: boolean;
  }>({});

  const isRx = authorization.practiceTemplate === "pharmacy";

  const onOpen = useCallback(
    (event: AuthorizationEvent) => {
      const { isRead, id } = event;
      if (!isRead && !opened[id]) {
        onRead(id);
      }
      setOpened((currentOpened) => ({
        ...currentOpened,
        [id]: true,
      }));
    },
    [onRead, opened],
  );

  useMount(() => {
    const isExpanded =
      !!expandedId && events.some((item) => item.id === expandedId);
    if (isExpanded) {
      setOpened((currentOpened) => ({
        ...currentOpened,
        [expandedId]: true,
      }));
      const event = authorization.events.find(
        (item) => item.id === expandedId,
      );
      if (event && !event.isRead) {
        onRead(expandedId);
      }

      if (containerRef?.current) scrollToEl(containerRef?.current);
    }
  });

  return (
    <Container>
      <div ref={containerRef}>
        <Border>
          <Top>
            <TitleContainer>
              <TitleIcon>
                {!isRx && <ServiceIcon />}
                {isRx && <RxIcon />}
              </TitleIcon>
              <TitleText>
                {isRx ? t`Rx authorization` : title}
              </TitleText>
            </TitleContainer>

            <Uid isRed={!isRx}>
              <Trans>Auth ID</Trans>
              {"\u00A0"}
              {uid.match(/-(\d+$)/)?.[1] || uid}
            </Uid>
          </Top>
          {events.map((item) => (
            <Notification
              key={item.id}
              authorization={authorization}
              event={item}
              isExpandedOnMount={item.id === expandedId}
              isOpened={opened[item.id]}
              onOpen={onOpen}
              userPlan={userPlan}
            />
          ))}
        </Border>
      </div>
    </Container>
  );
};

export default AuthorizationGroup;
