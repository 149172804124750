import { t } from "@lingui/macro";
import { FC } from "react";
import PageTitle from "../../../lib/PageTitle";
import PageLayout, { Body, Footer, Sidebar } from "../../PageLayout";
import Categories from "../Categories";
import ArticlesHeader from "../Header";
import { ArticlesContainer } from "../style";
import MustReadBlock from "./MustReadBlock";
import PopularBlock from "./PopularBlock";
import RecentBlock from "./RecentBlock";

const ArticlesListPage: FC = () => (
  <PageLayout
    footer={<Footer />}
    header={
      <>
        <PageTitle>{t`Articles`}</PageTitle>
        <ArticlesHeader />
      </>
    }
    sidebar={<Sidebar />}
  >
    <Body>
      <Categories />
      <ArticlesContainer>
        <MustReadBlock />
        <RecentBlock />
        <PopularBlock />
      </ArticlesContainer>
    </Body>
  </PageLayout>
);

export default ArticlesListPage;
