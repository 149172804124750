import * as t from "io-ts";
import { createResourceIdentifierC } from "../../network/jsonApi/builders";
import { AuthorizationC } from "./Authorization";
import { AuthorizationEventC } from "./AuthorizationEvent";
import { BillC } from "./Bill";
import { ServiceC } from "./Service";
import { UserPlanC } from "./UserPlan";

const AuthorizationWithRelationshipsC = t.intersection([
  AuthorizationC,
  t.type({
    relationships: t.type({
      bill: t.type({
        data: t.union([t.null, createResourceIdentifierC("bill")]),
      }),
      events: t.type({
        data: t.array(
          createResourceIdentifierC("notification_event"),
        ),
      }),
      services: t.type({
        data: t.array(createResourceIdentifierC("service")),
      }),
      user_plan: t.type({
        data: createResourceIdentifierC("user_plan"),
      }),
    }),
  }),
]);

export const responseC = t.type({
  data: t.array(AuthorizationWithRelationshipsC),
  included: t.array(
    t.union([UserPlanC, BillC, AuthorizationEventC, ServiceC]),
  ),
});
