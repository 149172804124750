import { FC, HTMLAttributes, ReactNode } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { fonts } from "../../../../constants";
import { Logo } from "../../../../lib/Logo";
import mediaQuery from "../../../../utils/mediaQuery";

export const Content = styled.main`
  grid-area: content;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      border: 1px solid #e0ddd9;
      border-radius: 10px;
      padding: 60px;
    `,
  )}
`;

const LogoLink = styled(Link)`
  grid-area: logo;
`;
const Title = styled.h1`
  color: #e0ddd9;
  font-family: ${fonts.alt};
  font-size: 20px;
  font-weight: bold;
  grid-area: title;
  justify-self: center;
  line-height: 24px;
  margin: 0;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      font-size: 24px;
      line-height: 29px;
      position: relative;
      top: 2px;
    `,
  )}
`;
export const Header = styled.header`
  align-items: center;
  display: grid;
  grid-area: header;
  grid-template-areas: "logo title";
  grid-template-columns: min-content 1fr;
  ${LogoLink} {
    position: absolute;
  }
`;

const Footer = styled.footer`
  grid-area: footer;
`;

const Container = styled.div`
  display: grid;
  grid-template-areas:
    "header"
    "content"
    "footer";
  grid-template-columns: 1fr;
  padding: 12px 0 0;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      padding: 30px 26px;
    `,
  )}

  ${Content} {
    justify-self: center;
    max-width: 728px;
    width: 100%;
    box-sizing: border-box;
  }

  ${Header} {
    margin-bottom: 22px;
    ${mediaQuery(
      "greaterThanPhone",
      css`
        margin-bottom: 68px;
      `,
    )}
  }
  ${Footer} {
    justify-self: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  ${LogoLink} {
    display: none;
    ${mediaQuery(
      "greaterThanPhone",
      css`
        display: inherit;
      `,
    )}
  }
`;
export interface LayoutProps extends HTMLAttributes<HTMLElement> {
  title: string;
  footer?: ReactNode;
}
const Layout: FC<LayoutProps> = ({
  title,
  footer,
  children,
  ...restProps
}) => {
  return (
    <Container {...restProps}>
      <Header>
        <LogoLink to="/">
          <Logo />
        </LogoLink>
        <Title>{title}</Title>
      </Header>
      <Content>{children}</Content>
      {footer && <Footer>{footer}</Footer>}
    </Container>
  );
};

export default Layout;
