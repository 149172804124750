import { Trans } from "@lingui/macro";
import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors } from "../../../constants";
import mediaQuery from "../../../utils/mediaQuery";

export const Container = styled.div`
  background: #f5f7f7;
  border-radius: 8px;
  grid-column: 1/-1;
  margin-bottom: 40px;
  padding-block-end: 8px;
  padding-block-start: 10px;
  padding-inline-end: 8px;
  padding-inline-start: 30px;
  position: relative;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      width: 570px;
    `,
  )}
`;
const ItemIcon = styled.div`
  left: 8px;
  position: absolute;
  top: 10px;
`;

const Text = styled.div`
  color: #4c4c4c;
  font-size: 13px;
  line-height: 16px;
`;

const InfoIcon = () => (
  <svg
    fill="none"
    height="14"
    viewBox="0 0 14 14"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 0C3.136 0 0 3.136 0 7C0 10.864 3.136 14 7 14C10.864 14 14 10.864 14 7C14 3.136 10.864 0 7 0ZM7.7 10.5H6.3V9.1H7.7V10.5ZM7.7 7.7H6.3V3.5H7.7V7.7Z"
      fill={colors.red}
    />
  </svg>
);

const Cobra = () => (
  <Container>
    <ItemIcon>
      <InfoIcon />
    </ItemIcon>
    <Text>
      <Trans>
        If you’re no longer working for the company with Stork Club
        coverage but you have COBRA for your health plan, reach out to
        us at{" "}
        <Link target="_blank" to="mailto:care@joinstorkclub.com">
          care@joinstorkclub.com
        </Link>{" "}
        to verify your eligibility and restore access to your account.
      </Trans>
    </Text>
  </Container>
);

export default Cobra;
