/* eslint-disable no-console */
import * as Sentry from "@sentry/react";
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { ReactQueryDevtools } from "react-query/devtools";
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import App from "./App";
import EmailChangingConfirmation from "./components/EmailChangingConfirmationPage";
import Error500Screen from "./components/Error500Screen";
import GlobalSpinner from "./components/GlobalSpinner";
import { TranslationsProvider } from "./locales/appLocale";
import { AxiosProvider } from "./network";
import QueryClientBound from "./network/QueryClientBound";
import "./utils/mobileRedirect";
import patchApi from "./patchApi";
import GlobalStyle from "./utils/globalStyle";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN || "foo",
  enabled:
    !!import.meta.env.VITE_SENTRY_ENV &&
    !!import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENV || "unknown",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      createRoutesFromChildren,
      matchRoutes,
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
    }),
    Sentry.replayIntegration(),
  ],
  replaysOnErrorSampleRate: 0,
  replaysSessionSampleRate: 0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/product\.aws\.joinstorkclub\.com\/api/,
    /^https:\/\/my\.joinstorkclub\.com\/api/,
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,
});

const rootEl = document.getElementById("root");
if (!rootEl) {
  throw new Error("Root element not found");
}
const root = createRoot(rootEl);

function EmailConfirmationWrapper() {
  const location = useLocation();

  return location.pathname === "/confirmation" ? (
    <EmailChangingConfirmation />
  ) : (
    <App />
  );
}

patchApi()
  .then(() => {
    root.render(
      <React.StrictMode>
        <GlobalStyle />
        <Suspense fallback={<GlobalSpinner />}>
          <BrowserRouter
            basename={import.meta.env.BASE_URL || undefined}
          >
            <TranslationsProvider>
              <Sentry.ErrorBoundary fallback={<Error500Screen />}>
                <QueryClientBound>
                  <AxiosProvider>
                    <EmailConfirmationWrapper />
                    <ReactQueryDevtools initialIsOpen={false} />
                  </AxiosProvider>
                </QueryClientBound>
              </Sentry.ErrorBoundary>
            </TranslationsProvider>
          </BrowserRouter>
        </Suspense>
      </React.StrictMode>,
    );
  })
  .catch((err) => console.error(err));
