import * as React from "react";
import { BaseIconAttributes } from "./types";

export const Plaid: React.FC<BaseIconAttributes> = ({
  width = "1em",
  height = "1em",
  mainColor = "currentColor",
  ...restProps
}) => (
  <svg
    {...restProps}
    fill="none"
    height={height}
    viewBox="0 0 55 20"
    width={width}
  >
    <path
      clipRule="evenodd"
      d="M28.8921 6.77852C28.4391 6.4081 27.6659 6.2231 26.5722 6.2231H24.1053V13.6348H25.9499V11.3119H26.7762C27.7791 11.3119 28.5145 11.0973 28.9825 10.6677C29.5096 10.1864 29.7749 9.5456 29.7749 8.7456C29.7749 7.91602 29.4805 7.26018 28.8921 6.77852ZM26.7303 9.63477H25.9499V7.90018H26.6511C27.5039 7.90018 27.9303 8.19102 27.9303 8.77268C27.9303 9.34685 27.5301 9.63435 26.7303 9.63435V9.63477ZM32.9313 6.22268H31.0079V13.6344H35.1608V11.9569H32.9313V6.22268ZM38.9292 6.22268L35.9078 13.6344H37.9786L38.3746 12.5569H41.0001L41.3622 13.6344H43.4562L40.4562 6.22268H38.9292ZM38.9065 11.0564L39.6989 8.5231L40.4789 11.0564H38.9061H38.9065Z"
      fill={mainColor}
      fillRule="evenodd"
    />
    <mask
      height="20"
      id="mask0_3759_1577"
      maskUnits="userSpaceOnUse"
      width="55"
      x="0"
      y="0"
    >
      <path d="M0.5 19.7804H54.5V0H0.5V19.7804Z" fill="white" />
    </mask>
    <g mask="url(#mask0_3759_1577)">
      <path
        clipRule="evenodd"
        d="M44.417 13.6342H46.3409V6.2225H44.417V13.6342ZM53.81 7.61167C53.567 7.2691 53.255 6.97808 52.8929 6.75625C52.3121 6.40042 51.5197 6.22292 50.5164 6.22292H47.9819V13.6342H50.9467C52.0181 13.6342 52.8779 13.2938 53.5267 12.6121C54.1756 11.9308 54.4996 11.0267 54.4996 9.90042C54.4996 9.00458 54.2694 8.24167 53.8096 7.61167H53.81ZM50.7316 11.9563H49.9057V7.90042H50.7431C51.3316 7.90042 51.7837 8.07958 52.1009 8.43667C52.418 8.79375 52.5766 9.30042 52.5766 9.95625C52.5766 11.2896 51.9616 11.9563 50.7316 11.9563ZM8.48729 0L2.25286 1.5875L0.534714 7.68292L2.68357 9.82792L0.5 11.9375L2.117 18.0596L8.32443 19.7458L10.5084 17.6358L12.6573 19.7804L18.8917 18.1929L20.6094 12.0971L18.461 9.95292L20.6446 7.84333L19.0276 1.72083L12.8193 0.0345833L10.6361 2.14417L8.48729 0ZM4.66143 2.76L7.94557 1.92333L9.38171 3.35667L7.28729 5.38L4.66143 2.76ZM11.8709 3.37583L13.3301 1.96625L16.6001 2.85458L13.9314 5.4325L11.8709 3.37583ZM2.50229 7.1825L3.407 3.97208L6.032 6.59208L3.938 8.61542L2.50229 7.18208V7.1825ZM15.1653 6.66458L17.834 4.08583L18.6851 7.31083L17.2263 8.72083L15.1653 6.66458ZM8.52157 6.61167L10.616 4.58833L12.6761 6.645L10.5821 8.66833L8.52157 6.61167ZM5.17271 9.84708L7.26671 7.82375L9.32814 9.88042L7.23329 11.9038L5.17271 9.84708ZM11.8169 9.9L13.9109 7.87667L15.9714 9.93333L13.877 11.9567L11.8169 9.9ZM2.45857 12.4696L3.91829 11.0592L5.97843 13.1163L3.31057 15.6938L2.45857 12.4696ZM8.46757 13.1354L10.562 11.1121L12.6226 13.1688L10.5286 15.1921L8.46757 13.1354ZM15.1113 13.1888L17.2057 11.1654L18.6419 12.5983L17.7376 15.8088L15.1113 13.1888ZM4.54443 16.9263L7.21271 14.3475L9.27414 16.4042L7.81443 17.8146L4.54443 16.9258V16.9263ZM11.7629 16.4238L13.8569 14.4004L16.4823 17.0208L13.1986 17.8571L11.7629 16.4238Z"
        fill={mainColor}
        fillRule="evenodd"
      />
    </g>
  </svg>
);
