import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { t, Trans } from "@lingui/macro";
import React, {
  FC,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import ReCAPTCHABase from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import * as yup from "yup";
import ReCAPTCHA from "../../../components/ReCAPTCHA";
import { colors } from "../../../constants";
import Alert from "../../../lib/Alert";
import FormField from "../../../lib/forms/FormField";
import Input from "../../../lib/forms/primitives/Input";
import Icon from "../../../lib/Icon";
import jsonApiErrorParser from "../../../utils/errors/jsonApiParser";
import squashErrors from "../../../utils/errors/squashErrors";
import { useHandleSubmitImpl } from "../../../utils/forms";
import mediaQuery from "../../../utils/mediaQuery";
import renderError from "../../../utils/renderError";
import Info from "../Info";
import Layout from "../Layout";
import imgSrc from "../Layout/signin-bg2-a73bb9ef0fc46cb003538bafd272607d.jpeg";
import { Header, StyledButton } from "../styles";
import { useForgotPassword } from "./hooks";

const StyledForm = styled.form`
  ${StyledButton} {
    margin-top: 24px;
    ${mediaQuery(
      "desktopMedium",
      css`
        width: auto;
      `,
    )}
  }
`;
const SignInLink = styled(Link)`
  align-items: center;
  display: flex;
  margin-bottom: 8px;
  text-decoration: none;
`;

const SignInLinkText = styled.div`
  color: ${colors.blue};
  display: block;
  font-size: 13px;
  line-height: 16px;
  margin-inline-start: 10px;
`;

const StyledLink = styled(Link)`
  color: ${colors.blueGreen};
  font-size: 20px;
  line-height: 24px;
`;

const Description = styled.div`
  max-width: 480px;
  font-size: 20px;
  line-height: 24px;
  color: #4c4c4c;
`;

const StyledLayout = styled(Layout)`
  ${Header} {
    display: block;
    margin-bottom: 30px;
  }
  ${StyledForm} {
    margin-top: 30px;
    ${mediaQuery(
      "desktopMedium",
      css`
        margin-top: 40px;
        max-width: 355px;
      `,
    )}
  }
  ${Description} {
    margin-bottom: 20px;
  }
`;

const getResolver = () => {
  const FormSchema = yup.object().shape({
    email: yup
      .string()
      .email(t`Please enter a valid email address`)
      .required(t`Email required`),
  });

  return yupResolver(FormSchema);
};

export interface FormProps {
  email: string;
}

const ForgotPasswordScreen: FC = () => {
  const location = useLocation();
  const {
    isLoading,
    error: externalError,
    mutate: askRestorePassword,
  } = useForgotPassword();
  const [isSuccess, setIsSuccess] = useState(false);

  const { email: locationEmail = "" } =
    (location.state as
      | {
          email?: string;
        }
      | undefined) || {};

  const resolver = useMemo(() => getResolver(), []);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormProps>({
    defaultValues: {
      email: locationEmail,
    },
    resolver,
  });
  const recaptchaRef = useRef<ReCAPTCHABase>(null);
  const onSubmit = useCallback(
    ({
      email,
      recaptchaToken,
    }: {
      email: string;
      recaptchaToken: string;
    }) => {
      askRestorePassword(
        { email, recaptchaToken },
        {
          onSuccess: () => {
            setIsSuccess(true);
          },
        },
      );
    },
    [askRestorePassword],
  );
  const { handler: handleSubmitImpl, error: internalError } =
    useHandleSubmitImpl(handleSubmit, onSubmit, recaptchaRef);

  const parsedErrors = useMemo(() => {
    const error = externalError || internalError;

    if (!error) {
      return null;
    }

    const parser = jsonApiErrorParser({});

    return squashErrors(parser(error));
  }, [externalError, internalError]);

  return (
    <StyledLayout imgSrc={imgSrc}>
      {!!parsedErrors?.error && (
        <Alert status="error">
          {renderError(parsedErrors.error)}
        </Alert>
      )}
      {isSuccess ? (
        <>
          <Header>
            <Trans>Check your email</Trans>
          </Header>
          <Description>
            <Trans>
              If your email address exists in our database, you will
              receive a password recovery link at your email address
              in a few minutes.
            </Trans>
          </Description>
          <div>
            <StyledLink
              state={
                {
                  // need strategies
                  // email: locationEmail,
                }
              }
              to="/signin"
            >
              <Trans>Sign in</Trans>
            </StyledLink>
          </div>
        </>
      ) : (
        <>
          <SignInLink to="/signin">
            <Icon
              fill={colors.blue}
              height={16}
              type="back"
              viewBox="0 0 24 24"
              width={16}
            />
            <SignInLinkText>
              <Trans>Sign in</Trans>
            </SignInLinkText>
          </SignInLink>
          <Header>
            <Trans>Forgot password</Trans>
          </Header>

          <StyledForm onSubmit={handleSubmitImpl}>
            <FormField
              description={t`We will send you a recovery email`}
              error={errors.email?.message}
              hideLabel
              label={t`Email`}
            >
              <Input
                {...register("email")}
                $activeColor={colors.blueGreen}
                autoComplete="username"
                placeholder={t`Email`}
                type="email"
              />
            </FormField>
            <StyledButton
              disabled={isLoading}
              kind="filledBlueGreen"
              type="submit"
            >
              <Trans>Send reset link</Trans>
            </StyledButton>
          </StyledForm>
          <Info />
        </>
      )}
      <ReCAPTCHA ref={recaptchaRef} />
    </StyledLayout>
  );
};

export default ForgotPasswordScreen;
