import { FC, HTMLAttributes, ReactNode } from "react";
import styled, { css, keyframes } from "styled-components";
import mediaQuery from "../../utils/mediaQuery";

const SkeletonLoading = keyframes`
  0% {
    transform: translateX(-37.5%);
  }

  100% {
    transform: translateX(37.5%);
  }
`;
const Row = styled.div<{ $width?: string; $active: boolean }>`
  border-radius: 4px;
  height: 16px;
  width: ${({ $width }) => $width || "100%"};
  ${({ $active }) =>
    $active
      ? css`
          background-color: transparent;
          overflow: hidden;
          position: relative;

          &::after {
            animation: ${SkeletonLoading} 1.4s ease infinite;
            background: linear-gradient(
              90deg,
              rgba(190, 190, 190, 0.2) 25%,
              rgba(129, 129, 129, 0.24) 37%,
              rgba(190, 190, 190, 0.2) 63%
            );
            content: " ";
            inset: 0 -150%;
            position: absolute;
          }
        `
      : css`
          background-color: rgba(190, 190, 190, 0.2);
        `};

  ${mediaQuery(
    "phone",
    css`
      &::after {
        animation: none;
      }
    `,
  )}
`;

const TitleRow = styled(Row)``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${TitleRow} {
    margin-bottom: 8px;
  }
`;

export interface SkeletonProps {
  /**
   * Show animation effect
   */
  active?: boolean;
  children?: ReactNode | ReactNode[];
  /**
   * Display the skeleton when true
   */
  loading: boolean;
}
const Skeleton: FC<HTMLAttributes<HTMLElement> & SkeletonProps> = ({
  active = false,
  children = null,
  loading = true,
  ...restProps
}) => {
  if (loading) {
    return (
      <Container {...restProps}>
        <TitleRow $active={active} $width="38%" />
        <Row $active={active} />
        <Row $active={active} />
        <Row $active={active} $width="61%" />
      </Container>
    );
  }
  return <>{children}</>;
};

export default Skeleton;
