import * as t from "io-ts";
import { createResourceIdentifierC } from "../../../network/jsonApi/builders";
import { CallC } from "./Call";
import { CallSubjectC } from "./CallSubject";
import { CareNavigatorC } from "./CareNavigator";

export const responseC = t.type({
  data: t.type({
    attributes: t.type({
      call_category_type_name: t.string,
    }),
    id: t.string,
    relationships: t.type({
      call_subjects: t.type({
        data: t.array(createResourceIdentifierC("call_subject")),
      }),
      future_calls: t.type({
        data: t.array(createResourceIdentifierC("call")),
      }),
    }),
    type: t.literal("call_category"),
  }),
  included: t.array(
    t.union([
      CareNavigatorC,
      t.intersection([
        CallSubjectC,
        t.type({
          relationships: t.type({
            call_experts: t.type({
              data: t.array(createResourceIdentifierC("call_expert")),
            }),
          }),
        }),
      ]),
      t.intersection([
        CallC,
        t.type({
          relationships: t.exact(
            t.type({
              call_expert: t.type({
                data: createResourceIdentifierC("call_expert"),
              }),
            }),
          ),
        }),
      ]),
    ]),
  ),
});
