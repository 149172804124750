import { FC } from "react";
import styled from "styled-components";
import { colors } from "../../constants";
import Markdown from "../../lib/Markdown";
import Title from "../../lib/Title";
import { goldenListMixin } from "../../utils/style";
import ScrollHandler from "../ScrollHandler";

type TabProps = {
  id: string;
  name: string;
  body: string;
};

const Header = styled(Title)`
  margin-bottom: 30px;
`;

const StyledMarkdown = styled(Markdown)`
  color: ${colors.brownBlack};
  ${goldenListMixin}
`;

const Tab: FC<TabProps> = ({ id, name, body }: TabProps) => (
  <ScrollHandler key={id}>
    <Header level="h1" styleLevel="h2">
      {name}
    </Header>
    <StyledMarkdown text={body.toString()} />
  </ScrollHandler>
);

export default Tab;
