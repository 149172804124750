import * as t from "io-ts";
import JSONApiRelationshipsRelatedLinkC from "./JSONApiRelationshipsRelatedLink";
import JSONApiRelationshipsSelfLinkC from "./JSONApiRelationshipsSelfLink";

const JSONApiRelationshipsLinksC = t.type(
  {
    links: t.union([
      JSONApiRelationshipsRelatedLinkC,
      JSONApiRelationshipsSelfLinkC,
      t.intersection([
        JSONApiRelationshipsRelatedLinkC,
        JSONApiRelationshipsSelfLinkC,
      ]),
    ]),
  },
  "JSONApiRelationshipsLinks",
);

export type JSONApiRelationshipsLinks = t.TypeOf<
  typeof JSONApiRelationshipsLinksC
>;

export default JSONApiRelationshipsLinksC;
