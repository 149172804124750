import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import { FC } from "react";
import { colors } from "../../../../constants";
import { formatCurrency } from "../../../../utils/currency";
import useCancelReimbursement from "../../../hooks/useCancelReimbursement";
import { Reimbursement } from "../../../models/Reimbursement";
import { State } from "../../../models/State";
import {
  ServicesContainer,
  ServicesStatus,
  ServicesTable,
  RequestedStateContainer,
  RequestedTotal,
  CancelButton,
  Bold,
  TableWrapper,
  ServiceRejectReason,
} from "./style";

type Props = {
  reimbursement: Reimbursement;
  state: State;
  isCancellable: boolean;
  currency: string;
};

export const Services: FC<Props> = (props: Props) => {
  const { reimbursement, state, isCancellable, currency } = props;
  const { services, totalRequestedAmount, id } = reimbursement;

  const approvedServices = services.filter(
    (service) => service.state === "approved",
  );
  const rejectedServices = services.filter(
    (service) => service.state === "rejected",
  );

  const { mutate: cancelReimbursement } = useCancelReimbursement();

  const cancelReimbursementHandler = () => {
    cancelReimbursement(id, {
      onSuccess: () => {
        window.scrollTo(0, 0);
      },
    });
  };

  return (
    <ServicesContainer>
      {/* All requested services */}
      {state === "requested" && (
        <>
          <ServicesStatus color={colors.brownBlack}>
            <Trans>Requested</Trans>
          </ServicesStatus>
          <TableWrapper>
            <ServicesTable>
              <tbody>
                {services.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <span dir="auto">
                        {formatCurrency(
                          item.requestedAmount,
                          currency,
                          i18n.locale,
                        )}
                      </span>
                    </td>
                    <td>{item.name}</td>
                  </tr>
                ))}
              </tbody>
            </ServicesTable>
          </TableWrapper>

          {isCancellable && (
            <RequestedStateContainer>
              <RequestedTotal>
                <Trans>Total:</Trans>{" "}
                <span dir="auto">
                  {formatCurrency(
                    totalRequestedAmount,
                    currency,
                    i18n.locale,
                  )}
                </span>
              </RequestedTotal>
              <CancelButton onClick={cancelReimbursementHandler}>
                <Trans>Cancel request</Trans>
              </CancelButton>
            </RequestedStateContainer>
          )}
        </>
      )}

      {/* All approved services */}
      {state !== "requested" && approvedServices.length > 0 && (
        <>
          <ServicesStatus color={colors.green}>
            <Trans>Approved</Trans>
          </ServicesStatus>
          <TableWrapper>
            <ServicesTable>
              <tbody>
                {approvedServices.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <Bold dir="auto">
                        {formatCurrency(
                          item.approvedAmount,
                          currency,
                          i18n.locale,
                        )}
                      </Bold>
                    </td>
                    <td>{item.name}</td>
                  </tr>
                ))}
              </tbody>
            </ServicesTable>
          </TableWrapper>
        </>
      )}

      {/* All rejected services */}
      {state !== "requested" && rejectedServices.length > 0 && (
        <>
          <ServicesStatus color={colors.coral}>
            <Trans>Rejected</Trans>
          </ServicesStatus>
          <TableWrapper>
            <ServicesTable rejected>
              <tbody>
                {rejectedServices.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <span dir="auto">
                        {formatCurrency(
                          item.requestedAmount,
                          currency,
                          i18n.locale,
                        )}
                      </span>
                    </td>
                    <td>
                      {item.name}
                      {item.reason && (
                        <ServiceRejectReason>
                          {item.reason}
                        </ServiceRejectReason>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </ServicesTable>
          </TableWrapper>
        </>
      )}
    </ServicesContainer>
  );
};
