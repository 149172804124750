import { useMutation } from "react-query";
import { useAxios } from "../../network";
import { ENDPOINTS } from "../../network/endpoints";

export interface Params {
  reason: string;
  duration: number;
}

const useExtendCall = (token: string) => {
  const axios = useAxios();
  return useMutation(async (data: Params) => {
    await axios.put(ENDPOINTS.extendCall.replace(":token", token), {
      minutes_to_extend: data.duration,
      reason: data.reason,
    });
  });
};

export default useExtendCall;
