import * as React from "react";
import { BaseIconAttributes } from "./types";

export const LinkBack: React.FC<BaseIconAttributes> = ({
  width = "1em",
  height = "1em",
  mainColor = "currentColor",
  ...restProps
}) => (
  <svg
    {...restProps}
    fill="none"
    height={height}
    viewBox="0 0 17 18"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 16.3334L1.6667 8.9981 9 1.6667M16.6659 8.9H1.6667"
      stroke={mainColor}
      strokeWidth="2"
    />
  </svg>
);
