/* eslint-disable no-nested-ternary */
import { Trans } from "@lingui/macro";
import {
  ChangeEvent,
  CSSProperties,
  FC,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { colors } from "../../../../constants";
import hexColorOpacity from "../../../../utils/hexColorOpacity";
import { useFormFieldContext } from "../../FormField/context";

const Input = styled.input`
  display: none;
`;
const UploadSvg = (props: {
  className?: string;
  style?: CSSProperties;
  children?: never;
}) => (
  <svg
    fill="none"
    height="0.875em"
    viewBox="0 0 13 14"
    width="0.8125em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.276 9.38757V11.7755H1.72428V9.38757H0.132324V11.7755C0.132324 12.6511 0.848703 13.3675 1.72428 13.3675H11.276C12.1516 13.3675 12.8679 12.6511 12.8679 11.7755V9.38757H11.276ZM2.52025 4.61172L3.64258 5.73404L5.70416 3.68042V10.1835H7.29611V3.68042L9.35769 5.73404L10.48 4.61172L6.50013 0.631836L2.52025 4.61172Z"
      fill="currentColor"
    />
  </svg>
);
const StyledUploadSvg = styled(UploadSvg)``;

const UploadText = styled.span`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  font-weight: 500;
  text-decoration: underline;

  ${StyledUploadSvg} {
    margin-inline-end: 7px;
  }
`;

const UploadedFileName = styled.span`
  color: ${colors.brownBlack};
  font-weight: 400;
`;
const UploadedText = styled.span`
  color: ${colors.brownBlack};
  font-weight: 500;
`;
const UploadedTextContainer = styled.span`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  gap: 8px;
`;

const FileButton = styled.button<{
  $invalid: boolean;
  $resetWidth: boolean;
}>`
  background: ${colors.white};
  border: 1px solid ${colors.brownLightGrey1};
  border-color: ${({ $invalid }) =>
    $invalid ? colors.redStable : colors.brownLightGrey1};
  border-radius: 10px;
  color: ${colors.brownBlack};
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  line-height: 1.5;
  padding: 28px;
  text-align: center;
  width: ${({ $resetWidth }) => ($resetWidth ? "auto" : "100%")};
  &:hover {
    border-color: ${colors.brownGrey};
  }
  &:active {
    background: ${colors.brownLightGrey2};
    border-color: ${colors.brownGrey};
  }

  &[disabled] {
    background: ${colors.brownLightGrey2};
    color: ${hexColorOpacity(colors.brownGrey, 0.6)};
    cursor: not-allowed;
  }
`;
const Container = styled.span<{ $disabled?: boolean }>`
  position: relative;
`;

export interface FileInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  children?: never;
  placeholder: string;
  resetWidth?: boolean;
}

const FileInput: FC<FileInputProps> = forwardRef(
  (props, ref: ForwardedRef<HTMLInputElement>) => {
    const {
      className,
      style,
      disabled,
      onChange,
      placeholder,
      resetWidth,
      "aria-invalid": ariaInvalid,
      ...rest
    } = props;

    const formFieldContextValue = useFormFieldContext();
    const invalid =
      (!!ariaInvalid && ariaInvalid !== "false") ||
      !!formFieldContextValue["aria-invalid"];
    const inputRef = useRef<HTMLInputElement>(null);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useImperativeHandle(ref, () => inputRef.current!);
    const [, setForceRender] = useState(0);
    const attachedFile = inputRef.current?.files?.[0];
    const fileName = attachedFile?.name;
    let shortenedFileName: string | undefined;
    if (fileName) {
      shortenedFileName =
        fileName.length > 23
          ? `${fileName.slice(0, 11).trimEnd()}...${fileName
              .slice(-14)
              .trimStart()}`
          : fileName;
    }

    const handleFileChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
          onChange(e);
        }
        setForceRender((prev) => prev + 1);
      },
      [onChange],
    );
    const handleClick = useCallback(() => {
      if (inputRef.current) {
        inputRef.current.click();
      }
    }, []);
    return (
      <Container
        $disabled={disabled}
        className={className}
        style={style}
      >
        <Input
          ref={inputRef}
          aria-invalid={invalid ? "true" : "false"}
          disabled={disabled}
          onChange={handleFileChange}
          type="file"
          {...rest}
        />
        <FileButton
          $invalid={invalid}
          $resetWidth={resetWidth || false}
          disabled={disabled}
          onClick={handleClick}
          type="button"
        >
          {shortenedFileName ? (
            <UploadedTextContainer>
              <UploadedFileName>{shortenedFileName}</UploadedFileName>
              <UploadedText>
                <Trans>Attached</Trans>
              </UploadedText>
            </UploadedTextContainer>
          ) : (
            <UploadText>
              <StyledUploadSvg />
              {placeholder}
            </UploadText>
          )}
        </FileButton>
      </Container>
    );
  },
);

export default FileInput;
