import React from "react";
import { colors } from "../../../constants";

export const ServiceIcon: React.FC = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 27 24"
    width="27"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M27 1L12 4v18h15V1z" fill={colors.coralStable} />
    <mask
      fill="#000"
      height="15"
      id="ServiceIconMask1"
      maskUnits="userSpaceOnUse"
      width="18"
      x="1"
      y="8.6411"
    >
      <path d="M1 8.6411h18v15H1z" fill="#fff" />
      <path
        clipRule="evenodd"
        d="M18 11.1669a21.7358 21.7358 0 00-16 0v10.8333h6v-5h4v5h6V11.1669z"
        fillRule="evenodd"
      />
    </mask>
    <path
      clipRule="evenodd"
      d="M18 11.1669a21.7358 21.7358 0 00-16 0v10.8333h6v-5h4v5h6V11.1669z"
      fill={colors.coralStable}
      fillRule="evenodd"
    />
    <path
      d="M2 11.1669H1v-.6797l.632-.2502.368.9299zm16 0l.368-.9299.632.2502v.6797h-1zM2 22.0002v1H1v-1h1zm6 0h1v1H8v-1zm0-5H7v-1h1v1zm4 0v-1h1v1h-1zm0 5v1h-1v-1h1zm6 0h1v1h-1v-1zM1.632 10.237a22.7366 22.7366 0 0116.736 0l-.736 1.8597a20.7357 20.7357 0 00-15.264 0l-.736-1.8597zM1 22.0002V11.1669h2v10.8333H1zm7 1H2v-2h6v2zm1-6v5H7v-5h2zm3 1H8v-2h4v2zm-1 4v-5h2v5h-2zm7 1h-6v-2h6v2zm1-11.8333v10.8333h-2V11.1669h2z"
      fill="#fff"
      mask="url(#ServiceIconMask1)"
    />
    <path d="M19 11h4v2h-4zM19 15h4v2h-4zM21 7h2v2h-2z" fill="#fff" />
  </svg>
);

export const RxIcon: React.FC = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 27 24"
    width="27"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs />
    <path
      d="M4.8048 6.5l1.9764-4h15.4376l1.9764 4H4.8048z"
      fill={colors.blue}
      stroke={colors.blue}
    />
    <path
      clipRule="evenodd"
      d="M25 8H4v14h21V8zM8.5 16c-.8284 0-1.5.6716-1.5 1.5S7.6716 19 8.5 19h3c.8284 0 1.5-.6716 1.5-1.5s-.6716-1.5-1.5-1.5h-3z"
      fill={colors.blue}
      fillRule="evenodd"
    />
  </svg>
);
