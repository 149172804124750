import { FC, useCallback, useState } from "react";
import { Outlet } from "react-router";
import { ProfileStatus } from "../../../../components/FamilyStatusForm/types";
import MemberIdScreen from "../MemberIdScreen";
import StatusScreen from "../StatusScreen";

export interface StatusScreenGuardProps {
  skipMemberIdUpdate: boolean;
  noticeMemberIdChange: boolean;
  askMemberId: boolean;
  memberId: string | null;
  userStatus: ProfileStatus | null;
  availableStatuses: readonly ProfileStatus[];
}
const StatusScreenGuard: FC<StatusScreenGuardProps> = ({
  askMemberId,
  userStatus,
  availableStatuses,
  memberId,
  noticeMemberIdChange,
  skipMemberIdUpdate,
}) => {
  const statusInTheList =
    !!userStatus && availableStatuses.includes(userStatus);
  /**
   * If member_id is not set and user opens the app for the first time - ask for member_id;
   * We can check if it is the first time by checking the userStatus (it is a mandatory field).
   *
   * The user can click on the skip button - in this case we close the memberIdScreen
   *
   * Member ID is not required out of US
   */
  const [showMemberId, setShowMemberId] = useState(() => {
    const askNormally = !userStatus && !memberId && askMemberId;
    const askJAMF = noticeMemberIdChange && !skipMemberIdUpdate;

    return askNormally || askJAMF;
  });
  const closeMemberIdScreen = useCallback(
    () => setShowMemberId(false),
    [],
  );
  if (!statusInTheList) {
    return (
      <StatusScreen
        availableStatuses={availableStatuses}
        userStatus={userStatus}
      />
    );
  }
  if (showMemberId) {
    return (
      <MemberIdScreen
        closeMemberIdScreen={closeMemberIdScreen}
        isJAMFUpdate={noticeMemberIdChange}
      />
    );
  }
  return <Outlet />;
};

export default StatusScreenGuard;
