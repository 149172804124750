import * as t from "io-ts";
import { ImageSet } from "../../../io-ts/types";

export const IncludedPostBlockC = t.type({
  attributes: t.union([
    t.type({
      body: t.string,
      media: t.literal("body"),
    }),
    t.type({
      embed: t.string,
      media: t.literal("embed"),
    }),
    t.type({
      image_set: ImageSet,
      media: t.literal("image"),
    }),
    t.type({
      image_set: ImageSet,
      media: t.literal("photo"),
    }),
    t.type({
      media: t.null,
    }),
  ]),
  id: t.string,
  type: t.literal("post_block"),
});

export type IncludedPostBlock = t.TypeOf<typeof IncludedPostBlockC>;
