import { Trans } from "@lingui/macro";
import {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMessagesApi } from "../../../lib/messages";
import ModalScreenCard from "../../../lib/ModalScreenCard";
import { JSONApiErrorsException } from "../../../network/jsonApi/core/JSONApiErrorsPayload";
import { mapJSONApiErrors } from "../../../utils/errors";
import ReimbursementForm from "../ReimbursementForm";
import { useSubmitReimbursement } from "./hooks";

export interface ReimbursementPageProps {
  currency: string;
}
export default function ReimbursementPage(
  props: ReimbursementPageProps,
) {
  const { currency } = props;
  // `State` can not be passed to another page, so if user opens it using "Open in new tab" or "Open in new window" context menu,
  //  it will be lost and user will be redirected to the home page.
  //  I think it is fine. Important thing that it is safe, so no one can manipulate with it without access to our code.
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { state } = useLocation();
  const navigate = useNavigate();
  let backLinkUrl = "/"; // default to home page
  let preselectedProgramId: string | undefined;
  if (
    state &&
    typeof state === "object" &&
    typeof (state as Record<string, unknown>).backLinkUrl === "string"
  ) {
    // or you can pass your own link
    backLinkUrl = (state as Record<string, unknown>)
      .backLinkUrl as string;
  }
  if (
    state &&
    typeof state === "object" &&
    typeof (state as Record<string, unknown>).preselectedProgramId ===
      "string"
  ) {
    preselectedProgramId = (state as Record<string, unknown>)
      .preselectedProgramId as string;
  }
  const { isLoading, mutate, error, variables } =
    useSubmitReimbursement();

  const mappedErrors = useMemo(() => {
    if (error instanceof JSONApiErrorsException) {
      return mapJSONApiErrors(error.errors, {
        anyServiceAmount: "data/attributes/services.amount",
        anyServiceDate: "data/attributes/services.date",
        anyServiceName: "data/attributes/services.name",
        file: "data/attributes/receipt",
        id: "data/attributes/program_id",
        provider: "data/attributes/provider_name",
      });
    }
    return null;
  }, [error]);
  const messagesApi = useMessagesApi();
  useEffect(() => {
    const isUnknownError = !mappedErrors && error;
    if (isUnknownError) {
      messagesApi.error({
        content: (
          <Trans>
            There was an unexpected error. Please try again. If this
            error persists, please contact our support team.
          </Trans>
        ),
      });
    }
  }, [error, mappedErrors, messagesApi]);
  const errors: ComponentProps<typeof ReimbursementForm>["errors"] =
    useMemo(() => {
      if (mappedErrors && Object.keys(mappedErrors).length > 0) {
        return {
          // FIXME: temporary solution, backend does not tell me which expense is wrong, so I show the same error for all of them
          expenses: variables?.services?.map(() => ({
            amount: mappedErrors.anyServiceAmount?.title,
            date: mappedErrors.anyServiceDate?.title,
            description: mappedErrors.anyServiceName?.title,
          })),
          file: mappedErrors.file?.title,
          id: mappedErrors.id?.title,
          provider: mappedErrors.provider?.title,
        };
      }
      return null;
    }, [mappedErrors, variables?.services]);
  const handleSubmit: ComponentProps<
    typeof ReimbursementForm
  >["onSubmit"] = useCallback(
    (values) => {
      mutate(
        {
          programId: values.id,
          providerName: values.provider,
          receipt: values.file,
          services: values.expenses.map((expense) => ({
            amount: expense.amount,
            date: expense.date,
            name: expense.description,
          })),
        },
        {
          onSuccess: () => {
            navigate("/reimbursement/success", {
              state: {
                backLinkUrl,
                preselectedProgramId,
              },
            });
          },
        },
      );
    },
    [backLinkUrl, mutate, navigate, preselectedProgramId],
  );

  return (
    <ModalScreenCard
      backLinkTitle={<Trans>Back</Trans>}
      backLinkUrl={backLinkUrl}
      desktopSidePaddingSize={40}
    >
      <ReimbursementForm
        currency={currency}
        errors={errors}
        isSubmitting={isLoading}
        onSubmit={handleSubmit}
        preselectedProgramId={preselectedProgramId}
      />
    </ModalScreenCard>
  );
}
