// ("#21CD97", 0.4) => rgba(33, 205, 151, 0.4)
const hexColorOpacity = (hex: string, opacity: number): string => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    hex,
  );

  return result
    ? `rgba(${parseInt(result[1], 16)}, ${parseInt(
        result[2],
        16,
      )}, ${parseInt(result[3], 16)}, ${opacity})`
    : "";
};

export default hexColorOpacity;
