import { useQuery } from "react-query";
import styled, { css } from "styled-components";
import Alert from "../../lib/Alert";
import Skeleton from "../../lib/Skeleton";
import Title from "../../lib/Title";
import { useAxios } from "../../network";
import mediaQuery from "../../utils/mediaQuery";
import renderError from "../../utils/renderError";
import PageLayout, {
  Body,
  Footer,
  Header,
  Sidebar,
} from "../PageLayout";
import HiddenProvidersList from "./HiddenProvidersList";
import { HiddenProviderEntity } from "./types";

const StyledHeader = styled(Header)`
  ${Title} {
    ${mediaQuery(
      "phone",
      css`
        font-size: 24px;
        line-height: 1;
      `,
    )};
  }
`;

const PageContent = styled.div`
  max-width: var(--content-max-width);
  padding: 0 var(--content-margin-side);
  padding-top: 30px;

  ${mediaQuery(
    "desktopMedium",
    css`
      padding-top: 60px;
    `,
  )}
`;

export default function HiddenProvidersPage() {
  const axios = useAxios();

  const { data, isLoading, error } = useQuery({
    queryFn: async () => {
      const resp = await axios.get("/v2/providers");
      return resp.data as { data: HiddenProviderEntity[] };
    },
    queryKey: "hidden-providers",
  });

  return (
    <PageLayout
      footer={<Footer />}
      header={
        <StyledHeader>
          <Title level="h1">Providers</Title>
        </StyledHeader>
      }
      layoutMode="textContent"
      sidebar={<Sidebar />}
    >
      <Body>
        <PageContent>
          <Skeleton active css="padding: 50px;" loading={isLoading}>
            {!!error && (
              <Alert status="error">{renderError(error)}</Alert>
            )}
            <HiddenProvidersList providers={data?.data || []} />
          </Skeleton>
        </PageContent>
      </Body>
    </PageLayout>
  );
}
