import { isLeft } from "fp-ts/Either";
import * as t from "io-ts";
import reporter from "io-ts-reporters";
import { wrongResponseFormat } from "../../locales/errors";
import { createResourceIdentifierC } from "../../network/jsonApi/builders";
import { denormalize } from "../../network/jsonApi/denormalize";
import { ProgramC } from "../models/Program";
import {
  Reimbursement,
  MainPageReimbursement,
  ReimbursementC,
  ReimbursementEntityWithRelations,
} from "../models/Reimbursement";
import {
  ReimbursementEventEntity,
  ReimbursementEvent,
  ReimbursementEventC,
} from "../models/ReimbursementEvent";
import {
  ReimbursementServiceC,
  ReimbursementServiceEntity,
  ReimbursementService,
} from "../models/ReimbursementService";

const getService = (
  raw: ReimbursementServiceEntity,
): ReimbursementService => ({
  approvedAmount: raw.attributes.approved_amount,
  date: raw.attributes.date,
  id: raw.id,
  name: raw.attributes.name,
  reason: raw.attributes.reason,
  requestedAmount: raw.attributes.requested_amount,
  state: raw.attributes.state,
});

const filterEvent = (event: ReimbursementEventEntity): boolean =>
  ["approved", "canceled", "rejected", "requested"].includes(
    event.attributes.name,
  );

const sortByCreatedAt = (
  a: ReimbursementEvent,
  b: ReimbursementEvent,
) => b.createdAt.getTime() - a.createdAt.getTime();

const getEvent = (
  raw: ReimbursementEventEntity,
  reimbursementReadAt: Date | null,
): ReimbursementEvent => {
  const createdAt = raw.attributes.created_at;

  return {
    createdAt,
    id: raw.id,
    // isRead: !reimbursementReadAt || createdAt <= reimbursementReadAt,
    isRead: !reimbursementReadAt,
    state: raw.attributes.name,
    userEvent: raw.attributes.user_event,
  };
};

export const getReimbursement = (
  raw: ReimbursementEntityWithRelations,
): Reimbursement => {
  const events = raw.relationships.events
    .filter(filterEvent)
    .map((event) =>
      getEvent(event, raw.attributes.notification_unread_at),
    )
    .sort(sortByCreatedAt);

  if (events.length === 0) {
    throw Error(`No valid events in authorization ${raw.id}`);
  }

  const result = {
    createdAt: raw.attributes.created_at,
    events,
    id: raw.id,
    programName: raw.relationships.program.attributes.name,
    providerName: raw.attributes.provider_name,
    services: raw.relationships.services.map(getService),
    state: raw.attributes.state,
    totalApprovedAmount: raw.attributes.total_approved_amount,
    totalRequestedAmount: raw.attributes.total_requested_amount,
    type: raw.type,
    uid: raw.attributes.uid,
  };

  return result;
};

export const getMainPageReimbursement = (
  raw: ReimbursementEntityWithRelations,
): MainPageReimbursement => {
  const events = raw.relationships.events
    .filter(filterEvent)
    .map((event) =>
      getEvent(event, raw.attributes.notification_unread_at),
    )
    .sort(sortByCreatedAt);

  if (events.length === 0) {
    throw Error(`No valid events in authorization ${raw.id}`);
  }

  const result = {
    createdAt: raw.attributes.created_at,
    events,
    id: raw.id,
    state: raw.attributes.state,
    totalApprovedAmount: raw.attributes.total_approved_amount,
    totalRequestedAmount: raw.attributes.total_requested_amount,
    type: raw.type,
    uid: raw.attributes.uid,
  };

  return result;
};

const ReimbursementWithRelationshipsC = t.intersection([
  ReimbursementC,
  t.type({
    relationships: t.type({
      events: t.type({
        data: t.array(
          createResourceIdentifierC("reimbursement_event"),
        ),
      }),
      program: t.type({
        data: createResourceIdentifierC("program"),
      }),
      services: t.type({
        data: t.array(
          createResourceIdentifierC("reimbursement_service"),
        ),
      }),
    }),
  }),
]);

export const responseC = t.type({
  data: t.array(ReimbursementWithRelationshipsC),
  included: t.array(
    t.union([ReimbursementServiceC, ProgramC, ReimbursementEventC]),
  ),
});

const toGetReimbursementsResult = (data: unknown) => {
  const decodeResult = responseC.decode(data);

  if (isLeft(decodeResult)) {
    // eslint-disable-next-line no-console
    console.error(reporter.report(decodeResult));
    throw wrongResponseFormat;
  }
  const denormalized = denormalize(decodeResult.right);
  const reimbursements = denormalized.map(getReimbursement);
  // .sort(
  //   (a, b) =>
  //     b.events[0].createdAt.getTime() -
  //     a.events[0].createdAt.getTime(),
  // );

  return {
    reimbursements,
  };
};

export default toGetReimbursementsResult;
