/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, {
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  css,
} from "styled-components";

type Type =
  | "desktop"
  | "desktopMedium"
  | "desktopSmall"
  | "greaterThanPhone"
  | "greaterThanTablet"
  | "noTouch"
  | "phone"
  | "tablet";

const types: Record<Type, string> = {
  desktop: "@media only screen and (min-width: 1024px)",
  desktopMedium: "@media (min-width: 1200px)",
  desktopSmall: "@media (min-width: 1024px) and (max-width: 1199px)",
  greaterThanPhone: "@media (min-width: 768px)",
  greaterThanTablet: "@media (min-width: 1024px)",
  noTouch: "@media (hover: hover) and (pointer: fine)",
  phone: "@media only screen and (max-width: 767px)",
  tablet:
    "@media only screen and (min-width: 768px) and (max-width: 1023px)",
};

function mediaQuery<P>(
  type: Type,
  style:
    | FlattenInterpolation<P>
    | FlattenSimpleInterpolation
    | string,
): FlattenSimpleInterpolation {
  return css`
    ${types[type]} {
      ${style as any}
    }
  `;
}

export const Desktop = styled.div`
  display: none;
  ${mediaQuery(
    "desktop",
    css`
      display: contents;
    `,
  )}
`;

export const DesktopTablet = styled.div`
  display: none;
  ${mediaQuery(
    "tablet",
    css`
      display: contents;
    `,
  )}
  ${mediaQuery(
    "desktop",
    css`
      display: contents;
    `,
  )}
`;

export const Tablet = styled.div`
  display: none;
  ${mediaQuery(
    "tablet",
    css`
      display: contents;
    `,
  )}
`;

export const Mobile = styled.div`
  display: none;
  ${mediaQuery(
    "phone",
    css`
      display: contents;
    `,
  )}
`;

export default mediaQuery;
