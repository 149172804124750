import { isLeft, isRight } from "fp-ts/Either";
import * as t from "io-ts";
import { useMutation } from "react-query";
import { wrongApiResponse } from "../../../../locales/errors";
import { useAxios } from "../../../../network";
import { ENDPOINTS } from "../../../../network/endpoints";
import JSONApiErrorsPayloadC from "../../../../network/jsonApi/core/JSONApiErrorsPayload";

const twoFactorC = t.type({
  two_factor_verification: t.literal(true),
  verification_token: t.string,
});
const responseC = t.union([
  twoFactorC,
  JSONApiErrorsPayloadC,
  t.unknown,
]);
export interface CheckPasswordPayload {
  email: string;
  password: string;
  recaptchaToken: string;
  // authenticity_token
  // g-recaptcha-response
}
export function useSignIn() {
  const axios = useAxios();
  return useMutation(async (payload: CheckPasswordPayload) => {
    const { email, password, recaptchaToken } = payload;
    const { data } = await axios.post<unknown>(
      `${ENDPOINTS.signIn}`,
      {
        email,
        "g-recaptcha-response": recaptchaToken,
        password,
        strategy: "password",
      },
    );

    const decoded = responseC.decode(data);
    if (isLeft(decoded)) {
      // eslint-disable-next-line no-console
      console.error(decoded.left);
      throw wrongApiResponse;
    } else {
      const is2FADecode = twoFactorC.decode(data);
      if (isRight(is2FADecode)) {
        return {
          mode: "2fa" as const,
          verificationToken: is2FADecode.right.verification_token,
        };
      }
    }
    return {
      mode: "password" as const,
    };
  });
}
