import { Trans } from "@lingui/macro";
import { ReactNode, FC } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../../constants";
import mediaQuery from "../../../../utils/mediaQuery";

const Container = styled.div`
  border: 1px solid ${colors.brownLightGrey1};
  border-radius: 10px;
  margin: 0 -10px;
  padding: 10px;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      margin: 0 -20px;
      padding: 15px 20px;
    `,
  )}
`;

const Top = styled.div`
  font-weight: 500;
  margin-bottom: 10px;
`;

type Props = {
  children: ReactNode;
};

const PrescribedRx: FC<Props> = ({ children }: Props) => (
  <Container>
    <Top>
      <Trans>Prescribed Rx</Trans>
    </Top>
    {children}
  </Container>
);

export default PrescribedRx;
