import { Trans } from "@lingui/macro";
import { TypeOf } from "io-ts";
import { FC, HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { colors, fonts } from "../../../../constants";
import { ImageSet } from "../../../../io-ts/types";
import LinkToTop from "../../../../lib/LinkToTop";
import Title from "../../../../lib/Title";
import mediaQuery from "../../../../utils/mediaQuery";

export interface HomePagePost {
  id: string;
  attributes: {
    name: string;
    slug: string;
    min_to_read: number;
    image_set: TypeOf<typeof ImageSet>;
  };
}
export interface PostProps extends HTMLAttributes<HTMLElement> {
  post: HomePagePost;
}

const PostImageLink = styled(LinkToTop)`
  display: block;
  flex-grow: 0;
  font-size: 0;

  img {
    aspect-ratio: 360 / 310;
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }

  ${mediaQuery(
    "phone",
    css`
      height: 150px;
      overflow: hidden;
      position: relative;
      width: 210px;

      img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    `,
  )}
`;

export const PostText = styled.div`
  box-sizing: border-box;
  flex-grow: 1;
  margin: 0;
`;

export const TimeToRead = styled.div`
  color: ${colors.brownGrey};
  font-family: ${fonts.main};
  font-size: 10px;
  letter-spacing: 0.7px;
  margin: 0 0 2px;
  text-transform: uppercase;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin: 0 0 6px;
    `,
  )}
`;

export const PostTitleLink = styled(LinkToTop)`
  text-decoration: none;
`;

export const PostTitle = styled(Title)`
  color: ${colors.brownBlack};
  font-size: 24px;
  font-weight: normal;
  line-height: 29px;

  &:hover {
    color: ${colors.blue};
  }
`;

const Container = styled.article`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 210px;

  ${mediaQuery(
    "desktopMedium",
    css`
      gap: 10px;
      width: auto;
    `,
  )}

  ${PostTitle} {
    font-family: ${fonts.main};
    font-size: 15px;
    font-weight: 500;
    line-height: 120%;
  }
`;

const Post: FC<PostProps> = ({ post, ...restProps }) => {
  return (
    <Container {...restProps}>
      <PostImageLink
        aria-describedby={`post-${post.id}-title`}
        to={`/articles/${post.attributes.slug}`}
      >
        <img
          alt={post.attributes.name}
          height={
            post.attributes.image_set.aspect_ratio.split(":")[1]
          }
          sizes="(max-width: 767px) 210px, 300px"
          src={post.attributes.image_set.w800}
          srcSet={`${post.attributes.image_set.w64} 64w, ${post.attributes.image_set.w128} 128w, ${post.attributes.image_set.w256} 256w, ${post.attributes.image_set.w512} 512w, ${post.attributes.image_set.w800} 800w`}
          width={post.attributes.image_set.aspect_ratio.split(":")[0]}
        />
      </PostImageLink>
      <PostText>
        <TimeToRead>
          <Trans>min to read: {post.attributes.min_to_read}</Trans>
        </TimeToRead>
        <PostTitleLink to={`/articles/${post.attributes.slug}`}>
          <PostTitle
            id={`post-${post.id}-title`}
            level="h4"
            styleLevel="h1"
          >
            {post.attributes.name}
          </PostTitle>
        </PostTitleLink>
      </PostText>
    </Container>
  );
};

export default Post;
